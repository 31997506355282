import * as React from 'react';
import { useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ForgotPasswordGetOTPLogic = () => {

    let navigate = useNavigate();
    const [showError,setError] = React.useState(false);
    const [userID,setUserID] = React.useState("");
   
    const handleSubmit = (event) => {
      event.preventDefault();
    };
  
    async function getOTP(e,user_id){
    
      console.log(user_id)
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_BASE_URL}public/verifyUserId/${user_id}`, requestOptions)
        .then(response => response.json())
        .then(res => {
          console.log(res)
          console.log("UUID is ..........",res.uuId);
          if(res.code === 200){  
            // setUser("user",{uuid: res.data})
            toast(res.message);
            setTimeout(() => {
              navigate('/forgotPasswordVerifyOTP',{state:{username:user_id,uuid:res.uuId,orgId:res.orgId}});
            }, 4000);
  
        }else if(res.code !== 200){
            toast("Invalid UserID")
        }
        })
        .catch(error => {
          console.log('error', error)
          if (error.code === "ERR_BAD_REQUEST") {
            console.log(error.response);
            console.log("server responded");
            toast("Unauthorized User");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });
      
  }
  return {showError,setError,userID,setUserID,getOTP,handleSubmit}

}

export default ForgotPasswordGetOTPLogic