import store from '../../context/StatePoolService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Await } from 'react-router-dom';

const AdminService = () => {
    
    const [user, setUser] = store.useState("user");
    const [personID,setPersonID] = store.useState('personID');
    const [doctorUUID,setDoctorUUID] = store.useState('doctorUUID');
    const [orgID,setOrgID] = store.useState('orgID');

        async function GetGenderDetails(){
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
            
            return fetch(`${user.baseUrl}/public/masterDataSearch/GENDER`, requestOptions)
              .then(response => response.json())
              .then(data => {
                  console.log('Success:', data.content);
                  return data.content;
              })
              .catch(error => console.log('error', error));
          
        }

        async function GetMasterData(masterDataType,search_term){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          console.log("ffffffffffffffffffffffffffffff",search_term)
              if(search_term === '' || search_term === undefined || search_term === 'undefined'){
                

                  const response = await fetch(`${user.baseUrl}/public/masterDataSearch/${masterDataType}/${search_term}`, requestOptions)
                  console.log("RESPONSE",response)
                  if (!response.ok && response.status === 204) {
                      // throw new Error('Exception message');
                      return [];
                  }else{
                      const data = await response.json();
                      const result = {status: response.status, data};
                      return result
                  }
                  

                  
              }else if(search_term !== ''){
                  
                  const response = await fetch(`${user.baseUrl}/public/masterDataSearch/${masterDataType}/${search_term}`, requestOptions)
                  console.log("RESPONSE",response)
                  if (!response.ok && response.status === 204) {
                      // throw new Error('Exception message');
                      return [];
                  }else{
                      const data = await response.json();
                      const result = {status: response.status, data};
                      console.log("RESULT",result)
                      return result
                  }
              }
        }

        async function GetPrivateMasterData(masterDataType,search_term){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          console.log("ffffffffffffffffffffffffffffff",search_term)
              if(search_term === '' || search_term === undefined || search_term === 'undefined'){
                

                  const response = await fetch(`${user.baseUrl}private/${masterDataType}/${search_term}`, requestOptions)
                  console.log("RESPONSE",response)
                  if (!response.ok && response.status === 204) {
                      // throw new Error('Exception message');
                      return [];
                  }else{
                      const data = await response.json();
                      const result = {status: response.status, data};
                      return result
                  }
                  

                  
              }else if(search_term !== ''){
                  
                  const response = await fetch(`${user.baseUrl}private/${masterDataType}/${search_term}`, requestOptions)
                  console.log("RESPONSE",response)
                  if (!response.ok && response.status === 204) {
                      // throw new Error('Exception message');
                      return [];
                  }else{
                      const data = await response.json();
                      const result = {status: response.status, data};
                      console.log("RESULT",result)
                      return result
                  }
              }

        }
        
        async function getDoctorsList(org_id,search_term){
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", user.token);
            
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
            try{
              let data = await fetch(`${user.baseUrl}user/userSearch/${org_id}/DOCTOR/${search_term}?size=2000`, requestOptions);
              if(data.status === 200){
                return await data.json()
              }else{
                return [];
              }
              
            }catch(error){
              toast(error)
            }
            // await 
            // return fetch(`${user.baseUrl}/user/userSearch/DOCTOR`, requestOptions)
              // .then(response => {
              //   console.log("LIST body",response)
              //   if(response.status === 200){
                  
              //     return  response.json()
              //   }else if(response.status === 204){
              //     return []
              //   }
              // })
              // .then(data => {
              //     console.log('Success:', data.content);
              //     return data.content;
              // })
              // .catch(error => console.log('error', error));
        }
        async function getDoctorsPageList(org_id,search_term,page){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);
          
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          try{
            let data = await fetch(`${user.baseUrl}user/userSearch/${org_id}/DOCTOR/${search_term}?page=${page}&size=5`, requestOptions);
            if(data.status === 200){
              return await data.json()
            }else{
              return [];
            }
            
          }catch(error){
            toast(error)
          }
          
      }
        async function getDoctorSearchList(org_id,search_term){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);
          
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          try{
            let data = await fetch(`${user.baseUrl}user/userSearch/${org_id}/DOCTOR/${search_term}?size=5`, requestOptions);
            if(data.status === 200){
              return await data.json()
            }else{
              return [];
            }
            
          }catch(error){
            toast(error)
          }
          // await 
          // return fetch(`${user.baseUrl}/user/userSearch/DOCTOR`, requestOptions)
            // .then(response => {
            //   console.log("LIST body",response)
            //   if(response.status === 200){
                
            //     return  response.json()
            //   }else if(response.status === 204){
            //     return []
            //   }
            // })
            // .then(data => {
            //     console.log('Success:', data.content);
            //     return data.content;
            // })
            // .catch(error => console.log('error', error));
        }

        async function getAdminList(org_id,search_term,page){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);
          
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          try{
            let data = await fetch(`${user.baseUrl}adminSearch/${org_id}/ADMIN/${search_term}?page=${page}&size=5`, requestOptions);
            if(data.status === 200){
              return await data.json()
            }else{
              return [];
            }
            
          }catch(error){
            toast(error)
          }
          
        }

        async function getPatientList(org_id,search_term){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);
          
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          try{
            let data = await fetch(`${user.baseUrl}user/userSearch/${org_id}/PATIENT/${search_term}?size=2000`, requestOptions);
            if(data.status === 200){
              return await data.json()
            }else{
              return [];
            }
            
          }catch(error){
            toast(error)
          }
          
          // return fetch(`${user.baseUrl}/user/userSearch/PATIENT/a?size=2000`, requestOptions)
          //   .then(response => response.json())
          //   .then(data => {
          //       console.log('Success:', data.content);
          //       return data.content;
          //   })
          //   .catch(error => console.log('error', error));
        }

        async function getOrgList(){
          var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", user.token);
            
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
            
            return fetch(`${user.baseUrl}organization/getChildOrg/${user.orgID}`, requestOptions)
              .then(response => response.json())
              .then(data => {
                  console.log('Success:', data);
                  return data;
              })
              .catch(error => console.log('error', error));
        }

        async function getAddressList(search_term){

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");


              var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
              };
              console.log("ffffffffffffffffffffffffffffff",search_term)
              if(search_term === '' || search_term === undefined || search_term === 'undefined'){
                

                  const response = await fetch(`${user.baseUrl}public/addressSearch/${search_term}?size=20`, requestOptions)
                  console.log("RESPONSE",response)
                  if (!response.ok && response.status === 204) {
                      // throw new Error('Exception message');
                      return [];
                  }else{
                      const data = await response.json();
                      const result = {status: response.status, data};
                      return result
                  }
                  

                  
              }else if(search_term !== ''){
                  
                  const response = await fetch(`${user.baseUrl}public/addressSearch/${search_term}?size=20`, requestOptions)
                  console.log("RESPONSE",response)
                  if (!response.ok && response.status === 204) {
                      // throw new Error('Exception message');
                      return [];
                  }else{
                      const data = await response.json();
                      const result = {status: response.status, data};
                      console.log("RESULT",result)
                      return result
                  }
              }
        }

        async function createOrg(orgName,contactEmail,contact_person,contactPhone,org_type,short_name,addr,
          city,pincode,state,country,contactUsEmail,contactUsPhone,org_logo,other_image){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var raw = JSON.stringify({
            "displayname": short_name,
            "email": contactEmail,
            "contactperson": contact_person,
            "phone": contactPhone,
            "organizationCategory": {
              "typeID": org_type
            },
            "orgname": orgName,
            "orglogo": org_logo,
            "otherimage": other_image,
            "contactUsContactNumber": contactUsPhone,
            "contactUsEmail": contactUsEmail,
            "addressDto": {
              "type": "permanent",
              "address1": addr,
              "address2": "",
              "taluk": city,
              "district": city,
              "city": city,
              "state": state,
              "country": country,
              "landMark": "",
              "postalCode": pincode,
              "postOffice": ""
            }
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          console.log(raw)

          return fetch(`${user.baseUrl}organization/createOrg`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }
        async function updateOrg(org_id,orgName,contactEmail,contact_person,contactPhone,org_type,short_name,addr,
          city,pincode,state,country,contactUsEmail,contactUsPhone,org_logo,other_image){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var raw = JSON.stringify({
            "orgId": org_id,
            "displayname": short_name,
            "email": contactEmail,
            "contactperson": contact_person,
            "phone": contactPhone,
            "organizationCategory": {
              "typeID": org_type
            },
            "orgname": orgName,
            "orglogo": org_logo,
            "otherimage": other_image,
            "contactUsContactNumber": contactUsPhone,
            "contactUsEmail": contactUsEmail,
            "addressDto": {
              "type": "permanent",
              "address1": addr,
              "address2": "",
              "taluk": city,
              "district": city,
              "city": city,
              "state": state,
              "country": country,
              "landMark": "",
              "postalCode": pincode,
              "postOffice": ""
            }
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          console.log(raw)

          return fetch(`${user.baseUrl}organization/updateOrg`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }

        async function registerDoctor(partner_id,prefix,username,password,firstname,lastname,dob,email,mobileno,gender,
        address1,address2,city,state,country,pincode,
        profileImage,sigImage,language,role,timezone,fees,bloodGroup){
        console.log(prefix,username,password,firstname,lastname,dob,
          profileImage,sigImage,language,role,timezone,fees,bloodGroup,
          email,mobileno,gender,address1,address2,city,state,country,pincode);
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var raw = JSON.stringify(
          {
            "userType": "DOCTOR",
            "username": username,
            "password": password,
            "givenName": firstname,
            "lastName": lastname,
            "contactNumber": mobileno,
            "email": email,
            "gender": {
                "genderCode": gender
            },
            "prefix": prefix,
            "dateOfBirth": dob,
            "dobStatus": "age",
            "address": {
                "type": "permanent",
                "address1": address1,
                "address2": address2,
                "taluk": city,
                "district": city,
                "city": city,
                "state": state,
                "country": country,
                "landMark": "landmark",
                "postalCode": pincode,
                "postOffice": "postoffice"
            },
            "profileImage": profileImage,
            "signatureUrl": sigImage,
            "imageFile": profileImage,
            "languagesSpoken": language,
            "fees": {
                "typeID": fees
            },
            "bloodgroup": {
                "typeID": bloodGroup
            },
            "timezone": {
                "typeID": timezone
            },
            "role": {
                "typeID": role
            },
            "partner":partner_id,
        });
          console.log("raw is",raw)
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

           return fetch(`${user.baseUrl}user/createUser`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              console.log("ID is",result.uuid)
              console.log(result.ehrStatus)
              setDoctorUUID(result.uuid)
              if(result.ehrStatus === '200 OK'){ 
                  return result;
              }else{
                toast(result.error)
              }
            })
            .catch(error => {
              console.log('error', error)
              toast(error);
            });
        }

        async function registerAdmin(username,password,firstname,lastname,email,mobileno,
          address1,city,state,country,pincode,role,partner_id,department_id){
          console.log(username,password,firstname,lastname,email,mobileno,
            address1,city,state,country,pincode,role,partner_id,department_id);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", user.token);
  
            var raw = JSON.stringify(
            {
              "userType": "ADMIN",
              "username": username,
              "password": password,
              "givenName": firstname,
              "lastName": lastname,
              "contactNumber": mobileno,
              "email": email,
              
              "address": {
                  "type": "permanent",
                  "address1": address1,
                  "address2": "",
                  "taluk": city,
                  "district": city,
                  "city": city,
                  "state": state,
                  "country": country,
                  "landMark": "landmark",
                  "postalCode": pincode,
                  "postOffice": "postoffice"
              },
              "role": {
                  "typeID": role
              },
              "partner":partner_id,
              "department": {
                  "typeID": department_id
              }
          });
            console.log("raw is",raw)
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
  
             return fetch(`${user.baseUrl}user/createUser`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
                console.log("ID is",result.uuid)
                console.log(result.ehrStatus)
                setDoctorUUID(result.uuid)
                if(result.ehrStatus === '200 OK'){ 
                    return result;
                }else{
                  toast(result.error)
                }
              })
              .catch(error => {
                console.log('error', error)
                toast(error);
              });
        }

        async function updateAdminDetail(person_id,username,password,firstname,lastname,email,mobileno,
          address1,city,state,country,pincode,role,partner_id,department_id){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var raw = JSON.stringify({
            "personId": person_id,
            "userType": "ADMIN",
              "username": username,
              "password": password,
              "givenName": firstname,
              "lastName": lastname,
              "contactNumber": mobileno,
              "email": email,
              
              "address": {
                  "type": "permanent",
                  "address1": address1,
                  "address2": "",
                  "taluk": city,
                  "district": city,
                  "city": city,
                  "state": state,
                  "country": country,
                  "landMark": "landmark",
                  "postalCode": pincode,
                  "postOffice": "postoffice"
              },
              "role": {
                  "typeID": role
              },
              "partner":partner_id,
              "department": {
                  "typeID": department_id
              }
            
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}/updateAdmin`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }

        async function registerPatient(partner_id,register_Id,firstname,lastname,dob,email,mobileno,gender,isCampPatient,bloodGroup){
          console.log(register_Id,firstname,lastname,dob,email,mobileno,gender,isCampPatient);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", user.token);
  
            var raw = JSON.stringify({
              "userType": "PATIENT",
              "username": register_Id,
              "password": "test123",
              "givenName": firstname,
              "lastName": lastname,
              "contactNumber": mobileno,
              "email": email,
              "gender": {
                  "genderCode": gender
              },
              "prefix": "",
              "dateOfBirth": dob,
              "dobStatus": "age",
              "address": {
                  "type": "permanent",
                  "address1": "string",
                  "address2": "string",
                  "taluk": "string",
                  "district": "string",
                  "city": "string",
                  "state": "string",
                  "country": "string",
                  "landMark": "string",
                  "postalCode": "string",
                  "postOffice": "string"
              },
              "profileImage": "string",
              "signatureUrl": "string",
              "languagesSpoken": "string",
              "fees": {
                "typeID": 140
                },
                "bloodgroup": {
                    "typeID": bloodGroup
                },
                "timezone": {
                    "typeID": 46
                },
                "role": {
                    "typeID": 141
                },
                "partner":partner_id,
                "isCamp": isCampPatient
          }
          
          );
            console.log("raw is",raw)
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
  
             return fetch(`${user.baseUrl}user/createUser`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
                console.log("ID is",result.uuid)
                console.log(result.ehrStatus)
                
                if(result.ehrStatus === '200 OK'){
                    return result;
                }
              })
              .catch(error => {
                console.log('error', error)
                toast(error);
              });
        }

        async function mapUsertoOrg(orgid,uuid){
          var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", user.token);

            var raw = JSON.stringify({
              "uuid": uuid,
              "orgId": orgid,
              "roleName": "OrgSuperAdmin"
            });
            console.log("RAW of MAP USER....",raw);
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            return await fetch(`${user.baseUrl}person/roleMapping`, requestOptions)
              .then(response => response.text())
              .then(result => console.log("Mapping result...........",result))
              .catch(error => console.log('error', error));
        }

        async function getDoctorDetails(person_id){
      var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        return fetch(`${user.baseUrl}user/getPersonDetailsByPersonId/${person_id}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result);
            return result;
          })
          .catch(error => console.log('error', error));
        }

        async function getAdminDetails(person_id){
          var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", user.token);
    
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
    
            return fetch(`${user.baseUrl}getAdminDetailsByPersonId/${person_id}`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result);
                return result;
              })
              .catch(error => console.log('error', error));
        }

        async function addProfessionalDetails(doctor_uuid,qualificationList,profSpeciality,stateLicense,otherLicense,
          experience,regNumber,category,description,emergency,researches,awards,accreditations,
          specializationDescription,qualificationDescription,publications,workExp){

          console.log(qualificationList,profSpeciality,stateLicense,otherLicense,
            experience,regNumber,category,description,emergency,researches,awards,accreditations,
            specializationDescription,qualificationDescription,publications,workExp)

        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "otherLicence": otherLicense,
          "experience": workExp,
          "description": description,
          "researches": researches,
          "awards": awards,
          "accreditations": accreditations,
          "publications": publications,
          "category":  {
            "typeID": category
          },
          "emergency":emergency,
          "userUuId": doctor_uuid,
          "expInYears":experience,
          "regNumber": regNumber,
          "qualificationDiscription":qualificationDescription,

            "specializationDiscription":specializationDescription,
            "pofesssionalSpl": {
                "typeID": profSpeciality
            },
            "stateLicence": {
                "typeID": stateLicense
            },
          "qualificationList": qualificationList
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        console.log(raw)
        return  fetch(`${user.baseUrl}professional/create`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result)
            if(result.status === 200){
                return result;
            }
          })
          .catch(error => {
            console.log('error', error)
            toast(error);
          });
        }

        async function getProfessionalDetails(user_uuid){
          var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        console.log(`${user.baseUrl}professional/getProfessionalByUuId/${user_uuid}`)
        return fetch(`${user.baseUrl}professional/getProfessionalByUuId/${user_uuid}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result);
            return result;
          })
          .catch(error => console.log('error', error));
        
        }

        async function addConsultationDetails(consultationList){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var raw = JSON.stringify(consultationList);
          console.log(raw)

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}consultation/create`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }

        async function updateConsultationDetails(consultationList,user_uuid){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var raw = JSON.stringify(consultationList);
          console.log(raw)

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}consultation/update/${user_uuid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }

        async function getConsultationDetails(doctor_uuid){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}consultation/getConsultationDetailByUuid/${doctor_uuid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
            return result
            })
            .catch(error => console.log('error', error));
        }

        async function addConfigurationDetails(org_id,entityId,smtpServer,userId,password,fromName,fromEmail,
          port,url,tls,smtpAuth){
          var myHeaders = new Headers();
            myHeaders.append("Authorization", user.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              "orgId": org_id,
              "entityId": entityId,
              "smtpServer": smtpServer,
              "userId": userId,
              "password": password,
              "fromName": fromName,
              "fromEmail": fromEmail,
              "port": port,
              "smsTemplate": "",
              "pushNotificationKey": "",
              "url": url,
              "tls": tls,
              "smtpAuth": smtpAuth
            });

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            return fetch(`${user.baseUrl}organization/createOrgNotificationDetails`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
                return result;
              })
              .catch(error => console.log('error', error));
        }

        async function updateConfigurationDetails(id,org_id,entityId,smtpServer,userId,password,fromName,fromEmail,
          port,url,tls,smtpAuth){
          var myHeaders = new Headers();
            myHeaders.append("Authorization", user.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              "id":id,
              "orgId": org_id,
              "entityId": entityId,
              "smtpServer": smtpServer,
              "userId": userId,
              "password": password,
              "fromName": fromName,
              "fromEmail": fromEmail,
              "port": port,
              "smsTemplate": "",
              "pushNotificationKey": "",
              "url": url,
              "tls": tls,
              "smtpAuth": smtpAuth
            });

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            return fetch(`${user.baseUrl}organization/createOrgNotificationDetails`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
                return result;
              })
              .catch(error => console.log('error', error));
        }

        async function getConfigurationDetails(org_id){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}organization/getOrgNotificationDetails/${org_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function addTemplateDetails(templateDataList){
          var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", user.token);

            var raw = JSON.stringify(templateDataList);

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
            console.log(raw)
            return fetch(`${user.baseUrl}organization/createNotifyTemplate`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
                return result
              })
              .catch(error => console.log('error', error));
        }

        async function updateTemplateDetails(templateDataList,org_id){
          var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", user.token);

            var raw = JSON.stringify(templateDataList);

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
            console.log(raw)
            return fetch(`${user.baseUrl}organization/updateNotifyTemplate/${org_id}`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
                return result
              })
              .catch(error => console.log('error', error));
        }

        async function getTemplateDetails(org_id){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}organization/getOrgNotificationTemplate/${org_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function getOrgDetails(org_Id){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}organization/getOrganizationDetailByOrgId/${org_Id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }

        async function addFamilyMember(first_name,last_name,gender,email,dob,relation_type,patient_id,bloodGroup_type,phoneNumber){
          var myHeaders = new Headers();
            myHeaders.append("Authorization", user.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              "personDto": {
                "bloodgroup":{
                  "typeID":bloodGroup_type
                },
                "phoneNumber": phoneNumber,
                "patientId": patient_id,
                "givenName": first_name,
                "middleName": last_name,
                "gender": {
                  "genderCode": gender
                },
                "prefix": "",
                "email": email,
                "dateOfBirth": dob,
                "dobStatus": "age",
                "profileImage": ""
              },
              "relation": {
                "typeID": parseInt(relation_type)
              },
              "parentUuId": patient_id
            });

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
            console.log(raw)
            return fetch(`${user.baseUrl}familyMember/addFamilyMember`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
                return result;
              })
              .catch(error => console.log('error', error));
        }

        async function getRelationshipList(){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}public/masterDataSearch/RELATIONSHIP`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function getFamilyMemberList(patient_uuid){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}familyMember/getFamilyMember/${patient_uuid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }

        async function getFamilyMemberDetails(member_id){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}/familyMember/getFamilyMemberDetails/${member_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function updateFamilyMember(member_id,first_name,last_name,gender,email,dob,relation_type,patient_id,bloodGroup_type,phoneNumber){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "personDto": {
              "bloodgroup":{
                    "typeID":bloodGroup_type
                },
              "phoneNumber": phoneNumber,
              "patientId": patient_id,
              "givenName": first_name,
              "middleName": last_name,
              "gender": {
                "genderCode": gender
              },
              "prefix": "",
              "email": email,
              "dateOfBirth": dob,
              "dobStatus": "age",
              "profileImage": ""
            },
            "relation": {
              "typeID": relation_type
            },
            "parentUuId": patient_id
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}familyMember/updateFamilyMember/${member_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function updatePersonalDetails(partner_id,person_Id,prefix,username,firstname,lastname,dob,email,mobileno,gender,
          address1,address2,city,state,country,pincode,
          profileImage,sigImage,language,role,timezone,fees,bloodGroup){
          console.log(prefix,username,firstname,lastname,dob,
            profileImage,sigImage,language,role,timezone,fees,bloodGroup,
            email,mobileno,gender,address1,address2,city,state,country,pincode);
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", user.token);
  
            var raw = JSON.stringify(
            {
              "personId": person_Id,
              "userType": "DOCTOR",
              "username": username,
              "givenName": firstname,
              "lastName": lastname,
              "contactNumber": mobileno,
              "email": email,
              "gender": {
                  "genderCode": gender
              },
              "prefix": prefix,
              "dateOfBirth": dob,
              "dobStatus": "age",
              "address": {
                  "type": "permanent",
                  "address1": address1,
                  "address2": address2,
                  "taluk": city,
                  "district": city,
                  "city": city,
                  "state": state,
                  "country": country,
                  "landMark": "landmark",
                  "postalCode": pincode,
                  "postOffice": "postoffice"
              },
              "profileImage": profileImage,
              "signatureUrl": sigImage,
              // "imageFile": profileImage,
              "languagesSpoken": language,
              "fees": {
                  "typeID": fees
              },
              "bloodgroup": {
                  "typeID": bloodGroup
              },
              "timezone": {
                  "typeID": timezone
              },
              "role": {
                  "typeID": role
              },
              "partner":partner_id,
          });
            console.log("raw is",raw)
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

          return fetch(`${user.baseUrl}user/updatePerson`, requestOptions)
            .then(response => response.json())
            .then(result => {
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function deleteFamilyMember(member_id){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}familyMember/deleteFamilyMember/${member_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function GetMasterSearchData(masterDataType){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}private/masterDataSearch/${masterDataType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result.content
            })
            .catch(error => console.log('error', error));
        }

        async function createDoctorPanel(panel_name,doctorUUIDList){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var raw = JSON.stringify({
            "orgId": user.orgID,
            "panelName": panel_name,
            "doctorList": doctorUUIDList
          });
          console.log(raw)
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}doctor/createDoctorPanel`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }
        async function updateDoctorPanel(panel_name,doctorUUIDList,panel_id){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var raw = JSON.stringify({
            "panelId":panel_id,
            "orgId": user.orgID,
            "panelName": panel_name,
            "doctorList": doctorUUIDList
          });
          console.log(raw)
          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}doctor/updateDoctorPanel`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }

        async function getPanelListByOrgId(){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}doctor/getPanelList/${user.orgID}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }

        async function deletePanelByID(panel_id){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}doctor/deleteDoctorPanel/${panel_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function getDoctorListByPanelID(panelID,search_term){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          console.log("panel ID INside API CALL",panelID)
          return fetch(`${user.baseUrl}doctor/getPanelDoctorList/${panelID}/${search_term}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
          
        }
        
        async function insertFilestoVault(file_path,description,file_type,doctor_uuid){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "doctorUuId": doctor_uuid,
            "fileType": {
              "typeID": file_type
            },
            "filePath": file_path,
            "description": description
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          console.log(raw)
          return fetch(`${user.baseUrl}vault/create`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }

        async function fileUpload(file,folderName){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var formdata = new FormData();
          formdata.append("file", file, file.name);

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}private/fileUpload/${folderName}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result
            })
            .catch(error => console.log('error', error));
        }

        async function getFileUploadURL(path){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}private/getFile?path=${path}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function changePassword(old_password,new_password){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "currentpassword": old_password,
            "newpassword": new_password
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          console.log(raw)
          return fetch(`${user.baseUrl}private/changePassword`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function userLogout(){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var raw = "";

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}private/signOut`, requestOptions)
            .then(response => response.json())
            .then(result => {console.log(result)
                return result;
            })
            .catch(error => console.log('error', error));
        }

        async function fileDownload(path){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        fetch(`${user.baseUrl}private/getFile?path=${path}`, requestOptions)
        .then((response) => response.blob())
  .then((blob) => {
    // Create blob link to download
    
    console.log('blob',blob)
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    if(blob.type === 'image/png'){
      link.setAttribute(
        'download',
        `${path.slice(81)}.png`,
      );
    }else if(blob.type === 'image/jpg'){
      link.setAttribute(
        'download',
        `${path.slice(81)}.jpg`,
      );
    }else if(blob.type === 'image/jpeg'){
      link.setAttribute(
        'download',
        `${path.slice(81)}.jpeg`,
      );
    }else if(blob.type === 'application/pdf'){
      link.setAttribute(
        'download',
        `${path.slice(81)}.pdf`,
      );
    }
    

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });
        }
        function encode (s) {
            const out = [];
            for ( let i = 0; i < s.length; i++ ) {
              out[i] = s.charCodeAt(i);
            }
            return new Uint8Array(out);
        }

        async function getVaultListFile(doctor_uuid,patient_uuid){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}vault/getVaultList/${doctor_uuid}/${patient_uuid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function doctorSearch(org_id,search_term){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}user/userSearch/${org_id}/DOCTOR/${search_term}?page=0&size=10`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }
        async function patientSearch(search_term){
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}user/userSearch/PATIENT/${search_term}?page=0&size=10`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function assignDoctortoPatient(doctor_uuid,patientList){
          var myHeaders = new Headers();
            myHeaders.append("Authorization", user.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
              "doctorList": [
                {
                  "uuId": doctor_uuid
                }
              ],
              "personList": patientList
            });

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            return fetch(`${user.baseUrl}doctor/doctorPatientMapping`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
                return result;
              })
              .catch(error => console.log('error', error));
        }

        async function getAssignedPatientList(doctor_uuid){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}doctor/getAssignedDoctor/${doctor_uuid}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function approvalList(orgId,status){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);
    
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
    
          return fetch(`${user.baseUrl}person/listPatient/${orgId}/${status}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function getPreRegDetailById(id){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}person/detailPatient/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function preRegUpdateAprrove(id,prefix,firstName,lastName,genderCode,email,phoneNumber,
          dob,bloodGroupID,hospitalNumber,address,city,state,country,pincode,partnerID,idType,idNumber,idFatherName,
          emergencyName,emergencyContact,emergencyRelation,doctorName,doctorNumber,profileImage){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "id": id,
            "userType": "PATIENT",
            "prefix": prefix,
            "givenName": firstName,
            "middleName": lastName,
            "gender": {
              "genderCode": genderCode
            },
            "email": email,
            "dateOfBirth": dob,
            "dobStatus": "age",
            "bloodgroup": {
              "typeID": bloodGroupID
            },
            "patientId": hospitalNumber,
            "address": {
              "type": "permanent",
              "address1": address,
              "address2": "",
              "taluk": city,
              "district": city,
              "city": city,
              "state": state,
              "country": country,
              "landMark": "",
              "postalCode": pincode,
              "postOffice": ""
            },
            "partnerId": partnerID,
            "idproof": {
              "type": idType,
              "idNumber": idNumber,
              "father_name": idFatherName
            },
            "profileImage": profileImage,
            "phoneNumber": phoneNumber,
            "emergencyName": emergencyName,
            "emergencyNumber": emergencyContact,
            "emergencyRelation": emergencyRelation,
            "doctorName": doctorName,
            "doctorNumber":doctorNumber
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };
          console.log(raw)
          return fetch(`${user.baseUrl}person/updateAndApprovePatient`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

        async function campPatientList(search_term,page){
          var myHeaders = new Headers();
          myHeaders.append("Authorization", user.token);
          myHeaders.append("Content-Type", "application/json");

          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };

          return fetch(`${user.baseUrl}user/campPatientSearch/PATIENT/${search_term}?page=${page}&size=5`, requestOptions)
            .then(response => response.json())
            .then(result => {
              console.log(result)
              return result;
            })
            .catch(error => console.log('error', error));
        }

    return {GetGenderDetails,getDoctorsList,getOrgList,getAddressList,createOrg,registerDoctor,getDoctorDetails,GetMasterData,
      addProfessionalDetails,getProfessionalDetails,getRelationshipList,addFamilyMember,getFamilyMemberList,GetPrivateMasterData,
      addConsultationDetails,updatePersonalDetails,GetMasterSearchData,getConsultationDetails,createDoctorPanel,getFileUploadURL,
    getPanelListByOrgId,getDoctorListByPanelID,getOrgDetails,insertFilestoVault,fileUpload,userLogout,registerPatient,getPatientList,mapUsertoOrg,
    addConfigurationDetails,addTemplateDetails,getConfigurationDetails,updateConfigurationDetails,updateOrg,getTemplateDetails,updateTemplateDetails,
  getAdminList,registerAdmin,getAdminDetails,deletePanelByID,getDoctorSearchList,updateDoctorPanel,changePassword,updateAdminDetail,fileDownload,
updateConsultationDetails,getVaultListFile,getFamilyMemberDetails,updateFamilyMember,deleteFamilyMember,doctorSearch,patientSearch,
assignDoctortoPatient,getAssignedPatientList,approvalList,getPreRegDetailById,preRegUpdateAprrove,campPatientList,getDoctorsPageList}
    
}

export default AdminService;