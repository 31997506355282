import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import { BrowserRouter,Routes,Route} from "react-router-dom";
import DoctorDashboard from '../src/routes/DoctorDashBoard/Doctor';
import Login from './Auth/Login/login';
import VerifyOTP from './routes/PreRegistration/PreRegistration2';
import PreRegistrationForm from './routes/PreRegistration/PreRegistrationForm';
import AdminDashboard from './routes/AdminDashboard/Admin';
import ForgotPasswordGetOTP from './Auth/ForgotPassword/GetOTP/ForgotPasswordGetOTP';
import ForgotPasswordVerifyOTP from './Auth/ForgotPassword/VerifyOTP/ForgotPasswordVerifyOTP';
import ChangePassword from './Auth/ForgotPassword/PasswordChange/ChangePassword';
import GetOTP from './routes/PreRegistration/PreRegistration1';
import TermsandCondition from './components/TermsandConditions';
import ContactUS from './components/ContactUs';
import PrivacyPolicy from './components/PrivacyPolicy';
import FAQ from './components/FAQ';
import { ToastContainer,toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FixedBottomNavigation from './components/BottomNavigation';


const root = ReactDOM.createRoot(
  document.getElementById("root")
);
root.render(
  <BrowserRouter>
    <Routes>  
      <Route path="/" element={<App />} />
      <Route path="/login" element={<Login />} />
      <Route path="/preReg" element={<GetOTP />} />
      <Route path="/verifyOTP" element={<VerifyOTP />} />
      <Route path="/preRegForm" element={<PreRegistrationForm />} />
      <Route path="/dDashboard" element={<DoctorDashboard />} />
      <Route path="/aDashboard" element={<AdminDashboard />} />
      <Route path="/forgotPasswordVerifyOTP" element={<ForgotPasswordVerifyOTP />} />
      <Route path="/forgotPasswordGetOTP" element={<ForgotPasswordGetOTP />} />
      <Route path="/changePassword" element={<ChangePassword />} />
      <Route path="/termsandconditions" element={<TermsandCondition />} />
      <Route path="/contactUs" element={<ContactUS />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} />
    </Routes>
    <FixedBottomNavigation/>
    <ToastContainer />
  </BrowserRouter>
);
