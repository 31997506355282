import * as React from 'react';
import Typography from '@mui/material/Typography';
import { colors,Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import TransferList from './TransferListComponent';




function AddNewPanel(){
    
        const [panelID, setPanelID] = React.useState('');
       
    return(
        <Grid item xs={12} md={12} lg={12}>
            <Typography padding={0.5} fontWeight={'bold'}>Add New Panel</Typography>
                        
                        <TransferList
                        panelId={panelID}
                        isUpdate={false}
                        />
                        
                </Grid>
    )
  }

export default AddNewPanel;