import * as React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import store from '../../../context/StatePoolService';
import ScheduleService from '../DoctorScheduling/ScheduleService';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import EncounterService from '../AppointmentsComponent/EncounterService';

function PatientCard(props){

  const {getDetailsByAptId,cancelAppointment} = ScheduleService();
  const {getVideoURL} = EncounterService();
  const [component, setComponent] = store.useState('componentState');
  const [user, setUser] = store.useState("user");
  const [aptData, setAptData] = React.useState({});
  const [isLoading,setIsLoading] = React.useState(true);
  console.log("CONSULTATION ID",props.consultationid)
  console.log("PATIENT DATA",aptData)
  React.useEffect(() => {
    getDetailsByAptId(props.consultationid).then(res => {
      if(res.status === 200){
        console.log("INSIDE DETAILS CALL",res)
        setAptData(res.data)
        setIsLoading(false)
      }else{
        setIsLoading(true)
        toast(res.message)
      }
      
    })
    
  },[])
  
    return(
          <>
          {isLoading === false ? <Card sx={{ maxWidth: 'auto',borderRadius:5,marginTop:1}} elevation={4}>
                    <CardHeader
                      action={
                        <Button variant="contained" color="success" size='small'>
                          {aptData.aptSlotsDto.aptSlotStartTime.slice(0,10)} | {aptData.aptSlotsDto.aptSlotStartTime.slice(11,16)}
                        </Button>
                      }
                      title={aptData.name}
                      subheader={`${aptData.gender} . ${aptData.age}`}
                    />
                    <Divider/>
                    <CardContent>
                    <Typography variant="body2" color="#212121" fontWeight={'bold'}>
                       Consultation Type
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {aptData.appointmentType.label}
                      </Typography>
                      <Typography variant="body2" color="#212121" fontWeight={'bold'}>
                        Symptoms
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {aptData.symptoms}
                      </Typography>
                      <Typography variant="body2" color="#212121" fontWeight={'bold'}>
                        Purpose
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {aptData.reasonForConsultation}
                      </Typography>
                      <Typography variant="body2" color="#212121" fontWeight={'bold'}>
                        Payment Status
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                      {aptData.feesPaid} 
                      </Typography>
                      <Typography variant="body2" color="#212121" fontWeight={'bold'}>
                        Appointment Fee
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {aptData.fees}
                      </Typography>
                      {user.userType === 'DOCTOR' ? 
                      <Button variant="outlined" sx={{margin:0.5,color:'#696CFF',borderColor:'#696CFF'}} fullWidth 
                      onClick={()=>{
                        getVideoURL(aptData.consultationId).then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            store.setState('appointmentData',{
                              aptId: aptData.consultationId,
                              doctorUUID: aptData.doctorUuId,
                              patientID: aptData.personUuId,
                              videoCallURL: res.data
                            })
                            setComponent('TeleCall')
                          }else{
                            toast("Couldn't Start Tele Call")
                          }
                        })
                        
                        }}
                        disabled={aptData.consultationStatus === 'completed' || props.showConsultation ? true : false}
                        >
                      Consultation
                    </Button> : <div></div>}
                    </CardContent>
                    <CardActions disableSpacing>
                    <Button variant="outlined" sx={{margin:0.5}} onClick={()=>{
                      console.log("data apt.....",aptData.consultationId,aptData.doctorUuId)
                      store.setState('appointmentData',{
                        aptId: aptData.consultationId,
                        doctorUUID: aptData.doctorUuId,
                        patientID: aptData.personUuId
                      })
                      setComponent('Reschedule')
                      }}
                      disabled={props.showReschedule}
                      >RESCHEDULE</Button>
                    <Button variant="outlined" sx={{margin:0.5}} color={'error'}
                    onClick={() => {
                      setComponent('Doctor Scheduling')
                      confirmAlert({
                        title: "Are you Sure you want to Cancel the Appointment",
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                              cancelAppointment(aptData.consultationId).then(
                                res =>{
                                  console.log(res)
                                  toast(res.message)
                                  setComponent('Doctor Scheduling')
                                  props.loadFunction('115,116,117')
                                }
                              )
                            }
                          },
                          {
                            label: 'NO',
                            onClick: () => {
                              setComponent('Doctor Scheduling')
                              props.loadFunction('115,116,117')
                            }
                          },
                        ],
                        closeOnEscape:false,
                        closeOnClickOutside:false,
                      })
                    }}
                    disabled={props.showCancel}
                    >Cancel</Button>
                </CardActions>
            </Card> : <div>Could'nt Fetch Data</div>}
          </>
          
    );
  }

export default PatientCard;