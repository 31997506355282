import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors, IconButton } from '@mui/material';
import logoUB from '../../../assets/images/logo_ub.png'
import FormControl from '@mui/material/FormControl';
import ForgotPasswordGetOTPLogic from './ForgotPasswordGetOTPLogic';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate} from 'react-router-dom';

const theme = createTheme();

export default function ForgotPasswordGetOTP() {

  const {showError,setError,userID,setUserID,getOTP,handleSubmit} = ForgotPasswordGetOTPLogic();
  let navigate = useNavigate();


  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={2}
          sm={2}
          md={3}
          sx={{
            bgcolor: '#651fff',
            display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
          }}>
            <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: 100, height: 100, padding:3}}
                variant = 'square'
              />
            <Typography component="h1" variant="h3" sx={{
              paddingTop:3, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              Delivering
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0,  
              color:'#eeeeee'}}>
              specialized
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0,
              color:'#eeeeee'}}>
              medical care
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              at home
            </Typography>
            <Typography component="h1" variant="h6" sx={{
              paddingTop:3, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              Palliative, Rehabilitative
            </Typography>
            <Typography component="h1" variant="h6" sx={{
              paddingTop:0, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              and Chronic Care
            </Typography>
          </Grid>
        <Grid item xs={10} sm={10} md={9} component={Paper} elevation={6} square 
        sx={{bgcolor:colors.grey[200]}}>
          <IconButton onClick={() =>{navigate('/')}}>
          <HomeIcon></HomeIcon>
          </IconButton>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: '25vh', height: '20vh', padding:2}}
                variant = 'square'
              />
            <Typography component="h1" variant="h5" sx={{fontWeight: 'bold'}}>
              Forgot Password
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, padding: 4 }}>
            <Typography component="h1" variant="h10">
             Enter User ID
            </Typography>
            <FormControl fullWidth>
            <TextField sx={{bgcolor:'#ffffff'}}
                margin="normal"
                required
                fullWidth
                id="mobileno"
                name="mobileno"
                autoComplete="mobileno"
                autoFocus
                type="string"
                value={userID}
                onInput={(e) =>{setError(false);}}
                onChange={(newValue) => setUserID(newValue.target.value)}
                error={showError === true}
                helperText={showError === true ? "Field can't be Empty" : ''}
              />  
            </FormControl>
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                onClick ={(e)=>{
                  if(userID === ""){
                    setError(true);
                  }else{
                    getOTP(e,userID)
                  }
                }}
                
              >
                Send OTP
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}