import * as React from 'react';
import { styled, useTheme, alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { colors,Paper } from '@mui/material';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import 'react-toastify/dist/ReactToastify.css';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import person_img from '../../../assets/images/person.jpg';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import prescription_img from '../../../assets/images/prescription_file.png';
import { toast } from 'react-toastify';
import ScheduleService from './ScheduleService';
import { confirmAlert } from 'react-confirm-alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import GroupIcon from '@mui/icons-material/Group'

function ScheduleLeave(props){

    const {addLeaves,getLeavesListbyUUID,getLeaveDetails,updateLeave,deleteLeaves} = ScheduleService();
    const [dcomponent, setDComponent] = React.useState('schedule');   
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    console.log("UUID......",props.doctorUUID)
    const handleListItemClick = (event,index) => {
        setSelectedIndex(index);
    };
    

    function ScheduleLeaves(props){
      const [startTime, setStartTime] = React.useState(null);
      const [endTime, setEndTime] = React.useState(null);
      const [startDate, setStartDate] = React.useState(null);
      const [endDate, setEndDate] = React.useState(null);
      const [description,setDescription] = React.useState('');
      const [errorText,setErrorText] = React.useState({
        startDate:{
          error:false,
          errorText:"Please Select Start Date"
        },
        startTime:{
          error:false,
          errorText:"Please Select Start Time"
        },
        endDate:{
          error:false,
          errorText:"Please Select End Date"
        },
        endTime:{
          error:false,
          errorText:"Please Select End Time"
        },
        
      })
      console.log("Add Leave UUID......",props.doctor_uuid)

      function handleValidation(){
        if(startDate === null || startDate !== null){
            if(startDate === null){
              setErrorText(previous => ({...previous,startDate:{error:true,
                errorText:"Please Select Start Date"}}))
                return true;
            }else if(startDate !== null){
              setErrorText(previous => ({...previous,startDate:{error:false,
                errorText:"Please Select Start Date"}}))
            }
          }
          if(startTime === null || startTime !== null){
            if(startTime === null){
              setErrorText(previous => ({...previous,startTime:{error:true,
                errorText:"Please Select Start Time"}}))
                return true;
            }else if(startTime !== null){
              setErrorText(previous => ({...previous,startTime:{error:false,
                errorText:"Please Select Start Time"}}))
            }
          }
          if(endDate === null || endDate !== null){
            if(endDate === null){
              setErrorText(previous => ({...previous,endDate:{error:true,
                errorText:"Please Select End Date"}}))
                return true;
            }else if(endDate !== null){
              setErrorText(previous => ({...previous,endDate:{error:false,
                errorText:"Please Select End Date"}}))
            }
          }
          if(endTime === null || endTime !== null){
            if(endTime === null){
              setErrorText(previous => ({...previous,endTime:{error:true,
                errorText:"Please Select End Time"}}))
                return true;
            }else if(endTime !== null){
              setErrorText(previous => ({...previous,endTime:{error:false,
                errorText:"Please Select End Time"}}))
                return false;
            }
          }
        
        
        console.log("RESULTS............");
      
        }
        
      return(
      <Grid item xs={10} md={10} lg={10}>
                  <Paper sx={{marginTop:1,paddingBottom:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>Schedule Leave</Typography>
                  </Paper>
                  <Paper sx={{marginTop:3,paddingBottom:2}}>
                  <Grid container spacing={2}>
                  <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Start Date</Typography>
                  <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Select Start Date"
                        inputFormat="MM/dd/yyyy"
                        value={startDate}
                        onChange={(newDate) => setStartDate(dayjs(newDate))}
                        renderInput={(params) => <TextField {...params} size = 'small'
                        helperText={errorText.startDate.error === true ? errorText.startDate.errorText:""}
                        error={errorText.startDate.error === true ? true : false}
                        autoFocus={errorText.startDate.error === true ? true : false}
                        />}
                      />
                  </LocalizationProvider> 
                            </FormControl>
                    </Grid>  
                  <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Start Time</Typography>
              <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopTimePicker
                          label="Select Start Time"
                          value={startTime}
                          onChange={(newValue) => {
                            setStartTime(newValue);
                          }}
                          renderInput={(params) => <TextField {...params} size='small' 
                          helperText={errorText.startTime.error === true ? errorText.startTime.errorText:""}
                          error={errorText.startTime.error === true ? true : false}
                          autoFocus={errorText.startTime.error === true ? true : false}
                          />}
                        />
                  </LocalizationProvider>
              </FormControl>
                    </Grid>
                  <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Select End Date</Typography>
                  <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                        label="Select End Date"
                        inputFormat="MM/dd/yyyy"
                        value={endDate}
                        onChange={(newDate) => setEndDate(dayjs(newDate))}
                        renderInput={(params) => <TextField {...params} size = 'small'
                        helperText={errorText.endDate.error === true ? errorText.endDate.errorText:""}
                        error={errorText.endDate.error === true ? true : false}
                        autoFocus={errorText.endDate.error === true ? true : false}
                        />}
                      />
                  </LocalizationProvider> 
                            </FormControl>
                    </Grid>
                  <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Select End Time</Typography>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopTimePicker
                              label="Select End Time"
                              value={endTime}
                              onChange={(newValue) => {
                                setEndTime(newValue);
                              }}
                              renderInput={(params) => <TextField {...params} size='small'
                              helperText={errorText.endTime.error === true ? errorText.endTime.errorText:""}
                              error={errorText.endTime.error === true ? true : false}
                              autoFocus={errorText.endTime.error === true ? true : false}
                              />}
                            />
                      </LocalizationProvider>
                            </FormControl>
                    </Grid>  
                  </Grid>
                  <Grid item xs={12} md={5} margin={2}>
                    <Typography color={colors.grey[900]} fontWeight={'bold'}>Description</Typography>
                      <TextField
                        autoComplete="given-name"
                        name="Description"
                        required
                        fullWidth
                        id="des"
                        label="Enter Description"
                        multiline
                        rows={2}
                        value={description}
                        onChange={(e) =>{setDescription(e.target.value)}}
                      />
                    </Grid> 
                  </Paper> 
                  <Paper sx={{marginTop:3,paddingBottom:1}}>
                  <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={12} md={6} margin={3}>
                      <Button variant="outlined" sx={{width:'40%',marginRight:2}}>
                          Clear
                        </Button>
                        <Button variant="contained" sx={{width:'40%',bgcolor:'#651fff'}}
                        onClick={()=>{
                          
                          var res = handleValidation();
                          if(res === false){
                              console.log("hi")
                              let startDateandTime = new Date(startDate.format('YYYY/MM/DD')+" " + startTime.format('hh:mm A'))
                          console.log("startDateandTime is",startDateandTime);
                          let endDateandTime = new Date(endDate.format('YYYY/MM/DD')+" " + endTime.format('hh:mm A'))
                          console.log("endDateandTime is",endDateandTime);
                          var startEpochTime = startDateandTime.getTime();
                          console.log("epoch startDate time",startEpochTime);
                          var endEpochTime = endDateandTime.getTime();
                          console.log("epoch endDate time",endEpochTime);
                          addLeaves(startEpochTime,endEpochTime,description,props.doctor_uuid).then(res =>{
                              console.log(res)
                              {res.status === 200 ? confirmAlert({
                                title: res.message,
                                buttons: [
                                  {
                                    label: 'Okay',
                                    onClick: () => {
                                      setDComponent('myleave')
                                    }
                                  },
                                ],
                                closeOnEscape:false,
                                closeOnClickOutside:false,
                              })
                            :toast(res.message)}
                          })
                          }else{
                            toast("Leave not Added, Please fill the details")
                          }
                        }}
                        >
                          Schedule
                        </Button>
                    </Grid>
                </Grid>
                  </Paper>
                  
          </Grid>
      );
    }
    
    function MyLeave(props){
      console.log(" Leave List UUID......",props.doctor_uuid)
      const [leaveList,setLeaveList] = React.useState([]);
      const [showLeaveList,setShowLeaveList] = React.useState(false);
      const [eventID, setEventID] = React.useState('');
      const [openPopup, setOpenPopup] = React.useState(false);

      const handleClosePopup = async () => {
        setOpenPopup(false);
        getLeavesListbyUUID(props.doctor_uuid).then(
          res =>{
            console.log("Leaves List",res)
            if(res.status === 200){
              setLeaveList(res.data)
              setShowLeaveList(true)
            }else{
              setShowLeaveList(false)
              toast(res.message)
            }
            
          }
        )
      };

      function UpdateLeave(){
        const [startTime, setStartTime] = React.useState(null);
        const [endTime, setEndTime] = React.useState(null);
        const [startDate, setStartDate] = React.useState(null);
        const [endDate, setEndDate] = React.useState(null);
        const [description,setDescription] = React.useState('');
        const [errorText,setErrorText] = React.useState({
          startDate:{
            error:false,
            errorText:"Please Select Start Date"
          },
          startTime:{
            error:false,
            errorText:"Please Select Start Time"
          },
          endDate:{
            error:false,
            errorText:"Please Select End Date"
          },
          endTime:{
            error:false,
            errorText:"Please Select End Time"
          },
          
        })
        console.log("Update Leave UUID......",props.doctor_uuid)
  
        function handleValidation(){
          if(startDate === null || startDate !== null){
              if(startDate === null){
                setErrorText(previous => ({...previous,startDate:{error:true,
                  errorText:"Please Select Start Date"}}))
                  return true;
              }else if(startDate !== null){
                setErrorText(previous => ({...previous,startDate:{error:false,
                  errorText:"Please Select Start Date"}}))
              }
            }
            if(startTime === null || startTime !== null){
              if(startTime === null){
                setErrorText(previous => ({...previous,startTime:{error:true,
                  errorText:"Please Select Start Time"}}))
                  return true;
              }else if(startTime !== null){
                setErrorText(previous => ({...previous,startTime:{error:false,
                  errorText:"Please Select Start Time"}}))
              }
            }
            if(endDate === null || endDate !== null){
              if(endDate === null){
                setErrorText(previous => ({...previous,endDate:{error:true,
                  errorText:"Please Select End Date"}}))
                  return true;
              }else if(endDate !== null){
                setErrorText(previous => ({...previous,endDate:{error:false,
                  errorText:"Please Select End Date"}}))
              }
            }
            if(endTime === null || endTime !== null){
              if(endTime === null){
                setErrorText(previous => ({...previous,endTime:{error:true,
                  errorText:"Please Select End Time"}}))
                  return true;
              }else if(endTime !== null){
                setErrorText(previous => ({...previous,endTime:{error:false,
                  errorText:"Please Select End Time"}}))
                  return false;
              }
            }
          
          
          console.log("RESULTS............");
        
          }
          
          React.useEffect(() => {
            getLeaveDetails(eventID).then(res =>{
              console.log(res)
              if(res.status === 200){
                setStartDate(dayjs(res.data.startDateAndTime.slice(0,10)))
                setEndDate(dayjs(res.data.endDateAndTime.slice(0,10)))
                setStartTime(new Date(res.data.startDateAndTime))
                setEndTime(new Date(res.data.endDateAndTime))
                setDescription(res.data.description)
                setEventID(res.data.eventId)
              }
            })
          },[])
          console.log(startDate,startTime,endDate,endTime,description)
        return(
          <div>
        <Dialog open={openPopup} onClose={handleClosePopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute',color:'white'}}>Reschedule Leave</DialogTitle>
            </Grid>
  
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:'5vh'}}/>
          <DialogContent>
          <Grid container spacing={2}>         
            <Grid item xs={12} md={5} margin={2}>
                    <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Start Date</Typography>
                    <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                          label="Select Start Date"
                          inputFormat="MM/dd/yyyy"
                          value={startDate}
                          onChange={(newDate) => {
                            console.log("Changed Date",newDate)
                            setStartDate(dayjs(newDate))}}
                          renderInput={(params) => <TextField {...params} size = 'small'
                          helperText={errorText.startDate.error === true ? errorText.startDate.errorText:""}
                          error={errorText.startDate.error === true ? true : false}
                          autoFocus={errorText.startDate.error === true ? true : false}
                          />}
                        />
                    </LocalizationProvider> 
                              </FormControl>
                      </Grid>  
            <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Start Time</Typography>
                <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                            label={"Select Start Time"}
                            value={startTime}
                            onChange={(newValue) => {
                              console.log("Changed Time",newValue)
                              setStartTime(newValue);
                            }}
                            renderInput={(params) => <TextField {...params} size='small' 
                            helperText={errorText.startTime.error === true ? errorText.startTime.errorText:""}
                            error={errorText.startTime.error === true ? true : false}
                            autoFocus={errorText.startTime.error === true ? true : false}
                            />}
                          />
                    </LocalizationProvider>
                </FormControl>
                      </Grid>
                    <Grid item xs={12} md={5} margin={2}>
                    <Typography color={colors.grey[900]} fontWeight={'bold'}>Select End Date</Typography>
                    <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                          label="Select End Date"
                          inputFormat="MM/dd/yyyy"
                          value={endDate}
                          onChange={(newDate) => setEndDate(dayjs(newDate))}
                          renderInput={(params) => <TextField {...params} size = 'small'
                          helperText={errorText.endDate.error === true ? errorText.endDate.errorText:""}
                          error={errorText.endDate.error === true ? true : false}
                          autoFocus={errorText.endDate.error === true ? true : false}
                          />}
                        />
                    </LocalizationProvider> 
                              </FormControl>
                      </Grid>
                    <Grid item xs={12} md={5} margin={2}>
                    <Typography color={colors.grey[900]} fontWeight={'bold'}>Select End Time</Typography>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopTimePicker
                                label="Select End Time"
                                value={endTime}
                                onChange={(newValue) => {
                                  setEndTime(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} size='small'
                                helperText={errorText.endTime.error === true ? errorText.endTime.errorText:""}
                                error={errorText.endTime.error === true ? true : false}
                                autoFocus={errorText.endTime.error === true ? true : false}
                                />}
                              />
                        </LocalizationProvider>
                              </FormControl>
                      </Grid>  
                    <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>Description</Typography>
                        <TextField
                          autoComplete="given-name"
                          name="Description"
                          required
                          fullWidth
                          id="des"
                          label="Enter Description"
                          multiline
                          rows={2}
                          value={description}
                          onChange={(e) =>{setDescription(e.target.value)}}
                        />
                      </Grid> 
                  
  
          </Grid>
          
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" sx={{width:'100%',margin:2}} color={'error'} onClick={handleClosePopup}>Cancel</Button>
            <Button variant="contained" sx={{width:'100%',bgcolor:'#651fff'}}
                          onClick={()=>{
                            var res = handleValidation();
                            if(res === false){
                                console.log("hi")
                            let timeStart = dayjs(startTime)
                            let timeEnd = dayjs(endTime)
                            let startDateandTime = new Date(startDate.format('YYYY/MM/DD')+ " " + timeStart.format('hh:mm A'))
                            console.log("startDateandTime is",startDateandTime);
                            let endDateandTime = new Date(endDate.format('YYYY/MM/DD')+ " " + timeEnd.format('hh:mm A'))
                            console.log("endDateandTime is",endDateandTime);
                            var startEpochTime = startDateandTime.getTime();
                            console.log("epoch startDate time",startEpochTime);
                            var endEpochTime = endDateandTime.getTime();
                            console.log("epoch endDate time",endEpochTime);
                            updateLeave(startEpochTime,endEpochTime,description,props.doctor_uuid,eventID).then(res =>{
                                console.log(res)
                                {res.status === 200 ? handleClosePopup() : toast(res.message)}
                                {res.status === 200 ? confirmAlert({
                                  title: res.message,
                                  buttons: [
                                    {
                                      label: 'Okay',
                                      onClick: () => {
                                        setDComponent('myleave')
                                      }
                                    },
                                  ],
                                  closeOnEscape:false,
                                  closeOnClickOutside:false,
                                  
                                })
                              :toast(res.message)}
                            })
                            }else{
                              toast("Leave not Added, Please fill the details")
                            }
                          }}
                          >
                            Reschedule
                          </Button>
          </DialogActions>
        </Dialog>
            </div>
        
        );
      }
      React.useEffect(() => {
        getLeavesListbyUUID(props.doctor_uuid).then(
          res =>{
            console.log("Leaves List",res)
            if(res.status === 200){
              setLeaveList(res.data)
              setShowLeaveList(true)
            }else{
              setShowLeaveList(false)
              toast(res.message)
            }
            
          }
        )
      },[])
      console.log("LIST DATA",leaveList)
      return(
        <Grid item xs={10} md={10} lg={10}>
            <Paper sx={{marginTop:1,paddingBottom:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>My Leave</Typography>
            </Paper>
            
            {showLeaveList === true ? 
                leaveList.map((leaves,index) =>(
            <Paper key= {leaves.eventId} sx={{marginTop:1,padding:2}}>
                <Grid container spacing={3} >
                  <Grid item xs={12} md={0.5}>
                    <Avatar src={prescription_img}></Avatar>
                  </Grid>
                <Grid item xs={12} md={9}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>
                    Leave {leaves.startDateAndTime.slice(0,10)} - {leaves.endDateAndTime.slice(0,10)}
                    </Typography>
                </Grid>

                <Grid item xs={12} md={1.25}>
                <Button variant="outlined" sx={{color:'#651fff',borderColor:'#651fff'}}
                  onClick={()=>{
                    confirmAlert({
                      title: "Are you sure you want to Delete ?",
                      buttons: [
                        {
                          label: 'Yes',
                          onClick: () => {
                            deleteLeaves(leaves.eventId).then(res =>{
                              console.log(res)
                              if(res.status === 200){
                                toast(res.message)
                                setDComponent('myleave')
                                getLeavesListbyUUID(props.doctor_uuid).then(
                                  res =>{
                                    console.log("Leaves List",res)
                                    if(res.status === 200){
                                      setLeaveList(res.data)
                                      setShowLeaveList(true)
                                    }else{
                                      setShowLeaveList(false)
                                      toast(res.message)
                                    }
                                    
                                  }
                                )
                              }else{
                                toast(res.message)
                              }
                            })
                            
                          }
                        },{
                          label: 'No',
                          onClick: () => {
                            setDComponent('myleave')
                          }
                        },
                      ],
                      closeOnEscape:false,
                      closeOnClickOutside:false,
                      
                    })
                  }}
                >
                  Delete
                </Button>
                </Grid>

                <Grid item xs={12} md={1.25}>
                <Button variant="contained" sx={{bgcolor:'#651fff'}}
                  onClick={() => {
                    setEventID(leaves.eventId)
                    setOpenPopup(true)
                  }}
                >
                  Edit
                </Button>
                </Grid>
                
              </Grid>

              <Grid container spacing={5} >
                <Grid item xs={12} md={2}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Start Date</Typography>
                <Typography paddingLeft={4} color={colors.grey[900]}>{leaves.startDateAndTime.slice(0,10)}</Typography>                
                </Grid>
                <Grid item xs={12} md={2}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Start Time</Typography>
                        <Typography paddingLeft={4} color={colors.grey[900]}>{leaves.startDateAndTime.slice(11,16)}</Typography>                
                </Grid>
                <Grid item xs={12} md={2}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>End Date</Typography>
                <Typography paddingLeft={4} color={colors.grey[900]}>{leaves.endDateAndTime.slice(0,10)}</Typography>                
                </Grid>
                <Grid item xs={12} md={2}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>End Time</Typography>
                        <Typography paddingLeft={4} color={colors.grey[900]}>{leaves.endDateAndTime.slice(11,16)}</Typography>                
                </Grid>
                <Grid item xs={12} md={2}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Description</Typography>
                <Typography paddingLeft={4} color={colors.grey[900]}>{leaves.description}</Typography>                
                </Grid>
                            
              </Grid>
                    </Paper>
                )):<Paper sx={{margin:5,padding:5}}>
                <Typography align='center'>No Data Available</Typography>
                  </Paper> }
            {openPopup === true ? <UpdateLeave/>:<div></div>}  
        </Grid>
      );
    }

    
    return(
      <Grid container spacing={2}>
                <Grid item xs={2} md={2} lg={2}>
                <Typography padding={0.5} fontWeight={'bold'} fontSize={18}>Schedule Leave</Typography>
                    <Paper>             
                    <Box display='center' justifyContent="center" padding={2}>
                      <Avatar alt="Remy Sharp" src={props.profileImage} sx={{width:100,height:100}} style={{alignSelf:'center'}}/>
                    </Box>
                    <Typography fontWeight={'bold'} align={'center'}>{props.doctorName}</Typography>
                    <Typography align={'center'} color={colors.grey[500]} fontSize={14}>MBBS.{props.speciality}</Typography>
                    <Typography align={'center'} color={colors.grey[500]} fontSize={14} paddingBottom={1}>Cardiologist</Typography>
                       </Paper>
                       <Paper sx={{marginTop:1,borderRadius:3}}>
                        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <List component="nav" aria-label="main mailbox folders"
                        sx={{
                            // selected and (selected + hover) states
                            '&& .Mui-selected, && .Mui-selected:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                            // hover states
                            '& .MuiListItemButton-root:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                          }}
                        >
                            <ListItemButton
                            selected={selectedIndex === 0}
                            onClick={(event) => {
                              handleListItemClick(event, 0)
                              setDComponent('schedule')
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Schedule Leave" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton>
                            <ListItemButton
                            selected={selectedIndex === 1}
                            onClick={(event) => {
                              handleListItemClick(event, 1)
                              setDComponent('myleave')
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="My Leave" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton>

                        </List>
                        </Box>
                    </Paper>
                </Grid>
                {dcomponent === 'schedule' ? <ScheduleLeaves doctor_uuid = {props.doctorUUID}/> : <div></div>}
                {dcomponent === 'myleave' ? <MyLeave doctor_uuid = {props.doctorUUID}/> : <div></div>}
                
            </Grid>
    );
  }

export default ScheduleLeave;