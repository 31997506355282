import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import { colors,Paper } from '@mui/material';
import { ViewState } from '@devexpress/dx-react-scheduler';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import List from '@mui/material/List';
import {
  Scheduler,
  DayView,
  WeekView,
  Appointments,
  Toolbar,
  ViewSwitcher,
  DateNavigator,
  TodayButton,
  AppointmentTooltip,
  MonthView
} from '@devexpress/dx-react-scheduler-material-ui';
import { Icon, IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import store from '../../../context/StatePoolService';
import PatientCard from '../PatientsCard/PatientsCardUI';
import ScheduleService from './ScheduleService';
import dayjs from 'dayjs';
import AdminService from '../../AdminDashboard/AdminService';
import { toast } from 'react-toastify';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';

const Appointment = ({
  children,style, ...restProps
}) => (
  <Appointments.Appointment
    {...restProps}
    style={{
      ...style,
      backgroundColor: '#FFC107',
      borderRadius: '8px',
      padding: '15px 0'
    }}
    
  >
    
    {children}
  </Appointments.Appointment>
);


const Content = (({
  children, appointmentData, ...restProps
}) => (
  <AppointmentTooltip.Content {...restProps} 
  appointmentData={appointmentData} style={{height:'50vh'}}>
    <PatientCard consultationid = {appointmentData.consultationId}/>
  </AppointmentTooltip.Content>
));



function Calendar(){

const {getAppointmentList} = ScheduleService();
const {getDoctorsList,getDoctorDetails} = AdminService();
const [doctorList, setDoctorList] = React.useState([]);
const [schedulerData,setSchedulerData] = React.useState([]);
const [formattedSchedulerData,setFormattedSchedulerData] = React.useState([]);
const [doctorName, setDoctorName] = React.useState('');
const [doctorUUID, setDoctorUUID] = React.useState('');
const [doctorProfileImage, setDoctorProfileImage] = React.useState('');
const [doctorSpeciality, setDoctorSpeciality] = React.useState('');
const [user, setUser] = store.useState("user");
const [component, setComponent] = store.useState('componentState');
const [componentDetails, setComponentDetails] = store.useState('componentDetails');
const [openPopup, setOpenPopup] = React.useState(false);
const [showList,setShowList] = React.useState(false);
const [dateToday,setDate] = React.useState(dayjs().toISOString().slice(0,10));
const [monthStartDate, setMonthStartDate] = React.useState()
const [monthEndDate, setMonthEndDate] = React.useState()
const [weekEndDate, setWeekEndDate] = React.useState()
const [apt_Type,setAptType] = React.useState("115,116,117")
const [viewType,setViewType] = React.useState('Month')
const [aptCardColor,setAptCardColor] = React.useState('#4dabf5')
const [refreshScheduler, setRefreshScheduler] = React.useState(true)
const [showScheduler, setShowScheduler] = React.useState(true)
const [stateData, setStateData] = React.useState({
  bookapt: false,
  reschedule: false,
  cancel: false,
  view: false,
  consultation: false,
  scheduleLeave: false
})

console.log("date is",dateToday)
console.log("word is",viewType)
console.log("NAV DATA",componentDetails)

const handlTypeChange = (event) => {
  setAptType(event.target.value);
};
const renderState = () =>{
  componentDetails.children.map((detail,index) =>{
    console.log("STATES",detail)
    if(detail.label === 'Book Appointment' && detail.checked === false){
      setStateData(prevState => ({
        ...prevState,
        bookapt: true
     }))
    }
    if(detail.label === 'Reschedule Appointment' && detail.checked === false){
      setStateData(prevState => ({
        ...prevState,
        reschedule: true
     }))
    }
    if(detail.label === 'Cancel Appointment' && detail.checked === false){
      setStateData(prevState => ({
        ...prevState,
        cancel: true
     }))
    }
    if(detail.label === 'View Appointment Details' && detail.checked === false){
      setStateData(prevState => ({
        ...prevState,
        view: true
     }))
    }
    if(detail.label === 'Consultation' && detail.checked === false){
      setStateData(prevState => ({
        ...prevState,
        consultation: true
     }))
    }
    if(detail.label === 'Schedule Leave' && detail.checked === false){
      setStateData(prevState => ({
        ...prevState,
        scheduleLeave: true
     }))
    }
  })
}

const loadData = async (appointment_type) => {
  {user.userType === 'OrgSuperAdmin' || user.userType === 'ADMIN' ? 
  await getDoctorsList(user.orgID,'a').then(res =>{
    console.log("DOCTOR LIST",res)
  if(res.status === 200){
    if(res.data !== []){
        if(doctorName === '' && doctorUUID == ''){
          setDoctorList(res.data.content);
          setDoctorName(res.data.content[0].prefix + " " + res.data.content[0].name)
          setDoctorUUID(res.data.content[0].uuId)
          setDoctorProfileImage(res.data.content[0].profileImage)
          setDoctorSpeciality(res.data.content[0].speciality)
          store.setState("schedulerData",{
            doctorName: res.data.content[0].prefix + " " + res.data.content[0].name,
            doctorUUID: res.data.content[0].uuId,
            profileImage: res.data.content[0].profileImage,
            speciality: res.data.content[0].speciality
          })
          if(viewType.toUpperCase() === 'MONTH'){
            setShowScheduler(false)
            setRefreshScheduler(false)
            let date = new Date(dateToday);
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            console.log("FIRST DAY",firstDay)
            console.log("LAST DAY",lastDay)
            getAppointmentList(res.data.content[0].uuId,appointment_type,
              dayjs(firstDay).format('YYYY-MM-DD'),dayjs(lastDay).format('YYYY-MM-DD'),viewType.toUpperCase())
              .then(res =>{
                if(res.status === 200){
                  console.log("Appointment Data",res)
                  setSchedulerData(res.data)
                  setShowScheduler(true)
                  setRefreshScheduler(true)
                }else{
                  setSchedulerData([])
                  console.log(res.message)
                  setRefreshScheduler(true)
                }
                
              });
          }else if(viewType.toUpperCase() === 'WEEK'){
            setShowScheduler(false)
            setRefreshScheduler(false)
            let weekStartDate = new Date(dateToday)
            weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay());
            let weekEndDate = new Date(dateToday);
            weekEndDate.setDate(weekEndDate.getDate() - weekEndDate.getDay() + 6);
            getAppointmentList(res.data.content[0].uuId,appointment_type,
              dayjs(weekStartDate).format('YYYY-MM-DD'),dayjs(weekEndDate).format('YYYY-MM-DD'),viewType.toUpperCase())
              .then(res =>{
                if(res.status === 200){
                  console.log("Appointment Data",res)
                  setSchedulerData(res.data)
                  setShowScheduler(true)
                  setRefreshScheduler(true)
                }else{
                  setSchedulerData([])
                  console.log(res.message)
                  setRefreshScheduler(true)
                }
              });
            console.log("weekEndDate........",weekEndDate)
          }else if(viewType.toUpperCase() === 'DAY'){
            setShowScheduler(false)
            setRefreshScheduler(false)
            console.log("dateToday........",dateToday)
            getAppointmentList(res.data.content[0].uuId,appointment_type,
              dateToday,dateToday,viewType.toUpperCase())
              .then(res =>{
                if(res.status === 200){
                  console.log("Appointment Data",res)
                  setSchedulerData(res.data)
                  setShowScheduler(true)
                  setRefreshScheduler(true)
                }else{
                  setSchedulerData([])
                  console.log(res.message)
                  setRefreshScheduler(true)
                }
              });
          }
        }else{
          store.setState("schedulerData",{
            doctorName: doctorName,
            doctorUUID: doctorUUID,
            profileImage: doctorProfileImage,
            speciality: doctorSpeciality
          })
          if(viewType.toUpperCase() === 'MONTH'){
            setShowScheduler(false)
            setRefreshScheduler(false)
            let date = new Date(dateToday);
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
            var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            console.log("FIRST DAY",firstDay)
            console.log("LAST DAY",lastDay)
            getAppointmentList(doctorUUID,appointment_type,
              dayjs(firstDay).format('YYYY-MM-DD'),dayjs(lastDay).format('YYYY-MM-DD'),viewType.toUpperCase())
              .then(res =>{
                if(res.status === 200){
                  console.log("Appointment Data",res)
                  setSchedulerData(res.data)
                  setShowScheduler(true)
                  setRefreshScheduler(true)
                }else{
                  setSchedulerData([])
                  console.log(res.message)
                  setRefreshScheduler(true)
                }
                
              });
          }else if(viewType.toUpperCase() === 'WEEK'){
            setShowScheduler(false)
            setRefreshScheduler(false)
            let weekStartDate = new Date(dateToday)
            weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay());
            let weekEndDate = new Date(dateToday);
            weekEndDate.setDate(weekEndDate.getDate() - weekEndDate.getDay() + 6);
            getAppointmentList(doctorUUID,appointment_type,
              dayjs(weekStartDate).format('YYYY-MM-DD'),dayjs(weekEndDate).format('YYYY-MM-DD'),viewType.toUpperCase())
              .then(res =>{
                if(res.status === 200){
                  console.log("Appointment Data",res)
                  setSchedulerData(res.data)
                  setShowScheduler(true)
                  setRefreshScheduler(true)
                }else{
                  setSchedulerData([])
                  console.log(res.message)
                  setRefreshScheduler(true)
                }
              });
            console.log("weekEndDate........",weekEndDate)
          }else if(viewType.toUpperCase() === 'DAY'){
            setShowScheduler(false)
            setRefreshScheduler(false)
            console.log("dateToday........",dateToday)
            getAppointmentList(doctorUUID,appointment_type,
              dateToday,dateToday,viewType.toUpperCase())
              .then(res =>{
                if(res.status === 200){
                  console.log("Appointment Data",res)
                  setSchedulerData(res.data)
                  setShowScheduler(true)
                  setRefreshScheduler(true)
                }else{
                  setSchedulerData([])
                  console.log(res.message)
                  setRefreshScheduler(true)
                }
              });
          }
        }
      
      
      
      
    }
  }else{
    console.log(res.message)
  }
    
  }): await getDoctorDetails(user.personID).then(
    res => {
    console.log("DOCTOR PERSONAL DETAILS",res)
    setDoctorName(res.prefix + " " + res.givenName + " " + res.middleName)
    setDoctorUUID(res.uuid)
    store.setState("schedulerData",{
      doctorName: res.prefix + " " + res.givenName + " " + res.middleName,
      doctorUUID: res.uuid,
      profileImage: res.profileImage,
      speciality: "General Medicine"
    })
    if(viewType.toUpperCase() === 'MONTH'){
      setShowScheduler(false)
      setRefreshScheduler(false)
      let date = new Date(dateToday);
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      console.log("FIRST DAY",firstDay)
      console.log("LAST DAY",lastDay)
      getAppointmentList(res.uuid,appointment_type,
        dayjs(firstDay).format('YYYY-MM-DD'),dayjs(lastDay).format('YYYY-MM-DD'),viewType.toUpperCase())
        .then(res =>{
          if(res.status === 200){
            console.log("Appointment Data",res)
            setSchedulerData(res.data)
            setShowScheduler(true)
            setRefreshScheduler(true)
          }else{
            setSchedulerData([])
            console.log(res.message)
            setRefreshScheduler(true)
          }
          
        });
    }else if(viewType.toUpperCase() === 'WEEK'){
      setShowScheduler(false)
      setRefreshScheduler(false)
      let weekStartDate = new Date(dateToday)
      weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay());
      let weekEndDate = new Date(dateToday);
      weekEndDate.setDate(weekEndDate.getDate() - weekEndDate.getDay() + 6);
      getAppointmentList(res.uuid,appointment_type,
        dayjs(weekStartDate).format('YYYY-MM-DD'),dayjs(weekEndDate).format('YYYY-MM-DD'),viewType.toUpperCase())
        .then(res =>{
          if(res.status === 200){
            console.log("Appointment Data",res)
            setSchedulerData(res.data)
            setShowScheduler(true)
            setRefreshScheduler(true)
          }else{
            setSchedulerData([])
            console.log(res.message)
            setRefreshScheduler(true)
          }
        });
      console.log("weekEndDate........",weekEndDate)
    }else if(viewType.toUpperCase() === 'DAY'){
      setShowScheduler(false)
      setRefreshScheduler(false)
      console.log("dateToday........",dateToday)
      getAppointmentList(res.uuid,appointment_type,
        dateToday,dateToday,viewType.toUpperCase())
        .then(res =>{
          if(res.status === 200){
            console.log("Appointment Data",res)
            setSchedulerData(res.data)
            setShowScheduler(true)
            setRefreshScheduler(true)
          }else{
            setSchedulerData([])
            console.log(res.message)
            setRefreshScheduler(true)
          }
        });
    }
  }
  )

}
}
  React.useEffect(() => {
    renderState()
    loadData(apt_Type);
  },[])
  console.log("stateData",stateData)
 return (
  <Grid item xs={10} md={10} lg={10}>
        <Grid container spacing={1} marginBottom={1}>
          <Grid item xs={12} md={4.5} >
          <Typography fontSize={20} fontWeight={'bold'} >Doctor Scheduling</Typography>
          </Grid>
          {user.userType === 'OrgSuperAdmin' || user.userType === 'ADMIN' ? 
          <Grid item xs={5} md={2.5}>
                <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.name){
                          setDoctorName(newValue.prefix + " " + newValue.name)
                          setDoctorUUID(newValue.uuId)
                          setDoctorProfileImage(newValue.profileImage)
                          setDoctorSpeciality(newValue.speciality)
                          store.setState("schedulerData",{
                            doctorName: newValue.prefix + " " + newValue.name,
                            doctorUUID: newValue.uuId,
                            profileImage: newValue.profileImage,
                            speciality: newValue.speciality,
                            orgID: newValue.orgId
                          })
                          if(viewType.toUpperCase() === 'MONTH'){
                            setShowScheduler(false)
                                let date = new Date(dateToday);
                                var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                                console.log("FIRST DAY",firstDay)
                                console.log("LAST DAY",lastDay)
                                getAppointmentList(newValue.uuId,apt_Type,
                                dayjs(firstDay).format('YYYY-MM-DD'),dayjs(lastDay).format('YYYY-MM-DD'),viewType.toUpperCase())
                                .then(res =>{
                                  if(res.status === 200){
                                    console.log("Appointment Data",res)
                                    setSchedulerData(res.data)
                                    setShowScheduler(true)
                                  }else{
                                    console.log(res.message)
                                  }
                                });
                          }else if(viewType.toUpperCase() === 'WEEK'){
                            setShowScheduler(false)
                            let weekStartDate = new Date(dateToday)
                            weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay());
                            let weekEndDate = new Date(dateToday);
                            weekEndDate.setDate(weekEndDate.getDate() - weekEndDate.getDay() + 6);
                                  getAppointmentList(newValue.uuId,apt_Type,
                                    dayjs(weekStartDate).format('YYYY-MM-DD'),dayjs(weekEndDate).format('YYYY-MM-DD'),viewType.toUpperCase())
                                  .then(res =>{
                                    if(res.status === 200){
                                      console.log("Appointment Data",res)
                                      setSchedulerData(res.data)
                                      setShowScheduler(true)
                                    }else{
                                      console.log(res.message)
                                    }
                                  });
                                console.log("weekEndDate........",weekEndDate)
                          }else if(viewType.toUpperCase() === 'DAY'){
                            setShowScheduler(false)
                                console.log("dateToday........",dateToday)
                                getAppointmentList(newValue.uuId,apt_Type,
                                dateToday,dateToday,viewType.toUpperCase())
                                .then(res =>{
                                  if(res.status === 200){
                                    console.log("Appointment Data",res)
                                    setSchedulerData(res.data)
                                    setShowScheduler(true)
                                  }else{
                                    console.log(res.message)
                                  }
                                });
                          }
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={doctorList}
                      value={doctorName}
                      getOptionLabel={(option) => option.name || doctorName}
                      isOptionEqualToValue={(option, value) => option.name === value}
                      renderInput={(params) => <TextField {...params} label="Select Doctor" />}
                    />
                  </FormControl>
          </Grid> : <Grid item xs={5} md={2.5}></Grid>} 
          <Grid item xs={5} md={2.5}>
            <Button variant="outlined" sx={{width:'100%',borderColor:'#651fff'}} startIcon={<AddIcon/>} onClick={()=>{
              setComponent('ScheduleLeave')
              }}
              disabled={stateData.scheduleLeave}
              >
                    Schedule Leave
                  </Button>
          </Grid>
          <Grid item xs={5} md={2.5}>
              <Button variant="contained" sx={{width:'100%',bgcolor:'#651fff'}} startIcon={<AddIcon/>} onClick={()=>{
              setComponent('NewAppointment')
              setShowList(true)
              }}
              disabled={stateData.bookapt}
              >
                    New Appointment
                  </Button>
          </Grid>
        </Grid>
        
      <Grid container spacing={1} marginBottom={2}>
      <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="controlled-radio-buttons-group"
        value={apt_Type}
        onChange={handlTypeChange}
      >
        <FormControlLabel value="115" control={<Radio sx={{
          color: '#C3C4FF',
          '&.Mui-checked': {
            color: '#C3C4FF',
          },
        }} 
        onClick={() =>{
          setAptCardColor('#C3C4FF')
          loadData("115")
        }}
        />} 
        label="Tele - Consultation"
        
        />
        <FormControlLabel value="116" control={<Radio sx={{
          color: '#566A7F',
          '&.Mui-checked': {
            color: '#566A7F',
          },
        }} onClick={() =>{
          setAptCardColor('#566A7F')
          loadData("116")
        }}
        />} label="Clinic Walkin" />
        <FormControlLabel value="117" control={<Radio sx={{
          color: '#FFAB00',
          '&.Mui-checked': {
            color: '#FFAB00',
          },
        }}
        onClick={() =>{
          setAptCardColor('#FFAB00')
          loadData("117")
        }}
        />} label="In - Care" />
        <FormControlLabel value="115,116,117" control={<Radio onClick={() =>{
          setAptCardColor('#4dabf5')
          loadData("115,116,117")
        }}/>} label="All" />
        <FormControlLabel
          value="disabled"
          disabled
          control={<Radio />}
          label="Holidays & Festivals"
        />
        <FormControlLabel
          value="disabled"
          disabled
          control={<Radio />}
          label="Past Appointments"
        />
      </RadioGroup>
    </FormControl>

      </Grid>
        
   <Paper>
    {showScheduler === false ?<Paper sx={{margin:1,padding:1}}>
                <Typography align='center' sx={{color:'red'}}>No Data Available</Typography>
                  </Paper> : null}
        {refreshScheduler === true ? 
        <Scheduler
          data={schedulerData}
          height={500}
        >
          <ViewState
            defaultCurrentDate={dateToday}
            defaultCurrentViewName = {viewType}
            onCurrentViewNameChange = {(name) =>{
              setViewType(name)
          if(name.toUpperCase() === 'MONTH'){
            setShowScheduler(false)
                let date = new Date(dateToday);
                var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                console.log("FIRST DAY",firstDay)
                console.log("LAST DAY",lastDay)
                getAppointmentList(doctorUUID,apt_Type,
                dayjs(firstDay).format('YYYY-MM-DD'),dayjs(lastDay).format('YYYY-MM-DD'),name.toUpperCase())
                .then(res =>{
                  if(res.status === 200){
                    console.log("Appointment Data",res)
                    setSchedulerData(res.data)
                    setShowScheduler(true)
                  }else{
                    console.log(res.message)
                  }
                });
          }else if(name.toUpperCase() === 'WEEK'){
            setShowScheduler(false)
                let weekStartDate = new Date(dateToday)
                weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay());
                let weekEndDate = new Date(dateToday);
                weekEndDate.setDate(weekEndDate.getDate() - weekEndDate.getDay() + 6);
                  getAppointmentList(doctorUUID,apt_Type,
                    dayjs(weekStartDate).format('YYYY-MM-DD'),dayjs(weekEndDate).format('YYYY-MM-DD'),name.toUpperCase())
                  .then(res =>{
                    if(res.status === 200){
                      console.log("Appointment Data",res)
                      setSchedulerData(res.data)
                      setShowScheduler(true)
                    }else{
                      console.log(res.message)
                    }
                  });
                console.log("weekEndDate........",weekEndDate)
          }else if(name.toUpperCase() === 'DAY'){
            setShowScheduler(false)
                console.log("dateToday........",dateToday)
                getAppointmentList(doctorUUID,apt_Type,
                dateToday,dateToday,name.toUpperCase())
                .then(res =>{
                  if(res.status === 200){
                    console.log("Appointment Data",res)
                    setSchedulerData(res.data)
                    setShowScheduler(true)
                  }else{
                    console.log(res.message)
                  }
                });
          }
            }}
            onCurrentDateChange = {(newDate) => {
              console.log("New date is",dayjs(newDate).format('YYYY-MM-DD'))
              setDate(dayjs(newDate).format('YYYY/MM/DD'))
              if(viewType.toUpperCase() === 'MONTH'){
                setShowScheduler(false)
                let date = new Date(newDate);
                console.log("New Date is",newDate)
                var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                console.log("FIRST DAY",firstDay)
                console.log("LAST DAY",lastDay)
                getAppointmentList(doctorUUID,apt_Type,
                dayjs(firstDay).format('YYYY-MM-DD'),dayjs(lastDay).format('YYYY-MM-DD'),viewType.toUpperCase())
                .then(res =>{
                  if(res.status === 200){
                    console.log("Appointment Data",res)
                    setSchedulerData(res.data)
                    setShowScheduler(true)
                  }else{
                    console.log(res.message)
                  }
                });
          }else if(viewType.toUpperCase() === 'WEEK'){
            setShowScheduler(false)
            let weekStartDate = new Date(newDate)
            weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay());
            let weekEndDate = new Date(newDate);
            weekEndDate.setDate(weekEndDate.getDate() - weekEndDate.getDay() + 6);
                  getAppointmentList(doctorUUID,apt_Type,
                    dayjs(weekStartDate).format('YYYY-MM-DD'),dayjs(weekEndDate).format('YYYY-MM-DD'),viewType.toUpperCase())
                  .then(res =>{
                    if(res.status === 200){
                      console.log("Appointment Data",res)
                      setSchedulerData(res.data)
                      setShowScheduler(true)
                    }else{
                      console.log(res.message)
                    }
                  });
                console.log("weekEndDate........",weekEndDate)
          }else if(viewType.toUpperCase() === 'DAY'){
            setShowScheduler(false)
                console.log("New Date is",dayjs(newDate).format('YYYY-MM-DD'))
                getAppointmentList(doctorUUID,apt_Type,
                  dayjs(newDate).format('YYYY-MM-DD'),dayjs(newDate).format('YYYY-MM-DD'),viewType.toUpperCase())
                .then(res =>{
                  if(res.status === 200){
                    console.log("Appointment Data",res)
                    setSchedulerData(res.data)
                    setShowScheduler(true)
                  }else{
                    console.log(res.message)
                  }
                });
          }
            }
            }
          />
          <WeekView
            startDayHour={0}
            endDayHour={24}
            cellDuration={15}
            
          />
          
          <DayView 
          startDayHour={0}
          endDayHour={24}
          cellDuration={15}
          />
          <MonthView></MonthView>
          <Toolbar />
          <DateNavigator />
          <TodayButton />
          <ViewSwitcher />
          
          <Appointments
            appointmentComponent={({
              children,style, ...restProps
            }) => (
              <Appointments.Appointment
                {...restProps}
                style={{
                  ...style,
                  backgroundColor: aptCardColor,
                  borderRadius: '8px',
                  padding: '5px 0'
                }}
                >  
              {children}
              </Appointments.Appointment>)} 
        />

            
          {viewType.toUpperCase() === 'WEEK' || viewType.toUpperCase() === 'DAY' ?
          <AppointmentTooltip
            contentComponent={({
              children, appointmentData, ...restProps
            }) => (
              <AppointmentTooltip.Content {...restProps} 
              appointmentData={appointmentData} style={{height:'50vh'}}>
                {stateData.view === false ? <PatientCard consultationid = {appointmentData.consultationId} loadFunction={loadData} showReschedule={stateData.reschedule} showCancel={stateData.cancel} showConsultation={stateData.consultation}/> : null }
              </AppointmentTooltip.Content>
            )}
            showCloseButton
          />:<></>}
        </Scheduler>:null}
      </Paper>
    </Grid>
    
);
  
}
export default Calendar;