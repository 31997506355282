import * as React from 'react';
import { styled, useTheme, alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { colors,Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import DraftsIcon from '@mui/icons-material/Drafts';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminService from '../AdminService';
import { useNavigate} from 'react-router-dom';
import store from '../../../context/StatePoolService';
import Helper from '../../../Helper/Helper';
import dayjs from 'dayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FileUploader } from "react-drag-drop-files";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SignatureCanvas from 'react-signature-canvas';

const {containsOnlyNumbers,containsSpecialChars,
  validateEmail,checkPassword} = Helper();
  
function AdminDetailUpdate(){
  const [user, setUser] = store.useState("user");
  const [personID,setPersonID] = store.useState('personID');
  const [doctorUUID,setDoctorUUID] = store.useState('doctorUUID');
  const {GetGenderDetails,getAddressList,mapUsertoOrg,GetMasterData,GetMasterSearchData,registerAdmin,
  getOrgList,getAdminDetails,updateAdminDetail} = AdminService();
  const [dcomponent, setDComponent] = React.useState('basic');
  const [component, setComponent] = store.useState('componentState');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  console.log("person id is",personID)
  console.log("doctor id is",doctorUUID)
  
  
  function BasicDetails(){
  const [genderList, setGenderList] = React.useState([]);
  const [addressList, setAddressList] = React.useState([]);
  const [roleList,setRoleList] = React.useState([]);
  const [partnerList,setPartnerList] = React.useState([]);
  const [departmentList,setDepartmentList] = React.useState([]);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [mobileno, setMobileNum] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [address1, setAddress1] = React.useState('');
  const [userId,setUserID] = React.useState('');
  const [password,setPassword] = React.useState('');
  const [confirmPassword,setConfirmPassword] = React.useState('');
  const [role,setRole] = React.useState('');
  const [roleID,setRoleID] = React.useState('');
  const [partner,setPartner] = React.useState('Select Partner');
  const [partnerID,setPartnerID] = React.useState('');
  const [department,setDepartment] = React.useState('');
  const [pincode, setPincode] = React.useState('');
  const [state, setState] = React.useState('Select State');
  const [city, setCity] = React.useState('Select City');
  const [country, setCountry] = React.useState('Select Country');

  const [errorText,setErrorText] = React.useState({
    firstName:{
      error:false,
      errorText:"Field cant be empty",
      verified:true
    },
    lastName:{
      error:false,
      errorText:"Field cant be empty",
      verified:true
    },
    
    pincode:{
      error:false,
      errorText:"Select an option from DropDown",
      verified:true
    },
    addr1:{
      error:false,
      errorText:"Field can't be empty",
      verified:true
    },
    
    email:{
      error:false,
      errorText:"Field can't be empty",
      verified:true
    },
    phone:{
      error:false,
      errorText:"Field can't be empty",
      verified:true
    },
    userId:{
      error:false,
      errorText:"Field can't be empty",
      verified:true
    },
    password:{
      error:false,
      errorText:"Field can't be empty",
      verified:true
    },
    confirmPassword:{
      error:false,
      errorText:"Field can't be empty",
      verified:true
    },
    role:{
      error:false,
      errorText:"Select an option from DropDown",
      verified:true
    },
    partner:{
      error:false,
      errorText:"Select an option from DropDown",
      verified:true
    },
    department:{
      error:false,
      errorText:"Select an option from DropDown",
      verified:true
    },

  })

  function handleValidation(){
  
  if(firstName === "" || firstName !== ""){
        if(firstName === ""){
          setErrorText(previous => ({...previous,firstName:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
            
        }else if(containsSpecialChars(firstName) === true){
          setErrorText(previous => ({...previous,firstName:{error:true,
            errorText:"Field cannot contain Special Characters",
            verified:false}}))
            return true;
        }else if(containsOnlyNumbers(firstName) === true){
          setErrorText(previous => ({...previous,firstName:{error:true,
            errorText:"Field cannot contain Numbers",
            verified:false}}))
            return true;
        }else if(firstName !== ""){
          setErrorText(previous => ({...previous,firstName:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))

        }       
    }

  if(lastName === "" || lastName !== ""){
      if(lastName === ""){
        setErrorText(previous => ({...previous,lastName:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
      }else if(containsSpecialChars(lastName) === true){
        setErrorText(previous => ({...previous,lastName:{error:true,
          errorText:"Field cannot contain Special Characters",
          verified:false}}))
          return true;
      }else if(containsOnlyNumbers(lastName) === true){
        setErrorText(previous => ({...previous,lastName:{error:true,
          errorText:"Field cannot contain Numbers",
          verified:false}}))
          return true;
      }else if(lastName !== ""){
        setErrorText(previous => ({...previous,lastName:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
        
      }         
    }

  if(address1 === '' || address1 !==''){
      if(address1 === ''){
        setErrorText(previous => ({...previous,addr1:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
      }else if(address1 !== ''){
        setErrorText(previous => ({...previous,addr1:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
      }
    }

  if(pincode === '' || pincode !==''){
      if(pincode === ''){
        setErrorText(previous => ({...previous,pincode:{error:true,
          errorText:"Select an option from DropDown",
          verified:false}}))
          return true;
      }else if(pincode !== ''){
        setErrorText(previous => ({...previous,pincode:{error:false,
          errorText:"Select an option from DropDown",
          verified:true}}))
      }
    }
  
  // if(userId === "" || userId !== ""){
  //     if(userId === ""){
  //       setErrorText(previous => ({...previous,userId:{error:true,
  //         errorText:"Field can't be empty",
  //         verified:false}}))
  //         return true;
  //     }else if(containsSpecialChars(userId) === true){
  //       setErrorText(previous => ({...previous,userId:{error:true,
  //         errorText:"Field cannot contain Special Characters",
  //         verified:false}}))
  //         return true;
  //     }else if(containsOnlyNumbers(userId) === true){
  //       setErrorText(previous => ({...previous,userId:{error:true,
  //         errorText:"Field cannot contain Numbers",
  //         verified:false}}))
  //         return true;
  //     }else if(userId !== ""){
  //       setErrorText(previous => ({...previous,userId:{error:false,
  //         errorText:"Field can't be empty",
  //         verified:true}}))

  //     }       
  //   }

  // if( password === "" || password !== ""){
  //   if(password === ""){
  //     setErrorText(previous => ({...previous,password:{error:true,
  //       errorText:"Field can't be empty",
  //       verified:false}}))
  //       return true;
  //   }else if(containsOnlyNumbers(password) === true){
  //     setErrorText(previous => ({...previous,password:{error:true,
  //       errorText:"Field cannot contain Numbers",
  //       verified:false}}))
  //       return true;
  //   }else if(password.length < 8){
  //     setErrorText(previous => ({...previous,password:{error:true,
  //       errorText:"Minimum Length of Password must be 8 characters",
  //       verified:false}}))
  //       return true;
  //   }else if(checkPassword(password) === false){
  //     setErrorText(previous => ({...previous,password:{error:true,
  //       errorText:"Password does not match Criteria: One UpperCase,lowercase,number and special character",
  //       verified:false}}))
  //       return true;
  //   }else if(password !== confirmPassword){
  //     setErrorText(previous => ({...previous,password:{error:true,
  //       errorText:"Password does not match",
  //       verified:false}}))
  //       return true;
  //   }else if(password !== ""){
  //     setErrorText(previous => ({...previous,password:{error:false,
  //       errorText:"Field can't be empty",
  //       verified:true}}))
  //     }       
  //   }

  // if( confirmPassword === "" || confirmPassword !== ""){
  //   if(confirmPassword === ""){
  //     setErrorText(previous => ({...previous,confirmPassword:{error:true,
  //       errorText:"Field can't be empty",
  //       verified:false}}))
  //       return true;
  //   }else if(containsOnlyNumbers(confirmPassword) === true){
  //     setErrorText(previous => ({...previous,confirmPassword:{error:true,
  //       errorText:"Field cannot contain Numbers",
  //       verified:false}}))
  //       return true;
  //   }else if(confirmPassword.length < 8){
  //     setErrorText(previous => ({...previous,confirmPassword:{error:true,
  //       errorText:"Minimum Length of Password must be 8 characters",
  //       verified:false}}))
  //       return true;
  //   }else if(checkPassword(confirmPassword) === false){
  //     setErrorText(previous => ({...previous,confirmPassword:{error:true,
  //       errorText:"Password does not match Criteria: One UpperCase,lowercase,number and special character",
  //       verified:false}}))
  //       return true;
  //   }else if(password !== confirmPassword){
  //     setErrorText(previous => ({...previous,confirmPassword:{error:true,
  //       errorText:"Password does not match",
  //       verified:false}}))
  //       return true;
  //   }else if(confirmPassword !== ""){
  //     setErrorText(previous => ({...previous,confirmPassword:{error:false,
  //       errorText:"Field can't be empty",
  //       verified:true}}))
  //   }       
  //   }

  if(mobileno === "" || mobileno !== ""){
      if(mobileno === ""){
        setErrorText(previous => ({...previous,phone:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
      }else if(mobileno.length < 10){
        setErrorText(previous => ({...previous,phone:{error:true,
          errorText:"Minimum length should be 10 digits",
          verified:false}}))
          return true;
      }else if(containsSpecialChars(mobileno) === true){
        setErrorText(previous => ({...previous,phone:{error:true,
          errorText:"Field cannot contain Special Characters",
          verified:false}}))
          return true;
      }else if(containsOnlyNumbers(mobileno) === true && mobileno !== ""){
        setErrorText(previous => ({...previous,phone:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
          
      }       
    }

  if(email === "" || email !== ""){
      if(email === ""){
        setErrorText(previous => ({...previous,email:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
      }else if(containsOnlyNumbers(email) === true){
        setErrorText(previous => ({...previous,email:{error:true,
          errorText:"Field cannot contain only Numbers",
          verified:false}}))
          return true;
      }else if(validateEmail(email) === false){
        setErrorText(previous => ({...previous,email:{error:true,
          errorText:"Email is not valid should contain '@' and '.'",
          verified:false}}))
          return true;
      }else if(email !== ""){
        setErrorText(previous => ({...previous,email:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
          
      }        
    }
  
  
  if(role === '' || role !==''){
    if(role === ''){
      setErrorText(previous => ({...previous,role:{error:true,
        errorText:"Select an option from DropDown",
        verified:false}}))
        return true;
    }else if(role !== ''){
      setErrorText(previous => ({...previous,role:{error:false,
        errorText:"Select an option from DropDown",
        verified:true}}))
      }
    }

  if(partner === '' || partner !==''){
      if(partner === ''){
        setErrorText(previous => ({...previous,partner:{error:true,
          errorText:"Select an option from DropDown",
          verified:false}}))
          return true;
      }else if(partner !== ''){
        setErrorText(previous => ({...previous,partner:{error:false,
          errorText:"Select an option from DropDown",
          verified:true}}))
          return false
        }
    }

  if(department === '' || department !==''){
      if(department === ''){
        setErrorText(previous => ({...previous,department:{error:true,
          errorText:"Select an option from DropDown",
          verified:false}}))
          return true;
      }else if(department !== ''){
        setErrorText(previous => ({...previous,department:{error:false,
          errorText:"Select an option from DropDown",
          verified:true}}))
          return false
        }
    }
  
  console.log("RESULTS............");
  
}
  
  
    // console.log("IS ERROR status",isError)
    console.log("Error Body",errorText)
    console.log("Error status",errorText.firstName.error)
    console.log("Error Message",errorText.firstName.errorText)
    console.log("GenderLISTttttt",genderList)
    console.log("id",doctorUUID)
    console.log("OrgList",partnerList)
    console.log("DeptList",departmentList)
  React.useEffect(() => {
    GetGenderDetails().then(res => setGenderList(res));
    getAddressList().then(res => setAddressList(res.content));
    GetMasterData('PRIVILEDGE_TYPE').then(res => setRoleList(res));
    GetMasterSearchData('ADMIN_DEPARTMENT').then(res => setDepartmentList(res));
    getOrgList().then(res =>{
      if(res.status === 200){
        setPartnerList(res.data);
      }else{
        setPartnerList([]);
      }
    })
    getAdminDetails(personID).then(res =>{
        console.log("admin details",res)
        setFirstName(res.givenName)
        setLastName(res.middleName)
        setAddress1(res.address[0].address1)
        setPincode(res.address[0].postalCode)
        setCity(res.address[0].city)
        setState(res.address[0].state)
        setCountry(res.address[0].country)
        setUserID(res.userName)
        setEmail(res.email)
        setMobileNum(res.phoneNumber)
        setRole(res.role.label)
        setPartner(res.partner)
        setPartnerID(res.partner)
        setRoleID(res.role.typeID)
    })
  },[])
    
    return(
    <Grid item xs={9} md={9} lg={9}>
      <Typography padding={0.5} fontWeight={'bold'}>Personal Details</Typography>
        <Paper sx={{marginTop:1,paddingBottom:1}}>
          <Typography paddingLeft={2} paddingTop={2} color={colors.grey[500]} fontSize={14}>NAME</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>First Name</Typography>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      fullWidth
                      id="firstName"
                      label="Enter First Name"
                      size='small'
                      helperText={errorText.firstName.error === true ? errorText.firstName.errorText:""}
                      error={errorText.firstName.error === true ? true : false}
                      autoFocus={errorText.firstName.error === true ? true : false}
                    />
                </Grid>
              <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Last Name</Typography>
                    <TextField
                      required
                      fullWidth
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      id="lastName"
                      label="Enter Last Name"
                      name="lastName"
                      autoComplete="family-name"
                      size='small'
                      helperText={errorText.lastName.error === true ? errorText.lastName.errorText:""}
                      error={errorText.lastName.error === true ? true : false}
                      autoFocus={errorText.lastName.error === true ? true : false}
                    />
                </Grid>
              </Grid>
          </Paper>

        <Paper sx={{marginTop:1}}>
          <Typography paddingLeft={2} paddingTop={2} color={colors.grey[500]} fontSize={14}>ADDRESS</Typography>
          <Grid container spacing={2}>
          <Grid item xs={12} md={10.35} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Full Address</Typography>
                <TextField
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  autoComplete="given-name"
                  name="addr1"
                  required
                  fullWidth
                  id="addr1"
                  label="Enter Address"
                  size='small'
                  multiline
                  rows={3}
                  helperText={errorText.addr1.error === true ? errorText.addr1.errorText:""}
                  error={errorText.addr1.error === true ? true : false}
                  autoFocus={errorText.addr1.error === true ? true : false}
                />
              </Grid>
          </Grid>
          
          <Grid container spacing={2}>
      <Grid item xs={12} md={5} margin={2}>
      <Typography color={colors.grey[900]} fontWeight={'bold'}>Pincode</Typography>
            <FormControl fullWidth>
                  <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.postalCode){
                        setPincode(newValue.postalCode)
                        setCity(newValue.district)
                        setState(newValue.state);
                        setCountry(newValue.country);
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={addressList}
                    value={pincode}
                    getOptionLabel={(option) => option.postalCode || pincode}
                    renderInput={(params) => <TextField {...params} label="Enter PinCode" 
                      helperText={errorText.pincode.error === true ? errorText.pincode.errorText:""}
                      error={errorText.pincode.error === true ? true : false}
                      autoFocus={errorText.pincode.error === true ? true : false}
                    />}
                  />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={5} margin={2}>
            <Typography color={colors.grey[900]} fontWeight={'bold'}>City</Typography>
            <FormControl fullWidth>
                  <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.district){
                        setCity(newValue.district);
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={addressList}
                    getOptionLabel={(option) => option.district || ""}
                    renderInput={(params) => <TextField {...params} label={city}/>}

                  />
                </FormControl>           
            </Grid>
            </Grid>
          <Grid container spacing={2}>
      <Grid item xs={12} md={5} margin={2}>
      <Typography color={colors.grey[900]} fontWeight={'bold'}>State</Typography>
            <FormControl fullWidth>
                  <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.state){
                        setState(newValue.state);
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={addressList}
                    getOptionLabel={(option) => option.state}
                    renderInput={(params) => <TextField {...params} label={state} />}

                  />
                </FormControl>
            </Grid>

            <Grid item xs={12} md={5} margin={2}>
            <Typography color={colors.grey[900]} fontWeight={'bold'}>Country</Typography>
            <FormControl fullWidth>
                <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.country){
                        setCountry(newValue.country);
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={addressList}
                    getOptionLabel={(option) => option.country}
                    renderInput={(params) => <TextField {...params} label={country} value={country}/>}

                  />
                </FormControl>
            </Grid>
            </Grid> 
          </Paper>

        
        <Paper sx={{marginTop:1}}>
                <Typography paddingLeft={2} paddingTop={2} color={colors.grey[500]} fontSize={14}>LOGIN DETAILS</Typography>
                
                <Grid container spacing={2}>
            <Grid item xs={12} md={5} margin={2}>
            
                <Typography color={colors.grey[900]} fontWeight={'bold'}>User ID</Typography>
              <TextField
                required
                fullWidth
                value = {userId}
                onChange={(e) => setUserID(e.target.value)}
                id="languageskn"
                label="Enter User ID"
                name="lang"
                autoComplete="family-name"
                size='small'
                disabled
                helperText={errorText.userId.error === true ? errorText.userId.errorText:""}
                error={errorText.userId.error === true ? true : false}
                autoFocus={errorText.userId.error === true ? true : false}
              />
            </Grid>
            <Grid item xs={12} md={5} margin={2}>
            </Grid>
                    </Grid>
                  <Grid container spacing={2}>
            <Grid item xs={12} md={5} margin={2}>
            <Typography color={colors.grey[900]} fontWeight={'bold'}>Password</Typography>
              <TextField
                required
                fullWidth
                value = {password}
                disabled
                onChange={(e) => setPassword(e.target.value)}
                id="languageskn"
                label="Enter Password"
                name="lang"
                autoComplete="family-name"
                size='small'
                helperText={errorText.password.error === true ? errorText.password.errorText:""}
                error={errorText.password.error === true ? true : false}
                autoFocus={errorText.password.error === true ? true : false}
              />
            </Grid>
            <Grid item xs={12} md={5} margin={2}>
            <Typography color={colors.grey[900]} fontWeight={'bold'}>Confirm Password</Typography>
              <TextField
                required
                fullWidth
                value = {confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                id="languageskn"
                disabled
                label="Confirm Password"
                name="lang"
                autoComplete="family-name"
                size='small'
                helperText={errorText.confirmPassword.error === true ? errorText.confirmPassword.errorText:""}
                error={errorText.confirmPassword.error === true ? true : false}
                autoFocus={errorText.confirmPassword.error === true ? true : false}
              />
            </Grid>
                   </Grid>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Mobile Number</Typography>
                  <TextField
                    value={mobileno}
                    onChange={(e) => setMobileNum(e.target.value)}
                    autoComplete="given-name"
                    name="mobilenum"
                    required
                    fullWidth
                    id="mobilenum"
                    label="Enter Mobile Number"
                    size='small'
                    type="number"
                    onInput = {(e) =>{
                      e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                    }}
                    helperText={errorText.phone.error === true ? errorText.phone.errorText:""}
                    error={errorText.phone.error === true ? true : false}
                    autoFocus={errorText.phone.error === true ? true : false}
                  />
                </Grid>
              <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Email Address</Typography>
                  <TextField
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    fullWidth
                    id="email"
                    label="Enter Email"
                    name="email"
                    autoComplete="family-name"
                    size='small'
                    helperText={errorText.email.error === true ? errorText.email.errorText:""}
                    error={errorText.email.error === true ? true : false}
                    autoFocus={errorText.email.error === true ? true : false}
                  />
              </Grid>
                    </Grid>
                  <Grid container spacing={2}>
            <Grid item xs={12} md={5} margin={2}>
            <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Role</Typography>
            <FormControl fullWidth>
                  <Autocomplete
                  onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                      setRoleID(newValue.typeID)
                      setRole(newValue.label)
                    }}}
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={roleList}
                  value={role}
                  getOptionLabel={(option) => option.label || role}
                  renderInput={(params) => <TextField {...params} label="Select Role"
                  helperText={errorText.role.error === true ? errorText.role.errorText:""}
                  error={errorText.role.error === true ? true : false}
                  autoFocus={errorText.role.error === true ? true : false}
                  />}
                />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={5} margin={2}>
            <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Partner</Typography>
            <FormControl fullWidth>
                  <Autocomplete
                  onChange = {(event, newValue) => {if(newValue && newValue.orgId){
                      setPartner(newValue.orgId)}}}
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={partnerList}
                  getOptionLabel={(option) => `${option.orgname} [${option.orgId}]`}
                  renderInput={(params) => <TextField {...params} label={partner}
                  helperText={errorText.role.error === true ? errorText.role.errorText:""}
                  error={errorText.role.error === true ? true : false}
                  autoFocus={errorText.role.error === true ? true : false}
                  />}
                />
                </FormControl>
            </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={5} margin={2}>
                    <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Department</Typography>
                    <FormControl fullWidth>
                          <Autocomplete
                          onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                              setDepartment(newValue.typeID)}}}
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={departmentList}
                          getOptionLabel={(option) => option.label}
                          renderInput={(params) => <TextField {...params} label="Select Department"
                          helperText={errorText.role.error === true ? errorText.role.errorText:""}
                          error={errorText.role.error === true ? true : false}
                          autoFocus={errorText.role.error === true ? true : false}
                          />}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={5} margin={2}>
                    </Grid>
                  </Grid>
          </Paper>

        <Paper sx={{marginTop:1}}>
              <Grid item xs={12} md={12} margin={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                    onClick = {() => {
                    
                    var res = handleValidation();
                    if(res === false){
                      updateAdminDetail(personID,userId,password,firstName,lastName,
                        email,mobileno,address1,city,state,country,
                        pincode,roleID,partner,department).then(res =>{
                          console.log("Result After Reg calling MAP,,,,,",res)
                          
                         {res.status === 200 ? confirmAlert({
                          title: res.message,
                          buttons: [
                            {
                              label: 'Okay',
                              onClick: () => setComponent('Admin Management')
                            }
                          ],
                          closeOnEscape:false,
                          closeOnClickOutside:false,
                        })
                      :toast("Error Occured")}
                          
                        })
                    }else{
                      toast("Form not Saved, Please fill the Details")
                    }
                    
                    }}
                  >
                    Save
                  </Button>
            </Grid>
          </Paper>
        </Grid>
    
    );
  }
    return(
      <Grid container spacing={2}>
                <Grid item xs={2} md={2} lg={2}>
                <Typography padding={0.5} fontWeight={'bold'}>Admin Registration</Typography>
                       <Paper sx={{marginTop:1,borderRadius:3}}>
                        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <List component="nav" aria-label="main mailbox folders"
                        sx={{
                            // selected and (selected + hover) states
                            '&& .Mui-selected, && .Mui-selected:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                            // hover states
                            '& .MuiListItemButton-root:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                          }}
                        >
                            <ListItemButton
                            selected={selectedIndex === 0}
                            onClick={(event) => {
                              
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Basic Details" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton>

                        </List>
                        </Box>
                    </Paper>
                </Grid>
                {dcomponent === 'basic' ? <BasicDetails/> : <div></div>}
            </Grid>
    );
  }

export default AdminDetailUpdate;