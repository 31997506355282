import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '@mui/material';
import logoUB from '../../assets/images/logo_ub.png'
import { useNavigate} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import PreRegService from './PreRegistrationService';

const theme = createTheme();

export default function VerifyOTP() {

  let navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [otp,setOTP] = React.useState("");
  const {generateOTPForReg,validateOTPForReg} = PreRegService();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
   
  };


  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh'}}>
        <CssBaseline />
        <Grid
          item
          xs={2}
          sm={2}
          md={3}
          sx={{
            bgcolor: '#651fff',
            display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
          }}>
            <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: 100, height: 100, padding:3}}
                variant = 'square'
              />
            <Typography component="h1" variant="h3" sx={{
              paddingTop:3, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              Delivering
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0,  
              color:'#eeeeee'}}>
              specialized
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0,
              color:'#eeeeee'}}>
              medical care
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              at home
            </Typography>
            <Typography component="h1" variant="h6" sx={{
              paddingTop:3, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              Palliative, Rehabilitative
            </Typography>
            <Typography component="h1" variant="h6" sx={{
              paddingTop:0, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              and Chronic Care
            </Typography>
          </Grid>
        <Grid item xs={10} sm={10} md={9} component={Paper} elevation={6} square 
        sx={{bgcolor:colors.grey[200]}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: '25vh', height: '20vh', padding:2}}
                variant = 'square'
              />
            <Typography component="h1" variant="h5" sx={{fontWeight: 'bold'}}>
              Pre Registration
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, padding: 4 }}>
            <Typography component="h1" variant="h10">
              Enter OTP
            </Typography>
              <TextField sx={{bgcolor:'#ffffff'}}
                margin="normal"
                required
                fullWidth
                id="verifyotp"
                name="verifyotp"
                type="number"
                onChange={(e) =>{setOTP(e.target.value)}}
                onInput = {(e) =>{
                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
                }}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                onClick ={()=>{
                  if(otp !== '' || otp != null || otp !== ""){
                    validateOTPForReg(otp).then(res =>{
                      console.log(res)
                      if(res.status === 200){
                        toast.success(res.message)
                          const data = {
                            number: state.number,
                          };
                          navigate('/preRegForm',{ state: data })
                      }else{
                        toast.error(res.message)
                      }
                    })
                  }else{
                    toast.error("Please check the Entered OTP")
                  }
                }}
                
              >
                Verify OTP
              </Button>
              <Grid container spacing={4}>
                <Grid item md>
                  <Link  variant="body2" onClick={() =>{
                    console.log('number',state.number)
                    generateOTPForReg(state.number).then(res =>{
                      console.log("otp generated",res)
                      if(res.status === 200){
                        toast(res.message)
                      }
                    })
                  }}>
                    Did'nt Recieve OTP ? Resend OTP
                  </Link>
                </Grid>
              </Grid>
              <Link href="#" variant="body2" onClick={()=>{navigate('/preReg')}} >
                    Not {state.number}? Change Number
                  </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}