import * as React from 'react';
import Grid from '@mui/material/Grid';
import { colors,Icon,Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import Helper from '../../../Helper/Helper';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import person_img from '../../../assets/images/person.jpg';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import PatientCard from '../PatientsCard/PatientsCardUI';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Iframe from 'react-iframe';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import store from '../../../context/StatePoolService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate} from 'react-router-dom';
import ReactPDF, { PDFViewer } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet,Image, Svg, Line} from '@react-pdf/renderer';
import NABHLogo from '../../../assets/images/pdf_image.png';
import UbiLogo from '../../../assets/images/pdf_logo.png';
import EncounterService from './EncounterService';
import ScheduleService from '../DoctorScheduling/ScheduleService';
import { confirmAlert } from 'react-confirm-alert';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { FileUploader } from "react-drag-drop-files";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SignatureCanvas from 'react-signature-canvas';
import FaceSheet from '../PatientsCard/FaceSheet';
import AdminService from '../../AdminDashboard/AdminService';
import { Autocomplete } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  heading: {
    margin: 0,
    padding: 0,
    flexGrow: 1
  },
  logo: {
    margin: 1,
    padding: 1,
    flexGrow: 1
  },
  table: { 
    display: "table", 
    width: "100%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderRightWidth: 0, 
    borderBottomWidth: 0 
  }, 
  tableRow: { 
    width:"100%",
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol: { 
    width: "25%", 
    borderStyle: "solid", 
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCell: { 
    margin: "auto", 
    marginTop: 5, 
    fontSize: 10 
  },
  line:{
    border:'1px solid black',
    borderRadius: '1px'
  },
  footerLine:{
    border:'1px solid black',
    borderRadius: '1px',
    position:'absolute',
    top:800
  },
  pageNumber:{
    position:'absolute',
    fontSize:8,
    bottom:0,
    left:0,
    right:0,
    textAlign:'center',
    color:'grey'
  }

});


const {Tab,TabPanel,TabsList,generateString,toBase64} = Helper();

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 55,
    border: `2px solid white`,
    padding: '0 4px',
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function TeleCallComponent(props){
    
    let navigate = useNavigate();
    const {GetMasterData,fileUpload} = AdminService()
    const {getDetailsByAptId,cancelAppointment,getUpcomingApt} = ScheduleService();
    const {createClinicalNote,vitalDetails,vitalList,updateVitals,deleteVitalByID,getMedicineList,getLabTestNameList,MasterDataEncounterSearch} = EncounterService();
    const [openComplaints, setComplaintState] = React.useState(false);
    const [openHistory, setHistoryState] = React.useState(false);
    const [openLabOrders, setLabOrdersState] = React.useState(false);
    const [openTreatmentPlan, setTreatmentPlanState] = React.useState(false);
    const [openOtherDetails, setOtherDetailsState] = React.useState(false);
    const [openDoctorSignature, setDoctorSignatureState] = React.useState(false);
    const [openTriage, setTriageState] = React.useState(false);
    const [openSymptomsPopup, setSymptomsOpenPopup] = React.useState(false);
    const [openVitalsPopup, setVitalsOpenPopup] = React.useState(false);
    const [openPrescriptionPopup, setPrescriptionOpenPopup] = React.useState(false);
    const [openInstructionPopup, setInstructionOpenPopup] = React.useState(false);
    const [openPdfViewPopup, setPdfViewOpenPopup] = React.useState(false);
    const [openFaceSheet, setFaceSheetOpenPopup] = React.useState(false);
    const [openLabTestPopup, setLabTestPopup] = React.useState(false);
    const [clinincalNote,setClinincalNote,updateClinincalNote] = store.useState('clinicalNote');
    const [showSymptoms, setShowSymptoms] = React.useState(false);
    const [showVitals, setShowVitals] = React.useState(false);
    const [showPrescriptions, setShowPrescriptions] = React.useState(false);
    const [showLabTest, setShowLabTest] = React.useState(false);
    const [showInstruction, setShowInstruction] = React.useState(false);
    const [isSymptomSaved, setIsSymptomSaved] = React.useState(false);
    const [isVitalSaved, setIsVitalSaved] = React.useState(false);
    const [isPrescriptionSaved, setIsPrescriptionSaved] = React.useState(false);
    const [isLabTestSaved, setIsLabTestSaved] = React.useState(false);
    const [isAdditionalInstSaved, setIsAdditionalInstSaved] = React.useState(false);
    const [user, setUser] = store.useState("user");
    const [patientData, setPatientData] = React.useState();
    const [upcomingAptData, setUpcomingAptData] = React.useState([]);
    const [showUpcomingApt,setShowUpcomingApt] =React.useState(false);
    const [showTeleUI,setShowTeleUI] =React.useState(false);
    console.log("this is called",clinincalNote);
    const [component, setComponent] = store.useState('componentState');
    const [showVitalPopup, setShowVitalPopup] = React.useState(false);
    const [showVitalDetailsPopup, setShowVitalDetailsPopup] = React.useState(false);
    const [editVitalID,setEditVitalID] = React.useState('')
    const fileTypes = ["JPG", "PNG", "GIF","JPEG"];
    const [signatureURL, setSignatureURL] = React.useState(null);
    const [signaturePreview,setSignaturePreview] = React.useState(false);
    
    
    const handleHistoryVitalsClosePopup = () => {
        setShowVitalPopup(false);
      };

    const handleVitalDetailsClosePopup = () => {
      setShowVitalDetailsPopup(false);
      };
      
    const handleSignatureChange = async (newFile) => {
      setSignatureURL(null)
      setSignatureURL(await toBase64(newFile));
      setSignaturePreview(true)
    };

    
    async function  getClinincalData(){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", user.token);

      var raw = "";

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${user.baseUrl}getClinicalNote/${props.consultationId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          if(result.status === 200){
            console.log("inside If")
            store.setState("clinicalNote", {
              cheifComplaint: result.data.cc, 
              symptoms:{
                covidSymptoms:[
                  {label:"Fever",value:result.data.symptoms.fever},
                  {label:"Cough",value:result.data.symptoms.cough},
                  {label:"Cold",value:result.data.symptoms.cold},
                  {label:"Sore Throat",value:result.data.symptoms.sore_throt},
                  {label:"Breathing Difficulty",value:result.data.symptoms.breathing_difficulty},
                  {label:"Persistent pain/pressure in the chest",value:result.data.symptoms.pain},
                  {label:"Mental confusion or inability to arouse",value:result.data.symptoms.confusion},
                  {label:"Slurred speech",value:result.data.symptoms.speech},
                  {label:"Seizures",value:result.data.symptoms.seizures},
                  {label:"Weakness or numbness in any limb or face",value:result.data.symptoms.weakness},
                  {label:"Developing bluish discolorations of lips/face",value:result.data.symptoms.face}
                ],
                generalSymptoms:[
                  {label:"Becoming Unconscious",value:result.data.symptoms.unconcious},
                  {label:"Chills",value:result.data.symptoms.chills},
                  {label:"Fatigue",value:result.data.symptoms.fatique},
                  {label:"Headache",value:result.data.symptoms.headec},
                  {label:"Loss of Appetite",value:result.data.symptoms.appetite},
                  {label:"Very low body temperature",value:result.data.symptoms.tempreture}
                ],
                cvsSymptoms:[
                  {label:"Irregular heartbeat",value:result.data.symptoms.heartbeat},
                  {label:"Palpitations",value:result.data.symptoms.palpitations},
                  {label:"Swelling in limbs",value:result.data.symptoms.limb_swelling},
                  {label:"Unexplained bleeding or bruising",value:result.data.symptoms.bleeding}
                ],
                respiratorySymptoms:[
                  {label:"Wheezing",value:result.data.symptoms.wheezing}
                ],
                nsSymptoms:[
                  {label:"Loss of balance",value:result.data.symptoms.balance_loss}
                ],
                muskeloskeletal:[
                  {label:"Muscle / Joint pains",value:result.data.symptoms.joint_pain}
                ],
                gitSymptoms:[
                  {label:"Abdominal pain and swelling",value:result.data.symptoms.git_pain},
                  {label:"Changes in bowel / urinary pattern",value:result.data.symptoms.git_bowel},
                  {label:"Diarrhoea",value:result.data.symptoms.git_diarrhoea},
                  {label:"Difficulty swallowing",value:result.data.symptoms.git_swallowing},
                  {label:"Nausea or vomiting",value:result.data.symptoms.git_vomiting},
                  {label:"Persistent indigestion or discomfort after eating",value:result.data.symptoms.git_eating_discomfort},
                  {label:"Skin and eyes that appear yellowish (jaundice)",value:result.data.symptoms.git_jaundice}
                ],
                urinarySymptoms:[
                  {label:"Blood in the urine",value:result.data.symptoms.urine_blood},
                  {label:"Dark urine color",value:result.data.symptoms.urine_color},
                  {label:"Decreased urine output",value:result.data.symptoms.urine_output}
                ],
                skinSymptoms:[
                  {label:"Itchy skin",value:result.data.symptoms.skin_itchy},
                  {label:"Rash/Kawasaki Rash",value:result.data.symptoms.skin_rash}
                ],
                eyeSymptoms:[
                  {label:"Blurred vision",value:result.data.symptoms.eye_blurred_vision},
                  {label:"Loss of vision",value:result.data.symptoms.eye_vision_loss}
                ]
              },
              HPI: result.data.hpi,
              vitals:{
                height:result.data.vitals.height,
                weight:result.data.vitals.weight,
                temperature:result.data.vitals.temperature,
                spO2:result.data.vitals.SPO2,
                pulse:result.data.vitals.pulse,
                bloodPressure:(result.data.vitals.systolic) / (result.data.vitals.diastolic) ,
                systolic:result.data.vitals.systolic,
                diastolic:result.data.vitals.diastolic,
                grbs:result.data.vitals.grbs,
                hunit:result.data.vitals.hunit,
                wunit:result.data.vitals.wunit,
                tempunit:result.data.vitals.tempunit,
                respiratoryRate:result.data.vitals.respiratoryrate,
          
              },
              labOrders:result.data.lab_order,
              lab_tests:result.data.lab_tests,
              prescription:result.data.prescriptions,
              treatmentPlan:result.data.treatment_plan,
              otherDetails:result.data.other_details,
              additionalDetails:result.data.add_instruction,
              doctorSignature:result.data.signature,
              triage:result.data.triage,   
          });
            setIsSymptomSaved(true);
            setShowTeleUI(true)
          }else{
            console.log("called in else")
             
          store.setState("clinicalNote", {
            cheifComplaint: "", 
            symptoms:{
              covidSymptoms:[
                {label:"Fever",value:0},
                {label:"Cough",value:0},
                {label:"Cold",value:0},
                {label:"Sore Throat",value:0},
                {label:"Breathing Difficulty",value:0},
                {label:"Persistent pain/pressure in the chest",value:0},
                {label:"Mental confusion or inability to arouse",value:0},
                {label:"Slurred speech",value:0},{label:"Seizures",value:0},
                {label:"Weakness or numbness in any limb or face",value:0},
                {label:"Developing bluish discolorations of lips/face",value:0}
              ],
              generalSymptoms:[
                {label:"Becoming Unconscious",value:0},
                {label:"Chills",value:0},
                {label:"Fatigue",value:0},
                {label:"Headache",value:0},
                {label:"Loss of Appetite",value:0},
                {label:"Very low body temperature",value:0}
              ],
              cvsSymptoms:[
                {label:"Irregular heartbeat",value:0},
                {label:"Palpitations",value:0},
                {label:"Swelling in limbs",value:0},
                {label:"Unexplained bleeding or bruising",value:0}
              ],
              respiratorySymptoms:[
                {label:"Wheezing",value:0}
              ],
              nsSymptoms:[
                {label:"Loss of balance",value:0}
              ],
              muskeloskeletal:[
                {label:"Muscle / Joint pains",value:0}
              ],
              gitSymptoms:[
                {label:"Abdominal pain and swelling",value:0},
                {label:"Changes in bowel / urinary pattern",value:0},
                {label:"Diarrhoea",value:0},
                {label:"Difficulty swallowing",value:0},
                {label:"Nausea or vomiting",value:0},
                {label:"Persistent indigestion or discomfort after eating",value:0},
                {label:"Skin and eyes that appear yellowish (jaundice)",value:0}
              ],
              urinarySymptoms:[
                {label:"Blood in the urine",value:0},
                {label:"Dark urine color",value:0},
                {label:"Decreased urine output",value:0}
              ],
              skinSymptoms:[
                {label:"Itchy skin",value:0},
                {label:"Rash/Kawasaki Rash",value:0}
              ],
              eyeSymptoms:[
                {label:"Blurred vision",value:0},
                {label:"Loss of vision",value:0}
              ]
            },
            HPI: "",
            vitals:{
              height:"",
              weight:"",
              temperature:"",
              spO2:"",
              pulse:"",
              bloodPressure:"",
              systolic:"",
              diastolic:"",
              grbs:"",
              hunit:"",
              wunit:"",
              tempunit:"",
              respiratoryRate:"",
        
            },
            labOrders:"",
            lab_tests:[],
            prescription:[],
            treatmentPlan:"",
            otherDetails:"",
            additionalDetails:[],
            doctorSignature:props.doctorSignature,
            triage:"",   
        });
        setShowTeleUI(true)
        setSignaturePreview(true)        
          }
        })
        
      .catch(error => console.log('error', error));
    }

    const handleSymptomsClosePopup = () => {
      setSymptomsOpenPopup(false);
    };
    const handleVitalsClosePopup = () => {
      setVitalsOpenPopup(false);
    };
    const handlePrescriptionClosePopup = () => {
      setPrescriptionOpenPopup(false);
    };
    const handleInstructionClosePopup = () => {
      setInstructionOpenPopup(false);
    };

    const handlePdfViewClosePopup = () => {
      setPdfViewOpenPopup(false);
    };
    const handleFaceSheetClosePopup = () => {
      setFaceSheetOpenPopup(false);
    };
    const handleLabTestClosePopup = () => {
      setLabTestPopup(false);
    };

    

    function SymptomsPopUpCN(){
     
      const Input = styled(MuiInput)`
      width: 42px;
    `;
  
    const [covidSymptomsList, setCovidSymptomsList] = React.useState(clinincalNote.symptoms.covidSymptoms);
    const [generalSymptomsList, setGeneralSymptomsList] = React.useState(clinincalNote.symptoms.generalSymptoms);
    const [cvsSymptomsList, setCvsSymptomsList] = React.useState(clinincalNote.symptoms.cvsSymptoms);
    const [respiratorySymptomsList, setRespiratorySymptomsList] = React.useState(clinincalNote.symptoms.respiratorySymptoms);
    const [nsSymptomsList, setNsSymptomsList] = React.useState(clinincalNote.symptoms.nsSymptoms);
    const [muskeloskeletalSymptomsList, setMuskeloskeletalSymptomsList] = React.useState(clinincalNote.symptoms.muskeloskeletal);
    const [gitSymptomsList, setGITSymptomsList] = React.useState(clinincalNote.symptoms.gitSymptoms);
    const [urinarySymptomsList, setUrinarySymptomsList] = React.useState(clinincalNote.symptoms.urinarySymptoms);
    const [skinSymptomsList, setSkinSymptomsList] = React.useState(clinincalNote.symptoms.skinSymptoms);
    const [eyeSymptomsList, setEyeSymptomsList] = React.useState(clinincalNote.symptoms.eyeSymptoms);

    const handleSymptomSave = () =>{
      clinincalNote.symptoms.covidSymptoms = [...covidSymptomsList]
      clinincalNote.symptoms.generalSymptoms = [...generalSymptomsList]
      clinincalNote.symptoms.cvsSymptoms = [...cvsSymptomsList]
      clinincalNote.symptoms.respiratorySymptoms = [...respiratorySymptomsList]
      clinincalNote.symptoms.nsSymptoms = [...nsSymptomsList]
      clinincalNote.symptoms.muskeloskeletal = [...muskeloskeletalSymptomsList]
      clinincalNote.symptoms.gitSymptoms = [...gitSymptomsList]
      clinincalNote.symptoms.urinarySymptoms = [...urinarySymptomsList]
      clinincalNote.symptoms.skinSymptoms = [...skinSymptomsList]
      clinincalNote.symptoms.eyeSymptoms = [...eyeSymptomsList]
      setIsSymptomSaved(true);
      console.log("added",clinincalNote)
    }


      return (
      <div>
        <Dialog open={openSymptomsPopup} onClose={handleSymptomsClosePopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Covid Symptoms</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleSymptomsClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          
          <Typography fontSize={18} fontWeight={'bold'}>Covid Symptoms</Typography>
              {covidSymptomsList.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...covidSymptomsList];
                    newData[index].value = Number(e.target.value);
                    setCovidSymptomsList(newData)           
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0} 
                  />
                </Grid>
                <Grid item>
                  <Input
                     value={symptom.value}
                     size="small"
                     onChange={(e) =>{
                      let newData = [...covidSymptomsList];
                      newData[index].value = Number(e.target.value);
                      setCovidSymptomsList(newData)
                    }}
                     inputProps={{
                       step: 1,
                       min: 0,
                       max: 10,
                       type: 'number',
                       'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}                   
                <Typography fontSize={18} fontWeight={'bold'}>General Symptoms</Typography>
              {generalSymptomsList.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider 
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...generalSymptomsList];
                    newData[index].value = Number(e.target.value);
                    setGeneralSymptomsList(newData)
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0}   
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={symptom.value}
                    size="small"
                    onChange={(e) =>{
                      let newData = [...generalSymptomsList];
                      newData[index].value = Number(e.target.value);
                      setGeneralSymptomsList(newData)
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                   }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}    
              <Typography fontSize={18} fontWeight={'bold'}>CVS ( Cardiovascular System )</Typography>
              {cvsSymptomsList.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider 
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...cvsSymptomsList];
                    newData[index].value = Number(e.target.value);
                    setCvsSymptomsList(newData)
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0}    
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={symptom.value}
                    size="small"
                    onChange={(e) =>{
                      let newData = [...cvsSymptomsList];
                      newData[index].value = Number(e.target.value);
                      setCvsSymptomsList(newData)
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                   }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
              <Typography fontSize={18} fontWeight={'bold'}>Respiratory System</Typography>
              {respiratorySymptomsList.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider 
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...respiratorySymptomsList];
                    newData[index].value = Number(e.target.value);
                    setRespiratorySymptomsList(newData)
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0}   
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={symptom.value}
                    size="small"
                    onChange={(e) =>{
                      let newData = [...respiratorySymptomsList];
                      newData[index].value = Number(e.target.value);
                      setRespiratorySymptomsList(newData)
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                   }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
              <Typography fontSize={18} fontWeight={'bold'}>NS ( Nervous System )</Typography>
              {nsSymptomsList.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider 
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...nsSymptomsList];
                    newData[index].value = Number(e.target.value);
                    setNsSymptomsList(newData)
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0}     
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={symptom.value}
                    size="small"
                    onChange={(e) =>{
                      let newData = [...nsSymptomsList];
                      newData[index].value = Number(e.target.value);
                      setNsSymptomsList(newData)
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                   }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
              <Typography fontSize={18} fontWeight={'bold'}>Muskeloskeletal</Typography>
              {muskeloskeletalSymptomsList.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider 
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...muskeloskeletalSymptomsList];
                    newData[index].value = Number(e.target.value);
                    setMuskeloskeletalSymptomsList(newData)
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0}    
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={symptom.value}
                    size="small"
                    onChange={(e) =>{
                      let newData = [...muskeloskeletalSymptomsList];
                      newData[index].value = Number(e.target.value);
                      setMuskeloskeletalSymptomsList(newData)
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                   }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))} 
              <Typography fontSize={18} fontWeight={'bold'}>GIT ( Gastrointestinal Tract )</Typography>
              {gitSymptomsList.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider 
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...gitSymptomsList];
                    newData[index].value = Number(e.target.value);
                    setGITSymptomsList(newData)
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0}   
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={symptom.value}
                    size="small"
                    onChange={(e) =>{
                      let newData = [...gitSymptomsList];
                      newData[index].value = Number(e.target.value);
                      setGITSymptomsList(newData)
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                   }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
              <Typography fontSize={18} fontWeight={'bold'}>Urinary</Typography>
              {urinarySymptomsList.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider 
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...urinarySymptomsList];
                    newData[index].value = Number(e.target.value);
                    setUrinarySymptomsList(newData)
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0}    
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={symptom.value}
                    size="small"
                    onChange={(e) =>{
                      let newData = [...urinarySymptomsList];
                      newData[index].value = Number(e.target.value);
                      setUrinarySymptomsList(newData)
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                   }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}   
              <Typography fontSize={18} fontWeight={'bold'}>Skin</Typography>
              {skinSymptomsList.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider 
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...skinSymptomsList];
                    newData[index].value = Number(e.target.value);
                    setSkinSymptomsList(newData)
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0}   
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={symptom.value}
                    size="small"
                    onChange={(e) =>{
                      let newData = [...skinSymptomsList];
                      newData[index].value = Number(e.target.value);
                      setSkinSymptomsList(newData)
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                   }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
              <Typography fontSize={18} fontWeight={'bold'}>Eye</Typography>
              {eyeSymptomsList.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider 
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...eyeSymptomsList];
                    newData[index].value = Number(e.target.value);
                    setEyeSymptomsList(newData)
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0}   
                  />
                </Grid>
                <Grid item>
                  <Input
                    value={symptom.value}
                    size="small"
                    onChange={(e) =>{
                      let newData = [...eyeSymptomsList];
                      newData[index].value = Number(e.target.value);
                      setEyeSymptomsList(newData)
                    }}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10,
                      type: 'number',
                      'aria-labelledby': 'input-slider',
                   }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
                
          </DialogContent>
          <DialogActions>
            
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={() =>{
              handleSymptomSave()
              handleSymptomsClosePopup()
              }}>
                      Save
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }

    function VitalsPopUpCN(){
     
      const [height, setHeight] = React.useState(clinincalNote.vitals.height);
      const [weight, setWeight] = React.useState(clinincalNote.vitals.weight);
      const [temp, setTemp] = React.useState(clinincalNote.vitals.temperature);
      const [spo2, setSPO2] = React.useState(clinincalNote.vitals.spO2);
      const [pulse, setPulse] = React.useState(clinincalNote.vitals.pulse);
      const [systolic, setSystolic] = React.useState(clinincalNote.vitals.systolic);
      const [diastolic, setDiastolic] = React.useState(clinincalNote.vitals.diastolic);
      const [respiratoryRate, setRespiratoryRate] = React.useState(clinincalNote.vitals.respiratoryRate);
      const [grbs, setGRBS] = React.useState(clinincalNote.vitals.grbs);
      const [hunit, setHunit] = React.useState('cm');
      const [wunit, setWunit] = React.useState('kg');
      const [tempUnit, setTempUnit] = React.useState('°C');
      const [tempText, setTempText] = React.useState('Normal Range 26 - 46');
      const [hunitToggle, setHunitToggle] = React.useState(false);
      const [tempUnitToggle, setTempUnitToggle] = React.useState(false);
      const [errorText,setErrorText] = React.useState({
        height:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        weight:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        temp:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        spo2:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        pulse:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        systolic:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        diastolic:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        respiratoryRate:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        grbs:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
      })
      const between = (x, min, max) => {
        if(x >= min && x <= max){
          return true
        }else{
          return false
        }
      }

      function handleValidation(){

      if(height === "" || height !== ""){
            if(height === ""){
              setErrorText(previous => ({...previous,height:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
                
            }else if(between(height,0,200) === false){
              setErrorText(previous => ({...previous,height:{error:true,
                errorText:"Input Value Not in Range",
                verified:false}}))
                return true;
            }else if(height !== "" && between(height,0,200) === true){
              setErrorText(previous => ({...previous,height:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
            }       
        }
        if(weight === "" || weight !== ""){
          if(weight === ""){
            setErrorText(previous => ({...previous,weight:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(between(weight,0,300) === false){
            setErrorText(previous => ({...previous,weight:{error:true,
              errorText:"Input Value Not in Range",
              verified:false}}))
              return true;
          }else if(weight !== "" && between(weight,0,300) === true){
            setErrorText(previous => ({...previous,weight:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
          }       
      }
      if(temp === "" || temp !== ""){
        if(temp === ""){
          setErrorText(previous => ({...previous,temp:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
            
        }else if(tempUnit === '°C' && between(temp,26,46) === false){
          setErrorText(previous => ({...previous,temp:{error:true,
            errorText:"Input Value Not in Range",
            verified:false}}))
            return true;
        }else if(tempUnit === '°F' && between(temp,78.8,114.8) === false){
          setErrorText(previous => ({...previous,temp:{error:true,
            errorText:"Input Value Not in Range",
            verified:false}}))
            return true;
        }
        else if(between(temp,26,46) === true  || between(temp,78.8,114.8) === true){
          setErrorText(previous => ({...previous,temp:{error:false,
            errorText:"Field Can't be Empty",
            verified:true}}))
        }       
    }

    if(spo2 === "" || spo2 !== ""){
      if(spo2 === ""){
        setErrorText(previous => ({...previous,spo2:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
          
      }else if(between(spo2,1,100) === false){
        setErrorText(previous => ({...previous,spo2:{error:true,
          errorText:"Input Value Not in Range",
          verified:false}}))
          return true;
      }else if(spo2 !== "" && between(spo2,1,100) === true){
        setErrorText(previous => ({...previous,spo2:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
      }       
  }
  if(pulse === "" || pulse !== ""){
    if(pulse === ""){
      setErrorText(previous => ({...previous,pulse:{error:true,
        errorText:"Field can't be empty",
        verified:false}}))
        return true;
        
    }else if(between(pulse,26,220) === false){
      setErrorText(previous => ({...previous,pulse:{error:true,
        errorText:"Input Value Not in Range",
        verified:false}}))
        return true;
    }else if(pulse !== "" && between(pulse,26,220) === true){
      setErrorText(previous => ({...previous,pulse:{error:false,
        errorText:"Field can't be empty",
        verified:true}}))
    }       
}

if(systolic === "" || systolic !== ""){
  if(systolic === ""){
    setErrorText(previous => ({...previous,systolic:{error:true,
      errorText:"Field can't be empty",
      verified:false}}))
      return true;
      
  }else if(between(systolic,30,240) === false){
    setErrorText(previous => ({...previous,systolic:{error:true,
      errorText:"Input Value Not in Range",
      verified:false}}))
      return true;
  }else if(systolic !== "" && between(systolic,30,240) === true){
    setErrorText(previous => ({...previous,systolic:{error:false,
      errorText:"Field can't be empty",
      verified:true}}))
  }       
}

if(diastolic === "" || diastolic !== ""){
  if(diastolic === ""){
    setErrorText(previous => ({...previous,diastolic:{error:true,
      errorText:"Field can't be empty",
      verified:false}}))
      return true;
      
  }else if(between(diastolic,10,200) === false){
    setErrorText(previous => ({...previous,diastolic:{error:true,
      errorText:"Input Value Not in Range",
      verified:false}}))
      return true;
  }else if(diastolic !== "" && between(diastolic,10,200) === true){
    setErrorText(previous => ({...previous,diastolic:{error:false,
      errorText:"Field can't be empty",
      verified:true}}))
  }       
}

if(respiratoryRate === "" || respiratoryRate !== ""){
  if(respiratoryRate === ""){
    setErrorText(previous => ({...previous,respiratoryRate:{error:true,
      errorText:"Field can't be empty",
      verified:false}}))
      return true;
      
  }else if(between(respiratoryRate,5,75) === false){
    setErrorText(previous => ({...previous,respiratoryRate:{error:true,
      errorText:"Input Value Not in Range",
      verified:false}}))
      return true;
  }else if(respiratoryRate !== "" && between(respiratoryRate,5,75) === true){
    setErrorText(previous => ({...previous,respiratoryRate:{error:false,
      errorText:"Field can't be empty",
      verified:true}}))
  }       
}

if(grbs === "" || grbs !== ""){
  if(grbs === ""){
    setErrorText(previous => ({...previous,grbs:{error:true,
      errorText:"Field can't be empty",
      verified:false}}))
      return true;
      
  }else if(between(grbs,5,1000) === false){
    setErrorText(previous => ({...previous,grbs:{error:true,
      errorText:"Input Value Not in Range",
      verified:false}}))
      return true;
  }else if(grbs !== "" && between(grbs,5,1000) === true){
    setErrorText(previous => ({...previous,grbs:{error:false,
      errorText:"Field can't be empty",
      verified:true}}))
      return false
  }       
}
      console.log("RESULTS............");
      
    }
      
      const handleVitalSave = () =>{
        clinincalNote.vitals.height = height;
        clinincalNote.vitals.weight = weight;
        clinincalNote.vitals.temperature = temp;
        clinincalNote.vitals.spO2 = spo2;
        clinincalNote.vitals.pulse = pulse;
        clinincalNote.vitals.bloodPressure = `${systolic}/${diastolic}`;
        clinincalNote.vitals.systolic = systolic;
        clinincalNote.vitals.diastolic = diastolic
        clinincalNote.vitals.grbs = grbs;
        clinincalNote.vitals.hunit = hunit;
        clinincalNote.vitals.wunit = wunit;
        clinincalNote.vitals.tempunit = tempUnit;
        clinincalNote.vitals.respiratoryRate = respiratoryRate;
        console.log("added",clinincalNote)
        handleVitalsClosePopup()
        setIsVitalSaved(true)
        toast.success('Vitals Saved Successfully', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }

      const convertCmToInch = (cm) => {
        return cm / 2.54;
      };

      const convertInchToCm = (inch) => {
        return inch * 2.54;
      };

      const convertCelsiusToFahrenheit = (celsius) => {
        return (celsius * 9/5) + 32;
      };

      const convertFahrenheitToCelsius = (fahrenheit) => {
        return (fahrenheit - 32) * 5/9;
      };

      return (
      <div>
        <Dialog open={openVitalsPopup} onClose={handleVitalsClosePopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add Vitals</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleVitalsClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={9}>
                <Typography fontSize={16} fontWeight={'bold'}>Height</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>In</Typography>
              <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={()=>{
                setHunitToggle(!hunitToggle)
                if(hunitToggle === true){
                  setHunit("cm")
                  setHeight(convertInchToCm(height))
                }else{
                  setHunit("In")
                  setHeight(convertCmToInch(height))
                }
              }} />
              <Typography>cm</Typography>
            </Stack>
            </Grid>
          </Grid>
                <TextField
                  value={height}
                  name="height"
                  required
                  fullWidth
                  type='number'
                  id="height"
                  label="Enter Height"
                  size='small'
                  onChange={(e) =>{setHeight(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{hunit}</InputAdornment>,
                  }}
                  helperText={errorText.height.error === true ? errorText.height.errorText:""}
                  error={errorText.height.error === true ? true : false}
                  autoFocus={errorText.height.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Weight (in Kg)</Typography>
                <TextField
                  required
                  fullWidth
                  id="weight"
                  value={weight}
                  label="Enter Weight"
                  name="weight"
                  type='number'
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setWeight(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                  }}
                  helperText={errorText.weight.error === true ? errorText.weight.errorText:""}
                  error={errorText.weight.error === true ? true : false}
                  autoFocus={errorText.weight.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Grid container spacing={2}>
            <Grid item xs={6} sm={9}>
                <Typography fontSize={16} fontWeight={'bold'}>Temperature</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>°F</Typography>
              <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={()=>{
                setTempUnitToggle(!tempUnitToggle)
                if(tempUnitToggle === true){
                  setTempUnit("°C")
                  setTempText('Normal Range 26 - 46')
                  setTemp(convertFahrenheitToCelsius(temp))
                }else{
                  setTempUnit("°F")
                  setTempText('Normal Range 78.8 - 114.8')
                  setTemp(convertCelsiusToFahrenheit(temp))
                }
              }} />
              <Typography>°C</Typography>
            </Stack>
            </Grid>
          </Grid>
                <TextField
                  name="Temperature"
                  required
                  fullWidth
                  value={temp}
                  id="temperature"
                  type='number'
                  label={tempText}
                  size='small'
                  onChange={(e) =>{setTemp(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{tempUnit}</InputAdornment>,
                  }}
                  helperText={errorText.temp.error === true ? errorText.temp.errorText:""}
                  error={errorText.temp.error === true ? true : false}
                  autoFocus={errorText.temp.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>SPO2</Typography>
                <TextField
                  required
                  fullWidth
                  value={spo2}
                  id="spo2"
                  label="Normal Range 1 - 100"
                  type='number'
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setSPO2(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ppm</InputAdornment>,
                  }}
                  helperText={errorText.spo2.error === true ? errorText.spo2.errorText:""}
                  error={errorText.spo2.error === true ? true : false}
                  autoFocus={errorText.spo2.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Pulse</Typography>
                <TextField
                  required
                  fullWidth
                  value={pulse}
                  id="pulse"
                  label="Normal Range 26 - 220"
                  type='number'
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setPulse(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">BPM</InputAdornment>,
                  }}
                  helperText={errorText.pulse.error === true ? errorText.pulse.errorText:""}
                  error={errorText.pulse.error === true ? true : false}
                  autoFocus={errorText.pulse.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Blood Pressure</Typography>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} padding={2}>
                    <TextField
                      fullWidth
                      value={systolic}
                      id="bloodpressure"
                      name="BloodPressure"
                      type='number'
                      label="30 - 240"
                      autoComplete="family-name"
                      size='small'
                      onChange={(e) =>{setSystolic(e.target.value)}}
                      helperText={errorText.systolic.error === true ? errorText.systolic.errorText:""}
                      error={errorText.systolic.error === true ? true : false}
                      autoFocus={errorText.systolic.error === true ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={0.1} padding={2}>
                <Typography fontSize={25}>/</Typography>
                </Grid>
                
                  <Grid item xs={12} sm={4} padding={2}>
                    <TextField
                      fullWidth
                      value={diastolic}
                      id="bloodpressure"
                      name="BloodPressure"
                      type='number'
                      label="10 - 200"
                      autoComplete="family-name"
                      size='small'
                      onChange={(e) =>{setDiastolic(e.target.value)}}
                      helperText={errorText.diastolic.error === true ? errorText.diastolic.errorText:""}
                      error={errorText.diastolic.error === true ? true : false}
                      autoFocus={errorText.diastolic.error === true ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={1} padding={2}>
                <Typography sx={{marginTop:2}}>mmHG</Typography>
                </Grid>
                  </Grid>
                
              </Grid>

            
              
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Respiratory Rate</Typography>
                <TextField
                  name="Temperature"
                  required
                  fullWidth
                  value={respiratoryRate}
                  id="temperature"
                  label="Normal Range 5 - 75"
                  type='number'
                  size='small'
                  onChange={(e) =>{setRespiratoryRate(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ppm</InputAdornment>,
                  }}
                  helperText={errorText.respiratoryRate.error === true ? errorText.respiratoryRate.errorText:""}
                  error={errorText.respiratoryRate.error === true ? true : false}
                  autoFocus={errorText.respiratoryRate.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>GRBS</Typography>
                <TextField
                  required
                  fullWidth
                  value={grbs}
                  id="spo2"
                  label="Normal Range 5 - 1000"
                  type='number'
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setGRBS(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">mg / DL</InputAdornment>,
                  }}
                  helperText={errorText.grbs.error === true ? errorText.grbs.errorText:""}
                  error={errorText.grbs.error === true ? true : false}
                  autoFocus={errorText.grbs.error === true ? true : false}
                />
              </Grid>
        </Grid>
          
                
          </DialogContent>
          <DialogActions>
            
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={() =>{
              let res = handleValidation();
              if(res === false){
                handleVitalSave()
              }else{
                toast("Please Check your Input Data")
              }
              
            }}>
                      Save
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }
    function PrescriptionPopUpCN(){
     
      const [drug, setDrug] = React.useState('');
      const [quantity, setQuantity] = React.useState('');
      const [instruction, setInstruction] = React.useState('');
      const [dispenseForm, setDispenseForm] = React.useState('');
      const [pharmaName, setPharmaName] = React.useState('');
      const [prescriptionListData,setPrescriptionListData] = React.useState(clinincalNote.prescription);
      const [drugList,setDrugList] = React.useState([])
      const [dispenseFormList,setDispenseFormList] = React.useState([])
      const [pharmacyList,setPharmacyList] = React.useState([])
      const [radioValue, setRadioValue] = React.useState(0)
      const [changeDataSource, setChangeDataSource] = React.useState(false)
      console.log("psData",prescriptionListData)

      const [errorText,setErrorText] = React.useState({
        drug:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        quantity:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        instruction:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        dispenseForm:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        pharmaName:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
      })
  
      function handleValidation(){
      if(drug === '' || drug !==''){
          if(drug === ''){
            setErrorText(previous => ({...previous,drug:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(drug !== ''){
            setErrorText(previous => ({...previous,drug:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }
      if(quantity === "" || quantity !== ""){
            if(quantity === ""){
              setErrorText(previous => ({...previous,quantity:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
                
            }else if(quantity !== ""){
              setErrorText(previous => ({...previous,quantity:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
  
            }       
        }
        if(instruction === "" || instruction !== ""){
          if(instruction === ""){
            setErrorText(previous => ({...previous,instruction:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(instruction !== ""){
            setErrorText(previous => ({...previous,instruction:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))

          }       
      }
      if(dispenseForm === '' || dispenseForm !==''){
        if(dispenseForm === ''){
          setErrorText(previous => ({...previous,dispenseForm:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(dispenseForm !== ''){
          setErrorText(previous => ({...previous,dispenseForm:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
      if(pharmaName === '' || pharmaName !==''){
        if(pharmaName === ''){
          setErrorText(previous => ({...previous,pharmaName:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(pharmaName !== ''){
          setErrorText(previous => ({...previous,pharmaName:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
            return false
        }
      }
      
      console.log("RESULTS............");
      
    }

      const handleDelete = (deleteIndex) => {
        setPrescriptionListData((prevData) =>
          prevData.filter((_, index) => index !== deleteIndex)
        );
      };

      const handlRadioChange = (event) => {
        setRadioValue(event.target.value);
      };

      function handlePrescriptionSave(){
        clinincalNote.prescription = prescriptionListData;
        console.log("added",clinincalNote)
        handlePrescriptionClosePopup()
        setIsPrescriptionSaved(true)
        toast.success('Prescription Saved Successfully', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });

      }
      return (
      <div>
        <Dialog open={openPrescriptionPopup} onClose={handlePrescriptionClosePopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add Prescription</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handlePrescriptionClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
        <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Select Data Source</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={radioValue}
        onChange={handlRadioChange}
      >
        <FormControlLabel value={0} control={<Radio 
          onChange={()=>{setRadioValue(0)
          setDrugList([])
          setChangeDataSource(false)
          }}
        />} label="Internal" />
        <FormControlLabel value={1} control={<Radio 
          onChange={()=>{setRadioValue(1)
          setDrugList([])
          setChangeDataSource(true)
          }}
        />} label="SnowMed" />
        
      </RadioGroup>
    </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} padding={2}></Grid>
    <Grid item xs={12} sm={6} padding={2}>
            <Typography fontSize={16} fontWeight={'bold'}>Drug</Typography>
                {changeDataSource === false ?
                <FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
                      setDrugList([])
                      
                      MasterDataEncounterSearch(event.target.value,'MEDICINE').then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setDrugList(res.data.content)
                        }else{
                          setDrugList([])
                        }
                        
                      })
                      
                      setDrug(newValue.term)
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.typeID){
                      console.log("seletced val.......",newValue.label)
                      setDrug(newValue.label)
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={drugList}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.typeID}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Drug"
                      helperText={errorText.drug.error === true ? errorText.drug.errorText:""}
                      error={errorText.drug.error === true ? true : false}
                      autoFocus={errorText.drug.error === true ? true : false}
                    />}
                  />
                    </FormControl>
                    : <FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
                      setDrugList([])
                      getMedicineList(event.target.value).then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setDrugList(res.data)
                        }else{
                          setDrugList([])
                        }
                        
                      })
                      
                      setDrug(newValue.term)
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.id){
                      console.log("seletced val.......",newValue.term)
                      setDrug(newValue.term)
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={drugList}
                    getOptionLabel={(option) => option.term}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.term}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Drug"
                    helperText={errorText.drug.error === true ? errorText.drug.errorText:""}
                    error={errorText.drug.error === true ? true : false}
                    autoFocus={errorText.drug.error === true ? true : false}
                    />}
                  />
                    </FormControl>}
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Quantity</Typography>
                <TextField
                  required
                  fullWidth
                  type='number'
                  id="qunatity"
                  label="Enter Quantity"
                  name="quantity"
                  value={quantity}
                  onChange={(e) =>setQuantity(e.target.value)}
                  size='small'
                  helperText={errorText.quantity.error === true ? errorText.quantity.errorText:""}
                  error={errorText.quantity.error === true ? true : false}
                  autoFocus={errorText.quantity.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Instruction</Typography>
                <TextField
                  name="Instruction"
                  required
                  fullWidth
                  id="instruction"
                  label="Enter Instruction"
                  size='small'
                  value={instruction}
                  onChange={(e) =>setInstruction(e.target.value)}
                  helperText={errorText.instruction.error === true ? errorText.instruction.errorText:""}
                  error={errorText.instruction.error === true ? true : false}
                  autoFocus={errorText.instruction.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Dispense Form</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
                      setDispenseFormList([])
                      
                      MasterDataEncounterSearch(event.target.value,'MEDICINE_TYPE').then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setDispenseFormList(res.data.content)
                        }else{
                          setDispenseFormList([])
                        }
                        
                      })
                      
                      setDispenseForm(newValue.term)
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.typeID){
                      console.log("seletced val.......",newValue.label)
                      setDispenseForm(newValue.label)
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={dispenseFormList}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.typeID}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Search Dispense Form"
                      helperText={errorText.dispenseForm.error === true ? errorText.dispenseForm.errorText:""}
                      error={errorText.dispenseForm.error === true ? true : false}
                      autoFocus={errorText.dispenseForm.error === true ? true : false}
                    />}
                  />
                    </FormControl>
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Pharmacy Name</Typography>
        <FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
                      setPharmacyList([])
                      
                      MasterDataEncounterSearch(event.target.value,'FARMACY').then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setPharmacyList(res.data.content)
                        }else{
                          setPharmacyList([])
                        }
                        
                      })
                      
                      setPharmaName(newValue.term)
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.typeID){
                      console.log("seletced val.......",newValue.label)
                      setPharmaName(newValue.label)
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={pharmacyList}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.typeID}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Search Pharmacy"
                    helperText={errorText.pharmaName.error === true ? errorText.pharmaName.errorText:""}
                    error={errorText.pharmaName.error === true ? true : false}
                    autoFocus={errorText.pharmaName.error === true ? true : false}
                    />}
                  />
                    </FormControl>
              </Grid>
             
        </Grid>
        {prescriptionListData.length != 0 ? 
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Medicine Name</StyledTableCell>
            <StyledTableCell align="center">Quantity</StyledTableCell>
            <StyledTableCell align="center">Instruction</StyledTableCell>
            <StyledTableCell align="center">Dispense Form</StyledTableCell>
            <StyledTableCell align="center">Pharmacy Name</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prescriptionListData.map((details,index) => (
            <StyledTableRow key={details.name}>
              <StyledTableCell component="th" scope="row">
                {details.name}
              </StyledTableCell>
              <StyledTableCell align="center">{details.quantity}</StyledTableCell>
              <StyledTableCell align="center">{details.instruction}</StyledTableCell>
              <StyledTableCell align="center">{details.form}</StyledTableCell>
              <StyledTableCell align="center">{details.farmacy_name}</StyledTableCell>
              <StyledTableCell align="center">
                <IconButton onClick={() =>{handleDelete(index)}}>
                    <DeleteIcon></DeleteIcon>
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
       : <div></div>}   
          </DialogContent>
          <DialogActions>
          <Button variant="outlined" sx={{width:'30%',borderColor:'#651fff'}} onClick={() =>{
            let res = handleValidation()
            if(res === false){
                setPrescriptionListData(prescriptionListData.concat({

                  orgid: user.orgID,
                  appointmentid: props.consultationId,
                  uuid: patientData.personUuId,
                  code:"34",
                  name:drug,
                  form:dispenseForm,
                  quantity:quantity,
                  frequency:"",
                  route:"",
                  hours_interval:"",
                  min_interval:"",
                  instruction:instruction,
                  farmacy_name:pharmaName,
                  note:"",
                  file:"",
                  remark:""
              
              }))
            }else{
              toast("Please Check your Input Data")
            }
            
            }}
            
            >
                      Add Prescription
                    </Button>
            
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={handlePrescriptionSave}>
                      Save
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }
    function AdditionalInstructionPopUpCN(){
     
      const [heading, setHeading] = React.useState('');
      const [content, setContent] = React.useState('');
      const [instructionsListData,setInstructionListData] = React.useState(clinincalNote.additionalDetails);
      console.log("INData",instructionsListData)
      const [errorText,setErrorText] = React.useState({
        heading:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        content:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
      })
  
      function handleValidation(){
      if(heading === '' || heading !==''){
          if(heading === ''){
            setErrorText(previous => ({...previous,heading:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
          }else if(heading !== ''){
            setErrorText(previous => ({...previous,heading:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
          }
        }

        if(content === '' || content !==''){
          if(content === ''){
            setErrorText(previous => ({...previous,content:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(content !== ''){
            setErrorText(previous => ({...previous,content:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
              return false
          }
        }
      
        
      
      console.log("RESULTS............");
      
    }

      const handleDelete = (deleteIndex) => {
        setInstructionListData((prevData) =>
          prevData.filter((_, index) => index !== deleteIndex)
        );
      };

      function handleInstructionSave(){
        clinincalNote.additionalDetails = instructionsListData;
        console.log("added",clinincalNote)
        handleInstructionClosePopup()
        setIsAdditionalInstSaved(true)
        toast.success('Additional Instruction Saved Successfully', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }
  
      return (
      <div>
        <Dialog open={openInstructionPopup} onClose={handleInstructionClosePopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add Instruction</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleInstructionClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
            
                <Typography fontSize={16} fontWeight={'bold'}>Heading</Typography>
                <TextField
                  autoComplete="given-name"
                  name="Heading"
                  required
                  fullWidth
                  id="heading"
                  value={heading}
                  onChange={(e) =>setHeading(e.target.value)}
                  label="Enter Heading"
                  size='small'
                  helperText={errorText.heading.error === true ? errorText.heading.errorText:""}
                  error={errorText.heading.error === true ? true : false}
                  autoFocus={errorText.heading.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Content</Typography>
                <TextField
                  required
                  fullWidth
                  id="content"
                  label="Enter Content"
                  name="content"
                  value={content}
                  onChange={(e) =>setContent(e.target.value)}
                  size='small'
                  helperText={errorText.content.error === true ? errorText.content.errorText:""}
                  error={errorText.content.error === true ? true : false}
                  autoFocus={errorText.content.error === true ? true : false}
                />
              </Grid>
        </Grid>
        {instructionsListData.length != 0 ? 
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Header</StyledTableCell>
            <StyledTableCell align="center">Content</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {instructionsListData.map((details,index) => (
            <StyledTableRow key={details.header}>
              <StyledTableCell component="th" scope="row">
                {details.header}
              </StyledTableCell>
              <StyledTableCell align="center">{details.value}</StyledTableCell>
              <StyledTableCell align="center">
                <IconButton onClick={() =>{handleDelete(index)}}>
                    <DeleteIcon></DeleteIcon>
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
       : <div></div>}
             
          </DialogContent>
          <DialogActions>
          <Button variant="outlined" sx={{width:'30%',borderColor:'#651fff'}} onClick={() =>{

            let res = handleValidation()
            if(res === false){
                setInstructionListData(instructionsListData.concat({

                  appointmentid:props.consultationId,
                  header:heading,
                  value:content
                }))
            }else{
              toast("Please Fill the Details")
            }
           
            }}>
                      Add Instruction
                    </Button>
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={handleInstructionSave}>
                      Save
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }

    function PdfViewPopUpCN(){
      async function uploadEncounterReport(){
        console.log("cid",props.consultationId)
        const blob = await ReactPDF.pdf(<MyDoc />).toBlob();
        console.log("encounter blob",blob)
        var file = new File([blob],`encounter_${props.consultationId}.pdf`,{type:blob.type})
        console.log("file object",file)
        fileUpload(file,`ENCOUNTER_REPORT_${props.consultationId}`).then(uploadResult =>{
          console.log(uploadResult)
          if(uploadResult.status === 200){
            createClinicalNote(props.patientID,clinincalNote.cheifComplaint,clinincalNote.HPI,clinincalNote.treatmentPlan,
              clinincalNote.otherDetails,clinincalNote.additionalDetails,"",clinincalNote.triage,clinincalNote.labOrders,
              "","","","",props.consultationId,"","completed",(clinincalNote.symptoms.covidSymptoms)[0].value,
              (clinincalNote.symptoms.covidSymptoms)[1].value,(clinincalNote.symptoms.covidSymptoms)[2].value,
              (clinincalNote.symptoms.covidSymptoms)[3].value,(clinincalNote.symptoms.covidSymptoms)[4].value,
              (clinincalNote.symptoms.covidSymptoms)[5].value,(clinincalNote.symptoms.covidSymptoms)[6].value,
              (clinincalNote.symptoms.covidSymptoms)[7].value,(clinincalNote.symptoms.covidSymptoms)[8].value,
              (clinincalNote.symptoms.covidSymptoms)[9].value,(clinincalNote.symptoms.covidSymptoms)[10].value,
              (clinincalNote.symptoms.generalSymptoms)[0].value,(clinincalNote.symptoms.generalSymptoms)[1].value,
              (clinincalNote.symptoms.generalSymptoms)[2].value,(clinincalNote.symptoms.generalSymptoms)[3].value,
              (clinincalNote.symptoms.generalSymptoms)[4].value,(clinincalNote.symptoms.generalSymptoms)[5].value,
              (clinincalNote.symptoms.cvsSymptoms)[0].value,(clinincalNote.symptoms.cvsSymptoms)[1].value,
              (clinincalNote.symptoms.cvsSymptoms)[2].value,(clinincalNote.symptoms.cvsSymptoms)[3].value,
              (clinincalNote.symptoms.respiratorySymptoms)[0].value,(clinincalNote.symptoms.nsSymptoms)[0].value,
              (clinincalNote.symptoms.muskeloskeletal)[0].value,(clinincalNote.symptoms.gitSymptoms)[0].value,
              (clinincalNote.symptoms.gitSymptoms)[1].value,(clinincalNote.symptoms.gitSymptoms)[2].value,
              (clinincalNote.symptoms.gitSymptoms)[3].value,(clinincalNote.symptoms.gitSymptoms)[4].value,
              (clinincalNote.symptoms.gitSymptoms)[5].value,(clinincalNote.symptoms.gitSymptoms)[6].value,
              (clinincalNote.symptoms.urinarySymptoms)[0].value,(clinincalNote.symptoms.urinarySymptoms)[1].value,
              (clinincalNote.symptoms.urinarySymptoms)[2].value,(clinincalNote.symptoms.skinSymptoms)[0].value,
              (clinincalNote.symptoms.skinSymptoms)[1].value,(clinincalNote.symptoms.eyeSymptoms)[0].value,
              (clinincalNote.symptoms.eyeSymptoms)[1].value,clinincalNote.lab_tests,clinincalNote.prescription,
              clinincalNote.vitals.weight,clinincalNote.vitals.height,clinincalNote.vitals.pulse,clinincalNote.vitals.systolic,
              clinincalNote.vitals.diastolic,clinincalNote.vitals.temperature,clinincalNote.vitals.respiratoryRate,
              clinincalNote.vitals.grbs,"",clinincalNote.vitals.hunit,clinincalNote.vitals.wunit,clinincalNote.vitals.tempunit,clinincalNote.vitals.spO2,"",uploadResult.path).then(res => {
                console.log(res)
                if(res.status === 200){
                  toast("Encounter Signed and Closed")
                  setComponent('Doctor Dashboard')
                }else{
                  toast("Error Occurred")
                }
              }) 
          }else{
            toast("Error while Uploading Report")
          }
        }
        )
      }

    const MyDoc = () => (
        <Document>
                <Page wrap size="A4" style={styles.page} orientation="portrait">
                <View style={{width:'100%',flexDirection: 'column'}}>
                    <View style={{width:'100%',flexDirection: 'row',margin: 20,textAlign:'center',alignItems:'center'}} fixed>
                      <View style={{width:'25%',flexDirection: 'column',paddingLeft:20}}>
                        <Image src={UbiLogo} style={{ width: 80, height: 40}}/>
                      </View>
                      <View style={{width:'50%',flexDirection: 'column'}}>
                        <Text>UBIQARE HEALTH</Text>
                      </View>
                      <View style={{width:'25%',flexDirection: 'column',paddingLeft:40}}>
                        {/* <Image src={NABHLogo} style={{ width: 40, height: 40}}/> */}
                      </View>
                      </View>
                    <View style={{width:'100%',flexDirection: 'row',alignItems:'center',marginTop:5,textAlign:'center',alignItems:'center'}}>
                        <View style={{width:'25%',flexDirection: 'column'}}>
                                <Text style={{fontSize:12}}>Date: {patientData.aptSlotsDto.aptSlotStartTime.slice(0,10)}</Text>
                                <Text style={{fontSize:12}}>Time: {patientData.aptSlotsDto.aptSlotStartTime.slice(11,19)}</Text>                       
                        </View>

                        <View style={{width:'50%',flexDirection: 'column'}}>
                                <Text style={{fontSize:12}}>First floor of house No.5,14th Main,15th Cross,4th Sector,HSR Layout,Bangalore Karnataka-560102, India</Text>
                                <Text style={{fontSize:12,marginTop:5}}>Tel: +91-80-47096828, 95333 88108, email:info@ubiqare.com</Text>
                                <Text style={{marginTop:10,marginBottom:10,textDecoration:'underline'}}>PRESCRIPTION (Teleconsultation)</Text>
                        </View>
                        <View style={{width:'25%',flexDirection: 'column'}}>
                                <Text style={{fontSize:12,fontWeight:'bold'}}>{props.doctorName}</Text>
                                <Text style={{fontSize:12}}>{props.doctorQualification} . {props.doctorProfSpeciality}</Text>
                        </View>
                      </View>
                    
                      
                      <View style={{width:'100%',flexDirection: 'row',marginTop:10,alignItems:'start'}} fixed>
                        <View style={{width:'100%',flexDirection: 'column'}}>
                        <hr style={styles.line}></hr>
                        </View>
                      </View>
                      
                    <View style={{width:'100%',flexDirection: 'row',marginTop:10,alignItems:'start'}} fixed>
                      <View style={{width:'80%',flexDirection: 'column',margin:10}}>
                        <Text style={{fontSize:12,marginTop:5}}>Patient Name: {patientData.name}</Text>
                        <Text style={{fontSize:12,marginTop:5}}>MRD No: N/A</Text>
                        <Text style={{fontSize:12,marginTop:5}}>Gender: {patientData.gender}</Text>
                        <Text style={{fontSize:12,marginTop:5}}>Age: {patientData.age} Years</Text>
                        <Text style={{fontSize:12,marginTop:5}}>Visit Type: {patientData.appointmentType.label}</Text>
                      </View>
                      <View style={{width:'20%',flexDirection: 'column'}}>
                        <Text style={{fontSize:12,marginTop:5}}>Address: PATIENT ADDRESS</Text>
                      </View>
                      </View>
                      <View style={{width:'100%',flexDirection: 'row',marginTop:10,alignItems:'start'}} fixed>
                        <View style={{width:'100%',flexDirection: 'column'}}>
                        <hr style={styles.line}></hr>
                        </View>
                      </View>
                     
                    
                      <View style={{width:'100%',flexDirection: 'row',marginTop:10,alignItems:'start',position:'absolute',top:785}} fixed>
                        <View style={{width:'100%',flexDirection: 'column'}} fixed>
                        <hr style={styles.line}></hr>
                        </View>
                      </View>
                      <View style={{width:'100%',flexDirection: 'row',marginTop:10,alignItems:'center',textAlign:'center',position:'absolute',top:800}} fixed>
                        <View style={{width:'10%',flexDirection: 'column'}} fixed>
                        <Text style={styles.pageNumber} render={({pageNumber,totalPages})=>(
                          `${pageNumber} / ${totalPages}`
                        )}/>
                        </View>
                        <View style={{width:'80%',flexDirection: 'column'}}>
                                <Text style={{fontSize:8}}>First floor of house No.5,14th Main,15th Cross,4th Sector,HSR Layout,Bangalore Karnataka-560102, India</Text>
                                <Text style={{fontSize:8,marginTop:5}}>Tel: +91-80-47096828, 95333 88108, email:info@ubiqare.com</Text>
                                
                        </View>
                        <View style={{width:'10%',flexDirection: 'column'}} fixed>
                        <Text style={{fontSize:8}}>Date: {patientData.aptSlotsDto.aptSlotStartTime.slice(0,10)}</Text>
                        </View>
                      </View>
                    
                        {clinincalNote.cheifComplaint !== "" ? 
                        <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Chief Complaint (CC)</Text>
                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.cheifComplaint}</Text>
                        </View>
                      :<View></View>}
                      
                      {isSymptomSaved === true ?
                      <View style={{width:'100%',flexDirection: 'column',marginTop:10}}>
          
                            <View style={{width:'100%',flexDirection: 'row',marginTop:10}}>
                              <View style={{width:'100%',flexDirection: 'column',marginLeft:20}}>
                                <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Covid Symptoms</Text>
                                  {(clinincalNote.symptoms.covidSymptoms).map((symptom, index) => (
                                    <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                      <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                        <Text style={{fontSize:10,marginTop:5}}>{symptom.label} : </Text>
                                      </View>
                                      <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                        <Text style={{fontSize:10,marginTop:5}}>{symptom.value}</Text>
                                      </View>
                                    </View>      
                                  ))}
                                  </View>
                            </View>
                      
                      <View style={{width:'100%',flexDirection: 'row',marginTop:10}}>
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>General Symptoms</Text>
                            {(clinincalNote.symptoms.generalSymptoms).map((symptom, index) => (
                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.label} : </Text>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.value}</Text>
                                </View>
                              </View>      
                            ))}
                            </View>
                      </View>

                      
                      <View style={{width:'100%',flexDirection: 'row',marginTop:10}}>
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>CVS ( Cardiovascular System )</Text>
                            {(clinincalNote.symptoms.cvsSymptoms).map((symptom, index) => (
                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.label} : </Text>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.value}</Text>
                                </View>
                              </View>      
                            ))}
                            </View>
                      </View>

                      
                      <View wrap={false} style={{width:'100%',flexDirection: 'row',marginTop:10}} break>
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Respiratory System</Text>
                            {(clinincalNote.symptoms.respiratorySymptoms).map((symptom, index) => (
                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.label} : </Text>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.value}</Text>
                                </View>
                              </View>      
                            ))}
                            </View>
                      </View>
                      
                      <View wrap={false} style={{width:'100%',flexDirection: 'row',marginTop:10}}>
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>NS ( Nervous System )</Text>
                            {(clinincalNote.symptoms.nsSymptoms).map((symptom, index) => (
                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.label} : </Text>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.value}</Text>
                                </View>
                              </View>      
                            ))}
                            </View>
                      </View>

                      
                      <View wrap={false} style={{width:'100%',flexDirection: 'row',marginTop:10}}>
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Muskeloskeletal</Text>
                            {(clinincalNote.symptoms.muskeloskeletal).map((symptom, index) => (
                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.label} : </Text>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.value}</Text>
                                </View>
                              </View>      
                            ))}
                            </View>
                      </View>

                      
                      <View wrap={false} style={{width:'100%',flexDirection: 'row',marginTop:10}}>
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>GIT ( Gastrointestinal Tract )</Text>
                            {(clinincalNote.symptoms.gitSymptoms).map((symptom, index) => (
                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.label} : </Text>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.value}</Text>
                                </View>
                              </View>      
                            ))}
                            </View>
                      </View>

                      
                      <View wrap={false} style={{width:'100%',flexDirection: 'row',marginTop:10}}>
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Urinary</Text>
                            {(clinincalNote.symptoms.urinarySymptoms).map((symptom, index) => (
                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.label} : </Text>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.value}</Text>
                                </View>
                              </View>      
                            ))}
                            </View>
                      </View>

                      
                      <View wrap={false} style={{width:'100%',flexDirection: 'row',marginTop:10}}>
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Skin</Text>
                            {(clinincalNote.symptoms.skinSymptoms).map((symptom, index) => (
                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.label} : </Text>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.value}</Text>
                                </View>
                              </View>      
                            ))}
                            </View>
                      </View>

                      
                      <View wrap={false} style={{width:'100%',flexDirection: 'row',marginTop:10}}>
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Eye</Text>
                            {(clinincalNote.symptoms.eyeSymptoms).map((symptom, index) => (
                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.label} : </Text>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <Text style={{fontSize:10,marginTop:5}}>{symptom.value}</Text>
                                </View>
                              </View>      
                            ))}
                            </View>
                      </View>
                        </View>:<View></View>}
                
                        {clinincalNote.HPI !== "" ? 
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20,marginTop:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>History of Present Illness (CC)</Text>
                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.HPI}</Text>
                        </View>
                      :<View></View>}
                      {isVitalSaved === true ? 
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20,marginTop:20}} break={isSymptomSaved === true ? true : false} >
                            <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Vitals</Text>
                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                      <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                          <Text style={{fontSize:10,marginTop:5}}>Height : </Text>
                                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.vitals.height} {clinincalNote.vitals.hunit}</Text>          
                                      </View>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                    <Text style={{fontSize:10,marginTop:5}}>Weight : </Text>
                                    <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.vitals.weight} {clinincalNote.vitals.wunit}</Text>
                                  </View>
                                </View>
                                </View>

                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                      <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                          <Text style={{fontSize:10,marginTop:5}}>Temperature : </Text>
                                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.vitals.temperature} {clinincalNote.vitals.tempunit}</Text>
                                      </View>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                    <Text style={{fontSize:10,marginTop:5}}>Pulse : </Text>
                                    <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.vitals.pulse} bpm</Text>
                                  </View>
                                </View>
                                </View>

                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                      <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                          <Text style={{fontSize:10,marginTop:5}}>Oxygen Saturation (SPO2) : </Text>
                                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.vitals.spO2} ppm</Text>
                                      </View>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                    <Text style={{fontSize:10,marginTop:5}}>Respiratory Rate : </Text>
                                    <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.vitals.respiratoryRate} ppm</Text>
                                  </View>
                                </View>
                                </View>

                              <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                      <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                          <Text style={{fontSize:10,marginTop:5}}>Systolic : </Text>
                                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.vitals.systolic} mmHG</Text>
                                      </View>
                                </View>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                  <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                        <Text style={{fontSize:10,marginTop:5}}>Diastolic : </Text>
                                        <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.vitals.diastolic} mmHG</Text>
                                    
                                  </View>
                                </View>
                                </View>

                                <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                                      <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                                          <Text style={{fontSize:10,marginTop:5}}>GRBS : </Text>
                                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.vitals.grbs} mg / DL</Text>
                                      </View>
                                </View>
                                
                                </View>

                          

                         

                            <View style={{width:'100%',flexDirection: 'row',marginLeft:20}}>
                            <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                              
                            </View>
                            <View style={{width:'50%',flexDirection: 'column',marginLeft:20}}>
                              
                            </View>
                            </View>
    
                        </View>:<View></View>}

                        {clinincalNote.labOrders !== "" ? 
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20,marginTop:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Lab Orders</Text>
                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.labOrders}</Text>
                        </View>
                      :<View></View>}

                    {isLabTestSaved === true ?
                        <View style={{width:'100%',flexDirection: 'column',marginTop:20}} >
                    <Text style={{fontSize:12,marginTop:5,marginLeft:20,fontWeight:'bold'}}>Lab Tests</Text>
                      <View style={styles.tableRow}>
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Sl.No</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Service Code</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Test Type</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Test Name</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Instruction</Text> 
                              </View>
                              
                          </View>
                          
                       {clinincalNote.lab_tests.length !== 0 ?
                       
                       clinincalNote.lab_tests.map((test,index)=>(
                            <View style={styles.table}> 
                                    
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{index + 1}</Text> 
                                      </View> 
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{test.code}</Text> 
                                      </View> 
                                      <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{test.test_type}</Text> 
                                      </View>
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{test.name}</Text> 
                                      </View>
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{test.instruction}</Text> 
                                      </View>
                                      
                                    </View> 
                                  </View>
                       )):<View></View>}
                       
                        </View>:<View></View>}

                  {isPrescriptionSaved === true ?
                      <View style={{width:'100%',flexDirection: 'column',marginTop:20}}>
                    <Text style={{fontSize:12,marginTop:5,marginLeft:20,fontWeight:'bold'}}>Prescription</Text>
                      <View style={styles.tableRow}>
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Sl.No</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Medicine Name</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Quantity</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Instruction</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Dispense Form</Text> 
                              </View>
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Pharmacy Name</Text> 
                              </View> 
                          </View>
                          
                       {clinincalNote.prescription.length !== 0 ?
                       
                       clinincalNote.prescription.map((prescription,index)=>(
                            <View style={styles.table}> 
                                    
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{index + 1}</Text> 
                                      </View> 
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{prescription.name}</Text> 
                                      </View> 
                                      <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{prescription.quantity}</Text> 
                                      </View>
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{prescription.instruction}</Text> 
                                      </View>
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{prescription.form}</Text> 
                                      </View>
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{prescription.farmacy_name}</Text> 
                                      </View> 
                                    </View> 
                                  </View>
                       )):<View></View>}
                       
                        </View>:<View></View>}

                        {clinincalNote.treatmentPlan !== "" ? 
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20,marginTop:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Treatment Plan</Text>
                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.treatmentPlan}</Text>
                        </View>
                      :<View></View>}

                {clinincalNote.otherDetails !== "" ? 
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20,marginTop:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Other Details</Text>
                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.otherDetails}</Text>
                        </View>
                      :<View></View>}

                {isAdditionalInstSaved === true ?
                    <View style={{width:'100%',flexDirection: 'column',marginTop:20}}>
                    <Text style={{fontSize:12,marginTop:5,marginLeft:20,fontWeight:'bold'}}>Additional Instruction</Text>
                      <View style={styles.tableRow}>
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Sl.No</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Header</Text> 
                              </View> 
                              <View style={styles.tableCol}> 
                                <Text style={styles.tableCell}>Content</Text> 
                              </View> 
                          </View>
                          
                       {clinincalNote.additionalDetails.length !== 0 ?
                       
                       clinincalNote.additionalDetails.map((addDetails,index)=>(
                            <View style={styles.table}> 
                                    
                                    <View style={styles.tableRow}> 
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{index + 1}</Text> 
                                      </View> 
                                      <View style={styles.tableCol}> 
                                        <Text style={styles.tableCell}>{addDetails.header}</Text> 
                                      </View> 
                                      <View style={styles.tableCol}>
                                        <Text style={styles.tableCell}>{addDetails.value}</Text> 
                                      </View>
                                      
                                    </View> 
                                  </View>
                       )):<View></View>}
                       
                        </View>:<View></View>}

              {clinincalNote.triage !== "" ? 
                        <View style={{width:'100%',flexDirection: 'column',marginLeft:20,marginTop:20}}>
                          <Text style={{fontSize:12,marginTop:5,fontWeight:'bold'}}>Triage</Text>
                          <Text style={{fontSize:10,marginTop:5}}>{clinincalNote.triage}</Text>
                        </View>
                      :<View></View>}

                <View style={{width:'100%',flexDirection: 'column',padding:20,alignItems:'flex-end'}} break={isSymptomSaved === true ? true : false}>
                        <Image src={clinincalNote.doctorSignature} style={{ width: 80, height: 40}}/>
                        <Text style={{fontSize:12,fontWeight:'bold'}}>{props.doctorName}</Text>
                        <Text style={{fontSize:12}}>{props.doctorQualification} . {props.doctorProfSpeciality}</Text>
                                
                      </View>

                    </View>      
                </Page>
              </Document>
    );
      return (
      <div>
        <Dialog open={openPdfViewPopup} onClose={handlePdfViewClosePopup} fullScreen>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>PDF</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handlePdfViewClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
            <PDFViewer showToolbar width={'100%'} height={'100%'}>
              <MyDoc />
            </PDFViewer>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={()=>{
              uploadEncounterReport()
              
              
              
            }}>
                      Sign and Close
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }

    const ShowSymptoms = () =>{
      return(
        <>
        <Paper style={{maxHeight: '50vh', overflow: 'auto',margin:2}}>
        <Typography fontSize={16} fontWeight={'bold'} margin={1}>Covid Symptoms</Typography>
          {(clinincalNote.symptoms.covidSymptoms).map((symptom, index) => (     
           <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>    
              ))}
        <Typography fontSize={16} fontWeight={'bold'} margin={1}>General Symptoms</Typography>
        {(clinincalNote.symptoms.generalSymptoms).map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}    
        <Typography fontSize={16} fontWeight={'bold'} margin={1}>CVS ( Cardiovascular System )</Typography>
          {(clinincalNote.symptoms.cvsSymptoms).map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
        <Typography fontSize={16} fontWeight={'bold'} margin={1}>Respiratory System</Typography>
        {(clinincalNote.symptoms.respiratorySymptoms).map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
        <Typography fontSize={16} fontWeight={'bold'} margin={1}>NS ( Nervous System )</Typography>
        {(clinincalNote.symptoms.nsSymptoms).map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
        <Typography fontSize={16} fontWeight={'bold'} margin={1}>Muskeloskeletal</Typography>
        {(clinincalNote.symptoms.muskeloskeletal).map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
        <Typography fontSize={16} fontWeight={'bold'} margin={1}>GIT ( Gastrointestinal Tract )</Typography>
        {(clinincalNote.symptoms.gitSymptoms).map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
         <Typography fontSize={16} fontWeight={'bold'} margin={1}>Urinary</Typography>
        {(clinincalNote.symptoms.urinarySymptoms).map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
         <Typography fontSize={16} fontWeight={'bold'} margin={1}>Skin</Typography>
        {(clinincalNote.symptoms.skinSymptoms).map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
         <Typography fontSize={16} fontWeight={'bold'} margin={1}>Eye</Typography>
        {(clinincalNote.symptoms.eyeSymptoms).map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}
              </Paper>
        </>
      );
    }

    const ShowVitals = () =>{
      return(
        <>
        <Grid container spacing={2} margin={1}>
          <Grid item xs={12} sm={6}>
              <Typography fontSize={16}>Height : </Typography>
          </Grid>
          <Grid item xs={12} sm={6}> 
            <Typography fontSize={16} fontWeight={'bold'}>{clinincalNote.vitals.height} {clinincalNote.vitals.hunit}</Typography>           
          </Grid>
        </Grid>

        <Grid container spacing={2} margin={1}>
          <Grid item xs={12} sm={6}>
              <Typography fontSize={16}>Weight : </Typography>
          </Grid>
          <Grid item xs={12} sm={6}> 
            <Typography fontSize={16} fontWeight={'bold'}>{clinincalNote.vitals.weight} {clinincalNote.vitals.wunit}</Typography>           
          </Grid>
        </Grid>

        <Grid container spacing={2} margin={1}>
          <Grid item xs={12} sm={6}>
              <Typography fontSize={16}>Temperature : </Typography>
          </Grid>
          <Grid item xs={12} sm={6}> 
            <Typography fontSize={16} fontWeight={'bold'}>{clinincalNote.vitals.temperature} {clinincalNote.vitals.tempunit}</Typography>           
          </Grid>
        </Grid>

        <Grid container spacing={2} margin={1}>
          <Grid item xs={12} sm={6}>
              <Typography fontSize={16}>Pulse : </Typography>
          </Grid>
          <Grid item xs={12} sm={6}> 
            <Typography fontSize={16} fontWeight={'bold'}>{clinincalNote.vitals.pulse} bpm</Typography>           
          </Grid>
        </Grid>

        <Grid container spacing={2} margin={1}>
          <Grid item xs={12} sm={6}>
              <Typography fontSize={16}>Oxygen Saturation (SPO2) : </Typography>
          </Grid>
          <Grid item xs={12} sm={6}> 
            <Typography fontSize={16} fontWeight={'bold'}>{clinincalNote.vitals.spO2} ppm</Typography>           
          </Grid>
        </Grid>

        <Grid container spacing={2} margin={1}>
          <Grid item xs={12} sm={6}>
              <Typography fontSize={16}>Systolic : </Typography>
          </Grid>
          <Grid item xs={12} sm={6}> 
            <Typography fontSize={16} fontWeight={'bold'}>{clinincalNote.vitals.systolic} mmHG</Typography>           
          </Grid>
        </Grid>

        <Grid container spacing={2} margin={1}>
          <Grid item xs={12} sm={6}>
              <Typography fontSize={16}>Diastolic : </Typography>
          </Grid>
          <Grid item xs={12} sm={6}> 
            <Typography fontSize={16} fontWeight={'bold'}>{clinincalNote.vitals.diastolic} mmHG</Typography>           
          </Grid>
        </Grid>

        <Grid container spacing={2} margin={1}>
          <Grid item xs={12} sm={6}>
              <Typography fontSize={16}>Respiratory Rate : </Typography>
          </Grid>
          <Grid item xs={12} sm={6}> 
            <Typography fontSize={16} fontWeight={'bold'}>{clinincalNote.vitals.respiratoryRate} ppm</Typography>           
          </Grid>
        </Grid>

        <Grid container spacing={2} margin={1}>
          <Grid item xs={12} sm={6}>
              <Typography fontSize={16}>GRBS : </Typography>
          </Grid>
          <Grid item xs={12} sm={6}> 
            <Typography fontSize={16} fontWeight={'bold'}>{clinincalNote.vitals.grbs} mg / DL</Typography>           
          </Grid>
        </Grid>
        </>
      )
    }

    const ShowPrescriptions = () =>{
        return(
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500,marginTop:2}} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Medicine Name</StyledTableCell>
            <StyledTableCell align="center">Quantity</StyledTableCell>
            <StyledTableCell align="center">Instruction</StyledTableCell>
            <StyledTableCell align="center">Dispense Form</StyledTableCell>
            <StyledTableCell align="center">Pharmacy Name</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(clinincalNote.prescription).map((details,index) => (
            <StyledTableRow key={details.name}>
              <StyledTableCell component="th" scope="row">
                {details.name}
              </StyledTableCell>
              <StyledTableCell align="center">{details.quantity}</StyledTableCell>
              <StyledTableCell align="center">{details.instruction}</StyledTableCell>
              <StyledTableCell align="center">{details.form}</StyledTableCell>
              <StyledTableCell align="center">{details.farmacy_name}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        );
    }
    const ShowInstructions = () =>{
      return(
          <TableContainer component={Paper}>
    <Table sx={{ minWidth: 400 }} aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Header</StyledTableCell>
          <StyledTableCell align="center">Content</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(clinincalNote.additionalDetails).map((details,index) => (
          <StyledTableRow key={details.heading}>
            <StyledTableCell component="th" scope="row">
              {details.heading}
            </StyledTableCell>
            <StyledTableCell align="center">{details.content}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
     
      );
    }
    const ShowLabTest = () =>{
      return(
          <TableContainer component={Paper}>
    <Table sx={{ minWidth: 400 }} aria-label="customized table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Service Code</StyledTableCell>
          <StyledTableCell align="center">Lab Test</StyledTableCell>
          <StyledTableCell align="center">Instruction</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(clinincalNote.lab_tests).map((details,index) => (
          <StyledTableRow key={details.name}>
            <StyledTableCell component="th" scope="row">
              {details.code}
            </StyledTableCell>
            <StyledTableCell align="center">{details.name}</StyledTableCell>
            <StyledTableCell align="center">{details.instruction}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
      );
    } 

    function LabTestPopUpCN(){
     
      const [testType, setTestType] = React.useState('');
      const [testName, setTestName] = React.useState('');
      const [instruction, setInstruction] = React.useState('');
      const [labTestListData,setLabTestListData] = React.useState(clinincalNote.lab_tests);
      const [testNameList,setTestNameList] = React.useState([])
      const [testTypeList,setTestTypeList] = React.useState([])
      const [radioValue, setRadioValue] = React.useState(0)
      const [changeDataSource, setChangeDataSource] = React.useState(false)
      console.log("LTData",labTestListData)

      const [errorText,setErrorText] = React.useState({
        testType:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        testName:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        instruction:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
      })
  
      function handleValidation(){
      if(testType === '' || testType !==''){
          if(testType === ''){
            setErrorText(previous => ({...previous,testType:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(testType !== ''){
            setErrorText(previous => ({...previous,testType:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }

        if(testName === '' || testName !==''){
          if(testName === ''){
            setErrorText(previous => ({...previous,testName:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(testName !== ''){
            setErrorText(previous => ({...previous,testName:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }
      
        if(instruction === "" || instruction !== ""){
          if(instruction === ""){
            setErrorText(previous => ({...previous,instruction:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(instruction !== ""){
            setErrorText(previous => ({...previous,instruction:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              return false
          }       
      }
      
      console.log("RESULTS............");
      
    }

      const handleDelete = (deleteIndex) => {
        setLabTestListData((prevData) =>
          prevData.filter((_, index) => index !== deleteIndex)
        );
      };
      const handlRadioChange = (event) => {
        setRadioValue(event.target.value);
        {event.target.value === 0 ? MasterDataEncounterSearch('','TEST').then(res =>{
          console.log(res)
          if(res.status === 200){
            setTestNameList(res.data.content)
          }else{
            setTestNameList([])
          }
          
        }) : getLabTestNameList('').then(res =>{
          console.log(res)
          if(res.status === 200){
            setTestNameList(res.data)
          }else{
            setTestNameList([])
          }
          
        })}
      };

      function handleLabTestSave(){
        clinincalNote.lab_tests = labTestListData;
        console.log("added",clinincalNote)
        handleLabTestClosePopup()
        setIsLabTestSaved(true)
        toast.success('Lab Tests Saved Successfully', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
      }

      React.useEffect(() => {
        MasterDataEncounterSearch('','TEST_TYPE').then(res =>{
          console.log(res)
          if(res.status === 200){
            setTestTypeList(res.data.content)
          }else{
            setTestTypeList([])
          }
          
        })
        MasterDataEncounterSearch('','TEST').then(res =>{
          console.log(res)
          if(res.status === 200){
            setTestNameList(res.data.content)
          }else{
            setTestNameList([])
          }
          
        })
      },[])
      return (
      <div>
        <Dialog open={openLabTestPopup} onClose={handleLabTestClosePopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add Lab Test</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleLabTestClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6} padding={2}></Grid>
          <Grid item xs={12} sm={6} padding={2}>
        <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Select Data Source</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={radioValue}
        onChange={handlRadioChange}
      >
        <FormControlLabel value={0} control={<Radio 
          onChange={()=>{setRadioValue(0)
          setTestNameList([])
          setChangeDataSource(false)
          MasterDataEncounterSearch('','TEST').then(res =>{
            console.log(res)
            if(res.status === 200){
              setTestNameList(res.data.content)
            }else{
              setTestNameList([])
            }
            
          })
          }}
        />} label="Internal" />
        <FormControlLabel value={1} control={<Radio 
          onChange={()=>{setRadioValue(1)
            setTestNameList([])
          setChangeDataSource(true)
          getLabTestNameList('').then(res =>{
            console.log(res)
            if(res.status === 200){
              setTestNameList(res.data)
            }else{
              setTestNameList([])
            }
            
          })
          }}
        />} label="SnowMed" />
        
      </RadioGroup>
    </FormControl>
    </Grid>
    
        <Grid item xs={12} sm={6} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'}>Test Type</Typography>          
                <FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.typeID) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
                      setTestTypeList([])
                      MasterDataEncounterSearch(event.target.value,'TEST_TYPE').then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setTestTypeList(res.data.content)
                        }else{
                          setTestTypeList([])
                        }
                        
                      })
                      
                      setTestType(newValue.label)
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.typeID){
                      console.log("seletced val.......",newValue.label)
                      setTestType(newValue.label)
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={testTypeList}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.typeID}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Search Test Type"
                      helperText={errorText.testType.error === true ? errorText.testType.errorText:""}
                      error={errorText.testType.error === true ? true : false}
                      autoFocus={errorText.testType.error === true ? true : false}
                    />}
                  />
                    </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Test Name</Typography>
                {changeDataSource === false ? 
                <FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.typeID) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
                      setTestNameList([])
                      MasterDataEncounterSearch(event.target.value,'TEST').then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setTestNameList(res.data.content)
                        }else{
                          setTestNameList([])
                        }
                        
                      })
                      
                      setTestName(newValue.label)
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.typeID){
                      console.log("seletced val.......",newValue.label)
                      setTestName(newValue.label)
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={testNameList}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.typeID}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Search Test Name"
                      helperText={errorText.testName.error === true ? errorText.testName.errorText:""}
                      error={errorText.testName.error === true ? true : false}
                      autoFocus={errorText.testName.error === true ? true : false}
                    />}
                  />
                    </FormControl>
                :<FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
                      setTestNameList([])
                      getLabTestNameList(event.target.value).then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setTestNameList(res.data)
                        }else{
                          setTestNameList([])
                        }
                        
                      })
                      
                      setTestName(newValue.term)
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.id){
                      console.log("seletced val.......",newValue.term)
                      setTestName(newValue.term)
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={testNameList}
                    getOptionLabel={(option) => option.term}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.term}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Search Test Name"
                    helperText={errorText.testName.error === true ? errorText.testName.errorText:""}
                    error={errorText.testName.error === true ? true : false}
                    autoFocus={errorText.testName.error === true ? true : false}
                    />}
                  />
                    </FormControl>}
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Instruction</Typography>
                <TextField
                  name="Instruction"
                  required
                  fullWidth
                  id="instruction"
                  label="Enter Instruction"
                  size='small'
                  value={instruction}
                  onChange={(e) =>setInstruction(e.target.value)}
                  helperText={errorText.instruction.error === true ? errorText.instruction.errorText:""}
                  error={errorText.instruction.error === true ? true : false}
                  autoFocus={errorText.instruction.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              </Grid>
        </Grid>
        {labTestListData.length != 0 ? 
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Service Code</StyledTableCell>
            <StyledTableCell align="center">Lab Test</StyledTableCell>
            <StyledTableCell align="center">Instruction</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {labTestListData.map((details,index) => (
            <StyledTableRow key={details.name}>
              <StyledTableCell component="th" scope="row">
                {details.code}
              </StyledTableCell>
              <StyledTableCell align="center">{details.name}</StyledTableCell>
              <StyledTableCell align="center">{details.instruction}</StyledTableCell>
              <StyledTableCell align="center">
                <IconButton onClick={() =>{handleDelete(index)}}>
                    <DeleteIcon></DeleteIcon>
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
       : <div></div>}   
          </DialogContent>
          <DialogActions>
          <Button variant="outlined" sx={{width:'30%',borderColor:'#651fff'}} onClick={() =>{
            let res = handleValidation();
            if(res === false){
                setLabTestListData(labTestListData.concat({
                  orgid: user.orgID,
                  appointmentid: props.consultationId,
                  uuid: patientData.personUuId,
                  code:"2345",
                  name:testName,
                  form:"",
                  instruction:instruction,
                  test_type:testType,
                  note:"",
                  file:"",
                  remark:""
              
              }))
            }else{
              toast("Please Check your Input Data")
            }
            
            }}>
                      Add Lab Test
                    </Button>
            
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={handleLabTestSave}>
                      Save
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }
    function PastHistoryEditVitals(){
     
      const [height, setHeight] = React.useState('');
      const [weight, setWeight] = React.useState('');
      const [temp, setTemp] = React.useState('');
      const [spo2, setSPO2] = React.useState('');
      const [pulse, setPulse] = React.useState('');
      const [systolic, setSystolic] = React.useState('');
      const [diastolic, setDiastolic] = React.useState('');
      const [respiratoryRate, setRespiratoryRate] = React.useState('');
      const [grbs, setGRBS] = React.useState('');
      const [hunit, setHunit] = React.useState('');
      const [wunit, setWunit] = React.useState('');
      const [tempUnit, setTempUnit] = React.useState('');
      const [tempText, setTempText] = React.useState('');
      const [hunitToggle, setHunitToggle] = React.useState(false);
      const [tempUnitToggle, setTempUnitToggle] = React.useState(false);


      React.useEffect(()=>{
        vitalDetails(editVitalID).then(res =>{
          console.log("Vital Edit Data",res)
          if(res.status === 200){
            setHeight(res.data.height)
            setWeight(res.data.weight)
            setTemp(res.data.temperature)
            setSPO2(res.data.oxygensaturation === null ? '' : res.data.oxygensaturation)
            setPulse(res.data.pulse)
            setSystolic(res.data.systolic)
            setDiastolic(res.data.diastolic)
            setRespiratoryRate(res.data.respiratoryrate === null ? '' : res.data.respiratoryrate)
            setGRBS(res.data.grbs)
            setHunit(res.data.hunit)
            setWunit(res.data.wunit)
            setTempUnit(res.data.tempunit)
           
          }
        })
      },[])

      return (
      <div>
        <Dialog open={showVitalPopup} onClose={handleHistoryVitalsClosePopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Update Vitals</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleHistoryVitalsClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={9}>
                <Typography fontSize={16} fontWeight={'bold'}>Height</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>In</Typography>
              <AntSwitch inputProps={{ 'aria-label': 'ant design' }} onChange={()=>{
                setHunitToggle(!hunitToggle)
                if(hunitToggle === true){
                  setHunit("cm")
                }else{
                  setHunit("In")
                }
              }}
              checked= {hunit === 'cm' ? true : false}
              />
              <Typography>cm</Typography>
            </Stack>
            </Grid>
          </Grid>
                <TextField
                  value={height}
                  name="height"
                  required
                  fullWidth
                  id="height"
                  label="Enter Height"
                  size='small'
                  onChange={(e) =>{setHeight(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{hunit}</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Weight (in Kg)</Typography>
                <TextField
                  required
                  fullWidth
                  id="weight"
                  value={weight}
                  label="Enter Weight"
                  name="weight"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setWeight(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                  }}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Grid container spacing={2}>
            <Grid item xs={6} sm={9}>
                <Typography fontSize={16} fontWeight={'bold'}>Temperature</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>°F</Typography>
              <AntSwitch inputProps={{ 'aria-label': 'ant design' }} onChange={()=>{
                setTempUnitToggle(!tempUnitToggle)
                if(tempUnitToggle === true){
                  setTemp('')
                  setTempUnit("°C")
                  setTempText('Normal Range 26 - 46')
                }else{
                  setTemp('')
                  setTempUnit("°F")
                  setTempText('Normal Range 78.8 - 114.8')
                }
              }} 
              checked={tempUnit === '°C' ? true : false}
              />
              <Typography>°C</Typography>
            </Stack>
            </Grid>
          </Grid>
                <TextField
                  name="Temperature"
                  required
                  fullWidth
                  value={temp}
                  id="temperature"
                  label={tempText}
                  size='small'
                  onChange={(e) =>{setTemp(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{tempUnit}</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>SPO2</Typography>
                <TextField
                  required
                  fullWidth
                  value={spo2}
                  id="spo2"
                  label="Normal Range 1 - 100"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setSPO2(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ppm</InputAdornment>,
                  }}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Pulse</Typography>
                <TextField
                  required
                  fullWidth
                  value={pulse}
                  id="pulse"
                  label="Normal Range 26 - 220"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setPulse(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">BPM</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Blood Pressure</Typography>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} padding={2}>
                    <TextField
                      fullWidth
                      value={systolic}
                      id="bloodpressure"
                      name="BloodPressure"
                      label="30 - 240"
                      autoComplete="family-name"
                      size='small'
                      onChange={(e) =>{setSystolic(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={12} sm={0.1} padding={2}>
                <Typography fontSize={25}>/</Typography>
                </Grid>
                
                  <Grid item xs={12} sm={4} padding={2}>
                    <TextField
                      fullWidth
                      value={diastolic}
                      id="bloodpressure"
                      name="BloodPressure"
                      label="10 - 200"
                      autoComplete="family-name"
                      size='small'
                      onChange={(e) =>{setDiastolic(e.target.value)}}
                    />
                </Grid>
                <Grid item xs={12} sm={1} padding={2}>
                <Typography sx={{marginTop:2}}>mmHG</Typography>
                </Grid>
                  </Grid>
                
              </Grid>

            
              
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Respiratory Rate</Typography>
                <TextField
                  name="Temperature"
                  required
                  fullWidth
                  value={respiratoryRate}
                  id="temperature"
                  label="Normal Range 5 - 75"
                  size='small'
                  onChange={(e) =>{setRespiratoryRate(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ppm</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>GRBS</Typography>
                <TextField
                  required
                  fullWidth
                  value={grbs}
                  id="spo2"
                  label="Normal Range 5 - 1000"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setGRBS(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">mg / DL</InputAdornment>,
                  }}
                />
              </Grid>
        </Grid>
          
                
          </DialogContent>
          <DialogActions>
            
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={() =>{
              updateVitals(editVitalID,patientData.personUuId,patientData.consultationId,weight,height,
                pulse,systolic,diastolic,temp,respiratoryRate,grbs,hunit,wunit,tempUnit,spo2).then(res =>{
                  console.log(res)
                  {res === 200 ? confirmAlert({
                    title:res.message,
                    buttons: [
                      {
                        label: 'Okay',
                        onClick: () => {
                          vitalList(patientData.personUuId).then(res =>{
                            console.log(res)
                          })
                        }
                      },
                    ],
                  }):toast("Error Occurred")}
                  }
                  
                )
            }}>
                      Save
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }
    function ShowVitalDetails(props){
     
      const [patientName, setPatientName] = React.useState(props.doctorName);
      const [doctorName, setDoctorName] = React.useState(patientData.name);
      const [height, setHeight] = React.useState('');
      const [weight, setWeight] = React.useState('');
      const [temp, setTemp] = React.useState('');
      const [spo2, setSPO2] = React.useState('');
      const [pulse, setPulse] = React.useState('');
      const [systolic, setSystolic] = React.useState('');
      const [diastolic, setDiastolic] = React.useState('');
      const [grbs, setGRBS] = React.useState('');
      const [respiratoryRate, setRespiratoryRate] = React.useState('');
      const [notes, setNotes] = React.useState('');
      const [hunit, setHunit] = React.useState('');
      const [wunit, setWunit] = React.useState('');
      const [tempUnit, setTempUnit] = React.useState('');
      const [fileName, setFileName] = React.useState('FileName.pdf');
      const [date, setDate] = React.useState('FileName.pdf');

      React.useEffect(()=>{
        vitalDetails(editVitalID).then(res =>{
          console.log("Vital Edit Data",res)
          if(res.status === 200){
            setHeight(res.data.height)
            setWeight(res.data.weight)
            setTemp(res.data.temperature)
            setSPO2(res.data.oxygensaturation === null ? '' : res.data.oxygensaturation)
            setPulse(res.data.pulse)
            setSystolic(res.data.systolic)
            setDiastolic(res.data.diastolic)
            setRespiratoryRate(res.data.respiratoryrate === null ? '' : res.data.respiratoryrate)
            setGRBS(res.data.grbs)
            setHunit(res.data.hunit)
            setWunit(res.data.wunit)
            setTempUnit(res.data.tempunit)
           
          }
        })
      },[])

      return (
      <div>
        <Dialog open={showVitalDetailsPopup} onClose={handleVitalDetailsClosePopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Vitals</DialogTitle>
            {/* <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',position:'absolute',right:72,top:8}}>
                  Download
                </Button> */}
            <IconButton
              aria-label="close"
              onClick={handleVitalDetailsClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>    
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} md={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Patient Name</Typography>
              <Typography fontSize={16}>{patientName}</Typography>
            </Grid>
            <Grid item xs={12} md={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Doctor Name</Typography>
              <Typography fontSize={16}>{doctorName}</Typography>
            </Grid>         
          </Grid>
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Height</Typography>
              <Typography fontSize={16}>{height} {hunit}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Weight</Typography>
              <Typography fontSize={16}>{weight} {wunit}</Typography>
            </Grid>   
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Temperature</Typography>
              <Typography fontSize={16}>{temp} {tempUnit}</Typography>
            </Grid>      
          </Grid> 
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>SPO2</Typography>
              <Typography fontSize={16}>{spo2}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Pulse</Typography>
              <Typography fontSize={16}>{pulse} BPM</Typography>
            </Grid>   
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Blood Pressure</Typography>
              <Typography fontSize={16}>{systolic} / {diastolic} mmHG</Typography>
            </Grid>      
          </Grid> 
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Respiratory Rate</Typography>
              <Typography fontSize={16}>{respiratoryRate} ppm</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>GRBS</Typography>
              <Typography fontSize={16}>{grbs}</Typography>
            </Grid>   
            <Grid item xs={12} sm={4} padding={2}>
            </Grid>      
          </Grid>
          {/* <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={3} padding={2}>
            <Button variant="outlined" endIcon={<KeyboardArrowDownIcon/>}>
                  {fileName}
                </Button>
            </Grid>
            <Grid item xs={12} sm={9} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Notes</Typography>
              <Typography fontSize={16}>{notes}</Typography>
            </Grid>         
          </Grid> */}
          </DialogContent>
        </Dialog>
            </div>
      );
    }
    function PastHealthRecord(){
      const [vitalListData, setVitalListData] = React.useState([]);
      const [showVital, setShowVital] = React.useState(false);
      
      
      React.useEffect(()=>{
        vitalList(patientData.personUuId).then(res =>{
          console.log(res)
          if(res.status === 200){
            setVitalListData(res.data)
            setShowVital(true)
          }else{
            console.log(res)
          }
          
        })
      },[])

      return(  
        <>
        {showVital === true ? 
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1000,marginTop:2}} aria-label="customized table">
              <TableHead>
                <TableRow>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell align="center">Temperature</StyledTableCell>
              <StyledTableCell align="center">Pulse</StyledTableCell>
              <StyledTableCell align="center">BP</StyledTableCell>
              <StyledTableCell align="center">SPO2</StyledTableCell>
              <StyledTableCell align="center">Resp.Rate</StyledTableCell>
              <StyledTableCell align="center">GRBS</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>  
            </TableRow>
          </TableHead>
          <TableBody>
            {vitalListData.length !== 0 ? vitalListData.map((details,index) => (
              <StyledTableRow key={details.createdOn}>
                <StyledTableCell component="th" scope="row">
                  {details.createdOn.slice(0,10)} {details.createdOn.slice(11,19)}
                </StyledTableCell>
                <StyledTableCell align="center">{details.temperature} {details.tempunit}</StyledTableCell>
                <StyledTableCell align="center">{details.pulse} bpm</StyledTableCell>
                <StyledTableCell align="center">{details.systolic} / {details.diastolic} mmHg</StyledTableCell>
                <StyledTableCell align="center">{details.oxygensaturation} ppm</StyledTableCell>
                <StyledTableCell align="center">{details.respiratoryrate} ppm</StyledTableCell>
                <StyledTableCell align="center">{details.grbs} mg / DL</StyledTableCell>
                <StyledTableCell align="center">
                <IconButton onClick={() =>{
                  setShowVitalPopup(true)
                  setEditVitalID(details.id)
                }}>
                    <EditIcon></EditIcon>
                </IconButton>
                <IconButton onClick={() =>{
                  deleteVitalByID(details.id).then(res =>{
                    console.log(res)
                    {res.status === 200 ? confirmAlert({
                      title: res.message,
                      buttons: [
                        {
                          label: 'Okay',
                          onClick: () => {
                            setShowVital(false)
                            vitalList(patientData.personUuId).then(res =>{
                              console.log(res)
                              if(res.status === 200){
                                setVitalListData(res.data)
                                setShowVital(true)
                              }else{
                                console.log(res)
                              }
                              
                            })
                          }
                        },
                      ],
                      closeOnEscape:false,
                      closeOnClickOutside:false,
                    }
                      
                    ): console.log(res)}
                  })
                }}>
                    <DeleteIcon></DeleteIcon>
                </IconButton>
                <IconButton onClick={() =>{
                  setShowVitalDetailsPopup(true)
                  setEditVitalID(details.id)
                }}>
                    <InfoRoundedIcon></InfoRoundedIcon>
                </IconButton>
              </StyledTableCell>
              </StyledTableRow>
            )):<div></div>}
          </TableBody>
        </Table>
      </TableContainer> : <Paper sx={{margin:5,padding:5}}>
      <Typography align='center'>No Data Available</Typography>
        </Paper> }
        </>        
              );
    }
    function DoctorSignature(){
      const [sigPad,setSigPad] = React.useState();
      return(
        <Grid item xs={12} md={12} marginTop={1}>
                  <Paper sx={{height:'auto'}}>
                    <Typography padding={1} fontWeight={'bold'}>Digital Signature</Typography>
                    <Grid container spacing={3}>
                      {signaturePreview === false ? 
                      <Grid item xs={6.5} md={6.5} margin={1}>
                        <Box sx={{width:'100%',height:'30vh',border:2,borderColor:'#9e9e9e',borderStyle:'dashed'}}>
                        <SignatureCanvas penColor='black' ref={(ref) => {setSigPad(ref)}}
                          canvasProps={{width: 'auto', height: 'auto', className: 'sigCanvas'}} />
                          </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={5} md={5} lg={5} margin={1}>
                          <Button variant="contained" sx={{bgcolor:'#696CFF'}} onClick={() => {sigPad.clear()}} size={'small'} fullWidth>
                                      Clear
                                  </Button>
                          </Grid>
                          <Grid item xs={5} md={5} lg={5} margin={1}>
                          <Button variant="contained" sx={{bgcolor:'#696CFF'}} onClick={() => {setSignatureURL(sigPad.toDataURL())
                          console.log("Saved file is",sigPad.toDataURL('base64string'))
                          // sigPad.clear()
                          sigPad.fromDataURL(signatureURL)
                          setSignaturePreview(true)
                          clinincalNote.doctorSignature = sigPad.toDataURL();
                          console.log("added",clinincalNote)
                          }} size={'small'} fullWidth>
                                      Save
                                  </Button>
                          </Grid>
                          </Grid>
                      </Grid> : <Grid item xs={6.5} md={6.5} margin={1}>
                        
                        <Box sx={{width:'100%',height:'25vh'}}>
                        <Avatar alt="Signature Image" variant='square'
                              src={signatureURL === null ? clinincalNote.doctorSignature : signatureURL} 
                              sx={{width:'auto',height:'auto', margin:'auto',maxHeight:'100%'}}/>
                        <Button variant="contained" sx={{bgcolor:'#696CFF'}} onClick={() =>{
                          setSignatureURL(null)
                          setSignaturePreview(false)
                          }} size={'small'} fullWidth>
                                  Edit
                              </Button>
                        </Box>
                        </Grid>}
                      <Grid item xs={1} md={1}>
                      <Typography fontWeight={'bold'} color={'#9e9e9e'}>or</Typography>
                      </Grid>
                      <Grid item xs={3.5} md={3.5} padding={2}>
                      <FileUploader 
                            handleChange={handleSignatureChange} 
                            name="file" 
                            types={fileTypes} 
                            multiple={false}
                            children={
                              <Box sx={{width:'100%',height:'auto',border:3,borderColor:'#651fff',borderStyle:'dashed',padding:3}}>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2}
                              >drag and drop to upload</Typography>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2} marginBottom={2}
                              >or</Typography>
                              <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF'}} onClick={() => {
                                setSignatureURL(null);
                                handleSignatureChange();
                              }} size={'small'} fullWidth>
                                  Upload
                              </Button>
                            </Box>
                            }
                            />
                      {/* {errorText.sigImage.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.sigImage.errorText}</Typography> : <div></div>} */}
                      </Grid>
                    </Grid>                
                    </Paper>
                  </Grid>
      );
    }
    
    
    React.useEffect(() => {
      // getClinincalData()
      console.log("APTID",props.consultationId)
      console.log("PATIENTID",props.patientID)
      getDetailsByAptId(props.consultationId).then(res =>{
        console.log("Patient Data",res)
        if(res.status === 200){
          setPatientData(res.data)
          getUpcomingApt(props.doctorUUID,"115").then(res =>{
            console.log(res)
            if(res.status === 200){
              setUpcomingAptData(res.data)
              setShowUpcomingApt(true)
            }else{
              console.log(res)
            }
          })
          getClinincalData()
        }else{
          console.log("Data not Available")
        }
        
      })
    
  },[])
    
console.log("sdasdadsadsa",openFaceSheet)
    return(
      <>
      {showTeleUI === true ? <Grid container spacing={2} marginBottom={1}>
          <Grid item xs={12} md={5}>
          <Iframe url={props.videoCallURL}
              width="100%"
              height= {420}
              id=""
              className=""
              display="block"
              position="relative"
              allow="camera;microphone"
              />
          
          <Paper elevation={3} sx={{height:'auto', marginTop:3}}> 
              <Grid container spacing={1}>
                <Grid item xs = {12}>
                <Box sx={{ bgcolor: '#fff',padding:2,maxWidth: { xs: 320, sm: 480 }}}>
                  <TabsUnstyled defaultValue={0}>
                  <TabsList sx={{maxWidth:'xs'}}>
                    <Tab onClick={() => {
                    setShowUpcomingApt(false)
                    getUpcomingApt(props.doctorUUID,"115").then(res =>{
                      console.log(res)
                      if(res.status === 200){
                        setUpcomingAptData(res.data)
                        setShowUpcomingApt(true)
                      }else{
                        console.log(res)
                      }
                    })
                  }}>Tele-consult</Tab>
                    <Tab onClick={() => {
                    setShowUpcomingApt(false)
                    getUpcomingApt(props.doctorUUID,"116").then(res =>{
                      console.log(res)
                      if(res.status === 200){
                        setUpcomingAptData(res.data)
                        setShowUpcomingApt(true)
                      }else{
                        console.log(res)
                      }
                    })
                  }}>In-Person</Tab>
                    <Tab onClick={() => {
                    setShowUpcomingApt(false)
                    getUpcomingApt(props.doctorUUID,"117").then(res =>{
                      console.log(res)
                      if(res.status === 200){
                        setUpcomingAptData(res.data)
                        setShowUpcomingApt(true)
                      }else{
                        console.log(res)
                      }
                    })
                  }}>In-Care</Tab>
                  </TabsList>
                  <TabPanel value={0}>
                  <ListItem component="div" disablePadding>
                    <ListItemButton sx={{ height: 56 }}>
                      <ListItemText
                        primary="Upcoming Appointments"
                        primaryTypographyProps={{
                          color: '#1212121',
                          fontWeight: 'medium',
                          variant: 'h6',
                        }}
                      />
                      {/* <IconButton
                        size="large">
                          <SwapVertOutlinedIcon/>
                      </IconButton>
                      <IconButton
                        size="large">
                          <FilterAltOutlinedIcon/>
                      </IconButton> */}
                    </ListItemButton>
                    </ListItem>
                    {showUpcomingApt === true ? upcomingAptData.map((aptData,index) =>(
                    <List sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemButton sx={{height:'auto'}}>
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src={person_img} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={aptData.patientName}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                align='left'
                              >
                                {aptData.patientGender} . {aptData.patientAge} 
                              </Typography>
                              {<Typography
                                sx={{ display: 'inline',position:'absolute',right:0}}
                                component="span"
                                variant="body2"
                                color="#8bc34a"
                                align='right'
                              >
                                {aptData.startDate.slice(0,10)} | {aptData.startDate.slice(11,16)}
                              </Typography>}
                            </React.Fragment>
                          }
                        />
                        </ListItemButton>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>)):<Paper sx={{margin:5,padding:5}}>
                <Typography align='center'>No Data Available</Typography>
                  </Paper> }
                  </TabPanel>
                  <TabPanel value={1} sx={{padding:1}}>
                   <ListItem component="div" disablePadding>
                    <ListItemButton sx={{ height: 56 }}>
                      <ListItemText
                        primary="Upcoming Appointments"
                        primaryTypographyProps={{
                          color: '#1212121',
                          fontWeight: 'medium',
                          variant: 'h6',
                        }}
                      />
                      {/* <IconButton
                        size="large">
                          <SwapVertOutlinedIcon/>
                      </IconButton>
                      <IconButton
                        size="large">
                          <FilterAltOutlinedIcon/>
                      </IconButton> */}
                    </ListItemButton>
                    </ListItem>
                    {showUpcomingApt === true ? upcomingAptData.map((aptData,index) =>(
                    <List sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemButton sx={{height:'auto'}}>
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src={person_img} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={aptData.patientName}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                align='left'
                              >
                                {aptData.patientGender} . {aptData.patientAge} 
                              </Typography>
                              {<Typography
                                sx={{ display: 'inline',position:'absolute',right:0}}
                                component="span"
                                variant="body2"
                                color="#8bc34a"
                                align='right'
                              >
                                {aptData.startDate.slice(0,10)} | {aptData.startDate.slice(11,16)}
                              </Typography>}
                            </React.Fragment>
                          }
                        />
                        </ListItemButton>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>)):<Paper sx={{margin:5,padding:5}}>
                <Typography align='center'>No Data Available</Typography>
                  </Paper> } 
                  </TabPanel>
                  <TabPanel value={2} sx={{padding:1}}>
                    <ListItem component="div" disablePadding>
                    <ListItemButton sx={{ height: 56 }}>
                      <ListItemText
                        primary="Upcoming Appointments"
                        primaryTypographyProps={{
                          color: '#1212121',
                          fontWeight: 'medium',
                          variant: 'h6',
                        }}
                      />
                      {/* <IconButton
                        size="large">
                          <SwapVertOutlinedIcon/>
                      </IconButton>
                      <IconButton
                        size="large">
                          <FilterAltOutlinedIcon/>
                      </IconButton> */}
                    </ListItemButton>
                    </ListItem>
                    {showUpcomingApt === true ? upcomingAptData.map((aptData,index) =>(
                    <List sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                      <ListItem alignItems="flex-start">
                        <ListItemButton sx={{height:'auto'}}>
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src={person_img} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={aptData.patientName}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                align='left'
                              >
                                {aptData.patientGender} . {aptData.patientAge} 
                              </Typography>
                              {<Typography
                                sx={{ display: 'inline',position:'absolute',right:0}}
                                component="span"
                                variant="body2"
                                color="#8bc34a"
                                align='right'
                              >
                                {aptData.startDate.slice(0,10)} | {aptData.startDate.slice(11,16)}
                              </Typography>}
                            </React.Fragment>
                          }
                        />
                        </ListItemButton>
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>)):<Paper sx={{margin:5,padding:5}}>
                <Typography align='center'>No Data Available</Typography>
                  </Paper> }
                  </TabPanel>
                </TabsUnstyled>
                </Box>
                </Grid>
              </Grid>
              </Paper>
          </Grid>
          <Grid item xs={12} md={7} >
          <Paper sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={7} marginTop={1} marginBottom={2}>
                <Button variant="outlined" sx={{width:'60%'}} onClick={()=>{setFaceSheetOpenPopup(true)}}>
                          FaceSheet
                        </Button>
                </Grid>
                    <Grid item xs={12} md={5} marginTop={1} marginBottom={2}>
                      <Button variant="outlined" sx={{marginRight:2,borderColor:'gray',color:'gray'}} onClick={()=>{
                      console.log("data apt.....",props.consultationId,props.doctorUUID)
                      store.setState('appointmentData',{
                        aptId: props.consultationId,
                        doctorUUID: props.doctorUUID,
                        patientID: patientData.personUuId
                      })
                      setComponent('Reschedule')
                      }}>
                          Reschedule
                        </Button>
                        <Button variant="outlined" color={'error'}onClick={() => {
                      confirmAlert({
                        title: "Are you Sure you want to Cancel the Appointment",
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                              cancelAppointment(props.consultationId).then(
                                res =>{
                                  console.log(res)
                                  toast(res.message)
                                  setComponent('Doctor Dashboard')
                                }
                              )
                            }
                          },
                          {
                            label: 'NO',
                            onClick: () => {
                              
                            }
                          },
                        ],
                        closeOnEscape:false,
                        closeOnClickOutside:false,
                      })
                    }}>Cancel</Button>
                    </Grid>
                </Grid>
              <Grid container spacing={2}>
                    <Grid item xs={12} md={5}>
                    <Box sx={{width:'auto',height:'auto',border:2,borderColor:'#DEDEDE',borderRadius:2}}>
                      <Box display='center' justifyContent="center" padding={2}>
                        <Avatar alt="Remy Sharp" src={props.doctorProfileImage} sx={{width:100,height:100}} style={{alignSelf:'center'}}/>
                      </Box>
                      <Typography fontWeight={'bold'} align={'center'}>{props.doctorName}</Typography>
                      <Typography align={'center'} color={colors.grey[500]} fontSize={12}>{props.doctorQualification} . {props.doctorSpecialization}</Typography>
                      <Typography align={'center'} color={colors.grey[500]} fontSize={12} paddingBottom={2}>{props.doctorProfSpeciality}</Typography>
                      {/* <Typography align={'center'} color={colors.grey[500]} fontSize={14} paddingBottom={1}>{props.doctorUUID}</Typography> */}
                    </Box>
                    
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Box sx={{width:'auto',height:'auto',border:2,borderColor:'#DEDEDE',borderRadius:2}}>
                    <Grid container spacing={3} marginTop={1}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]} fontWeight={'bold'}>Phone</Typography>
                    <Typography paddingLeft={4} fontSize={14}color={colors.grey[900]}>{patientData.phoneNumber}</Typography>                
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]} fontWeight={'bold'}>Payment Status</Typography>
                    <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]}>{patientData.feesPaid}</Typography>                
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]} fontWeight={'bold'}>Health Center</Typography>
                    <Typography paddingLeft={4} fontSize={14}color={colors.grey[900]}>Ubiqare</Typography>                
                    </Grid>
                    </Grid>
                    <Grid container spacing={2} marginTop={2} marginBottom={4.5}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]} fontWeight={'bold'}>Symtoms</Typography>
                    <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]}>{patientData.symptoms}</Typography>                
                    </Grid>
                    <Grid item xs={12} md={8}>
                    <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]} fontWeight={'bold'}>Purpose</Typography>
                    <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]}>{patientData.reasonForConsultation}</Typography>                
                    </Grid>                               
                </Grid>
                            </Box>
                        </Grid>
                </Grid>
              <Paper sx={{marginTop:1}}>
                <TabsUnstyled defaultValue={0}>
                  <TabsList sx={{maxWidth:'xs',height:'6vh'}}>
                    <Tab sx={{height:'5vh'}}>Clinical Note</Tab>
                    <Tab sx={{height:'5vh'}}>Past Health Record</Tab>
                  </TabsList>
                  <TabPanel value={0} >
                    <List sx={{ width: '100%', bgcolor: 'background.paper',paddingLeft:2,paddingRight:2}}>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setComplaintState(current => !current);}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}}>
                        <ListItemText
                          primary="Chief Complaint (CC)"
                        />
                        </ListItemButton>
                      </ListItem>
                      {openComplaints ? <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="Type Here..."
                                size='small'
                                multiline
                                defaultValue={clinincalNote.cheifComplaint}
                                onChange={(e) =>{clinincalNote.cheifComplaint = e.target.value}}
                                sx={{margin:1}}
                              />: <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() => {setSymptomsOpenPopup(true);}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}} onClick={() =>{setShowSymptoms(!showSymptoms)}}>
                        <ListItemText
                          primary="Symptoms"
                        />
                        </ListItemButton>
                      </ListItem>
                      {showSymptoms ? <ShowSymptoms/> : <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setHistoryState(current => !current);}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}}>
                        <ListItemText
                          primary="History of Present Illness (HPI)"
                        />
                        </ListItemButton>
                      </ListItem>
                      {openHistory ? <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="Type Here..."
                                size='small'
                                multiline
                                defaultValue={clinincalNote.HPI}
                                onChange={(e) =>{clinincalNote.HPI = e.target.value}}
                                sx={{margin:1}}
                              />: <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setVitalsOpenPopup(true)}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}} onClick={() =>{setShowVitals(!showVitals)}}>
                        <ListItemText
                          primary="Vitals"
                        />
                        </ListItemButton>
                      </ListItem>
                      {showVitals ? <ShowVitals/> : <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setLabOrdersState(current => !current);}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}}>
                        <ListItemText
                          primary="Lab Orders"
                        />
                        </ListItemButton>
                      </ListItem>
                      {openLabOrders ? <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="Type Here..."
                                size='small'
                                multiline
                                defaultValue={clinincalNote.labOrders}
                                onChange={(e) =>{clinincalNote.labOrders = e.target.value}}
                                sx={{margin:1}}
                              />: <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setLabTestPopup(true)}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}} onClick={() => {setShowLabTest(!showLabTest)}}>
                        <ListItemText
                          primary="Ubiqare Lab Tests"
                        />
                        </ListItemButton>
                      </ListItem>
                      {showLabTest ? <ShowLabTest/> : <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setPrescriptionOpenPopup(true)}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}} onClick={() =>{setShowPrescriptions(!showPrescriptions)}}>
                        <ListItemText
                          primary="Prescription / Medication"
                        />
                        </ListItemButton>
                      </ListItem>
                      {showPrescriptions ? <ShowPrescriptions/> : <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setTreatmentPlanState(current => !current);}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}}>
                        <ListItemText
                          primary="Treatment Plan"
                        />
                        </ListItemButton>
                      </ListItem>
                      {openTreatmentPlan ? <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="Type Here..."
                                multiline
                                size='small'
                                defaultValue={clinincalNote.treatmentPlan}
                                onChange={(e) =>{clinincalNote.treatmentPlan = e.target.value}}
                                sx={{margin:1}}
                              />: <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setOtherDetailsState(current => !current);}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}}>
                        <ListItemText
                          primary="Other Details"
                        />
                        </ListItemButton>
                      </ListItem>
                      {openOtherDetails ? <TextField
                                autoComplete="given-name"
                                name="firstName"
                                required
                                fullWidth
                                id="firstName"
                                label="Type Here..."
                                multiline
                                size='small'
                                defaultValue={clinincalNote.otherDetails}
                                onChange={(e) =>{clinincalNote.otherDetails = e.target.value}}
                                sx={{margin:1}}
                              />: <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setInstructionOpenPopup(true);}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}} onClick={() => {setShowInstruction(!showInstruction)}}>
                        <ListItemText
                          primary="Additional Instruction"
                        />
                        </ListItemButton>
                      </ListItem>
                      {showInstruction ? <ShowInstructions/> : <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setDoctorSignatureState(current => !current);}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}}>
                        <ListItemText
                          primary="Doctor Signature"
                        />
                        </ListItemButton>
                      </ListItem>
                      {openDoctorSignature ? <DoctorSignature />: <div></div>}
                      <Divider component="li"/>
                      <ListItem alignItems="flex-start" secondaryAction={
                          <IconButton onClick={() =>{setTriageState(current => !current);}}>
                            <EditIcon />
                          </IconButton>
                        }>
                        <ListItemButton sx={{height:'3vh'}}>
                        <ListItemText
                          primary="Triage"
                        />
                        </ListItemButton>
                      </ListItem>
                      {openTriage ? <Grid container spacing={1} marginBottom={2}>
          <Grid item xs={5} md={0.75} >
            <IconButton sx={{color:'green'}} onClick={() =>{clinincalNote.triage = 'Green'}}>
              <Brightness1Icon sx={{color:'green'}}>
              </Brightness1Icon>
            </IconButton>
          </Grid>
          <Grid item xs={5} md={1.65} marginTop={1.5}>
              <Typography fontSize={12} fontWeight={'bold'} >Green</Typography>
          </Grid>
          <Grid item xs={5} md={0.75} >
            <IconButton sx={{color:'yellow'}} onClick={() =>{clinincalNote.triage = 'Yellow'}}>
              <Brightness1Icon sx={{color:'yellow'}}>
              </Brightness1Icon>
            </IconButton>
          </Grid>
          <Grid item xs={5} md={1.65} marginTop={1.5}>
              <Typography fontSize={12} fontWeight={'bold'} >Yellow</Typography>
          </Grid>
          <Grid item xs={5} md={0.75} >
            <IconButton sx={{color:'orange'}} onClick={() =>{clinincalNote.triage = 'Orange'}}>
              <Brightness1Icon sx={{color:'orange'}}>
              </Brightness1Icon>
            </IconButton>
          </Grid>
          <Grid item xs={5} md={1.65} marginTop={1.5}>
              <Typography fontSize={12} fontWeight={'bold'} >Orange</Typography>
          </Grid>
          <Grid item xs={5} md={0.75} >
            <IconButton sx={{color:'red'}} onClick={() =>{clinincalNote.triage = 'Red'}}>
              <Brightness1Icon sx={{color:'red'}}>
              </Brightness1Icon>
            </IconButton>
          </Grid>
          <Grid item xs={5} md={1.65} marginTop={1.5}>
              <Typography fontSize={12} fontWeight={'bold'} >Red</Typography>
          </Grid>
        </Grid>: <div></div>}
                      <Divider component="li"/>
                    </List>
                  </TabPanel>
                  <TabPanel value={1} sx={{padding:1}}><PastHealthRecord /></TabPanel>
                </TabsUnstyled>
                </Paper>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} marginTop={1} marginBottom={2} alignItems='center'>
                <Button variant="outlined" onClick={() =>{
                    createClinicalNote(props.patientID,clinincalNote.cheifComplaint,clinincalNote.HPI,clinincalNote.treatmentPlan,
                      clinincalNote.otherDetails,clinincalNote.additionalDetails,clinincalNote.doctorSignature,clinincalNote.triage,clinincalNote.labOrders,
                      "","","","",props.consultationId,"","draft",(clinincalNote.symptoms.covidSymptoms)[0].value,
                      (clinincalNote.symptoms.covidSymptoms)[1].value,(clinincalNote.symptoms.covidSymptoms)[2].value,
                      (clinincalNote.symptoms.covidSymptoms)[3].value,(clinincalNote.symptoms.covidSymptoms)[4].value,
                      (clinincalNote.symptoms.covidSymptoms)[5].value,(clinincalNote.symptoms.covidSymptoms)[6].value,
                      (clinincalNote.symptoms.covidSymptoms)[7].value,(clinincalNote.symptoms.covidSymptoms)[8].value,
                      (clinincalNote.symptoms.covidSymptoms)[9].value,(clinincalNote.symptoms.covidSymptoms)[10].value,
                      (clinincalNote.symptoms.generalSymptoms)[0].value,(clinincalNote.symptoms.generalSymptoms)[1].value,
                      (clinincalNote.symptoms.generalSymptoms)[2].value,(clinincalNote.symptoms.generalSymptoms)[3].value,
                      (clinincalNote.symptoms.generalSymptoms)[4].value,(clinincalNote.symptoms.generalSymptoms)[5].value,
                      (clinincalNote.symptoms.cvsSymptoms)[0].value,(clinincalNote.symptoms.cvsSymptoms)[1].value,
                      (clinincalNote.symptoms.cvsSymptoms)[2].value,(clinincalNote.symptoms.cvsSymptoms)[3].value,
                      (clinincalNote.symptoms.respiratorySymptoms)[0].value,(clinincalNote.symptoms.nsSymptoms)[0].value,
                      (clinincalNote.symptoms.muskeloskeletal)[0].value,(clinincalNote.symptoms.gitSymptoms)[0].value,
                      (clinincalNote.symptoms.gitSymptoms)[1].value,(clinincalNote.symptoms.gitSymptoms)[2].value,
                      (clinincalNote.symptoms.gitSymptoms)[3].value,(clinincalNote.symptoms.gitSymptoms)[4].value,
                      (clinincalNote.symptoms.gitSymptoms)[5].value,(clinincalNote.symptoms.gitSymptoms)[6].value,
                      (clinincalNote.symptoms.urinarySymptoms)[0].value,(clinincalNote.symptoms.urinarySymptoms)[1].value,
                      (clinincalNote.symptoms.urinarySymptoms)[2].value,(clinincalNote.symptoms.skinSymptoms)[0].value,
                      (clinincalNote.symptoms.skinSymptoms)[1].value,(clinincalNote.symptoms.eyeSymptoms)[0].value,
                      (clinincalNote.symptoms.eyeSymptoms)[1].value,clinincalNote.lab_tests,clinincalNote.prescription,
                      clinincalNote.vitals.weight,clinincalNote.vitals.height,clinincalNote.vitals.pulse,clinincalNote.vitals.systolic,
                      clinincalNote.vitals.diastolic,clinincalNote.vitals.temperature,clinincalNote.vitals.respiratoryRate,
                      clinincalNote.vitals.grbs,"",clinincalNote.vitals.hunit,clinincalNote.vitals.wunit,clinincalNote.vitals.tempUnit,clinincalNote.vitals.spO2,"","").then(res => {
                        console.log(res)
                        if(res.status === 200){
                          confirmAlert({
                            title: res.message,
                            buttons: [
                              {
                                label: 'Okay',
                                onClick: () => {
                                  getClinincalData()
                                }
                              },
                            ],
                            closeOnEscape:false,
                            closeOnClickOutside:false,
                          })
                        }else{
                          toast("Error Occurred")
                        }
                      })    
                        }}>
                          Save as Draft
                        </Button>
                </Grid>
                    <Grid item xs={12} md={6} marginTop={1} marginBottom={2} >
                      <Button variant="contained" sx={{color:'white'}} onClick={() =>{
                          setPdfViewOpenPopup(true)
                          }}>
                          Preview
                        </Button>
                    </Grid>
                </Grid>
          </Paper>
          </Grid>
        </Grid> : <Paper sx={{margin:5,padding:5}}>
                <Typography align='center'>No Data Available</Typography>
                  </Paper> }
        {openSymptomsPopup === true ?<SymptomsPopUpCN/>:<div></div>}
        {openVitalsPopup === true ?<VitalsPopUpCN/>:<div></div>}
        {openPrescriptionPopup === true ?<PrescriptionPopUpCN/>:<div></div>}
        {openInstructionPopup === true ?<AdditionalInstructionPopUpCN/>:<div></div>}
        {openPdfViewPopup === true ?<PdfViewPopUpCN/>:<div></div>}
        {openLabTestPopup === true ?<LabTestPopUpCN/>:<div></div>}
        {showVitalPopup === true ?<PastHistoryEditVitals />:<div></div>}
        {openFaceSheet === true ?<FaceSheet open={openFaceSheet} handleClose={handleFaceSheetClosePopup} 
        patientName={patientData.name} patientUUID = {patientData.personUuId}/>:<div></div>}
        {showVitalDetailsPopup === true ?<ShowVitalDetails doctorName={props.doctorName}/>:<div></div>}
      </>
        
    );
}

export default TeleCallComponent;