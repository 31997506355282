import * as React from 'react';
import { styled, useTheme, alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { colors,Paper } from '@mui/material';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TransferList from './TransferListComponent';
import store from '../../../context/StatePoolService';
import AdminService from '../../AdminDashboard/AdminService';
import Autocomplete from '@mui/material/Autocomplete';


function DoctorPanelList(){

    const {GetPrivateMasterData} = AdminService();
    const {getPanelListByOrgId,getDoctorListByPanelID} = AdminService()
    const [component, setComponent] = store.useState('componentState');
    const [panelID, setPanelID] = React.useState('');
    const [dcomponent, setDComponent] = React.useState('basic');
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [panels,setPanels] = React.useState([]);
    const [panelName,setPanelName] = React.useState();
    const [isloading,setIsloading] = React.useState(false)
    
    const handleListItemClick = (event,index) => {
        setSelectedIndex(index);
    };
    const StyledBadge = styled(Badge)(({ theme }) => ({
      '& .MuiBadge-badge': {
        right: -3,
        top: 55,
        border: `2px solid white`,
        padding: '0 4px',
      },
    }));
    function BasicDetails(){
      const [speciality,setSpeciality] = React.useState('');
      const [specialityList, setSpecialityList] = React.useState([]);
      

      React.useEffect(() => {
        GetPrivateMasterData('specialitySearch').then(res => setSpecialityList(res))
    },[])
      return(
      <Grid item xs={10} md={10} lg={10}>
                  <Paper sx={{marginTop:5,padding:2}}>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Speciality</Typography>
              <FormControl fullWidth>
                  <Autocomplete
                  onChange = {(event, newValue) => {if(newValue && newValue.label){
                      setSpeciality(newValue.label)}}}
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={specialityList}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Select Speciality"
                  // helperText={errorText.specialization.error === true ? errorText.specialization.errorText:""}
                  // error={errorText.specialization.error === true ? true : false}
                  // autoFocus={errorText.specialization.error === true ? true : false}
                  />}
                />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              
              </Grid>
        </Grid>
                  </Paper>
                  <TransferList
                  panelName={panelName}
                  panelId={panelID}
                  isUpdate = {true}
                  orgFunction = {loadOrg}
                  />
                  
          </Grid>
      );
    }

    async function loadOrg(){
      getPanelListByOrgId().then(res=>{
        console.log("org list data",res)
        if(res.status === 200){
            setPanelName(res.data[0].panelName)
            setPanelID(res.data[0].panelId)
            setPanels(res.data)
            setIsloading(true)
          }else{
            setIsloading(true)
          }
        })
    }
    console.log("panels are",panels)
    React.useEffect(() => {
      loadOrg();
    },[])
    return(
      <Grid>
        {isloading === true ? 
                  <Grid container spacing={2}>
                        <Grid item xs={2} md={2} lg={2}>
                        <Typography padding={0.5} fontWeight={'bold'}>Manage Panel</Typography>
                              <Paper sx={{marginTop:1,borderRadius:3}}>
                                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                {panels !== [] ? panels.map((panel,index) =>(<List component="nav" aria-label="main mailbox folders"
                                sx={{
                                    // selected and (selected + hover) states
                                    '&& .Mui-selected, && .Mui-selected:hover': {
                                      bgcolor: '#651fff',
                                      '&, & .MuiListItemIcon-root': {
                                        color: 'white',
                                      },
                                    },
                                    // hover states
                                    '& .MuiListItemButton-root:hover': {
                                      bgcolor: '#651fff',
                                      '&, & .MuiListItemIcon-root': {
                                        color: 'white',
                                      },
                                    },
                                  }}
                                  key={panel.panelId}
                                >
                                    <ListItemButton
                                    selected={selectedIndex === index}
                                    onClick={(event) => {
                                      handleListItemClick(event, index)
                                      setPanelName(panel.panelName)
                                      setPanelID(panel.panelId)
                                    }}
                                    >
                                    <ListItemIcon>
                                        <InboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary={panel.panelName} 
                                    primaryTypographyProps={{fontSize: '1.5vh'}} 
                                    />
                                    </ListItemButton>

                                </List>)):<div></div>}
                                </Box>
                            </Paper>
                            <Button
                              type="submit"
                              fullWidth
                                variant="contained"
                              sx={{ borderRadius: 2,bgcolor:'#651fff',marginTop:2,marginBottom:4}}
                              onClick={()=>{setComponent('AddNewPanel')}}
                            >Add Panel</Button>
                        </Grid>
                        {dcomponent === 'basic' ? <BasicDetails/> : <div></div>}
                    </Grid> : <Paper><Typography align='center'>Loading......</Typography></Paper>}
      </Grid>
      
    );
  }

export default DoctorPanelList;