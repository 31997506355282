
import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { colors,Paper } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import ScheduleService from './ScheduleService';
import dayjs from 'dayjs';
import AdminService from '../../AdminDashboard/AdminService';
import { toast } from 'react-toastify';
import Helper from '../../../Helper/Helper';
import { confirmAlert } from 'react-confirm-alert';
import store from '../../../context/StatePoolService'; // Import

const {containsSpecialChars} = Helper();

function RescheduleAppointment(props){

    const {rescheduleAppointment,getAppointmentSlots} = ScheduleService();
    const {GetMasterSearchData,getPatientList,fileUpload} = AdminService();

    const [component, setComponent] = store.useState('componentState');
    const [consultationType, setConsultationType] = React.useState('');
    const [consultationTypeID, setConsultationTypeID] = React.useState('');
    const [reason, setReason] = React.useState('');
    const [consultationTypeList, setConsultationTypeList] = React.useState([]);
    const [selectedDate,setSelectedDate] = React.useState(dayjs().toISOString().slice(0,10));
    const [timeSlots, setTimeSlots] = React.useState([])
    const [selectedTime, setSelectedTime] = React.useState('')
    const [epochStartTime, setEpochStartTime] = React.useState('')
    const [epochEndTime, setEpochEndTime] = React.useState('')
    const [aptDuration, setAptDuration] = React.useState('')
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [slotMessage, setSlotMessage] = React.useState("")
    const {getDetailsByAptId} = ScheduleService();
    const [aptData, setAptData] = React.useState({});
    const [isLoading,setIsLoading] = React.useState(true);
  console.log(props.consultationId)
  console.log("PATIENT DATA",aptData)
    const [errorText,setErrorText] = React.useState({
      consultType:{
        error:false,
        errorText:"Select an option from DropDown"
      },
      reason:{
        error:false,
        errorText:"Field can't be empty"
      },
      selectedTime:{
        error:false,
        errorText:"Please Select the Consultation Slot to Reschedule Appointment"
      },
    })

    function handleValidation(){
      if(consultationType === '' || consultationType !==''){
          if(consultationType === ''){
            setErrorText(previous => ({...previous,consultType:{error:true,
              errorText:"Select an option from DropDown"}}))
              return true;
          }else if(consultationType !== ''){
            setErrorText(previous => ({...previous,consultType:{error:false,
              errorText:"Select an option from DropDown"}}))
          }
        }
        if(reason === "" || reason !== ""){
          if(reason === ""){
            setErrorText(previous => ({...previous,reason:{error:true,
              errorText:"Field can't be empty"}}))
              return true;
              
          }else if(containsSpecialChars(reason) === true){
            setErrorText(previous => ({...previous,reason:{error:true,
              errorText:"Field cannot contain Special Characters"}}))
              return true;
              
          }else if(reason !== ""){
            setErrorText(previous => ({...previous,reason:{error:false,
              errorText:"Field can't be empty"}}))              
          }         
        }
        if(selectedTime === '' || selectedTime !==''){
          if(selectedTime === ''){
            setErrorText(previous => ({...previous,selectedTime:{error:true,
              errorText:"Please Select the Consultation Slot to Reschedule Appointment"}}))
              return true;
          }else if(selectedTime !== ''){
            setErrorText(previous => ({...previous,selectedTime:{error:false,
              errorText:"Please Select the Consultation Slot to Reschedule Appointment"}}))
              return false;
          }
        }
      
      console.log("RESULTS............");
    
      }

      

    console.log("selected time is",selectedTime)
    console.log("selected Date is",selectedDate)

    const loadSlotData = async (consult_type,selectedDate) => {
      setTimeSlots([])
      console.log("SLOTS INPUT",selectedDate,consult_type)
      await getAppointmentSlots(props.doctorUUID,selectedDate,consult_type).then(res => {
        console.log("SLOTS DATA",res)
        if(res.status === 200){
          setTimeSlots(res.data)
        }else{
          setSlotMessage(res.message)
        }
        
      })
    }
    React.useEffect(() => {
      GetMasterSearchData('CONS_TYPE').then(res => {
        setConsultationTypeList(res)
        console.log("CONSULTATION DROPDOWN",res)
        setConsultationType(res[0].label)
        setConsultationTypeID(res[0].typeID)
        loadSlotData(res[0].label,selectedDate)
      }); 
      getDetailsByAptId(props.consultationId).then(res => {
        if(res.status === 200){
          console.log("INSIDE DETAILS CALL",res)
          setAptData(res.data)
          setIsLoading(false)
        }else{
          setIsLoading(true)
          toast(res.message)
        }
        
      }) 
      
    },[])
    console.log("time slots is",timeSlots)
    return(
      <Grid item xs={10} md={10} lg={10}>
        <Grid container spacing={1} marginBottom={1}>
          <Grid item xs={12} md={7} >
          <Typography fontSize={20} fontWeight={'bold'} >Reschedule Booking</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} marginBottom={1}>
          <Grid item xs={12} md={2} >
          <Paper sx={{padding:2}}>
          <Typography fontSize={16} fontWeight={'bold'} >{aptData.name}</Typography>
          <Typography fontSize={14} fontWeight={'bold'} >{`${aptData.gender} . ${aptData.age}`}</Typography>
          <Typography fontSize={14} fontWeight={'bold'} >{aptData.personUuId}</Typography>
          </Paper>
          </Grid>
          <Grid item xs={12} md={10} >
          <Paper sx={{padding:2}}>
          {isLoading === false ? <Grid container spacing={5} >
                <Grid item xs={12} md={2}>
                <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]} fontWeight={'bold'}>Phone</Typography>
                <Typography paddingLeft={4} fontSize={14}color={colors.grey[900]}>64621654165</Typography>                
                </Grid>
                <Grid item xs={12} md={2}>
                <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]} fontWeight={'bold'}>Payment Status</Typography>
                <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]}>{aptData.feesPaid}</Typography>                
                </Grid>
                <Grid item xs={12} md={2}>
                <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]} fontWeight={'bold'}>Health Center</Typography>
                <Typography paddingLeft={4} fontSize={14}color={colors.grey[900]}>Ubiqare</Typography>                
                </Grid>
                <Grid item xs={12} md={2}>
                <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]} fontWeight={'bold'}>Symtoms</Typography>
                <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]}>{aptData.symptoms}</Typography>                
                </Grid>
                <Grid item xs={12} md={4}>
                <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]} fontWeight={'bold'}>Purpose</Typography>
                <Typography paddingLeft={4} fontSize={14} color={colors.grey[900]}>{aptData.reasonForConsultation}</Typography>                
                </Grid>
                            
              </Grid> : <div></div>}
          </Paper>
          </Grid>
        </Grid>
        <Paper sx={{padding:2}}>
        <Grid container spacing={2} marginBottom={1}>
          <Grid item xs={12} md={4} lg={4} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Consultation Type</Typography>
              
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setConsultationType(newValue.label);
                          setConsultationTypeID(newValue.typeID);
                          loadSlotData(newValue.label,selectedDate)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={consultationTypeList}
                      value={consultationType}
                      getOptionLabel={(option) => option.label || consultationType}
                      isOptionEqualToValue={(option, value) => option.label === value}
                      renderInput={(params) => <TextField {...params} label="Select Consultation Type" 
                      helperText={errorText.consultType.error === true ? errorText.consultType.errorText:""}
                      error={errorText.consultType.error === true ? true : false}
                      autoFocus={errorText.consultType.error === true ? true : false}
                      />}
                      />
                </FormControl>
              </Grid> 
              <Grid item xs={12} md={4} lg={4} margin={2}></Grid>
              </Grid>
          </Paper>
          <Grid container spacing={2} marginBottom={1}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper sx={{marginTop: 2,width:'100%',height:'auto'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    orientation="portrait"
                    openTo="day"
                    value={selectedDate}
                    onChange={(newValue) => {
                      setSelectedDate(dayjs(newValue).format('YYYY-MM-DD'));
                      loadSlotData(consultationType,dayjs(newValue).format('YYYY-MM-DD'))
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
            <Paper sx={{marginTop: 2,width:'100%',height:'auto',padding:4}}>
            <Typography color={colors.grey[900]} fontWeight={'bold'} paddingLeft={1} paddingTop={1}>{props.doctorName}</Typography>
            <Typography fontSize={12} color={colors.grey[500]} fontWeight={'bold'} paddingLeft={1} marginBottom={2}>Available Slots</Typography>
            
        {timeSlots.length === 0 ? <Typography>{slotMessage}</Typography> : <div> 
          {timeSlots.map((value,index)=>(
     
            <Button key={index} variant="contained" sx={{width:'15%',bgcolor: selectedIndex === index ? '#45C500' : '#ffffff',margin:0.5,color: selectedIndex === index ? '#ffffff': '#45C500'}} onClick={() => {
              console.log("Time is ",(timeSlots[index].aptSlotStartTime).toLocaleString('en-US', { timeZone: 'Asia/Kolkata'}))
              setSelectedTime((timeSlots[index].aptSlotStartTime).slice(11,16))
              setEpochStartTime(timeSlots[index].aptSlotStartTime)
              setEpochEndTime(timeSlots[index].aptSlotEndTime)
              setAptDuration(timeSlots[index].aptSlotDuration)
              setSelectedIndex(index)}}
              disabled={timeSlots[index].aptBooked}
              >
              {(timeSlots[index].aptSlotStartTime).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }).slice(11,16)}
              </Button>
          ))}</div>}
          {errorText.selectedTime.error === true ? <Typography sx={{color:'red'}}>{errorText.selectedTime.errorText}</Typography> :<div></div>}
            </Paper>
            
            </Grid>
          </Grid>
          
          
          <Paper sx={{marginTop: 4}}>
          <Grid container spacing={2} marginBottom={1}>
          <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Reason For Rescheduling</Typography>
                <TextField
                  required
                  fullWidth
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  id="email"
                  label="Enter Reason"
                  name="email"
                  autoComplete="family-name" 
                  multiline
                  rows={4}
                  helperText={errorText.reason.error === true ? errorText.reason.errorText:""}
                  error={errorText.reason.error === true ? true : false}
                  autoFocus={errorText.reason.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}></Grid>
            </Grid>
          </Paper>
          <Paper sx={{marginTop:3,paddingBottom:1}}>
                  <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={12} md={6} margin={3}>
                      <Button variant="outlined" sx={{width:'40%',marginRight:2}} onClick={() =>{
                        // setPatientName('')
                        setReason('')
                        setConsultationType(consultationTypeList[0].label)
                        loadSlotData(consultationTypeList[0].label,selectedDate)
                      }}>
                          Clear
                        </Button>
                        <Button variant="contained" sx={{width:'40%',bgcolor:'#651fff'}}
                        onClick={()=>{
                        var startEpochTime =new Date(epochStartTime).getTime();
                        console.log("epoch start time",startEpochTime);
                        var endEpochTime = new Date(epochEndTime).getTime();
                        console.log("epoch end time",endEpochTime);
                        var oldStartEpochTime =new Date(aptData.aptSlotsDto.aptSlotStartTime).getTime();
                        console.log("OLD epoch start time",startEpochTime);
                          var res = handleValidation()
                if(res === false && consultationTypeID != ''){
                  rescheduleAppointment(aptData.consultationId,aptData.doctorUuId,
                    consultationTypeID,aptData.personUuId,aptData.aptSlotsDto.bookedAptSlotId,startEpochTime,endEpochTime,
                    aptDuration,oldStartEpochTime,aptData.reasonForConsultation,aptData.symptoms,
                    aptData.feesPaid,aptData.fees).then(res =>{
                    console.log(res)
                    console.log(aptData.consultationId,aptData.doctorUuId,
                      consultationTypeID,aptData.personUuId,aptData.aptSlotsDto.bookedAptSlotId,startEpochTime,endEpochTime,
                      aptDuration,oldStartEpochTime,aptData.reasonForConsultation,aptData.symptoms,
                      aptData.feesPaid,aptData.fees)
                    {res.status === 200 ? confirmAlert({
                      title: res.message,
                      buttons: [
                        {
                          label: 'Okay',
                          onClick: () => {
                            setComponent('Doctor Scheduling')
                          }
                        },
                      ],
                      closeOnEscape:false,
                      closeOnClickOutside:false,
                    })
                  :toast("Error Occured")}
                             
                  })
                }else{
                  toast("Please fill the details to book appointment")
                }
                
              }}
                          
                          // console.log(consultationType,patientName,patientName,startEpochTime,endEpochTime,duration,purpose,feeStatus,appointmentFee,symptoms)
                          // createNewAppointment(consultationType,patientName,patientName,startEpochTime,endEpochTime,duration,purpose,feeStatus,appointmentFee,symptoms)}}
                        >
                          Reschedule
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
          
        

    </Grid>
      
    

    );
}

export default RescheduleAppointment;

