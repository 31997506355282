import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { colors,Paper } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import store from '../../../context/StatePoolService';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { useState, useEffect } from "react";
import Checkbox from '@mui/material/Checkbox';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AdminService from '../AdminService';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import { ListItem, Collapse } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { toast } from 'react-toastify';
import PrivilegeService from './PrivilgesService';



const OrgSuperAdmin = [
  {
    id: 1,
    label: "Patient Dashboard",
    value: "Patient Dashboard",
    checked: true,
    isOpen: false,
    parent: null,
  },
  {
    id: 2,
    label: "Doctor Scheduling",
    value:'Doctor Scheduling',
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 3,
        label: "Book Appointment",
        value:"Book Appointment",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 4,
        label: "Reschedule Appointment",
        value:"Reschedule Appointment",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 5,
        label: "Cancel Appointment",
        value:"Cancel Appointment",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 6,
        label: "View Appointment Details",
        value:"View Appointment Details",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 7,
        label: "Consultation",
        value:"Consultation",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 8,
        label: "Schedule Leave",
        value:"Schedule Leave",
        checked: false,
        isOpen: false,
        parent: 2,
      },
    ]
  },
  {
    id: 9,
    label: "Assign Doctor",
    value: "Assign Doctor",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 10,
    label: "Doctor Management",
    value: "Doctor Management",
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 11,
        label: "Register Doctor",
        value: "Register Doctor",
        checked: false,
        isOpen: false,
        parent: 10,
        children: [
          {
            id: 12,
            label: "Personal Details",
            value: "Personal Details",
            checked: false,
            isOpen: false,
            parent: 11,
          },
          {
            id: 13,
            label: "Professional Details",
            value: "Professional Details",
            checked: false,
            isOpen: false,
            parent: 11,
          },
          {
            id: 14,
            label: "Consultation Details",
            value: "Consultation Details",
            checked: false,
            isOpen: false,
            parent: 11,
          }
        ]
      },
      {
        id: 15,
        label: "Edit Doctor",
        value: "Edit Doctor",
        checked: false,
        isOpen: false,
        parent: 10,
        children: [
          {
            id: 16,
            label: "Personal Details",
            value: "Personal Details",
            checked: false,
            isOpen: false,
            parent: 15,
          },
          {
            id: 17,
            label: "Professional Details",
            value: "Professional Details",
            checked: false,
            isOpen: false,
            parent: 15,
          },
          {
            id: 18,
            label: "Consultation Details",
            value: "Consultation Details",
            checked: false,
            isOpen: false,
            parent: 15,
          }
        ]
      }
    ]
  },
  {
    id: 19,
    label: "Partner Management",
    value: "Partner Management",
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 20,
        label: "Register Partner",
        value: "Register Partner",
        checked: false,
        isOpen: false,
        parent: 19,
        children: [
          {
            id: 21,
            label: "Basic Details",
            value: "Basic Details",
            checked: false,
            isOpen: false,
            parent: 20,
          },
          {
            id: 22,
            label: "Configuration Details",
            value: "Configuration Details",
            checked: false,
            isOpen: false,
            parent: 20,
          },
          {
            id: 23,
            label: "Template Details",
            value: "Template Details",
            checked: false,
            isOpen: false,
            parent: 20,
          }
        ]
      },
      {
        id: 24,
        label: "Edit Partner",
        value: "Edit Partner",
        checked: false,
        isOpen: false,
        parent: 19,
        children: [
          {
            id: 25,
            label: "Basic Details",
            value: "Basic Details",
            checked: false,
            isOpen: false,
            parent: 24,
          },
          {
            id: 26,
            label: "Configuration Details",
            value: "Configuration Details",
            checked: false,
            isOpen: false,
            parent: 24,
          },
          {
            id: 27,
            label: "Template Details",
            value: "Template Details",
            checked: false,
            isOpen: false,
            parent: 24,
          }
        ]
      }
    ]
  },
  {
    id: 28,
    label: "Admin Management",
    value: "Admin Management",
    checked: false,
    isOpen: false,
    parent: null,
   
  },
  {
    id: 29,
    label: "Role Management",
    value: "Role Management",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 30,
    label: "Doctor Panel",
    value: "Doctor Panel",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 31,
    label: "Patient Registration",
    value: "Patient Registration",
    checked: false,
    isOpen: false,
    parent: null,
  },
 
  {
    id: 32,
    label: "Admin Settings",
    value: "Admin Settings",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 33,
    label: "Patient Search",
    value: "Patient Search",
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 34,
        label: "Personal Details",
        value: "Personal Details",
        checked: false,
        isOpen: false,
        parent: 33,
      },
      {
        id: 35,
        label: "Appoinments",
        value: "Appoinments",
        checked: false,
        isOpen: false,
        parent: 33,
      },
      {
        id: 36,
        label: "Prescriptions",
        value: "Prescriptions",
        checked: false,
        isOpen: false,
        parent: 33,
      },
      {
        id: 37,
        label: "Lab Reports",
        value: "Lab Reports",
        checked: false,
        isOpen: false,
        parent: 33,
      },
      {
        id: 38,
        label: "Medical Condition",
        value: "Medical Condition",
        checked: false,
        isOpen: false,
        parent: 33,
      },
      {
        id: 39,
        label: "Vitals",
        value: "Vitals",
        checked: false,
        isOpen: false,
        parent: 33,
      },
      {
        id: 40,
        label: "Allergies",
        value: "Allergies",
        checked: false,
        isOpen: false,
        parent: 33,
      },
      {
        id: 41,
        label: "Vault",
        value: "Vault",
        checked: false,
        isOpen: false,
        parent: 33,
      },
      {
        id: 42,
        label: "Health Indicator",
        value: "Health Indicator",
        checked: false,
        isOpen: false,
        parent: 33,
      },
      {
        id: 43,
        label: "Family Member",
        value: "Family Member",
        checked: false,
        isOpen: false,
        parent: 33,
      },
      {
        id: 44,
        label: "File Cabinet",
        value: "File Cabinet",
        checked: false,
        isOpen: false,
        parent: 33,
      },
    ]
  },
  {
    id: 45,
    label: "Registration Approvals",
    value: "Registration Approvals",
    checked: false,
    isOpen: false,
    parent: null,
  },
]

const adminPrivilages = [
  {
    id: 1,
    label: "Patient Dashboard",
    value: "Patient Dashboard",
    checked: true,
    isOpen: false,
    parent: null,
  },
  {
    id: 2,
    label: "Doctor Scheduling",
    value:'Doctor Scheduling',
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 3,
        label: "Book Appointment",
        value:"Book Appointment",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 4,
        label: "Reschedule Appointment",
        value:"Reschedule Appointment",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 5,
        label: "Cancel Appointment",
        value:"Cancel Appointment",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 6,
        label: "View Appointment Details",
        value:"View Appointment Details",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 7,
        label: "Consultation",
        value:"Consultation",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 8,
        label: "Schedule Leave",
        value:"Schedule Leave",
        checked: false,
        isOpen: false,
        parent: 2,
      },
    ]
  },
  {
    id: 9,
    label: "Assign Doctor",
    value: "Assign Doctor",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 10,
    label: "Doctor Management",
    value: "Doctor Management",
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 11,
        label: "Register Doctor",
        value: "Register Doctor",
        checked: false,
        isOpen: false,
        parent: 10,
        children: [
          {
            id: 12,
            label: "Personal Details",
            value: "Personal Details",
            checked: false,
            isOpen: false,
            parent: 11,
          },
          {
            id: 13,
            label: "Professional Details",
            value: "Professional Details",
            checked: false,
            isOpen: false,
            parent: 11,
          },
          {
            id: 14,
            label: "Consultation Details",
            value: "Consultation Details",
            checked: false,
            isOpen: false,
            parent: 11,
          }
        ]
      },
      {
        id: 15,
        label: "Edit Doctor",
        value: "Edit Doctor",
        checked: false,
        isOpen: false,
        parent: 10,
        children: [
          {
            id: 16,
            label: "Personal Details",
            value: "Personal Details",
            checked: false,
            isOpen: false,
            parent: 15,
          },
          {
            id: 17,
            label: "Professional Details",
            value: "Professional Details",
            checked: false,
            isOpen: false,
            parent: 15,
          },
          {
            id: 18,
            label: "Consultation Details",
            value: "Consultation Details",
            checked: false,
            isOpen: false,
            parent: 15,
          }
        ]
      }
    ]
  },
  {
    id: 19,
    label: "Doctor Panel",
    value: "Doctor Panel",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 20,
    label: "Patient Registration",
    value: "Patient Registration",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 21,
    label: "Admin Settings",
    value: "Admin Settings",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 22,
    label: "Patient Search",
    value: "Patient Search",
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 23,
        label: "Personal Details",
        value: "Personal Details",
        checked: false,
        isOpen: false,
        parent: 22,
      },
      {
        id: 24,
        label: "Appoinments",
        value: "Appoinments",
        checked: false,
        isOpen: false,
        parent: 22,
      },
      {
        id: 25,
        label: "Prescriptions",
        value: "Prescriptions",
        checked: false,
        isOpen: false,
        parent: 22,
      },
      {
        id: 26,
        label: "Lab Reports",
        value: "Lab Reports",
        checked: false,
        isOpen: false,
        parent: 22,
      },
      {
        id: 27,
        label: "Medical Condition",
        value: "Medical Condition",
        checked: false,
        isOpen: false,
        parent: 22,
      },
      {
        id: 28,
        label: "Vitals",
        value: "Vitals",
        checked: false,
        isOpen: false,
        parent: 22,
      },
      {
        id: 29,
        label: "Allergies",
        value: "Allergies",
        checked: false,
        isOpen: false,
        parent: 22,
      },
      {
        id: 30,
        label: "Vault",
        value: "Vault",
        checked: false,
        isOpen: false,
        parent: 22,
      },
      {
        id: 31,
        label: "Health Indicator",
        value: "Health Indicator",
        checked: false,
        isOpen: false,
        parent: 22,
      },
      {
        id: 32,
        label: "Family Member",
        value: "Family Member",
        checked: false,
        isOpen: false,
        parent: 22,
      },
      {
        id: 33,
        label: "File Cabinet",
        value: "File Cabinet",
        checked: false,
        isOpen: false,
        parent: 22,
      },
    ]
  },
  {
    id: 34,
    label: "Registration Approvals",
    value: "Registration Approvals",
    checked: false,
    isOpen: false,
    parent: null,
  },
]

const doctorPrivilages = [
  {
    id: 1,
    label: "Doctor Dashboard",
    value: "Doctor Dashboard",
    checked: true,
    isOpen: false,
    parent: null,
  },
  {
    id: 2,
    label: "Doctor Scheduling",
    value:'Doctor Scheduling',
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 3,
        label: "Book Appointment",
        value:"Book Appointment",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 4,
        label: "Reschedule Appointment",
        value:"Reschedule Appointment",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 5,
        label: "Cancel Appointment",
        value:"Cancel Appointment",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 6,
        label: "View Appointment Details",
        value:"View Appointment Details",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 7,
        label: "Consultation",
        value:"Consultation",
        checked: false,
        isOpen: false,
        parent: 2,
      },
      {
        id: 8,
        label: "Schedule Leave",
        value:"Schedule Leave",
        checked: false,
        isOpen: false,
        parent: 2,
      },
    ]
  },
  {
    id: 9,
    label: "Assign Doctor",
    value: "Assign Doctor",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 10,
    label: "Doctor Management",
    value: "Doctor Management",
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 11,
        label: "Register Doctor",
        value: "Register Doctor",
        checked: false,
        isOpen: false,
        parent: 10,
        children: [
          {
            id: 12,
            label: "Personal Details",
            value: "Personal Details",
            checked: false,
            isOpen: false,
            parent: 11,
          },
          {
            id: 13,
            label: "Professional Details",
            value: "Professional Details",
            checked: false,
            isOpen: false,
            parent: 11,
          },
          {
            id: 14,
            label: "Consultation Details",
            value: "Consultation Details",
            checked: false,
            isOpen: false,
            parent: 11,
          }
        ]
      },
      {
        id: 15,
        label: "Edit Doctor",
        value: "Edit Doctor",
        checked: false,
        isOpen: false,
        parent: 10,
        children: [
          {
            id: 16,
            label: "Personal Details",
            value: "Personal Details",
            checked: false,
            isOpen: false,
            parent: 15,
          },
          {
            id: 17,
            label: "Professional Details",
            value: "Professional Details",
            checked: false,
            isOpen: false,
            parent: 15,
          },
          {
            id: 18,
            label: "Consultation Details",
            value: "Consultation Details",
            checked: false,
            isOpen: false,
            parent: 15,
          }
        ]
      }
    ]
  },
  {
    id: 19,
    label: "Doctor Panel",
    value: "Doctor Panel",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 20,
    label: "Patient Registration",
    value: "Patient Registration",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id:21,
    label:"Doctor Settings",
    value:"Doctor Settings",
    checked:false,
    isOpen:false,
    parent:null,
    children:[
      {
        id:22,
        label:"Personal Details",
        value:"Personal Details",
        checked:false,
        isOpen:false,
        parent:21,
      },
      {
        id:23,
        label:"Professional Details",
        value:"Professional Details",
        checked:false,
        isOpen:false,
        parent:21,
        },
        {
          id:24,
          label:"Consulation Details",
          value:"Consulation Details",
          checked:false,
          isOpen:false,
          parent:21
        },
        {
            id:25,
            label:"Change Password",
            value:"Change Password",
            checked:false,
            isOpen:false,
            parent:21
        },
        {
          id:26,
          label:"Shared Files",
          value:"Shared Files",
          checked:false,
          isOpen:false,
          parent:21,
        }]
      },
  {
    id: 27,
    label: "Patient Search",
    value: "Patient Search",
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 28,
        label: "Personal Details",
        value: "Personal Details",
        checked: false,
        isOpen: false,
        parent: 27,
      },
      {
        id: 29,
        label: "Appoinments",
        value: "Appoinments",
        checked: false,
        isOpen: false,
        parent: 27,
      },
      {
        id: 30,
        label: "Prescriptions",
        value: "Prescriptions",
        checked: false,
        isOpen: false,
        parent: 27,
      },
      {
        id: 31,
        label: "Lab Reports",
        value: "Lab Reports",
        checked: false,
        isOpen: false,
        parent: 27,
      },
      {
        id: 32,
        label: "Medical Condition",
        value: "Medical Condition",
        checked: false,
        isOpen: false,
        parent: 27,
      },
      {
        id: 33,
        label: "Vitals",
        value: "Vitals",
        checked: false,
        isOpen: false,
        parent: 27,
      },
      {
        id: 34,
        label: "Allergies",
        value: "Allergies",
        checked: false,
        isOpen: false,
        parent: 27,
      },
      {
        id: 35,
        label: "Vault",
        value: "Vault",
        checked: false,
        isOpen: false,
        parent: 27,
      },
      {
        id: 36,
        label: "Health Indicator",
        value: "Health Indicator",
        checked: false,
        isOpen: false,
        parent: 27,
      },
      {
        id: 37,
        label: "Family Member",
        value: "Family Member",
        checked: false,
        isOpen: false,
        parent: 27,
      },
      {
        id: 38,
        label: "File Cabinet",
        value: "File Cabinet",
        checked: false,
        isOpen: false,
        parent: 27,
      },
    ]
  }
]

const medicDocPrivilages = [
  {
    id: 1,
    label: "Medical Camp",
    value: "Medical Camp",
    checked: true,
    isOpen: false,
    parent: null,
  },
  {
    id: 2,
    label: "Patient Registration",
    value: "Patient Registration",
    checked: false,
    isOpen: false,
    parent: null,
  },
  {
    id: 3,
    label: "Patient Search",
    value: "Patient Search",
    checked: false,
    isOpen: false,
    parent: null,
    children: [
      {
        id: 4,
        label: "Personal Details",
        value: "Personal Details",
        checked: false,
        isOpen: false,
        parent: 3,
      },
      {
        id: 5,
        label: "Appoinments",
        value: "Appoinments",
        checked: false,
        isOpen: false,
        parent: 3,
      },
      {
        id: 6,
        label: "Prescriptions",
        value: "Prescriptions",
        checked: false,
        isOpen: false,
        parent: 3,
      },
      {
        id: 7,
        label: "Lab Reports",
        value: "Lab Reports",
        checked: false,
        isOpen: false,
        parent: 3,
      },
      {
        id: 8,
        label: "Medical Condition",
        value: "Medical Condition",
        checked: false,
        isOpen: false,
        parent: 3,
      },
      {
        id: 9,
        label: "Vitals",
        value: "Vitals",
        checked: false,
        isOpen: false,
        parent: 3,
      },
      {
        id: 10,
        label: "Allergies",
        value: "Allergies",
        checked: false,
        isOpen: false,
        parent: 3,
      },
      {
        id: 11,
        label: "Vault",
        value: "Vault",
        checked: false,
        isOpen: false,
        parent: 3,
      },
      {
        id: 12,
        label: "Health Indicator",
        value: "Health Indicator",
        checked: false,
        isOpen: false,
        parent: 3,
      },
      {
        id: 13,
        label: "Family Member",
        value: "Family Member",
        checked: false,
        isOpen: false,
        parent: 3,
      },
      {
        id: 14,
        label: "File Cabinet",
        value: "File Cabinet",
        checked: false,
        isOpen: false,
        parent: 3,
      },
    ]
  },
]
  
function PrivilegeDetails(){

  const {searchRoleAvailability,createPrivilege,getPrivilegeList} = PrivilegeService();
  const {GetMasterData} = AdminService();
  const [user, setUser] = store.useState("user");
  const [personID,setPersonID] = store.useState('personID');
  const [doctorUUID,setDoctorUUID] = store.useState('doctorUUID');
  const [dcomponent, setDComponent] = React.useState('basic');
  const [component, setComponent] = store.useState('componentState');
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  
  
  console.log("person id is",personID)
  console.log("doctor id is",doctorUUID)
  
  
  function BasicDetails(){
  const [roleName, setRoleName] = React.useState('');
  const [roleDescription, setRoleDescription] = React.useState('');
  const [role,setRole] = React.useState('');
  const [roleList,setRoleList] = React.useState([]);
  const [items, setItems] = useState();
  const [errorText,setErrorText] = React.useState({
    userType:{
      error:false,
      errorText:"Select an option from DropDown",
      verified:true
    },
    roleName:{
      error:false,
      errorText:"Field cant be empty",
      verified:true
    },
    roleDescription:{
      error:false,
      errorText:"Field cant be empty",
      verified:true
    },
  })
  const [roleStatus,setRoleStatus] = React.useState(false);
  const [roleStatusText,setRoleStatusText] = React.useState('')
  const [roleTextColor,setRoleTextColor] = React.useState('')
  const [showList,setShowList] = React.useState(false)

  function handleValidation(){
  if(role === '' || role !==''){
      if(role === ''){
        setErrorText(previous => ({...previous,userType:{error:true,
          errorText:"Select an option from DropDown",
          verified:false}}))
          return true;
      }else if(role !== ''){
        setErrorText(previous => ({...previous,userType:{error:false,
          errorText:"Select an option from DropDown",
          verified:true}}))
      }
    }
    if(roleName === '' || roleName !==''){
      if(roleName === ''){
        setErrorText(previous => ({...previous,roleName:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
      }else if(roleName !== ''){
        setErrorText(previous => ({...previous,roleName:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
      }
    }
    if(roleDescription === '' || roleDescription !==''){
      if(roleDescription === ''){
        setErrorText(previous => ({...previous,roleDescription:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
      }else if(roleDescription !== ''){
        setErrorText(previous => ({...previous,roleDescription:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
          return false;
      }
    }
  console.log("RESULTS............");
  
}
  
  
  const handleCheckboxChange = (itemId, isChecked,isOpen,parent,item) => {
    
    if(parent !== null){
      const updatedItems = toggleCheckbox(items, itemId, isChecked,isOpen);
      console.log(itemId)
      console.log(parent)
      const parentUpdateItems = updateParentChecked(itemId,updatedItems)
      console.log("UIiiiiiii",parentUpdateItems)
      setItems(parentUpdateItems);
    }else if(parent === null){
      const updatedItems = toggleCheckbox(items, itemId, isChecked,isOpen);
      setItems(updatedItems)
    }
    
  };

  const toggleCheckbox = (itemList, itemId, isChecked,isOpen) => {
    return itemList.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          checked: isChecked,
          isOpen: isOpen,
          children: item.children
            ? item.children.map((child) => ({
                ...child,
                checked: isChecked,
                isOpen: isOpen,
                children: child.children
                ? child.children.map((grandChild) => ({
                    ...grandChild,
                    checked: isChecked,
                    isOpen: isOpen,
                  }))
                : null,
              }))
            : null,
        };
      }

      if (item.children) {
        return {
          ...item,
          children: toggleCheckbox(item.children, itemId, isChecked,isOpen),
        };
      }


      return item;
    });
  };

  const handleClick = (itemId, isOpen) => {
    const updatedItems = handleState(items, itemId, isOpen);
    setItems(updatedItems);
  };

  const handleState = (itemList, itemId, isOpen) => {
    return itemList.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          isOpen: isOpen,
          children: item.children
            ? item.children.map((child) => ({
                ...child,
                isOpen: isOpen,
              }))
            : null,
        };
      }

      if (item.children) {
        return {
          ...item,
          children: handleState(item.children, itemId, isOpen),
        };
      }

      return item;
    });
    
  };

  const updateParentChecked = (childId,data) => {
    
      const parentIds = getParentIds(data, childId);
      console.log("pareeeeee",parentIds)
      const newData = [...data];

      if (parentIds) {
        parentIds.map(parentId => {
          console.log("parentid",parentId)
          console.log("data",data)
          const parent = getObjectById(data,parentId)
          console.log("parent",parent)
          if (parent) {
            parent.checked = true;
          }
        });
      }

      return data;
    
  };
  function getObjectById(data, targetId) {
    for (const item of data) {
      if (item.id === targetId) {
        return item;
      }
      if (item.children) {
        const result = getObjectById(item.children, targetId);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  function getParentIds(data, childId, parentIds = []) {
    for (const item of data) {
      if (item.id === childId) {
        return parentIds;
      }
      if (item.children) {
        const updatedParentIds = [...parentIds, item.id];
        const result = getParentIds(item.children, childId, updatedParentIds);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
  const TreeView = ({ items, onCheckboxChange, onStateChange}) => {
  
    const handleStateChange = (item) => (event) => {
      const isOpen = !item.isOpen;
      onStateChange(item.id, isOpen);
    };
    
    const handleCheckboxChange = (item) => (event) => {
      const isChecked = event.target.checked;
      const isOpen = item.isOpen
      onCheckboxChange(item.id, isChecked,isOpen,item.parent,item);

    };

    return (
      <>
        <ListItem button onClick={handleStateChange(items)} disabled={items.label === 'Patient Dashboard' && items.checked === true || items.label === 'Doctor Dashboard' && items.checked === true || items.label === 'Medical Camp' && items.checked === true? true : false}>
        {items.children ? <ChevronRightIcon /> : null}
          <Checkbox
            checked={items.checked}
            onChange={handleCheckboxChange(items)}
          />
          <ListItemText primary={items.label} />
          
        </ListItem>
        <Collapse in={items.isOpen} timeout="auto" unmountOnExit>
          <List component="div" sx={{paddingLeft: 8}}>
            {items.children &&
              items.children.map((child) => (
                <TreeView
                  key={child.id}
                  items={child}
                  onCheckboxChange={onCheckboxChange}
                  onStateChange={onStateChange}
                />
              ))}
          </List>
        </Collapse>
      </>
    );
  };
  
 

  console.log("priviiiiiiiii",items)
  useEffect(() => {
    GetMasterData('ROLE_MASTER').then(res => setRoleList(res));
  }, []);

    return(
    <Grid item xs={9} md={9} lg={9}>
      <Typography padding={0.5} fontWeight={'bold'}>Basic Details</Typography>
        <Paper sx={{marginTop:1,paddingBottom:1}}>
          <Typography paddingLeft={2} paddingTop={2} color={colors.grey[500]} fontSize={14}>DETAILS</Typography>
            <Grid container spacing={2}>
            <Grid item xs={12} md={5} margin={2}>
            <Typography color={colors.grey[900]} fontWeight={'bold'}>Select User Type</Typography>
            <FormControl fullWidth>
                  <Autocomplete
                  onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                      setRole(newValue.typeID)
                      if(newValue.label === 'ADMIN'){
                        setItems(adminPrivilages)
                        setShowList(true)
                      }else if(newValue.label === 'DOCTOR'){
                        setItems(doctorPrivilages)
                        setShowList(true)
                      }else if(newValue.label === 'MEDICAL_CAMP_DOCTOR'){
                        setItems(medicDocPrivilages)
                        setShowList(true)
                      }
                    }}}
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={roleList}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => <TextField {...params} label="Select User Type"
                  helperText={errorText.userType.error === true ? errorText.userType.errorText:""}
                  error={errorText.userType.error === true ? true : false}
                  autoFocus={errorText.userType.error === true ? true : false}
                  />}
                />
                </FormControl>
            </Grid>
            <Grid item xs={12} md={5} margin={2}></Grid>
              <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Role Name</Typography>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      value={roleName}
                      onChange={(e) => setRoleName(e.target.value)}
                      required
                      fullWidth
                      id="firstName"
                      label="Enter Role Name"
                      size='small'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button variant="outlined" size='small' style={{textTransform: 'none'}} 
                            onClick={() =>{
                              if(roleName === ''){
                                setErrorText(previous => ({...previous,roleName:{error:true,
                                  errorText:"Field can't be empty",
                                  verified:false}}))
                                  setRoleStatus(true)
                              }else{
                                setErrorText(previous => ({...previous,roleName:{error:false,
                                  errorText:"Field can't be empty",
                                  verified:false}}))
                                searchRoleAvailability(user.orgID,roleName).then(res =>{
                                  console.log(res)
                                  if(res.status === 200){
                                    console.log("res",res.id)
                                    if(res.id === false){
                                    
                                    setRoleStatusText("Role Name is Available")
                                    setRoleTextColor('green')
                                    setRoleStatus(true)
                                  }else if(res.id === true){
                                    
                                    setRoleStatusText("Role Name not Available, Please Enter Different RoleName")
                                    setRoleTextColor('red')
                                    setRoleStatus(true)
                                  }
                                }
                                })
                              }
                              
                            }}>Verify</Button>
                          </InputAdornment>
                        ),
                      }}
                      helperText={errorText.roleName.error === true ? errorText.roleName.errorText:""}
                      error={errorText.roleName.error === true ? true : false}
                      autoFocus={errorText.roleName.error === true ? true : false}
                    />
                  
                      {roleStatus === true ? <Typography fontSize={14} color={`${roleTextColor}`}>{roleStatusText}</Typography> : <div></div>}
                      
                </Grid>
              <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Role Description</Typography>
                    <TextField
                      required
                      fullWidth
                      value={roleDescription}
                      onChange={(e) => setRoleDescription(e.target.value)}
                      id="lastName"
                      label="Enter Description"
                      name="lastName"
                      autoComplete="family-name"
                      size='small'
                      helperText={errorText.roleDescription.error === true ? errorText.roleDescription.errorText:""}
                      error={errorText.roleDescription.error === true ? true : false}
                      autoFocus={errorText.roleDescription.error === true ? true : false}
                    />
                </Grid>
              </Grid>
          </Paper>

        <Paper sx={{marginTop:1}}>
          <Typography paddingLeft={2} paddingTop={2} color={colors.grey[500]} fontSize={14}>LIST PRIVILEGES</Typography>
          {showList === true ? <List>
            {items.map((item) => (
              <TreeView
                key={item.id}
                items={item}
                onCheckboxChange={handleCheckboxChange}
                onStateChange={handleClick}
              />
            ))}
        </List> : null} 
          </Paper>

        
        

        <Paper sx={{marginTop:1}}>
              <Grid item xs={12} md={12} margin={2}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                    onClick={() =>{
                      // console.log("itemsOnSAve",items)
                      // var string = JSON.stringify({PrivilegeDetails:JSON.stringify(items)})
                      // console.log("itemsOnString",string)
                      var res = handleValidation();
                      if(res === false){
                        searchRoleAvailability(user.orgID,roleName).then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            createPrivilege(role,user.orgID,roleName,roleDescription,items).then(res =>{
                              console.log(res)
                              if(res.status === 200){
                                toast.success(res.message)
                                setComponent('Role Management')
                              }else{
                                toast.error("Error Occurred")
                              }
                            })
                          }else{
                            setRoleStatus(true)
                            setRoleStatusText("Role Name not Available, Please Enter Different RoleName")
                            setRoleTextColor('red')
                          }
                        })
                      }else{
                        toast.error("Please Check the Input Fields")
                      }
                    }}
                  >
                    Save
                  </Button>
            </Grid>
          </Paper>
        </Grid>
    
    );
  }
    return(
      <Grid container spacing={2}>
                <Grid item xs={2} md={2} lg={2}>
                <Typography padding={0.5} fontWeight={'bold'}>Privileges Management</Typography>
                       <Paper sx={{marginTop:1,borderRadius:3}}>
                        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <List component="nav" aria-label="main mailbox folders"
                        sx={{
                            // selected and (selected + hover) states
                            '&& .Mui-selected, && .Mui-selected:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                            // hover states
                            '& .MuiListItemButton-root:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                          }}
                        >
                            <ListItemButton
                            selected={selectedIndex === 0}
                            onClick={(event) => {
                              
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Basic Details" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton>

                        </List>
                        </Box>
                    </Paper>
                </Grid>
                {dcomponent === 'basic' ? <BasicDetails/> : <div></div>}
            </Grid>
    );
  }

export default PrivilegeDetails;