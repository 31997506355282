import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '@mui/material';
import logoUB from '../../assets/images/logo_ub.png'
import { useNavigate} from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Helper from '../../Helper/Helper';
import Autocomplete from '@mui/material/Autocomplete';
import dayjs from 'dayjs';
import { FileUploader } from "react-drag-drop-files";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import PreRegService from './PreRegistrationService';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

const theme = createTheme();
const {drawerWidth,Main,AppBar,DrawerHeader,Search,SearchIconWrapper,StyledInputBase,patientList,
  generateString,toBase64,containsOnlyNumbers,containsSpecialChars,
  validateEmail,checkPassword} = Helper();

export default function PreRegistrationForm() {
    let navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const {GetGenderDetails,getAddressList,GetMasterData,patientPreReg} = PreRegService();
    const [genderList, setGenderList] = React.useState([]);
    const [addressList, setAddressList] = React.useState([]);
    const [bgList, setBGList] = React.useState([]);
    const [salutationList,setSalutationList] = React.useState([]);
    const [relationshipList, setRelationshipList] = React.useState([]);
    const [idTypeList, setIDTypeList] = React.useState([]);
    const [partnerList,setPartnerList] = React.useState([]);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [prefix, setPrefix] = React.useState('');
    const [prefixID, setPrefixID] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [genderID, setGenderID] = React.useState('');
    const [date, setDate] = React.useState('');
    const [hospitalNumber,setHospitalNumber] = React.useState();
    const [bloodGroup,setBloodGroup] = React.useState('');
    const [bloodGroupID,setBloodGroupID] = React.useState('');
    const [partnerName,setPartnerName] = React.useState('');
    const [partnerID,setPartnerID] = React.useState('');
    const [mobileno, setMobileNum] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [address1, setAddress1] = React.useState('');
    const [pincode, setPincode] = React.useState('');
    const [states, setState] = React.useState('Select State');
    const [city, setCity] = React.useState('Select City');
    const [country, setCountry] = React.useState('Select Country');
    const [idType, setIDType] = React.useState('');
    const [idNumber, setIDNumber] = React.useState('');
    const [idFatherName, setIDFatherName] = React.useState('');
    const [emergencyName, setEmergencyName] = React.useState('');
    const [relation, setRelation] = React.useState('');
    const [emergencyContact, setEmergencyContact] = React.useState('');
    const [familyDoctorName, setFamilyDoctorName] = React.useState('');
    const [familyDoctorNumber, setFamilyDoctorNumber] = React.useState('');
    const fileTypes = ["JPG", "PNG", "GIF","JPEG"];
    const [profilePreview, setProfilePreview] = React.useState(false);
    const [objectURL, setObjectURL] = React.useState(null);
    const [age,setAge] = React.useState('')
    
   
    const [errorText,setErrorText] = React.useState({
      firstName:{
        error:false,
        errorText:"Field cant be empty",
        verified:true
      },
      lastName:{
        error:false,
        errorText:"Field cant be empty",
        verified:true
      },
      prefix:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      gender:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      dob:{
        error:false,
        errorText:"Select Date of Birth",
        verified:true
      },
      bg:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      partnerID:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      
      email:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      phone:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      pincode:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      addr1:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      idType:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      idNumber:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      idFatherName:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      emergencyName:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      emergencyNumber:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      emergencyContact:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      profileImage:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      relation:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      }, 

    })

    function handleValidation(){
    
    if(firstName === "" || firstName !== ""){
          if(firstName === ""){
            setErrorText(previous => ({...previous,firstName:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(containsSpecialChars(firstName) === true){
            setErrorText(previous => ({...previous,firstName:{error:true,
              errorText:"Field cannot contain Special Characters",
              verified:false}}))
              return true;
          }else if(containsOnlyNumbers(firstName) === true){
            setErrorText(previous => ({...previous,firstName:{error:true,
              errorText:"Field cannot contain Numbers",
              verified:false}}))
              return true;
          }else if(firstName !== ""){
            setErrorText(previous => ({...previous,firstName:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))

          }       
      }
    if(lastName === "" || lastName !== ""){
        if(lastName === ""){
          setErrorText(previous => ({...previous,lastName:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(containsSpecialChars(lastName) === true){
          setErrorText(previous => ({...previous,lastName:{error:true,
            errorText:"Field cannot contain Special Characters",
            verified:false}}))
            return true;
        }else if(containsOnlyNumbers(lastName) === true){
          setErrorText(previous => ({...previous,lastName:{error:true,
            errorText:"Field cannot contain Numbers",
            verified:false}}))
            return true;
        }else if(lastName !== ""){
          setErrorText(previous => ({...previous,lastName:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
          
        }         
      }
    if(prefix === '' || prefix !==''){
        if(prefix === ''){
          setErrorText(previous => ({...previous,prefix:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(prefix !== ''){
          setErrorText(previous => ({...previous,prefix:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    if(gender === '' || gender !==''){
        if(gender === ''){
          setErrorText(previous => ({...previous,gender:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(gender !== ''){
          setErrorText(previous => ({...previous,gender:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    if(date === '' || date !==''){
        if(date === ''){
          setErrorText(previous => ({...previous,dob:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(date !== ''){
          setErrorText(previous => ({...previous,dob:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    if(bloodGroup === '' || bloodGroup !==''){
        if(bloodGroup === ''){
          setErrorText(previous => ({...previous,bg:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(bloodGroup !== ''){
          setErrorText(previous => ({...previous,bg:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    // if(partnerID === '' || partnerID !==''){
    //     if(partnerID === ''){
    //       setErrorText(previous => ({...previous,partnerID:{error:true,
    //         errorText:"Select an option from DropDown",
    //         verified:false}}))
    //         return true;
    //     }else if(partnerID !== ''){
    //       setErrorText(previous => ({...previous,partnerID:{error:false,
    //         errorText:"Select an option from DropDown",
    //         verified:true}}))
    //     }
    // }
    
    if(mobileno === "" || mobileno !== ""){
        if(mobileno === ""){
          setErrorText(previous => ({...previous,phone:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(mobileno.length < 10){
          setErrorText(previous => ({...previous,phone:{error:true,
            errorText:"Minimum length should be 10 digits",
            verified:false}}))
            return true;
        }else if(containsSpecialChars(mobileno) === true){
          setErrorText(previous => ({...previous,phone:{error:true,
            errorText:"Field cannot contain Special Characters",
            verified:false}}))
            return true;
        }else if(containsOnlyNumbers(mobileno) === true && mobileno !== ""){
          setErrorText(previous => ({...previous,phone:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
            
        }       
      }
    if(email === "" || email !== ""){
        if(email === ""){
          setErrorText(previous => ({...previous,email:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(containsOnlyNumbers(email) === true){
          setErrorText(previous => ({...previous,email:{error:true,
            errorText:"Field cannot contain only Numbers",
            verified:false}}))
            return true;
        }else if(validateEmail(email) === false){
          setErrorText(previous => ({...previous,email:{error:true,
            errorText:"Email is not valid should contain '@' and '.'",
            verified:false}}))
            return true;
        }else if(email !== ""){
          setErrorText(previous => ({...previous,email:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
            
        }        
      }
      if(address1 === '' || address1 !==''){
        if(address1 === ''){
          setErrorText(previous => ({...previous,addr1:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(address1 !== ''){
          setErrorText(previous => ({...previous,addr1:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
        }
      }
      if(pincode === '' || pincode !==''){
        if(pincode === ''){
          setErrorText(previous => ({...previous,pincode:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(pincode !== ''){
          setErrorText(previous => ({...previous,pincode:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    
      if(idType === '' || idType !==''){
        if(idType === ''){
          setErrorText(previous => ({...previous,idType:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(idType !== ''){
          setErrorText(previous => ({...previous,idType:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
      if(idNumber === '' || idNumber !==''){
        if(idNumber === ''){
          setErrorText(previous => ({...previous,idNumber:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(idNumber !== ''){
          setErrorText(previous => ({...previous,idNumber:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
        }
      }
      if(idFatherName === '' || idFatherName !==''){
        if(idFatherName === ''){
          setErrorText(previous => ({...previous,idFatherName:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(idFatherName !== ''){
          setErrorText(previous => ({...previous,idFatherName:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
        }
      }
      
      if(emergencyName === "" || emergencyName !== ""){
        if(emergencyName === ""){
          setErrorText(previous => ({...previous,emergencyName:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
            
        }else if(containsSpecialChars(emergencyName) === true){
          setErrorText(previous => ({...previous,emergencyName:{error:true,
            errorText:"Field cannot contain Special Characters",
            verified:false}}))
            return true;
        }else if(containsOnlyNumbers(firstName) === true){
          setErrorText(previous => ({...previous,firstName:{error:true,
            errorText:"Field cannot contain Numbers",
            verified:false}}))
            return true;
        }else if(emergencyName !== ""){
          setErrorText(previous => ({...previous,emergencyName:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))

        }       
    }
    if(relation === '' || relation !==''){
      if(relation === ''){
        setErrorText(previous => ({...previous,relation:{error:true,
          errorText:"Select an option from DropDown",
          verified:false}}))
          return true;
      }else if(relation !== ''){
        setErrorText(previous => ({...previous,relation:{error:false,
          errorText:"Select an option from DropDown",
          verified:true}}))
      }
    }
    if(emergencyContact === "" || emergencyContact !== ""){
      if(emergencyContact === ""){
        setErrorText(previous => ({...previous,emergencyContact:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
      }else if(emergencyContact.length < 10){
        setErrorText(previous => ({...previous,emergencyContact:{error:true,
          errorText:"Minimum length should be 10 digits",
          verified:false}}))
          return true;
      }else if(containsSpecialChars(emergencyContact) === true){
        setErrorText(previous => ({...previous,emergencyContact:{error:true,
          errorText:"Field cannot contain Special Characters",
          verified:false}}))
          return true;
      }else if(containsOnlyNumbers(emergencyContact) === true && emergencyContact !== ""){
        setErrorText(previous => ({...previous,emergencyContact:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
          
      }       
    }
    if(objectURL === null || objectURL !== null){
      if(objectURL === null){
        setErrorText(previous => ({...previous,profileImage:{error:true,
          errorText:"Please Upload Profile Image",
          verified:false}}))
          return true;
      }else if(objectURL !== null){
        setErrorText(previous => ({...previous,profileImage:{error:false,
          errorText:"Select an option from DropDown",
          verified:true}}))
          return false;
        }
      }
    console.log("RESULTS............");
    
  }
   
  const handleProfileChange = async (newFile) => {
    setObjectURL(await toBase64(newFile));
  setProfilePreview(true);
};
  const handleDateChange = (newDate) => {
    setDate(dayjs(newDate).format('YYYY-MM-DD'));
    calculateAge(newDate)
  };
  function routeToDoctorDashBoard(){
    navigate('/login');
  }

  const calculateAge = (dob) => {
    const currentDate = new Date();
    const birthDate = new Date(dob);

    let calculatedAge = currentDate.getFullYear() - birthDate.getFullYear();

    // Adjust age based on month and day comparison
    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      calculatedAge--;
    }

    setAge(calculatedAge);
  };
  console.log("MOBNO",state.number)
  React.useEffect(() => {
    GetGenderDetails().then(res => setGenderList(res));
    getAddressList().then(res => setAddressList(res.content));
    GetMasterData('BG').then(res => setBGList(res))
    GetMasterData('SALUTAION').then(res => setSalutationList(res))
    GetMasterData('RELATIONSHIP').then(res => setRelationshipList(res))
    GetMasterData('ID_TYPE').then(res => setIDTypeList(res))
    GetMasterData('PARTNER').then(res => setPartnerList(res))
    setPartnerName(process.env.REACT_APP_PARTNER_NAME)
    setPartnerID(process.env.REACT_APP_PARTNER_ID)
    setMobileNum(state.number)
  },[])

  console.log('name',partnerName)
  console.log('id',partnerID)
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ width:'100%',height: '100vh',bgcolor:colors.grey[200]}}>
        <CssBaseline />
        <Grid item xs={12} sm={12} md={12} sx={{bgcolor:colors.grey[200],marginTop:0}}>
            <Paper elevation={3} sx={{width:'100%',margin:0,position:'fixed',zIndex:'999'}}>
                <Grid container spacing={2}>
                        <Grid>
                        <Avatar
                            alt="logo"
                            src={logoUB}
                            sx={{ width: 80, height: 80, padding:3}}
                            variant = 'square'
                        />
                    </Grid>
                    <Grid>
                    <Typography component="h1" variant="h5" sx={{fontWeight: 'bold',marginTop:3}}>
                        Pre Registration Form
                        </Typography>
                    </Grid>
                        
                  </Grid>
              </Paper>     
            <Grid item xs={8} md={8} lg={8} marginLeft={'20%'} marginTop={'10vh'}>
              <Paper sx={{marginTop:1}}>
                    <Typography padding={2} color={colors.grey[400]}>DETAILS</Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={5} margin={2}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>First Name</Typography>
                      <TextField
                        autoComplete="given-name"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        fullWidth
                        id="firstName"
                        label="Enter First Name"
                        size='small'
                        helperText={errorText.firstName.error === true ? errorText.firstName.errorText:""}
                        error={errorText.firstName.error === true ? true : false}
                        autoFocus={errorText.firstName.error === true ? true : false}
                      />
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>Last Name</Typography>
                          <TextField
                            required
                            fullWidth
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            id="lastName"
                            label="Enter Last Name"
                            name="lastName"
                            autoComplete="family-name"
                            size='small'
                            helperText={errorText.lastName.error === true ? errorText.lastName.errorText:""}
                            error={errorText.lastName.error === true ? true : false}
                            autoFocus={errorText.lastName.error === true ? true : false}
                          />
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                        <Typography component="h1" variant="h10" fontWeight={'bold'}>
                        Prefix / Salutations
                        </Typography>
                        <FormControl fullWidth>

                        <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                          setPrefix(newValue.label)
                          setPrefixID(newValue.typeID)
                        }}}
                      size="small"
                      disablePortal
                      value={prefix}
                      id="combo-box-demo"
                      options={salutationList}
                      getOptionLabel={(option) => option.label || prefix}
                      renderInput={(params) => <TextField 
                        {...params} label="Select Prefix"
                        helperText={errorText.prefix.error === true ? errorText.prefix.errorText:""}
                        error={errorText.prefix.error === true ? true : false}
                        autoFocus={errorText.prefix.error === true ? true : false}
                        />}
                    />
                      </FormControl>
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>Gender</Typography>
                    <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.genderCode){
                        setGender(newValue.genderName)
                        setGenderID(newValue.genderCode)
                      }}}
                    size="small"
                    disablePortal
                    value={gender}
                    id="combo-box-demo"
                    options={genderList}
                    getOptionLabel={(option) => option.genderName || gender}
                    renderInput={(params) => <TextField {...params} label="Select Gender"
                        helperText={errorText.gender.error === true ? errorText.gender.errorText:""}
                        error={errorText.gender.error === true ? true : false}
                        autoFocus={errorText.gender.error === true ? true : false}
                    
                    />}
                  />
                  </FormControl>
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>Date of Birth</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Date of Birth"
                      inputFormat="yyyy/MM/dd"
                      value={date}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField size='small' fullWidth {...params} value={date}
                      helperText={errorText.dob.error === true ? errorText.dob.errorText:""}
                      error={errorText.dob.error === true ? true : false}
                      autoFocus={errorText.dob.error === true ? true : false}
                      />}
                    />
                </LocalizationProvider>
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                        <Typography component="h1" variant="h10" fontWeight={'bold'}>
                          Age
                        </Typography>
                            <TextField
                            autoComplete="given-name"
                            name="age"
                            required
                            value={age}
                            fullWidth
                            id="typeage"
                            label="Enter Age"
                            size='small'
                            />
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                        <Typography component="h1" variant="h10" fontWeight={'bold'}>
                          Hospital Number
                        </Typography>
                            <TextField
                            autoComplete="given-name"
                            value={hospitalNumber}
                            onChange={(e) =>{setHospitalNumber(e.target.value)}}
                            name="hospnum"
                            fullWidth
                            id="hospnum"
                            label="Enter Hospital Number"
                            size='small'
                            />
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                        <Typography component="h1" variant="h10" color={colors.grey[500]} fontSize={'2vh'}>
                          If you are an existing patient, providing your existing number will help your Doctor
                          to refer your previous records and provide a clinical opinion
                        </Typography>
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>Blood Group</Typography>
                    <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setBloodGroup(newValue.label)
                        setBloodGroupID(newValue.typeID)
                      }}}
                    size="small"
                    disablePortal
                    value={bloodGroup}
                    id="combo-box-demo"
                    options={bgList}
                    getOptionLabel={(option) => option.label || bloodGroup}
                    renderInput={(params) => <TextField {...params} label="Select Blood Group"
                      helperText={errorText.bg.error === true ? errorText.bg.errorText:""}
                      error={errorText.bg.error === true ? true : false}
                      autoFocus={errorText.bg.error === true ? true : false}
                    />}
                  />
                    </FormControl>
                          </Grid>
                          <Grid item xs={12} md={5} margin={2}>
            <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Hospital / Clinic</Typography>
            <FormControl fullWidth>
                  <Autocomplete
                  onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                      setPartnerID(newValue.uuid)
                      setPartnerName(newValue.label)
                    }}}
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={partnerList}
                  value={partnerName}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.typeID}>
                        {`${option.label} [${option.uuid}]`}
                      </li>
                    );
                  }}
                  getOptionLabel={(option) => option.label || partnerName}
                  renderInput={(params) => <TextField {...params} label="Select Hospital / Clinic "
                  helperText={errorText.partnerID.error === true ? errorText.partnerID.errorText:""}
                  error={errorText.partnerID.error === true ? true : false}
                  autoFocus={errorText.partnerID.error === true ? true : false}
                  

                  />}
                />
                </FormControl>
            </Grid>
                      </Grid>
                  </Paper>
                <Paper sx={{marginTop:1}}>
                  <Typography padding={2} color={colors.grey[400]}>CONTACT</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} margin={2}>
                    <Typography component="h1" variant="h10" fontWeight={'bold'}>
                      Mobile Number
                    </Typography>
                    <TextField
                      value={mobileno}
                      disabled={mobileno !== '' ? true : false}
                      onChange={(e) => setMobileNum(e.target.value)}
                      autoComplete="given-name"
                      name="mobilenum"
                      required
                      fullWidth
                      id="mobilenum"
                      label="Enter Mobile Number"
                      size='small'
                      type="number"
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                      }}
                      helperText={errorText.phone.error === true ? errorText.phone.errorText:""}
                      error={errorText.phone.error === true ? true : false}
                      autoFocus={errorText.phone.error === true ? true : false}
                    />
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                        <Typography component="h1" variant="h10" fontWeight={'bold'}>
                        Email
                        </Typography>
                        <TextField
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      fullWidth
                      id="email"
                      label="Enter Email"
                      name="email"
                      autoComplete="family-name"
                      size='small'
                      helperText={errorText.email.error === true ? errorText.email.errorText:""}
                      error={errorText.email.error === true ? true : false}
                      autoFocus={errorText.email.error === true ? true : false}
                    />
                        </Grid> 
                      </Grid>
                  </Paper>
                <Paper sx={{marginTop:1}}>
                        <Typography padding={2} color={colors.grey[400]}>ADDRESS</Typography>
                        <Grid container spacing={2}>
                      <Grid item xs={12} md={10} margin={2}>
                        <Typography component="h1" variant="h10" fontWeight={'bold'}>
                          Full Address
                          </Typography>
                          <TextField
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                          autoComplete="given-name"
                          name="addr1"
                          required
                          fullWidth
                          multiline
                          rows={3}
                          id="addr1"
                          label="Enter Address"
                          size='small'
                          helperText={errorText.addr1.error === true ? errorText.addr1.errorText:""}
                          error={errorText.addr1.error === true ? true : false}
                          autoFocus={errorText.addr1.error === true ? true : false}
                        />
                        </Grid>
                      <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>Pincode</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.postalCode){
                          setPincode(newValue.postalCode)
                          setCity(newValue.district)
                          setState(newValue.state);
                          setCountry(newValue.country);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      value={pincode}
                      options={addressList}
                      getOptionLabel={(option) => option.postalCode || pincode}
                      renderInput={(params) => <TextField {...params} label="Enter PinCode" 
                        helperText={errorText.pincode.error === true ? errorText.pincode.errorText:""}
                        error={errorText.pincode.error === true ? true : false}
                        autoFocus={errorText.pincode.error === true ? true : false}
                      />}
                    />
                  </FormControl>
                        </Grid>
                      <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>City</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.district){
                          setCity(newValue.district);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={addressList}
                      getOptionLabel={(option) => option.district || ""}
                      renderInput={(params) => <TextField {...params} label={city}/>}
  
                    />
                  </FormControl>    
                        </Grid>   
                      <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>State</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.state){
                          setState(newValue.state);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={addressList}
                      getOptionLabel={(option) => option.state}
                      renderInput={(params) => <TextField {...params} label={states} />}
  
                    />
                  </FormControl>
                        </Grid> 
                      <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>Country</Typography>
              <FormControl fullWidth>
                  <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.country){
                          setCountry(newValue.country);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={addressList}
                      getOptionLabel={(option) => option.country}
                      renderInput={(params) => <TextField {...params} label={country} value={country}/>}
  
                    />
                  </FormControl>
                        </Grid>                 
                  </Grid>
                  </Paper>                  
                <Paper sx={{marginTop:1}}>
                      <Typography padding={2} color={colors.grey[400]}>ID</Typography>
                      <Grid container spacing={2}>
                          <Grid item xs={12} md={5} margin={2}>
                          <Typography component="h1" variant="h10" fontWeight={'bold'}>
                                  Valid ID Type
                                </Typography>
                                <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setIDType(newValue.label)}}}
                    size="small"
                    disablePortal
                    value={idType}
                    id="combo-box-demo"
                    options={idTypeList}
                    getOptionLabel={(option) => option.label || idType}
                    renderInput={(params) => <TextField {...params} label="Select ID Type"
                      helperText={errorText.idType.error === true ? errorText.idType.errorText:""}
                      error={errorText.idType.error === true ? true : false}
                      autoFocus={errorText.idType.error === true ? true : false}
                    />}
                  />
                    </FormControl>
                            </Grid>
                          <Grid item xs={12} md={5} margin={2}>
                          <Typography component="h1" variant="h10" fontWeight={'bold'}>
                            ID Proof Number
                          </Typography>
                              <TextField
                              autoComplete="given-name"
                              name="idproofnum"
                              value={idNumber}
                              onChange={(e)=>{setIDNumber(e.target.value)}}
                              required
                              fullWidth
                              id="idproofnum"
                              label="Enter ID Proof Number"
                              size='small'
                              helperText={errorText.idNumber.error === true ? errorText.idNumber.errorText:""}
                              error={errorText.idNumber.error === true ? true : false}
                              autoFocus={errorText.idNumber.error === true ? true : false}
                              />
                            </Grid>
                          <Grid item xs={12} md={5} margin={2}>
                          <Typography component="h1" variant="h10" fontWeight={'bold'}>
                            Father / Spouse / Guardian Name
                          </Typography>
                              <TextField
                              autoComplete="given-name"
                              value={idFatherName}
                              name="guardian"
                              onChange={(e) =>{setIDFatherName(e.target.value)}}
                              required
                              fullWidth
                              id="guardian"
                              label="Enter Father / Spouse / Guardian Name"
                              size='small'
                              helperText={errorText.idFatherName.error === true ? errorText.idFatherName.errorText:""}
                              error={errorText.idFatherName.error === true ? true : false}
                              autoFocus={errorText.idFatherName.error === true ? true : false}
                              />
                            </Grid>
                        </Grid>
                </Paper>     
                <Paper sx={{marginTop:1}}>
                  <Typography padding={2} color={colors.grey[400]}>EMERGENCY CONTACT</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} margin={2}>
                            <Typography component="h1" variant="h10" fontWeight={'bold'}>
                              Name
                            </Typography>
                                <TextField
                                autoComplete="given-name"
                                name="emergencyname"
                                value={emergencyName}
                                required
                                onChange={(e) =>{setEmergencyName(e.target.value)}}
                                fullWidth
                                id="emergencyname"
                                label="Enter Contact Name"
                                size='small'
                                helperText={errorText.emergencyName.error === true ? errorText.emergencyName.errorText:""}
                                error={errorText.emergencyName.error === true ? true : false}
                                autoFocus={errorText.emergencyName.error === true ? true : false}
                                />
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                            <Typography component="h1" variant="h10" fontWeight={'bold'}>
                                    Relationship
                                  </Typography>
                                  <FormControl fullWidth>
                      <Autocomplete
                        onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                            setRelation(newValue.label)
                        }}}
                        size="small"
                        disablePortal
                        value={relation}
                        id="combo-box-demo"
                        options={relationshipList}
                        getOptionLabel={(option) => option.label || relation}
                        renderInput={(params) => <TextField {...params} label="Select Relationship"
                        helperText={errorText.relation.error === true ? errorText.relation.errorText:""}
                        error={errorText.relation.error === true ? true : false}
                        autoFocus={errorText.relation.error === true ? true : false}
                        />}
                      />
                    </FormControl>
                          </Grid>
                        <Grid item xs={12} md={5} margin={2}>
                            <Typography component="h1" variant="h10" fontWeight={'bold'}>
                              Mobile Number
                            </Typography>
                            <TextField
                              value={emergencyContact}
                              onChange={(e) => setEmergencyContact(e.target.value)}
                              autoComplete="given-name"
                              name="mobilenum"
                              required
                              fullWidth
                              id="mobilenum"
                              label="Enter Mobile Number"
                              size='small'
                              type="number"
                              onInput = {(e) =>{
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                              }}
                              helperText={errorText.emergencyContact.error === true ? errorText.emergencyContact.errorText:""}
                              error={errorText.emergencyContact.error === true ? true : false}
                              autoFocus={errorText.emergencyContact.error === true ? true : false}
                            />
                          </Grid>
                          <Grid item xs={12} md={5} margin={2}></Grid>
                        <Grid item xs={12} md={5} margin={2}>
                            <Typography component="h1" variant="h10" fontWeight={'bold'}>
                              Doctor Name
                            </Typography>
                                <TextField
                                autoComplete="given-name"
                                value={familyDoctorName}
                                name="emergencydoctor"
                                onChange={(e) =>{setFamilyDoctorName(e.target.value)}}
                                required
                                fullWidth
                                id="emergencydoctor"
                                label="Enter Doctor Name"
                                size='small'
                                />
                          </Grid>
                          <Grid item xs={12} md={5} margin={2}>
                            <Typography component="h1" variant="h10" fontWeight={'bold'}>
                              Doctor Number
                            </Typography>
                                <TextField
                                value={familyDoctorNumber}
                                onChange={(e) => setFamilyDoctorNumber(e.target.value)}
                                autoComplete="given-name"
                                name="mobilenum"
                                required
                                fullWidth
                                id="mobilenum"
                                label="Enter Doctor Number"
                                size='small'
                                type="number"
                                onInput = {(e) =>{
                                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                }}
                                
                                />
                          </Grid>
                      </Grid>
                  </Paper>
                  <Grid item xs={4} md={4} marginTop={1}>
                  <Paper sx={{height:'40vh'}}>
                    <Typography padding={1} fontWeight={'bold'} fontSize={16}>Profile Picture</Typography>
                    <Grid container spacing={2}>
                    <Grid item xs={5} md={5} padding={1}>
                    {profilePreview === true ? <Avatar alt="Remy Sharp"
                              src={objectURL} 
                              sx={{width:100,height:100, margin:'auto'}}/>: <div></div>}
                    </Grid>
                    <Grid item xs={7} md={7} marginBottom={2} paddingRight={1}>
                      <FileUploader 
                            handleChange={handleProfileChange} 
                            name="file" 
                            types={fileTypes} 
                            multiple={false}
                            children={
                              <Box sx={{width:'100%',height:'auto',border:3,borderColor:'#651fff',borderStyle:'dashed',padding:3}}>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2}
                              >drag and drop to upload</Typography>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2} marginBottom={2}
                              >or</Typography>
                              <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF'}} onClick={handleProfileChange} size={'small'} fullWidth>
                                  Upload
                              </Button>
                            </Box>
                            }
                            />
                      {errorText.profileImage.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.profileImage.errorText}</Typography> : <div></div>}
                      </Grid>
                    </Grid>                   
                    </Paper>
                  </Grid>
                  
                  <Paper sx={{marginTop:3,marginBottom:3}}>
                    <Grid container spacing={2} padding={1}>
                    <Grid item xs={5} md={5} margin={2}>
                            <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{borderRadius: 2,bgcolor:'#651fff'}}
                            onClick ={()=>{
                              var valid = handleValidation()
                              if(valid === false){
                                patientPreReg(firstName,lastName,prefix,genderID,email,mobileno,date,bloodGroupID,hospitalNumber,address1,city,states,country,pincode,partnerID,idType,idNumber,idFatherName,
                                  objectURL,emergencyName,emergencyContact,relation,familyDoctorName,familyDoctorNumber).then(res =>{
                                    console.log(res)
                                    if(res.status === 200){
                                      toast.success(res.message)
                                      navigate('/login')
                                    }else{
                                      toast.error('Error Occurred')
                                    }
                                  })
                              }else{
                                toast.error("Please Check the Input Fields")
                              }
                            }}
                            >
                            Register
                            </Button>
                         </Grid>
                        <Grid item xs={5} md={5} margin={2}>
                                <Button
                                fullWidth
                                variant="outlined"
                                onClick={() =>{
                                  setFirstName('')
                                  setLastName('')
                                  setPrefix('')
                                  setGender('')
                                  setDate('')
                                  setHospitalNumber('')
                                  setBloodGroup('')
                                  setPartnerID('')
                                  setPartnerName('')
                                  setMobileNum(state.number)
                                  setEmail('')
                                  setAddress1('')
                                  setPincode('')
                                  setCity('Select City')
                                  setState('Select State')
                                  setCountry('Select Country')
                                  setIDType('')
                                  setIDNumber('')
                                  setIDFatherName('')
                                  setEmergencyName('')
                                  setEmergencyContact('')
                                  setRelation('')
                                  setFamilyDoctorName('')
                                  setFamilyDoctorNumber('')
                                  setAge('')
                                  setObjectURL('')
                                }}                         
                                >
                                Reset
                                </Button>
                          </Grid>
                    </Grid>
                    </Paper>                
                </Grid>         
            </Grid> 
        </Grid>
    </ThemeProvider>
  );
}