import * as React from 'react';
import Grid from '@mui/material/Grid';
import { colors,Icon,IconButton,Paper, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import person_img from '../../../assets/images/person.jpg';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import Badge from '@mui/material/Badge';
import EditIcon from '@mui/icons-material/Edit';
import { styled,useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DownloadIcon from '@mui/icons-material/Download';
import prescription_img from '../../../assets/images/prescription_file.png';
import diagonsis_file from '../../../assets/images/diagonsis_file.png';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import GroupIcon from '@mui/icons-material/Group';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { DataGrid } from '@mui/x-data-grid';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from '@mui/material/Autocomplete';
import AdminService from '../../AdminDashboard/AdminService';
import dayjs from 'dayjs';
import store from '../../../context/StatePoolService';
import SearchService from './SearchService';
import EncounterService from '../AppointmentsComponent/EncounterService';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import Helper from '../../../Helper/Helper';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import ListItem from '@mui/material/ListItem';
import { confirmAlert } from 'react-confirm-alert';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ScheduleService from '../DoctorScheduling/ScheduleService';
import FaceSheet from '../PatientsCard/FaceSheet';
import ShareIcon from '@mui/icons-material/Share';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import LockIcon from '@mui/icons-material/Lock';
import ListItemAvatar from '@mui/material/ListItemAvatar';





  const {generateString,containsOnlyNumbers,containsSpecialChars,
    validateEmail} = Helper();

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function SearchResults(props){

    const {getMedicineList,getLabTestNameList,MasterDataEncounterSearch,getVideoURL} = EncounterService();
    const {getPatientPersoanlDetails,createNewAllergy,getAllergyDetails,getAllergyList,
    createNewMedicalCondition,getMedicalConditionList,getMedicialConditionDetails,
    createLabReport,getLabReportDetails,getLabReportList,createNewPrescription,getPrescriptionDetails,getPrescriptionList,
    createHealthIndicator,getHealthIndicatorList,getHealthIndicatorDetail,updateHealthIndicator,deleteHealthIndicator,
    getPastAppointmentList,getUpcomingAppointmentList,getPendingAppointmentList,addFileCabinet,deleteFileCabinet,editFileCabinet,getFileCabinetDetails,getFileCabinetList,getShareFileDetail,addAccesstoFile,removeAccesstoFile} = SearchService();
    const {insertFilestoVault,fileUpload,fileDownload,GetMasterSearchData,
      getDoctorsList,getVaultListFile,addFamilyMember,getFamilyMemberDetails,deleteFamilyMember,updateFamilyMember,getFamilyMemberList,
    getRelationshipList,GetGenderDetails,GetMasterData} = AdminService();
    const {getDetailsByAptId,cancelAppointment} = ScheduleService();
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [dComponent,setDComponent] = React.useState('personal')
    const [addPrescription, setAddPrescription] = React.useState(false);
    const [openShareFile, setOpenShareFile] = React.useState(false);
    const [addLabReport, setAddLabReport] = React.useState(false);
    const [addVitals, setAddVitals] = React.useState(false);
    const [addAllergy, setAddAllergy] = React.useState(false);
    const [addMC, setAddMC] = React.useState(false);
    const [addFile, setAddFile] = React.useState(false);
    const [editFile, setEditFile] = React.useState(false);
    const [addFilestoVault, setAddFilestoVault] = React.useState(false);
    const [vitalListData, setVitalListData] = React.useState([]);
    const [allergyListData, setAllergyListData] = React.useState([]);
    const [labReportListData,setLabReportListData] = React.useState([]);
    const [healthIndicatorListData,setHealthIndicatorListData] = React.useState([]);
    const [mcListData, setMCListData] = React.useState([]);
    const [fcListData, setFCListData] = React.useState([]);
    const [vaultListData, setVaultListData] = React.useState([]);
    const [prescriptionListData, setPrescriptionListData] = React.useState([]);
    const [showVitals, setShowVitals] = React.useState(false);
    const [showAllergy, setShowAllergy] = React.useState(false);
    const [showMC, setShowMC] = React.useState(false);
    const [showFCDetail, setShowFCDetail] = React.useState(false);
    const [showLabReport, setShowLabReport] = React.useState(false);
    const [showHealthIndicator, setShowHealthIndicator] = React.useState(false);
    const [showPrescription, setShowPrescription] = React.useState(false);
    const [vitalPopupDetails,setVitalDetails] = React.useState([]);
    const [allergyPopupDetails,setAllergyDetails] = React.useState([]);
    const [mcPopupDetails,setMCDetails] = React.useState([]);
    const [labPopupDetails,setLabDetails] = React.useState([]);
    const [fcPopupDetails,setFCDetails] = React.useState([]);
    const [vaultPopupDetails,setVaultDetails] = React.useState([]);
    const [prescriptionPopupDetails,setPrescriptionDetails] = React.useState([]);
    const [healthDetails,setHIDetails] = React.useState({})
    const inputRef  = React.useRef(null);
    const [user, setUser] = store.useState("user");
    const [patient, setPatient] = store.useState("patient");
    const [faceSheetData, setFaceSheetData] = React.useState();
    const [openSymptomsPopup, setSymptomsOpenPopup] = React.useState(false);
    const [editSymptom, setEditSymptom] = React.useState(false);
    const [showPast, setShowPast] = React.useState(false);
    const [showPending, setShowPending] = React.useState(false);
    const [member_id,setMemberID] = React.useState('')
    const [fc_id,setFCID] = React.useState('')
    const [showPastApt, setShowPastApt] = React.useState([]);
    const [showUpcomingApt, setShowUpcomingApt] = React.useState([]);
    const [showPendingApt, setShowPendingApt] = React.useState([]);
    const [pendingAppointmentList,setPendingAppointmentList] = React.useState([])
    const [upcomingAppointmentList,setUpcomingAppointmentList] = React.useState([])
    const [pastAppointmentList,setPastAppointmentList] = React.useState([])
    const [defaultMonthYear,setDefaultMonthYear] = React.useState(new Date())
    const [aptData, setAptData] = React.useState({});
    const [patientData, setPatientData] = React.useState();
    const [openFaceSheet, setFaceSheetOpenPopup] = React.useState(false);
    const [component, setComponent] = store.useState('componentState');
    const [componentDetails, setComponentDetails] = store.useState('componentDetails');
    const [stateData, setStateData] = React.useState({
      personal: false,
      appointment: false,
      prescription: false,
      labReport: false,
      medicalCondition: false,
      vital: false,
      allergy: false,
      vault: false,
      healthIndicator: false,
      familyMember: false,
      fileCabinet: false,
    })
    console.log("person id is",user.personId)
    console.log("doctor id is",user.uuid)
    console.log("doctor state",stateData)
    

      const handleDateChange = (newValue) => {
        setDefaultMonthYear(newValue);
        console.log("start Date is",getFirstDayOfMonth(new Date(newValue).getFullYear(),new Date(newValue).getMonth()))
        console.log("end Date is",getLastDayOfMonth(new Date(newValue).getFullYear(),new Date(newValue).getMonth()))
        console.log("formatted start date is",format(getFirstDayOfMonth(new Date(newValue).getFullYear(),new Date(newValue).getMonth())))
        
          getPastAppointmentList(patient.uuid,
            format(getFirstDayOfMonth(new Date(newValue).getFullYear(),new Date(newValue).getMonth())),
            format(getLastDayOfMonth(new Date(newValue).getFullYear(),new Date(newValue).getMonth()))).then(res => {
              console.log("past appointment list",res)
              // setShowPastApt([])
              if(res.status === 200){
                setPastAppointmentList(res.data)
                for (let i = 0; i < res.data.length; i++) {
                  showPastApt.push(false);
              }
              console.log(showPastApt);
              setShowPast(!showPast)
              }else{
                setPastAppointmentList([])
                setShowPast(!showPast)
                console.log('error')
              }
            })
        
      };
      function getFirstDayOfMonth(year, month) {
        return new Date(year, month, 1);
      }

      function getLastDayOfMonth(year, month) {
        return new Date(year, month + 1, 0);
      }

      function format(inputDate) {
        let date, month, year;
      
        date = inputDate.getDate();
        month = inputDate.getMonth() + 1;
        year = inputDate.getFullYear();
      
          date = date
              .toString()
              .padStart(2, '0');
      
          month = month
              .toString()
              .padStart(2, '0');
      
        return `${year}-${month}-${date}`;
      }
    
    console.log('Patient Details',patient)
    console.log('Patient Component Details',componentDetails)
    
    const renderState = () =>{
      var rcomponent = null;
      componentDetails.children.map((detail,index) =>{
        console.log("STATES",detail)
        if(detail.label === 'Personal Details' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            personal: true
         }))
         getPatientPersoanlDetails(props.patientUUID).then(res =>{
          console.log("PDATA",res)
          if(res.status === 200){
            setPatientData(res.data)
            }
        })
        if(rcomponent === null){
          rcomponent = 'personal';
          setSelectedIndex(index)
         }
        }
        if(detail.label === 'Appoinments' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            appointment: true
         }))
         
         if(rcomponent === null){
          rcomponent = 'appointments';
          setSelectedIndex(index)
         } 
        }
        if(detail.label === 'Prescriptions' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            prescription: true
         }))
        
         if(rcomponent === null){
          rcomponent = 'prescriptions';
          setSelectedIndex(index)
         }  
        }
        if(detail.label === 'Lab Reports' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            labReport: true
         }))
         
         if(rcomponent === null){
          rcomponent = 'diagonasticReports';
          setSelectedIndex(index)
         }  
        }
        if(detail.label === 'Medical Condition' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            medicalCondition: true
         }))
         if(rcomponent === null){
          rcomponent = 'medicalCondtions';
          setSelectedIndex(index)
         }   
        }
        if(detail.label === 'Vitals' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            vital: true
         }))
         if(rcomponent === null){
          rcomponent = 'vitals';
          setSelectedIndex(index)
         }   
        }
        if(detail.label === 'Allergies' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            allergy: true
         }))
         if(rcomponent === null){
          rcomponent = 'allergy';
          setSelectedIndex(index)
         }   
        }
        if(detail.label === 'Vault' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            vault: true
         }))
         if(rcomponent === null){
          rcomponent = 'vault';
          setSelectedIndex(index)
         }   
        }
        if(detail.label === 'Health Indicator' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            healthIndicator: true
         }))
         if(rcomponent === null){
          rcomponent = 'health';
          setSelectedIndex(index)
         }   
        }
        if(detail.label === 'Family Member' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            familyMember: true
         }))
         if(rcomponent === null){
          rcomponent = 'FamilyMember';
          setSelectedIndex(index)
         }   
        }
        if(detail.label === 'File Cabinet' && detail.checked === true){
          setStateData(prevState => ({
            ...prevState,
            fileCabinet: true
         }))
         if(rcomponent === null){
          rcomponent = 'filecabinet';
          setSelectedIndex(index)
         }   
        }
       
      })
     setDComponent(rcomponent)
    }
      
    const handleClick = () => {
      // 👇️ open file input box on click of other element
      inputRef.current.click();
    };
   
    const handleListItemClick = (event,index) => {
        setSelectedIndex(index);
    };
    const handleClosePrescriptionPopup = () => {
      setAddPrescription(false);
    };
    const handleCloseLabReportPopup = () => {
      setAddLabReport(false);
    };
    const handleCloseVitalPopup = () => {
      setAddVitals(false);
    };
    const handleCloseAllergyPopup = () => {
      setAddAllergy(false);
    };
    const handleCloseMCPopup = () => {
      setAddMC(false);
    };
    const handleCloseFileCabinetPopup = () => {
      setAddFile(false);
    };
    const handleCloseFileCabinetEditPopup = () => {
      setEditFile(false);
    };
    const handleCloseVitalDetailsPopup = () => {
      setShowVitals(false);
    };
    const handleCloseHealthDetailsPopup = () => {
      setShowHealthIndicator(false);
    };
    const handleClosePrescriptionDetailsPopup = () => {
      setShowPrescription(false);
    };
    const handleCloseAllergyDetailsPopup = () => {
      setShowAllergy(false);
    };
    const handleCloseMCDetailsPopup = () => {
      setShowMC(false);
    };
    const handleCloseFCDetailPopup = () => {
      setShowFCDetail(false);
    };
    const handleCloseLabDetailsPopup = () => {
      setShowLabReport(false);
    };
    const handleCloseVaultPopup = () => {
      setAddFilestoVault(false);
      // setFile('')
      // setIsFileSelected(false)
    };
    const handleSymptomsClosePopup = () => {
      setSymptomsOpenPopup(false);
    };
    const handleEditSymptomClosePopup = () => {
      setEditSymptom(false);
    };
    const handleCloseShareFilePopup = () => {
      setOpenShareFile(false);
    };

    const StyledBadge = styled(Badge)(({ theme }) => ({
      '& .MuiBadge-badge': {
        right: -3,
        top: 55,
        border: `2px solid white`,
        padding: '0 4px',
      },
    }));

    const handleFaceSheetClosePopup = () => {
      setFaceSheetOpenPopup(false);
    };

    console.log("user DATA",props.patientData)
    console.log("user login DATA",user)
    console.log("patient UUID",props.patientUUID)
      React.useEffect(() =>{
        renderState()
        // getPatientPersoanlDetails(props.patientUUID).then(res =>{
        //   console.log("PDATA",res)
        //   if(res.status === 200){
        //     setPatientData(res.data)
        //     setSelectedIndex(0)
        //     setDComponent('personal')
        //   }else{
        //     setSelectedIndex(0)
        //     setDComponent('personal')
        //   }
        // })
      },[])
    
    function PersonalDetails(){
      
      return(
      <Grid item xs={10} md={10} lg={10} marginBottom={4}>
        <Paper sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12} >
                <Typography color={colors.grey[500]} marginTop={1}>Basic Details</Typography>
                </Grid>
              </Grid>
                    
           </Paper>
          
          <Paper sx={{marginTop:2,padding:2}}>
                  <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>PERSONAL DETAILS</Typography>
              
              <Paper sx={{marginTop:2,padding:1}}>
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                      <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Name</Typography>
                      <Typography paddingLeft={2} color={colors.grey[900]}>{props.patientData.givenName} {props.patientData.lastName} </Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Email</Typography>
                              <Typography paddingLeft={2} color={colors.grey[900]}>{props.patientData.email}</Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Phone</Typography>
                              <Typography paddingLeft={2} color={colors.grey[900]}>{props.patientData.contactNumber}</Typography>
                          </Grid>
        </Grid>
                  </Paper>
                  
                  <Paper sx={{marginTop:4,padding:1}}>
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                      <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Gender</Typography>
                      <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.gendername}</Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Age</Typography>
                              <Typography paddingLeft={2} color={colors.grey[900]}>{props.patientData.age} years</Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Patient ID</Typography>
                              <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.hospitalNumber}</Typography>
                          </Grid>
        </Grid>
                  </Paper>
                  <Paper sx={{marginTop:4,padding:1}}>
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                      <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Blood Group</Typography>
                      <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.bloodGroup}</Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>DOB</Typography>
                              <Typography paddingLeft={2} color={colors.grey[900]}>{props.patientData.dob}</Typography>
                          </Grid>
        </Grid>
                  </Paper>
                  
                  <Paper sx={{marginTop:4,padding:1}}>
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={12}>
                      <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Address</Typography>
                      <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.address.address1}, {props.patientData.address.address2}, {props.patientData.address.city},
                      {props.patientData.address.state}, {props.patientData.address.postalCode}</Typography>
                          </Grid>                         
        </Grid>
                  </Paper>
            </Paper>
  
          <Paper sx={{marginTop:2,padding:2}}>
                  <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>EMERGENCY CONTACT</Typography>
                    
                    <Paper sx={{marginTop:2,padding:1}}>
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                      <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Name</Typography>
                      <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.emergencyContactDetailDto.familyContactName}</Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Relationship</Typography>
                              <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.emergencyContactDetailDto.familyRelation}</Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Mobile Number</Typography>
                              <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.emergencyContactDetailDto.familyContactNumber}</Typography>
                          </Grid>
        </Grid>
                      </Paper>
                      <Paper sx={{marginTop:4,padding:1}}>
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                      <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Doctor Name</Typography>
                      <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.emergencyContactDetailDto.doctorName}</Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Doctor Phone</Typography>
                              <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.emergencyContactDetailDto.doctorContactNumber}</Typography>
                          </Grid>
        </Grid>
                      </Paper>
            </Paper>
  
          <Paper sx={{marginTop:2,padding:2}}>
                        <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>ID</Typography>
                    
          <Paper sx={{marginTop:2,padding:1}}>
                  <Grid container spacing={3}>
                      <Grid item xs={12} md={4}>
                      <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>ID Type</Typography>
                      <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.idproofDto.type}</Typography>
                          </Grid>
                          <Grid item xs={12} md={4}>
                              <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>ID Number</Typography>
                              <Typography paddingLeft={4} color={colors.grey[900]}>{props.patientData.idproofDto.idNumber}</Typography>
                          </Grid>
                    </Grid>
                      </Paper>
            </Paper>
          </Grid>
      );
    }
    function Appointments(){
      
      console.log('DOB Month',new Date(defaultMonthYear).getFullYear())
      console.log('DOB Year',new Date(defaultMonthYear).getMonth())
      
        return(
        <Grid item xs={10} md={10} lg={10}>
            <Paper sx={{padding:1}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} >
                <Typography color={colors.grey[500]} marginTop={1}>UPCOMING APPOINTMENTS</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() =>{ 
                  store.setState("schedulerData",{
                    doctorName: user.name,
                    doctorUUID: user.uuid,
                    profileImage: user.profileImage,
                    speciality: user.profSpeciality
                  })
                  setComponent('NewAppointment')
                            }}>
                  New Appointment
                </Button>
                </Grid>
                
              </Grid>
                    
                </Paper>
                <Box sx={{maxHeight: '50vh', overflow:'auto'}}>
                {upcomingAppointmentList.length !== 0 ? upcomingAppointmentList.map((appointment,index) =>(
                <Paper sx={{marginTop:1,padding:2}} key={index}>
                <Grid container spacing={5} >
                <Grid item xs={12} md={0.5}>
                  <Box width={30} height={30} sx={{bgcolor:'#651fff'}}></Box>
                </Grid>
                <Grid item xs={12} md={2.5}>
                <Typography color={colors.grey[900]}>{appointment.doctorName}</Typography>
                <Typography color={colors.grey[700]} fontSize={12}>{appointment.degree} . {appointment.specialization}</Typography>
                </Grid>
                <Grid item xs={12} md={1.85}>
                <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} onClick={()=>{setFaceSheetOpenPopup(true)}}>
                  FaceSheet
                </Button>
                </Grid>
                <Grid item xs={12} md={1.85}>
                {appointment.status !== 'draft' ? <Button variant="outlined" sx={{marginRight:2,borderColor:'gray',color:'gray'}} onClick={()=>{
                      console.log("data apt.....",appointment.consultationId,appointment.doctorUuId)
                      store.setState('appointmentData',{
                        aptId: appointment.consultationId,
                        doctorUUID: appointment.doctorUuId,
                        patientID: patient.uuid
                      })
                      setComponent('Reschedule')
                      }}>
                          Reschedule
                        </Button> : <Button variant="outlined"><Typography fontSize={12}>Request to Complete</Typography></Button>}
                </Grid>
                <Grid item xs={12} md={1.85}>
                {appointment.status !== 'draft' ? <Button variant="outlined" color={'error'}onClick={() => {
                      confirmAlert({
                        title: "Are you Sure you want to Cancel the Appointment",
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                              cancelAppointment(appointment.consultationId).then(
                                res =>{
                                  console.log(res)
                                  toast(res.message)
                                  getUpcomingAppointmentList(patient.uuid).then(res =>{
                                    console.log("upcoming appointment list",res)
                                    if(res.status === 200){
                                      setUpcomingAppointmentList(res.data)
                                        for (let i = 0; i < res.data.length; i++) {
                                            showUpcomingApt.push(false);
                                        }
                                        console.log(showUpcomingApt);
                                    }else{
                                      setUpcomingAppointmentList([])
                                      console.log('error')
                                    }

                                  })
                                  
                                }
                              )
                            }
                          },
                          {
                            label: 'NO',
                            onClick: () => {
                              
                            }
                          },
                        ],
                        closeOnEscape:false,
                        closeOnClickOutside:false,
                      })
                    }}>Cancel</Button> : <Typography fontSize={12} color={'red'}>Draft</Typography>}
                </Grid>
                <Grid item xs={12} md={2.5}>
                <Typography
                              component="span"
                              variant="body2"
                              color="#8bc34a"
                              fontWeight={'bold'}
                            >
                              {appointment.consultationDate.slice(0,10)} | {appointment.consultationDate.slice(11,16)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={0.5}>
                <IconButton onClick={() =>{
                  console.log(showUpcomingApt);
                  if(showUpcomingApt[index] === false){
                    getDetailsByAptId(appointment.consultationId).then(res => {
                      if(res.status === 200){
                        let dispArr = []
                        for (let i = 0; i < upcomingAppointmentList.length; i++) {
                          dispArr.push(false);
                        }
                        console.log("INSIDE DETAILS CALL",res)
                        setAptData(res.data)
                        let newArr = [...dispArr]
                        newArr[index] = !showUpcomingApt[index];
                        setShowUpcomingApt(newArr)
                      }else{
                        toast(res.message)
                      }
                      
                    })
                  }else  if(showUpcomingApt[index] === true){
                    let newArr = [...showUpcomingApt]
                    newArr[index] = !showUpcomingApt[index];
                    setShowUpcomingApt(newArr)
                  }else{
                    toast.error("Could not fetch Consultation Details")
                  }
                }}>
                  {showUpcomingApt[index] === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                  </IconButton>
                </Grid>
                
              </Grid>
              {showUpcomingApt[index] === true ? <AppointmentDetailCard aptID = {appointment.consultationId}/> : <div></div>}
                    </Paper>)) :<Paper sx={{margin:5,padding:5}}>
                      <Typography align='center'>No Data Available</Typography>
                        </Paper>}
                </Box>
                
            <Paper sx={{marginTop:4,padding:1}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={11.25} >
                <Typography color={colors.grey[500]} marginTop={1}>PENDING APPOINTMENTS</Typography>
                </Grid>
                <Grid item xs={12} md={0.75}>
                <IconButton onClick={() =>{
                 if(showPending === false){
                  getPendingAppointmentList(patient.uuid).then(res =>{
                    console.log("pending appointment list",res)
                      
                    if(res.status === 200){
                      setPendingAppointmentList(res.data)
                        for (let i = 0; i < res.data.length; i++) {
                            showPendingApt.push(false);
                        }
                        console.log(showPendingApt);
                        setShowPending(!showPending)
                    }else{
                      setPendingAppointmentList([])
                      setShowPending(!showPending)
                      console.log('error')
                    }
                  })
                 }else{
                  setShowPending(false)
                  setShowPendingApt([])
                 }
                    
                  
                 
                }}>
                  {showPending === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                  </IconButton>
                </Grid>
              </Grid>
                    
                </Paper>
                {showPending === true ? 
                <Box sx={{maxHeight: '50vh', overflow:'auto'}}>
                {pendingAppointmentList.length !== 0 ? pendingAppointmentList.map((appointment,index) =>(
                <Paper sx={{marginTop:1,padding:2}} key={index}>
                <Grid container spacing={5} >
                <Grid item xs={12} md={0.5}>
                  <Box width={30} height={30} sx={{bgcolor:'#651fff'}}></Box>
                </Grid>
                <Grid item xs={12} md={2.5}>
                <Typography color={colors.grey[900]}>{appointment.doctorName}</Typography>
                <Typography color={colors.grey[700]} fontSize={12}>{appointment.degree} . {appointment.specialization}</Typography>
                </Grid>
                <Grid item xs={12} md={1.85}>
                <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} onClick={()=>{setFaceSheetOpenPopup(true)}}>
                  FaceSheet
                </Button>
                </Grid>
                <Grid item xs={12} md={1.85}>
                {appointment.status !== 'draft' ? <Button variant="outlined" sx={{marginRight:2,borderColor:'gray',color:'gray'}} onClick={()=>{
                      console.log("data apt.....",appointment.consultationId,appointment.doctorUuId)
                      store.setState('appointmentData',{
                        aptId: appointment.consultationId,
                        doctorUUID: appointment.doctorUuId,
                        patientID: patient.uuid
                      })
                      setComponent('Reschedule')
                      }}>
                          Reschedule
                        </Button> :<Button variant="outlined"><Typography fontSize={12}>Request to Complete</Typography></Button>}
                </Grid>
                <Grid item xs={12} md={1.85}>
                {appointment.status !== 'draft' ?<Button variant="outlined" color={'error'}onClick={() => {
                      confirmAlert({
                        title: "Are you Sure you want to Cancel the Appointment",
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                              cancelAppointment(appointment.consultationId).then(
                                res =>{
                                  console.log(res)
                                  toast(res.message)
                                  getPendingAppointmentList(patient.uuid).then(res =>{
                                    console.log("pending appointment list",res)
                                    if(res.status === 200){
                                      setPendingAppointmentList(res.data)
                                        for (let i = 0; i < res.data.length; i++) {
                                            showPendingApt.push(false);
                                        }
                                        console.log(showPendingApt);
                                        setShowPending(!showPending)
                                    }else{
                                      setPendingAppointmentList([])
                                      console.log('error')
                                    }
                                  })
                                  
                                }
                              )
                            }
                          },
                          {
                            label: 'NO',
                            onClick: () => {
                              
                            }
                          },
                        ],
                        closeOnEscape:false,
                        closeOnClickOutside:false,
                      })
                    }}>Cancel</Button> : <Typography fontSize={16} color={'red'}>Draft</Typography>}
                </Grid>
                <Grid item xs={12} md={2.5}>
                <Typography
                              component="span"
                              variant="body2"
                              color="#8bc34a"
                              fontWeight={'bold'}
                            >
                              {appointment.consultationDate.slice(0,10)} | {appointment.consultationDate.slice(11,16)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={0.5}>

                  
                <IconButton onClick={() =>{
                  console.log(showPendingApt);
                 console.log(showPendingApt[index]);
                 if(showPendingApt[index] === false){
                   getDetailsByAptId(appointment.consultationId).then(res => {
                     if(res.status === 200){
                       let dispArr = []
                       for (let i = 0; i < pendingAppointmentList.length; i++) {
                         dispArr.push(false);
                       }
                       console.log("INSIDE DETAILS CALL",res)
                       setAptData(res.data)
                       let newArr = [...dispArr]
                       newArr[index] = !showPendingApt[index];
                       setShowPendingApt(newArr)
                     }else{
                       toast(res.message)
                     }
                     
                   })
                 }else if(showPendingApt[index] === true){
                   let newArr = [...showPendingApt]
                   newArr[index] = !showPendingApt[index];
                   setShowPendingApt(newArr)
                   
                 }else{
                  toast.error("Could not Fetch Consultation Details")
                 }
                }}>
                  {showPendingApt[index] === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                  </IconButton>
                </Grid>
                
              </Grid>
              {showPendingApt[index] === true ? <AppointmentDetailCard aptID = {appointment.consultationId}/> : <div></div>}
                    </Paper>)) :<Paper sx={{margin:5,padding:5}}>
                      <Typography align='center'>No Data Available</Typography>
                        </Paper>}
                </Box>:<div></div>}
                
                    
                    
            <Paper sx={{marginTop:4,padding:1.5}}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8.25}>
                <Typography color={colors.grey[500]} marginTop={1}>PAST APPOINTMENTS</Typography>
                </Grid>
                <Grid item xs={12} md={3} padding={1}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  
                    <DatePicker 
                    label={'Select Month and Year'}
                    value={defaultMonthYear} 
                    views={['month', 'year']}
                    onChange={handleDateChange}
                      renderInput={(params) => <TextField size='small' fullWidth {...params} />}
                    />
                  
              </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={0.75}>

                <IconButton onClick={() =>{
                  if(showPast === false){
                    getPastAppointmentList(patient.uuid,
                      format(getFirstDayOfMonth(new Date(defaultMonthYear).getFullYear(),new Date(defaultMonthYear).getMonth())),
                      format(getLastDayOfMonth(new Date(defaultMonthYear).getFullYear(),new Date(defaultMonthYear).getMonth()))).then(res => {
                        console.log("past appointment list",res)
                        
                        if(res.status === 200){
                          setPastAppointmentList(res.data)
                          for (let i = 0; i < res.data.length; i++) {
                            showPastApt.push(false);
                        }
                        console.log(showPastApt);
                        setShowPast(!showPast)
                        }else{
                          setPastAppointmentList([])
                          setShowPast(!showPast)
                          console.log('error')
                        }
                      })
                  }else{
                    setShowPast(false)
                    setShowPastApt([])
                  }
                  
                 
                }}>
                  {showPast === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                  </IconButton>
                </Grid>
              </Grid>
                    
                </Paper>
                {showPast === true ? 
                <Box sx={{maxHeight: '50vh', overflow:'auto'}}>
                {pastAppointmentList.length !== 0 ? pastAppointmentList.map((appointment,index) =>(
                <Paper sx={{marginTop:1,padding:2}} key={index}>
                <Grid container spacing={5} >
                <Grid item xs={12} md={0.5}>
                  <Box width={30} height={30} sx={{bgcolor:'#651fff'}}></Box>
                </Grid>
                <Grid item xs={12} md={6.35}>
                <Typography color={colors.grey[900]}>{appointment.doctorName}</Typography>
                <Typography color={colors.grey[700]} fontSize={12}>{appointment.degree} . {appointment.specialization}</Typography>
                </Grid>
                <Grid item xs={12} md={1.85}>
                <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} onClick={()=>{setFaceSheetOpenPopup(true)}}>
                  FaceSheet
                </Button>
                </Grid>
                <Grid item xs={12} md={2.5}>
                <Typography
                              component="span"
                              variant="body2"
                              color="#8bc34a"
                              fontWeight={'bold'}
                            >
                              {appointment.consultationDate.slice(0,10)} | {appointment.consultationDate.slice(11,19)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={0.5}>

               

                <IconButton onClick={() =>{
                  console.log(showPastApt);
                console.log(showPastApt[index]);
                  if(showPastApt[index] === false){
                    getDetailsByAptId(appointment.consultationId).then(res => {
                      if(res.status === 200){
                        let dispArr = []
                        for (let i = 0; i < pastAppointmentList.length; i++) {
                          dispArr.push(false);
                        }
                        console.log("INSIDE DETAILS CALL",res)
                        setAptData(res.data)
                        let newArr = [...dispArr]
                        newArr[index] = !showPastApt[index];
                        setShowPastApt(newArr)
                      }else{
                        toast(res.message)
                      }
                      
                    })
                  }else if(showPastApt[index] === true){
                    let newArr = [...showPastApt]
                    newArr[index] = !showPastApt[index];
                    setShowPastApt(newArr)
                    
                  }else{
                    toast.error("Could not Fetch Consultation Details")
                  }
                   
                }}>
                  {showPastApt[index] === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                  </IconButton>
                </Grid>
                
              </Grid>
              {showPastApt[index] === true ? <AppointmentDetailCard aptID = {appointment.consultationId}/> : <div></div>}
                    </Paper>)):<Paper sx={{margin:5,padding:5}}>
                      <Typography align='center'>No Data Available</Typography>
                        </Paper>}
                </Box>:<div></div>}
                
                    
            </Grid>
        );
      }
    function MedicalConditions(){
      
        return(
          <Grid item xs={10} md={10} lg={10}>
              <Paper sx={{padding:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8.5} >
                  <Typography color={colors.grey[500]} marginTop={1}>Medical Conditions</Typography>
                  </Grid>
                  <Grid item xs={12} md={3.5}>
                  <Button variant="contained" startIcon={<AddIcon />} onClick={() => {setAddMC(true)}}>
                    Add Medicial Conditions
                  </Button>
                  </Grid>
                  
                </Grid>
                      
                  </Paper>
                  <Box sx={{maxHeight:'70vh', overflow:'auto'}}>
              {mcListData.length !== 0 ? mcListData.map((mcData,index) =>(
                    <Paper sx={{marginTop:1,padding:2}} key={index}>
                  <Grid container spacing={3} >
                  <Grid item xs={12} md={0.5}>
                    <Avatar src={diagonsis_file} ></Avatar>
                  </Grid>
                  <Grid item xs={12} md={8}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>{mcData.medicalcondition}</Typography>
                  
                  </Grid>
                  <Grid item xs={12} md={3.5}>
                  <Typography
                                component="span"
                                variant="body2"
                                color={colors.grey[500]}
                                fontWeight={'bold'}
                              >
                                {mcData.startdate.slice(0,10)}
                    </Typography>
                  </Grid>
                  
                </Grid>
                <Grid container spacing={5} >
                <Grid item xs={12} md={2.5}>
                  <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Source</Typography>
                          <Typography paddingLeft={4} color={colors.grey[900]}>{mcData.source}</Typography>                
                  </Grid>
                  <Grid item xs={12} md={3.5}>
                  <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Doctor Name</Typography>
                  <Typography paddingLeft={4} color={colors.grey[900]}>Dr. {mcData.doctorname}</Typography>                
                  </Grid>
                  <Grid item xs={12} md={4.5}>
                  </Grid>
                  <Grid item xs={12} md={1.5}>
                  <Button variant="contained" onClick={() =>{getMedicialConditionDetails(mcData.id).then(res =>{
                    console.log(res)
                    if(res.status === 200){
                      setMCDetails(res.data)
                      setShowMC(true)
                    }else{
                      setMCDetails([])
                      setShowMC(false)
                    }
                  })}}>
                    View
                  </Button>
                  </Grid>
                  
                  
                  
                </Grid>
                      </Paper>
                  )):<Paper sx={{margin:5,padding:5}}>
                  <Typography align='center'>No Data Available</Typography>
                    </Paper>}
                  </Box>
                  
                      
                      
          </Grid>
        );
      }

    function AppointmentDetailCard(){
    
      return(
        <div>
          
            <Paper sx={{marginTop:4}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                        <Typography paddingLeft={4} color={colors.grey[500]}>Patients Name</Typography>
                        <Typography paddingLeft={4} color={colors.grey[900]}>{aptData.name}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography paddingLeft={4} color={colors.grey[500]}>Consultation Type</Typography>
                                <Typography paddingLeft={4} color={colors.grey[900]}>{aptData.appointmentType.label}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography paddingLeft={4} color={colors.grey[500]}>Purpose</Typography>
                                <Typography paddingLeft={4} color={colors.grey[900]} fontSize={14}>{aptData.reasonForConsultation}</Typography>
                            </Grid>
                      </Grid>
                      <Grid container spacing={3} paddingTop={2}>
                        <Grid item xs={12} md={3}>
                        <Typography paddingLeft={4} color={colors.grey[500]}>Symptoms</Typography>
                        <Typography paddingLeft={4} color={colors.grey[900]}>{aptData.symptoms}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography paddingLeft={4} color={colors.grey[500]}>Payment Status</Typography>
                                <Typography paddingLeft={4} color={colors.red[900]}>{aptData.feesPaid}</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Typography paddingLeft={4} color={colors.grey[500]}>Clinical Notes Status</Typography>
                                <Typography paddingLeft={4} color={colors.red[900]}>{aptData.consultationStatus}</Typography>
                            </Grid>
                            
                      </Grid>
                      <Grid container spacing={3} paddingTop={4}>
                        <Grid item xs={12} md={6} marginLeft={4} padding={2}>
                        {aptData.consultationStatus === 'completed' ?
                        <Button variant="outlined" endIcon={<DownloadIcon/>} onClick={()=>{
                          {aptData.file ? fileDownload(aptData.file) : toast.error("No File Available to Download")}
                        }}>
                          <Typography>{aptData.file.slice(80) || 'NA'}</Typography>
                        </Button> : <div></div>}
                            </Grid>
                      </Grid> 
                    </Paper>
          
          
        </div>
      );
    }
    

    function Precriptions(){

      return(
        <Grid item xs={10} md={10} lg={10}>
            <Paper sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} >
                <Typography color={colors.grey[500]} marginTop={1}>PRESCRIPTIONS</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() =>{ 
                              setAddPrescription(true);
                            }}>
                  New Prescription
                </Button>
                </Grid>
                
              </Grid>
                    
                </Paper>
              <Grid paddingBottom={4}>
                <Box sx={{maxHeight:'70vh',overflow:'auto'}}>
                {prescriptionListData.length !== 0 ? prescriptionListData.map((medicine,index) =>(
                  <Paper sx={{marginTop:1,padding:2}} key={index}>
                <Grid container spacing={3} >
                <Grid item xs={12} md={0.5}>
                  <Avatar src={prescription_img} ></Avatar>
                </Grid>
                <Grid item xs={12} md={10}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}> Prescription  - {medicine.createdOn.slice(0,10)}</Typography>
                
                </Grid>
                <Grid item xs={12} md={1.5}>
                <Typography
                              component="span"
                              variant="body2"
                              color={colors.grey[500]}
                              fontWeight={'bold'}
                            >
                              {medicine.createdOn.slice(0,10)}
                  </Typography>
                </Grid>
                
              </Grid>
              <Grid container spacing={5} >
                <Grid item xs={12} md={3}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Doctor</Typography>
                <Typography paddingLeft={4} color={colors.grey[900]}>Dr. {medicine.doctorname}</Typography>                </Grid>
                <Grid item xs={12} md={3}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Source</Typography>
                        <Typography paddingLeft={4} color={colors.grey[900]}>{medicine.source}</Typography>                
                </Grid>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={1.5}>
                <Button variant="outlined" onClick={()=>{
                  fileDownload(medicine.filename)
                }} disabled={medicine.filename === '' || medicine.filename === null || medicine.filename === undefined || medicine.filename === 'None' ? true : false}>
                  Download
                </Button>
                </Grid>
                <Grid item xs={12} md={1.5}>
                <Button variant="contained" onClick={() =>{
                  getPrescriptionDetails(medicine.prescriptionid).then(res =>{
                    console.log(res)
                    if(res.status === 200){
                      setPrescriptionDetails(res.data)
                      setShowPrescription(true)
                    }else{
                      console.log("error")
                      setPrescriptionDetails([])
                      setShowPrescription(false)
                    }
                  })
                }}>
                  View
                </Button>
                </Grid>
                
                
                
              </Grid>
                    </Paper>
                )):<Paper sx={{margin:5,padding:5}}>
                <Typography align='center'>No Data Available</Typography>
                  </Paper>}
                </Box>
              
              </Grid>
                
                
                    
        </Grid>
      );
    }
    function DiagnosticReports(){
      
      return(
        <Grid item xs={10} md={10} lg={10}>
            <Paper sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9.5} >
                <Typography color={colors.grey[500]} marginTop={1}>Lab Reports</Typography>
                </Grid>
                <Grid item xs={12} md={2.5}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() =>{
                  setAddLabReport(true)
                }}>
                  New Report
                </Button>
                </Grid>
                
              </Grid>
                    
                </Paper>
                <Grid paddingBottom={4}>
                  <Box sx={{maxHeight: '70vh', overflow:'auto'}}>
                  {labReportListData.length !== 0 ? labReportListData.map((lab,index) =>(
                  <Paper sx={{marginTop:1,padding:2}} key={index}>
                <Grid container spacing={3} >
                <Grid item xs={12} md={0.5}>
                  <Avatar src={diagonsis_file} ></Avatar>
                </Grid>
                <Grid item xs={12} md={10}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>{lab.name}</Typography>
                
                </Grid>
                <Grid item xs={12} md={1.5}>
                <Typography
                              component="span"
                              variant="body2"
                              color={colors.grey[500]}
                              fontWeight={'bold'}
                            >
                              {lab.createdOn.slice(0,10)}
                  </Typography>
                </Grid>
                
              </Grid>
              <Grid container spacing={5} >
              <Grid item xs={12} md={3.5}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Lab Test Type</Typography>
                <Typography paddingLeft={4} color={colors.grey[900]}>{lab.test_type}</Typography>                
                </Grid>
                <Grid item xs={12} md={3.5}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Prescibed By</Typography>
                <Typography paddingLeft={4} color={colors.grey[900]}>Dr. {lab.doctorname}</Typography>                
                </Grid>
                <Grid item xs={12} md={3.5}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Source</Typography>
                        <Typography paddingLeft={4} color={colors.grey[900]}>{user.userType}</Typography>                
                </Grid>
                <Grid item xs={12} md={1.5}>
                <Button variant="contained" onClick={() =>{
                  getLabReportDetails(lab.id).then(res =>{
                    console.log(res)
                    if(res.status === 200){
                      setLabDetails(res.data)
                      setShowLabReport(true)
                    }else{
                      setLabDetails([])
                      setShowLabReport(true)
                    }
                  })
                }}>
                  View
                </Button>
                </Grid>
                
                
                
              </Grid>
                    </Paper>
                )):<Paper sx={{margin:5,padding:5}}>
                <Typography align='center'>No Data Available</Typography>
                  </Paper>}
                  </Box>
                
                </Grid>
                
                
                    
                    
        </Grid>
      );
    }

    function AddLabReportPopUp(){
      const [testType, setTestType] = React.useState('');
      const [testName, setTestName] = React.useState('');
      const [testCode, setTestCode] = React.useState('');
      const [labTestListData,setLabTestListData] = React.useState([]);
      const [testNameList,setTestNameList] = React.useState([]);
      const [testTypeList,setTestTypeList] = React.useState([]);
      const [radioValue, setRadioValue] = React.useState(0);
      const [doctorName, setDoctorName] = React.useState('');
      const [doctor_UUID, setDoctor_UUID] = React.useState('');
      const [doctorList, setDoctorList] = React.useState([]);
      const [changeDataSource, setChangeDataSource] = React.useState(false);
      const [testDate, setTestDate] = React.useState('');
      const [filePath,setFilePath] = React.useState('');
      const [uploadedFilePath,setUploadedFilePath] = React.useState('');
      const [file,setFile ] = React.useState('');
      const [fileName,setFileName ] = React.useState('Please Upload a file');
      const [notes, setNotes] = React.useState('');

      const handleLabReportFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    
        console.log('fileObj is', fileObj);
    
        // 👇️ reset file input
        event.target.value = null;
    
        // 👇️ is now empty
        console.log(event.target.files);
    
        // 👇️ can still access file object here
        console.log(fileObj);
        console.log(fileObj.name);
        if(fileObj.type === 'image/png' || fileObj.type === 'image/jpg' 
        || fileObj.type === 'image/jpeg' || fileObj.type === 'application/pdf'){
          fileUpload(fileObj,filePath).then(res =>{
            console.log(res)
            if(res.status === 200){
              setUploadedFilePath(res.path)
              setFile(fileObj)
              setFileName(fileObj.name)
            }else{
              setFile('')
              setFileName('Please Upload a file')
            }
          }
          )
        }else{
          alert("Invalid File Type !! Accepted Files: PNG,JPG,JPEG,PDF")
          setFile('')
          setFileName('Please Upload a file')
        }
        
        
      };
      console.log("LTData",labTestListData)

      const [errorText,setErrorText] = React.useState({
        doctorId:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        testType:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        testName:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        testDate:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        file:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        notes:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
      })
  
      function handleValidation(){
        if(doctor_UUID === '' || doctor_UUID !==''){
          if(doctor_UUID === ''){
            setErrorText(previous => ({...previous,doctorId:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(doctor_UUID !== ''){
            setErrorText(previous => ({...previous,doctorId:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }
        if(testDate === "" || testDate !== ""){
          if(testDate === ""){
            setErrorText(previous => ({...previous,testDate:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(testDate !== ""){
            setErrorText(previous => ({...previous,testDate:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
          } 
        }
        if(testName === '' || testName !==''){
          if(testName === ''){
            setErrorText(previous => ({...previous,testName:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(testName !== ''){
            setErrorText(previous => ({...previous,testName:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }
      if(testType === '' || testType !==''){
          if(testType === ''){
            setErrorText(previous => ({...previous,testType:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(testType !== ''){
            setErrorText(previous => ({...previous,testType:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }
        if(filePath === "" || filePath !== ""){
          if(filePath === ""){
            setErrorText(previous => ({...previous,file:{error:true,
              errorText:"Please Upload a file",
              verified:false}}))
              return true;
              
          }else if(filePath !== ""){
            setErrorText(previous => ({...previous,file:{error:false,
              errorText:"Please Upload a file",
              verified:true}}))
              
          }       
      }

        if(notes === "" || notes !== ""){
          if(notes === ""){
            setErrorText(previous => ({...previous,notes:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(notes !== ""){
            setErrorText(previous => ({...previous,notes:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              return false
          }       
      }
      
      console.log("RESULTS............");
      
      }

      const handleTestDateChange = (newValue) => {
        setTestDate(newValue);
      };

      const handlRadioChange = (event) => {
        setRadioValue(event.target.value);
      };
      
    React.useEffect(() => {
      getDoctorsList(user.orgID,'a').then(res =>{
        console.log(res)
        if(res.status === 200){
          setDoctorList(res.data.content)
        }
      })
      MasterDataEncounterSearch('a','TEST').then(res =>{
        console.log(res)
        if(res.status === 200){
          setTestNameList(res.data.content)
        }else{
          setTestNameList([])
        }
        
      })
      MasterDataEncounterSearch('a','TEST_TYPE').then(res =>{
        console.log(res)
        if(res.status === 200){
          setTestTypeList(res.data.content)
        }else{
          setTestTypeList([])
        }
        
      })
    },[])

      return (
      <div>
        <input
                style={{display: 'none'}}
                ref={inputRef}
                type="file"
                onChange={handleLabReportFileChange}
          />
        <Dialog open={addLabReport} onClose={handleCloseLabReportPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add Lab Record</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseLabReportPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
          <Grid item xs={12} sm={6} padding={2}>
            <Typography fontSize={16} fontWeight={'bold'}>Doctor Name</Typography>
                  <FormControl fullWidth>
                        <Autocomplete
                          onChange = {(event, newValue) => {if(newValue && newValue.name){
                              setDoctorName(newValue.name)
                              setDoctor_UUID(newValue.uuId)
                          }}}
                          size="small"
                          disablePortal
                          id="combo-box-demo"
                          options={doctorList}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => <TextField {...params} label="Choose Doctor" 
                          helperText={errorText.doctorId.error === true ? errorText.doctorId.errorText:""}
                          error={errorText.doctorId.error === true ? true : false}
                          autoFocus={errorText.doctorId.error === true ? true : false}
                          />}
                        />
                    </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} padding={2}>
        <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Select Data Source</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={radioValue}
        onChange={handlRadioChange}
      >
        <FormControlLabel value={0} control={<Radio 
          onChange={()=>{setRadioValue(0)
          setTestNameList([])
          setChangeDataSource(false)
          }}
        />} label="Internal" />
        <FormControlLabel value={1} control={<Radio 
          onChange={()=>{setRadioValue(1)
            setTestNameList([])
          setChangeDataSource(true)
          }}
        />} label="SnowMed" />
        
      </RadioGroup>
    </FormControl>
    </Grid>
    
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Test Date</Typography>
                  <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Select test Date"
                      inputFormat="MM/dd/yyyy"
                      value={testDate}
                      onChange={handleTestDateChange}
                      renderInput={(params) => <TextField {...params} size="small"
                      helperText={errorText.testDate.error === true ? errorText.testDate.errorText:""}
                      error={errorText.testDate.error === true ? true : false}
                      autoFocus={errorText.testDate.error === true ? true : false}
                      />}
                    />
                </LocalizationProvider>
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Test Name</Typography>
                {changeDataSource === false ? 
                <FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.typeID) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
                      setTestNameList([])
                      MasterDataEncounterSearch(event.target.value,'TEST').then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setTestNameList(res.data.content)
                        }else{
                          setTestNameList([])
                        }
                        
                      })
                      
                      setTestName(newValue.label)
                      setTestCode(newValue.typeID)
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.typeID){
                      console.log("seletced val.......",newValue.label)
                      setTestName(newValue.label)
                      setTestCode(newValue.typeID)
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={testNameList}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.typeID}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Search Test Name"
                      helperText={errorText.testName.error === true ? errorText.testName.errorText:""}
                      error={errorText.testName.error === true ? true : false}
                      autoFocus={errorText.testName.error === true ? true : false}
                    />}
                  />
                    </FormControl>
                :<FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
                      setTestNameList([])
                      getLabTestNameList(event.target.value).then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setTestNameList(res.data)
                        }else{
                          setTestNameList([])
                        }
                        
                      })
                      
                      setTestName(newValue.term)
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.id){
                      console.log("seletced val.......",newValue.term)
                      setTestName(newValue.term)
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={testNameList}
                    getOptionLabel={(option) => option.term}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.term}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Search Test Name"
                    helperText={errorText.testName.error === true ? errorText.testName.errorText:""}
                    error={errorText.testName.error === true ? true : false}
                    autoFocus={errorText.testName.error === true ? true : false}
                    />}
                  />
                    </FormControl>}
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'}>Test Type</Typography>          
                <FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.typeID) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
                      setTestTypeList([])
                      MasterDataEncounterSearch(event.target.value,'TEST_TYPE').then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setTestTypeList(res.data.content)
                        }else{
                          setTestTypeList([])
                        }
                        
                      })
                      
                      setTestType(newValue.label)
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.typeID){
                      console.log("seletced val.......",newValue.label)
                      setTestType(newValue.label)
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={testTypeList}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.typeID}>
                          {option.label}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Search Test Type"
                      helperText={errorText.testType.error === true ? errorText.testType.errorText:""}
                      error={errorText.testType.error === true ? true : false}
                      autoFocus={errorText.testType.error === true ? true : false}
                    />}
                  />
                    </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'}>Choose Files</Typography>
                  <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',fontSize:'12px',maxWidth:'100%'}} onClick={()=>{
                  setFilePath(`LAB_REPORT_${patient.uuid}`)
                  handleClick()
                }}>
                  Upload
                  </Button>
                  <Typography fontSize={12} fontWeight={'bold'} paddingTop={2}>Uploaded File Name: {fileName}</Typography>
                  {errorText.file.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.file.errorText}</Typography> : <div></div>}
              </Grid>
              <Grid item xs={12} sm={12} md={12} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Notes</Typography>
                <TextField
                  required
                  fullWidth
                  value={notes}
                  id="spo2"
                  label="Enter Notes"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setNotes(e.target.value)}}
                  multiline
                  rows={4}
                  helperText={errorText.notes.error === true ? errorText.notes.errorText:""}
                  error={errorText.notes.error === true ? true : false}
                  autoFocus={errorText.notes.error === true ? true : false}
                />
              </Grid>
              
        </Grid>
          </DialogContent>
          <DialogActions>
            
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={()=>{
              let verify = handleValidation();
              if(verify === false){
                createLabReport(user.orgID,patient.uuid,patient.name,doctorName,doctor_UUID,
                  testName,uploadedFilePath,notes,testDate,testCode,testType).then(res =>{
                    console.log(res)
                    if(res.status === 200){
                      toast.success(res.message)
                      getLabReportList(patient.uuid).then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setLabReportListData(res.data)
                        }else{
                          console.log("error")
                          setLabReportListData([])
                        }
                      })
                      handleCloseLabReportPopup()
                    }
                  })
              }else{
                toast.error("Please check the Input fields")
              }
              
            }}>
                      Add Lab Report
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }

    function Vitals(){
 
      return(
        <Grid item xs={10} md={10} lg={10}>
            <Paper sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={10} >
                <Typography color={colors.grey[500]} marginTop={1}>Vitals</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() => {setAddVitals(true)}} sx={{bgcolor:'#696CFF'}}>
                  Add Vitals
                </Button>
                </Grid>
                
              </Grid>
                    
                </Paper>
            <VitalListComponent/>    
        </Grid>
      );
    }
    function Allergy(){
      
      return(
        <Grid item xs={10} md={10} lg={10}>
            <Paper sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9.5} >
                <Typography color={colors.grey[500]} marginTop={1}>Allergies</Typography>
                </Grid>
                <Grid item xs={12} md={2.5}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() =>{setAddAllergy(true)}}>
                  Add Allergy
                </Button>
                </Grid>
                
              </Grid>
                    
                </Paper>
                <Box sx={{maxHeight:'70vh',overflow:'auto'}}></Box>
               {allergyListData.length !== 0 ? allergyListData.map((allergy,index) =>(
                <Paper sx={{marginTop:1,padding:2}} key={index}>
                    <Grid container spacing={3} >
                    <Grid item xs={12} md={0.5}>
                      <Avatar src={diagonsis_file} ></Avatar>
                    </Grid>
                    <Grid item xs={12} md={8}>
                    <Typography color={colors.grey[900]} fontWeight={'bold'}>{allergy.alleryinfo}</Typography>
                    
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                    <Typography
                                  component="span"
                                  variant="body2"
                                  color={colors.grey[500]}
                                  fontWeight={'bold'}
                                >
                                  {allergy.startdate.slice(0,10)}
                      </Typography>
                    </Grid>
                    
                      </Grid>
                    <Grid container spacing={5} >
                  <Grid item xs={12} md={2.5}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Severity</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{allergy.severty}</Typography>                
                    </Grid>
                    <Grid item xs={12} md={2.5}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Status</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{allergy.allergystatus}</Typography>                
                    </Grid>
                    <Grid item xs={12} md={5.5}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Source</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>Dr. {allergy.treatedby}</Typography>                
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                    <Button variant="contained" onClick={()=>{getAllergyDetails(allergy.id).then(res =>{
                      console.log(res)
                      if(res.status === 200){
                        setAllergyDetails(res.data)
                        setShowAllergy(true)
                      }else{
                        setAllergyDetails([])
                      setShowAllergy(false)
                      }
                      
                    })}}>
                      View
                    </Button>
                    </Grid>
                    
                    
                    
                      </Grid>
                  </Paper>
               )):<Paper sx={{margin:5,padding:5}}>
               <Typography align='center'>No Data Available</Typography>
                 </Paper>} 
                    
        </Grid>
      );
    }
    function HealthIndicator(){
      
      return(
        <Grid item xs={10} md={10} lg={10}>
            <Paper sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9.5} >
                <Typography color={colors.grey[500]} marginTop={1}>Health Indicator</Typography>
                </Grid>
                <Grid item xs={12} md={2.5}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() =>{setSymptomsOpenPopup(true)}}>
                  Add New
                </Button>
                </Grid>
                
              </Grid>
                    
                </Paper>
                <Paper sx={{padding:2,marginTop:2}}>
                <HealthIndicatorListComponent/>
                </Paper>
                
                    
        </Grid>
      );
    }
    function Vault(){
      const [component1, setComponent1] = React.useState(false);
      const handleClick1 = event => {
        // 👇️ toggle shown state
        setComponent1(current => !current);
      };
      return(
        <Grid item xs={10} md={10} lg={10}>
            <Paper sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={10} >
                <Typography color={colors.grey[500]} marginTop={1}>Vault</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={()=>{setAddFilestoVault(true)}}>
                  Upload
                </Button>
                </Grid>
                
              </Grid>
                    
                </Paper>
                {vaultListData.map((file,index) =>(
                  <Paper sx={{marginTop:1,padding:2}}>
                <Grid container spacing={3} >
                <Grid item xs={12} md={0.5}>
                  <Avatar src={prescription_img} ></Avatar>
                </Grid>
                <Grid item xs={12} md={10}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>{file.fileDisplayName}</Typography>
                
                </Grid>
                <Grid item xs={12} md={1.5}>
                <Typography
                              component="span"
                              variant="body2"
                              color={colors.grey[500]}
                              fontWeight={'bold'}
                            >
                              12th July 2021
                  </Typography>
                </Grid>
                
              </Grid>
              <Grid container spacing={5} >
                <Grid item xs={12} md={3}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Document Type</Typography>
                <Typography paddingLeft={4} color={colors.grey[900]}>{file.fileType.label}</Typography>                </Grid>
                <Grid item xs={12} md={3}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Source</Typography>
                        <Typography paddingLeft={4} color={colors.grey[900]}>Ubiqare</Typography>                
                </Grid>
                <Grid item xs={12} md={3}></Grid>
                <Grid item xs={12} md={1.5}>
                <Button variant="outlined" onClick={()=>{
                  fileDownload(file.filePath)
                }}>
                  Download
                </Button>
                </Grid>
                <Grid item xs={12} md={1.5}>
                <Button variant="contained">
                  View
                </Button>
                </Grid>
              </Grid>
                    </Paper>
                ))}
                
                   
        </Grid>
      );
    }
    function AddPrescription(){
      
        const [drug, setDrug] = React.useState('');
        const [quantity, setQuantity] = React.useState('');
        const [dispenseForm, setDispenseForm] = React.useState('');
        const [pharmaName, setPharmaName] = React.useState('');
        const [prescriptionMedicineListData,setPrescriptionMedicineListData] = React.useState([]);
        const [drugList,setDrugList] = React.useState([])
        const [dispenseFormList,setDispenseFormList] = React.useState([])
        const [pharmacyList,setPharmacyList] = React.useState([])
        const [radioValue, setRadioValue] = React.useState(0)
        const [changeDataSource, setChangeDataSource] = React.useState(false)
        const [medicalCondition, setMedicalCondition] = React.useState('');
         const [medicalCList, setMedicalCList] = React.useState([]);
        const [doctorName, setDoctorName] = React.useState('');
        const [doctor_UUID, setDoctor_UUID] = React.useState('');
        const [doctorList, setDoctorList] = React.useState([]);
        const [notes, setNotes] = React.useState('');
        const [medNotes, setMedNotes] = React.useState('');
        const [filePath,setFilePath] = React.useState('');
        const [uploadedFilePath,setUploadedFilePath] = React.useState('');
        const [file,setFile ] = React.useState('');
        const [fileName,setFileName ] = React.useState('Please Upload a file');

      const handlePrescriptionFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    
        console.log('fileObj is', fileObj);
    
        // 👇️ reset file input
        event.target.value = null;
    
        // 👇️ is now empty
        console.log(event.target.files);
    
        // 👇️ can still access file object here
        console.log(fileObj);
        console.log(fileObj.name);
        if(fileObj.type === 'image/png' || fileObj.type === 'image/jpg' 
        || fileObj.type === 'image/jpeg' || fileObj.type === 'application/pdf'){
          fileUpload(fileObj,filePath).then(res =>{
            console.log(res)
            if(res.status === 200){
              setUploadedFilePath(res.path)
              setFile(fileObj)
              setFileName(fileObj.name)
            }else{
              setFile('')
              setFileName('Please Upload a file')
            }
          }
          )
        }else{
          alert("Invalid File Type !! Accepted Files: PNG,JPG,JPEG,PDF")
          setFile('')
          setFileName('Please Upload a file')
        }
        
        
      };
        console.log("psData",prescriptionListData)
  
        const [errorText,setErrorText] = React.useState({
          doctorName:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          },
          medicalCondition:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          },
          file:{
            error:false,
            errorText:"Please Upload Prescription File",
            verified:true
          },
          notes:{
            error:false,
            errorText:"Field cant be empty",
            verified:true
          },
          drug:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          },
          quantity:{
            error:false,
            errorText:"Field cant be empty",
            verified:true
          },
          medNotes:{
            error:false,
            errorText:"Field cant be empty",
            verified:true
          },
          dispenseForm:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          },
          pharmaName:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          },
        })
    
        function handleValidation(){
          if(doctor_UUID === '' || doctor_UUID !==''){
            if(doctor_UUID === ''){
              setErrorText(previous => ({...previous,doctorName:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(doctor_UUID !== ''){
              setErrorText(previous => ({...previous,doctorName:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
                
            }
          }
          if(pharmaName === '' || pharmaName !==''){
            if(pharmaName === ''){
              setErrorText(previous => ({...previous,pharmaName:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(pharmaName !== ''){
              setErrorText(previous => ({...previous,pharmaName:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
                
            }
          }
          if(medicalCondition === '' || medicalCondition !==''){
            if(medicalCondition === ''){
              setErrorText(previous => ({...previous,medicalCondition:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(medicalCondition !== ''){
              setErrorText(previous => ({...previous,medicalCondition:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
                
            }
          }
          if(filePath === "" || filePath !== ""){
            if(filePath === ""){
              setErrorText(previous => ({...previous,file:{error:true,
                errorText:"Please Upload Prescription File",
                verified:false}}))
                return true;
                
            }else if(filePath !== ""){
              setErrorText(previous => ({...previous,file:{error:false,
                errorText:"Please Upload Prescription File",
                verified:true}}))
  
            }       
        }
      //   if(notes === "" || notes !== ""){
      //     if(notes === ""){
      //       setErrorText(previous => ({...previous,notes:{error:true,
      //         errorText:"Field can't be empty",
      //         verified:false}}))
      //         return true;
              
      //     }else if(notes !== ""){
      //       setErrorText(previous => ({...previous,notes:{error:false,
      //         errorText:"Field can't be empty",
      //         verified:true}}))

      //     }       
      //  }
        if(drug === '' || drug !==''){
            if(drug === ''){
              setErrorText(previous => ({...previous,drug:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(drug !== ''){
              setErrorText(previous => ({...previous,drug:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(quantity === "" || quantity !== ""){
              if(quantity === ""){
                setErrorText(previous => ({...previous,quantity:{error:true,
                  errorText:"Field can't be empty",
                  verified:false}}))
                  return true;
                  
              }else if(quantity !== ""){
                setErrorText(previous => ({...previous,quantity:{error:false,
                  errorText:"Field can't be empty",
                  verified:true}}))
    
              }       
          }
          
        if(dispenseForm === '' || dispenseForm !==''){
          if(dispenseForm === ''){
            setErrorText(previous => ({...previous,dispenseForm:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(dispenseForm !== ''){
            setErrorText(previous => ({...previous,dispenseForm:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }
        if(medNotes === "" || medNotes !== ""){
          if(medNotes === ""){
            setErrorText(previous => ({...previous,medNotes:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(medNotes !== ""){
            setErrorText(previous => ({...previous,medNotes:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              return false
          }       
      }
        
        console.log("RESULTS............");
        
      }
  
        const handleDelete = (deleteIndex) => {
          setPrescriptionMedicineListData((prevData) =>
            prevData.filter((_, index) => index !== deleteIndex)
          );
        };
  
        const handlRadioChange = (event) => {
          setRadioValue(event.target.value);
        };
  
        // function handlePrescriptionSave(){
        //   clinincalNote.prescription = prescriptionListData;
        //   console.log("added",clinincalNote)
        //   handlePrescriptionClosePopup()
        //   setIsPrescriptionSaved(true)
        //   toast.success('Prescription Saved Successfully', {
        //     position: "top-right",
        //     autoClose: 3000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //     });
  
        // }
        React.useEffect(() => {
          GetMasterSearchData('MEDICAL_CONDITION').then(res =>setMedicalCList(res))
          getDoctorsList(user.orgID,'a').then(res =>{
            console.log(res)
            if(res.status === 200){
              setDoctorList(res.data.content)
            }
          })
          MasterDataEncounterSearch('a','FARMACY').then(res =>{
            console.log(res)
            if(res.status === 200){
              setPharmacyList(res.data.content)
            }else{
              setPharmacyList([])
            }
            
          })
          MasterDataEncounterSearch('a','MEDICINE').then(res =>{
            console.log(res)
            if(res.status === 200){
              setDrugList(res.data.content)
            }else{
              setDrugList([])
            }
            
          })
          MasterDataEncounterSearch('a','MEDICINE_TYPE').then(res =>{
            console.log(res)
            if(res.status === 200){
              setDispenseFormList(res.data.content)
            }else{
              setDispenseFormList([])
            }
            
          })
        },[])
        return (
        <div>
          <input
            style={{display: 'none'}}
            ref={inputRef}
            type="file"
            onChange={handlePrescriptionFileChange}
            />
          <Dialog open={addPrescription} onClose={handleClosePrescriptionPopup} fullWidth maxWidth={'md'}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} padding={2}>
              <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add Prescription</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClosePrescriptionPopup}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
          >
            <CloseIcon />
          </IconButton>
              </Grid>
            </Grid>
            <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
            <DialogContent> 
            <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Doctor Name</Typography>
                <FormControl fullWidth>
                      <Autocomplete
                        onChange = {(event, newValue) => {if(newValue && newValue.name){
                            setDoctorName(newValue.name)
                            setDoctor_UUID(newValue.uuId)
                        }}}
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={doctorList}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Choose Doctor"
                        helperText={errorText.doctorName.error === true ? errorText.doctorName.errorText:""}
                        error={errorText.doctorName.error === true ? true : false}
                        autoFocus={errorText.doctorName.error === true ? true : false}
                        />}
                      />
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
          <Typography fontSize={16} fontWeight={'bold'}>Pharmacy Name</Typography>
          <FormControl fullWidth>
                      <Autocomplete
                      onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                        console.log("val.......",event.target.value)
                        setPharmacyList([])
                        
                        MasterDataEncounterSearch(event.target.value,'FARMACY').then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            setPharmacyList(res.data.content)
                          }else{
                            setPharmacyList([])
                          }
                          
                        })
                        
                        setPharmaName(newValue.term)
                      }}}
                      onChange={(event, newValue) => {if(newValue && newValue.typeID){
                        console.log("seletced val.......",newValue.label)
                        setPharmaName(newValue.label)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={pharmacyList}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.typeID}>
                            {option.label}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Search Pharmacy"
                      helperText={errorText.pharmaName.error === true ? errorText.pharmaName.errorText:""}
                      error={errorText.pharmaName.error === true ? true : false}
                      autoFocus={errorText.pharmaName.error === true ? true : false}
                      />}
                    />
                      </FormControl>
                </Grid>
              
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Medical Condition</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setMedicalCondition(newValue.typeID)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={medicalCList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Choose Medical Condition"
                      helperText={errorText.medicalCondition.error === true ? errorText.medicalCondition.errorText:""}
                      error={errorText.medicalCondition.error === true ? true : false}
                      autoFocus={errorText.medicalCondition.error === true ? true : false}
                      />}
                    />
                </FormControl>
              </Grid>
        <Grid item xs={12} sm={3} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Choose Files</Typography>
        <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',fontSize:'12px',maxWidth:'100%'}} onClick={()=>{
         setFilePath(`PRESCRIPTION_${patient.uuid}`)
         handleClick()
       }}>
                 Upload
                </Button>
                <Typography fontSize={12} fontWeight={'bold'} paddingTop={2}>Uploaded File Name: {fileName}</Typography>
                {errorText.file.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.file.errorText}</Typography> : <div></div>}
              </Grid>
              <Grid item xs={12} sm={9} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Notes</Typography>
                <TextField
                  required
                  fullWidth
                  value={notes}
                  id="spo2"
                  label="Enter Notes"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setNotes(e.target.value)}}
                  multiline
                  rows={4}
                  // helperText={errorText.notes.error === true ? errorText.notes.errorText:""}
                  // error={errorText.notes.error === true ? true : false}
                  // autoFocus={errorText.notes.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Typography fontSize={18} padding={1} fontWeight={'bold'}>Add Medicine</Typography>
        <Divider sx={{bgcolor:'#651fff',height:2}}/>     
            <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12} sm={6} padding={2}>
          <FormControl>
        <FormLabel id="demo-row-radio-buttons-group-label">Select Data Source</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={radioValue}
          onChange={handlRadioChange}
        >
          <FormControlLabel value={0} control={<Radio 
            onChange={()=>{setRadioValue(0)
            setDrugList([])
            setChangeDataSource(false)
            }}
          />} label="Internal" />
          <FormControlLabel value={1} control={<Radio 
            onChange={()=>{setRadioValue(1)
            setDrugList([])
            setChangeDataSource(true)
            }}
          />} label="SnowMed" />
          
        </RadioGroup>
      </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} padding={2}></Grid>
      <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Drug</Typography>
                  {changeDataSource === false ?
                  <FormControl fullWidth>
                      <Autocomplete
                      onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                        console.log("val.......",event.target.value)
                        setDrugList([])
                        
                        MasterDataEncounterSearch(event.target.value,'MEDICINE').then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            setDrugList(res.data.content)
                          }else{
                            setDrugList([])
                          }
                          
                        })
                        
                        setDrug(newValue.term)
                      }}}
                      onChange={(event, newValue) => {if(newValue && newValue.typeID){
                        console.log("seletced val.......",newValue.label)
                        setDrug(newValue.label)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={drugList}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.typeID}>
                            {option.label}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Select Drug"
                        helperText={errorText.drug.error === true ? errorText.drug.errorText:""}
                        error={errorText.drug.error === true ? true : false}
                        autoFocus={errorText.drug.error === true ? true : false}
                      />}
                    />
                      </FormControl>
                      : <FormControl fullWidth>
                      <Autocomplete
                      onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                        console.log("val.......",event.target.value)
                        setDrugList([])
                        getMedicineList(event.target.value).then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            setDrugList(res.data)
                          }else{
                            setDrugList([])
                          }
                          
                        })
                        
                        setDrug(newValue.term)
                      }}}
                      onChange={(event, newValue) => {if(newValue && newValue.id){
                        console.log("seletced val.......",newValue.term)
                        setDrug(newValue.term)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={drugList}
                      getOptionLabel={(option) => option.term}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.id}>
                            {option.term}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Select Drug"
                      helperText={errorText.drug.error === true ? errorText.drug.errorText:""}
                      error={errorText.drug.error === true ? true : false}
                      autoFocus={errorText.drug.error === true ? true : false}
                      />}
                    />
                      </FormControl>}
                </Grid>
                <Grid item xs={12} sm={6} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'}>Quantity</Typography>
                  <TextField
                    required
                    fullWidth
                    type='number'
                    id="qunatity"
                    label="Enter Quantity"
                    name="quantity"
                    value={quantity}
                    onChange={(e) =>setQuantity(e.target.value)}
                    size='small'
                    helperText={errorText.quantity.error === true ? errorText.quantity.errorText:""}
                    error={errorText.quantity.error === true ? true : false}
                    autoFocus={errorText.quantity.error === true ? true : false}
                  />
                </Grid>
          </Grid>
          <Grid container spacing={2} paddingTop={3}>
                <Grid item xs={12} sm={6} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'}>Dispense Form</Typography>
                <FormControl fullWidth>
                      <Autocomplete
                      onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                        console.log("val.......",event.target.value)
                        setDispenseFormList([])
                        
                        MasterDataEncounterSearch(event.target.value,'MEDICINE_TYPE').then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            setDispenseFormList(res.data.content)
                          }else{
                            setDispenseFormList([])
                          }
                          
                        })
                        
                        setDispenseForm(newValue.term)
                      }}}
                      onChange={(event, newValue) => {if(newValue && newValue.typeID){
                        console.log("seletced val.......",newValue.label)
                        setDispenseForm(newValue.label)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={dispenseFormList}
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option.typeID}>
                            {option.label}
                          </li>
                        );
                      }}
                      renderInput={(params) => <TextField {...params} label="Search Dispense Form"
                        helperText={errorText.dispenseForm.error === true ? errorText.dispenseForm.errorText:""}
                        error={errorText.dispenseForm.error === true ? true : false}
                        autoFocus={errorText.dispenseForm.error === true ? true : false}
                      />}
                    />
                      </FormControl>
                </Grid>
          </Grid>
          <Grid container spacing={2} paddingTop={3}>
          <Grid item xs={12} sm={12} md={12} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Notes</Typography>
                <TextField
                  required
                  fullWidth
                  value={medNotes}
                  id="spo2"
                  label="Enter Notes"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setMedNotes(e.target.value)}}
                  multiline
                  rows={4}
                  helperText={errorText.medNotes.error === true ? errorText.medNotes.errorText:""}
                  error={errorText.medNotes.error === true ? true : false}
                  autoFocus={errorText.medNotes.error === true ? true : false}
                />
              </Grid>
               
          </Grid>
          {prescriptionMedicineListData.length != 0 ? 
              <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Medicine Name</StyledTableCell>
              <StyledTableCell align="center">Quantity</StyledTableCell>
              <StyledTableCell align="center">Notes</StyledTableCell>
              <StyledTableCell align="center">Dispense Form</StyledTableCell>
              <StyledTableCell align="center">Pharmacy Name</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {prescriptionMedicineListData.map((details,index) => (
              <StyledTableRow key={details.name}>
                <StyledTableCell component="th" scope="row">
                  {details.name}
                </StyledTableCell>
                <StyledTableCell align="center">{details.quantity}</StyledTableCell>
                <StyledTableCell align="center">{details.note}</StyledTableCell>
                <StyledTableCell align="center">{details.form}</StyledTableCell>
                <StyledTableCell align="center">{pharmaName}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton onClick={() =>{handleDelete(index)}}>
                      <DeleteIcon></DeleteIcon>
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
         : <div></div>}   
            </DialogContent>
            <DialogActions>
            <Button variant="outlined" sx={{width:'30%',borderColor:'#651fff'}} onClick={() =>{
              let res = handleValidation()
              if(res === false){
                  setPrescriptionMedicineListData(prescriptionMedicineListData.concat({
  
                    name: drug,
                    quantity:quantity,
                    form:dispenseForm,
                    note: medNotes
                
                }))
              }else{
                toast("Please Check your Input Data")
              }
              
              }}
              
              >
                        Add Medicine
                      </Button>
              
              <Button variant="contained" sx={{width:'30%',bgcolor:'#651fff'}} onClick={() =>{
                {prescriptionMedicineListData.length !== 0 ? 
                  createNewPrescription(user.orgID,patient.uuid,doctor_UUID,doctorName,
                    user.userType,notes,pharmaName,uploadedFilePath,
                    medicalCondition,prescriptionMedicineListData).then(res =>{
                      console.log(res)
                      if(res.status === 200){
                        toast.success(res.message)
                        
                        getPrescriptionList(patient.uuid).then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            setPrescriptionListData(res.data)
                            setAddPrescription(false)
                          }
                        })
                      }else{
                        toast.error("Error Occurred")
                        getPrescriptionList(patient.uuid).then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            setPrescriptionListData(res.data)
                            setAddPrescription(false)
                          }
                        })
                      }
                    }): toast.error("Medicine List can't be empty")}
                }}>
                        Add Prescription
                      </Button>
      
              
            </DialogActions>
          </Dialog>
              </div>
        );
     
    }
    function AddVitals(){
     
      const [height, setHeight] = React.useState('');
      const [weight, setWeight] = React.useState('');
      const [temp, setTemp] = React.useState('');
      const [spo2, setSPO2] = React.useState('');
      const [pulse, setPulse] = React.useState('');
      const [systolic, setSystolic] = React.useState('');
      const [diastolic, setDiastolic] = React.useState('');
      const [grbs, setGRBS] = React.useState('');
      const [respiratoryRate, setRespiratoryRate] = React.useState('');
      const [notes, setNotes] = React.useState('');
      const [hunit, setHunit] = React.useState('cm');
      const [wunit, setWunit] = React.useState('kg');
      const [tempUnit, setTempUnit] = React.useState('°C');
      const [tempText, setTempText] = React.useState('Normal Range 26 - 46');
      const [hunitToggle, setHunitToggle] = React.useState(false);
      const [tempUnitToggle, setTempUnitToggle] = React.useState(false);
      const [filePath,setFilePath] = React.useState('');
      const [uploadedFilePath,setUploadedFilePath] = React.useState('');
      const [file,setFile ] = React.useState('');
      const [fileName,setFileName ] = React.useState('Please Upload a file');
      const [errorText,setErrorText] = React.useState({
        height:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        weight:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        temp:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        spo2:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        pulse:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        systolic:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        diastolic:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        respiratoryRate:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        grbs:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        notes:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        file:{
          error:false,
          errorText:"Please Upload a File",
          verified:true
        },
      })
      const between = (x, min, max) => {
        if(x >= min && x <= max){
          return true
        }else{
          return false
        }
      }

      function handleValidation(){

        if(height === "" || height !== ""){
                if(height === ""){
                  setErrorText(previous => ({...previous,height:{error:true,
                    errorText:"Field can't be empty",
                    verified:false}}))
                    return true;
                    
                }else if(between(height,0,200) === false){
                  setErrorText(previous => ({...previous,height:{error:true,
                    errorText:"Input Value Not in Range",
                    verified:false}}))
                    return true;
                }else if(height !== "" && between(height,0,200) === true){
                  setErrorText(previous => ({...previous,height:{error:false,
                    errorText:"Field can't be empty",
                    verified:true}}))
                }       
        }
        if(weight === "" || weight !== ""){
              if(weight === ""){
                setErrorText(previous => ({...previous,weight:{error:true,
                  errorText:"Field can't be empty",
                  verified:false}}))
                  return true;
                  
              }else if(between(weight,0,300) === false){
                setErrorText(previous => ({...previous,weight:{error:true,
                  errorText:"Input Value Not in Range",
                  verified:false}}))
                  return true;
              }else if(weight !== "" && between(weight,0,300) === true){
                setErrorText(previous => ({...previous,weight:{error:false,
                  errorText:"Field can't be empty",
                  verified:true}}))
              }       
        }
        if(temp === "" || temp !== ""){
          if(temp === ""){
            setErrorText(previous => ({...previous,temp:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(tempUnit === '°C' && between(temp,26,46) === false){
            setErrorText(previous => ({...previous,temp:{error:true,
              errorText:"Input Value Not in Range",
              verified:false}}))
              return true;
          }else if(tempUnit === '°F' && between(temp,78.8,114.8) === false){
            setErrorText(previous => ({...previous,temp:{error:true,
              errorText:"Input Value Not in Range",
              verified:false}}))
              return true;
          }
          else if(between(temp,26,46) === true  || between(temp,78.8,114.8) === true){
            setErrorText(previous => ({...previous,temp:{error:false,
              errorText:"Field Can't be Empty",
              verified:true}}))
          }       
      }

        if(spo2 === "" || spo2 !== ""){
          if(spo2 === ""){
            setErrorText(previous => ({...previous,spo2:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(between(spo2,1,100) === false){
            setErrorText(previous => ({...previous,spo2:{error:true,
              errorText:"Input Value Not in Range",
              verified:false}}))
              return true;
          }else if(spo2 !== "" && between(spo2,1,100) === true){
            setErrorText(previous => ({...previous,spo2:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
          }       
        }
        if(pulse === "" || pulse !== ""){
          if(pulse === ""){
            setErrorText(previous => ({...previous,pulse:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(between(pulse,26,220) === false){
            setErrorText(previous => ({...previous,pulse:{error:true,
              errorText:"Input Value Not in Range",
              verified:false}}))
              return true;
          }else if(pulse !== "" && between(pulse,26,220) === true){
            setErrorText(previous => ({...previous,pulse:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
          }       
        }

        if(systolic === "" || systolic !== ""){
          if(systolic === ""){
            setErrorText(previous => ({...previous,systolic:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(between(systolic,30,240) === false){
            setErrorText(previous => ({...previous,systolic:{error:true,
              errorText:"Input Value Not in Range",
              verified:false}}))
              return true;
          }else if(systolic !== "" && between(systolic,30,240) === true){
            setErrorText(previous => ({...previous,systolic:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
          }       
        }

        if(diastolic === "" || diastolic !== ""){
          if(diastolic === ""){
            setErrorText(previous => ({...previous,diastolic:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(between(diastolic,10,200) === false){
            setErrorText(previous => ({...previous,diastolic:{error:true,
              errorText:"Input Value Not in Range",
              verified:false}}))
              return true;
          }else if(diastolic !== "" && between(diastolic,10,200) === true){
            setErrorText(previous => ({...previous,diastolic:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
          }       
        }

        if(respiratoryRate === "" || respiratoryRate !== ""){
          if(respiratoryRate === ""){
            setErrorText(previous => ({...previous,respiratoryRate:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(between(respiratoryRate,5,75) === false){
            setErrorText(previous => ({...previous,respiratoryRate:{error:true,
              errorText:"Input Value Not in Range",
              verified:false}}))
              return true;
          }else if(respiratoryRate !== "" && between(respiratoryRate,5,75) === true){
            setErrorText(previous => ({...previous,respiratoryRate:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
          }       
        }

        if(grbs === "" || grbs !== ""){
          if(grbs === ""){
            setErrorText(previous => ({...previous,grbs:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(between(grbs,5,1000) === false){
            setErrorText(previous => ({...previous,grbs:{error:true,
              errorText:"Input Value Not in Range",
              verified:false}}))
              return true;
          }else if(grbs !== "" && between(grbs,5,1000) === true){
            setErrorText(previous => ({...previous,grbs:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              
          }       
        }
        if(filePath === "" || filePath !== ""){
          if(filePath === ""){
            setErrorText(previous => ({...previous,file:{error:true,
              errorText:"Please Upload a file",
              verified:false}}))
              return true;
              
          }else if(filePath !== ""){
            setErrorText(previous => ({...previous,file:{error:false,
              errorText:"Please Upload a file",
              verified:true}}))
              
          }       
      }

        if(notes === "" || notes !== ""){
          if(notes === ""){
            setErrorText(previous => ({...previous,notes:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(notes !== ""){
            setErrorText(previous => ({...previous,notes:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              return false
          }       
      }
          console.log("RESULTS............");
          
        }

      const handleVitalFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    
        console.log('fileObj is', fileObj);
    
        // 👇️ reset file input
        event.target.value = null;
    
        // 👇️ is now empty
        console.log(event.target.files);
    
        // 👇️ can still access file object here
        console.log(fileObj);
        console.log(fileObj.name);
        if(fileObj.type === 'image/png' || fileObj.type === 'image/jpg' 
        || fileObj.type === 'image/jpeg' || fileObj.type === 'application/pdf'){
          fileUpload(fileObj,filePath).then(res =>{
            console.log(res)
            if(res.status === 200){
              setUploadedFilePath(res.path)
              setFile(fileObj)
              setFileName(fileObj.name)
            }else{
              setFile('')
              setFileName('Please Upload a file')
            }
          }
          )
        }else{
          alert("Invalid File Type !! Accepted Files: PNG,JPG,JPEG,PDF")
          setFile('')
          setFileName('Please Upload a file')
        }
        
        
      };
      
      async function createVitals(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "orgid": user.orgID,
          "weight": weight,
          "height": height,
          "pulse": pulse,
          "systolic": systolic,
          "diastolic": diastolic,
          "temperature": temp,
          "respiratoryrate": respiratoryRate,
          "grbs": grbs,
          "waist": "",
          "hunit": hunit,
          "wunit": wunit,
          "tempunit": tempUnit,
          "oxygensaturation": spo2,
          "note": notes,
          "file": uploadedFilePath,
          "appointmentid": "",
          "uuid": patient.uuid,
          "source": "Ubiqare"
        });
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
         fetch(`${user.baseUrl}public/addVitals`, requestOptions)
          .then(response => response.json())
          .then(result => {
            if(result.status === 200){
              toast.success(result.message)
              vitalList()
              handleCloseVitalPopup()
            }else{
              toast.error("Error occurred")
            }
            
          })
          .catch(error => console.log('error', error));
      }

      const convertCmToInch = (cm) => {
        return cm / 2.54;
      };

      const convertInchToCm = (inch) => {
        return inch * 2.54;
      };

      const convertCelsiusToFahrenheit = (celsius) => {
        return (celsius * 9/5) + 32;
      };

      const convertFahrenheitToCelsius = (fahrenheit) => {
        return (fahrenheit - 32) * 5/9;
      };

      return (
      <div>
        <input
                style={{display: 'none'}}
                ref={inputRef}
                type="file"
                onChange={handleVitalFileChange}
          />
        <Dialog open={addVitals} onClose={handleCloseVitalPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add Vitals</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseVitalPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={9}>
                <Typography fontSize={16} fontWeight={'bold'}>Height</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>In</Typography>
              <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={()=>{
                setHunitToggle(!hunitToggle)
                if(hunitToggle === true){
                  setHunit("cm")
                  setHeight(convertInchToCm(height))
                }else{
                  setHunit("In")
                  setHeight(convertCmToInch(height))
                }
              }} />
              <Typography>cm</Typography>
            </Stack>
            </Grid>
          </Grid>
                <TextField
                  value={height}
                  name="height"
                  required
                  fullWidth
                  id="height"
                  label="Enter Height"
                  size='small'
                  onChange={(e) =>{setHeight(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{hunit}</InputAdornment>,
                  }}
                  helperText={errorText.height.error === true ? errorText.height.errorText:""}
                  error={errorText.height.error === true ? true : false}
                  autoFocus={errorText.height.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Weight (in Kg)</Typography>
                <TextField
                  required
                  fullWidth
                  id="weight"
                  value={weight}
                  label="Enter Weight"
                  name="weight"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setWeight(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                  }}
                  helperText={errorText.weight.error === true ? errorText.weight.errorText:""}
                  error={errorText.weight.error === true ? true : false}
                  autoFocus={errorText.weight.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Grid container spacing={2}>
            <Grid item xs={6} sm={9}>
                <Typography fontSize={16} fontWeight={'bold'}>Temperature</Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography>°F</Typography>
              <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} onChange={()=>{
                setTempUnitToggle(!tempUnitToggle)
                if(tempUnitToggle === true){
                  setTempUnit("°C")
                  setTempText('Normal Range 26 - 46')
                  setTemp(convertFahrenheitToCelsius(temp))
                }else{
                  setTempUnit("°F")
                  setTempText('Normal Range 78.8 - 114.8')
                  setTemp(convertCelsiusToFahrenheit(temp))
                }
              }} />
              <Typography>°C</Typography>
            </Stack>
            </Grid>
          </Grid>
                <TextField
                  name="Temperature"
                  required
                  fullWidth
                  value={temp}
                  id="temperature"
                  label={tempText}
                  size='small'
                  onChange={(e) =>{setTemp(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">{tempUnit}</InputAdornment>,
                  }}
                  helperText={errorText.temp.error === true ? errorText.temp.errorText:""}
                  error={errorText.temp.error === true ? true : false}
                  autoFocus={errorText.temp.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>SPO2</Typography>
                <TextField
                  required
                  fullWidth
                  value={spo2}
                  id="spo2"
                  label="Normal Range 1 - 100"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setSPO2(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ppm</InputAdornment>,
                  }}
                  helperText={errorText.spo2.error === true ? errorText.spo2.errorText:""}
                  error={errorText.spo2.error === true ? true : false}
                  autoFocus={errorText.spo2.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Pulse</Typography>
                <TextField
                  required
                  fullWidth
                  value={pulse}
                  id="pulse"
                  label="Normal Range 26 - 220"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setPulse(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">BPM</InputAdornment>,
                  }}
                  helperText={errorText.pulse.error === true ? errorText.pulse.errorText:""}
                  error={errorText.pulse.error === true ? true : false}
                  autoFocus={errorText.pulse.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Blood Pressure</Typography>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} padding={2}>
                    <TextField
                      fullWidth
                      value={systolic}
                      id="bloodpressure"
                      name="BloodPressure"
                      label="30 - 240"
                      autoComplete="family-name"
                      size='small'
                      onChange={(e) =>{setSystolic(e.target.value)}}
                      helperText={errorText.systolic.error === true ? errorText.systolic.errorText:""}
                      error={errorText.systolic.error === true ? true : false}
                      autoFocus={errorText.systolic.error === true ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={0.1} padding={2}>
                <Typography fontSize={25}>/</Typography>
                </Grid>
                
                  <Grid item xs={12} sm={4} padding={2}>
                    <TextField
                      fullWidth
                      value={diastolic}
                      id="bloodpressure"
                      name="BloodPressure"
                      label="10 - 200"
                      autoComplete="family-name"
                      size='small'
                      onChange={(e) =>{setDiastolic(e.target.value)}}
                      helperText={errorText.diastolic.error === true ? errorText.diastolic.errorText:""}
                      error={errorText.diastolic.error === true ? true : false}
                      autoFocus={errorText.diastolic.error === true ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={1} padding={2}>
                <Typography sx={{marginTop:2}}>mmHG</Typography>
                </Grid>
                  </Grid>
                
              </Grid>  
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Respiratory Rate</Typography>
                <TextField
                  name="Temperature"
                  required
                  fullWidth
                  value={respiratoryRate}
                  id="temperature"
                  label="Normal Range 5 - 75"
                  size='small'
                  onChange={(e) =>{setRespiratoryRate(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">ppm</InputAdornment>,
                  }}
                  helperText={errorText.respiratoryRate.error === true ? errorText.respiratoryRate.errorText:""}
                  error={errorText.respiratoryRate.error === true ? true : false}
                  autoFocus={errorText.respiratoryRate.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>GRBS</Typography>
                <TextField
                  required
                  fullWidth
                  value={grbs}
                  id="spo2"
                  label="Normal Range 5 - 1000"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setGRBS(e.target.value)}}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">mg / DL</InputAdornment>,
                  }}
                  helperText={errorText.grbs.error === true ? errorText.grbs.errorText:""}
                  error={errorText.grbs.error === true ? true : false}
                  autoFocus={errorText.grbs.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={4} md={4} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Choose Files</Typography>
        <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',fontSize:'12px',maxWidth:'100%'}} onClick={()=>{
          setFilePath(`VITALS_${patient.uuid}`)
          handleClick()
        }}>
          
                  Upload
                </Button>
                <Typography fontSize={12} fontWeight={'bold'} paddingTop={2}>Uploaded File Name: {fileName}</Typography>
                {errorText.file.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.file.errorText}</Typography> : <div></div>}
              </Grid>
              <Grid item xs={12} sm={8} md={8} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Notes</Typography>
                <TextField
                  required
                  fullWidth
                  value={notes}
                  id="spo2"
                  label="Enter Notes"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setNotes(e.target.value)}}
                  multiline
                  rows={4}
                  helperText={errorText.notes.error === true ? errorText.notes.errorText:""}
                  error={errorText.notes.error === true ? true : false}
                  autoFocus={errorText.notes.error === true ? true : false}
                />
              </Grid>
        </Grid>
          
        <Divider sx={{bgcolor:'#651fff',height:5,marginTop:1,width:'100%'}}/>  
          </DialogContent>
          <DialogActions>
            
            {/* <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={() =>{handleVitalSave(height,weight,temp,spo2,pulse,systolic,diastolic,grbs,hunit,wunit,tempUnit,respiratoryRate)}}>
                      Save
                    </Button> */}
                    <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={() =>{
                      var validate = handleValidation()
                      if(validate === false){
                        createVitals()
                      }else{
                        toast.error("Please check the Input Fields")
                      }
                      
                      
                      }} >
                      Add Vitals
                    </Button>
    
                    
          </DialogActions>
        </Dialog>
            </div>
      );
    }

    function VitalListComponent(){
      const columns = [
        {
          field: 'id',
          headerName: 'ID',
          width: 50,
          editable: false,
        },
          {
            field: 'createdOn',
            headerName: 'Date',
            width: 210,
            editable: false,
          },
          {
            field: 'temperature',
            headerName: 'Temp',
            width: 120,
            editable: false,
          },
          {
            field: 'pulse',
            headerName: 'Pulse',
            width: 120,
            editable: false,
          },
          {
              field: 'systolic',
              headerName: 'BP',
              width: 120,
              editable: false,
            },
            {
              field: 'oxygensaturation',
              headerName: 'SPO2',
              width: 120,
              editable: false,
            },
            {
              field: 'respiratoryrate',
              headerName: 'Resp.Rate',
              width: 120,
              editable: false,
            },
            {
              field: 'grbs',
              headerName: 'GRBS',
              width: 120,
              editable: false,
            },
            // {
            //   field: 'source',
            //   headerName: 'Source',
            //   width: 120,
            //   editable: true,
            // }
          
        ];
      return(  
          <Grid>
              <Box sx={{ height: '70vh', width: '100%',bgcolor:'white',marginTop:2 }}>
                              
                              <DataGrid
                              rows={vitalListData}
                              columns={columns}
                              pageSize={10}
                              rowsPerPageOptions={[10]}
                              experimentalFeatures={{ newEditingApi: true }}
                              onRowClick={(e)=>{vitalDetails(e.id)}}
                              // getRowId={{}}
                              />
                          </Box>
          </Grid>
                
              );
    }
    function MedicineListComponent(){
      const columns = [
        {
          field: 'prescriptionid',
          headerName: 'ID',
          width: 120,
          editable: false,
        },
          {
            field: 'name',
            headerName: 'Medicine Name',
            width: 200,
            editable: false,
          },
          {
            field: 'quantity',
            headerName: 'QTY',
            width: 100,
            editable: false,
          },
          {
              field: 'form',
              headerName: 'Dispense Form',
              width: 200,
              editable: false,
            },
            {
              field: 'note',
              headerName: 'Instructions',
              width: 200,
              editable: false,
            },
          
        ];
      return(  
          <Grid>
            <Typography fontSize={18} fontWeight={'bold'} paddingTop={2}>Medicines</Typography>
              <Box sx={{ height: '50vh', width: '100%',bgcolor:'white',marginTop:2 }}>
                              
                              <DataGrid
                              getRowId={(row) =>  generateString(0,16)}
                              rows={prescriptionPopupDetails.medicineList}
                              columns={columns}
                              pageSize={5}
                              rowsPerPageOptions={[5]}
                              experimentalFeatures={{ newEditingApi: true }}
                              // getRowId={{}}
                              />
                          </Box>
          </Grid>
                
              );
    }
    function HealthIndicatorListComponent(){
      return(
        <Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 1000,marginTop:2}} aria-label="customized table">
              <TableHead>
                <TableRow>
              <StyledTableCell>Start Date</StyledTableCell>
              <StyledTableCell align="center">Source</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>  
            </TableRow>
          </TableHead>
          <TableBody>
            {healthIndicatorListData.length !== 0 ? healthIndicatorListData.map((health,index) => (
              <StyledTableRow key={health.createdOn}>
                <StyledTableCell component="th" scope="row">
                  {health.createdOn.slice(0,10)} {health.createdOn.slice(11,19)}
                </StyledTableCell>
                <StyledTableCell align="center">{user.userType}</StyledTableCell>
                <StyledTableCell align="center">
                <IconButton onClick={() =>{
                  getHealthIndicatorDetail(health.id).then(res =>{
                    console.log(res)
                    
                      setHIDetails(res)
                      setEditSymptom(true)
                  })
                }}>
                    <EditIcon></EditIcon>
                </IconButton>
                <IconButton onClick={() =>{
                  deleteHealthIndicator(health.id).then(res =>{
                    console.log(res)
                    {res.status === 200 ? confirmAlert({
                      title: res.message,
                      buttons: [
                        {
                          label: 'Okay',
                          onClick: () => {
                            getHealthIndicatorList(user.orgID,patient.uuid).then(res =>{
                              console.log(res)
                              setHealthIndicatorListData(res)
                              setDComponent('health')
                              // if(res.status === 200){
                              //   setHealthIndicatorListData(res)
                              //   setComponent('health')
                              // }else{
                              //   console.log(res)
                              //   setComponent('health')
                              // }
                              
                            })
                          }
                        },
                      ],
                      closeOnEscape:false,
                      closeOnClickOutside:false,
                    }
                      
                    ): console.log(res)}
                  })
                }}>
                    <DeleteIcon></DeleteIcon>
                </IconButton>
                <IconButton onClick={() =>{
                  getHealthIndicatorDetail(health.id).then(res =>{
                    console.log(res)
                    
                      setHIDetails(res)
                      setShowHealthIndicator(true)
                  })
                  
                }}>
                    <InfoRoundedIcon></InfoRoundedIcon>
                </IconButton>
              </StyledTableCell>
              </StyledTableRow>
            )):<Paper sx={{margin:5,padding:5}}>
            <Typography align='center'>No Data Available</Typography>
              </Paper>}
          </TableBody>
        </Table>
      </TableContainer>
        </Grid>
      )
    }

    async function vitalList(){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      var raw = JSON.stringify({
        "uuid": patient.uuid
      });
      
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      
      fetch(`${user.baseUrl}public/vitalList`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setVitalListData(result.data)
          })
        .catch(error => console.log('error', error));
    }
    function ShowVitalDetails(){
     
      
      const [height, setHeight] = React.useState(vitalPopupDetails.height);
      const [weight, setWeight] = React.useState(vitalPopupDetails.weight);
      const [temp, setTemp] = React.useState(vitalPopupDetails.temperature);
      const [spo2, setSPO2] = React.useState(vitalPopupDetails.oxygensaturation);
      const [pulse, setPulse] = React.useState(vitalPopupDetails.pulse);
      const [systolic, setSystolic] = React.useState(vitalPopupDetails.systolic);
      const [diastolic, setDiastolic] = React.useState(vitalPopupDetails.diastolic);
      const [bp, setBP] = React.useState(vitalPopupDetails.BP);
      const [grbs, setGRBS] = React.useState(vitalPopupDetails.grbs);
      const [respiratoryRate, setRespiratoryRate] = React.useState(vitalPopupDetails.respiratoryrate);
      const [notes, setNotes] = React.useState(vitalPopupDetails.note);
      const [hunit, setHunit] = React.useState(vitalPopupDetails.hunit);
      const [wunit, setWunit] = React.useState(vitalPopupDetails.wunit);
      const [tempUnit, setTempUnit] = React.useState(vitalPopupDetails.tempunit);
      const [fileName, setFileName] = React.useState(vitalPopupDetails.file);

      console.log(vitalPopupDetails)

      return (
      <div>
        <Dialog open={showVitals} onClose={handleCloseVitalDetailsPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Vitals</DialogTitle>
            <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',position:'absolute',right:72,top:8}}
            onClick={
              () =>{
                fileDownload(fileName)
              }
            }
            disabled={fileName === "" || fileName === "None" || fileName === null ? true : false }
            >
                  Download
                </Button>
            <IconButton
              aria-label="close"
              onClick={handleCloseVitalDetailsPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>    
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Patient Name</Typography>
              <Typography fontSize={16}>{patient.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Doctor Name</Typography>
              <Typography fontSize={16}>{user.name === 'undefined' || user.name === undefined ? vitalPopupDetails.createdBy : user.name}</Typography>
            </Grid>         
          </Grid>
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Height</Typography>
              <Typography fontSize={16}>{ height === "" || height === "None" || height === null ? "" : height} { hunit === "" || hunit === "None" || hunit === null ? "" : hunit}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Weight</Typography>
              <Typography fontSize={16}>{ weight === "" || weight === "None" || weight === null ? "" : weight} { wunit === "" || wunit === "None" || wunit === null ? "" : wunit}</Typography>
            </Grid>   
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Temperature</Typography>
              <Typography fontSize={16}>{ temp === "" || temp === "None" || temp === null ? "" : temp} { tempUnit === "" || tempUnit === "None" || tempUnit === null ? "" : tempUnit}</Typography>
            </Grid>      
          </Grid> 
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>SPO2</Typography>
              <Typography fontSize={16}>{ spo2 === "" || spo2 === "None" || spo2 === null ? "" : spo2}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Pulse</Typography>
              <Typography fontSize={16}>{ pulse === "" || pulse === "None" || pulse === null ? "" : pulse} BPM</Typography>
            </Grid>   
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Blood Pressure</Typography>
              <Typography fontSize={16}>{ systolic === "" || systolic === "None" || systolic === null ? "" : systolic} / { diastolic === "" || diastolic === "None" || diastolic === null ? "" : diastolic} mmHG</Typography>
            </Grid>      
          </Grid> 
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Respiratory Rate</Typography>
              <Typography fontSize={16}>{ respiratoryRate === "" || respiratoryRate === "None" || respiratoryRate === null ? "" : respiratoryRate} ppm</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>GRBS</Typography>
              <Typography fontSize={16}>{ grbs === "" || grbs === "None" || grbs === null ? "" : grbs}</Typography>
            </Grid>   
            <Grid item xs={12} sm={4} padding={2}>
            </Grid>      
          </Grid>
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={6} md={6} lg={6} padding={2}>
            <Button variant="outlined"  endIcon={<KeyboardArrowDownIcon/>}>
                  <Typography fontSize={12}>{fileName === "" || fileName === "None" || fileName === null ? "" : fileName.slice(81) }</Typography>
                </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Notes</Typography>
              <Typography fontSize={16}>{notes === "" || notes === "None" || notes === null ? "" : notes}</Typography>
            </Grid>         
          </Grid>
          </DialogContent>
        </Dialog>
            </div>
      );
    }
    async function vitalDetails(vitalId){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", user.token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch(`${user.baseUrl}public/vitalDetail/${vitalId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          setVitalDetails(result.data)
          setShowVitals(true)
        })
        .catch(error => console.log('error', error));
      
    }


    function ShowAllergyDetails(){
     

      console.log(allergyPopupDetails)

      return (
      <div>
        <Dialog open={showAllergy} onClose={handleCloseAllergyDetailsPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>{allergyPopupDetails.alleryinfo}</DialogTitle>
            <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',position:'absolute',right:72,top:8}}
            onClick={()=>{
              fileDownload(allergyPopupDetails.file)
              }
            }
            disabled={allergyPopupDetails.file === '' || allergyPopupDetails.file === 'None' || allergyPopupDetails.file === undefined || allergyPopupDetails.file === null ? true : false}
            >
                  Download
                </Button>
            <IconButton
              aria-label="close"
              onClick={handleCloseAllergyDetailsPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>    
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Patient Name</Typography>
              <Typography fontSize={16}>{patient.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Doctor Name</Typography>
              <Typography fontSize={16}>Dr. {allergyPopupDetails.treatedby === '' || allergyPopupDetails.treatedby === 'None' || allergyPopupDetails.treatedby === undefined || allergyPopupDetails.treatedby === null ? "" : allergyPopupDetails.treatedby}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Allergy Name</Typography>
              <Typography fontSize={16}>{allergyPopupDetails.alleryinfo === '' || allergyPopupDetails.alleryinfo === 'None' || allergyPopupDetails.alleryinfo === undefined || allergyPopupDetails.alleryinfo === null ? "" : allergyPopupDetails.alleryinfo}</Typography>
            </Grid>         
          </Grid>
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Type of Reaction</Typography>
              <Typography fontSize={16}>{allergyPopupDetails.reactioninfo === '' || allergyPopupDetails.reactioninfo === 'None' || allergyPopupDetails.reactioninfo === undefined || allergyPopupDetails.reactioninfo === null ? "" : allergyPopupDetails.reactioninfo}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Severity</Typography>
              <Typography fontSize={16}>{allergyPopupDetails.severty === '' || allergyPopupDetails.severty === 'None' || allergyPopupDetails.severty === undefined || allergyPopupDetails.severty === null ? "" : allergyPopupDetails.severty}</Typography>
            </Grid>   
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Status</Typography>
              <Typography fontSize={16}>{allergyPopupDetails.allergystatus === '' || allergyPopupDetails.allergystatus === 'None' || allergyPopupDetails.allergystatus === undefined || allergyPopupDetails.allergystatus === null ? "" : allergyPopupDetails.allergystatus}</Typography>
            </Grid>      
          </Grid> 
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Start Date</Typography>
              <Typography fontSize={16}>{allergyPopupDetails.startdate === '' || allergyPopupDetails.startdate === 'None' || allergyPopupDetails.startdate === undefined || allergyPopupDetails.startdate === null ? "" : allergyPopupDetails.startdate.slice(0,10)}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>End Date</Typography>
              <Typography fontSize={16}>{allergyPopupDetails.enddate === '' || allergyPopupDetails.enddate === 'None' || allergyPopupDetails.enddate === undefined || allergyPopupDetails.enddate === null ? "" : allergyPopupDetails.enddate.slice(0,10)}</Typography>
            </Grid>   
            <Grid item xs={12} sm={4} padding={2}>
              
            </Grid>      
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={6} md={6} lg={6} padding={2}>
            <Button variant="outlined" endIcon={<KeyboardArrowDownIcon/>}>
                  {allergyPopupDetails.file === '' || allergyPopupDetails.file === 'None' || allergyPopupDetails.file === undefined || allergyPopupDetails.file === null ? "" : allergyPopupDetails.file.slice(82) }
                </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Notes</Typography>
              <Typography fontSize={16}>{allergyPopupDetails.note === '' || allergyPopupDetails.note === 'None' || allergyPopupDetails.note === undefined || allergyPopupDetails.note === null ? "" : allergyPopupDetails.note}</Typography>
            </Grid>         
          </Grid>
          </DialogContent>
        </Dialog>
            </div>
      );
    }

    
    function AddAllergy(){
     
      const [allergy, setAllergy] = React.useState('');
      const [allergyCode, setAllergyCode] = React.useState('');
      const [algyList, setAllergyList] = React.useState([]);
      const [reactionType, setReactionType] = React.useState('');
      const [reactionTypeCode, setReactionTypeCode] = React.useState('');
      const [reactionList, setReactionList] = React.useState([]);
      const [severity, setSeverity] = React.useState('');
      const [severityList, setSeverityList] = React.useState([{name:"High"},{name:"Medium"},{name:"Low"}]);
      const [status, setStatus] = React.useState('');
      const [statusList, setStatusList] = React.useState([{name:"Pending"},{name:"Resolved"}]);
      const [doctorName, setDoctorName] = React.useState('');
      const [doctor_UUID, setDoctor_UUID] = React.useState('');
      const [doctorList, setDoctorList] = React.useState([]);
      const [notes, setNotes] = React.useState('');
      const [showMore, setShowMore] = React.useState(false);
      const [startDate, setStartDate] = React.useState('');
      const [endDate, setEndDate] = React.useState('');
      const [filePath,setFilePath] = React.useState('');
      const [uploadedFilePath,setUploadedFilePath] = React.useState('');
      const [file,setFile ] = React.useState('');
      const [fileName,setFileName ] = React.useState('Please Upload a file');
      const [errorText,setErrorText] = React.useState({
        allergy:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        reactionType:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        severity:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        status:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        file:{
          error:false,
          errorText:"Please Upload Prescription File",
          verified:true
        },
        notes:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        doctorName:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        startDate:{
          error:false,
          errorText:"Please Select Start Date",
          verified:true
        },
        endDate:{
          error:false,
          errorText:"Please Select End Date",
          verified:true
        },
        
      })
  
      function handleValidation(){
        if(allergyCode === '' || allergyCode !==''){
          if(allergyCode === ''){
            setErrorText(previous => ({...previous,allergy:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(allergyCode !== ''){
            setErrorText(previous => ({...previous,allergy:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
              
          }
        }
        if(reactionTypeCode === '' || reactionTypeCode !==''){
          if(reactionTypeCode === ''){
            setErrorText(previous => ({...previous,reactionType:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(reactionTypeCode !== ''){
            setErrorText(previous => ({...previous,reactionType:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
              
          }
        }
        if(severity === '' || severity !==''){
          if(severity === ''){
            setErrorText(previous => ({...previous,severity:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(severity !== ''){
            setErrorText(previous => ({...previous,severity:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
              
          }
        }
        if(status === '' || status !==''){
          if(status === ''){
            setErrorText(previous => ({...previous,status:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(status !== ''){
            setErrorText(previous => ({...previous,status:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
              
          }
        }
        if(filePath === "" || filePath !== ""){
          if(filePath === ""){
            setErrorText(previous => ({...previous,file:{error:true,
              errorText:"Please Upload Prescription File",
              verified:false}}))
              return true;
              
          }else if(filePath !== ""){
            setErrorText(previous => ({...previous,file:{error:false,
              errorText:"Please Upload Prescription File",
              verified:true}}))

          }       
      }
      if(notes === "" || notes !== ""){
        if(notes === ""){
          setErrorText(previous => ({...previous,notes:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
            
        }else if(notes !== ""){
          setErrorText(previous => ({...previous,notes:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))

        }       
     }
      if(startDate === '' || startDate !==''){
          if(startDate === ''){
            setErrorText(previous => ({...previous,startDate:{error:true,
              errorText:"Please Select Start Date",
              verified:false}}))
              setShowMore(true)
              return true;
          }else if(startDate !== ''){
            setErrorText(previous => ({...previous,startDate:{error:false,
              errorText:"Please Select Start Date",
              verified:true}}))
          }
        }
      if(endDate === "" || endDate !== ""){
            if(endDate === ""){
              setErrorText(previous => ({...previous,endDate:{error:true,
                errorText:"Please Select End Date",
                verified:false}}))
                setShowMore(true)
                return true;
                
            }else if(endDate !== ""){
              setErrorText(previous => ({...previous,endDate:{error:false,
                errorText:"Please Select End Date",
                verified:true}}))
  
            }       
        }
        
      if(doctor_UUID === '' || doctor_UUID !==''){
        if(doctor_UUID === ''){
          setErrorText(previous => ({...previous,doctorName:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            setShowMore(true)
            return true;
        }else if(doctor_UUID !== ''){
          setErrorText(previous => ({...previous,doctorName:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
            return false
        }
      }
      
      
      console.log("RESULTS............");
      
    }

      const handleAllergyFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    
        console.log('fileObj is', fileObj);
    
        // 👇️ reset file input
        event.target.value = null;
    
        // 👇️ is now empty
        console.log(event.target.files);
    
        // 👇️ can still access file object here
        console.log(fileObj);
        console.log(fileObj.name);
        if(fileObj.type === 'image/png' || fileObj.type === 'image/jpg' 
        || fileObj.type === 'image/jpeg' || fileObj.type === 'application/pdf'){
          fileUpload(fileObj,filePath).then(res =>{
            console.log(res)
            if(res.status === 200){
              setUploadedFilePath(res.path)
              setFile(fileObj)
              setFileName(fileObj.name)
            }else{
              setFile('')
              setFileName('Please Upload a file')
            }
          }
          )
        }else{
          alert("Invalid File Type !! Accepted Files: PNG,JPG,JPEG,PDF")
          setFile('')
          setFileName('Please Upload a file')
        }
        
        
      };
  
      const handleEndDateChange = (newValue) => {
        setEndDate(newValue);
      };
  
      const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
      };


      

    React.useEffect(() => {
      GetMasterSearchData('ALLERGY').then(res =>setAllergyList(res))
      GetMasterSearchData('PROBLEM').then(res =>setReactionList(res))
      getDoctorsList(user.orgID,'a').then(res =>{
        console.log(res)
        if(res.status === 200){
          setDoctorList(res.data.content)
        }
      })
    },[])

      return (
      <div>
        <input
                style={{display: 'none'}}
                ref={inputRef}
                type="file"
                onChange={handleAllergyFileChange}
          />
        <Dialog open={addAllergy} onClose={handleCloseAllergyPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add Allergy</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseAllergyPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'}>Allergy</Typography>
                <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                          setAllergy(newValue.label)
                          setAllergyCode(newValue.typeID)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={algyList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Choose Allergy" 
                      helperText={errorText.allergy.error === true ? errorText.allergy.errorText:""}
                      error={errorText.allergy.error === true ? true : false}
                      autoFocus={errorText.allergy.error === true ? true : false}
                      
                      />}
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Type of Reaction</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                          setReactionType(newValue.label)
                          setReactionTypeCode(newValue.typeID)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={reactionList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Choose Reaction Type"
                      helperText={errorText.reactionType.error === true ? errorText.reactionType.errorText:""}
                      error={errorText.reactionType.error === true ? true : false}
                      autoFocus={errorText.reactionType.error === true ? true : false}
                      />}
                    />
                </FormControl>
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'}>Severity</Typography>
                <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.name){
                          setSeverity(newValue.name)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={severityList}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Choose Severity" 
                      helperText={errorText.severity.error === true ? errorText.severity.errorText:""}
                      error={errorText.severity.error === true ? true : false}
                      autoFocus={errorText.severity.error === true ? true : false}
                      />}
                    />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Status</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.name){
                          setStatus(newValue.name)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={statusList}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Choose Status" 
                      helperText={errorText.status.error === true ? errorText.status.errorText:""}
                      error={errorText.status.error === true ? true : false}
                      autoFocus={errorText.status.error === true ? true : false}
                      />}
                    />
                </FormControl>
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={3} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Choose Files</Typography>
        <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',fontSize:'12px',maxWidth:'100%'}} onClick={()=>{
         setFilePath(`ALLERGY_${patient.uuid}`)
         handleClick()
       }}>
                 Upload
                </Button>
                <Typography fontSize={12} fontWeight={'bold'} paddingTop={2}>Uploaded File Name: {fileName}</Typography>
                {errorText.file.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.file.errorText}</Typography> : <div></div>}
              </Grid>
              <Grid item xs={12} sm={9} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Notes</Typography>
                <TextField
                  required
                  fullWidth
                  value={notes}
                  id="spo2"
                  label="Enter Notes"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setNotes(e.target.value)}}
                  multiline
                  rows={4}
                  helperText={errorText.notes.error === true ? errorText.notes.errorText:""}
                  error={errorText.notes.error === true ? true : false}
                  autoFocus={errorText.notes.error === true ? true : false}
                />
              </Grid>
        </Grid>
        {showMore === true ?<Grid>
          <Grid container spacing={2} paddingTop={3}>
            <Grid item xs={12} sm={6} padding={2}>
                  <Typography fontSize={16} fontWeight={'bold'}>Start Date</Typography>
                  <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Select Date"
                      inputFormat="MM/dd/yyyy"
                      value={startDate}
                      onChange={handleStartDateChange}
                      renderInput={(params) => <TextField {...params} size="small"
                      helperText={errorText.startDate.error === true ? errorText.startDate.errorText:""}
                      error={errorText.startDate.error === true ? true : false}
                      autoFocus={errorText.startDate.error === true ? true : false}
                      />}
                    />
                </LocalizationProvider>
                  </FormControl>
                </Grid>
            <Grid item xs={12} sm={6} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'}>End Date</Typography>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Select Date"
                      inputFormat="MM/dd/yyyy"
                      value={endDate}
                      onChange={handleEndDateChange}
                      renderInput={(params) => <TextField {...params} size="small" 
                      helperText={errorText.endDate.error === true ? errorText.endDate.errorText:""}
                      error={errorText.endDate.error === true ? true : false}
                      autoFocus={errorText.endDate.error === true ? true : false}
                      />}
                    />
                </LocalizationProvider>
                  </FormControl>
                </Grid>
          </Grid>
          <Grid container spacing={2} paddingTop={1} paddingBottom={3}>
            <Grid item xs={12} sm={6} padding={2}>
            <Typography fontSize={16} fontWeight={'bold'}>Treated by</Typography>
                <FormControl fullWidth>
                      <Autocomplete
                        onChange = {(event, newValue) => {if(newValue && newValue.name){
                            setDoctorName(newValue.name)
                            setDoctor_UUID(newValue.uuId)
                        }}}
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={doctorList}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Choose Doctor" 
                        helperText={errorText.doctorName.error === true ? errorText.doctorName.errorText:""}
                        error={errorText.doctorName.error === true ? true : false}
                        autoFocus={errorText.doctorName.error === true ? true : false}
                        />}
                      />
                  </FormControl>
                </Grid>
            <Grid item xs={12} sm={6} padding={2}>
                
                </Grid>
          </Grid>
          </Grid>
           :<div></div>}
        <Grid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={12}>
          <Button variant="text" sx={{textDecoration:'underline'}} onClick={() =>{setShowMore(!showMore)}}>{showMore === false ? "More +" : "Less"}</Button>
          </Grid>
        </Grid>
        <Divider sx={{bgcolor:'#651fff',height:5,marginTop:1,width:'100%'}}/>  
          </DialogContent>
          <DialogActions>
            
            {/* <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={() =>{handleVitalSave(height,weight,temp,spo2,pulse,systolic,diastolic,grbs,hunit,wunit,tempUnit,respiratoryRate)}}>
                      Save
                    </Button> */}
                    <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={() =>{
                      var validate = handleValidation()
                      if(validate === false){
                        createNewAllergy(user.orgID,patient.uuid,allergyCode,allergy,status,reactionTypeCode,
                          reactionType,startDate,endDate,doctorName,severity,uploadedFilePath,notes).then(res =>{
                            console.log(res)
                            if(res.status === 200){
                              toast.success(res.message)
                              handleCloseAllergyPopup()
                              getAllergyList(patient.uuid).then(res =>{
                                console.log(res)
                                if(res.status === 200){
                                  setAllergyListData(res.data)
                                }else{
                                  console.log("error")
                                }
                              })
                            }else{
                              toast.error("Error Occured")
                              handleCloseAllergyPopup()
                              getAllergyList(patient.uuid).then(res =>{
                                console.log(res)
                                if(res.status === 200){
                                  setAllergyListData(res.data)
                                }else{
                                  console.log("error")
                                }
                              })
                            }
                          })
                      }else{
                        toast.error("Please check the Input Fields")
                      }
                      }}>
                      Add Allergy
                    </Button>
    
                    
          </DialogActions>
        </Dialog>
            </div>
      );
    }


    function ShowMedicalConditionDetails(){

      console.log(mcPopupDetails)

      return (
      <div>
        <Dialog open={showMC} onClose={handleCloseMCDetailsPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Medical Condition</DialogTitle>
            <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',position:'absolute',right:72,top:8}}
              onClick={()=>{
                fileDownload(mcPopupDetails.uploadfilename)
              }}
            >
                  Download
                </Button>
            <IconButton
              aria-label="close"
              onClick={handleCloseMCDetailsPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>    
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Doctor Name</Typography>
              <Typography fontSize={16}>Dr. {mcPopupDetails.doctorname}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Condition Name</Typography>
              <Typography fontSize={16}>{mcPopupDetails.medicalcondition}</Typography>
            </Grid> 
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Source</Typography>
              <Typography fontSize={16}>{mcPopupDetails.source}</Typography>
            </Grid>        
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Start Date</Typography>
              <Typography fontSize={16}>{mcPopupDetails.startdate.slice(0,10)}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>End Date</Typography>
              <Typography fontSize={16}>{mcPopupDetails.enddate.slice(0,10)}</Typography>
            </Grid>     
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={6} md={6} padding={2}>
            <Button variant="outlined" endIcon={<KeyboardArrowDownIcon/>}>
                  {mcPopupDetails.uploadfilename.slice(81)}
                </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Notes</Typography>
              <Typography fontSize={16}>{mcPopupDetails.note}</Typography>
            </Grid>         
          </Grid>
          </DialogContent>
        </Dialog>
            </div>
      );
    }

    function ShowLabReportDetails(){

      console.log(labPopupDetails)
      const [disableDownload,setDisableDownload] = React.useState(false)
      
      React.useEffect(() =>{
        if(labPopupDetails.file === 'None' || labPopupDetails.file === ''){
          setDisableDownload(true)
        }
      },[])
      return (
      <div>
        <Dialog open={showLabReport} onClose={handleCloseLabDetailsPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Report {labPopupDetails.id}</DialogTitle>
            <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',position:'absolute',right:72,top:8}} disabled={disableDownload}
              onClick={()=>{
                fileDownload(labPopupDetails.file)
              }}
            >
                  Download
                </Button>
            <IconButton
              aria-label="close"
              onClick={handleCloseLabDetailsPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>    
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Patient Name</Typography>
              <Typography fontSize={16}>{patient.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Doctor Name</Typography>
              <Typography fontSize={16}>Dr. {labPopupDetails.doctorname}</Typography>
            </Grid> 
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Package Name</Typography>
              <Typography fontSize={16}>{labPopupDetails.packagename}</Typography>
            </Grid>        
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Test Type</Typography>
              <Typography fontSize={16}>{labPopupDetails.test_type}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>End Date</Typography>
              <Typography fontSize={16}>{(labPopupDetails.testdate === null || labPopupDetails.testdate === '') ? '' : labPopupDetails.testdate.slice(0,10)} </Typography>
            </Grid>     
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
            {disableDownload === false ? <Grid item xs={12} sm={6} md={6} padding={2}>
            <Button variant="outlined" endIcon={<KeyboardArrowDownIcon/>}>
                  {labPopupDetails.file.slice(81)}
                </Button> 
            </Grid>: <div></div>}
            <Grid item xs={12} sm={6} md={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Notes</Typography>
              <Typography fontSize={16}>{labPopupDetails.note}</Typography>
            </Grid>         
          </Grid>
          </DialogContent>
        </Dialog>
            </div>
      );
    }
    function EditSymptomsPopup(){

      const Input = styled(MuiInput)`
      width: 42px;
    `;
    const [disableButton, setDisableButton] = React.useState(true)
    const [healthIndicators, setHealthIndicatorList] = React.useState([
      {label:"Pain",value:0},
      {label:"Tiredness",value:0},
      {label:"Drowsiness",value:0},
      {label:"Nausea",value:0},
      {label:"Appetite",value:0},
      {label:"Breathing",value:0},
      {label:"Anxiety",value:0},
      {label:"General Wellness",value:0},
      {label:"Depress",value:0},
    
      ]);
      console.log("health Details",healthDetails)
    React.useEffect(()=>{
      let symptomArr = [...healthIndicators]
      symptomArr[0].value = parseInt(healthDetails.pain)
      symptomArr[1].value = parseInt(healthDetails.tiredness)
      symptomArr[2].value = parseInt(healthDetails.drowsiness)
      symptomArr[3].value = parseInt(healthDetails.nausea)
      symptomArr[4].value = parseInt(healthDetails.appetite)
      symptomArr[5].value = parseInt(healthDetails.breathing)
      symptomArr[6].value = parseInt(healthDetails.anxiety)
      symptomArr[7].value = parseInt(healthDetails.generalwellness)
      symptomArr[8].value = parseInt(healthDetails.depress)
      setHealthIndicatorList(symptomArr)
    },[])

      return (
      <div>
        <Dialog open={editSymptom} onClose={handleEditSymptomClosePopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Health Indicator {labPopupDetails.id}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleEditSymptomClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>    
          <Typography fontSize={18} fontWeight={'bold'} align='right' paddingRight={15}>Less----------Medium----------Worst</Typography>
              {healthIndicators.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...healthIndicators];
                    newData[index].value = Number(e.target.value);
                    setHealthIndicatorList(newData)
                    setDisableButton(false)           
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0} 
                  />
                </Grid>
                <Grid item>
                  <Input
                     value={symptom.value}
                     size="small"
                     onChange={(e) =>{
                      let newData = [...healthIndicators];
                      newData[index].value = Number(e.target.value);
                      setHealthIndicatorList(newData)
                    }}
                     inputProps={{
                       step: 1,
                       min: 0,
                       max: 10,
                       type: 'number',
                       'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))} 
          </DialogContent>
          <DialogActions>
            
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} 
            disabled={disableButton}
            onClick={() =>{
              // handleSymptomsClosePopup()
              console.log(healthIndicators)
              console.log(healthIndicators[0].value)
              updateHealthIndicator(healthDetails.id,user.orgID,patient.uuid,
                healthIndicators[0].value,
                healthIndicators[1].value,
                healthIndicators[2].value,
                healthIndicators[3].value,
                healthIndicators[4].value,
                healthIndicators[5].value,
                healthIndicators[6].value,
                healthIndicators[7].value,
                healthIndicators[8].value).then(res =>{
                  console.log(res)
                  if(res.status === 200){
                    toast.success(res.message)
                    getHealthIndicatorList(user.orgID,patient.uuid).then(res =>{
                      console.log(res)
                      setHealthIndicatorListData(res)
                      handleEditSymptomClosePopup()
                      // if(res.status === 200){
                      //   setHealthIndicatorListData(res.data)
                      //   handleSymptomsClosePopup()
                      // }else{
                      //   console.log(res)
                      //   handleSymptomsClosePopup()
                      // }
                      
                    })
                  }else{
                    toast.error("Error Occurred")
                    handleEditSymptomClosePopup()
                  }
                })
              }}>
                      Update
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }
    function ShowPrescriptionDetails(){
      const [disableDownload,setDisableDownload] = React.useState(false)
      console.log(prescriptionPopupDetails)
      React.useEffect(() =>{
        if(prescriptionPopupDetails.filename === 'None' || prescriptionPopupDetails.filename === ''){
          setDisableDownload(true)
        }
      },[])
      return (
      <div>
        <Dialog open={showPrescription} onClose={handleClosePrescriptionDetailsPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Prescription {prescriptionPopupDetails.createdOn.slice(0,10)}</DialogTitle>
            <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',position:'absolute',right:72,top:8}} disabled={disableDownload}
              onClick={()=>{
                fileDownload(prescriptionPopupDetails.filename)
              }}
            >
                  Download
                </Button>
            <IconButton
              aria-label="close"
              onClick={handleClosePrescriptionDetailsPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>    
          <Grid container spacing={3} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Patient Name</Typography>
              <Typography fontSize={16}>{patient.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Doctor Name</Typography>
              <Typography fontSize={16}>Dr. {prescriptionPopupDetails.doctorname}</Typography>
            </Grid> 
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Medical Condition</Typography>
              <Typography fontSize={16}>{prescriptionPopupDetails.medicalconditionid.label}</Typography>
            </Grid>        
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Consultation Date</Typography>
              <Typography fontSize={16}>{prescriptionPopupDetails.createdOn.slice(0,10)}</Typography>
            </Grid>     
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
          {disableDownload === false ? <Grid item xs={12} sm={6} md={6} padding={2}>
            <Button variant="outlined" endIcon={<KeyboardArrowDownIcon/>}>
                  {prescriptionPopupDetails.filename.slice(81)}
                </Button> 
            </Grid>: <div></div>}
            <Grid item xs={12} sm={6} md={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Notes</Typography>
              <Typography fontSize={16}>{prescriptionPopupDetails.note}</Typography>
            </Grid>
                     
          </Grid>
          <MedicineListComponent/>
          </DialogContent>
        </Dialog>
            </div>
      );
    }
    
    function AddMedicalCondition(){
     
      const [medicalCondition, setMedicalCondition] = React.useState('');
      const [medicalCList, setMedicalCList] = React.useState([]);
      const [doctorName, setDoctorName] = React.useState('');
      const [doctor_UUID, setDoctor_UUID] = React.useState('');
      const [doctorList, setDoctorList] = React.useState([]);
      const [notes, setNotes] = React.useState('');
      const [showMore, setShowMore] = React.useState(false);
      const [startDate, setStartDate] = React.useState('');
      const [endDate, setEndDate] = React.useState('');
      const [filePath,setFilePath] = React.useState('');
      const [uploadedFilePath,setUploadedFilePath] = React.useState('');
      const [file,setFile ] = React.useState('');
      const [fileName,setFileName ] = React.useState('Please Upload a file');
      const [errorText,setErrorText] = React.useState({
        doctorId:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        medicalCondition:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        file:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        notes:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        startDate:{
          error:false,
          errorText:"Please Select Start Date",
          verified:true
        },
        endDate:{
          error:false,
          errorText:"Please Select End Date",
          verified:true
        },
      })
  
      function handleValidation(){
        if(doctor_UUID === '' || doctor_UUID !==''){
          if(doctor_UUID === ''){
            setErrorText(previous => ({...previous,doctorId:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(doctor_UUID !== ''){
            setErrorText(previous => ({...previous,doctorId:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }   
      if(medicalCondition === '' || medicalCondition !==''){
          if(medicalCondition === ''){
            setErrorText(previous => ({...previous,medicalCondition:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(medicalCondition !== ''){
            setErrorText(previous => ({...previous,medicalCondition:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }
        if(filePath === "" || filePath !== ""){
          if(filePath === ""){
            setErrorText(previous => ({...previous,file:{error:true,
              errorText:"Please Upload a file",
              verified:false}}))
              return true;
              
          }else if(filePath !== ""){
            setErrorText(previous => ({...previous,file:{error:false,
              errorText:"Please Upload a file",
              verified:true}}))
              
          }       
      }

        if(notes === "" || notes !== ""){
          if(notes === ""){
            setErrorText(previous => ({...previous,notes:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(notes !== ""){
            setErrorText(previous => ({...previous,notes:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
          }       
      }
      if(startDate === "" || startDate !== ""){
        if(startDate === ""){
          setErrorText(previous => ({...previous,startDate:{error:true,
            errorText:"Please Select Start Date",
            verified:false}}))
            return true;
            
        }else if(startDate !== ""){
          setErrorText(previous => ({...previous,startDate:{error:false,
            errorText:"Please Select Start Date",
            verified:true}}))
            
        }       
    }
    if(endDate === "" || endDate !== ""){
      if(endDate === ""){
        setErrorText(previous => ({...previous,endDate:{error:true,
          errorText:"Please Select End Date",
          verified:false}}))
          return true;
          
      }else if(endDate !== ""){
        setErrorText(previous => ({...previous,endDate:{error:false,
          errorText:"Please Select End Date",
          verified:true}}))
          return false
      }       
  }
      
      console.log("RESULTS............");
      
      }

      const handleMCFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    
        console.log('fileObj is', fileObj);
    
        // 👇️ reset file input
        event.target.value = null;
    
        // 👇️ is now empty
        console.log(event.target.files);
    
        // 👇️ can still access file object here
        console.log(fileObj);
        console.log(fileObj.name);
        if(fileObj.type === 'image/png' || fileObj.type === 'image/jpg' 
        || fileObj.type === 'image/jpeg' || fileObj.type === 'application/pdf'){
          fileUpload(fileObj,filePath).then(res =>{
            console.log(res)
            if(res.status === 200){
              setUploadedFilePath(res.path)
              setFile(fileObj)
              setFileName(fileObj.name)
            }else{
              setFile('')
              setFileName('Please Upload a file')
            }
          }
          )
        }else{
          alert("Invalid File Type !! Accepted Files: PNG,JPG,JPEG,PDF")
          setFile('')
          setFileName('Please Upload a file')
        }
        
        
      };
  
      const handleEndDateChange = (newValue) => {
        setEndDate(newValue);
      };
  
      const handleStartDateChange = (newValue) => {
        setStartDate(newValue);
      };


    React.useEffect(() => {
      GetMasterSearchData('MEDICAL_CONDITION').then(res =>setMedicalCList(res))
      getDoctorsList(user.orgID,'a').then(res =>{
        console.log(res)
        if(res.status === 200){
          setDoctorList(res.data.content)
        }
      })
    },[])
      return (
      <div>
      <input
        style={{display: 'none'}}
        ref={inputRef}
        type="file"
        onChange={handleMCFileChange}
        />
        <Dialog open={addMC} onClose={handleCloseMCPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add Medical Condition</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseMCPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Treated by</Typography>
                <FormControl fullWidth>
                      <Autocomplete
                        onChange = {(event, newValue) => {if(newValue && newValue.name){
                            setDoctorName(newValue.name)
                            setDoctor_UUID(newValue.uuId)
                        }}}
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={doctorList}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} label="Choose Doctor"
                        helperText={errorText.doctorId.error === true ? errorText.doctorId.errorText:""}
                        error={errorText.doctorId.error === true ? true : false}
                        autoFocus={errorText.doctorId.error === true ? true : false}  
                        />}
                      />
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Medical Condition</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setMedicalCondition(newValue.label)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={medicalCList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Choose Medical Condition"
                        helperText={errorText.medicalCondition.error === true ? errorText.medicalCondition.errorText:""}
                        error={errorText.medicalCondition.error === true ? true : false}
                        autoFocus={errorText.medicalCondition.error === true ? true : false}  
                      />}
                    />
                </FormControl>
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={3} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Choose Files</Typography>
        <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',fontSize:'12px',maxWidth:'100%'}} onClick={()=>{
         setFilePath(`MEDICAL_CONDITION_${patient.uuid}`)
         handleClick()
       }}>
                 Upload
                </Button>
                <Typography fontSize={12} fontWeight={'bold'} paddingTop={2}>Uploaded File Name: {fileName}</Typography>
                {errorText.file.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.file.errorText}</Typography> : <div></div>}
              </Grid>
              <Grid item xs={12} sm={9} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Notes</Typography>
                <TextField
                  required
                  fullWidth
                  value={notes}
                  id="spo2"
                  label="Enter Notes"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setNotes(e.target.value)}}
                  multiline
                  rows={4}
                  helperText={errorText.notes.error === true ? errorText.notes.errorText:""}
                  error={errorText.notes.error === true ? true : false}
                  autoFocus={errorText.notes.error === true ? true : false} 
                />
              </Grid>
        </Grid>
        <Grid>
          <Grid container spacing={2} paddingTop={3}>
            <Grid item xs={12} sm={6} padding={2}>
                  <Typography fontSize={16} fontWeight={'bold'}>Start Date</Typography>
                  <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Select Date"
                      inputFormat="MM/dd/yyyy"
                      value={startDate}
                      onChange={handleStartDateChange}
                      renderInput={(params) => <TextField {...params} size="small"
                      helperText={errorText.startDate.error === true ? errorText.startDate.errorText:""}
                        error={errorText.startDate.error === true ? true : false}
                        autoFocus={errorText.startDate.error === true ? true : false}
                      />}
                    />
                </LocalizationProvider>
                  </FormControl>
                </Grid>
            <Grid item xs={12} sm={6} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'}>End Date</Typography>
                <FormControl fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Select Date"
                      inputFormat="MM/dd/yyyy"
                      value={endDate}
                      onChange={handleEndDateChange}
                      renderInput={(params) => <TextField {...params} size="small" 
                      helperText={errorText.endDate.error === true ? errorText.endDate.errorText:""}
                      error={errorText.endDate.error === true ? true : false}
                      autoFocus={errorText.endDate.error === true ? true : false}
                      />}
                    />
                </LocalizationProvider>
                  </FormControl>
                </Grid>
          </Grid>
          </Grid>
        
        <Divider sx={{bgcolor:'#651fff',height:5,marginTop:1,width:'100%'}}/>  
          </DialogContent>
          <DialogActions>
                    <Button variant="contained" sx={{width:'40%',bgcolor:'#651fff'}} onClick={() =>{
                      let verify = handleValidation();
                      if(verify === false){
                        createNewMedicalCondition(user.orgID,doctor_UUID,doctorName,patient.uuid,medicalCondition,startDate,endDate,uploadedFilePath,notes).then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            toast.success(res.message)
                            getMedicalConditionList(patient.uuid).then(res =>{
                              console.log(res)
                              if(res.status === 200){
                                setMCListData(res.data)
                              }else{
                                console.log("error")
                                setMCListData([])
                              }
                            })
                            handleCloseMCPopup()
                          }else{
                            toast.error("Error Occurred")
                            getMedicalConditionList(patient.uuid)
                            handleCloseMCPopup()
                          }
                        })
                      }else{
                        toast.error("Please check the Input Fields")
                      }
                      
                    
                    }}>
                      Add Medical Condition
                    </Button>
    
                    
          </DialogActions>
        </Dialog>
            </div>
      );
    }

    function FamilyMember(){
      const [memberList, setMemberList] = React.useState([]);
      console.log("Members List",memberList)
      React.useEffect(() => {
        getFamilyMemberList(patient.uuid).then(res => {
          console.log("Family Members List",res)
          if(res.status === 200){
            setMemberList(res.data)
          }else{
            console.log("errror occurred")
            setMemberList([])
          }   
        })
      },[])

      return(
        <Grid item xs={10} md={10} lg={10}>
            <Paper sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={9} >
                <Typography color={colors.grey[500]} marginTop={1}>Manage Family Member</Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() => {setDComponent('AddFamilyMember')}} sx={{bgcolor:'#696CFF'}}>
                  Add Family Member
                </Button>
                </Grid>
                
              </Grid>  
                </Paper>
                <Box sx={{maxHeight:'70vh',overflow:'auto'}}>
                { memberList.length !== 0 ? memberList.map((member,index) =>(
                  <Paper sx={{marginTop:2,padding:2}} key={index}>
                    <Grid container spacing={3} >
                        <Grid item xs={12} md={0.75}>
                          <Box width={30} height={30} sx={{bgcolor:'#651fff'}}>
                          <Avatar src={''} sizes='small' variant='square'></Avatar>
                          </Box>
                        </Grid>
                        <Grid item xs={12} md={7.25}>
                            <Typography color={colors.grey[900]} fontWeight={'bold'}>{member.personDto.givenName} {member.personDto.middleName}</Typography>
                            <Typography color={'blue'} fontSize={12} fontStyle={'italic'}>{member.relation.label} . {member.personDto.email}</Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                        <Button variant="outlined" sx={{width:'80%'}} 
                        onClick={()=>{
                          confirmAlert({
                            title: "Are you Sure you want to Delete Family Member",
                            buttons: [
                              {
                                label: 'Yes',
                                onClick: () => {
                                  deleteFamilyMember(member.personDto.personId).then(res =>{
                                    console.log(res)
                                    if(res.status === 200){
                                      toast.success(res.message, {
                                        position: "top-right",
                                        autoClose: 4000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                        });
                                        getFamilyMemberList(patient.uuid).then(res => {
                                          console.log("Family Members List",res)
                                          if(res.status === 200){
                                            setMemberList(res.data)
                                          }else{
                                            console.log("errror occurred")
                                            setMemberList([])
                                          } 
                                        })
                                    }
                                  })
                                }
                              },
                              {
                                label: 'NO',
                                onClick: () => {
                                  getFamilyMemberList(patient.uuid).then(res => {
                                    console.log("Family Members List",res)
                                    if(res.status === 200){
                                      setMemberList(res.data)
                                    }else{
                                      console.log("errror occurred")
                                      setMemberList([])
                                    } 
                                  })
                                }
                              },
                            ],
                            closeOnEscape:false,
                            closeOnClickOutside:false,
                          })
                          }}>
                          Delete
                        </Button>
                        </Grid>
                        <Grid item xs={12} md={2}>
                        <Button variant="contained" sx={{width:'100%'}} onClick={()=>{
                          setDComponent('MemberDetails')
                          setMemberID(member.personDto.personId)
                          }}>
                          Edit
                        </Button>
                        </Grid>
                     </Grid>
                  </Paper> 
          )):<Paper sx={{margin:5,padding:5}}>
          <Typography align='center'>No Data Available</Typography>
            </Paper>}
                </Box>
          
        </Grid>
      );
    }

    function AddFamilyMember(){
    
      function BasicDetails(){
        const {getRelationshipList,GetGenderDetails,addFamilyMember,getFamilyMemberList} = AdminService();
        const [genderList, setGenderList] = React.useState([]);
        const [gender, setGender] = React.useState('');
        const [relationshipList, setRelationshipList] = React.useState([]);
        const [relation, setRelation] = React.useState('');
        const [firstName, setFirstName] = React.useState('');
        const [lastName, setLastName] = React.useState('');
        const [bloodGroup, setBloodGroup] = React.useState('');
        const [patientID, setPatientID] = React.useState('');
        const [email, setEmail] = React.useState('');
        const [contactNumber, setContactNumber] = React.useState('');
        const [date, setDate] = React.useState('');
        const [bgList, setBGList] = React.useState([]);
        const [errorText,setErrorText] = React.useState({
          firstName:{
            error:false,
            errorText:"Field cant be empty",
            verified:true
          },
          lastName:{
            error:false,
            errorText:"Field cant be empty",
            verified:true
          },
          gender:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          },
          dob:{
            error:false,
            errorText:"Select Date of Birth",
            verified:true
          },
          bg:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          },
          email:{
            error:false,
            errorText:"Field can't be empty",
            verified:true
          },
          contactNumber:{
            error:false,
            errorText:"Field can't be empty",
            verified:true
          },
          relation:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          }, 
    
        })
    
        function handleValidation(){
        if(firstName === "" || firstName !== ""){
              if(firstName === ""){
                setErrorText(previous => ({...previous,firstName:{error:true,
                  errorText:"Field can't be empty",
                  verified:false}}))
                  return true;
                  
              }else if(containsSpecialChars(firstName) === true){
                setErrorText(previous => ({...previous,firstName:{error:true,
                  errorText:"Field cannot contain Special Characters",
                  verified:false}}))
                  return true;
              }else if(containsOnlyNumbers(firstName) === true){
                setErrorText(previous => ({...previous,firstName:{error:true,
                  errorText:"Field cannot contain Numbers",
                  verified:false}}))
                  return true;
              }else if(firstName !== ""){
                setErrorText(previous => ({...previous,firstName:{error:false,
                  errorText:"Field can't be empty",
                  verified:true}}))
    
              }       
          }
        if(lastName === "" || lastName !== ""){
            if(lastName === ""){
              setErrorText(previous => ({...previous,lastName:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(containsSpecialChars(lastName) === true){
              setErrorText(previous => ({...previous,lastName:{error:true,
                errorText:"Field cannot contain Special Characters",
                verified:false}}))
                return true;
            }else if(containsOnlyNumbers(lastName) === true){
              setErrorText(previous => ({...previous,lastName:{error:true,
                errorText:"Field cannot contain Numbers",
                verified:false}}))
                return true;
            }else if(lastName !== ""){
              setErrorText(previous => ({...previous,lastName:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
              
            }         
          }
          if(date === '' || date !==''){
            if(date === ''){
              setErrorText(previous => ({...previous,dob:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(date !== ''){
              setErrorText(previous => ({...previous,dob:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(gender === '' || gender !==''){
            if(gender === ''){
              setErrorText(previous => ({...previous,gender:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(gender !== ''){
              setErrorText(previous => ({...previous,gender:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        
        if(bloodGroup === '' || bloodGroup !==''){
            if(bloodGroup === ''){
              setErrorText(previous => ({...previous,bg:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(bloodGroup !== ''){
              setErrorText(previous => ({...previous,bg:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
          if(relation === '' || relation !==''){
            if(relation === ''){
              setErrorText(previous => ({...previous,relation:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(relation !== ''){
              setErrorText(previous => ({...previous,relation:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        
        if(email === "" || email !== ""){
            if(email === ""){
              setErrorText(previous => ({...previous,email:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(containsOnlyNumbers(email) === true){
              setErrorText(previous => ({...previous,email:{error:true,
                errorText:"Field cannot contain only Numbers",
                verified:false}}))
                return true;
            }else if(validateEmail(email) === false){
              setErrorText(previous => ({...previous,email:{error:true,
                errorText:"Email is not valid should contain '@' and '.'",
                verified:false}}))
                return true;
            }else if(email !== ""){
              setErrorText(previous => ({...previous,email:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
                
            }        
          }
          if(contactNumber === "" || contactNumber !== ""){
            if(contactNumber === ""){
              setErrorText(previous => ({...previous,contactNumber:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(contactNumber.length < 10){
              setErrorText(previous => ({...previous,contactNumber:{error:true,
                errorText:"Minimum length should be 10 digits",
                verified:false}}))
                return true;
            }else if(containsSpecialChars(contactNumber) === true){
              setErrorText(previous => ({...previous,contactNumber:{error:true,
                errorText:"Field cannot contain Special Characters",
                verified:false}}))
                return true;
            }else if(containsOnlyNumbers(contactNumber) === true && contactNumber !== ""){
              setErrorText(previous => ({...previous,contactNumber:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
                return false;
            }       
          }
        
        console.log("RESULTS............");
        
      }
        console.log("relation value is",relation)
        console.log("gender value is",gender)
        console.log("date is",date)
          const handleDateChange = (newDate) => {
              setDate(dayjs(newDate).format('YYYY/MM/DD'));
          };
  
          React.useEffect(() => {
            GetMasterData('BG').then(res => setBGList(res))
            GetGenderDetails().then(res => setGenderList(res));
            getRelationshipList().then(res => setRelationshipList(res.content))
          },[])
        
        return(
        <Grid item xs={12} md={12} lg={12}>
                    <Paper>
                    <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>BASIC DETAILS</Typography>
                    <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>First Name</Typography>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="Enter First Name"
                    size='small'
                    value={firstName}
                    onChange={(e)=>{setFirstName(e.target.value)}}
                    helperText={errorText.firstName.error === true ? errorText.firstName.errorText:""}
                    error={errorText.firstName.error === true ? true : false}
                    autoFocus={errorText.firstName.error === true ? true : false}
                  />
                </Grid>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Last Name</Typography>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="Enter Last Name"
                    size='small'
                    value={lastName}
                    onChange={(e)=>{setLastName(e.target.value)}}
                    helperText={errorText.lastName.error === true ? errorText.lastName.errorText:""}
                    error={errorText.lastName.error === true ? true : false}
                    autoFocus={errorText.lastName.error === true ? true : false}
                  />
                </Grid>
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={12} md={5} margin={2}>
          <Typography color={colors.grey[900]} fontWeight={'bold'}>Date of Birth</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Date of Birth"
                      inputFormat="yyyy/MM/dd"
                      value={date}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField size='small' fullWidth {...params} 
                      helperText={errorText.dob.error === true ? errorText.dob.errorText:""}
                      error={errorText.dob.error === true ? true : false}
                      autoFocus={errorText.dob.error === true ? true : false}
                      />}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Gender</Typography>
              <FormControl fullWidth>
                      <Autocomplete
                        onChange = {(event, newValue) => {if(newValue && newValue.genderCode){
                            setGender(newValue.genderCode)
                        }}}
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={genderList}
                        getOptionLabel={(option) => option.genderName}
                        renderInput={(params) => <TextField {...params} label="Select Gender" 
                        helperText={errorText.gender.error === true ? errorText.gender.errorText:""}
                        error={errorText.gender.error === true ? true : false}
                        autoFocus={errorText.gender.error === true ? true : false}
                        />}
                      />
                    </FormControl>
                </Grid>
          </Grid>
          <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Blood Group</Typography>
                <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setBloodGroup(newValue.typeID)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={bgList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select Blood Group"
                      helperText={errorText.bg.error === true ? errorText.bg.errorText:""}
                      error={errorText.bg.error === true ? true : false}
                      autoFocus={errorText.bg.error === true ? true : false}
                    />}
                  />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Relationship</Typography>
                <FormControl fullWidth>
                      <Autocomplete
                        onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                            setRelation(newValue.typeID)
                        }}}
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={relationshipList}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField {...params} label="Select Relationship"
                        helperText={errorText.relation.error === true ? errorText.relation.errorText:""}
                        error={errorText.relation.error === true ? true : false}
                        autoFocus={errorText.relation.error === true ? true : false}
                        />}
                      />
                    </FormControl>
                </Grid>
          </Grid>
          <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Patient ID</Typography>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="Enter Patient ID"
                    size='small'
                    value={patient.uuid}
                    onChange={(e)=>{setPatientID(e.target.value)}}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={5} margin={2}>
                
                </Grid>
          </Grid>
          <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Email</Typography>
                  <TextField
                    autoComplete="given-name"
                    name="bloodgroup"
                    required
                    fullWidth
                    id="firstName"
                    label="Enter Email"
                    size='small'
                    value={email}
                    onChange={(e)=>{setEmail(e.target.value)}}
                    helperText={errorText.email.error === true ? errorText.email.errorText:""}
                    error={errorText.email.error === true ? true : false}
                    autoFocus={errorText.email.error === true ? true : false}
                  />
                </Grid>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Contact Number</Typography>
                <TextField
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                      autoComplete="given-name"
                      name="mobilenum"
                      required
                      fullWidth
                      id="mobilenum"
                      label="Enter Mobile Number"
                      size='small'
                      type="number"
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                      }}
                      helperText={errorText.contactNumber.error === true ? errorText.contactNumber.errorText:""}
                      error={errorText.contactNumber.error === true ? true : false}
                      autoFocus={errorText.contactNumber.error === true ? true : false}
                    />
                </Grid>
          </Grid>
                    </Paper> 
                    <Paper sx={{marginTop:1}}>
                  <Grid item xs={12} md={12} margin={2}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                        onClick={()=>{
                          var validation = handleValidation();
                          if(validation === false){
                            console.log(firstName,lastName,gender,email,date,relation,patient.uuid,bloodGroup,contactNumber)
                            addFamilyMember(firstName,lastName,gender,email,date,relation,patient.uuid,bloodGroup,contactNumber).then(res =>{
                              console.log(res)
                              if(res.status === 200){
                                  toast.success(res.message, {
                                    position: "top-right",
                                    autoClose: 4000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                  setDComponent('FamilyMember');
                              }else{
                                toast.error("Error Occurred", {
                                  position: "top-right",
                                  autoClose: 4000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                  });
                                  setDComponent('FamilyMember');
                              }
                            }
                            )
                          }else{
                            toast.error("Please fill Mandatory Fields", {
                              position: "top-right",
                              autoClose: 4000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                              });
                          }
                          
                          
                        }}
                      >
                        Save
                      </Button>
                </Grid>
                  </Paper>
            </Grid>
        );
      }
      return(
        <Grid item xs={10} md={10} lg={10}>
          <Typography padding={0.5} fontWeight={'bold'}>Family Registration</Typography>
            <BasicDetails/>
              </Grid>
      );
    }
    function MemberDetailandUpdate(){
    
      function BasicDetails(){
        const {getRelationshipList,GetGenderDetails,addFamilyMember,getFamilyMemberList} = AdminService();
        const [genderList, setGenderList] = React.useState([]);
        const [gender, setGender] = React.useState('');
        const [genderCode, setGenderCode] = React.useState('');
        const [relationshipList, setRelationshipList] = React.useState([]);
        const [relation, setRelation] = React.useState('');
        const [relationCode, setRelationCode] = React.useState('');
        const [firstName, setFirstName] = React.useState('');
        const [lastName, setLastName] = React.useState('');
        const [bloodGroup, setBloodGroup] = React.useState('');
        const [bloodGroupCode, setBloodGroupCode] = React.useState('');
        const [patientID, setPatientID] = React.useState('');
        const [email, setEmail] = React.useState('');
        const [contactNumber, setContactNumber] = React.useState('');
        const [date, setDate] = React.useState('');
        const [bgList, setBGList] = React.useState([]);
        const [errorText,setErrorText] = React.useState({
          firstName:{
            error:false,
            errorText:"Field cant be empty",
            verified:true
          },
          lastName:{
            error:false,
            errorText:"Field cant be empty",
            verified:true
          },
          gender:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          },
          dob:{
            error:false,
            errorText:"Select Date of Birth",
            verified:true
          },
          bg:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          },
          email:{
            error:false,
            errorText:"Field can't be empty",
            verified:true
          },
          contactNumber:{
            error:false,
            errorText:"Field can't be empty",
            verified:true
          },
          relation:{
            error:false,
            errorText:"Select an option from DropDown",
            verified:true
          }, 
    
        })
    
        function handleValidation(){
        if(firstName === "" || firstName !== ""){
              if(firstName === ""){
                setErrorText(previous => ({...previous,firstName:{error:true,
                  errorText:"Field can't be empty",
                  verified:false}}))
                  return true;
                  
              }else if(containsSpecialChars(firstName) === true){
                setErrorText(previous => ({...previous,firstName:{error:true,
                  errorText:"Field cannot contain Special Characters",
                  verified:false}}))
                  return true;
              }else if(containsOnlyNumbers(firstName) === true){
                setErrorText(previous => ({...previous,firstName:{error:true,
                  errorText:"Field cannot contain Numbers",
                  verified:false}}))
                  return true;
              }else if(firstName !== ""){
                setErrorText(previous => ({...previous,firstName:{error:false,
                  errorText:"Field can't be empty",
                  verified:true}}))
    
              }       
          }
        if(lastName === "" || lastName !== ""){
            if(lastName === ""){
              setErrorText(previous => ({...previous,lastName:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(containsSpecialChars(lastName) === true){
              setErrorText(previous => ({...previous,lastName:{error:true,
                errorText:"Field cannot contain Special Characters",
                verified:false}}))
                return true;
            }else if(containsOnlyNumbers(lastName) === true){
              setErrorText(previous => ({...previous,lastName:{error:true,
                errorText:"Field cannot contain Numbers",
                verified:false}}))
                return true;
            }else if(lastName !== ""){
              setErrorText(previous => ({...previous,lastName:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
              
            }         
          }

          if(date === '' || date !==''){
            if(date === ''){
              setErrorText(previous => ({...previous,dob:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(date !== ''){
              setErrorText(previous => ({...previous,dob:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(gender === '' || gender !==''){
            if(gender === ''){
              setErrorText(previous => ({...previous,gender:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(gender !== ''){
              setErrorText(previous => ({...previous,gender:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        
        if(bloodGroup === '' || bloodGroup !==''){
            if(bloodGroup === ''){
              setErrorText(previous => ({...previous,bg:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(bloodGroup !== ''){
              setErrorText(previous => ({...previous,bg:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
          if(relation === '' || relation !==''){
            if(relation === ''){
              setErrorText(previous => ({...previous,relation:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(relation !== ''){
              setErrorText(previous => ({...previous,relation:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        
        if(email === "" || email !== ""){
            if(email === ""){
              setErrorText(previous => ({...previous,email:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(containsOnlyNumbers(email) === true){
              setErrorText(previous => ({...previous,email:{error:true,
                errorText:"Field cannot contain only Numbers",
                verified:false}}))
                return true;
            }else if(validateEmail(email) === false){
              setErrorText(previous => ({...previous,email:{error:true,
                errorText:"Email is not valid should contain '@' and '.'",
                verified:false}}))
                return true;
            }else if(email !== ""){
              setErrorText(previous => ({...previous,email:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
                
            }        
          }
          if(contactNumber === "" || contactNumber !== ""){
            if(contactNumber === ""){
              setErrorText(previous => ({...previous,contactNumber:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(contactNumber.length < 10){
              setErrorText(previous => ({...previous,contactNumber:{error:true,
                errorText:"Minimum length should be 10 digits",
                verified:false}}))
                return true;
            }else if(containsSpecialChars(contactNumber) === true){
              setErrorText(previous => ({...previous,contactNumber:{error:true,
                errorText:"Field cannot contain Special Characters",
                verified:false}}))
                return true;
            }else if(containsOnlyNumbers(contactNumber) === true && contactNumber !== ""){
              setErrorText(previous => ({...previous,contactNumber:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
                return false;
            }       
          }
        
        console.log("RESULTS............");
        
      }
        console.log("relation value is",relation)
        console.log("gender value is",gender)
        console.log("date is",date)
          const handleDateChange = (newDate) => {
              setDate(dayjs(newDate).format('YYYY/MM/DD'));
          };
  
          React.useEffect(() => {
            getFamilyMemberDetails(member_id).then(res =>{
              console.log("member details")
              if(res.status === 200){
                setFirstName(res.data.personDto.givenName)
                setLastName(res.data.personDto.middleName)
                setDate(res.data.personDto.dateOfBirth)
                setGender(res.data.personDto.gender.genderName)
                setGenderCode(res.data.personDto.gender.genderCode)
                setBloodGroup(res.data.personDto.bloodgroup.label)
                setBloodGroupCode(res.data.personDto.bloodgroup.typeID)
                setRelation(res.data.relation.label)
                setRelationCode(res.data.relation.typeID)
                setPatientID(res.data.personDto.personId)
                setEmail(res.data.personDto.email)
                setContactNumber(res.data.personDto.phoneNumber)
              }else{
                toast.error("Could not fetch details", {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
              }
            })
            GetMasterData('BG').then(res => setBGList(res))
            GetGenderDetails().then(res => setGenderList(res));
            getRelationshipList().then(res => setRelationshipList(res.content))
          },[])
        
        return(
        <Grid item xs={12} md={12} lg={12}>
                    <Paper>
                    <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>BASIC DETAILS</Typography>
                    <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>First Name</Typography>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="Enter First Name"
                    size='small'
                    value={firstName}
                    onChange={(e)=>{setFirstName(e.target.value)}}
                    helperText={errorText.firstName.error === true ? errorText.firstName.errorText:""}
                    error={errorText.firstName.error === true ? true : false}
                    autoFocus={errorText.firstName.error === true ? true : false}
                  />
                </Grid>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Last Name</Typography>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="Enter Last Name"
                    size='small'
                    value={lastName}
                    onChange={(e)=>{setLastName(e.target.value)}}
                    helperText={errorText.lastName.error === true ? errorText.lastName.errorText:""}
                    error={errorText.lastName.error === true ? true : false}
                    autoFocus={errorText.lastName.error === true ? true : false}
                  />
                </Grid>
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={12} md={5} margin={2}>
          <Typography color={colors.grey[900]} fontWeight={'bold'}>Date of Birth</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Date of Birth"
                      inputFormat="yyyy/MM/dd"
                      value={date}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField size='small' fullWidth {...params} 
                      helperText={errorText.dob.error === true ? errorText.dob.errorText:""}
                      error={errorText.dob.error === true ? true : false}
                      autoFocus={errorText.dob.error === true ? true : false}
                      />}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Gender</Typography>
              <FormControl fullWidth>
                      <Autocomplete
                        onChange = {(event, newValue) => {if(newValue && newValue.genderCode){
                            setGender(newValue.genderName)
                            setGenderCode(newValue.genderCode)
                        }}}
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={genderList}
                        value={gender}
                        getOptionLabel={(option) => option.genderName || gender}
                        renderInput={(params) => <TextField {...params} label="Select Gender" 
                        helperText={errorText.gender.error === true ? errorText.gender.errorText:""}
                        error={errorText.gender.error === true ? true : false}
                        autoFocus={errorText.gender.error === true ? true : false}
                        />}
                      />
                    </FormControl>
                </Grid>
          </Grid>
          <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Blood Group</Typography>
                <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setBloodGroup(newValue.label)
                        setBloodGroupCode(newValue.typeID)
                      }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={bgList}
                    value={bloodGroup}
                    getOptionLabel={(option) => option.label || bloodGroup}
                    renderInput={(params) => <TextField {...params} label="Select Blood Group"
                      helperText={errorText.bg.error === true ? errorText.bg.errorText:""}
                      error={errorText.bg.error === true ? true : false}
                      autoFocus={errorText.bg.error === true ? true : false}
                    />}
                  />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Relationship</Typography>
                <FormControl fullWidth>
                      <Autocomplete
                        onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                            setRelation(newValue.label)
                            setRelationCode(newValue.typeID)
                        }}}
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={relationshipList}
                        value={relation}
                        getOptionLabel={(option) => option.label || relation}
                        renderInput={(params) => <TextField {...params} label="Select Relationship"
                        helperText={errorText.relation.error === true ? errorText.relation.errorText:""}
                        error={errorText.relation.error === true ? true : false}
                        autoFocus={errorText.relation.error === true ? true : false}
                        />}
                      />
                    </FormControl>
                </Grid>
          </Grid>
          <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Patient ID</Typography>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="Enter Patient ID"
                    size='small'
                    value={patientID}
                    onChange={(e)=>{setPatientID(e.target.value)}}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={5} margin={2}>
                
                </Grid>
          </Grid>
          <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Email</Typography>
                  <TextField
                    autoComplete="given-name"
                    name="bloodgroup"
                    required
                    fullWidth
                    id="firstName"
                    label="Enter Email"
                    size='small'
                    value={email}
                    onChange={(e)=>{setEmail(e.target.value)}}
                    helperText={errorText.email.error === true ? errorText.email.errorText:""}
                    error={errorText.email.error === true ? true : false}
                    autoFocus={errorText.email.error === true ? true : false}
                  />
                </Grid>
                <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Contact Number</Typography>
                <TextField
                      value={contactNumber}
                      onChange={(e) => setContactNumber(e.target.value)}
                      autoComplete="given-name"
                      name="mobilenum"
                      required
                      fullWidth
                      id="mobilenum"
                      label="Enter Mobile Number"
                      size='small'
                      type="number"
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                      }}
                      helperText={errorText.contactNumber.error === true ? errorText.contactNumber.errorText:""}
                      error={errorText.contactNumber.error === true ? true : false}
                      autoFocus={errorText.contactNumber.error === true ? true : false}
                    />
                </Grid>
          </Grid>
                    </Paper> 
                    <Paper sx={{marginTop:1}}>
                  <Grid item xs={12} md={12} margin={2}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                        onClick={()=>{
                          var validation = handleValidation();
                          if(validation === false){
                            console.log(member_id,firstName,lastName,gender,email,date,relation,patient.uuid,bloodGroup,contactNumber)
                            updateFamilyMember(member_id,firstName,lastName,genderCode,email,date,relationCode,patient.uuid,bloodGroupCode,contactNumber).then(res =>{
                              console.log(res)
                              if(res.status === 200){
                                  toast.success(res.message, {
                                    position: "top-right",
                                    autoClose: 4000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                    });
                                    getFamilyMemberDetails(member_id).then(res =>{
                                      console.log("member details")
                                      if(res.status === 200){
                                        setFirstName(res.data.personDto.givenName)
                                        setLastName(res.data.personDto.middleName)
                                        setDate(res.data.personDto.dateOfBirth)
                                        setGender(res.data.personDto.gender.genderName)
                                        setGenderCode(res.data.personDto.gender.genderCode)
                                        setBloodGroup(res.data.personDto.bloodgroup.label)
                                        setBloodGroupCode(res.data.personDto.bloodgroup.typeID)
                                        setRelation(res.data.relation.label)
                                        setRelationCode(res.data.relation.typeID)
                                        setPatientID(res.data.personDto.personId)
                                        setEmail(res.data.personDto.email)
                                        setContactNumber(res.data.personDto.phoneNumber)
                                      }else{
                                        toast.error("Could not fetch details", {
                                          position: "top-right",
                                          autoClose: 4000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          theme: "light",
                                          });
                                      }
                                    })
                                  
                              }else{
                                toast.error("Error Occurred", {
                                  position: "top-right",
                                  autoClose: 4000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  theme: "light",
                                  });
                                  setDComponent('FamilyMember');
                              }
                            }
                            )
                          }else{
                            toast.error("Please fill Mandatory Fields", {
                              position: "top-right",
                              autoClose: 4000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                              });
                          }
                          
                          
                        }}
                      >
                        Save
                      </Button>
                </Grid>
                  </Paper>
            </Grid>
        );
      }
      return(
        <Grid item xs={10} md={10} lg={10}>
          <Typography padding={0.5} fontWeight={'bold'}>Family Registration</Typography>
            <BasicDetails/>
              </Grid>
      );
    }

    function AddFileToVault(){
      const [fileType,setFileType] = React.useState('');
      const [description,setDescription] = React.useState('');
      const [fileTypeList, setFileTypeList] = React.useState([{label:"Vitals",typeID:'23'},{label:"Notes",typeID:'23'},{label:"Medical Condition",typeID:'23'},{label:"Prescription",typeID:'23'}]);
      const [filePath,setFilePath] = React.useState('');
      const [uploadedFilePath,setUploadedFilePath] = React.useState('');
      const [file,setFile ] = React.useState('');
      const [fileName,setFileName ] = React.useState('Please Upload a file');

      const handleVaultFileChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    
        console.log('fileObj is', fileObj);
    
        // 👇️ reset file input
        event.target.value = null;
    
        // 👇️ is now empty
        console.log(event.target.files);
    
        // 👇️ can still access file object here
        console.log(fileObj);
        console.log(fileObj.name);
        if(fileObj.type === 'image/png' || fileObj.type === 'image/jpg' 
        || fileObj.type === 'image/jpeg' || fileObj.type === 'application/pdf'){
          fileUpload(fileObj,filePath).then(res =>{
            console.log(res)
            if(res.status === 200){
              setUploadedFilePath(res.path)
              setFile(fileObj)
              setFileName(fileObj.name)
            }else{
              setFile('')
              setFileName('Please Upload a file')
            }
          }
          )
        }else{
          alert("Invalid File Type !! Accepted Files: PNG,JPG,JPEG,PDF")
          setFile('')
          setFileName('Please Upload a file')
        }
        
        
      };
      

      return(
        <div>
          <input
        style={{display: 'none'}}
        ref={inputRef}
        type="file"
        onChange={handleVaultFileChange}
        />
        <Dialog open={addFilestoVault} onClose={handleCloseVaultPopup} fullWidth maxWidth={'md'}>
        
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add Files to Vault</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseVaultPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent> 
            <Grid>
            <Grid item xs={12} sm={6} lg={6} padding={2}>
            <Typography fontSize={16} fontWeight={'bold'}>Document Type</Typography>
                <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                          setFileType(newValue.typeID)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={fileTypeList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Choose Document Type" />}
                    />
                </FormControl>
              </Grid>
            
            
            <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Choose Files</Typography>
              <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',fontSize:'12px',maxWidth:'100%'}} onClick={()=>{
                setFilePath(`VAULT_${patient.uuid}`)
                handleClick()
              }}>
                 Upload
                </Button>
                <Typography fontSize={12} fontWeight={'bold'} paddingTop={2}>Uploaded File Name: {fileName}</Typography>
              </Grid>
              
            <Grid item xs={12} sm={9} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Description</Typography>
                <TextField
                  required
                  fullWidth
                  value={description}
                  id="desc"
                  label="Enter File Description"
                  name="desc"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setDescription(e.target.value)}}
                  multiline
                  rows={4}
                />
              </Grid>
            </Grid>
            <Divider sx={{bgcolor:'#651fff',height:5,marginTop:1,width:'100%'}}/>  
          </DialogContent>
          
          <DialogActions>
            
            <Button variant="outlined" sx={{width:'20%',borderColor:'#651fff'}} onClick={() =>{handleCloseVaultPopup()}}>
                Cancel
              </Button>
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={() =>{
              console.log("typeID",fileType)
              insertFilestoVault(filePath,description,fileType,user.uuid).then(res =>{
              console.log(res)
              if(res.status === 200){
                toast(res.message)
                setAddFilestoVault(false)
                getVaultListFile(user.uuid,patient.uuid).then(
                  res=>{
                   setVaultListData(res)
                  }
                )
              }
            })}}>
                Add to Vault
              </Button>
    
                    
          </DialogActions>
        </Dialog>
        </div>
      
      );
    }

    function SymptomsPopUpCN(){
     
      const Input = styled(MuiInput)`
      width: 42px;
    `;
  
    const [healthIndicators, setHealthIndicatorList] = React.useState([
      {label:"Pain",value:0},
      {label:"Tiredness",value:0},
      {label:"Drowsiness",value:0},
      {label:"Nausea",value:0},
      {label:"Appetite",value:0},
      {label:"Breathing",value:0},
      {label:"Anxiety",value:0},
      {label:"General Wellness",value:0},
      {label:"Depress",value:0},
    
    ]);

      return (
      <div>
        <Dialog open={openSymptomsPopup} onClose={handleSymptomsClosePopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Health Indicator</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleSymptomsClosePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          
          <Typography fontSize={18} fontWeight={'bold'} align='right' paddingRight={15}>Less----------Medium----------Worst</Typography>
              {healthIndicators.map((symptom, index) => (
                <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} padding={2}>
                  <ListItemText
                    primary= {symptom.label}
                  />
                  </Grid>
                  <Grid item xs={12} sm={6} padding={2}>
                  <Grid container spacing={2}>
                  <Grid item xs>
                  <Slider
                  value={symptom.value}
                  onChange={(e) =>{
                    let newData = [...healthIndicators];
                    newData[index].value = Number(e.target.value);
                    setHealthIndicatorList(newData)           
                  }}
                  aria-labelledby="input-slider" 
                  max={10}
                  min={0} 
                  />
                </Grid>
                <Grid item>
                  <Input
                     value={symptom.value}
                     size="small"
                     onChange={(e) =>{
                      let newData = [...healthIndicators];
                      newData[index].value = Number(e.target.value);
                      setHealthIndicatorList(newData)
                    }}
                     inputProps={{
                       step: 1,
                       min: 0,
                       max: 10,
                       type: 'number',
                       'aria-labelledby': 'input-slider',
                    }}
                  />
                </Grid>
                </Grid>
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>
              ))}                   
              
          </DialogContent>
          <DialogActions>
            
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={() =>{
              // handleSymptomsClosePopup()
              console.log(healthIndicators)
              console.log(healthIndicators[0].value)
              createHealthIndicator(user.orgID,patient.uuid,
                healthIndicators[0].value,
                healthIndicators[1].value,
                healthIndicators[2].value,
                healthIndicators[3].value,
                healthIndicators[4].value,
                healthIndicators[5].value,
                healthIndicators[6].value,
                healthIndicators[7].value,
                healthIndicators[8].value).then(res =>{
                  console.log(res)
                  if(res.status === 200){
                    toast(res.message)
                    getHealthIndicatorList(user.orgID,patient.uuid).then(res =>{
                      console.log(res)
                      setHealthIndicatorListData(res)
                      handleSymptomsClosePopup()
                      // if(res.status === 200){
                      //   setHealthIndicatorListData(res.data)
                      //   handleSymptomsClosePopup()
                      // }else{
                      //   console.log(res)
                      //   handleSymptomsClosePopup()
                      // }
                      
                    })
                  }else{
                    toast("Error Occurred")
                    handleSymptomsClosePopup()
                  }
                })
              }}>
                      Save
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }

    function ShowHealthIndicatorDetails(){
      const [healthPopupDetails,setHealthIndicatorDetails] = React.useState([
        {label:"Pain",value:0},
        {label:"Tiredness",value:0},
        {label:"Drowsiness",value:0},
        {label:"Nausea",value:0},
        {label:"Appetite",value:0},
        {label:"Breathing",value:0},
        {label:"Anxiety",value:0},
        {label:"General Wellness",value:0},
        {label:"Depress",value:0},
      
      ]);
     
    console.log(healthPopupDetails)
    console.log("health Details",healthDetails)
    React.useEffect(()=>{
      let symptomArr = [...healthPopupDetails]
      symptomArr[0].value = healthDetails.pain
      symptomArr[1].value = healthDetails.tiredness
      symptomArr[2].value = healthDetails.drowsiness
      symptomArr[3].value = healthDetails.nausea
      symptomArr[4].value = healthDetails.appetite
      symptomArr[5].value = healthDetails.breathing
      symptomArr[6].value = healthDetails.anxiety
      symptomArr[7].value = healthDetails.generalwellness
      symptomArr[8].value = healthDetails.depress
      setHealthIndicatorDetails(symptomArr)
    },[])

      return (
      <div>
        <Dialog open={showHealthIndicator} onClose={handleCloseHealthDetailsPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Health Indicator</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseHealthDetailsPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          
          <Paper style={{maxHeight: '50vh', overflow: 'auto',margin:2}}>
        <Typography fontSize={16} fontWeight={'bold'} margin={1}>Symptoms</Typography>
          {healthPopupDetails.map((symptom, index) => (     
           <List>
                  <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={8}>
                    <Typography fontSize={16}>{symptom.label} : </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}> 
                  <Typography fontSize={16}>{symptom.value}</Typography>           
                </Grid>
                  </Grid>    
                  </ListItem>
                  </List>    
              ))}
            </Paper>                 
              
          </DialogContent>
          <DialogActions>
            
            <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} 
            onClick={() =>{handleCloseHealthDetailsPopup()}}>
                      Close
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
    }

    function FileCabinet(){
      
      return(
        <Grid item xs={10} md={10} lg={10}>
            <Paper sx={{padding:2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={10} >
                <Typography color={colors.grey[500]} marginTop={1}>File Cabinet</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                <Button variant="contained" startIcon={<AddIcon />} onClick={() => {setAddFile(true)}}>
                  Add File
                </Button>
                </Grid>
                
              </Grid>
                    
                </Paper>
                <Box sx={{maxHeight:'70vh', overflow:'auto'}}>
            {fcListData.length !== 0 ? fcListData.map((fcData,index) =>(
                  <Paper sx={{marginTop:1,padding:2}} key={index}>
                <Grid container spacing={3} >
                <Grid item xs={12} md={0.5}>
                  <Avatar src={diagonsis_file} ></Avatar>
                </Grid>
                <Grid item xs={12} md={7}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>
                  {fcData.documenttype.label} - {fcData.createdOn !== 'None' || fcData.createdOn !== '' || fcData.createdOn !== undefined || fcData.createdOn !== null ? fcData.createdOn.slice(0,10) : fcData.createdOn}
                  </Typography>
                
                </Grid>
                
                <Grid item xs={12} md={2}>
                <Typography
                              component="span"
                              variant="body2"
                              color={colors.grey[500]}
                              fontWeight={'bold'}
                            >
                              {fcData.createdOn !== 'None' || fcData.createdOn !== '' || fcData.createdOn !== undefined || fcData.createdOn !== null ? new Date(fcData.createdOn.slice(0,19)).toString().slice(0,15) : fcData.createdOn}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={1.5}>
                <Button variant="outlined" endIcon={<ShareIcon />} sx={{borderColor:'green',color:'green'}} onClick={() => {
                  setOpenShareFile(true)
                  setFCID(fcData.fileCabinetId)
                  }}>
                  Share
                </Button>
                </Grid>
                <Grid item xs={12} md={0.5} marginRight={0.5}>
                <IconButton onClick={()=>{
                          confirmAlert({
                            title: "Are you Sure you want to delete the file",
                            buttons: [
                              {
                                label: 'Yes',
                                onClick: () => {
                                  deleteFileCabinet(fcData.fileCabinetId).then(res =>{
                                    console.log(res)
                                    if(res.status === 200){
                                      toast.success(res.message, {
                                        position: "top-right",
                                        autoClose: 4000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                        });
                                        getFileCabinetList(patient.uuid).then(res =>{
                                          console.log(res)
                                          if(res.status === 200){
                                            setFCListData(res.data)
                                            
                                          }else{
                                            setFCListData([])
                                            
                                          }
                                        })
                                    }else{
                                      toast.error(`Error Occurred ${res.message}`)
                                    }
                                  })
                                }
                              },
                              {
                                label: 'NO',
                                onClick: () => {
                                  getFileCabinetList(patient.uuid).then(res =>{
                                    console.log(res)
                                    if(res.status === 200){
                                      setFCListData(res.data)
                                      
                                    }else{
                                      setFCListData([])
                                      
                                    }
                                  })
                                }
                              },
                            ],
                            closeOnEscape:false,
                            closeOnClickOutside:false,
                          })
                          }}>
                  <DeleteIcon/>
                </IconButton>
                </Grid>
              </Grid>
              <Grid container spacing={5} marginTop={0.5}>
              <Grid item xs={12} md={2.5}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Source</Typography>
                        <Typography paddingLeft={4} color={colors.grey[900]}>{fcData.source}</Typography>                
                </Grid>
                <Grid item xs={12} md={3.5}>
                <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Document Type</Typography>
                <Typography paddingLeft={4} color={colors.grey[900]}>{fcData.documenttype.label}</Typography>                
                </Grid>
                <Grid item xs={12} md={3.5}>
                </Grid>
                <Grid item xs={12} md={1}>
                <Button variant="outlined" onClick={() => {
                  
                  getFileCabinetDetails(fcData.fileCabinetId).then(res =>{
                    console.log(res)
                    if(res.status === 200){
                      setFCDetails(res.data)
                      setEditFile(true)
                    }else{
                      console.log('error')
                      setFCDetails([])
                    }
                  })
                  }}>
                  Edit
                </Button>
                </Grid>
                <Grid item xs={12} md={1}>
                <Button variant="contained" onClick={() => {
                  getFileCabinetDetails(fcData.fileCabinetId).then(res =>{
                    console.log(res)
                    if(res.status === 200){
                      setFCDetails(res.data)
                      setShowFCDetail(true)
                    }else{
                      console.log('error')
                      setFCDetails([])
                    }
                  })
                  }}>
                  View
                </Button>
                </Grid>
                
                
                
              </Grid>
                    </Paper>
                 )):<Paper sx={{margin:5,padding:5}}>
                <Typography align='center'>No Data Available</Typography>
                  </Paper>} 
                </Box>
                
                    
                    
        </Grid>
      );
    }

    function AddFileCabinet(){

      const [documentType, setDocumentType] = React.useState('');
      const [documentTypeID, setDocumentTypeID] = React.useState('');
      const [documentTypeList, setDocumentTypeList] = React.useState([]);
      const [description, setDescription] = React.useState('');
      const [filePath,setFilePath] = React.useState('');
      const [uploadedFilePath,setUploadedFilePath] = React.useState('');
      const [file,setFile ] = React.useState('');
      const [fileName,setFileName ] = React.useState('Please Upload a file');
      const [errorText,setErrorText] = React.useState({
        documentId:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        file:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        description:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
      })
  
      function handleValidation(){
        if(documentTypeID === '' || documentTypeID !==''){
          if(documentTypeID === ''){
            setErrorText(previous => ({...previous,documentId:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(documentTypeID !== ''){
            setErrorText(previous => ({...previous,documentId:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }   
        if(uploadedFilePath === "" || uploadedFilePath !== ""){
          if(uploadedFilePath === ""){
            setErrorText(previous => ({...previous,file:{error:true,
              errorText:"Please Upload a file",
              verified:false}}))
              return true;
              
          }else if(uploadedFilePath !== ""){
            setErrorText(previous => ({...previous,file:{error:false,
              errorText:"Please Upload a file",
              verified:true}}))
              
          }       
      }

        if(description === "" || description !== ""){
          if(description === ""){
            setErrorText(previous => ({...previous,description:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(description !== ""){
            setErrorText(previous => ({...previous,description:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              return false;
          }       
      } 
      console.log("RESULTS............");
      
      }

      const handleFileCabinetChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    
        console.log('fileObj is', fileObj);
    
        // 👇️ reset file input
        event.target.value = null;
    
        // 👇️ is now empty
        console.log(event.target.files);
    
        // 👇️ can still access file object here
        console.log(fileObj);
        console.log(fileObj.name);
        if(fileObj.type === 'image/png' || fileObj.type === 'image/jpg' 
        || fileObj.type === 'image/jpeg' || fileObj.type === 'application/pdf'){
          fileUpload(fileObj,filePath).then(res =>{
            console.log(res)
            if(res.status === 200){
              setUploadedFilePath(res.path)
              setFile(fileObj)
              setFileName(fileObj.name)
            }else{
              setFile('')
              setFileName('Please Upload a file')
            }
          }
          )
        }else{
          alert("Invalid File Type !! Accepted Files: PNG,JPG,JPEG,PDF")
          setFile('')
          setFileName('Please Upload a file')
        }
        
        
      };

    React.useEffect(() => {
      GetMasterSearchData('FILE_TYPE').then(res =>{
        console.log(res)
        setDocumentTypeList(res)
      })
    },[])
    console.log('document type list',documentTypeList)
      return (
      <div>
      <input
        style={{display: 'none'}}
        ref={inputRef}
        type="file"
        onChange={handleFileCabinetChange}
        />
        <Dialog open={addFile} onClose={handleCloseFileCabinetPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Add File</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseFileCabinetPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Document Type</Typography>
                <FormControl fullWidth>
                      <Autocomplete
                        onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                            setDocumentType(newValue.label)
                            setDocumentTypeID(newValue.typeID)
                        }}}
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={documentTypeList}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => <TextField {...params} label="Choose Document Type"
                        helperText={errorText.documentId.error === true ? errorText.documentId.errorText:""}
                        error={errorText.documentId.error === true ? true : false}
                        autoFocus={errorText.documentId.error === true ? true : false}  
                        />}
                      />
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} md={6} lg={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Choose Files</Typography>
        <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',fontSize:'12px',maxWidth:'100%'}} onClick={()=>{
         setFilePath(`FILE_CABINET_${patient.uuid}`)
         handleClick()
       }}>
                 Upload
                </Button>
                <Typography fontSize={12} fontWeight={'bold'} paddingTop={2}>Uploaded File Name: {fileName}</Typography>
                {errorText.file.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.file.errorText}</Typography> : <div></div>}
              </Grid>
              <Grid item xs={12} sm={9} padding={2}>
              
              </Grid>
        </Grid>
        <Grid>
          <Grid container spacing={2} paddingTop={1}>
          <Grid item xs={12} sm={9} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Description</Typography>
                <TextField
                  required
                  fullWidth
                  value={description}
                  id="spo2"
                  label="Enter Description"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setDescription(e.target.value)}}
                  multiline
                  rows={4}
                  helperText={errorText.description.error === true ? errorText.description.errorText:""}
                  error={errorText.description.error === true ? true : false}
                  autoFocus={errorText.description.error === true ? true : false} 
                />
              </Grid>
            <Grid item xs={12} sm={6} padding={2}>
                
                </Grid>
          </Grid>
          </Grid>
        
        <Divider sx={{bgcolor:'#651fff',height:5,marginTop:1,width:'100%'}}/>  
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleCloseFileCabinetPopup}>Cancel</Button>
                    <Button variant="contained" sx={{ bgcolor:'#651fff'}} onClick={() =>{
                      let verify = handleValidation();
                      if(verify === false){
                        if(user.userType === 'ADMIN' || 'OrgSuperAdmin'){
                          addFileCabinet(user.orgID,user.uuid,patient.uuid,uploadedFilePath,
                            fileName,documentTypeID,description,'Admin').then(res =>{
                              console.log(res)
                              if(res.status === 200){
                                toast.success(res.message)
                                handleCloseFileCabinetPopup()
                                getFileCabinetList(patient.uuid).then(res =>{
                                  console.log(res)
                                  if(res.status === 200){
                                    setFCListData(res.data)
                                    setDComponent('filecabinet')
                                  }else{
                                    setFCListData([])
                                    setDComponent('filecabinet')
                                  }
                                })
                              }else{
                                toast.error(`Error Occurred ${res.message}`)
                                handleCloseFileCabinetPopup()
                                getFileCabinetList(patient.uuid).then(res =>{
                                  console.log(res)
                                  if(res.status === 200){
                                    setFCListData(res.data)
                                    setDComponent('filecabinet')
                                  }else{
                                    setFCListData([])
                                    setDComponent('filecabinet')
                                  }
                                })
                              }
                            })
                        }else if(user.userType === 'DOCTOR'){
                          addFileCabinet(user.orgID,user.uuid,patient.uuid,uploadedFilePath,
                            fileName,documentTypeID,description,user.name).then(res =>{
                              console.log(res)
                              if(res.status === 200){
                                toast.success(res.message)
                                handleCloseFileCabinetPopup()
                                getFileCabinetList(patient.uuid).then(res =>{
                                  console.log(res)
                                  if(res.status === 200){
                                    setFCListData(res.data)
                                    setDComponent('filecabinet')
                                  }else{
                                    setFCListData([])
                                    setDComponent('filecabinet')
                                  }
                                })
                              }else{
                                toast.error(`Error Occurred ${res.message}`)
                                handleCloseFileCabinetPopup()
                                getFileCabinetList(patient.uuid).then(res =>{
                                  console.log(res)
                                  if(res.status === 200){
                                    setFCListData(res.data)
                                    setDComponent('filecabinet')
                                  }else{
                                    setFCListData([])
                                    setDComponent('filecabinet')
                                  }
                                })
                              }
                            })}
                        
                      }else{
                        toast.error("Please check the Input Fields")
                      }
                      
                    
                    }}>
                      Save
                    </Button>
    
                    
          </DialogActions>
        </Dialog>
            </div>
      );
    }

    function EditFileCabinet(){
     
      const [documentType, setDocumentType] = React.useState('');
      const [documentTypeID, setDocumentTypeID] = React.useState('');
      const [documentTypeList, setDocumentTypeList] = React.useState([]);
      const [description, setDescription] = React.useState('');
      const [filePath,setFilePath] = React.useState('');
      const [uploadedFilePath,setUploadedFilePath] = React.useState('');
      const [file,setFile ] = React.useState('');
      const [fileName,setFileName ] = React.useState('Please Upload a file');
      const [errorText,setErrorText] = React.useState({
        documentId:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        file:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        description:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
      })
  
      function handleValidation(){
        if(documentTypeID === '' || documentTypeID !==''){
          if(documentTypeID === ''){
            setErrorText(previous => ({...previous,documentId:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(documentTypeID !== ''){
            setErrorText(previous => ({...previous,documentId:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }   
        if(uploadedFilePath === "" || uploadedFilePath !== ""){
          if(uploadedFilePath === ""){
            setErrorText(previous => ({...previous,file:{error:true,
              errorText:"Please Upload a file",
              verified:false}}))
              return true;
              
          }else if(uploadedFilePath !== ""){
            setErrorText(previous => ({...previous,file:{error:false,
              errorText:"Please Upload a file",
              verified:true}}))
              
          }       
      }

        if(description === "" || description !== ""){
          if(description === ""){
            setErrorText(previous => ({...previous,description:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(description !== ""){
            setErrorText(previous => ({...previous,description:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              return false;
          }       
      } 
      console.log("RESULTS............");
      
      }

      const handleFileCabinetChange = event => {
        const fileObj = event.target.files && event.target.files[0];
        if (!fileObj) {
          return;
        }
    
        console.log('fileObj is', fileObj);
    
        // 👇️ reset file input
        event.target.value = null;
    
        // 👇️ is now empty
        console.log(event.target.files);
    
        // 👇️ can still access file object here
        console.log(fileObj);
        console.log(fileObj.name);
        if(fileObj.type === 'image/png' || fileObj.type === 'image/jpg' 
        || fileObj.type === 'image/jpeg' || fileObj.type === 'application/pdf'){
          fileUpload(fileObj,filePath).then(res =>{
            console.log(res)
            if(res.status === 200){
              setUploadedFilePath(res.path)
              setFile(fileObj)
              setFileName(fileObj.name)
            }else{
              setFile('')
              setFileName('Please Upload a file')
            }
          }
          )
        }else{
          alert("Invalid File Type !! Accepted Files: PNG,JPG,JPEG,PDF")
          setFile('')
          setFileName('Please Upload a file')
        }
        
        
      };
      
      console.log(fcPopupDetails)
    React.useEffect(() => {
      GetMasterSearchData('FILE_TYPE').then(res =>{
        console.log(res)
        setDocumentTypeList(res)
        setDocumentType(fcPopupDetails.documenttype.label)
        setDocumentTypeID(fcPopupDetails.documenttype.typeID)
        setFileName(fcPopupDetails.filename)
        setUploadedFilePath(fcPopupDetails.filepath)
        setDescription(fcPopupDetails.description)
      })
    },[])
    console.log('document type list',documentTypeList)
      return (
      <div>
      <input
        style={{display: 'none'}}
        ref={inputRef}
        type="file"
        onChange={handleFileCabinetChange}
        />
        <Dialog open={editFile} onClose={handleCloseFileCabinetEditPopup} fullWidth maxWidth={'md'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Edit File</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseFileCabinetEditPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>      
          <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Document Type</Typography>
                <FormControl fullWidth>
                      <Autocomplete
                        onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                            setDocumentType(newValue.label)
                            setDocumentTypeID(newValue.typeID)
                        }}}
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        options={documentTypeList}
                        value={documentType}
                        getOptionLabel={(option) => option.label || documentType}
                        renderInput={(params) => <TextField {...params} label="Choose Document Type"
                        helperText={errorText.documentId.error === true ? errorText.documentId.errorText:""}
                        error={errorText.documentId.error === true ? true : false}
                        autoFocus={errorText.documentId.error === true ? true : false}  
                        />}
                      />
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} md={6} lg={6} padding={2}>
        <Typography fontSize={16} fontWeight={'bold'}>Choose Files</Typography>
        <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',fontSize:'12px',maxWidth:'100%'}} onClick={()=>{
         setFilePath(`FILE_CABINET_${patient.uuid}`)
         handleClick()
       }}>
                 Upload
                </Button>
                <Typography fontSize={12} fontWeight={'bold'} paddingTop={2}>Uploaded File Name: {fileName}</Typography>
                {errorText.file.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.file.errorText}</Typography> : <div></div>}
              </Grid>
              <Grid item xs={12} sm={9} padding={2}>
              
              </Grid>
        </Grid>
        <Grid>
          <Grid container spacing={2} paddingTop={1}>
          <Grid item xs={12} sm={9} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Description</Typography>
                <TextField
                  required
                  fullWidth
                  value={description}
                  id="spo2"
                  label="Enter Description"
                  name="SPO2"
                  autoComplete="family-name"
                  size='small'
                  onChange={(e) =>{setDescription(e.target.value)}}
                  multiline
                  rows={4}
                  helperText={errorText.description.error === true ? errorText.description.errorText:""}
                  error={errorText.description.error === true ? true : false}
                  autoFocus={errorText.description.error === true ? true : false} 
                />
              </Grid>
            <Grid item xs={12} sm={6} padding={2}>
                
                </Grid>
          </Grid>
          </Grid>
        
        <Divider sx={{bgcolor:'#651fff',height:5,marginTop:1,width:'100%'}}/>  
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={handleCloseFileCabinetEditPopup}>Cancel</Button>
                    <Button variant="contained" sx={{ bgcolor:'#651fff'}} onClick={() =>{
                      let verify = handleValidation();
                      if(verify === false){
                        if(user.userType === 'ADMIN' || 'OrgSuperAdmin'){
                          editFileCabinet(fcPopupDetails.fileCabinetId,user.orgID,user.uuid,patient.uuid,uploadedFilePath,
                            fileName,documentTypeID,description,'Admin').then(res =>{
                              console.log(res)
                              if(res.status === 200){
                                toast.success(res.message)
                                handleCloseFileCabinetEditPopup()
                                getFileCabinetList(patient.uuid).then(res =>{
                                  console.log(res)
                                  if(res.status === 200){
                                    setFCListData(res.data)
                                    setDComponent('filecabinet')
                                  }else{
                                    setFCListData([])
                                    setDComponent('filecabinet')
                                  }
                                })
                              }else{
                                toast.error(`Error Occurred ${res.message}`)
                                handleCloseFileCabinetEditPopup()
                                getFileCabinetList(patient.uuid).then(res =>{
                                  console.log(res)
                                  if(res.status === 200){
                                    setFCListData(res.data)
                                    setDComponent('filecabinet')
                                  }else{
                                    setFCListData([])
                                    setDComponent('filecabinet')
                                  }
                                })
                              }
                            })
                        }else if(user.userType === 'DOCTOR'){
                          editFileCabinet(fcPopupDetails.fileCabinetId,user.orgID,user.uuid,patient.uuid,uploadedFilePath,
                            fileName,documentTypeID,description,user.name).then(res =>{
                              console.log(res)
                              if(res.status === 200){
                                toast.success(res.message)
                                handleCloseFileCabinetEditPopup()
                                getFileCabinetList(patient.uuid).then(res =>{
                                  console.log(res)
                                  if(res.status === 200){
                                    setFCListData(res.data)
                                    setDComponent('filecabinet')
                                  }else{
                                    setFCListData([])
                                    setDComponent('filecabinet')
                                  }
                                })
                              }else{
                                toast.error(`Error Occurred ${res.message}`)
                                handleCloseFileCabinetEditPopup()
                                getFileCabinetList(patient.uuid).then(res =>{
                                  console.log(res)
                                  if(res.status === 200){
                                    setFCListData(res.data)
                                    setDComponent('filecabinet')
                                  }else{
                                    setFCListData([])
                                    setDComponent('filecabinet')
                                  }
                                })
                              }
                            })}
                        
                      }else{
                        toast.error("Please check the Input Fields")
                      }
                      
                    
                    }}>
                      Save
                    </Button>
    
                    
          </DialogActions>
        </Dialog>
            </div>
      );
    }

    function ShowFileCabinetDetails(){

      console.log(fcPopupDetails)

      return (
      <div>
        <Dialog open={showFCDetail} onClose={handleCloseFCDetailPopup} fullWidth maxWidth={'sm'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>{fcPopupDetails.documenttype.label !== 'None' || fcPopupDetails.documenttype.label !== '' || fcPopupDetails.documenttype.label !== undefined || fcPopupDetails.filename !== null ? fcPopupDetails.documenttype.label : 'NA'} - {fcPopupDetails.createdOn !== 'None' || fcPopupDetails.createdOn !== '' || fcPopupDetails.createdOn !== undefined || fcPopupDetails.createdOn !== null ? fcPopupDetails.createdOn.slice(0,10) : fcPopupDetails.createdOn}</DialogTitle>
            <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF',position:'absolute',right:72,top:8}}
              onClick={()=>{
                if(fcPopupDetails.filename !== 'None' || fcPopupDetails.filename !== '' || fcPopupDetails.filename !== undefined || fcPopupDetails.filename !== null){
                  fileDownload(fcPopupDetails.filepath)
                }else{
                  toast.error("Error while downloading")
                }
                
              }}
              disabled={fcPopupDetails.filepath !== 'None' || fcPopupDetails.filepath !== '' || fcPopupDetails.filepath !== undefined || fcPopupDetails.filepath !== null ? false : true}
            >
                  Download
                </Button>
            <IconButton
              aria-label="close"
              onClick={handleCloseFCDetailPopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>    
          <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>File Name</Typography>
              <Typography fontSize={16}>{fcPopupDetails.filename !== 'None' || fcPopupDetails.filename !== '' || fcPopupDetails.filename !== undefined || fcPopupDetails.filename !== null ? fcPopupDetails.filename : 'NA'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Document Type</Typography>
              <Typography fontSize={16}>{fcPopupDetails.documenttype.label !== 'None' || fcPopupDetails.documenttype.label !== '' || fcPopupDetails.documenttype.label !== undefined || fcPopupDetails.documenttype.label !== null ? fcPopupDetails.documenttype.label : 'NA'}</Typography>
            </Grid> 
                    
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Created Date</Typography>
              <Typography fontSize={16}>{fcPopupDetails.createdOn !== 'None' || fcPopupDetails.createdOn !== '' || fcPopupDetails.createdOn !== undefined || fcPopupDetails.createdOn !== null ? fcPopupDetails.createdOn.slice(0,10) : fcPopupDetails.createdOn}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Source</Typography>
              <Typography fontSize={16}>{fcPopupDetails.source !== 'None' || fcPopupDetails.source !== '' || fcPopupDetails.source !== undefined || fcPopupDetails.source !== null ? fcPopupDetails.source : 'NA'}</Typography>
            </Grid>    
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
            
            <Grid item xs={12} sm={6} md={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Description</Typography>
              <Typography fontSize={16}>{fcPopupDetails.description !== 'None' || fcPopupDetails.description !== '' || fcPopupDetails.description !== undefined || fcPopupDetails.description !== null ? fcPopupDetails.description : 'NA'}</Typography>
            </Grid>         
          </Grid>
          </DialogContent>
        </Dialog>
            </div>
      );
    }

    function ShareFile(){

      const [basicData,setBasicData] = React.useState({});
      const [doctorName, setDoctorName] = React.useState('');
      const [doctor_UUID, setDoctor_UUID] = React.useState('');
      const [doctorList, setDoctorList] = React.useState([]);
      const [date, setDate] = React.useState('');
      const [readChecked, setReadChecked] = React.useState(true);
      const [modifyChecked, setModifyChecked] = React.useState(false);
      const [deleteChecked, setDeleteChecked] = React.useState(false);
      const [allChecked, setAllChecked] = React.useState(false);
      const [isLoading, setIsLoading] = React.useState(true);

      const [errorText,setErrorText] = React.useState({
        doctorName:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        date:{
          error:false,
          errorText:"Please Select Date",
          verified:true
        },
      })
  
      function handleValidation(){
        if(doctor_UUID === '' || doctor_UUID !==''){
          if(doctor_UUID === ''){
            setErrorText(previous => ({...previous,doctorName:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(doctor_UUID !== ''){
            setErrorText(previous => ({...previous,doctorName:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
              
          }
        }
      if(date === "" || date !== ""){
        if(date === ""){
          setErrorText(previous => ({...previous,date:{error:true,
            errorText:"Please Select Date",
            verified:false}}))
            return true;
            
        }else if(date !== ""){
          setErrorText(previous => ({...previous,date:{error:false,
            errorText:"Please Select Date",
            verified:true}}))
            return false;
        }       
     }
      
      
      console.log("RESULTS............");
      
    }


      const handleDateChange = (newDate) => {
        setDate(dayjs(newDate).format('YYYY-MM-DD'));
      };
      

      const handleReadChange = (event) => {
        setReadChecked(event.target.checked);
      }
      const handleModifyChange = (event) => {
        setModifyChecked(event.target.checked);
      }
      const handleDeleteChange = (event) => {
        setDeleteChecked(event.target.checked);
      }
      const handleAllChange = (event) => {
        setAllChecked(event.target.checked);
        setReadChecked(true)
        setModifyChecked(true)
        setDeleteChecked(true)
      }
      console.log('FCID',fc_id)
      React.useEffect(() => {
        getDoctorsList(user.orgID,'a').then(res =>{
          console.log(res)
          if(res.status === 200){
            setDoctorList(res.data.content)
          }
        })
        getShareFileDetail(fc_id).then(res => {
          console.log(res)
          if(res.status === 200){
            setBasicData(res.data)
            setIsLoading(false)
          }else{
            setBasicData(res.data)
            setIsLoading(true)
          }
          
        })
      },[])
      return (
      <div>
        <Dialog open={openShareFile} onClose={handleCloseShareFilePopup} fullWidth maxWidth={'lg'}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>File Sharing</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseShareFilePopup}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
          <DialogContent>
            {isLoading === false ? <div>
            <Box sx={{width:'100%',minHeight:'50vh',border:'2px solid black',borderRadius:4}}>
              <Grid container spacing={2}>
                <Grid xs={12} md={4} lg={4} paddingTop={5} paddingLeft={5}>
                <Grid container spacing={2} marginTop={1}>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>File Name</Typography>
              <Typography fontSize={16}>{basicData.fileCabinetInfo.filename !== 'None' || basicData.fileCabinetInfo.filename !== '' || basicData.fileCabinetInfo.filename !== undefined || basicData.fileCabinetInfo.filename !== null ? basicData.fileCabinetInfo.filename : 'NA'}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Document Type</Typography>
              <Typography fontSize={16}>{basicData.fileCabinetInfo.documenttype.label !== 'None' || basicData.fileCabinetInfo.documenttype.label !== '' || basicData.fileCabinetInfo.documenttype.label !== undefined || basicData.fileCabinetInfo.documenttype.label !== null ? basicData.fileCabinetInfo.documenttype.label : 'NA'}</Typography>
            </Grid> 
                    
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
          <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Created Date</Typography>
              <Typography fontSize={16}>{basicData.fileCabinetInfo.createdOn !== 'None' || basicData.fileCabinetInfo.createdOn !== '' || basicData.fileCabinetInfo.createdOn !== undefined || basicData.fileCabinetInfo.createdOn !== null ? basicData.fileCabinetInfo.createdOn.slice(0,10) : basicData.fileCabinetInfo.createdOn}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Source</Typography>
              <Typography fontSize={16}>{basicData.fileCabinetInfo.source !== 'None' || basicData.fileCabinetInfo.source !== '' || basicData.fileCabinetInfo.source !== undefined || basicData.fileCabinetInfo.source !== null ? basicData.fileCabinetInfo.source : 'NA'}</Typography>
            </Grid>    
          </Grid> 
          <Grid container spacing={2} marginTop={1}>
            
            <Grid item xs={12} sm={6} md={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Description</Typography>
              <Typography fontSize={16}>{basicData.fileCabinetInfo.description !== 'None' || basicData.fileCabinetInfo.description !== '' || basicData.fileCabinetInfo.description !== undefined || basicData.fileCabinetInfo.description !== null ? basicData.fileCabinetInfo.description : 'NA'}</Typography>
            </Grid>         
          </Grid>
                </Grid>
                <Grid item xs={12} md={8} lg={8} paddingTop={5}>
                  <Button sx={{bgcolor:'white',color:'black'}} endIcon={<LockIcon/>}>File Access</Button>
                  <Divider sx={{height:2,bgcolor:'black',width:'80%'}}></Divider>
                  <Box sx={{height:'40vh',overflow:'auto'}}>
                  {basicData.assignedDoctorList.length !== 0 ? basicData.assignedDoctorList.map((details,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={details.name}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {details.emailId} . {details.contactNumber}
                                </Typography>
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                  </Box>
                  
                </Grid>
              </Grid>
              </Box> 
      <Typography fontSize={18} padding={1} fontWeight={'bold'} marginTop={2}>Share File</Typography>
      <Divider sx={{bgcolor:'#651fff',height:2}}/>     
          <Grid container spacing={2} marginTop={2}>
          <Grid item xs={12} sm={6} padding={2}>
      <Typography fontSize={16} fontWeight={'bold'}>Doctor Name</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.name){
                          setDoctorName(newValue.name)
                          setDoctor_UUID(newValue.uuId)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={doctorList}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} label="Choose Doctor"
                      helperText={errorText.doctorName.error === true ? errorText.doctorName.errorText:""}
                      error={errorText.doctorName.error === true ? true : false}
                      autoFocus={errorText.doctorName.error === true ? true : false}
                      />}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} padding={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Valid Upto</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Select Expiry Date"
                      inputFormat="yyyy/MM/dd"
                      value={date}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField size='small' fullWidth {...params} 
                      helperText={errorText.date.error === true ? errorText.date.errorText:""}
                      error={errorText.date.error === true ? true : false}
                      autoFocus={errorText.date.error === true ? true : false}
                      />}
                    />
                </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'}>Permissions</Typography>
                  <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} padding={2}>
                <FormGroup>
                <FormControlLabel control={<Checkbox 
                checked={readChecked}
                onChange={handleReadChange}
                inputProps={{ 'aria-label': 'controlled' }}
                />} label="Read" />

                <FormControlLabel control={<Checkbox 
                checked={modifyChecked}
                onChange={handleModifyChange}
                inputProps={{ 'aria-label': 'controlled' }}
                />} label="Modify" />
                
                </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6} padding={2}>
                <FormGroup>

              <FormControlLabel control={<Checkbox 
                checked={deleteChecked}
                onChange={handleDeleteChange}
                inputProps={{ 'aria-label': 'controlled' }}
                />} label="Delete" />

              <FormControlLabel control={<Checkbox 
                checked={allChecked}
                onChange={handleAllChange}
                inputProps={{ 'aria-label': 'controlled' }}
                />} label="All" />
                
                </FormGroup>
                </Grid>
                  </Grid>
                
              
                
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Button variant="contained" sx={{width:'30%',bgcolor:'#651fff'}} onClick={() =>{
            let res = handleValidation()
            if(res === false){
              addAccesstoFile(patient.uuid,user.orgID,{
                "read":readChecked,
                "write":modifyChecked,
                "delete":deleteChecked,
                "all":allChecked
            },date,fc_id,doctor_UUID).then(res =>{
              console.log(res)
              if(res.status === 200){
                getShareFileDetail(fc_id).then(res => {
                  console.log(res)
                  if(res.status === 200){
                    setBasicData(res.data)
                    setIsLoading(false)
                  }else{
                    setBasicData(res.data)
                    setIsLoading(true)
                  }
                  
                })
              }
            })
            }else{
              toast("Please Check your Input Data")
            }
            
            }}
            
            >
                      Add
                    </Button>
              </Grid>
        </Grid>
        
        {basicData.sharedDoctorList.length != 0 ? 
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell> NAME</StyledTableCell>
            <StyledTableCell align="center">Valid TILL</StyledTableCell>
            <StyledTableCell align="center">PERMISSIONS</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {basicData.sharedDoctorList.map((fileData,index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {fileData.doctorInfo.name}
              </StyledTableCell>
              <StyledTableCell align="center">{fileData.expiry_time}</StyledTableCell>
              <StyledTableCell align="center">
                {fileData.permission.read === true ? 'Read' : ''} {fileData.permission.write === true ? '- Modify' : ''} {fileData.permission.delete === true ? '- Delete' : '' } {fileData.permission.all === true ? '- All' : ''}
              </StyledTableCell>
              
              <StyledTableCell align="center">
                <IconButton onClick={() =>{
                  removeAccesstoFile(basicData.fileCabinetInfo.fileCabinetId,fileData.doctorInfo.uuId).then(res =>{
                    console.log(res)
                  if(res.status === 200){
                    getShareFileDetail(fc_id).then(res => {
                      console.log(res)
                      if(res.status === 200){
                        setBasicData(res.data)
                        setIsLoading(false)
                      }else{
                        setBasicData(res.data)
                        setIsLoading(true)
                      }
                      
                    })
              }

                  })
                }}>
                    <DeleteIcon></DeleteIcon>
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
       : <div></div>}   
            </div> : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
            
          </DialogContent>
          <DialogActions>
          
            <Button variant="contained" sx={{width:'10%',bgcolor:'#651fff'}} onClick={handleCloseShareFilePopup}>
                      Apply
                    </Button>
    
            
          </DialogActions>
        </Dialog>
            </div>
      );
   
    }
    
    return(
        <div>
          <Grid>
          <Grid container spacing={2}>
                <Grid item xs={2} md={2} lg={2}>
                
                    <Paper>
                    
                    <Box display='center' justifyContent="center" padding={2}>
                      
                      <Avatar alt="Remy Sharp" src={null} sx={{width:100,height:100}} style={{alignSelf:'center'}}/>
                    
                    </Box>
                    <Typography fontWeight={'bold'} align={'center'}>{props.patientData.givenName} {props.patientData.lastName}</Typography>
                    <Typography align={'center'} color={colors.grey[500]} fontSize={14}>{props.patientData.gendername} . {props.patientData.age} . {props.patientData.bloodGroup}</Typography>
                    <Typography align={'center'} color={colors.grey[500]} fontSize={14} paddingBottom={1}>{props.patientData.hospitalNumber}</Typography>
                       </Paper>
                       <Paper sx={{marginTop:1,borderRadius:3,marginBottom:4}}>
                        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <List component="nav" aria-label="main mailbox folders"
                        sx={{
                            // selected and (selected + hover) states
                            '&& .Mui-selected, && .Mui-selected:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                            // hover states
                            '& .MuiListItemButton-root:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                          }}
                        >
                            {stateData.personal === true ? <ListItemButton
                            selected={selectedIndex === 0}
                            onClick={(event) => {
                              handleListItemClick(event, 0)
                              getPatientPersoanlDetails(props.patientUUID).then(res =>{
                                console.log("PDATA",res)
                                if(res.status === 200){
                                  setPatientData(res.data)
                                  setDComponent('personal')
                                }else{
                                  setDComponent('personal')
                                }
                              })
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Personal Details" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }

                            {stateData.appointment === true ?<ListItemButton
                            selected={selectedIndex === 1}
                            onClick={(event) => {
                              handleListItemClick(event, 1)
                              setDefaultMonthYear(new Date())
                              setShowPast(false)
                              setShowPending(false)
                              setShowPastApt([])
                              setShowPendingApt([])
                              getUpcomingAppointmentList(patient.uuid).then(res =>{
                                console.log("upcoming appointment list",res)       
                                if(res.status === 200){
                                  setUpcomingAppointmentList(res.data)
                                    for (let i = 0; i < res.data.length; i++) {
                                        showUpcomingApt.push(false);
                                    }
                                    console.log(showUpcomingApt);
                                    setDComponent('appointments')
                                    setSelectedIndex(1)
                                }else{
                                  setUpcomingAppointmentList([])
                                  setDComponent('appointments')
                                  setSelectedIndex(1)
                                  console.log('error')
                                }

                              })
                              
                            }}
                            >
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Appointments" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }

                            {stateData.prescription === true ?<ListItemButton
                            selected={selectedIndex === 2}
                            onClick={(event) => {
                              handleListItemClick(event, 2)
                              setDComponent('prescriptions')
                              getPrescriptionList(patient.uuid).then(res =>{
                                console.log(res)
                                if(res.status === 200){
                                  setPrescriptionListData(res.data)
                                }
                              })
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Prescriptions" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }

                            {stateData.labReport === true ? <ListItemButton
                            selected={selectedIndex === 3}
                            onClick={(event) =>{ 
                              handleListItemClick(event, 3)
                              setDComponent('diagonasticReports')
                              getLabReportList(patient.uuid).then(res =>{
                                console.log(res)
                                if(res.status === 200){
                                  setLabReportListData(res.data)
                                }else{
                                  console.log("error")
                                  setLabReportListData([])
                                }
                              })
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Lab Reports" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }
                            {stateData.medicalCondition === true ? <ListItemButton
                            selected={selectedIndex === 4}
                            onClick={(event) =>{ 
                              handleListItemClick(event, 4)
                              setDComponent('medicalCondtions')
                              getMedicalConditionList(patient.uuid).then(res =>{
                                console.log(res)
                                if(res.status === 200){
                                  setMCListData(res.data)
                                }else{
                                  setMCListData([])
                                }
                              })
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Medical Conditions" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }
                            {stateData.vital === true ?<ListItemButton
                            selected={selectedIndex === 5}
                            onClick={(event) =>{ 
                              handleListItemClick(event, 5)
                              setDComponent('vitals')
                              vitalList()
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Vitals" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }
                            {stateData.allergy === true ?<ListItemButton
                            selected={selectedIndex === 6}
                            onClick={(event) =>{ 
                              handleListItemClick(event, 6)
                              setDComponent('allergy')
                              getAllergyList(patient.uuid).then(res =>{
                                console.log(res)
                                if(res.status === 200){
                                  setAllergyListData(res.data)
                                }else{
                                  console.log("error")
                                }
                              })
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Allergies" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }
                            {stateData.vault === true ? <ListItemButton
                            selected={selectedIndex === 7}
                            onClick={(event) =>{ 
                              // handleListItemClick(event, 7)
                              // setDComponent('vault')
                              // getVaultListFile(user.uuid,patient.uuid).then(
                              //  res=>{
                              //   setVaultListData(res)
                              //  }
                              // )
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Vault" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }
                            {stateData.healthIndicator === true ?<ListItemButton
                            selected={selectedIndex === 8}
                            onClick={(event) =>{ 
                              handleListItemClick(event, 8)
                              getHealthIndicatorList(user.orgID,patient.uuid).then(res =>{
                                console.log(res)
                                setHealthIndicatorListData(res)
                                setDComponent('health')
                                // if(res.status === 200){
                                //   setHealthIndicatorListData(res.data)
                                //   setComponent('health')
                                // }else{
                                //   console.log(res)
                                //   setComponent('health')
                                // }
                                
                              })
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Health Indicator" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }
                            {stateData.familyMember === true ?<ListItemButton
                            selected={selectedIndex === 9}
                            onClick={(event) =>{ 
                              handleListItemClick(event, 9)
                              setDComponent('FamilyMember')
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Family Members" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }
                           
                            {stateData.fileCabinet === true ?<ListItemButton
                            selected={selectedIndex === 10}
                            onClick={(event) =>{ 
                              handleListItemClick(event, 10)
                              getFileCabinetList(patient.uuid).then(res =>{
                                console.log(res)
                                if(res.status === 200){
                                  setFCListData(res.data)
                                  setDComponent('filecabinet')
                                }else{
                                  setFCListData([])
                                  setDComponent('filecabinet')
                                }
                              })
                              
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="File Cabinet" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }
                        </List>
                        </Box>
                    </Paper>
                </Grid>
                {dComponent === 'personal' ? <PersonalDetails/> : <div></div>}
                {dComponent === 'appointments' ? <Appointments/>: <div></div>}
                {dComponent === 'medicalCondtions' ? <MedicalConditions/>: <div></div>}
                {dComponent === 'prescriptions' ? <Precriptions/> : <div></div>}
                {dComponent === 'diagonasticReports' ? <DiagnosticReports/>: <div></div>}
                {dComponent === 'vitals' ? <Vitals/>: <div></div>}
                {dComponent === 'allergy' ? <Allergy/>: <div></div>}
                {dComponent === 'vault' ? <Vault/>: <div></div>}
                {dComponent === 'FamilyMember' ? <FamilyMember/>: <div></div>}
                {dComponent === 'MemberDetails' ? <MemberDetailandUpdate/>:<div></div>}
                {dComponent === 'AddFamilyMember' ? <AddFamilyMember/>:<div></div>}
                {dComponent === 'health' ? <HealthIndicator/>:<div></div>}
                {dComponent === 'filecabinet' ? <FileCabinet/>:<div></div>}
                {addPrescription === true ?<AddPrescription/>:<div></div>}
                {addVitals === true ?<AddVitals/>:<div></div>}
                {showVitals === true ?<ShowVitalDetails/>:<div></div>}
                {addAllergy === true ?<AddAllergy/>:<div></div>}
                {showAllergy === true ?<ShowAllergyDetails/>:<div></div>}
                {addMC === true ?<AddMedicalCondition/>:<div></div>}
                {addFile === true ?<AddFileCabinet/>:<div></div>}
                {editFile === true ?<EditFileCabinet/>:<div></div>}
                {showMC === true ?<ShowMedicalConditionDetails/>:<div></div>}
                {showFCDetail === true ?<ShowFileCabinetDetails/>:<div></div>}
                {addFilestoVault === true ?<AddFileToVault/>:<div></div>}
                {addLabReport === true ?<AddLabReportPopUp/>:<div></div>}
                {showLabReport === true ?<ShowLabReportDetails/>:<div></div>}
                {showPrescription === true ?<ShowPrescriptionDetails/>:<div></div>}
                {showHealthIndicator === true ?<ShowHealthIndicatorDetails/>:<div></div>}
                {openSymptomsPopup === true ?<SymptomsPopUpCN/>:<div></div>}
                {editSymptom === true ?<EditSymptomsPopup/>:<div></div>}
                {openShareFile === true ?<ShareFile/>:<div></div>}
                {openFaceSheet === true ?<FaceSheet open={openFaceSheet} handleClose={handleFaceSheetClosePopup} 
                  patientName={patient.name} patientUUID = {patient.uuid}/>:<div></div>}
            </Grid>
          </Grid>
            
        </div>
        );
    }

   