import store from '../../../context/StatePoolService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const ScheduleService = () => {
    
    const [user, setUser] = store.useState("user");
    console.log("details",user)

    async function createNewAppointment(doctor_uuid,app_type,person_id,created_by,aptStartTime,aptEndTime,aptDuration,reason,feePaid,feeAmt,symptoms,file_path){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");
        
        var raw = JSON.stringify({
          "doctorUuId": doctor_uuid,
          "appointmentType": {
            "typeID": app_type
          },
          "personUuId": person_id,
          "createdBy": created_by,
          "aptSlotsDto": {
            "aptSlotStartTime": parseInt(aptStartTime),
            "aptSlotEndTime": parseInt(aptEndTime),
            "aptSlotDuration": aptDuration
          },
          "reasonForConsultation": reason,
          "feesPaid": feePaid,
          "symptoms": symptoms,
          "fees": parseInt(feeAmt),
          "MedicalHistory":file_path
        });
        
        
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
        
        return fetch(`${user.baseUrl}apt/create`, requestOptions)
          .then(response => response.json())
          .then(result => {console.log(result)
            console.log(raw)
            return result;
            // toast (result.message)
          })
          .catch(error => {console.log('error', error)
          console.log(raw)});
    }

    async function getAppointmentList(doctor_uuid,apt_Type,start_date,end_date,view_Type){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", user.token);
      myHeaders.append("Content-Type", "application/json");
      console.log(apt_Type,view_Type)
      var raw = JSON.stringify({
        "appointmentType": apt_Type,
        "viewType": view_Type,
        "doctorUuId":doctor_uuid,
        "inputStartDate": start_date,
        "inputEndDate": end_date
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      console.log(raw)
      return fetch(`${user.baseUrl}/apt/getCalenderView`,requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          return result
        })
        .catch(error => console.log('error', error));
          }

    async function getAppointmentSlots(doctor_uuid,slot_date,consult_type){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", user.token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      try{
        let response = await fetch( `${user.baseUrl}sheduleController/freeSlotsByDoctorIdAndDate/${doctor_uuid}/${slot_date}/${consult_type}`, requestOptions)
        if(response.status === 200){
          return await response.json()
        }else{
          return []
        }
      }catch(error){
        toast(error)
      } 
    }

    async function getDetailsByAptId(apt_id){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", user.token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      return fetch(`${user.baseUrl}apt/getappointmentDetailByAptId/${apt_id}`, requestOptions)
        .then(response => response.json())
        .then(result =>{ 
          console.log(result)
          return result
        })
        .catch(error => console.log('error', error));
    }

    async function rescheduleAppointment(consultationId,doctor_uuid,consult_type,
      person_id,bookedAptSlotId,aptSlotStartTime,aptSlotEndTime,aptSlotDuration,oldaSlotStartTime,reason,symptoms,feeStatus,fees,){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", user.token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "consultationId": consultationId,
        "doctorUuId": doctor_uuid,
        "appointmentType": {
          "typeID": consult_type
        },
        "personUuId": person_id,
        "createdBy": doctor_uuid,
        "aptSlotsDto": {
          "bookedAptSlotId": bookedAptSlotId,
          "aptSlotStartTime": aptSlotStartTime,
          "aptSlotEndTime": aptSlotEndTime,
          "aptSlotDuration": aptSlotDuration,
          "oldaSlotStartTime": oldaSlotStartTime
        },
        "reasonForConsultation": reason,
        "feesPaid": feeStatus,
        "symptoms": symptoms,
        "fees": fees,
        "rescheduled": 1
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      console.log(raw)
      return fetch(`${user.baseUrl}apt/create`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          return result;
        })
        .catch(error => console.log('error', error));
    }

    async function cancelAppointment(apt_id){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", user.token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      return fetch(`${user.baseUrl}apt/cancelApt/${apt_id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          return result;
        })
        .catch(error => console.log('error', error));
    }

  async function getLeavesListbyUUID(doctor_uuid){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", user.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    return fetch(`${user.baseUrl}user/getLeaveList/${doctor_uuid}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        return result;
      })
      .catch(error => console.log('error', error));
        }
  async function addLeaves(startDateandTime,endDateandTime,description,doctor_uuid){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", user.token);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "startDateAndTime": startDateandTime,
        "endDateAndTime": endDateandTime,
        "description": description,
        "userUuId": doctor_uuid
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      console.log(raw)
      return fetch(`${user.baseUrl}user/createLeave`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          return result;
        })
        .catch(error => console.log('error', error));
  }

  async function updateLeave(startDateandTime,endDateandTime,description,doctor_uuid,event_id){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", user.token);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "startDateAndTime": startDateandTime,
      "endDateAndTime": endDateandTime,
      "description": description,
      "userUuId": doctor_uuid,
      "eventId": event_id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    return fetch(`${user.baseUrl}/user/updateLeave`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        return result;
      })
      .catch(error => console.log('error', error));
  }

  async function deleteLeaves(event_id){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", user.token);

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    return fetch(`${user.baseUrl}user/deleteLeave/${event_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        return result;
      })
      .catch(error => console.log('error', error));
  }

  async function getLeaveDetails(event_id){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", user.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    return fetch(`${user.baseUrl}user/getLeaveDetails/${event_id}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        return result;
      })
      .catch(error => console.log('error', error));
  }

  async function getUpcomingApt(doctor_uuid,apt_type){
    var myHeaders = new Headers();
    myHeaders.append("Authorization", user.token);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    return fetch(`${user.baseUrl}apt/getUpComingAppointment/${doctor_uuid}/${apt_type}`, requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        return result;
      })
      .catch(error => console.log('error', error));
  }
          return{createNewAppointment,getAppointmentList,getAppointmentSlots,
            getDetailsByAptId,rescheduleAppointment,cancelAppointment,
          addLeaves,getLeavesListbyUUID,getLeaveDetails,updateLeave,deleteLeaves,getUpcomingApt}
}

export default ScheduleService;
