import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';

export default function FixedBottomNavigation() {
    let navigate = useNavigate();
    const [value, setValue] = React.useState();
    return (
        <Paper sx={{ width:'100%',height:'6.5vh',position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          
        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <Typography align='left' sx={{position:'absolute',left:5,top:15,fontSize:12,color:'blue'}}>Copyright © 2020. All Rights Reserved By Ubiqare</Typography>
          <BottomNavigationAction label="Terms and Conditions" onClick={()=>{navigate("/termsandconditions")}} sx={{color:'blue'}}/>
          <BottomNavigationAction label="Contact Us"  onClick={()=>{navigate("/contactUs")}} sx={{color:'blue'}}/>
          <BottomNavigationAction label="Privacy Policy"  onClick={()=>{navigate("/privacypolicy")}} sx={{color:'blue'}}/>
          <BottomNavigationAction label="FAQ"  onClick={()=>{navigate("/faq")}} sx={{color:'blue'}}/>
          <Typography align='right' sx={{position:'absolute',right:5,top:15,fontSize:12,color:'blue'}}>v 1.1.0</Typography>
        </BottomNavigation>
        
      </Paper>
  );
}