import * as React from 'react';
import { useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";

const PasswordChangeLogic = () => {
   
      let navigate = useNavigate();
      const [password, setPassword] = React.useState('');
      const [confirmPassword, setConfirmPassword] = React.useState('');
      const [showError,setError] = React.useState(false);
      const [errorText,setErrorText] = React.useState('');
      const handleSubmit = (event) => {
        event.preventDefault();
        if(password === '' || confirmPassword === ''){
          setErrorText('Field cant be empty');
          setError(true);
        }else{
          console.log(confirmPassword);
        console.log(password);
        }
        
      };
      const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
      });
      
      const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
      };
      const [values1, setValues1] = React.useState({
        confirmPassword: "",
        showConfirmPassword: false,
      });
      
      const handleClickShowConfirmPassword = () => {
        setValues1({ ...values1, showConfirmPassword: !values1.showConfirmPassword });
      };
      
      const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };
    
      async function generatePasswordKey(uuid){
        console.log(uuid,password)
        var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "uuId": uuid
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      return await fetch(`${process.env.REACT_APP_BASE_URL}public/generateForgotPasswordKey`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          return result;
        })
        .catch(error => {
          console.log('error', error)
          if (error.code === "ERR_BAD_REQUEST") {
            console.log(error.response);
            console.log("server responded");
            toast("Unauthorized User");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
            toast(error)
          }
        
        });
      }

      async function changePassword(resetKey,user_id,password){
        console.log(password);
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          "password": password,
          "userId": user_id,
          "resetKey": resetKey
        });

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        return await fetch(`${process.env.REACT_APP_BASE_URL}public/forgotPassword`, requestOptions)
          .then(response => response.json())
          .then(res => {
            console.log("RAW of CP",requestOptions)
            console.log(res)
            if(res.status === 200){
              toast(res.message)
              setTimeout(() => {
                navigate('/login');
              }, 4000);
  
          }else if(res.status !== 200){
              toast(res.message)
          }
          })
          .catch(error => {
            console.log('error', error)
            if (error.status !== 200) {
              console.log(error.response);
              console.log("server responded");
              toast(error.message)
            } else if (error.request) {
              console.log("network error");
            } else {
              console.log(error);
              toast(error)
            }
          });        
    }

    return{password,setPassword,confirmPassword,setConfirmPassword,showError,
        setError,errorText,setErrorText,handleSubmit,values,setValues,values1,setValues1,
        handleClickShowPassword,handleClickShowConfirmPassword,handleMouseDownPassword,changePassword,generatePasswordKey}
}

export default PasswordChangeLogic;