import * as React from 'react';
import { styled, useTheme, alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { colors,Paper,Icon } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import logoUB from '../../assets/images/logo_ub.png'
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import AssignDoctor from './Assign Doctor/AssignDoctor';
import Helper from '../../Helper/Helper';
import PatientDashboardComponent from './PatientDashboard/PatientRegUI';
import AdminDetails from './AdminManagement/AdminCreate';
import store from '../../context/StatePoolService';
import AdminService from './AdminService';
import { useNavigate} from 'react-router-dom';
import Calendar from '../DoctorDashBoard/DoctorScheduling/DoctorSchedule';
import ScheduleLeave from '../DoctorDashBoard/DoctorScheduling/ScheduleLeave';
import RescheduleAppointment from '../DoctorDashBoard/DoctorScheduling/RescheduleAppointment';
import TeleCallComponent from '../DoctorDashBoard/AppointmentsComponent/TeleCallComponent';
import NewAppointment from '../DoctorDashBoard/DoctorScheduling/NewAppointment';
import DoctorNewRegistration from './DoctorManagement/DoctorNewRegistartion';
import DoctorDetails from './DoctorManagement/DoctorGetUpdateDetails';
import DoctorPanelList from '../DoctorDashBoard/DoctorPanel/PanelListComponent';
import AddNewPanel from '../DoctorDashBoard/DoctorPanel/CreateNewPanel';
import PartnerDetails from './PartnerManagement/PartnerGetUpdateDetails';
import PartnerNewRegistration from './PartnerManagement/PartnerNewRegistration';
import Menu from '@mui/material/Menu';
import PatientRegistration from '../PatientComponents/PatientRegistration';
import SearchResults from '../DoctorDashBoard/SearchComponents/SearchResults';
import { toast } from 'react-toastify';
import AdminDetailUpdate from './AdminManagement/AdminGetandUpdate';
import DoctorSettingsPage from '../DoctorDashBoard/DoctorSettingsPage';
import SearchService from '../DoctorDashBoard/SearchComponents/SearchService';
import PrivilegeDetails from './PrivilegesManagement/PrivilegesDetailCreate';
import PrivilegeService from './PrivilegesManagement/PrivilgesService';
import RecommendIcon from '@mui/icons-material/Recommend';
import { confirmAlert } from 'react-confirm-alert';
import PrivilegeDetailUpdate from './PrivilegesManagement/PrivilegesDetailUpdate';
import PreRegApproval from 'C:/Users/shara/ReactApp/Git_repo/webdevelopment/src/routes/PreRegistration/PreRegApproval.js';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

const {drawerWidth,Main,AppBar,DrawerHeader,Search,SearchIconWrapper,StyledInputBase,generateString} = Helper();

export default function Admin() {

  let navigate = useNavigate();
  const {getPatientPersoanlDetails} = SearchService();
  const {getDoctorsList,getOrgList,userLogout,getPatientList,getAdminList,getDoctorsPageList} = AdminService();
  const [user, setUser] = store.useState("user");
  const [component, setComponent] = store.useState('componentState');
  const [dataForScheduler,setDataForScheduler] = store.useState('schedulerData');
  const [dataForAppointment,setDataForAppointment] = store.useState('appointmentData');
  console.log('data of appointment',dataForAppointment)
  console.log('data of scheduler',dataForScheduler)
  console.log(user.uuid);
  console.log(user.orgID);
  console.log(user.token);
  const theme = useTheme();
  const [roleID,setRoleID] = React.useState('');
  const [open, setOpen] = React.useState(true);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [personID,setPersonID] = store.useState('personID');
  const [doctorUUID,setDoctorUUID] = store.useState('doctorUUID');
  const [patientList, setPatientList] = React.useState([]);
  const [patientID, setPatientID] = React.useState('')
  const [orgID,setOrgID] = store.useState('orgID');
  const [patientData, setPatientData] = React.useState();
  const [showPatientSearch,setShowPatientSearch] = React.useState(true)
  const [patientSearchTerm, setPatientSearchTerm] = React.useState('');
  console.log("person",personID)
  console.log("doctor",doctorUUID)
  console.log("org",orgID)
  console.log("compo",component)
  console.log("UP",user)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openNow = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleClosePopup = async () => {
    setOpenPopup(false);
    await getPatientList(user.orgID,'a').then(res => setPatientList(res.data.content));
  };
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSearchResults =(uuid,orgID,name,personID,profileImage) =>{
    
    store.setState("patient", {
      uuid: uuid,
      orgID: orgID,
      name: name,
      personID: personID,
      profileImage: profileImage                
    });
    getPatientPersoanlDetails(uuid).then(res =>{
      console.log("PDATA",res)
      if(res.status === 200){
        setPatientData(res.data)
        console.log("USER PRIVILEGES",user.priviliges.length)
        if(user.userType === 'OrgSuperAdmin'){
          for(var i = 0; i < user.priviliges.length;i++){
            console.log("USER PRIVILEGES",user.priviliges[i])
            console.log("USER PRIVILEGES",user.priviliges[i].children)
            if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === true){
              store.setState("componentDetails",user.priviliges[i])
            }
            
          }
          
        }else if(user.userType === 'ADMIN'){
          for(var i = 0; i < user.priviliges.length;i++){
            if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === true){
              store.setState("componentDetails",user.priviliges[i])
            }
          }
          
        }else if(user.userType === 'DOCTOR'){
          for(var i = 0; i < user.priviliges.length;i++){
            if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === true){
              store.setState("componentDetails",user.priviliges[i])
            }
          }
          
        }else if(user.userType === 'MEDICAL_CAMP_DOCTOR'){
          for(var i = 0; i < user.priviliges.length;i++){
            if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === true){
              store.setState("componentDetails",user.priviliges[i])
            }
          }
          
        }
        setComponent('SearchResults')
        console.log("I am here")
      }else{
        setComponent('SearchResults')
      }
    })
    
  }

  const handleLogout = () =>{
    userLogout().then( res => {
      if(res.status === 200){
        navigate("/login")
      }
    })
  }

  function drawerNavigation(text){
    if(text.label === 'Patient Registration'){
      setOpenPopup(true);
    }else{
      console.log("INSIDE NAV",text)
      store.setState("componentDetails",text)
      setComponent(text.checked === true ? text.label : "")
    }

  }


  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));
  

  function ManageDoctor(){
    const [doctorList,setDoctorList] = React.useState([]);
    const[showList,setShowList] = React.useState(false);
    const [componentDetails, setComponentDetails] = store.useState('componentDetails');
    const [doctorSearchTerm, setDoctorSearchTerm] = React.useState('');
    const [pageTotalCount, setPageTotalCount] = React.useState()
    const [stateData, setStateData] = React.useState({
      register: false,
      edit: false
    })
    const renderState = () =>{
      componentDetails.children.map((detail,index) =>{
        console.log("STATES",detail)
        if(detail.label === 'Register Doctor' && detail.checked === false){
          setStateData(prevState => ({
            ...prevState,
            register: true
         }))
        }
        if(detail.label === 'Edit Doctor' && detail.checked === false){
          setStateData(prevState => ({
            ...prevState,
            edit: true
         }))
        }
        
      })
    }
    const handleInputChange = async (event) => {
      var value = event.target.value;
      setDoctorSearchTerm(event.target.value);
      console.log("Search Val",value)
      if (value !== '') {
        // Call the search API when there is input
        setDoctorList([]);
        await getDoctorsPageList(user.orgID,value,0).then(res =>{
          console.log("DOCTOR ELSE LIST",res)
            if(res.status === 200){
              setDoctorList(res.data.content);
              setPageTotalCount(res.data.totalPages)
              setShowList(true);
            }else{
              setDoctorList([]);
              setShowList(false);
            }
                      
          });
      } else {
        // Call the default API when input is cleared
        setDoctorList([]);
        await getDoctorsPageList(user.orgID,'',0).then(res =>{
          console.log("DOCTOR LIST",res)
            if(res.status === 200){
                setDoctorList(res.data.content);
                setPageTotalCount(res.data.totalPages)
                setShowList(true);
              }else{
                setDoctorList([]);
                setShowList(false);
              }
                      
          });
      }
    };

    React.useEffect(() => {
      renderState()
      const loadData = async () => {
        setDoctorList([]);
        await getDoctorsPageList(user.orgID,'',0).then(res =>{
          console.log("DOCTOR LIST",res)
          if(res.status === 200){
            setDoctorList(res.data.content);
            setPageTotalCount(res.data.totalPages)
            setShowList(true);
          }else{
            setDoctorList([]);
            setShowList(false);
          }
          
        });
      }
      loadData();
    },[])
    return(
      <Grid item xs={10} md={10} lg={10}>
        <Grid container spacing={3}>
          <Grid item xs={5} md={4}>
          <Typography fontSize={20} fontWeight={'bold'} >Manage Doctor</Typography>
          </Grid>
          <Grid item xs={2} md={5.5}>
              <TextField label="Search Doctor" variant="outlined" size='small' fullWidth
                value={doctorSearchTerm}
                onChange={handleInputChange}
                
              />
          </Grid>
          <Grid item xs={5} md={2.5}>
            <Button variant="contained" sx={{width:'100%',bgcolor:'#651fff'}} startIcon={<AddIcon/>} onClick={()=>{
              setComponent('DoctorRegistration')
              console.log("Menu Details",componentDetails.children[0].children)
              store.setState("componentMenuDetails",componentDetails.children[0].children)
              }} disabled={stateData.register}>
                    Register Doctor
                  </Button>
          </Grid>
        </Grid>
        {doctorList.length !== 0 ?doctorList.map((list,i) => (
        <Paper sx={{marginTop:2,padding:2}} key={generateString(i,16)}>
                  <Grid container spacing={3} >
                  <Grid item xs={12} md={0.5}>
                    <Box width={30} height={30} sx={{bgcolor:'#651fff'}}>
                      <img src={list.profileImage}></img>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={9.5}>
                  <Typography color={colors.grey[900]}>{list.prefix} {list.name}</Typography>
                  <Typography color={colors.grey[700]} fontSize={12}>{list.orgName} . {list.contactNumber} . {list.emailId}</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                  <Button variant="outlined" sx={{width:'100%'}} onClick={()=>{
                    setPersonID(list.personId)
                    setDoctorUUID(list.uuId)
                    setComponent('doctorDetails')
                    console.log("Menu Details",componentDetails.children[1].children,)
                    store.setState("componentMenuDetails",componentDetails.children[1].children)
                  }}
                  disabled={stateData.edit}
                  >
                    Edit
                  </Button>
                  </Grid>
                </Grid>
                      </Paper>)) : <Paper sx={{margin:5,padding:5}}>
                      <Typography align='center'>No Data Available</Typography>
                        </Paper> }
                      
      <Stack spacing={2} marginTop={3} marginBottom={5}>
      
        <Pagination count={pageTotalCount} color="primary" onChange={async(event,page) =>{
          setDoctorList([]);
          await getDoctorsPageList(user.orgID,' ',page - 1).then(res =>{
            console.log("DOCTOR LIST",res)
            if(res.status === 200){
              setDoctorList(res.data.content);
              setPageTotalCount(res.data.totalPages)
              setShowList(true);
            }else{
              setDoctorList([]);
              setShowList(false);
            }
            
          });
        
        }}/>
      
      </Stack>         
      </Grid>
    );
  }

  function ManagePartner(){
    
    const [partnerList,setPartnerList] = React.useState([]);
    const[showList,setShowList] = React.useState(false);
    
    React.useEffect(() => {
      getOrgList().then(res =>{
        if(res.status === 200){
          setPartnerList(res.data);
          setShowList(true);
        }else{
          setPartnerList([]);
          setShowList(false);
        }
        
      });
    },[])
    return(
      <Grid item xs={10} md={10} lg={10}>
        <Grid container spacing={3}>
          <Grid item xs={5} md={4}>
          <Typography fontSize={20} fontWeight={'bold'} >Manage Partner</Typography>
          </Grid>
          <Grid item xs={2} md={5.5}></Grid>
          <Grid item xs={5} md={2.5}>
            <Button variant="contained" sx={{width:'100%',bgcolor:'#651fff'}} startIcon={<AddIcon/>} onClick={()=>{setComponent('PartnerRegistration')}}>
                    Add Partner
                  </Button>
          </Grid>
        </Grid>
        {showList !== false ?partnerList.map((list,i) => (<Paper sx={{marginTop:2,padding:2}} key={generateString(i,16)}>
                  <Grid container spacing={3}>
                  <Grid item xs={12} md={0.5}>
                    <Box width={30} height={30} sx={{bgcolor:'#651fff'}}>
                    <Avatar src={list.orglogo} sizes='small' variant='square'></Avatar>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={9.5}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>{list.orgname}</Typography>
                  <Typography color={'#651fff'} fontSize={12}>{list.organizationCategory.label} . {list.email} . {list.addressDto.city}</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                  <Button variant="outlined" sx={{width:'100%'}} onClick={()=>{
                    console.log("org id is",list.orgId)
                    setOrgID(list.orgId)
                    setComponent('partnerDetails')
                    }}>
                    Edit
                  </Button>
                  </Grid>
                </Grid>
          </Paper>)) : <Paper sx={{margin:5,padding:5}}>
                      <Typography align='center'>No Data Available</Typography>
                        </Paper> }                 
      </Grid>
    );
  }
  
  function ManageAdmin(){
    const [adminList,setAdminList] = React.useState([]);
    const[showList,setShowList] = React.useState(false);
    const [adminSearchTerm, setAdminSearchTerm] = React.useState('');
    const [pageTotalCount, setPageTotalCount] = React.useState()
    console.log("Admin List",adminList)

    const handleInputChange = async (event) => {
      var value = event.target.value;
      setAdminSearchTerm(event.target.value);
      console.log("Search Val",value)
      if (value !== '') {
        // Call the search API when there is input
        setAdminList([]);
        await getAdminList(user.orgID,value,0).then(res =>{
          console.log("DOCTOR ELSE LIST",res)
            if(res.status === 200){
              setAdminList(res.data.content);
              setPageTotalCount(res.data.totalPages)
              setShowList(true);
            }else{
              setAdminList([]);
              setShowList(false);
            }
                      
          });
      } else {
        // Call the default API when input is cleared
        setAdminList([]);
        await getAdminList(user.orgID,'a',0).then(res =>{
          console.log("DOCTOR LIST",res)
            if(res.status === 200){
                setAdminList(res.data.content);
                setPageTotalCount(res.data.totalPages)
                setShowList(true);
              }else{
                setAdminList([]);
                setShowList(false);
              }
                      
          });
      }
    };

    React.useEffect(() => {
      getAdminList(user.orgID,'a',0).then(res =>{
        if(res.status === 200){
          setAdminList(res.data.content);
          setPageTotalCount(res.data.totalPages);
          setShowList(true);
        }else{
          setAdminList([]);
          setShowList(false);
        }
        
      });
    },[])
    return(
      <Grid item xs={10} md={10} lg={10}>
        <Grid container spacing={3}>
          <Grid item xs={5} md={4}>
          <Typography fontSize={20} fontWeight={'bold'} >Manage Admin</Typography>
          </Grid>
          <Grid item xs={2} md={5.5}>
          <TextField label="Search Doctor" variant="outlined" size='small' fullWidth
                value={adminSearchTerm}
                onChange={handleInputChange}
                
              />
          </Grid>
          <Grid item xs={5} md={2.5}>
            <Button variant="contained" sx={{width:'100%',bgcolor:'#651fff'}} startIcon={<AddIcon/>} onClick={()=>{setComponent('adminDetails')}}>
                    Add Admin
                  </Button>
          </Grid>
        </Grid>
        {showList !== false ?adminList.map((list,i) => (<Paper sx={{marginTop:2,padding:2}} key={generateString(i,16)}>
                  <Grid container spacing={3}>
                  <Grid item xs={12} md={0.5}>
                    <Box width={30} height={30} sx={{bgcolor:'#651fff'}}>
                    <Avatar src={list.orglogo} sizes='small' variant='square'></Avatar>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={9.5}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>{list.name}</Typography>
                  <Typography color={'#651fff'} fontSize={12}>{list.orgName} . {list.emailId} . {list.contactNumber}</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                  <Button variant="outlined" sx={{width:'100%'}} onClick={()=>{
                    console.log("org id is",list.orgId)
                    setDoctorUUID(list.uuId)
                    setPersonID(list.personId)
                    setComponent('adminDetailUpdate')
                    }}>
                    Edit
                  </Button>
                  </Grid>
                </Grid>
          </Paper>)) : <Paper sx={{margin:5,padding:5}}>
                      <Typography align='center'>No Data Available</Typography>
                        </Paper> }  
                        <Stack spacing={2} marginTop={3} marginBottom={5}>
      
        <Pagination count={pageTotalCount} color="primary" onChange={async(event,page) =>{
          setAdminList([]);
          await getAdminList(user.orgID,' ',page - 1).then(res =>{
            console.log("DOCTOR LIST",res)
            if(res.status === 200){
              setAdminList(res.data.content);
              setPageTotalCount(res.data.totalPages)
              setShowList(true);
            }else{
              setAdminList([]);
              setShowList(false);
            }
            
          });
        
        }}/>
      
      </Stack>               
      </Grid>
    );
  }

  function PrivilegesList(){
    const {getPrivilegeList,deletePrivilege} = PrivilegeService();
    const [privilegeList, setPrivilegeList] = React.useState([]);
    
    React.useEffect(() =>{
      getPrivilegeList(user.orgID).then(res =>{
        console.log(res)
        setPrivilegeList(res.data)
      })
    },[])

    return(
        <Grid item xs={10} md={10} lg={10}>
          <Grid container spacing={3}>
            <Grid item xs={5} md={4}>
            <Typography fontSize={20} fontWeight={'bold'} >Manage Privileges</Typography>
            </Grid>
            <Grid item xs={2} md={5.5}></Grid>
            <Grid item xs={5} md={2.5}>
              <Button variant="contained" sx={{width:'100%',bgcolor:'#651fff'}} startIcon={<AddIcon/>} onClick={()=>{setComponent('previlageDetails')}}>
                      Add Role
                    </Button>
            </Grid>
          </Grid>
          {privilegeList.length === 0 ? privilegeList.map((priviliges,index) => (
            <Paper sx={{marginTop:2,padding:2}} key={index}>
                    <Grid container spacing={3} >
                    <Grid item xs={12} md={0.5}>
                      
                        <Icon sx={{width:'auto',height:'auto'}}><RecommendIcon/></Icon>
                      
                    </Grid>
                    <Grid item xs={12} md={9.5}>
                    <Typography color={colors.grey[900]}>{priviliges.rolename}</Typography>
                    <Typography color={'blue'} fontStyle={'italic'} fontSize={12}>{priviliges.type.label} . {priviliges.roledescription}</Typography>
                    </Grid>
                    <Grid item xs={12} md={1}>
                    <Button variant="outlined" sx={{width:'100%'}} onClick={() =>{
                      confirmAlert({
                        title: "Are you Sure you want to delete the Role",
                        buttons: [
                          {
                            label: 'Yes',
                            onClick: () => {
                              deletePrivilege(user.orgID,priviliges.roleid).then(res =>{
                                console.log(res)
                                if(res.status === 200){
                                  toast.success(res.message)
                                  getPrivilegeList(user.orgID).then(res =>{
                                    console.log(res)
                                    setPrivilegeList(res.data)
                                  })
                                }else{
                                  toast.error(`Error Occurred ${res.message}`)
                                }
                              })
                            }
                          },
                          {
                            label: 'NO',
                            onClick: () => {
                              getPrivilegeList(user.orgID).then(res =>{
                                console.log(res)
                                setPrivilegeList(res.data)
                              })
                            }
                          },
                        ],
                        closeOnEscape:false,
                        closeOnClickOutside:false,
                      })
                    }}>
                      Delete
                    </Button>
                    </Grid>
                    <Grid item xs={12} md={1}>
                    <Button variant="contained" sx={{width:'100%'}} onClick={()=>{
                      setRoleID(priviliges.roleid)
                      setComponent('previlageDetailUpdate')
                    }}>
                      Edit
                    </Button>
                    </Grid>
                  </Grid>
                        </Paper>
          )) : <Paper sx={{margin:5,padding:5}}>
          <Typography align='center'>No Data Available</Typography>
            </Paper>}
          
                        
                        
        </Grid>
      );
}

const renderSearchState = () =>{
  if(user.userType === 'OrgSuperAdmin'){
    for(var i = 0; i < user.priviliges.length;i++){
      if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === false){
        setShowPatientSearch(false)
      }
    }
    
  }else if(user.userType === 'ADMIN'){
    for(var i = 0; i < user.priviliges.length;i++){
      if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === false){
        setShowPatientSearch(false)
      }
    }
    
  }else if(user.userType === 'DOCTOR'){
    for(var i = 0; i < user.priviliges.length;i++){
      if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === false){
        setShowPatientSearch(false)
      }
    }
    
  }
}
  
  React.useEffect(() => {
    const loadData = async () => {
      console.log("USER DATA",user)
      renderSearchState()
      
      await getPatientList(user.orgID,'a').then(res =>{
        console.log("Patient LIST",res)
        if(res.status === 200){
          setPatientList(res.data.content);
          
        }else{
          setPatientList([]);
          console.log(res.message)
        }
        
      });
    }
    loadData();
    
  },[])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{bgcolor:colors.grey[200]}}>
        
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon  sx={{color:'#000000'}}/>
          </IconButton>
          <Paper className='.MuiPaper-elevation12' sx={{width:'98%'}}>
           
          {/* {showPatientSearch === true ?
          <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
               <Autocomplete
                sx={{
                  width:'50%',marginLeft:4,
                }}
                size="small"
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={patientList}
                inputValue={patientSearchTerm}
                onInputChange={async (event, newValue) =>{
                  var value = event.target.value;
                  setPatientSearchTerm(event.target.value);
                  if(value === undefined || value === '' || value === 'undefined'){
                    await getPatientList('a').then(res =>{
                      console.log("Patient LIST",res)
                      if(res.status === 200){
                        setPatientList(res.data.content);
                      }else{
                        setPatientList([]);
                        console.log(res.message)
                      }
                      
                    });
                  }else{
                    await getPatientList(event.target.value).then(res =>{
                      console.log("Patient LIST INSIDE AUTOCOMPLETE",res)
                      if(res.status === 200){
                        setPatientList(res.data.content);
                      }else{
                        setPatientList([]);
                        console.log(res.message)
                      }
                      
                    });
                  }
                  
                }}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <StyledInputBase
                  hiddenLabel
                  variant='standard'
                    {...params}
                    label="Search for Patients...."
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      disableUnderline:true
                    }}
                  />
                )}
                renderOption={(props,option) =>(
                  <li{...props} key={option.uuId}>
                  <List sx={{ width: '100%',bgcolor: '#fff' }}>
                    <ListItem alignItems="flex-start"  onClick={() => {
                      setComponent('Patient Dashboard')
                      setPatientID(option.uuId)
                      handleSearchResults(option.uuId,option.orgName,option.name,option.personId,option.profileImage)
                      
                      }}>
                      <ListItemButton sx={{height:'auto'}}>
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={''} />
                      </ListItemAvatar>
                      <ListItemText
                     
                        primary={option.name}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                              align='left'
                              fontStyle={'italic'}
                              fontSize={12}
                            >
                              {option.emailId} . {option.orgName} . {option.contactNumber}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      </ListItemButton>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </List>
                  </li>
                )} 
              /> 
            
            </Search>
            : <div></div> } */}

        {showPatientSearch === true ?
          <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
               <Autocomplete
                sx={{
                  width:'50%',marginLeft:4,
                }}
                size="small"
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={patientList}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <StyledInputBase
                  hiddenLabel
                  variant='standard'
                    {...params}
                    label="Search for Patients...."
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      disableUnderline:true
                    }}
                  />
                )}
                renderOption={(props,option) =>(
                  <li{...props} key={option.uuId}>
                  <List sx={{ width: '100%',bgcolor: '#fff' }}>
                    <ListItem alignItems="flex-start"  onClick={() => {
                      setComponent('Patient Dashboard')
                      setPatientID(option.uuId)
                      handleSearchResults(option.uuId,option.orgName,option.name,option.personId,option.profileImage)
                      if(user.userType === 'OrgSuperAdmin'){
                        for(var i = 0; i < user.priviliges.length;i++){
                          if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === true){
                            store.setState("componentDetails",user.priviliges[i])
                          }
                        }
                        
                      }else if(user.userType === 'ADMIN'){
                        for(var i = 0; i < user.priviliges.length;i++){
                          if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === true){
                            store.setState("componentDetails",user.priviliges[i])
                          }
                        }
                        
                      }else if(user.userType === 'DOCTOR'){
                        for(var i = 0; i < user.priviliges.length;i++){
                          if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === true){
                            store.setState("componentDetails",user.priviliges[i])
                          }
                        }
                        
                      }
                      }}>
                      <ListItemButton sx={{height:'auto'}}>
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={''} />
                      </ListItemAvatar>
                      <ListItemText
                     
                        primary={option.name}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                              align='left'
                              fontStyle={'italic'}
                              fontSize={12}
                            >
                              {option.emailId} . {option.orgName} . {option.contactNumber}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      </ListItemButton>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </List>
                  </li>
                )} 
              /> 
            
            </Search>: <div></div> }
            

          </Paper>
          <div>
            <IconButton color="inherit" onClick={handleClick}>
              <StyledBadge
                color="secondary"
                variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  badge: 'green-ripple',
                }}
              >
              <Avatar alt="Remy Sharp" src={''} sx={{width:30, height:30}}/>
              
              </StyledBadge>
            </IconButton>
            <Menu
               anchorEl={anchorEl}
               open={Boolean(anchorEl)}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'top',
                 horizontal: 'right',
               }}
               transformOrigin={{
                 vertical: 'top',
                 horizontal: 'right',
               }}
              >
                <MenuItem onClick={() => {setComponent('Admin Settings')}}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>

            </div>
                          
            <Badge color="secondary" badgeContent={4} sx={{position: 'absolute',
                  top: 20,right:80,color:'black'}}>
              <NotificationsIcon />
            </Badge>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          '& .MuiButtonBase-root': {
            '&:hover': {
              backgroundColor: '#651fff',
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
        <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: 50, height: 50, position: 'absolute',
                  top: 0,
                  left: 5}}
                variant = 'square'
              />
            <Typography fontWeight={'bold'} fontSize={14}>ADMIN PROFILE</Typography>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Typography component="h1" variant="subtitle2" sx={{padding:1,color:colors.grey[500]}}>
              MENU
        </Typography>
        <List>
          {user.priviliges.map((text, index) => (
            (text.label !== 'Patient Search' && text.checked === true ?<ListItem key={text.id} disablePadding>
            <ListItemButton onClick={() => drawerNavigation(text)}>
              <ListItemIcon>
              {text.checked === true ? index % 2 === 0 ? <InboxIcon /> : <MailIcon /> : null}
              </ListItemIcon>
              <ListItemText primary={text.checked === true ? text.label : ""} />
            </ListItemButton>
          </ListItem> : <div></div>)
            
          ))}
        </List>
      </Drawer>
      
      <Main open={open} sx={{bgcolor:colors.grey[200]}}>
        <DrawerHeader />
        {component === 'Patient Dashboard' ?<PatientDashboardComponent token={user.token} baseUrl={user.baseUrl} orgID={user.orgID}/>:<div></div>}
        {component === 'Assign Doctor' ? <AssignDoctor/>:<div></div>}
        {component === 'Doctor Management' ? <ManageDoctor/>:<div></div>}
        {component === 'doctorDetails' ? <DoctorDetails/>:<div></div>}
        {component === 'partnerDetails' ? <PartnerDetails/>:<div></div>}
        {component === 'PartnerRegistration' ? <PartnerNewRegistration/>:<div></div>}
        {component === 'Partner Management' ? <ManagePartner/>:<div></div>}
        {component === 'Admin Management' ? <ManageAdmin/>:<div></div>}
        {component === 'adminDetails' ? <AdminDetails/>:<div></div>}
        {component === 'previlageDetails' ? <PrivilegeDetails/>:<div></div>}
        {component === 'previlageDetailUpdate' ? <PrivilegeDetailUpdate id={roleID}/>:<div></div>}
        {component === 'DoctorRegistration' ? <DoctorNewRegistration/>:<div></div>}
        {component === 'Doctor Scheduling' ? <Calendar/>:<div></div>}
        {component === 'ScheduleLeave' ? <ScheduleLeave doctorName = {dataForScheduler.doctorName} doctorUUID = {dataForScheduler.doctorUUID} profileImage={dataForScheduler.profileImage} speciality={dataForScheduler.speciality}/>:<div></div>}
        {component === 'Reschedule' ? <RescheduleAppointment consultationId = {dataForAppointment.aptId} doctorUUID = {dataForAppointment.doctorUUID}/>:<div></div>}
        {component === 'NewAppointment' ? <NewAppointment orgID = {user.orgID} doctorName = {dataForScheduler.doctorName} doctorUUID = {dataForScheduler.doctorUUID}/>:<div></div>}
        {component === 'Role Management' ? <PrivilegesList/>:<div></div>}
        {component === 'TeleCall' ?<TeleCallComponent consultationId = {dataForAppointment.aptId} doctorUUID = {dataForAppointment.doctorUUID} patientID = {dataForAppointment.patientID}/>:<div></div>}
        {component === 'Doctor Panel' ?<DoctorPanelList/>:<div></div>}
        {component === 'AddNewPanel' ?<AddNewPanel/>:<div></div>}
        {component === 'SearchResults' ?<SearchResults patientData = {patientData} patientUUID = {patientID}/>:<div></div>}
        {component === 'adminDetailUpdate' ?<AdminDetailUpdate/>:<div></div>}
        {component === 'Admin Settings' ?<DoctorSettingsPage/>:<div></div>}
        {component === 'Registration Approvals' ?<PreRegApproval/>:<div></div>}
        {openPopup === true ? <PatientRegistration openPopup = {openPopup} handleClosePopup = {handleClosePopup}/>:<div></div>}
      </Main>
    </Box>
  );

}