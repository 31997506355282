import * as React from 'react';
import { useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import store from '../../context/StatePoolService';

const LoginLogic = () => {
    let navigate = useNavigate();
    const [password, setPassword] = React.useState('');
    const [username, setUsername] = React.useState('');
  
    const handleSubmit = (event) => {
      event.preventDefault();
      console.log(username);
      console.log(password);
    };
    
  
    const [values, setValues] = React.useState({
      password: "",
      showPassword: false,
    });
    
    const handleClickShowPassword = () => {
      setValues({ ...values, showPassword: !values.showPassword });
    };
    
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };
    
  
    const handleFPNavigation = () => {
      navigate('/forgotPasswordGetOTP')
    };
    const handleSignUpNavigation = () => {
      navigate('/preReg')
    };
    
    
    function userLogin(e,username,password){

      e.preventDefault();
      axios.post(`${process.env.REACT_APP_BASE_URL}public/signIn`,{
          password : password,
          username : username
      })
      .then(
        (res) =>{
          console.log(res)
          console.log("status",res.status);
          console.log(res.data);
          console.log("userType",res.data.userType)
          if(res.status === 200){
            if(res.data.userType === 'OrgSuperAdmin' || res.data.userType === 'ADMIN' ){
              store.setState("user", {
                baseUrl: process.env.REACT_APP_BASE_URL, 
                uuid: res.data.uuid,
                orgID: res.data.orgId,
                token: res.data.ubiqareToken,
                name: res.data.name,
                profileImage: res.data.profileImage,
                userType: res.data.userType,
                personID: res.data.personId,
                qualification: res.data.qualification,
                specialization: res.data.specialization,
                profSpeciality: res.data.professionalspeciality,
                signature: res.data.signature,
                priviliges: JSON.parse(res.data.previlege)                  
              });
              console.log("privileges",JSON.parse(res.data.previlege))
              store.setState("personID","abc");
              store.setState("doctorUUID","abc");
              store.setState("orgID","abc");
              store.setState("componentState","Patient Dashboard")
              toast("Login Succesful");
              setTimeout(() => {
                navigate('/aDashboard');
              }, 4000);
            }else if(res.data.userType === 'DOCTOR'){
              store.setState("user", {
                baseUrl: process.env.REACT_APP_BASE_URL,
                uuid: res.data.uuid,
                orgID: res.data.orgId,
                token: res.data.ubiqareToken,
                name: res.data.name,
                profileImage: res.data.profileImage,
                userType: res.data.userType,
                personID: res.data.personId,
                qualification: res.data.qualification,
                specialization: res.data.specialization,
                profSpeciality: res.data.professionalspeciality,
                signature: res.data.signature,
                priviliges: JSON.parse(res.data.previlege)          
              });
              console.log("privileges",JSON.parse(res.data.previlege))
              store.setState("componentState","Doctor Dashboard")
              toast("Login Succesful");
              setTimeout(() => {
                navigate('/dDashboard');
              }, 4000);
            }
            else if(res.data.userType === 'MEDICAL_CAMP_DOCTOR'){
              store.setState("user", {
                baseUrl: process.env.REACT_APP_BASE_URL,
                uuid: res.data.uuid,
                orgID: res.data.orgId,
                token: res.data.ubiqareToken,
                name: res.data.name,
                profileImage: res.data.profileImage,
                userType: res.data.userType,
                personID: res.data.personId,
                qualification: res.data.qualification,
                specialization: res.data.specialization,
                profSpeciality: res.data.professionalspeciality,
                signature: res.data.signature,
                priviliges: JSON.parse(res.data.previlege)          
              });
              console.log("privileges",JSON.parse(res.data.previlege))
              store.setState("componentState","Medical Camp")
              toast("Login Succesful");
              setTimeout(() => {
                navigate('/dDashboard');
              }, 4000);
            }
              
          }else if(res.status !== 200){
              toast("Invalid Credentials")
          }
          
      }).catch((error) => {
        if (error.response.status === '409') {
          console.log(error.response);
          console.log("server responded");
          toast("Invalid Credentials")
        } else if (error.request) {
          console.log("network error");
          toast("network errors")
        } else {
          console.log(error);
          toast(error)
        }
      })
  
  }

    async function getPatientDashboardData(token,baseUrl,org_id){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      return fetch(`${baseUrl}patientDashboard/${org_id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          return result;
        })
        .catch(error => console.log('error', error));
    }
    async function getDoctorDashboardDetail(baseUrl,doctor_uuid,selectedDate,aptType,token){
      var myHeaders = new Headers();
      myHeaders.append("Authorization", token);

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      return fetch(`${baseUrl}doctor/dashboard/${doctor_uuid}/${selectedDate}/${aptType}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result)
          return result;
        })
        .catch(error => console.log('error', error));
    }
  return {password,setPassword,username,setUsername,handleSubmit,getPatientDashboardData,getDoctorDashboardDetail,
    values,setValues,handleClickShowPassword,handleMouseDownPassword,handleFPNavigation,handleSignUpNavigation,userLogin}
}

export default LoginLogic;