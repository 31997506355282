import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { colors,Paper,IconButton } from '@mui/material';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AssignDoctorLogic from './AssignDoctorLogic';
import AdminService from '../AdminService';
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Helper from '../../../Helper/Helper';
import store from '../../../context/StatePoolService';

const {generateString} = Helper()

function AssignDoctor(){
    const {GetPrivateMasterData,doctorSearch,patientSearch,assignDoctortoPatient,getAssignedPatientList} = AdminService();
    const [specialityList, setSpecialityList] = React.useState([]);
    const [speciality, setSpeciality] = React.useState('');
    const [specialityID, setSpecialityID] = React.useState('');
    const [doctorName, setDoctorName] = React.useState('');
    const [user, setUser] = store.useState("user");
    const [disableSpeciality, setDisableSpeciality] = React.useState(false);
    const [disableDoctorName, setDisableDoctorName] = React.useState(false);
    const [doctorList, setDoctorList] = React.useState([]);
    const [openAssignPatient, setOpenAssignPatient] = React.useState(false);
    const [doctorID,setDoctorID] = React.useState('');
    const handleCloseAssignPatientPopup = () => {
      setOpenAssignPatient(false);
    };
    React.useEffect(() => {
      GetPrivateMasterData('specialitySearch').then(res => setSpecialityList(res))
      doctorSearch(user.orgID,'a').then(res =>{
        console.log(res)
        if(res.status === 200){
          setDoctorList(res.data.content)
        }else{
          console.log("error")
          setDoctorList([])
        }
        
      })
    },[])

    function AssignPatient(){
      const [firstName, setFirstName] = React.useState('');
      const [lastName, setLastName] = React.useState('');
      const [patientList, setPatientList] = React.useState([]);
      const [disableFirstName, setDisableFirstName] = React.useState(false);
      const [disableLastName, setDisableLastName] = React.useState(false);
      const [selectedPatientList, setSelectedPatientList] = React.useState([]);
      const [closePId, setClosePId] = React.useState(-1);

      const handleCloseClickPatient = i => {
        setClosePId(closePId === i ? -1 : i);
        setSelectedPatientList([
          ...selectedPatientList.slice(0, i),
          ...selectedPatientList.slice(i + 1, selectedPatientList.length)
        ]);
      };

      function userExists(uuId) {
        return selectedPatientList.some(function(el) {
          return el.uuId === uuId;
        }); 
      }

      React.useEffect(() => {
        patientSearch('a').then(res =>{
          console.log(res)
          if(res.status === 200){
            setPatientList(res.data.content)
          }else{
            console.log("error")
            setPatientList([])
          }
          
        })
        getAssignedPatientList(doctorID).then(res =>{
          console.log("assigned patient list server",res)
          if(res.status === 200){
            setSelectedPatientList(res.data)
          }
        })
      },[])
      return (
        <div>
          <Dialog open={openAssignPatient} onClose={handleCloseAssignPatientPopup} fullWidth maxWidth={'md'}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} padding={2}>
              <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Assign Patient</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseAssignPatientPopup}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
          >
            <CloseIcon />
          </IconButton>
              </Grid>
            </Grid>
            <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
            <DialogContent>      
            <Grid item xs={10} md={10} lg={10}>
            <Paper elevation={3} sx={{height:'auto',marginTop:3}}>
              <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={12} md={4} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'} paddingBottom={1}>First Name</Typography>
                <TextField
                  required
                  fullWidth
                  value={firstName}
                  disabled={disableFirstName}
                  onChange={(e) => {
                    setFirstName(e.target.value)
                    setDisableLastName(true)
                  }}
                  id="email"
                  label="Enter First Name"
                  name="email"
                  autoComplete="family-name"
                  size='small'
                  // helperText={errorText.otherLicence.error === true ? errorText.otherLicence.errorText:""}
                  // error={errorText.otherLicence.error === true ? true : false}
                  // autoFocus={errorText.otherLicence.error === true ? true : false}
                />
                </Grid>
                <Grid item xs={12} md={4} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'} paddingBottom={1}>Last Name</Typography>
                <TextField
                  required
                  fullWidth
                  value={lastName}
                  disabled={disableLastName}
                  onChange={(e) => {
                    setLastName(e.target.value)
                    setDisableFirstName(true)
                  }}
                  id="email"
                  label="Enter Last Name"
                  name="email"
                  autoComplete="family-name"
                  size='small'
                  // helperText={errorText.otherLicence.error === true ? errorText.otherLicence.errorText:""}
                  // error={errorText.otherLicence.error === true ? true : false}
                  // autoFocus={errorText.otherLicence.error === true ? true : false}
                />
                </Grid>
          </Grid>
          <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={12} md={6} margin={3}>
                <Button variant="outlined" sx={{width:'40%',marginRight:2}} onClick={()=>{
                  setFirstName('')
                  setLastName('')
                  setDisableFirstName(false)
                  setDisableLastName(false)
                  patientSearch('a').then(res =>{
                    console.log(res)
                    if(res.status === 200){
                      setPatientList(res.data.content)
                    }else{
                      console.log("error")
                      setPatientList([])
                    }
                    
                  })
                }}>
                    Clear
                  </Button>
                  <Button variant="contained" sx={{width:'40%',bgcolor:'#651fff'}} onClick={() =>{
                    if(disableFirstName === true){
                      patientSearch(lastName).then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setPatientList(res.data.content)
                        }else{
                          console.log("error")
                          setPatientList([])
                        }
                        
                      })
                    }else if(disableLastName === true){
                      patientSearch(firstName).then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setPatientList(res.data.content)
                        }else{
                          console.log("error")
                          setPatientList([])
                        }
                        
                      })
                    }
                    
                  }} >
                    Search
                  </Button>
                </Grid>
          </Grid>
                      </Paper>
              <Typography fontSize={16} fontWeight={'bold'} marginTop={4}>Selected Patients</Typography>
              <Grid item xs={12} md={12} lg={12} margin={1}>
              <Stack
                  direction={{ xs: 'row', sm: 'row', md: 'row'}}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                  overflow={'auto'}
                >
                  <>
                {selectedPatientList.map((selectedPatient, i) => (
          <Card key={generateString(i,16)} sx={{minWidth:'30%',height:'auto',border:'1px solid',borderColor:'#696CFF'}}>
                <Grid container spacing={2}>
                <Grid item xs={10} md={9} lg={9}>
                    <CardContent>
                        <Typography fontWeight={'bold'} fontSize={14}>{selectedPatient.name}</Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={2} md={3} lg={3}>
                    <CardActions disableSpacing>
                        <IconButton
                            onClick={() => handleCloseClickPatient(i)}
                            aria-expanded={closePId === i}
                            aria-label="show more"
                            >
                            <HighlightOffIcon/>
                        </IconButton>
                    </CardActions>
                </Grid>
                </Grid>
                      </Card>
      ))}
      
                  </>
                </Stack>
              
                
              </Grid>
              <Typography fontSize={16} fontWeight={'bold'} marginTop={4}>Search Results</Typography>
              <Box marginBottom={4}>
              {patientList.map((patient,index) =>(
              <Paper sx={{marginTop:1,padding:2}} key={index}>
                  <Grid container spacing={3} >
                  <Grid item xs={12} md={1}>
                      
                      <Avatar src={patient.profileImage} sizes='small' variant='square'></Avatar>
                  </Grid>
                  <Grid item xs={12} md={9}>
                  <Typography color={colors.grey[900]}>{patient.name}</Typography>
                  <Typography color={'blue'} fontSize={12} fontStyle={'italic'}>{patient.emailId} . {patient.orgName} . {patient.contactNumber}</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                  <Button variant="contained" sx={{width:'100%',bgcolor:'#651fff'}} onClick={() =>{
                      var exist = userExists(patient.uuId)
                      console.log("exist",exist,patient.uuId)
                      if(exist === false){
                        setSelectedPatientList((oldState) => [...oldState, {
                          "name": patient.name,
                          "uuId": patient.uuId,
                        }]);
                      }
                     
                  }}>
                    Add
                  </Button>
                  </Grid>
                </Grid>
                      </Paper>))}
                </Box>
            </Grid>
            </DialogContent>
            <DialogActions>
              
              <Button variant="contained" sx={{width:'20%',bgcolor:'#651fff'}} onClick={() =>{
                console.log("assign patient list",selectedPatientList)
                assignDoctortoPatient(doctorID,selectedPatientList).then(res =>{
                  console.log(res)
                  if(res.status === 200){
                    toast.success(res.message, {
                      position: "top-right",
                      autoClose: 4000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });
                      setOpenAssignPatient(false)
                  }
                })
              }}>
                        Update
                      </Button>
      
              
            </DialogActions>
          </Dialog>
              </div>
        );
    }
      return(
          <Grid item xs={10} md={10} lg={10}>
              <Typography fontSize={20} fontWeight={'bold'}>Assign Doctor</Typography>
                      <Paper elevation={3} sx={{height:'auto',marginTop:3}}>
              <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={12} md={4} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'} paddingBottom={1}>Doctor Name</Typography>
                <TextField
                  required
                  fullWidth
                  value={doctorName}
                  disabled={disableDoctorName}
                  onChange={(e) => {
                    setDoctorName(e.target.value)
                    setDisableSpeciality(true)
                  }}
                  id="email"
                  label="Enter Doctor Name"
                  name="email"
                  autoComplete="family-name"
                  size='small'
                  // helperText={errorText.otherLicence.error === true ? errorText.otherLicence.errorText:""}
                  // error={errorText.otherLicence.error === true ? true : false}
                  // autoFocus={errorText.otherLicence.error === true ? true : false}
                />
                </Grid>
                <Grid item xs={12} md={4} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'} paddingBottom={1}>Choose Speciality</Typography>
                <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.label){
                        setSpeciality(newValue.label)
                        setSpecialityID(newValue.typeID)
                        setDisableDoctorName(true)
                      }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    disabled={disableSpeciality}
                    options={specialityList}
                    value={speciality}
                    getOptionLabel={(option) => option.label || speciality}
                    renderInput={(params) => <TextField {...params} label="Select Speciality"
                    // helperText={errorText.specialization.error === true ? errorText.specialization.errorText:""}
                    // error={errorText.specialization.error === true ? true : false}
                    // autoFocus={errorText.specialization.error === true ? true : false}
                    />}
                  />
                  </FormControl>
                </Grid>
          </Grid>
          <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                <Grid item xs={12} md={6} margin={3}>
                <Button variant="outlined" sx={{width:'40%',marginRight:2}} onClick={()=>{
                  setDoctorName('')
                  setSpeciality('')
                  setDisableDoctorName(false)
                  setDisableSpeciality(false)
                  doctorSearch(user.orgID,'a').then(res =>{
                    console.log(res)
                    if(res.status === 200){
                      setDoctorList(res.data.content)
                    }else{
                      console.log("error")
                      setDoctorList([])
                    }
                    
                  })
                }}>
                    Clear
                  </Button>
                  <Button variant="contained" sx={{width:'40%',bgcolor:'#651fff'}} 
                  onClick={() =>{
                    if(disableDoctorName === true){
                      doctorSearch(user.orgID,specialityID).then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setDoctorList(res.data.content)
                        }else{
                          console.log("error")
                          setDoctorList([])
                        }
                        
                      })
                    }else if(disableSpeciality === true){
                      doctorSearch(user.orgID,doctorName).then(res =>{
                        console.log(res)
                        if(res.status === 200){
                          setDoctorList(res.data.content)
                        }else{
                          console.log("error")
                          setDoctorList([])
                        }
                        
                      })
                    }
                    
                  }}>
                    Search
                  </Button>
                </Grid>
          </Grid>
                      </Paper>
              <Typography fontSize={20} fontWeight={'bold'} marginTop={4}>Search Results</Typography>
              <Box marginBottom={4}>
              {doctorList.map((doctor,index) =>(
              <Paper sx={{marginTop:1,padding:2}}>
                  <Grid container spacing={3} >
                  <Grid item xs={12} md={0.5}>
                      <img src={doctor.profileImage}></img>
                  </Grid>
                  <Grid item xs={12} md={9.5}>
                  <Typography color={colors.grey[900]}>{doctor.prefix} {doctor.name}</Typography>
                  <Typography color={'blue'} fontSize={12} fontStyle={'italic'}>{doctor.professionalspeciality} . {doctor.qualification} . {doctor.speciality}</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                  <Button variant="contained" sx={{width:'100%',bgcolor:'#651fff'}} onClick={()=>{
                    setDoctorID(doctor.uuId)
                    setOpenAssignPatient(true)
                    }}>
                    Assign
                  </Button>
                  </Grid>
                </Grid>
                      </Paper>))}
                </Box>
                
                {openAssignPatient === true ?<AssignPatient/>:<div></div>}   
          </Grid>
          
          
      );
  }
  export default AssignDoctor