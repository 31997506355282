import * as React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import GroupIcon from '@mui/icons-material/Group'
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import AdminService from '../AdminDashboard/AdminService';
import Helper from '../../Helper/Helper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { colors} from '@mui/material';
import store from '../../context/StatePoolService';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const {containsOnlyNumbers,containsSpecialChars,validateEmail} = Helper();

function PatientRegistration(props){

    const {mapUsertoOrg} = AdminService();
    const {GetGenderDetails,registerPatient,GetMasterData} = AdminService();
    const [genderList, setGenderList] = React.useState([]);
    const [gender, setGender] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [registrationID, setRegistrationId] = React.useState('');
    const [email,setEmail] = React.useState('');
    const [dateofBirth, setDOB] = React.useState(dayjs());
    const [isCampPatient, setIsCampPatient] = React.useState(false);
    const [bloodGroup,setBloodGroup] = React.useState('');
    const [bgList, setBGList] = React.useState([]);
    const [user, setUser] = store.useState("user");
    const [isLoading,setIsLoading] = React.useState(false);
    const [errorText,setErrorText] = React.useState({
      firstName:{
        error:false,
        errorText:"Field cant be empty"
      },
      lastName:{
        error:false,
        errorText:"Field cant be empty"
      },
      gender:{
        error:false,
        errorText:"Select an option from DropDown"
      },
      dob:{
        error:false,
        errorText:"Select Date of Birth"
      },
      email:{
        error:false,
        errorText:"Field can't be empty"
      },
      phone:{
        error:false,
        errorText:"Field can't be empty"
      },
      userId:{
        error:false,
        errorText:"Field can't be empty"
      },
      bg:{
        error:false,
        errorText:"Select an option from DropDown"
      },

    })

    function handleValidation(){
      var isError = true;
      
      if(firstName === "" || firstName !== ""){
            if(firstName === ""){
              setErrorText(previous => ({...previous,firstName:{error:true,
                errorText:"Field can't be empty"}}))
                isError = true;
            }else if(containsSpecialChars(firstName) === true){
              setErrorText(previous => ({...previous,firstName:{error:true,
                errorText:"Field cannot contain Special Characters"}}))
                isError = true;
  
            }else if(containsOnlyNumbers(firstName) === true){
              setErrorText(previous => ({...previous,firstName:{error:true,
                errorText:"Field cannot contain Numbers"}}))
                isError = true;
  
            }else if(firstName !== ""){
              setErrorText(previous => ({...previous,firstName:{error:false,
                errorText:"Field can't be empty"}}))
                isError = false;
  
            }       
        }

      if(lastName === "" || lastName !== ""){
          if(lastName === ""){
            setErrorText(previous => ({...previous,lastName:{error:true,
              errorText:"Field can't be empty"}}))
              isError = true;
              
          }else if(containsSpecialChars(lastName) === true){
            setErrorText(previous => ({...previous,lastName:{error:true,
              errorText:"Field cannot contain Special Characters"}}))
              isError = true;
              
          }else if(containsOnlyNumbers(lastName) === true){
            setErrorText(previous => ({...previous,lastName:{error:true,
              errorText:"Field cannot contain Numbers"}}))
              isError = true;
              
          }else if(lastName !== ""){
            setErrorText(previous => ({...previous,lastName:{error:false,
              errorText:"Field can't be empty"}}))
              isError = false;
            
          }         
        }

      if(gender === '' || gender !==''){
          if(gender === ''){
            setErrorText(previous => ({...previous,gender:{error:true,
              errorText:"Select an option from DropDown"}}))
              isError = true;
          }else if(gender !== ''){
            setErrorText(previous => ({...previous,gender:{error:false,
              errorText:"Select an option from DropDown"}}))
              isError = false;
          }
        }
      
      if(dateofBirth === '' || dateofBirth !==''){
          if(dateofBirth === ''){
            setErrorText(previous => ({...previous,dob:{error:true,
              errorText:"Select an option from DropDown"}}))
              isError = true;
          }else if(dateofBirth !== ''){
            setErrorText(previous => ({...previous,dob:{error:false,
              errorText:"Select an option from DropDown"}}))
              isError = false;
          }
        }
      
      if(phoneNumber === "" || phoneNumber !== ""){
          if(phoneNumber === ""){
            setErrorText(previous => ({...previous,phone:{error:true,
              errorText:"Field can't be empty"}}))
              isError = true;
              
          }else if(phoneNumber.length < 10){
            setErrorText(previous => ({...previous,phone:{error:true,
              errorText:"Minimum length should be 10 digits"}}))
              isError = true;
              
          }else if(containsSpecialChars(phoneNumber) === true){
            setErrorText(previous => ({...previous,phone:{error:true,
              errorText:"Field cannot contain Special Characters"}}))
              isError = true;
              
          }else if(containsOnlyNumbers(phoneNumber) === true && phoneNumber !== ""){
            setErrorText(previous => ({...previous,phone:{error:false,
              errorText:"Field can't be empty"}}))
              isError = false;
              
          }       
        }

      if(email === "" || email !== ""){
          if(email === ""){
            setErrorText(previous => ({...previous,email:{error:true,
              errorText:"Field can't be empty"}}))
              isError = true;
              
          }else if(containsOnlyNumbers(email) === true){
            setErrorText(previous => ({...previous,email:{error:true,
              errorText:"Field cannot contain only Numbers"}}))
              isError = true;
              
          }else if(validateEmail(email) === false){
            setErrorText(previous => ({...previous,email:{error:true,
              errorText:"Email is not valid should contain '@' and '.'"}}))
              isError = true;
              
          }else if(email !== ""){
            setErrorText(previous => ({...previous,email:{error:false,
              errorText:"Field can't be empty"}}))
              isError = false;
              
          }        
        }
      
      if(registrationID === "" || registrationID !== ""){
          if(registrationID === ""){
            setErrorText(previous => ({...previous,userId:{error:true,
              errorText:"Field can't be empty"}}))
              isError = true;
          }else if(containsSpecialChars(registrationID) === true){
            setErrorText(previous => ({...previous,userId:{error:true,
              errorText:"Field cannot contain Special Characters"}}))
              isError = true;
  
          }else if(containsOnlyNumbers(registrationID) === true){
            setErrorText(previous => ({...previous,userId:{error:true,
              errorText:"Field cannot contain Numbers"}}))
              isError = true;
  
          }else if(registrationID !== ""){
            setErrorText(previous => ({...previous,userId:{error:false,
              errorText:"Field can't be empty"}}))
              isError = false;
  
          }       
        }
        
      if(bloodGroup === '' || bloodGroup !==''){
        if(bloodGroup === ''){
          setErrorText(previous => ({...previous,bg:{error:true,
            errorText:"Select an option from DropDown"}}))
            isError = true;
        }else if(bloodGroup !== ''){
          setErrorText(previous => ({...previous,bg:{error:false,
            errorText:"Select an option from DropDown"}}))
            isError = false;
        }
      }
      
      console.log("RESULTS............",isError);
      setIsLoading(false)
      return isError;
      }

  const handleDateChange = (newValue) => {
    setDOB(newValue);
  };

  React.useEffect(() => {
    GetGenderDetails().then(res => setGenderList(res));
    GetMasterData('BG').then(res => setBGList(res))
  },[])
  
    return (
    <div>
      <Dialog open={props.openPopup} onClose={props.handleClosePopup} fullWidth maxWidth={'md'}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} padding={2}>
            <IconButton sx={{display:'inline'}}>
            <GroupIcon sx={{width:30,height:30,margin:1}}/>
          </IconButton>
          <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>New Patient Registration</DialogTitle>
          </Grid>

        </Grid>
        <Divider sx={{bgcolor:'#651fff',height:5}}/>
        <DialogContent>
        
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} padding={2}>
            <Typography fontWeight={'bold'}>First Name</Typography>
            <TextField
                        autoComplete="given-name"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        fullWidth
                        id="firstName"
                        label="Enter First Name"
                        size='small'
                        helperText={errorText.firstName.error === true ? errorText.firstName.errorText:""}
                        error={errorText.firstName.error === true ? true : false}
                        autoFocus={errorText.firstName.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontWeight={'bold'}>Last Name</Typography>
              <TextField
                        required
                        fullWidth
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        id="lastName"
                        label="Enter Last Name"
                        name="lastName"
                        autoComplete="family-name"
                        size='small'
                        helperText={errorText.lastName.error === true ? errorText.lastName.errorText:""}
                        error={errorText.lastName.error === true ? true : false}
                        autoFocus={errorText.lastName.error === true ? true : false}
                      />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontWeight={'bold'}>Email</Typography>
          <TextField
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      fullWidth
                      id="email"
                      label="Enter Email"
                      name="email"
                      autoComplete="family-name"
                      size='small'
                      helperText={errorText.email.error === true ? errorText.email.errorText:""}
                      error={errorText.email.error === true ? true : false}
                      autoFocus={errorText.email.error === true ? true : false}
                    />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontWeight={'bold'}>Phone Number</Typography>
              <TextField
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      autoComplete="given-name"
                      name="mobilenum"
                      required
                      fullWidth
                      id="mobilenum"
                      label="Enter Mobile Number"
                      size='small'
                      type="number"
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                      }}
                      helperText={errorText.phone.error === true ? errorText.phone.errorText:""}
                      error={errorText.phone.error === true ? true : false}
                      autoFocus={errorText.phone.error === true ? true : false}
                    />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography fontWeight={'bold'}>Gender</Typography>
            <FormControl fullWidth>
            <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.genderCode){
                        setGender(newValue.genderCode)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={genderList}
                    getOptionLabel={(option) => option.genderName}
                    renderInput={(params) => <TextField {...params} label="Select Gender"
                        helperText={errorText.gender.error === true ? errorText.gender.errorText:""}
                        error={errorText.gender.error === true ? true : false}
                        autoFocus={errorText.gender.error === true ? true : false}
                    
                    />}
                  />
            </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontWeight={'bold'}>Date of Birth</Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                      label="Date of Birth"
                      inputFormat="yyyy/MM/dd"
                      value={dateofBirth}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField size='small' fullWidth {...params} 
                      helperText={errorText.dob.error === true ? errorText.dob.errorText:""}
                      error={errorText.dob.error === true ? true : false}
                      autoFocus={errorText.dob.error === true ? true : false}
                      />}
                    />
              </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography fontWeight={'bold'}>Registration ID</Typography>
              <TextField
                  required
                  fullWidth
                  value = {registrationID}
                  onChange={(e) => setRegistrationId(e.target.value)}
                  id="languageskn"
                  label="Enter User ID"
                  name="lang"
                  autoComplete="family-name"
                  size='small'
                  helperText={errorText.userId.error === true ? errorText.userId.errorText:""}
                  error={errorText.userId.error === true ? true : false}
                  autoFocus={errorText.userId.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <FormControl>
                <FormLabel id="demo-controlled-radio-buttons-group">Camp Patient</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={isCampPatient}
                  onChange={(e) => setIsCampPatient(e.target.value)}
                >
                  <FormControlLabel value= {true} control={<Radio />} label="True" />
                  <FormControlLabel value={false} control={<Radio />} label="False" />
                </RadioGroup>
              </FormControl>
              </Grid>

              <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Blood Group</Typography>
                    <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setBloodGroup(newValue.typeID)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={bgList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select Blood Group"
                      helperText={errorText.bg.error === true ? errorText.bg.errorText:""}
                      error={errorText.bg.error === true ? true : false}
                      autoFocus={errorText.bg.error === true ? true : false}
                    />}
                  />
                    </FormControl>
                </Grid>
        </Grid>
        
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" sx={{width:'100%',margin:2}} color={'error'} onClick={props.handleClosePopup}>Cancel</Button>
          <Button variant="contained" sx={{width:'100%',margin:2,bgcolor:'#651fff'}} onClick={() => {
            setIsLoading(true)
            var result = handleValidation();
            console.log('VALIDATION RESULTS',handleValidation())
            if(result === false){
              setIsLoading(true)
              registerPatient(user.orgID,registrationID,firstName,lastName,dateofBirth.toISOString().slice(0,10),email,phoneNumber,gender,isCampPatient,bloodGroup).then((res) =>{
              if(res.ehrStatus === '200 OK'){
                toast.success("Patient Registered Successfully", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    })
                    setTimeout(() => {
                      props.handleClosePopup()
                      setIsLoading(false)
                    }, 4000);
                    setTimeout(() => {
                      mapUsertoOrg(user.orgID,res.uuid)
                    }, 10000);

                }else{
                    toast.error("Error Occurred", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        })
                        setTimeout(() => {
                          props.handleClosePopup()
                          setIsLoading(false)
                        }, 4000);
                }
            
            
            console.log("Registered Data is..........",res)
          })
        }else{
          toast.error("Please fill all the details")
          setIsLoading(false);
        }
      }}>
                    Register Patient
                  </Button>
        </DialogActions>
        {isLoading === true ? 
      <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    : <div></div>}
      </Dialog>
          </div>
          
    );
    
  }

  export default PatientRegistration;