import store from "../../../context/StatePoolService";

const PrivilegeService = () => {
    const [user, setUser] = store.useState("user");

    async function getPrivilegeList(orgId){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "orgid": orgId
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}listRole`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function createPrivilege(userType,orgID,roleName,roleDescription,priviligesList){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "type": {
            "typeID": userType
        },
        "orgid": orgID,
        "rolename": roleName,
        "roledescription": roleDescription,
        "priviledgedata": JSON.stringify(priviligesList)
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        console.log(raw)

        return fetch(`${user.baseUrl}addRole`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result
        })
        .catch(error => console.log('error', error));
            }

    async function searchRoleAvailability(orgId,roleName){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
    
        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}searchRole/${orgId}/${roleName}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function deletePrivilege(orgId,roleId){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        
        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        return fetch(`${user.baseUrl}deleteRole/${orgId}/${roleId}`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log(result)
            return result;
        })
          .catch(error => console.log('error', error));
    }

    async function getPrivilegeDetailsById(id){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getRole/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function updatePrivilege(id,userType,orgID,roleName,roleDescription,priviligesList){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "type": {
            "typeID": userType
        },
        "orgid": orgID,
        "rolename": roleName,
        "roledescription": roleDescription,
        "priviledgedata": JSON.stringify(priviligesList)
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        console.log(raw)

        return fetch(`${user.baseUrl}updateRole/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result
        })
        .catch(error => console.log('error', error));
            }


    return {getPrivilegeList,createPrivilege,searchRoleAvailability,deletePrivilege,getPrivilegeDetailsById,updatePrivilege}
}

export default PrivilegeService;