import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { colors,Paper, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import Helper from '../../../Helper/Helper';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import person_img from '../../../assets/images/person.jpg';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import AdminService from '../../AdminDashboard/AdminService';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import store from '../../../context/StatePoolService';


const {drawerWidth,Main,AppBar,DrawerHeader,Search,SearchIconWrapper,StyledInputBase,patientList,generateString} = Helper();

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList(props) {

  const [panelName,setPanelName] = React.useState('');
  const [component, setComponent] = store.useState('componentState');
  const {getDoctorSearchList,getDoctorListByPanelID,createDoctorPanel,updateDoctorPanel,deletePanelByID} = AdminService();
  const [doctorList,setDoctorList] = React.useState([]);
  const [user, setUser] = store.useState("user");
  const [doctorPanelList,setDoctorPanelList] = React.useState([]);
  const [doctorUUIDList,setDoctorUUIDList] = React.useState([]);
  const [errorText,setErrorText] = React.useState('Please Enter Panel Name');
  const [isError,setError] = React.useState(false)
  const [checked, setChecked] = React.useState([]);

  const leftChecked = intersection(checked, doctorList);
  const rightChecked = intersection(checked, doctorPanelList);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedAdd = () => {
    setDoctorPanelList(doctorPanelList.concat(leftChecked));
    setDoctorList(not(doctorList, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedRemove = () => {
    setDoctorList(doctorList.concat(rightChecked));
    setDoctorPanelList(not(doctorPanelList, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  
  console.log("Panel ID is",props.panelId)

  React.useEffect(() => {
    
    getDoctorSearchList(user.orgID,'a').then(result1 =>{
      let doctorArray = [];
      let doctorPanelArray = [];
      console.log(result1)
      if(result1.status === 200){

        doctorArray = result1.data.content;
        getDoctorListByPanelID(props.panelId,'').then(result2 => {
          console.log("in list compo",result2)
          if(result2.status === 200){
            doctorPanelArray = result2.data;
            console.log("doctorArray",doctorArray)
            console.log("doctorPanelArray",doctorPanelArray)
            let newarr = doctorArray.filter(el => {
              return !doctorPanelArray.find(element => {
                 return element.uuId === el.uuId;
              });
           });
            console.log('newww',newarr)
            setDoctorList(newarr)
            setDoctorPanelList(result2.data)
          }else{
            setDoctorList(doctorArray)
            setDoctorPanelList([])
          }
        })
        
      }else{
        setDoctorList([]);
      }
      
    });

    
  },[])
  console.log('doccccccPanel',doctorPanelList)
  console.log('docccccccc',doctorList)
  return (
    <Grid item xs={12} md={12} lg={12}>
      {props.isUpdate === false ? 
      <Paper sx={{marginTop:1,padding:1}}>
      <Grid container spacing={2}>
  <Grid item xs={12} md={5} margin={2}>
  <Typography color={colors.grey[900]} fontWeight={'bold'}>Panel Name</Typography>
      <TextField
          required
          fullWidth
          value={panelName}
          onChange={(e) => setPanelName(e.target.value)}
          id="email"
          label="Enter Panel Name"
          name="email"
          autoComplete="family-name" 
          size='small'
          helperText={isError === true ? errorText : ''}
          error={isError === true ? true : false}
      />
  </Grid>
  <Grid item xs={12} md={5} margin={2}>
  
  </Grid>
</Grid>
      </Paper> : <Typography fontWeight={'bold'} marginTop={2}>Panel Name: {props.panelName}</Typography>}
      <Grid container spacing={2}>
      <Grid item xs={12} sm={4.5} lg={4.5} marginTop={2}>
      <Typography color={colors.grey[900]} fontWeight={'bold'}>Doctor List</Typography>
        <Paper className='.MuiPaper-elevation12' sx={{width:'100%'}}>
                <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <TextField
                    sx={{
                    width:'95%',marginLeft:4,
                    }}
                    size="small"
                    fullWidth
                    id="free-solo-2-demo"
                    label={'Search for Doctors...'} 
                    onInput={(newValue)=>{
                      console.log('search...',newValue.target.value)
                      if(newValue.target.value !== ''){
                        getDoctorSearchList(user.orgID,newValue.target.value).then(res =>{
                          console.log('dataaaaa',res)
                          if(res.status === 200){
                            setDoctorList(res.data.content)
                          }else{
                            setDoctorList([])
                          }
                          
                        })
                      }else{
                        getDoctorSearchList(user.orgID,'a').then(res =>{
                          console.log('dataaaaa',res)
                          if(res.status === 200){
                            setDoctorList(res.data.content)
                          }else{
                            setDoctorList([])
                          }
                          
                        })
                      }
                      
                    }}
                />
                </Search>
                
            </Paper>
      {doctorList.length !== 0 || doctorList !== 0 ? <Paper sx={{ width: '100%', height: '45vh', overflow: 'auto',marginTop:2 }}>
      {doctorList.map((item,index) =>(
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItem
                     key={item.uuId}
                      role="listitem"
                      button
                      onClick={handleToggle(item)}
                      >
                        <ListItemIcon>
                            <Checkbox
                            checked={checked.indexOf(item) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                                'aria-labelledby': `transfer-list-item-${item}-label`,
                            }}
                            />
                        </ListItemIcon>
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src={item.profileImage} />
                        </ListItemAvatar>
                
                        <ListItemText
                          primary={item.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                align='left'
                              >
                                {item.orgName} . {item.speciality}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>))}
      </Paper> : 
      <Paper sx={{ width: '100%', height: '45vh', overflow: 'auto',marginTop:2}}>
          <Typography align='center'>No Data Available</Typography>
      </Paper>}
      </Grid>
      <Grid item xs={12} sm={1.5} lg={1.5} justifyContent="center" alignItems="center" marginTop={25}>
        <Grid container direction="column" alignItems="center">
            <Button
                fullWidth
                variant="contained"
                sx={{borderRadius: 2,bgcolor:'#651fff'}}
                onClick={() =>{handleCheckedAdd()}}
            >
            Add
            </Button>
            <Button
                fullWidth
                variant="contained"
                sx={{borderRadius: 2,bgcolor:'#651fff',marginTop:2}}
                onClick={() =>{handleCheckedRemove()}}
            >
            Remove
            </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4.5} lg={4.5} marginTop={2}>
      <Typography color={colors.grey[900]} fontWeight={'bold'}>Doctor Panel</Typography>
        <Paper className='.MuiPaper-elevation12' sx={{width:'100%'}}>
                <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <TextField
                    sx={{
                    width:'95%',marginLeft:4,
                    }}
                    size="small"
                    fullWidth
                    id="free-solo-2-demo"
                    label={'Search for Panel Doctors...'} 
                    onInput={(newValue)=>{
                      console.log('search...',newValue.target.value)
                      if(newValue.target.value !== ''){
                        getDoctorListByPanelID(props.panelId).then(res => {
                          console.log("doctors in panels",res)
                          if(res.status === 200){
                            setDoctorPanelList(res.data)
                          }else{
                            setDoctorPanelList([])
                          }
                          
                        })
                      }else{
                        getDoctorListByPanelID(props.panelId).then(res => {
                          console.log("doctors in panels",res)
                          if(res.status === 200){
                            setDoctorPanelList(res.data)
                          }else{
                            setDoctorPanelList([])
                          }
                          
                        })
                      }
                      
                    }}
                />
                </Search>
                
            </Paper>
      {doctorPanelList.length !== 0 || doctorPanelList !== []  ? <Paper sx={{ width: '100%', height: '45vh', overflow: 'auto',marginTop:2 }}>
      {doctorPanelList.map((item,index) =>(
      <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                      <ListItem
                     key={item.uuId}
                      role="listitem"
                      button
                      onClick={handleToggle(item)}
                      >
                        <ListItemIcon>
                            <Checkbox
                            checked={checked.indexOf(item) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                                'aria-labelledby': `transfer-list-item-${item}-label`,
                            }}
                            />
                        </ListItemIcon>
                        <ListItemAvatar>
                          <Avatar alt="Remy Sharp" src={item.profileImage} />
                        </ListItemAvatar>
                
                        <ListItemText
                          primary={item.name}
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                align='left'
                              >
                                {item.orgName} . {item.speciality}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </List>))}
      </Paper> : 
      <Paper sx={{ width: '100%', height: '45vh', overflow: 'auto',marginTop:2}}>
          <Typography align='center'>No Data Available</Typography>
      </Paper>}
      </Grid>

    </Grid>
    {props.isUpdate === false ?
       <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff',marginBottom:4}}
                      onClick={() =>{
                        if(panelName === ''){
                          setErrorText('Please Enter Panel Name')
                          setError(true)
                        }else{
                          let uuIDList = [];
                          doctorPanelList.map((list,i) =>(
                            uuIDList.push({"uuId":`${doctorPanelList[i].uuId}`,"active": true})
                          ))
                          createDoctorPanel(panelName,uuIDList).then(res =>{
                              {res.status === 200 ? confirmAlert({
                                  title: res.message,
                                  buttons: [
                                    {
                                      label: 'Okay',
                                      onClick: () => setComponent('Doctor Panel')
                                    },
                                  ],
                                  closeOnEscape:false,
                                  closeOnClickOutside:false,
                                })
                              :toast("Error Occured")}
                          })
                        }
                          
                          
                          
                      }}
                  >
                      Create
                </Button>: <Paper sx={{marginTop:4}}>
                    <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                      onClick={() =>{
                        let uuIDList = [];
                        doctorPanelList.map((list,i) =>(
                          uuIDList.push({"uuId":`${doctorPanelList[i].uuId}`,"active": true})
                        ))
                          updateDoctorPanel(props.panelName,uuIDList,props.panelId).then(res =>{
                              {res.status === 200 ? confirmAlert({
                                  title: res.message,
                                  buttons: [
                                    {
                                      label: 'Okay',
                                      onClick: () => {
                                        getDoctorListByPanelID(props.panelId,'').then(res => {
                                          console.log("in list compo",res)
                                          if(res.status === 200){
                                            setDoctorPanelList(res.data)
                                            // setComponent('Doctor Panel')
                                          }else{
                                            setDoctorPanelList([])
                                          }
                                          
                                        })
                                        
                                      
                                      }
                                    },
                                  ],
                                  closeOnEscape:false,
                                  closeOnClickOutside:false,
                                })
                              :toast("Error Occured")}
                          })            
                      }}
                    >
                      Update
                    </Button>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="outlined"
                      sx={{ mt: 3, mb: 2 ,borderRadius: 2}}
                      onClick={() =>{
                          deletePanelByID(props.panelId).then(res =>{
                              {res.status === 200 ? confirmAlert({
                                  title: res.message,
                                  buttons: [
                                    {
                                      label: 'Okay',
                                      onClick: () => {
                                        props.orgFunction()
                                      }
                                    },
                                  ],
                                  closeOnEscape:false,
                                  closeOnClickOutside:false,
                                })
                              :toast("Error Occured")}
                          })            
                      }}
                    >
                      Delete
                    </Button>
              </Grid>
              </Grid>
                </Paper>
      }
                
      
    </Grid>
  );
}