import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '@mui/material';
import logoUB from '../../../assets/images/logo_ub.png'
import { useLocation, useNavigate} from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomeIcon from '@mui/icons-material/Home';
import { IconButton } from '@mui/material';

const theme = createTheme();

export default function ForgotPasswordVerifyOTP() {

  let navigate = useNavigate();
  const location = useLocation();
  const [showError,setError] = React.useState(false);
  const [otp,setOTP] = React.useState("");
  const [uuid,setUUID] = React.useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const [timer, setTimer] = React.useState(30); // Initial timer value in seconds
  const [isTimerRunning, setIsTimerRunning] = React.useState(true);

  async function verifyOTP(otp){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      console.log(otp)
      console.log(location.state.uuid)
      var raw = JSON.stringify({
        "otp": otp,
        "action": "FORGOT_PASSWORD",
        "uuId": location.state.uuid
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_BASE_URL}public/validateOtp`, requestOptions)
        .then(response => response.json())
        .then(res => {
          console.log(res)
          console.log(res.status);
        if(res.status === 200){
          // setUUID(res.data);
          toast(res.message);
          setTimeout(() => {
            navigate('/changePassword',{state:{username:location.state.username, uuid:location.state.uuid,orgId:location.state.orgId}});
          }, 4000);

      }else if(res.status === 409){
          toast("Invalid OTP")
      }
        
        })
        .catch(error => {
          console.log('error', error)
          if (error.code === "ERR_BAD_REQUEST") {
            console.log(error.response);
            console.log("server responded");
            toast("Unauthorized User");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });

}
const handleResendClick = () => {
  // Add logic here to resend OTP
  // For example, you can make an API call to request a new OTP
  // and then start the timer again
  // Don't forget to set setIsTimerRunning(true) when the OTP resend is initiated.
};

async function resentOTP(){
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "action": "FORGOT_PASSWORD",
    "uuId": location.state.uuid,
    "orgId": location.state.orgId
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  fetch(`${process.env.REACT_APP_BASE_URL}public/generateOtp`, requestOptions)
    .then(response => response.json())
    .then(res => {
      console.log(res)
      if(res.status === 200){
        // setUUID(res.data);
        toast(res.message);
        setIsTimerRunning(true)
        
    }else if(res.status === 409){
        toast("Invalid OTP")
    }
    })
    .catch(error => {
      console.log('error', error)
      if (error.code === "ERR_BAD_REQUEST") {
        console.log(error.response);
        console.log("server responded");
        toast("Unauthorized User");
      } else if (error.request) {
        console.log("network error");
      } else {
        console.log(error);
      }
    });
}

React.useEffect(() => {
  let interval;

  if (isTimerRunning) {
    interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);
  } else {
    clearInterval(interval);
  }

  // Clear the interval when the component unmounts
  return () => clearInterval(interval);
}, [isTimerRunning]);

React.useEffect(() => {
  if (timer === 0) {
    setIsTimerRunning(false);
    setTimer(30); // Reset the timer to the initial value when it reaches 0
  }
}, [timer]);

console.log("TIMER",timer)
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={2}
          sm={2}
          md={3}
          sx={{
            bgcolor: '#651fff',
            display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
          }}>
            <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: 100, height: 100, padding:3}}
                variant = 'square'
              />
            <Typography component="h1" variant="h3" sx={{
              paddingTop:3, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              Delivering
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0,  
              color:'#eeeeee'}}>
              specialized
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0,
              color:'#eeeeee'}}>
              medical care
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              at home
            </Typography>
            <Typography component="h1" variant="h6" sx={{
              paddingTop:3, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              Palliative, Rehabilitative
            </Typography>
            <Typography component="h1" variant="h6" sx={{
              paddingTop:0, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              and Chronic Care
            </Typography>
          </Grid>
        <Grid item xs={10} sm={10} md={9} component={Paper} elevation={6} square 
        sx={{bgcolor:colors.grey[200]}}>
          <IconButton onClick={() =>{navigate('/')}}>
          <HomeIcon></HomeIcon>
          </IconButton>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: '25vh', height: '20vh', padding:2}}
                variant = 'square'
              />
            <Typography component="h1" variant="h5" sx={{fontWeight: 'bold'}}>
              Forgot Password
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, padding: 4 }}>
            <Typography component="h1" variant="h10">
              Enter OTP
            </Typography>
            <FormControl fullWidth>
            <TextField sx={{bgcolor:'#ffffff'}}
                margin="normal"
                required
                fullWidth
                id="mobileno"
                name="mobileno"
                autoComplete="mobileno"
                autoFocus
                type="number"
                value={otp}
                onInput={(e) =>{
                  e.target.value = e.target.value.toString().slice(0,6)
                  setError(false);
                }}
                onChange={(newValue) => setOTP(newValue.target.value)}
                error={showError === true}
                helperText={showError === true ? "Please Enter OTP" : ''}
              />  
            </FormControl>
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                onClick ={(e)=>{
                  if(otp === ""){
                    setError(true);
                  }else{
                    verifyOTP(otp)
                  }
                }}
                
              >
                Verify OTP
              </Button>
              <Grid container spacing={4}>
                <Grid item md>
                  <Button variant="primary" onClick={resentOTP} disabled={isTimerRunning}>
                  {isTimerRunning ? `Resend in ${timer} seconds` : "Did n't Recieve OTP ? Click here"}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}