import store from '../../../context/StatePoolService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

const EncounterService = () => {
    const [user, setUser] = store.useState("user");
    console.log("details",user)

    async function createClinicalNote(patient_id,chief_complaint,hpi,treatment_plan,other_details,
        add_instruction,doctor_signature,triage,lab_order,diagnosis,follow_up,follow_up_date,remark,appointmentid,
        other_instructions,status,symptoms_fever,symptoms_cough,symptoms_cold,symptoms_sore_throat,
        symptoms_breathing_difficulty,symptoms_pain,symptoms_confusion,symptoms_speech,symptoms_seizures,
        symptoms_weakness,symptoms_face,symptoms_unconcious,symptoms_chills,symptoms_fatique,symptoms_headache,
        symptoms_appetite,symptoms_temperature,symptoms_heartbeat,symptoms_palpitations,symptoms_limb_swelling,
        symptoms_bleeding,symptoms_wheezing,symptoms_balance_loss,symptoms_joint_pain,symptoms_git_pain,
        symptoms_git_bowel,symptoms_git_diarrhoea,symptoms_git_swallowing,symtoms_git_vomiting,
        symptoms_git_eating_discomfort,symptoms_git_jaundice,symptoms_urine_blood,symptoms_urine_color,
        symptoms_urine_output,symptoms_skin_itchy,symptoms_skin_rash,symptoms_eye_blurred_vision,symptoms_eye_vision_loss,
        lab_tests,prescriptions,vitals_weight,vitals_height,vitals_pulse,vitals_systolic,vitals_diastolic,
        vitals_temperature,vitals_respiratoryRate,vitals_grbs,vitals_waist,vitals_hunit,vitals_wunit,vitals_tempunit,
        vitals_oxygenSaturation,vital_file,pdf_File){
        var myHeaders = new Headers();
            myHeaders.append("Authorization", user.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
            "orgid": user.orgID,
            "doctor_id": user.uuid,
            "patient_id": patient_id,
            "note_type": "doctor_note",
            "cc": chief_complaint,
            "hpi": hpi,
            "treatment_plan": treatment_plan,
            "file":pdf_File,
            "other_details": other_details,
            "add_instruction": add_instruction,
            "signature": doctor_signature,
            "triage": triage,
            "lab_order": lab_order,
            "diagnosis": diagnosis,
            "follow_up": follow_up,
            "follow_up_date": follow_up_date,
            "remark": remark,
            "appointmentid": appointmentid,
            "other_instructions": other_instructions,
            "status": status,
            "symptoms": 
                {
                "orgid": user.orgID,
                "appointmentid": appointmentid,
                "uuid": patient_id,
                "fever": symptoms_fever,
                "cough": symptoms_cough,
                "cold": symptoms_cold,
                "sore_throt": symptoms_sore_throat,
                "breathing_difficulty": symptoms_breathing_difficulty,
                "pain": symptoms_pain,
                "confusion": symptoms_confusion,
                "speech": symptoms_speech,
                "seizures": symptoms_seizures,
                "weakness": symptoms_weakness,
                "face": symptoms_face,
                "unconcious": symptoms_unconcious,
                "chills": symptoms_chills,
                "fatique": symptoms_fatique,
                "headec": symptoms_headache,
                "appetite": symptoms_appetite,
                "tempreture": symptoms_temperature,
                "heartbeat": symptoms_heartbeat,
                "palpitations": symptoms_palpitations,
                "limb_swelling": symptoms_limb_swelling,
                "bleeding": symptoms_bleeding,
                "wheezing": symptoms_wheezing,
                "balance_loss": symptoms_balance_loss,
                "joint_pain": symptoms_joint_pain,
                "git_pain": symptoms_git_pain,
                "git_bowel": symptoms_git_bowel,
                "git_diarrhoea": symptoms_git_diarrhoea,
                "git_swallowing": symptoms_git_swallowing,
                "git_vomiting": symtoms_git_vomiting,
                "git_eating_discomfort": symptoms_git_eating_discomfort,
                "git_jaundice": symptoms_git_jaundice,
                "urine_blood": symptoms_urine_blood,
                "urine_color": symptoms_urine_color,
                "urine_output": symptoms_urine_output,
                "skin_itchy": symptoms_skin_itchy,
                "skin_rash": symptoms_skin_rash,
                "eye_blurred_vision": symptoms_eye_blurred_vision,
                "eye_vision_loss": symptoms_eye_vision_loss,
                "remark": ""
                },
            "lab_tests": lab_tests,
            "prescriptions": prescriptions,
            "vitals": {
                "orgid": user.orgID,
                "appointmentid": appointmentid,
                "uuid": patient_id,
                "weight": vitals_weight,
                "height": vitals_height,
                "pulse": vitals_pulse,
                "systolic": vitals_systolic,
                "diastolic": vitals_diastolic,
                "temperature": vitals_temperature,
                "respiratoryrate": vitals_respiratoryRate,
                "grbs": vitals_grbs,
                "waist": vitals_waist,
                "hunit": vitals_hunit,
                "wunit": vitals_wunit,
                "tempunit": vitals_tempunit,
                "oxygensaturation": vitals_oxygenSaturation,
                "note": "Vitals",
                "file": vital_file
            }
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };
            console.log(raw)
            return fetch(`${user.baseUrl}addClinicalNote`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                return result;
            })
            .catch(error => console.log('error', error));
    }

    async function saveMedicalCampForm(consultation_id,form_data,status,prescriptionFile){
            var myHeaders = new Headers();
            myHeaders.append("Authorization", user.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
            "consultationId": consultation_id,
            "consultationData": JSON.stringify(form_data),
            "consultationStatus": status,
            "prescriptionFile": prescriptionFile
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };

            console.log(raw)

            return fetch(`${user.baseUrl}apt/updateCampConsultation`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                return result;
            })
            .catch(error => console.log('error', error));
        }

    async function getMedicalCampFormDetails(consultation_id){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}apt/getCampConsultation/${consultation_id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function vitalList(patient_uuid){
        var myHeaders = new Headers();
            myHeaders.append("Authorization", user.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
            "uuid": patient_uuid
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };

            return fetch(`${user.baseUrl}public/vitalList`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                return result
            })
            .catch(error => console.log('error', error));
    }

    async function vitalDetails(id){
        var myHeaders = new Headers();
            myHeaders.append("Authorization", user.token);

            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

            return fetch(`${user.baseUrl}public/vitalDetail/${id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                return result;
            })
            .catch(error => console.log('error', error));
    }

    async function updateVitals(id,patient_uuid,apt_id,weight,height,pulse,systolic,diastolic,temperature,
        respiratoryrate,grbs,hunit,wunit,tempunit,oxygensaturation){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "orgid": user.orgID,
        "weight": weight,
        "height": height,
        "pulse": pulse,
        "systolic": systolic,
        "diastolic": diastolic,
        "temperature": temperature,
        "respiratoryrate": respiratoryrate,
        "grbs": grbs,
        "waist": "",
        "hunit": hunit,
        "wunit": wunit,
        "tempunit": tempunit,
        "oxygensaturation": oxygensaturation,
        "note": "Vitals",
        "file": "",
        "appointmentid": apt_id,
        "uuid": patient_uuid,
        "source": "Ubiqare"
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        console.log(raw)
        return fetch(`${user.baseUrl}public/updateVitals/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function deleteVitalByID(id){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}public/deleteVital/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getVideoURL(consultation_id){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getVideoCallURL/${consultation_id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getFaceSheetData(patient_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getFaceSheet/${patient_uuid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getMedicineList(search_term){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("access-control-allow-origin", "*");


        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
        console.log("ffffffffffffffffffffffffffffff",search_term)
        if(search_term === '' || search_term === undefined || search_term === 'undefined'){
           

            const response = await fetch(`https://hermes-2-kbsdxvq3bq-el.a.run.app/v1/snomed/search?s=a&maxHits=50&constraint=%5E91000271104+%7Cclinical+drug%7C`, requestOptions)
            console.log("RESPONSE",response)
            if (!response.ok && response.status === 404) {
                // throw new Error('Exception message');
                return [];
            }else{
                const data = await response.json();
                const result = {status: response.status, data};
                return result
            }
            

            
        }else if(search_term !== ''){
            
            const response = await fetch(`https://hermes-2-kbsdxvq3bq-el.a.run.app/v1/snomed/search?s=${search_term}&maxHits=50&constraint=%5E91000271104+%7Cclinical+drug%7C`, requestOptions)
            console.log("RESPONSE",response)
            if (!response.ok && response.status === 404) {
                // throw new Error('Exception message');
                return [];
            }else{
                const data = await response.json();
                const result = {status: response.status, data};
                console.log("RESULT",result)
                return result
            }
        }
        
    }

    async function getLabTestNameList(search_term){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("access-control-allow-origin", "*");


        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
        console.log("ffffffffffffffffffffffffffffff",search_term)
        if(search_term === '' || search_term === undefined || search_term === 'undefined'){
           

            const response = await fetch(`https://hermes-2-kbsdxvq3bq-el.a.run.app/v1/snomed/search?s=a&maxHits=10&constraint=%3C122869004+%7C+Measurement+procedure+(procedure)+%7C`, requestOptions)
            console.log("RESPONSE",response)
            if (!response.ok && response.status === 404) {
                // throw new Error('Exception message');
                return [];
            }else{
                const data = await response.json();
                const result = {status: response.status, data};
                return result
            }
            

            
        }else if(search_term !== ''){
            
            const response = await fetch(`https://hermes-2-kbsdxvq3bq-el.a.run.app/v1/snomed/search?s=${search_term}&maxHits=10&constraint=%3C122869004+%7C+Measurement+procedure+(procedure)+%7C`, requestOptions)
            console.log("RESPONSE",response)
            if (!response.ok && response.status === 404) {
                // throw new Error('Exception message');
                return [];
            }else{
                const data = await response.json();
                const result = {status: response.status, data};
                console.log("RESULT",result)
                return result
            }
        }
        
    }

    async function MasterDataEncounterSearch(search_term,type){
        var myHeaders = new Headers();
        myHeaders.append("authorization", user.token);
        myHeaders.append("Content-Type", "application/json");


        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
        console.log("ffffffffffffffffffffffffffffff",search_term)
        if(search_term === '' || search_term === undefined || search_term === 'undefined'){
           

            const response = await fetch(`${user.baseUrl}public/masterDataSearch/${type}/?page=0&size=20`, requestOptions)
            console.log("RESPONSE",response)
            if (!response.ok && response.status === 204) {
                // throw new Error('Exception message');
                return [];
            }else{
                const data = await response.json();
                const result = {status: response.status, data};
                return result
            }
            

            
        }else if(search_term !== ''){
            
            const response = await fetch(`${user.baseUrl}public/masterDataSearch/${type}/${search_term}?page=0&size=20`, requestOptions)
            console.log("RESPONSE",response)
            if (!response.ok && response.status === 204) {
                // throw new Error('Exception message');
                return [];
            }else{
                const data = await response.json();
                const result = {status: response.status, data};
                console.log("RESULT",result)
                return result
            }
        }
        
    }
        return{createClinicalNote,vitalDetails,vitalList,updateVitals,deleteVitalByID,getVideoURL,getFaceSheetData,getMedicineList,getLabTestNameList,MasterDataEncounterSearch,saveMedicalCampForm,getMedicalCampFormDetails}
}
export default EncounterService;