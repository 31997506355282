import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import Grid from '@mui/material/Grid';
import LoginLogic from '../../../Auth/Login/LoginLogic';
import { toast } from 'react-toastify';
import Helper from '../../../Helper/Helper';

const {generateString} = Helper();

function PatientDashboardComponent(props){
  const {getPatientDashboardData} = LoginLogic();
  const [rowData, setRowData] = React.useState([])
    const columns = [
        { field: 'id', headerName: 'S.no', width: 50, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1},
        {
          field: 'patientName',
          headerName: 'Patient Name',
          width: 120,
          editable: false,
        },
        {
          field: 'triage',
          headerName: 'Triage',
          width: 120,
          editable: false,
        },
        {
          field: 'tempreture',
          headerName: 'Temp',
          width: 120,
          editable: false,
        },
        {
            field: 'pulse',
            headerName: 'Pulse',
            width: 120,
            editable: false,
          },
          {
            field: 'bp',
            headerName: 'BP',
            width: 120,
            editable: false,
          },
          {
            field: 'spo2',
            headerName: 'SPO2',
            width: 120,
            editable: false,
          },
          {
            field: 'resp_rate',
            headerName: 'Resp.Rate',
            width: 120,
            editable: false,
          },
          {
            field: 'grbs',
            headerName: 'GRBS',
            width: 120,
            editable: false,
          },
          {
            field: 'condition',
            headerName: 'Comorbidity',
            width: 120,
            editable: false,
          },
        
      ];
    React.useEffect(() => {
      getPatientDashboardData(props.token,props.baseUrl,props.orgID).then(res =>{
        console.log(res)
        if(res.status === 200){
          setRowData(res.data)
        }else{
          toast.error("Could not fetch Patient List")
        }
      })
    },[])
    return(  
        <Grid>
        <Typography fontSize={20} fontWeight={'bold'}>Patient Dashboard</Typography>
            <Box sx={{ height: '80vh', width: '100%',bgcolor:'white' }}>
                            
                            <DataGrid
                            getRowId={(row) =>  generateString(0,16)}
                            rows={rowData}
                            columns={columns}
                            pageSize={9}
                            rowsPerPageOptions={[9]}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            />
                        </Box>
        </Grid>
              
            );
}

export default PatientDashboardComponent;