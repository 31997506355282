import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '@mui/material';
import logoUB from '../../../assets/images/logo_ub.png'
import FormControl from '@mui/material/FormControl';
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import PasswordChangeLogic from './ChangePasswordLogic';
import { useLocation} from 'react-router-dom';

const theme = createTheme();

export default function ChangePassword() {
  const location = useLocation();
  const {password,setPassword,confirmPassword,setConfirmPassword,showError,
    setError,errorText,setErrorText,handleSubmit,values,setValues,values1,setValues1,
    handleClickShowPassword,handleClickShowConfirmPassword,handleMouseDownPassword,changePassword,generatePasswordKey} = PasswordChangeLogic()
  const [resetKey,setResetKey] = React.useState("");
  const [user_id,setUserId] = React.useState("");
    React.useEffect(() => {
      generatePasswordKey(location.state.uuid).then(res=>{
        console.log("RESET KEY DATA.................",res)
        setUserId(res.userId)
        setResetKey(res.resetKey)
      })
    },[])
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={2}
          sm={2}
          md={3}
          sx={{
            bgcolor: '#651fff',
            display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
          }}>
            <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: 100, height: 100, padding:3}}
                variant = 'square'
              />
            <Typography component="h1" variant="h3" sx={{
              paddingTop:3, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              Delivering
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0,  
              color:'#eeeeee'}}>
              specialized
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0,
              color:'#eeeeee'}}>
              medical care
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              at home
            </Typography>
            <Typography component="h1" variant="h6" sx={{
              paddingTop:3, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              Palliative, Rehabilitative
            </Typography>
            <Typography component="h1" variant="h6" sx={{
              paddingTop:0, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              and Chronic Care
            </Typography>
          </Grid>
        <Grid item xs={10} sm={10} md={9} component={Paper} elevation={6} square 
        sx={{bgcolor:colors.grey[200]}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: '25vh', height: '20vh', padding:2}}
                variant = 'square'
              />
            <Typography component="h1" variant="h5" sx={{fontWeight: 'bold'}}>
              Change Password
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, padding: 4 }}>
            <Typography component="h1" variant="h10" sx={{paddingTop: 2}}>
              Enter Your New Password
              </Typography>
        <FormControl fullWidth>
        <TextField sx={{bgcolor:'#ffffff'}}
                margin="normal"
                required
                fullWidth
                id="mobileno1"
                name="mobileno1"
                autoComplete="mobileno1"
                autoFocus
                type={values.showPassword ? 'text' : 'password'}
                value={password}
                onInput={(e) =>{
                  setErrorText("Field can't Empty");
                  setError(false);}}
                onChange={(newValue) => setPassword(newValue.target.value)}
                error={showError === true}
                helperText={showError === true ? errorText : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                    </InputAdornment>
                  ),
                }}
              />  
        </FormControl>
        <Typography component="h1" variant="h10" sx={{paddingTop: 2}}>
             Confirm Password
              </Typography>
              <FormControl fullWidth>
        <TextField sx={{bgcolor:'#ffffff'}}
                margin="normal"
                required
                fullWidth
                id="mobileno"
                name="mobileno"
                autoComplete="mobileno"
                type={values1.showConfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onInput={(e) =>{
                  setErrorText("Field can't Empty");
                  setError(false);}}
                onChange={(newValue) => setConfirmPassword(newValue.target.value)}
                error={showError === true}
                helperText={showError === true ? errorText : ''}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values1.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
                    </InputAdornment>
                  ),
                }}
              />  
        </FormControl>
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                onClick ={(e)=>{
                  if(password !== confirmPassword || password === '' || confirmPassword === ''){
                    setErrorText('Password does not match');
                    setError(true);
                  }else{
                    console.log("RESET KEY....",resetKey)
                    console.log("User ID....",user_id)
                    changePassword(resetKey,user_id,password)
                  }
                }}
                
              >
                Proceed
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}