import * as React from 'react';

export default function FAQ(){
    const [html, setHTML] = React.useState({__html: ""});
    
      React.useEffect(() => {
        async function createMarkup() {

        let response;
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
              
        response = await fetch(`${process.env.REACT_APP_BASE_URL}externalLink/public/getContentByType/FAQ/a7581127-8b91-4443-9336-170648eb8951`, requestOptions);
           const backendHtmlString = await response.json()
    
           console.log(backendHtmlString)
            return {__html: backendHtmlString.content};
         }
         createMarkup().then(result => setHTML(result));
      }, []);
    return(
        <div dangerouslySetInnerHTML={html} />
    );
}