import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { colors,Paper } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import ScheduleService from './ScheduleService';
import dayjs from 'dayjs';
import AdminService from '../../AdminDashboard/AdminService';
import { toast } from 'react-toastify';
import Helper from '../../../Helper/Helper';
import { confirmAlert } from 'react-confirm-alert';
import store from '../../../context/StatePoolService'; // Import

const {containsSpecialChars} = Helper();

function NewAppointment(props){

    const {createNewAppointment,getAppointmentSlots} = ScheduleService();
    const {GetMasterSearchData,getPatientList,fileUpload} = AdminService();

    const [component, setComponent] = store.useState('componentState');
    const [consultationType, setConsultationType] = React.useState('');
    const [consultationTypeID, setConsultationTypeID] = React.useState('');
    const [patientName, setPatientName] = React.useState('');
    const [patientID, setPatientID] = React.useState('');
    const [symptoms, setSymptoms] = React.useState('');
    const [purpose, setPurpose] = React.useState('');
    const [feeStatus, setFeeStatus] = React.useState('');
    const [duration, setDuration] = React.useState('');
    const [appointmentFee, setAppointmentFee] = React.useState('');
    const [consultationTypeList, setConsultationTypeList] = React.useState([]);
    const [patientList, setPatientList] = React.useState([]);
    const [feeStatusList, setFeeStatusList] = React.useState([{name:"NOT PAID"},{name:"PAID"}]);
    const [selectedDate,setSelectedDate] = React.useState(dayjs().toISOString().slice(0,10));
    const [timeSlots, setTimeSlots] = React.useState([])
    const [selectedTime, setSelectedTime] = React.useState('')
    const [epochStartTime, setEpochStartTime] = React.useState('')
    const [epochEndTime, setEpochEndTime] = React.useState('')
    const [aptDuration, setAptDuration] = React.useState('')
    const [selectedIndex, setSelectedIndex] = React.useState(-1);
    const [slotMessage, setSlotMessage] = React.useState("")
    const inputRef  = React.useRef(null);
    const [file,setFile ] = React.useState('');
    const [filePath,setFilePath] = React.useState('');
    const [isFileSelected,setIsFileSelected ] = React.useState(false);
    const [errorText,setErrorText] = React.useState({
      consultType:{
        error:false,
        errorText:"Select an option from DropDown"
      },
      patientName:{
        error:false,
        errorText:"Select an option from DropDown"
      },
      symptoms:{
        error:false,
        errorText:"Field can't be empty"
      },
      purpose:{
        error:false,
        errorText:"Field can't be empty"
      },
      feeStatus:{
        error:false,
        errorText:"Select an option from DropDown"
      },
      appointmentFee:{
        error:false,
        errorText:"Field can't be empty"
      },
      selectedTime:{
        error:false,
        errorText:"Please Select the Consultation Slot to Book Appointment"
      },
    })

    function handleValidation(){
      if(consultationType === '' || consultationType !==''){
          if(consultationType === ''){
            setErrorText(previous => ({...previous,consultType:{error:true,
              errorText:"Select an option from DropDown"}}))
              return true;
          }else if(consultationType !== ''){
            setErrorText(previous => ({...previous,consultType:{error:false,
              errorText:"Select an option from DropDown"}}))
          }
        }
      if(patientName === '' || patientName !==''){
          if(patientName === ''){
            setErrorText(previous => ({...previous,patientName:{error:true,
              errorText:"Select an option from DropDown"}}))
              return true;
          }else if(patientName !== ''){
            setErrorText(previous => ({...previous,patientName:{error:false,
              errorText:"Select an option from DropDown"}}))
          }
        }
        if(symptoms === "" || symptoms !== ""){
          if(symptoms === ""){
            setErrorText(previous => ({...previous,symptoms:{error:true,
              errorText:"Field can't be empty"}}))
              return true;
              
          }else if(containsSpecialChars(symptoms) === true){
            setErrorText(previous => ({...previous,symptoms:{error:true,
              errorText:"Field cannot contain Special Characters"}}))
              return true;
              
          }else if(symptoms !== ""){
            setErrorText(previous => ({...previous,symptoms:{error:false,
              errorText:"Field can't be empty"}}))              
          }         
        }
        if(purpose === "" || purpose !== ""){
          if(purpose === ""){
            setErrorText(previous => ({...previous,purpose:{error:true,
              errorText:"Field can't be empty"}}))
              return true;
              
          }else if(containsSpecialChars(purpose) === true){
            setErrorText(previous => ({...previous,purpose:{error:true,
              errorText:"Field cannot contain Special Characters"}}))
              return true;
              
          }else if(purpose !== ""){
            setErrorText(previous => ({...previous,purpose:{error:false,
              errorText:"Field can't be empty"}}))              
          }         
        }
      if(feeStatus === '' || feeStatus !==''){
          if(feeStatus === ''){
            setErrorText(previous => ({...previous,feeStatus:{error:true,
              errorText:"Select an option from DropDown"}}))
              return true;
          }else if(feeStatus !== ''){
            setErrorText(previous => ({...previous,feeStatus:{error:false,
              errorText:"Select an option from DropDown"}}))
          }
        }
        if(appointmentFee === "" || appointmentFee !== ""){
          if(appointmentFee === ""){
            setErrorText(previous => ({...previous,appointmentFee:{error:true,
              errorText:"Field can't be empty"}}))
              return true;
              
          }else if(containsSpecialChars(appointmentFee) === true){
            setErrorText(previous => ({...previous,appointmentFee:{error:true,
              errorText:"Field cannot contain Special Characters"}}))
              return true;
              
          }else if(appointmentFee !== ""){
            setErrorText(previous => ({...previous,appointmentFee:{error:false,
              errorText:"Field can't be empty"}}))              
          }         
        }
        if(selectedTime === '' || selectedTime !==''){
          if(selectedTime === ''){
            setErrorText(previous => ({...previous,selectedTime:{error:true,
              errorText:"Please Select the Consultation Slot to Book Appointment"}}))
              return true;
          }else if(selectedTime !== ''){
            setErrorText(previous => ({...previous,selectedTime:{error:false,
              errorText:"Please Select the Consultation Slot to Book Appointment"}}))
              return false;
          }
        }
      
      console.log("RESULTS............");
    
      }

      
      
        const handleUploadClick = () => {
          // 👇️ open file input box on click of other element
          inputRef.current.click();
        };
      
        const handleFileChange = event => {
          const fileObj = event.target.files && event.target.files[0];
          if (!fileObj) {
            return;
          }
      
          console.log('fileObj is', fileObj);
      
          // 👇️ reset file input
          event.target.value = null;
      
          // 👇️ is now empty
          console.log(event.target.files);
      
          // 👇️ can still access file object here
          console.log(fileObj);
          console.log(fileObj.name);
          fileUpload(fileObj,'CONSULTATION_MEDICAL_HISTORY').then(res =>{
            if(res.status === 200){
              setFilePath(res.path)
            }else(
              toast("File Could not be Uploaded")
            )
          })
          setFile(fileObj)
          setIsFileSelected(true)
        };

    console.log("selected time is",selectedTime)
    console.log("selected Date is",selectedDate)

    const loadSlotData = async (consult_type,selectedDate) => {
      setTimeSlots([])
      console.log("SLOTS INPUT",selectedDate,consult_type)
      await getAppointmentSlots(props.doctorUUID,selectedDate,consult_type).then(res => {
        console.log("SLOTS DATA",res)
        if(res.status === 200){
          setTimeSlots(res.data)
        }else{
          setSlotMessage(res.message)
        }
        
      })
    }
    React.useEffect(() => {
      GetMasterSearchData('CONS_TYPE').then(res => {
        setConsultationTypeList(res)
        console.log("CONSULTATION DROPDOWN",res)
        setConsultationType(res[0].label)
        setConsultationTypeID(res[0].typeID)
        loadSlotData(res[0].label,selectedDate)
      });  
      const loadGetPatientList = async () =>{
        await getPatientList(props.orgID,'a').then(res => setPatientList(res.data.content));
      }

      loadGetPatientList();
    },[])
    console.log("time slots is",timeSlots)
    return(
      <div>
        <input
                style={{display: 'none'}}
                ref={inputRef}
                type="file"
                onChange={handleFileChange}
              />
              <Grid item xs={10} md={10} lg={10}>
        <Grid container spacing={1} marginBottom={1}>
          <Grid item xs={12} md={7} >
          <Typography fontSize={20} fontWeight={'bold'} >New Appointment</Typography>
          </Grid>
        </Grid>
        <Paper sx={{padding:2}}>
        <Grid container spacing={1}>
        <Grid item xs={12} sm={12} lg={12}>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={6} padding={2}>
        <Typography color={colors.grey[900]} fontWeight={'bold'}>Consultation Type</Typography>
            <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setConsultationType(newValue.label);
                          setConsultationTypeID(newValue.typeID);
                          loadSlotData(newValue.label,selectedDate)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={consultationTypeList}
                      value={consultationType}
                      getOptionLabel={(option) => option.label || consultationType}
                      isOptionEqualToValue={(option, value) => option.label === value}
                      renderInput={(params) => <TextField {...params} label="Select Consultation Type" 
                      helperText={errorText.consultType.error === true ? errorText.consultType.errorText:""}
                      error={errorText.consultType.error === true ? true : false}
                      autoFocus={errorText.consultType.error === true ? true : false}
                      />}
  
                    />
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Patient Name</Typography>
            <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event,newValue) => {
                        setPatientName(newValue.name);
                        setPatientID(newValue.uuId);
                      }}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={patientList}
                      value={patientName}
                      getOptionLabel={(option) => option.name || patientName}
                      isOptionEqualToValue={(option, value) => option.name === value}
                      renderInput={(params) => <TextField {...params} label="Select Patient Name" 
                      helperText={errorText.patientName.error === true ? errorText.patientName.errorText:""}
                      error={errorText.patientName.error === true ? true : false}
                      autoFocus={errorText.patientName.error === true ? true : false}
                      />}
                    />
                  </FormControl>
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
        <Typography color={colors.grey[900]} fontWeight={'bold'}>Symptoms</Typography>
                <TextField
                  autoComplete="given-name"
                  name="Symptoms"
                  required
                  fullWidth
                  id="symptoms"
                  label="Enter Symptoms"
                  multiline
                  rows={2}
                  value={symptoms}
                  onChange={(e) =>{setSymptoms(e.target.value)}}
                  helperText={errorText.symptoms.error === true ? errorText.symptoms.errorText:""}
                  error={errorText.symptoms.error === true ? true : false}
                  autoFocus={errorText.symptoms.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Purpose</Typography>
                <TextField
                  required
                  fullWidth
                  id="purpose"
                  label="Enter Purpose"
                  name="Purpose"
                  autoComplete="family-name"
                  multiline
                  rows={2}
                  value={purpose}
                  onChange={(e) =>{setPurpose(e.target.value)}}
                  helperText={errorText.purpose.error === true ? errorText.purpose.errorText:""}
                  error={errorText.purpose.error === true ? true : false}
                  autoFocus={errorText.purpose.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2} paddingTop={3}>
        <Grid item xs={12} sm={6} padding={2}>
            <Typography color={colors.grey[900]} fontWeight={'bold'}>Fee Status</Typography>
            <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {
                          setFeeStatus(newValue.name);
                      }}
                      value={feeStatus}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={feeStatusList}
                      getOptionLabel={(option) => option.name || feeStatus}
                      isOptionEqualToValue={(option, value) => option.name === value}
                      renderInput={(params) => <TextField {...params} label="Select Fee Status" 
                      helperText={errorText.feeStatus.error === true ? errorText.feeStatus.errorText:""}
                      error={errorText.feeStatus.error === true ? true : false}
                      autoFocus={errorText.feeStatus.error === true ? true : false}
                      />}
  
                    />
                  </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} padding={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Appointment Fees</Typography>
            <FormControl fullWidth>
            <TextField
                  required
                  fullWidth
                  value = {appointmentFee}
                  type='number'
                  onChange={(e) => setAppointmentFee(e.target.value)}
                  id="languageskn"
                  label="Enter Fees"
                  name="lang"
                  size='small'
                  helperText={errorText.appointmentFee.error === true ? errorText.appointmentFee.errorText:""}
                  error={errorText.appointmentFee.error === true ? true : false}
                  autoFocus={errorText.appointmentFee.error === true ? true : false}
                />
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={6} padding={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Medical History</Typography>
              <Button variant="contained" endIcon={<KeyboardArrowDown/>} sx={{width:'50%',bgcolor:'#651fff'}} onClick={handleUploadClick}>
                {isFileSelected === true ? file.name : 'Upload'}
                </Button>
              </Grid>
        </Grid>
        </Grid>
        </Grid>
          </Paper>
          <Grid container spacing={2} marginBottom={1}>
            <Grid item xs={12} md={6} lg={4}>
                <Paper sx={{marginTop: 2,width:'100%',height:'auto'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    orientation="portrait"
                    openTo="day"
                    value={selectedDate}
                    onChange={(newValue) => {
                      setSelectedDate(dayjs(newValue).format('YYYY-MM-DD'));
                      loadSlotData(consultationType,dayjs(newValue).format('YYYY-MM-DD'))
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
                </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
            <Paper sx={{marginTop: 2,width:'100%',height:'auto',padding:4}}>
            <Typography color={colors.grey[900]} fontWeight={'bold'} paddingLeft={1} paddingTop={1}>{props.doctorName}</Typography>
            <Typography fontSize={12} color={colors.grey[500]} fontWeight={'bold'} paddingLeft={1} marginBottom={2}>Available Slots</Typography>
            
        {timeSlots.length === 0 ? <Typography>{slotMessage}</Typography> : <div> 
          {timeSlots.map((value,index)=>(
     
            <Button key={index} variant="contained" sx={{width:'15%',bgcolor: selectedIndex === index ? '#45C500' : '#ffffff',margin:0.5,color: selectedIndex === index ? '#ffffff': '#45C500'}} onClick={() => {
              console.log("Time is ",(timeSlots[index].aptSlotStartTime).toLocaleString('en-US', { timeZone: 'Asia/Kolkata'}))
              setSelectedTime((timeSlots[index].aptSlotStartTime).slice(11,16))
              setEpochStartTime(timeSlots[index].aptSlotStartTime)
              setEpochEndTime(timeSlots[index].aptSlotEndTime)
              setAptDuration(timeSlots[index].aptSlotDuration)
              setSelectedIndex(index)}}
              disabled={timeSlots[index].aptBooked}
              >
              {(timeSlots[index].aptSlotStartTime).toLocaleString('en-US', { timeZone: 'Asia/Kolkata' }).slice(11,16)}
              </Button>
          ))}</div>}
          {errorText.selectedTime.error === true ? <Typography sx={{color:'red'}}>{errorText.selectedTime.errorText}</Typography> :<div></div>}
            </Paper>
            
            </Grid>
          </Grid>
          
          <Paper sx={{marginTop:3,paddingBottom:1}}>
                  <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                    <Grid item xs={12} md={6} margin={3}>
                      <Button variant="outlined" sx={{width:'40%',marginRight:2}} onClick={() =>{
                        // setPatientName('')
                        setSymptoms('')
                        setAppointmentFee('')
                        setConsultationType(consultationTypeList[0].label)
                        setPurpose('');
                        setFeeStatus('');
                        setPatientName('')
                        
                      }}>
                          Clear
                        </Button>
                        <Button variant="contained" sx={{width:'40%',bgcolor:'#651fff'}}
                        onClick={()=>{
                        var startEpochTime =new Date(epochStartTime).getTime();
                        console.log("epoch start time",startEpochTime);
                        var endEpochTime = new Date(epochEndTime).getTime();
                        console.log("epoch end time",endEpochTime);
                          var res = handleValidation()
                if(res === false && patientID != '' && consultationTypeID != ''){
                  createNewAppointment(props.doctorUUID,consultationTypeID,
                  patientID,props.doctorUUID,startEpochTime,endEpochTime,aptDuration,purpose,feeStatus,appointmentFee,symptoms,filePath).then(res =>{
                    console.log(res)
                    console.log("e79c1df2-99be-4f37-a451-9fb639125376",consultationTypeID,
                    patientID,"e79c1df2-99be-4f37-a451-9fb639125376",startEpochTime,endEpochTime,aptDuration,purpose,feeStatus,appointmentFee,symptoms,filePath)
                    {res.status === 200 ? confirmAlert({
                      title: res.message,
                      buttons: [
                        {
                          label: 'Okay',
                          onClick: () => {
                            setComponent('Doctor Scheduling')
                          }
                        },
                      ],
                      closeOnEscape:false,
                      closeOnClickOutside:false,
                    })
                  :toast("Error Occured")}
                             
                  })
                }else{
                  toast("Please fill the details to book appointment")
                }
                
              }}
                          
                          // console.log(consultationType,patientName,patientName,startEpochTime,endEpochTime,duration,purpose,feeStatus,appointmentFee,symptoms)
                          // createNewAppointment(consultationType,patientName,patientName,startEpochTime,endEpochTime,duration,purpose,feeStatus,appointmentFee,symptoms)}}
                        >
                          Book Appointment
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
    </Grid>
      </div>
      
    

    );
}

export default NewAppointment;