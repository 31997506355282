import { Typography } from '@mui/material';
import * as React from 'react';
import { styled, useTheme, alpha} from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { colors, Icon, Paper } from '@mui/material';
import BoyRoundedIcon from '@mui/icons-material/BoyRounded';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocalHotelIcon from '@mui/icons-material/LocalHotel';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import person_img from '../../assets/images/person.jpg';
import Grid from '@mui/material/Grid';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Helper from '../../Helper/Helper';
import PatientCardDashboard from './PatientsCard/PatientCardDashboard';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import store from '../../context/StatePoolService';
import Button from '@mui/material/Button';
import LoginLogic from '../../Auth/Login/LoginLogic';
import { toast } from 'react-toastify';

const {Tab,TabPanel,TabsList} = Helper();

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

function DoctorDashboard(props){
    console.log("PI",props.profileImage)
    console.log("DN",props.doctorName)
    const {getDoctorDashboardDetail} = LoginLogic();
    const [user, setUser] = store.useState("user");
    const [component, setComponent] = store.useState('componentState');
    const [dashboardDetails, setDashboardDetails] = React.useState([]);
    const [aptId,setAptId] = React.useState('')
    const [isPreviewShown, setPreviewShown] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const [selectedDate, setSelectedDate] = React.useState(dayjs(new Date()).format('YYYY-MM-DD'));
    const [aptType,setAptType] = React.useState('115')
    const handleDateChange = (newDate) => {
      setSelectedDate(dayjs(newDate).format('YYYY-MM-DD'));
      getDoctorDashboardDetail(props.baseUrl,props.uuid,dayjs(newDate).format('YYYY-MM-DD'),aptType,props.token).then(res =>{
        console.log(res)
        if(res.status === 200){
          setDashboardDetails(res.data)
          setLoading(false)
        }else{
          console.log("error")
          setDashboardDetails([])
        }
      })
    };
    
    const handlePreview=(e,consultationId)=>{
        e.preventDefault();
  
        setPreviewShown(!isPreviewShown);
         // Here we change state
         setAptId(consultationId)
    }
    
    const loadData = () =>{
      getDoctorDashboardDetail(props.baseUrl,props.uuid,dayjs(new Date()).format('YYYY-MM-DD'),aptType,props.token).then(res =>{
        console.log(res)
        if(res.status === 200){
          setDashboardDetails(res.data)
          setLoading(false)
          setPreviewShown(false)
        }else{
          console.log("error")
          setDashboardDetails([])
        }
      })
    }
    React.useEffect(() =>{
      getDoctorDashboardDetail(props.baseUrl,props.uuid,dayjs(new Date()).format('YYYY-MM-DD'),aptType,props.token).then(res =>{
        console.log(res)
        if(res.status === 200){
          setDashboardDetails(res.data)
          setLoading(false)
        }else{
          console.log("error")
          setDashboardDetails([])
        }
      })
    
    },[])
    return(
        <>
        {isLoading === false ? <div>
        <Grid paddingLeft={2} paddingBottom={2}>
            <Grid container spacing = {3}>
                <Grid item xs={0.5} md={0.5} lg={0.5}>
              <Avatar alt="Remy Sharp" src={props.profileImage} sx={{width:30, height:30}}/>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                    <Typography fontWeight={'bold'}>{props.doctorName}</Typography>
                </Grid>
                <Grid item xs={9} md={9} lg={9}></Grid>
            </Grid>
          </Grid>
        
        <Grid container spacing={4} sx={{position:'relative',top:10}}>
        <Grid item xs={3}>
          <Paper elevation={3} sx={{height:'auto',borderRadius:5}}>
          <Grid container spacing={2}>
          <Grid item xs = {4} sx={{padding:2}}>
            <Box sx={{width:60,height:60,bgcolor:'#ede7f6',marginLeft:2,borderRadius:3}}>
            <BoyRoundedIcon sx={{fontSize:60,color:'#651fff'}}/>
            </Box>
              </Grid> 
            <Grid item xs = {8}>
              <Typography fontStyle={'italic'} fontSize={'5vh'} sx={{color:'#651fff'}}>{dashboardDetails.patientCount}</Typography>
            <Typography fontWeight={'bold'} fontSize={'2vh'}>Total Patients</Typography>
            </Grid>
          </Grid>            
          </Paper>
        </Grid>
        <Grid item xs={3}>
        <Paper elevation={3} sx={{height:'auto',borderRadius:5}}> 
        <Grid container spacing={2}>
          <Grid item xs = {4} sx={{padding:2}}>
            <Box sx={{width:60,height:60,bgcolor:'#ede7f6',marginLeft:2,borderRadius:3}}>
            <LocalHospitalIcon sx={{fontSize:60,color:'#651fff'}}/>
            </Box>
              </Grid> 
            <Grid item xs = {8}>
            
              <Typography fontStyle={'italic'} fontSize={'5vh'} sx={{color:'#651fff'}}>{dashboardDetails.teleConsultationCount}</Typography>
            <Typography fontWeight={'bold'} fontSize={'2vh'}>Tele-Consultation</Typography>
            </Grid>
          </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
        <Paper elevation={3} sx={{height:'auto',borderRadius:5}}> 
        <Grid container spacing={2}>
          <Grid item xs = {4} sx={{padding:2}}>
            <Box sx={{width:60,height:60,bgcolor:'#ede7f6',marginLeft:2,borderRadius:3}}>
            <LocalHotelIcon sx={{fontSize:60,color:'#651fff'}}/>
            </Box>
              </Grid> 
            <Grid item xs = {8}>
              <Typography fontStyle={'italic'} fontSize={'5vh'} sx={{color:'#651fff'}}>{dashboardDetails.inPersonCount}</Typography>
            <Typography fontWeight={'bold'} fontSize={'2vh'}>In-Person</Typography>
            </Grid>
          </Grid>
          </Paper>
        </Grid>
        <Grid item xs={3}>
        <Paper elevation={3} sx={{height:'auto',borderRadius:5}}> 
        <Grid container spacing={2}>
          <Grid item xs = {4} sx={{padding:2}}>
            <Box sx={{width:60,height:60,bgcolor:'#ede7f6',marginLeft:2,borderRadius:3}}>
            <LocalPoliceIcon sx={{fontSize:60,color:'#651fff'}}/>
            </Box>
              </Grid> 
            <Grid item xs = {8}>
              <Typography fontStyle={'italic'} fontSize={'5vh'} sx={{color:'#651fff'}}>{dashboardDetails.inCareCount}</Typography>
            <Typography fontWeight={'bold'} fontSize={'2vh'}>In-Care</Typography>
            </Grid>
          </Grid>
          </Paper>
        </Grid>
          </Grid>

        <Grid container spacing={4} sx={{position:'relative',top:10}}>
            <Grid item xs={9} sx={{marginTop:5,marginBottom:5}}>
              <Paper elevation={3} sx={{height:'auto'}}> 
              <Grid container spacing={3}>
                <Grid item xs = {12} md ={7} lg={7}>
                  <Box sx={{ bgcolor: '#fff',padding:2,maxWidth: { xs: 320, sm: 480 }}}>
                    <TabsUnstyled defaultValue={0}>
                    <TabsList sx={{maxWidth:'xs'}}>
                      <Tab onClick={() =>{
                        setAptType('115')
                        getDoctorDashboardDetail(props.baseUrl,props.uuid,selectedDate,'115',props.token).then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            setDashboardDetails(res.data)
                            setLoading(false)
                          }else{
                            console.log("error")
                            setDashboardDetails([])
                          }
                        })
                        }}>Tele-consult</Tab>
                      <Tab onClick={() =>{
                        setAptType('116')
                        getDoctorDashboardDetail(props.baseUrl,props.uuid,selectedDate,'116',props.token).then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            setDashboardDetails(res.data)
                            setLoading(false)
                          }else{
                            console.log("error")
                            setDashboardDetails([])
                          }
                        })
                        }}>In-Person</Tab>
                      <Tab onClick={() =>{
                        setAptType('117')
                        getDoctorDashboardDetail(props.baseUrl,props.uuid,selectedDate,'117',props.token).then(res =>{
                          console.log(res)
                          if(res.status === 200){
                            setDashboardDetails(res.data)
                            setLoading(false)
                          }else{
                            console.log("error")
                            setDashboardDetails([])
                          }
                        })
                        }}>In-Care</Tab>
                    </TabsList>
                    <TabPanel value={0} >
                    <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2}}>
                        <ListItemText
                          primary="Upcoming Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                      </ListItemButton>
                      </ListItem>
                     
                      {dashboardDetails.upcomingAppointments.length !== 0 ? dashboardDetails.upcomingAppointments.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={(e) =>{handlePreview(e,apt.consultationId)}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                      
                      <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2, marginTop:2}}>
                        <ListItemText
                          primary="Pending Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                          <IconButton>
                          {/* {showPending === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> } */}
                          <KeyboardArrowDownIcon />
                          </IconButton>
                      </ListItemButton>
                      </ListItem>
                      {dashboardDetails.delayedAppointments.length !== 0 ? dashboardDetails.delayedAppointments.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={(e) =>{handlePreview(e,apt.consultationId)}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                      <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2,marginTop:2}}>
                        <ListItemText
                          primary="Completed Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                        <IconButton>
                          {/* {showPending === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> } */}
                          <KeyboardArrowDownIcon />
                          </IconButton>
                      </ListItemButton>
                      </ListItem>
                      {dashboardDetails.completedAppointments.length !== 0 ? dashboardDetails.completedAppointments.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={(e) =>{handlePreview(e,apt.consultationId)}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                    </TabPanel>
                    <TabPanel value={1} sx={{padding:1}}>
                    <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2}}>
                        <ListItemText
                          primary="Upcoming Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                      </ListItemButton>
                      </ListItem>
                     
                      {dashboardDetails.upcomingAppointments.length !== 0 ? dashboardDetails.upcomingAppointments.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={(e) =>{handlePreview(e,apt.consultationId)}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                      
                      <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2, marginTop:2}}>
                        <ListItemText
                          primary="Pending Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                          <IconButton>
                          {/* {showPending === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> } */}
                          <KeyboardArrowDownIcon />
                          </IconButton>
                      </ListItemButton>
                      </ListItem>
                      {dashboardDetails.delayedAppointments.length !== 0 ? dashboardDetails.delayedAppointments.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={(e) =>{handlePreview(e,apt.consultationId)}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                      <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2,marginTop:2}}>
                        <ListItemText
                          primary="Completed Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                        <IconButton>
                          {/* {showPending === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> } */}
                          <KeyboardArrowDownIcon />
                          </IconButton>
                      </ListItemButton>
                      </ListItem>
                      {dashboardDetails.completedAppointments.length !== 0 ? dashboardDetails.completedAppointments.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={(e) =>{handlePreview(e,apt.consultationId)}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                    </TabPanel>
                    <TabPanel value={2} sx={{padding:1}}>
                    <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2}}>
                        <ListItemText
                          primary="Upcoming Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                      </ListItemButton>
                      </ListItem>
                     
                      {dashboardDetails.upcomingAppointments.length !== 0 ? dashboardDetails.upcomingAppointments.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={(e) =>{handlePreview(e,apt.consultationId)}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                      
                      <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2, marginTop:2}}>
                        <ListItemText
                          primary="Pending Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                          <IconButton>
                          {/* {showPending === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> } */}
                          <KeyboardArrowDownIcon />
                          </IconButton>
                      </ListItemButton>
                      </ListItem>
                      {dashboardDetails.delayedAppointments.length !== 0 ? dashboardDetails.delayedAppointments.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={(e) =>{handlePreview(e,apt.consultationId)}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                      <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2,marginTop:2}}>
                        <ListItemText
                          primary="Completed Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                        <IconButton>
                          {/* {showPending === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> } */}
                          <KeyboardArrowDownIcon />
                          </IconButton>
                      </ListItemButton>
                      </ListItem>
                      {dashboardDetails.completedAppointments.length !== 0 ? dashboardDetails.completedAppointments.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={(e) =>{handlePreview(e,apt.consultationId)}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                    </TabPanel>
                  </TabsUnstyled>
                  </Box>
                </Grid>
                <Grid item xs = {5} md ={5} lg={5} sx={{padding:2,marginTop:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Select Date"
                      inputFormat="yyyy/MM/dd"
                      value={selectedDate}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField size='small' fullWidth {...params}/>}
                    />
                </LocalizationProvider>
                {isPreviewShown && <PatientCardDashboard consultationid = {aptId} loadFunction={loadData}/>}
                </Grid>
              </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
            <Paper elevation={3} sx={{height:'auto',marginTop:2,padding:2}}> 
               <Typography fontWeight={'bold'} fontSize={14} sx={{padding:1}}>
                To - Do
               </Typography>
               {dashboardDetails.todoList.length !== 0 ? dashboardDetails.todoList.map((todo,index) =>(
                <Box maxWidth={'100%'} minHeight={'20%'} maxHeight={'30%'} key={index}
                sx={{bgcolor:'#E7E7FF',borderRadius:2,margin:1,border:1,borderColor:'#6E6EF7'}}>
                 <Grid padding={2} sx={{justifyContent: "center", display: "flex",alignItems:'center'}}>
                   <Grid item xs={10}>
                       <Typography fontWeight={'bold'} fontSize={'2vh'} align={'justify'} color={'#6E6EF7'}>
                       {todo.type}
                     </Typography>
                   </Grid>
                   <Grid item xs={2}>
                       <Avatar sx={{ bgcolor: '#fff',color:'#212121'}}
                       style={{border:'2px',borderColor:'#6E6EF7'}}
                       >
                       {todo.content}
                     </Avatar>
                   </Grid>
                 </Grid>
               </Box>
               ))
               
                : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                
               
              </Paper>
              <Paper elevation={3} sx={{height:'auto',marginTop:2,marginBottom:6,padding:2}}> 
               <Typography fontWeight={'bold'} fontSize={14} sx={{padding:1}}>
                Quick - Links
               </Typography>
                <Box maxWidth={'100%'} minHeight={'20%'} maxHeight={'30%'}
                 sx={{bgcolor:'#D7F5FC',borderRadius:2,margin:1,border:1,borderColor:'#11C1EA'}}>
                  <Grid padding={2} sx={{justifyContent: "center", display: "flex",alignItems:'center'}}>
                    <Grid item xs={12}>
                        
                      <Button variant='text' onClick={()=>{
                        store.setState("schedulerData",{
                          doctorName: user.name,
                          doctorUUID: user.uuid,
                          profileImage: user.profileImage,
                          speciality: user.profSpeciality
                        })
                        setComponent('ScheduleLeave')}}><Typography fontWeight={'bold'} fontSize={'2vh'} align={'justify'}>
                        Block Calendar
                      </Typography></Button>
                    </Grid>
                    
                  </Grid>
                </Box>
                <Box maxWidth={'100%'} minHeight={'20%'} maxHeight={'30%'}
                 sx={{bgcolor:'#D7F5FC',borderRadius:2,margin:1,border:1,borderColor:'#11C1EA'}}>
                  <Grid padding={2} sx={{justifyContent: "center", display: "flex",alignItems:'center'}}>
                    <Grid item xs={12}>
                        <Button variant='text' onClick={()=>{
                          for(var i = 0; i < user.priviliges.length;i++){
                            if(user.priviliges[i].label === 'Doctor Settings' && user.priviliges[i].checked === true){
                              store.setState("componentDetails",user.priviliges[i])
                              setComponent('Doctor Settings')
                            }
                          }
                          }} ><Typography fontWeight={'bold'} fontSize={'2vh'} align={'justify'}>
                        Change Work Timing
                      </Typography></Button>
                    </Grid>
                    
                  </Grid>
                </Box>

                  
                  
                  
              </Paper>
            </Grid>
          </Grid>
        </div> : <div>Loading....</div> }
        
        </>
        
    );
}
export default DoctorDashboard;