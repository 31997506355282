import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colors } from '@mui/material';
import logoUB from '../../assets/images/logo_ub.png'
import { useNavigate} from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Helper from '../../Helper/Helper';
import PreRegService from './PreRegistrationService';
import { toast } from 'react-toastify';


const theme = createTheme();
const {containsOnlyNumbers,containsSpecialChars} = Helper();

export default function GetOTP() {

  let navigate = useNavigate();
  const [mobileNum,setMobileNum] = React.useState("");
  const {generateOTPForReg} = PreRegService();
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };
  const [errorText,setErrorText] = React.useState({
    phone:{
      error:false,
      errorText:"Field can't be empty",
      verified:true
    },
  })

  function handleValidation(){
    if(mobileNum === "" || mobileNum !== ""){
      if(mobileNum === ""){
        setErrorText(previous => ({...previous,phone:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
      }else if(mobileNum.length < 10){
        setErrorText(previous => ({...previous,phone:{error:true,
          errorText:"Minimum length should be 10 digits",
          verified:false}}))
          return true;
      }else if(containsSpecialChars(mobileNum) === true){
        setErrorText(previous => ({...previous,phone:{error:true,
          errorText:"Field cannot contain Special Characters",
          verified:false}}))
          return true;
      }else if(containsOnlyNumbers(mobileNum) === true && mobileNum !== ""){
        setErrorText(previous => ({...previous,phone:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
          return false
      }       
    }
  }
  

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={2}
          sm={2}
          md={3}
          sx={{
            bgcolor: '#651fff',
            display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
          }}>
            <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: 100, height: 100, padding:3}}
                variant = 'square'
              />
            <Typography component="h1" variant="h3" sx={{
              paddingTop:3, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              Delivering
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0,  
              color:'#eeeeee'}}>
              specialized
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0,
              color:'#eeeeee'}}>
              medical care
            </Typography>
            <Typography component="h1" variant="h3" sx={{
              paddingTop:1, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              at home
            </Typography>
            <Typography component="h1" variant="h6" sx={{
              paddingTop:3, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              Palliative, Rehabilitative
            </Typography>
            <Typography component="h1" variant="h6" sx={{
              paddingTop:0, 
              paddingLeft:3, 
              paddingBottom:0, 
              color:'#eeeeee'}}>
              and Chronic Care
            </Typography>
          </Grid>
        <Grid item xs={10} sm={10} md={9} component={Paper} elevation={6} square 
        sx={{bgcolor:colors.grey[200]}}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: '25vh', height: '20vh', padding:2}}
                variant = 'square'
              />
            <Typography component="h1" variant="h5" sx={{fontWeight: 'bold'}}>
              Pre Registration
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, padding: 4 }}>
            <Typography component="h1" variant="h10">
              Mobile Number
            </Typography>
            <FormControl fullWidth>
            <TextField sx={{bgcolor:'#ffffff'}}
                              value={mobileNum}
                              onChange={(e) => setMobileNum(e.target.value)}
                              autoComplete="given-name"
                              name="mobilenum"
                              required
                              fullWidth
                              id="mobilenum"
                              label="Enter Mobile Number"
                              size='small'
                              type="number"
                              onInput = {(e) =>{
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                              }}
                              helperText={errorText.phone.error === true ? errorText.phone.errorText:""}
                              error={errorText.phone.error === true ? true : false}
                              autoFocus={errorText.phone.error === true ? true : false}
                            />  
            </FormControl>
              
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                onClick ={() =>{
                  var valid = handleValidation()
                  if(valid === false){
                      generateOTPForReg(mobileNum).then(res =>{
                        console.log("otp generated",res)
                        if(res.status === 200){
                          toast.success(res.message)
                          const data = {
                            number: mobileNum,
                          };
                          navigate('/verifyOTP',{ state: data })
                        }else{
                          toast.error(res.message)
                        }
                      })
                  }else{
                    toast.error("Please Check the Mobile Number")
                  }
                }}
                
              >
                Send OTP
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}