import { Typography } from '@mui/material';
import * as React from 'react';
import Grid from '@mui/material/Grid';
import { styled, useTheme, alpha} from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';

const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

function DoctorDetailsBar(props){
    console.log("PI",props.profileImage)
    console.log("DN",props.doctorName)
    return(
        <Grid paddingLeft={2} paddingBottom={2}>
            <Grid container spacing = {3}>
                <Grid item xs={0.5} md={0.5} lg={0.5}>
              <Avatar alt="Remy Sharp" src={props.profileImage} sx={{width:30, height:30}}/>
                </Grid>
                <Grid item xs={2} md={2} lg={2}>
                    <Typography fontWeight={'bold'}>{props.doctorName}</Typography>
                </Grid>
                <Grid item xs={9} md={9} lg={9}></Grid>
            </Grid>
        </Grid>
    );
}
export default DoctorDetailsBar;