import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { colors, Icon, Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import person_img from '../../../assets/images/person.jpg';
import Grid from '@mui/material/Grid';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Helper from '../../../Helper/Helper';
import PatientCardDashboard from '../PatientsCard/PatientsCardUI';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import store from '../../../context/StatePoolService';
import Button from '@mui/material/Button';
const {Tab,TabPanel,TabsList} = Helper();

function AppointmentsComponent(props){
    const [component, setComponent] = store.useState('componentState');
    const [aptId,setAptId] = React.useState('')
    const [isPreviewShown, setPreviewShown] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const handleDateChange = (newDate) => {
      setSelectedDate(dayjs(newDate).format('YYYY-MM-DD'));
    };
    
    const handlePreview=(e,consultationId)=>{
        e.preventDefault();
  
        setPreviewShown(!isPreviewShown);
         // Here we change state
         setAptId(consultationId)
    }
    
    const loadData = () =>{
      setComponent('Doctor Dashboard')
    }
    return(
  <Grid container spacing={4} sx={{position:'relative',top:10}}>
            <Grid item xs={9} sx={{marginTop:5,marginBottom:5}}>
              <Paper elevation={3} sx={{height:'auto'}}> 
              <Grid container spacing={3}>
                <Grid item xs = {12} md ={7} lg={7}>
                  <Box sx={{ bgcolor: '#fff',padding:2,maxWidth: { xs: 320, sm: 480 }}}>
                    <TabsUnstyled defaultValue={0}>
                    <TabsList sx={{maxWidth:'xs'}}>
                      <Tab>Tele-consult</Tab>
                      <Tab>In-Person</Tab>
                      <Tab>In-Care</Tab>
                    </TabsList>
                    <TabPanel value={0} >
                    <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2}}>
                        <ListItemText
                          primary="Upcoming Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                      </ListItemButton>
                      </ListItem>
                      
                      {props.upcomingApt.length !== 0 ? props.upcomingApt.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={(e) =>{handlePreview(e,apt.consultationId)}}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                      
                      <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2, marginTop:2}}>
                        <ListItemText
                          primary="Pending Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                          <IconButton>
                          {/* {showPending === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> } */}
                          <KeyboardArrowDownIcon />
                          </IconButton>
                      </ListItemButton>
                      </ListItem>
                      {props.pendingApt.length !== 0 ? props.pendingApt.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={handlePreview}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                      <ListItem component="div" disablePadding>
                      <ListItemButton sx={{ height: 'auto' ,bgcolor:'#ede7f6',borderRadius:2,marginTop:2}}>
                        <ListItemText
                          primary="Completed Appointments"
                          primaryTypographyProps={{
                            color: '#1212121',
                            fontWeight: 'bold',
                            variant: 'h7',
                          }}
                        />
                        <IconButton>
                          {/* {showPending === true ?  <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> } */}
                          <KeyboardArrowDownIcon />
                          </IconButton>
                      </ListItemButton>
                      </ListItem>
                      {props.completedApt.length !== 0 ? props.completedApt.map((apt,index) =>(
                        <List key={index} sx={{ width: '100%', maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper' }}>
                        <ListItem alignItems="flex-start">  
                          <ListItemButton sx={{height:'auto'}} onClick={handlePreview}>
                          <ListItemAvatar>
                            <Avatar alt="Remy Sharp" src={''} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={apt.patientName}
                            primaryTypographyProps={{
                              color: '#1212121',
                              fontWeight: 'medium',
                              variant: 'h9',
                            }}
                            secondary={
                              <React.Fragment>
                                <Typography
                                  sx={{ display: 'inline' }}
                                  component="span"
                                  variant="body2"
                                  color="text.primary"
                                  align='left'
                                  fontSize={12}
                                >
                                  {apt.patientGender} . {apt.patientAge} . Tele - Consultation
                                </Typography>
                                {<Typography
                                  sx={{ display: 'inline',position:'absolute',right:0}}
                                  component="span"
                                  variant="body2"
                                  color="#8bc34a"
                                  align='right'
                                >
                                  {apt.startDate.slice(11)}
                                </Typography>}
                              </React.Fragment>
                            }
                          />
                          </ListItemButton>
                        </ListItem>
                        <Divider variant="inset" component="li" />
                        </List>
                        )) : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                    </TabPanel>
                    <TabPanel value={1} sx={{padding:1}}>Second page</TabPanel>
                    <TabPanel value={2} sx={{padding:1}}>Third page</TabPanel>
                  </TabsUnstyled>
                  </Box>
                </Grid>
                <Grid item xs = {5} md ={5} lg={5} sx={{padding:2,marginTop:2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Select Date"
                      inputFormat="yyyy/MM/dd"
                      value={selectedDate}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField size='small' fullWidth {...params}/>}
                    />
                </LocalizationProvider>
                {isPreviewShown && <PatientCardDashboard consultationid = {aptId} loadFunction={loadData}/>}
                </Grid>
              </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
            <Paper elevation={3} sx={{height:'auto',marginTop:2,padding:2}}> 
               <Typography fontWeight={'bold'} fontSize={14} sx={{padding:1}}>
                To - Do
               </Typography>
               {props.todoList.length !== 0 ? props.todoList.map((todo,index) =>(
                <Box maxWidth={'100%'} minHeight={'20%'} maxHeight={'30%'} key={index}
                sx={{bgcolor:'#E7E7FF',borderRadius:2,margin:1,border:1,borderColor:'#6E6EF7'}}>
                 <Grid padding={2} sx={{justifyContent: "center", display: "flex",alignItems:'center'}}>
                   <Grid item xs={10}>
                       <Typography fontWeight={'bold'} fontSize={'2vh'} align={'justify'} color={'#6E6EF7'}>
                       {todo.type}
                     </Typography>
                   </Grid>
                   <Grid item xs={2}>
                       <Avatar sx={{ bgcolor: '#fff',color:'#212121'}}
                       style={{border:'2px',borderColor:'#6E6EF7'}}
                       >
                       {todo.content}
                     </Avatar>
                   </Grid>
                 </Grid>
               </Box>
               ))
               
                : <Paper sx={{margin:1, padding:1}}><Typography align='center'>No Data Available</Typography></Paper>}
                
               
              </Paper>
              <Paper elevation={3} sx={{height:'auto',marginTop:2,marginBottom:6,padding:2}}> 
               <Typography fontWeight={'bold'} fontSize={14} sx={{padding:1}}>
                Quick - Links
               </Typography>
                <Box maxWidth={'100%'} minHeight={'20%'} maxHeight={'30%'}
                 sx={{bgcolor:'#D7F5FC',borderRadius:2,margin:1,border:1,borderColor:'#11C1EA'}}>
                  <Grid padding={2} sx={{justifyContent: "center", display: "flex",alignItems:'center'}}>
                    <Grid item xs={12}>
                        
                      <Button variant='text' onClick={()=>{setComponent('ScheduleLeave')}}><Typography fontWeight={'bold'} fontSize={'2vh'} align={'justify'}>
                        Block Calendar
                      </Typography></Button>
                    </Grid>
                    
                  </Grid>
                </Box>
                <Box maxWidth={'100%'} minHeight={'20%'} maxHeight={'30%'}
                 sx={{bgcolor:'#D7F5FC',borderRadius:2,margin:1,border:1,borderColor:'#11C1EA'}}>
                  <Grid padding={2} sx={{justifyContent: "center", display: "flex",alignItems:'center'}}>
                    <Grid item xs={12}>
                        <Button variant='text' onClick={()=>{
                          setComponent('ScheduleLeave')}} ><Typography fontWeight={'bold'} fontSize={'2vh'} align={'justify'}>
                        Change Work Timing
                      </Typography></Button>
                    </Grid>
                    
                  </Grid>
                </Box>

                  
                  
                  
              </Paper>
            </Grid>
          </Grid>
    );
  }

export default AppointmentsComponent;
  