import * as React from 'react';
import { styled, useTheme, alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { colors,Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import DraftsIcon from '@mui/icons-material/Drafts';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminService from '../AdminService';
import { useNavigate} from 'react-router-dom';
import store from '../../../context/StatePoolService';
import Helper from '../../../Helper/Helper';
import dayjs from 'dayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FileUploader } from "react-drag-drop-files";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SignatureCanvas from 'react-signature-canvas';


const {drawerWidth,Main,AppBar,DrawerHeader,Search,SearchIconWrapper,StyledInputBase,patientList,
  generateString,toBase64,containsOnlyNumbers,containsSpecialChars,
  validateEmail,checkPassword} = Helper();
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function DoctorNewRegistration(){

    // const classes = useStyles();
    const [user, setUser] = store.useState("user");
    const [personID,setPersonID] = store.useState('personID');
    const [doctorUUID,setDoctorUUID] = store.useState('doctorUUID');
    const {GetGenderDetails,getAddressList,registerDoctor,addProfessionalDetails,getProfessionalDetails,
      addConsultationDetails,GetMasterSearchData,mapUsertoOrg,GetMasterData,GetPrivateMasterData} = AdminService();
    const [dcomponent, setDComponent] = React.useState();
    // const [dcomponent, setDComponent] = React.useState('consultation');
    const [component, setComponent] = store.useState('componentState');
    const [menuComponent, setMenuComponent] = store.useState('componentMenuDetails');
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    console.log("person id is",personID)
    console.log("doctor id is",doctorUUID)
    
    
    function PersonalDetails(){
    const [genderList, setGenderList] = React.useState([]);
    const [addressList, setAddressList] = React.useState([]);
    const [bgList, setBGList] = React.useState([]);
    const [timezoneList,setTimezoneList] = React.useState([]);
    const [feeList,setFeeList] = React.useState([]);
    const [roleList,setRoleList] = React.useState([]);
    const [salutationList,setSalutationList] = React.useState([]);
    const [prefix, setPrefix] = React.useState('');
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [mobileno, setMobileNum] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [address1, setAddress1] = React.useState('');
    const [address2, setAddress2] = React.useState('');
    const [language, setLanguage] = React.useState('');
    const [timezone, setTimeZone] = React.useState('');
    const [userId,setUserID] = React.useState('');
    const [password,setPassword] = React.useState('');
    const [confirmPassword,setConfirmPassword] = React.useState('');
    const [role,setRole] = React.useState('');
    const [consultationfee, setConsultationFee] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [pincode, setPincode] = React.useState('');
    const [state, setState] = React.useState('Select State');
    const [city, setCity] = React.useState('Select City');
    const [country, setCountry] = React.useState('Select Country');
    const [date, setDate] = React.useState('');
    const [bloodGroup,setBloodGroup] = React.useState('');
    const fileTypes = ["JPG", "PNG", "GIF","JPEG"];
    const [profilePreview, setProfilePreview] = React.useState(false);
    const [objectURL, setObjectURL] = React.useState(null);
    const [signatureURL, setSignatureURL] = React.useState(null);
    const [signaturePreview,setSignaturePreview] = React.useState(false);
    // const [isError,setIsError] = React.useState();
    const [sigPad,setSigPad] = React.useState();
    const [errorText,setErrorText] = React.useState({
      prefix:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      firstName:{
        error:false,
        errorText:"Field cant be empty",
        verified:true
      },
      lastName:{
        error:false,
        errorText:"Field cant be empty",
        verified:true
      },
      gender:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      language:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      dob:{
        error:false,
        errorText:"Select Date of Birth",
        verified:true
      },
      bg:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      pincode:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      addr1:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      addr2:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      email:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      phone:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      timezone:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      fees:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      userId:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      password:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      confirmPassword:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      role:{
        error:false,
        errorText:"Select an option from DropDown",
        verified:true
      },
      profileImage:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },
      sigImage:{
        error:false,
        errorText:"Field can't be empty",
        verified:true
      },

    })

    function handleValidation(){
    if(prefix === '' || prefix !==''){
        if(prefix === ''){
          setErrorText(previous => ({...previous,prefix:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(prefix !== ''){
          setErrorText(previous => ({...previous,prefix:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    if(firstName === "" || firstName !== ""){
          if(firstName === ""){
            setErrorText(previous => ({...previous,firstName:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(containsSpecialChars(firstName) === true){
            setErrorText(previous => ({...previous,firstName:{error:true,
              errorText:"Field cannot contain Special Characters",
              verified:false}}))
              return true;
          }else if(containsOnlyNumbers(firstName) === true){
            setErrorText(previous => ({...previous,firstName:{error:true,
              errorText:"Field cannot contain Numbers",
              verified:false}}))
              return true;
          }else if(firstName !== ""){
            setErrorText(previous => ({...previous,firstName:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))

          }       
      }
    if(lastName === "" || lastName !== ""){
        if(lastName === ""){
          setErrorText(previous => ({...previous,lastName:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(containsSpecialChars(lastName) === true){
          setErrorText(previous => ({...previous,lastName:{error:true,
            errorText:"Field cannot contain Special Characters",
            verified:false}}))
            return true;
        }else if(containsOnlyNumbers(lastName) === true){
          setErrorText(previous => ({...previous,lastName:{error:true,
            errorText:"Field cannot contain Numbers",
            verified:false}}))
            return true;
        }else if(lastName !== ""){
          setErrorText(previous => ({...previous,lastName:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
          
        }         
      }
    if(gender === '' || gender !==''){
        if(gender === ''){
          setErrorText(previous => ({...previous,gender:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(gender !== ''){
          setErrorText(previous => ({...previous,gender:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    if(language === "" || language !== ""){
        if(language === ""){
          setErrorText(previous => ({...previous,language:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
            
        }else if(containsSpecialChars(language) === true){
          setErrorText(previous => ({...previous,language:{error:true,
            errorText:"Field cannot contain Special Characters",
            verified:false}}))
            return true;
        }else if(containsOnlyNumbers(language) === true){
          setErrorText(previous => ({...previous,language:{error:true,
            errorText:"Field cannot contain Numbers",
            verified:false}}))
            return true;
        }else if(language !== ""){
          setErrorText(previous => ({...previous,language:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
          
        }         
      }
    if(date === '' || date !==''){
        if(date === ''){
          setErrorText(previous => ({...previous,dob:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(date !== ''){
          setErrorText(previous => ({...previous,dob:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    if(bloodGroup === '' || bloodGroup !==''){
        if(bloodGroup === ''){
          setErrorText(previous => ({...previous,bg:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(bloodGroup !== ''){
          setErrorText(previous => ({...previous,bg:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    if(pincode === '' || pincode !==''){
        if(pincode === ''){
          setErrorText(previous => ({...previous,pincode:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(pincode !== ''){
          setErrorText(previous => ({...previous,pincode:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    if(address1 === '' || address1 !==''){
        if(address1 === ''){
          setErrorText(previous => ({...previous,addr1:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(address1 !== ''){
          setErrorText(previous => ({...previous,addr1:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
        }
      }
    if(address2 === '' || address2 !==''){
        if(address2 === ''){
          setErrorText(previous => ({...previous,addr2:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(address2 !== ''){
          setErrorText(previous => ({...previous,addr2:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
        }
      }
    if(mobileno === "" || mobileno !== ""){
        if(mobileno === ""){
          setErrorText(previous => ({...previous,phone:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(mobileno.length < 10){
          setErrorText(previous => ({...previous,phone:{error:true,
            errorText:"Minimum length should be 10 digits",
            verified:false}}))
            return true;
        }else if(containsSpecialChars(mobileno) === true){
          setErrorText(previous => ({...previous,phone:{error:true,
            errorText:"Field cannot contain Special Characters",
            verified:false}}))
            return true;
        }else if(containsOnlyNumbers(mobileno) === true && mobileno !== ""){
          setErrorText(previous => ({...previous,phone:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
            
        }       
      }
    if(email === "" || email !== ""){
        if(email === ""){
          setErrorText(previous => ({...previous,email:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(containsOnlyNumbers(email) === true){
          setErrorText(previous => ({...previous,email:{error:true,
            errorText:"Field cannot contain only Numbers",
            verified:false}}))
            return true;
        }else if(validateEmail(email) === false){
          setErrorText(previous => ({...previous,email:{error:true,
            errorText:"Email is not valid should contain '@' and '.'",
            verified:false}}))
            return true;
        }else if(email !== ""){
          setErrorText(previous => ({...previous,email:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))
            
        }        
      }
    if(timezone === '' || timezone !==''){
        if(timezone === ''){
          setErrorText(previous => ({...previous,timezone:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(timezone !== ''){
          setErrorText(previous => ({...previous,timezone:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    if(consultationfee === '' || consultationfee !==''){
        if(consultationfee === ''){
          setErrorText(previous => ({...previous,fees:{error:true,
            errorText:"Select an option from DropDown",
            verified:false}}))
            return true;
        }else if(consultationfee !== ''){
          setErrorText(previous => ({...previous,fees:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
        }
      }
    if(userId === "" || userId !== ""){
        if(userId === ""){
          setErrorText(previous => ({...previous,userId:{error:true,
            errorText:"Field can't be empty",
            verified:false}}))
            return true;
        }else if(containsSpecialChars(userId) === true){
          setErrorText(previous => ({...previous,userId:{error:true,
            errorText:"Field cannot contain Special Characters",
            verified:false}}))
            return true;
        }else if(containsOnlyNumbers(userId) === true){
          setErrorText(previous => ({...previous,userId:{error:true,
            errorText:"Field cannot contain Numbers",
            verified:false}}))
            return true;
        }else if(userId !== ""){
          setErrorText(previous => ({...previous,userId:{error:false,
            errorText:"Field can't be empty",
            verified:true}}))

        }       
      }
    if( password === "" || password !== ""){
      if(password === ""){
        setErrorText(previous => ({...previous,password:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
      }else if(containsOnlyNumbers(password) === true){
        setErrorText(previous => ({...previous,password:{error:true,
          errorText:"Field cannot contain Numbers",
          verified:false}}))
          return true;
      }else if(password.length < 8){
        setErrorText(previous => ({...previous,password:{error:true,
          errorText:"Minimum Length of Password must be 8 characters",
          verified:false}}))
          return true;
      }else if(checkPassword(password) === false){
        setErrorText(previous => ({...previous,password:{error:true,
          errorText:"Password does not match Criteria: One UpperCase,lowercase,number and special character",
          verified:false}}))
          return true;
      }else if(password !== confirmPassword){
        setErrorText(previous => ({...previous,password:{error:true,
          errorText:"Password does not match",
          verified:false}}))
          return true;
      }else if(password !== ""){
        setErrorText(previous => ({...previous,password:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
        }       
      }
    if( confirmPassword === "" || confirmPassword !== ""){
      if(confirmPassword === ""){
        setErrorText(previous => ({...previous,confirmPassword:{error:true,
          errorText:"Field can't be empty",
          verified:false}}))
          return true;
      }else if(containsOnlyNumbers(confirmPassword) === true){
        setErrorText(previous => ({...previous,confirmPassword:{error:true,
          errorText:"Field cannot contain Numbers",
          verified:false}}))
          return true;
      }else if(confirmPassword.length < 8){
        setErrorText(previous => ({...previous,confirmPassword:{error:true,
          errorText:"Minimum Length of Password must be 8 characters",
          verified:false}}))
          return true;
      }else if(checkPassword(confirmPassword) === false){
        setErrorText(previous => ({...previous,confirmPassword:{error:true,
          errorText:"Password does not match Criteria: One UpperCase,lowercase,number and special character",
          verified:false}}))
          return true;
      }else if(password !== confirmPassword){
        setErrorText(previous => ({...previous,confirmPassword:{error:true,
          errorText:"Password does not match",
          verified:false}}))
          return true;
      }else if(confirmPassword !== ""){
        setErrorText(previous => ({...previous,confirmPassword:{error:false,
          errorText:"Field can't be empty",
          verified:true}}))
      }       
    }
    if(role === '' || role !==''){
      if(role === ''){
        setErrorText(previous => ({...previous,role:{error:true,
          errorText:"Select an option from DropDown",
          verified:false}}))
          return true;
      }else if(role !== ''){
        setErrorText(previous => ({...previous,role:{error:false,
          errorText:"Select an option from DropDown",
          verified:true}}))
        }
      }
    if(objectURL === null || objectURL !== null){
      if(objectURL === null){
        setErrorText(previous => ({...previous,profileImage:{error:true,
          errorText:"Please Upload Profile Image",
          verified:false}}))
          return true;
      }else if(objectURL !== null){
        setErrorText(previous => ({...previous,profileImage:{error:false,
          errorText:"Select an option from DropDown",
          verified:true}}))
        }
      }
    if(signatureURL === null || signatureURL !== null){
      if(signatureURL === null){
        setErrorText(previous => ({...previous,sigImage:{error:true,
          errorText:"Please Upload Signature",
          verified:false}}))
          return true;
      }else if(signatureURL !== null){
        setErrorText(previous => ({...previous,sigImage:{error:false,
          errorText:"Select an option from DropDown",
          verified:true}}))
          return false;      
        }
      }
    console.log("RESULTS............");
    
  }

  const handleInputAddressChange = async (event) => {
    var value = event.target.value;
    console.log("Addr Val",value)
    if (value !== '') {
      // Call the search API when there is input
      setAddressList([]);
      await getAddressList(value).then(res =>{
        console.log("ADDRESS LIST",res)
          if(res.status === 200){
            setAddressList(res.content);
          }else{
            setAddressList([]);
          }
                    
        });
    } else {
      // Call the default API when input is cleared
      setAddressList([]);
      await getAddressList('').then(res =>{
        console.log("DOCTOR LIST",res)
          if(res.status === 200){
            setAddressList(res.content);
            }else{
              setAddressList([]);
            }
                    
        });
    }
  };
    
    const handleProfileChange = async (newFile) => {
        setObjectURL(await toBase64(newFile));
      setProfilePreview(true);
    };
    const handleSignatureChange = async (newFile) => {
      setSignatureURL(null)
      setSignatureURL(await toBase64(newFile));
      setSignaturePreview(true)
    };
    const handleDateChange = (newDate) => {
          setDate(dayjs(newDate).format('YYYY-MM-DD'));
    };
      // console.log("IS ERROR status",isError)
      console.log("Error Body",errorText)
      console.log("Error status",errorText.firstName.error)
      console.log("Error Message",errorText.firstName.errorText)
      console.log("GenderLISTttttt",genderList)
      console.log("Gender",gender)
      console.log("City",city)
      console.log("Uploaded file URL is",objectURL)
      console.log("Signature file URL is",signatureURL)
      console.log("date is",date)
      console.log("SigPad is",sigPad)
      console.log("id",doctorUUID)

    React.useEffect(() => {
      GetGenderDetails().then(res => setGenderList(res));
      getAddressList('').then(res => setAddressList(res.data.content));
      GetMasterData('BG').then(res => setBGList(res))
      GetMasterData('TIME_ZONE').then(res => setTimezoneList(res))
      GetMasterData('CONSULTATION_FEE').then(res => setFeeList(res))
      GetMasterData('PRIVILEDGE_TYPE').then(res => setRoleList(res))
      GetMasterData('SALUTAION').then(res => setSalutationList(res))
    },[])
      
      return(
      <Grid item xs={9} md={9} lg={9}>
        <Typography padding={0.5} fontWeight={'bold'}>Personal Details</Typography>
          <Paper sx={{marginTop:1,paddingBottom:1}}>
            <Typography paddingLeft={2} paddingTop={2} color={colors.grey[500]} fontSize={14}>NAME</Typography>
              <Grid item xs={12} md={5} margin={2}>
                <Typography color={colors.grey[900]} fontWeight={'bold'}>Prefix / Salutations</Typography>
                  <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                          setPrefix(newValue.label)}}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={salutationList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField 
                        {...params} label="Select Prefix"
                        helperText={errorText.prefix.error === true ? errorText.prefix.errorText:""}
                        error={errorText.prefix.error === true ? true : false}
                        autoFocus={errorText.prefix.error === true ? true : false}
                        />}
                    />
                </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                    <Typography color={colors.grey[900]} fontWeight={'bold'}>First Name</Typography>
                      <TextField
                        autoComplete="given-name"
                        name="firstName"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        required
                        fullWidth
                        id="firstName"
                        label="Enter First Name"
                        size='small'
                        helperText={errorText.firstName.error === true ? errorText.firstName.errorText:""}
                        error={errorText.firstName.error === true ? true : false}
                        autoFocus={errorText.firstName.error === true ? true : false}
                      />
                  </Grid>
                <Grid item xs={12} md={5} margin={2}>
                    <Typography color={colors.grey[900]} fontWeight={'bold'}>Last Name</Typography>
                      <TextField
                        required
                        fullWidth
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        id="lastName"
                        label="Enter Last Name"
                        name="lastName"
                        autoComplete="family-name"
                        size='small'
                        helperText={errorText.lastName.error === true ? errorText.lastName.errorText:""}
                        error={errorText.lastName.error === true ? true : false}
                        autoFocus={errorText.lastName.error === true ? true : false}
                      />
                  </Grid>
                </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Gender</Typography>
                    <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.genderCode){
                        setGender(newValue.genderCode)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={genderList}
                    getOptionLabel={(option) => option.genderName}
                    renderInput={(params) => <TextField {...params} label="Select Gender"
                        helperText={errorText.gender.error === true ? errorText.gender.errorText:""}
                        error={errorText.gender.error === true ? true : false}
                        autoFocus={errorText.gender.error === true ? true : false}
                    
                    />}
                  />
                  </FormControl>
                  </Grid>
                <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Languages Spoken</Typography>
                    <TextField
                      value={language}
                      onChange={(e) => setLanguage(e.target.value)}
                      required
                      fullWidth
                      id="languageskn"
                      label="Enter Languages Spoken"
                      name="lang"
                      autoComplete="family-name"
                      size='small'
                      helperText={errorText.language.error === true ? errorText.language.errorText:""}
                      error={errorText.language.error === true ? true : false}
                      autoFocus={errorText.language.error === true ? true : false}
                    />
                </Grid>
                </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Date of Birth</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                      label="Date of Birth"
                      inputFormat="yyyy/MM/dd"
                      value={date}
                      onChange={handleDateChange}
                      renderInput={(params) => <TextField size='small' fullWidth {...params} 
                      helperText={errorText.dob.error === true ? errorText.dob.errorText:""}
                      error={errorText.dob.error === true ? true : false}
                      autoFocus={errorText.dob.error === true ? true : false}
                      />}
                    />
                </LocalizationProvider>
                </Grid>
              <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Blood Group</Typography>
                    <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setBloodGroup(newValue.typeID)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={bgList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select Blood Group"
                      helperText={errorText.bg.error === true ? errorText.bg.errorText:""}
                      error={errorText.bg.error === true ? true : false}
                      autoFocus={errorText.bg.error === true ? true : false}
                    />}
                  />
                    </FormControl>
                </Grid>
              </Grid>
            </Paper>

          <Paper sx={{marginTop:1}}>
            <Typography paddingLeft={2} paddingTop={2} color={colors.grey[500]} fontSize={14}>CONTACT</Typography>
            <Grid container spacing={2}>
        <Grid item xs={12} md={5} margin={2}>
        <Typography color={colors.grey[900]} fontWeight={'bold'}>Pincode</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                    onInputChange = {(event, newValue) => {if((newValue && newValue.id) || event.target.value !== 'undefined' || event.target.value !== undefined){
                      console.log("val.......",event.target.value)
      
                      setAddressList([]);
                      getAddressList(event.target.value).then(res =>{
                          console.log("ADDRESS LIST",res)
                            if(res.status === 200){
                              setAddressList(res.data.content);
                            }else{
                              setAddressList([]);
                            }
                                      
                          });
                    }}}
                    onChange={(event, newValue) => {if(newValue && newValue.id){
                      console.log("seletced val.......",newValue.postalCode)
                      setPincode(newValue.postalCode)
                          setCity(newValue.district)
                          setState(newValue.state);
                          setCountry(newValue.country);
                    }}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={addressList}
                    getOptionLabel={(option) => option.postalCode}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.typeID}>
                          {option.postalCode}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Enter PinCode" 
                        helperText={errorText.pincode.error === true ? errorText.pincode.errorText:""}
                        error={errorText.pincode.error === true ? true : false}
                        autoFocus={errorText.pincode.error === true ? true : false}
                    />}
                  />
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>City</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.district){
                          setCity(newValue.district);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={addressList}
                      getOptionLabel={(option) => option.district || ""}
                      renderInput={(params) => <TextField {...params} label={city}/>}
  
                    />
                  </FormControl>           
              </Grid>
              </Grid>
            <Grid container spacing={2}>
        <Grid item xs={12} md={5} margin={2}>
        <Typography color={colors.grey[900]} fontWeight={'bold'}>State</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.state){
                          setState(newValue.state);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={addressList}
                      getOptionLabel={(option) => option.state}
                      renderInput={(params) => <TextField {...params} label={state} />}
  
                    />
                  </FormControl>
              </Grid>
  
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Country</Typography>
              <FormControl fullWidth>
                  <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.country){
                          setCountry(newValue.country);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={addressList}
                      getOptionLabel={(option) => option.country}
                      renderInput={(params) => <TextField {...params} label={country} value={country}/>}
  
                    />
                  </FormControl>
              </Grid>
              </Grid>
            <Grid container spacing={2}>
                    <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>Address Line 1</Typography>
                        <TextField
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                          autoComplete="given-name"
                          name="addr1"
                          required
                          fullWidth
                          id="addr1"
                          label="Enter Address"
                          size='small'
                          helperText={errorText.addr1.error === true ? errorText.addr1.errorText:""}
                          error={errorText.addr1.error === true ? true : false}
                          autoFocus={errorText.addr1.error === true ? true : false}
                        />
                      </Grid>
                    <Grid item xs={12} md={5} margin={2}>
                    <Typography color={colors.grey[900]} fontWeight={'bold'}>Address Line 2</Typography>
                      <TextField
                        value={address2}
                        onChange={(e) => setAddress2(e.target.value)}
                        required
                        fullWidth
                        id="addr2"
                        label="Enter Address"
                        name="addr2"
                        autoComplete="family-name"
                        size='small'
                        helperText={errorText.addr2.error === true ? errorText.addr2.errorText:""}
                        error={errorText.addr2.error === true ? true : false}
                        autoFocus={errorText.addr2.error === true ? true : false}
                      />
                      </Grid>
              </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Mobile Number</Typography>
                    <TextField
                      value={mobileno}
                      onChange={(e) => setMobileNum(e.target.value)}
                      autoComplete="given-name"
                      name="mobilenum"
                      required
                      fullWidth
                      id="mobilenum"
                      label="Enter Mobile Number"
                      size='small'
                      type="number"
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                      }}
                      helperText={errorText.phone.error === true ? errorText.phone.errorText:""}
                      error={errorText.phone.error === true ? true : false}
                      autoFocus={errorText.phone.error === true ? true : false}
                    />
                  </Grid>
                <Grid item xs={12} md={5} margin={2}>
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>Email Address</Typography>
                    <TextField
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      fullWidth
                      id="email"
                      label="Enter Email"
                      name="email"
                      autoComplete="family-name"
                      size='small'
                      helperText={errorText.email.error === true ? errorText.email.errorText:""}
                      error={errorText.email.error === true ? true : false}
                      autoFocus={errorText.email.error === true ? true : false}
                    />
                </Grid>
              </Grid>
            </Paper>

          <Paper sx={{marginTop:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[500]} fontSize={14}>CONSULTATION</Typography>
                  
                    <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Time-Zone</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setTimeZone(newValue.typeID)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={timezoneList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select TimeZone"
                      helperText={errorText.timezone.error === true ? errorText.timezone.errorText:""}
                      error={errorText.timezone.error === true ? true : false}
                      autoFocus={errorText.timezone.error === true ? true : false}
                    />}
                  />
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Consultation Fee</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setConsultationFee(newValue.typeID)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={feeList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select Consultation Fees"
                      helperText={errorText.fees.error === true ? errorText.fees.errorText:""}
                      error={errorText.fees.error === true ? true : false}
                      autoFocus={errorText.fees.error === true ? true : false}
                    />}
                  />
                  </FormControl>
              </Grid>
                    </Grid>
            </Paper>

          <Paper sx={{marginTop:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[500]} fontSize={14}>LOGIN DETAILS</Typography>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              
                  <Typography color={colors.grey[900]} fontWeight={'bold'}>User ID</Typography>
                <TextField
                  required
                  fullWidth
                  value = {userId}
                  onChange={(e) => setUserID(e.target.value)}
                  id="languageskn"
                  label="Enter User ID"
                  name="lang"
                  autoComplete="family-name"
                  size='small'
                  helperText={errorText.userId.error === true ? errorText.userId.errorText:""}
                  error={errorText.userId.error === true ? true : false}
                  autoFocus={errorText.userId.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              </Grid>
                    </Grid>
                    <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Password</Typography>
                <TextField
                  required
                  fullWidth
                  value = {password}
                  onChange={(e) => setPassword(e.target.value)}
                  id="languageskn"
                  label="Enter Password"
                  name="lang"
                  autoComplete="family-name"
                  size='small'
                  helperText={errorText.password.error === true ? errorText.password.errorText:""}
                  error={errorText.password.error === true ? true : false}
                  autoFocus={errorText.password.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Confirm Password</Typography>
                <TextField
                  required
                  fullWidth
                  value = {confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  id="languageskn"
                  label="Confirm Password"
                  name="lang"
                  autoComplete="family-name"
                  size='small'
                  helperText={errorText.confirmPassword.error === true ? errorText.confirmPassword.errorText:""}
                  error={errorText.confirmPassword.error === true ? true : false}
                  autoFocus={errorText.confirmPassword.error === true ? true : false}
                />
              </Grid>
                    </Grid>
                    <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Role</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setRole(newValue.typeID)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={roleList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select Role"
                    helperText={errorText.role.error === true ? errorText.role.errorText:""}
                    error={errorText.role.error === true ? true : false}
                    autoFocus={errorText.role.error === true ? true : false}
                    />}
                  />
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              </Grid>
                    </Grid>
            </Paper>

          <Grid container spacing={2}>
                  <Grid item xs={4} md={4} marginTop={1}>
                  <Paper sx={{height:'40vh'}}>
                    <Typography padding={1} fontWeight={'bold'} fontSize={16}>Profile Picture</Typography>
                    <Grid container spacing={2}>
                    <Grid item xs={5} md={5} padding={1}>
                    {profilePreview === true ? <Avatar alt="Remy Sharp"
                              src={objectURL} 
                              sx={{width:100,height:100, margin:'auto'}}/>: <div></div>}
                    </Grid>
                    <Grid item xs={7} md={7} marginBottom={2} paddingRight={1}>
                      <FileUploader 
                            handleChange={handleProfileChange} 
                            name="file" 
                            types={fileTypes} 
                            multiple={false}
                            children={
                              <Box sx={{width:'100%',height:'auto',border:3,borderColor:'#651fff',borderStyle:'dashed',padding:3}}>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2}
                              >drag and drop to upload</Typography>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2} marginBottom={2}
                              >or</Typography>
                              <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF'}} onClick={handleProfileChange} size={'small'} fullWidth>
                                  Upload
                              </Button>
                            </Box>
                            }
                            />
                      {errorText.profileImage.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.profileImage.errorText}</Typography> : <div></div>}
                      </Grid>
                    </Grid>                   
                    </Paper>
                  </Grid>
                  <Grid item xs={8} md={8} marginTop={1}>
                  <Paper sx={{height:'40vh'}}>
                    <Typography padding={1} fontWeight={'bold'}>Digital Signature</Typography>
                    <Grid container spacing={3}>
                      {signaturePreview === false ? <Grid item xs={6.5} md={6.5} margin={1}>
                        <Box sx={{width:'100%',height:'25vh',border:2,borderColor:'#9e9e9e',borderStyle:'dashed'}}>
                        <SignatureCanvas penColor='black' ref={(ref) => { setSigPad(ref)}}
                          canvasProps={{width: 'auto', height: 'auto', className: 'sigCanvas'}} />
                          </Box>
                        <Grid container spacing={2}>
                          <Grid item xs={5} md={5} lg={5} margin={1}>
                          <Button variant="contained" sx={{bgcolor:'#696CFF'}} onClick={() => {sigPad.clear()}} size={'small'} fullWidth>
                                      Clear
                                  </Button>
                          </Grid>
                          <Grid item xs={5} md={5} lg={5} margin={1}>
                          <Button variant="contained" sx={{bgcolor:'#696CFF'}} onClick={() => {setSignatureURL(sigPad.toDataURL())
                          console.log("Saved file is",sigPad.toDataURL('base64string'))
                          // sigPad.clear()
                          sigPad.fromDataURL(signatureURL)
                          setSignaturePreview(true)
                          }} size={'small'} fullWidth>
                                      Save
                                  </Button>
                          </Grid>
                          </Grid>
                      </Grid> : <Grid item xs={6.5} md={6.5} margin={1}>
                        
                        <Box sx={{width:'100%',height:'25vh'}}>
                        <Avatar alt="Signature Image" variant='square'
                              src={signatureURL} 
                              sx={{width:'auto',height:'auto', margin:'auto',maxHeight:'100%'}}/>
                        <Button variant="contained" sx={{bgcolor:'#696CFF'}} onClick={() =>{
                          setSignatureURL(null)
                          setSignaturePreview(false)
                          }} size={'small'} fullWidth>
                                  Edit
                              </Button>
                        </Box>
                        </Grid>}
                      <Grid item xs={1} md={1}>
                      <Typography fontWeight={'bold'} color={'#9e9e9e'}>or</Typography>
                      </Grid>
                      <Grid item xs={3.5} md={3.5} padding={2}>
                      <FileUploader 
                            handleChange={handleSignatureChange} 
                            name="file" 
                            types={fileTypes} 
                            multiple={false}
                            children={
                              <Box sx={{width:'100%',height:'auto',border:3,borderColor:'#651fff',borderStyle:'dashed',padding:3}}>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2}
                              >drag and drop to upload</Typography>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2} marginBottom={2}
                              >or</Typography>
                              <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF'}} onClick={() => {
                                setSignatureURL(null);
                                handleSignatureChange();
                              }} size={'small'} fullWidth>
                                  Upload
                              </Button>
                            </Box>
                            }
                            />
                      {errorText.sigImage.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.sigImage.errorText}</Typography> : <div></div>}
                      </Grid>
                    </Grid>                
                    </Paper>
                  </Grid>
            </Grid>
  
          <Paper sx={{marginTop:1}}>
                <Grid item xs={12} md={12} margin={2}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                      onClick = {() => {
                      
                      var res = handleValidation();
                      if(res === false){
                        registerDoctor(user.orgID,prefix,userId,password,firstName,lastName,date,
                          email,mobileno,gender,address1,address2,city,state,country,
                          pincode,objectURL,signatureURL,language,role,timezone,consultationfee,bloodGroup).then(res =>{
                            console.log("Result After Reg calling MAP,,,,,",res)
                            {res.ehrStatus === '200 OK' ? mapUsertoOrg(user.orgID,res.uuid):console.log("error....")}
                           {res.ehrStatus === '200 OK' ? confirmAlert({
                            title: 'Doctor Registered Successfully',
                            message: 'Click Proceed to fill Professional Details.',
                            buttons: [
                              {
                                label: 'Proceed',
                                onClick: () => setDComponent('Professional Details')
                              },
                              {
                                label: 'Fill Later',
                                onClick: () => setComponent('Doctor Management')
                              }
                            ],
                            closeOnEscape:false,
                            closeOnClickOutside:false,
                          })
                        :toast("Error Occured")}
                            
                          })
                      }else{
                        toast("Form not Saved, Please fill the Details")
                      }
                      
                      }}
                    >
                      Save
                    </Button>
              </Grid>
            </Paper>
          </Grid>
      
      );
    }
    
    function ProfessionalDetails(){
      const [closeQId, setCloseQId] = React.useState(-1);
      const [closeSId, setCloseSId] = React.useState(-1);
      const [qualificationList,setQualificationList] = React.useState([]);
      const [qualificationDataList,setQualificationDataList] = React.useState([]);
      const [specialityList, setSpecialityList] = React.useState([]);
      const [statelicenseList, setStateLicenseList] = React.useState([]);
      const [profSpecialityList, setProfSpecialityList] = React.useState([]);
      const [docCategoryList, setDocCategoryList] = React.useState([]);
      const [profSpeciality,setProfSpeciality] = React.useState('');
      const [stateLicence,setStateLicence] = React.useState('');
      const [otherLicence,setOtherLicence] = React.useState('');
      const [experience,setExperience] = React.useState('');
      const [regNumber,setRegNumber] = React.useState('');
      const [category,setCategory] = React.useState('');
      const [description,setDescription] = React.useState('');
      const [emergency,setEmergency] = React.useState('');
      const [researches,setResearches] = React.useState('');
      const [awards,setAwards] = React.useState('');
      const [accreditations,setAccreditations] = React.useState('');
      const [specializationDescription,setSpecialization] = React.useState('');
      const [qualificationDescription,setQualifications] = React.useState('');
      const [publications,setPublications] = React.useState('');
      const [workExp,setWorkExp] = React.useState('');
      console.log("personID",personID)
      console.log("Qualification List",qualificationList)

      const handleCloseClickQual = i => {
        setCloseQId(closeQId === i ? -1 : i);
        setQualificationList([
          ...qualificationList.slice(0, i),
          ...qualificationList.slice(i + 1, qualificationList.length)
        ]);
      };
      const [qualification, setQualification] = React.useState('');
      const [speciality, setSpeciality] = React.useState('');
      const [errorText,setErrorText] = React.useState({
        qualification:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        specialization:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        qualificationList:{
          error:false,
          errorText:"Click to Add Qualification and Speciality",
          verified:true
        },
        profSpeciality:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        stateLicence:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        otherLicence:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        experience:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        regNumber:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        category:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        description:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        emergency:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        
      })

      function handleValidation(){
        
        if(qualification === '' || qualification !==''){
            if(qualification === ''){
              setErrorText(previous => ({...previous,qualification:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(qualification !== ''){
              setErrorText(previous => ({...previous,qualification:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(speciality === '' || speciality !==''){
            if(speciality === ''){
              setErrorText(previous => ({...previous,specialization:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(speciality !== ''){
              setErrorText(previous => ({...previous,specialization:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(qualificationList.length === 0 || qualificationList.length !== 0){
            if(qualificationList.length === 0){
              setErrorText(previous => ({...previous,qualificationList:{error:true,
                errorText:"Click to Add Qualification and Speciality",
                verified:false}}))
                return true;
            }else if(qualificationList.length !== 0){
              setErrorText(previous => ({...previous,qualificationList:{error:false,
                errorText:"Click to Add Qualification and Speciality",
                verified:true}}))
            }
          }
        if(profSpeciality === '' || profSpeciality !==''){
            if(profSpeciality === ''){
              setErrorText(previous => ({...previous,profSpeciality:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(profSpeciality !== ''){
              setErrorText(previous => ({...previous,profSpeciality:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(stateLicence === '' || stateLicence !==''){
            if(stateLicence === ''){
              setErrorText(previous => ({...previous,stateLicence:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(stateLicence !== ''){
              setErrorText(previous => ({...previous,stateLicence:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(otherLicence === "" || otherLicence !== ""){
            if(otherLicence === ""){
              setErrorText(previous => ({...previous,otherLicence:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(containsSpecialChars(otherLicence) === true){
              setErrorText(previous => ({...previous,otherLicence:{error:true,
                errorText:"Field cannot contain Special Characters",
                verified:false}}))
                return true;
            }else if(otherLicence !== ""){
              setErrorText(previous => ({...previous,otherLicence:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))              
            }         
          }
        if(experience === "" || experience !== ""){
            if(experience === ""){
              setErrorText(previous => ({...previous,experience:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(containsSpecialChars(experience) === true){
              setErrorText(previous => ({...previous,experience:{error:true,
                errorText:"Field cannot contain Special Characters",
                verified:false}}))
                return true;
            }else if(experience !== ""){
              setErrorText(previous => ({...previous,experience:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
              
            }         
          }
        if(regNumber === "" || regNumber !== ""){
            if(regNumber === ""){
              setErrorText(previous => ({...previous,regNumber:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(regNumber !== ""){
              setErrorText(previous => ({...previous,regNumber:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))              
            }         
          }
        if(category === '' || category !==''){
            if(category === ''){
              setErrorText(previous => ({...previous,category:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(category !== ''){
              setErrorText(previous => ({...previous,category:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(description === "" || description !== ""){
            if(description === ""){
              setErrorText(previous => ({...previous,description:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(containsSpecialChars(description) === true){
              setErrorText(previous => ({...previous,description:{error:true,
                errorText:"Field cannot contain Special Characters",
                verified:false}}))
                return true;
            }else if(description !== ""){
              setErrorText(previous => ({...previous,description:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
              
            }         
          }
        if(emergency === "" || emergency !== ""){
            if(emergency === ""){
              setErrorText(previous => ({...previous,emergency:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(containsSpecialChars(emergency) === true){
              setErrorText(previous => ({...previous,emergency:{error:true,
                errorText:"Field cannot contain Special Characters",
                verified:false}}))
                return true;
            }else if(emergency !== ""){
              setErrorText(previous => ({...previous,emergency:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
                return false;
            }         
          }
        console.log("RESULTS............");
        
        }

      React.useEffect(() => {
        GetPrivateMasterData('qualificationSearch','').then(res => setQualificationDataList(res.data.content))
        GetPrivateMasterData('specialitySearch','').then(res => setSpecialityList(res.data.content))
        GetMasterData('PROF_SPECIALITY','').then(res => setProfSpecialityList(res.data.content))
        GetMasterData('DOC_CATEGORY','').then(res => setDocCategoryList(res.data.content))
        GetMasterData('STATE_LICENCE','').then(res => setStateLicenseList(res.data.content))
      },[])
      
      return(
      <Grid item xs={10} md={10} lg={10}>
                  <Typography padding={0.5} fontWeight={'bold'}>Professional Details</Typography>
                  <Paper sx={{marginTop:1}}>
                  <Typography padding={2} color={colors.grey[400]}>QUALIFICATIONS</Typography>
                  <Grid container spacing={2}>
              <Grid item xs={6} md={4} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Qualification</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.label){
                        setQualification(newValue.label)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={qualificationDataList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select Qualification"
                    helperText={errorText.qualification.error === true ? errorText.qualification.errorText:""}
                    error={errorText.qualification.error === true ? true : false}
                    autoFocus={errorText.qualification.error === true ? true : false}
                    />}
                  />
                  </FormControl>
              </Grid>
              <Grid item xs={6} md={4} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Speciality</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.label){
                        setSpeciality(newValue.label)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={specialityList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select Speciality"
                    helperText={errorText.specialization.error === true ? errorText.specialization.errorText:""}
                    error={errorText.specialization.error === true ? true : false}
                    autoFocus={errorText.specialization.error === true ? true : false}
                    />}
                  />
                  </FormControl>
              </Grid>
              <Grid item xs={4} md={3} marginTop={5}>
              <Button variant="contained" sx={{width:'30%',bgcolor:'#651fff'}} startIcon={<AddIcon/>} onClick={()=>{
                setQualificationList((oldState) => [...oldState, {
                  "qualification": qualification,
                  "specialization": speciality,
                  "userUuId": doctorUUID
                }]);
              }}>
                    Add
                  </Button>
                  {errorText.qualificationList.error === true && 
                  errorText.qualification.error === false  && 
                  errorText.specialization.error === false ? 
                  <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.qualificationList.errorText}</Typography> : <div></div>}
              </Grid>
              <Grid item xs={12} md={12} lg={12} margin={1}>
              <Stack
                  direction={{ xs: 'column', sm: 'column' }}
                  spacing={{ xs: 1, sm: 2, md: 2 }}
                >
                  <>
                {qualificationList.map((qual, i) => (
          <Card key={generateString(i,16)} sx={{width:'25%',height:'auto',border:'1px solid',borderColor:'#696CFF'}}>
                <Grid container spacing={2}>
                <Grid item xs={10} md={9} lg={9}>
                    <CardContent>
                        <Typography fontWeight={'bold'} fontSize={14}>{qual.qualification} - {qual.specialization}</Typography>
                    </CardContent>
                </Grid>
                <Grid item xs={2} md={3} lg={3}>
                    <CardActions disableSpacing>
                        <IconButton
                            onClick={() => handleCloseClickQual(i)}
                            aria-expanded={closeSId === i}
                            aria-label="show more"
                            >
                            <HighlightOffIcon/>
                        </IconButton>
                    </CardActions>
                </Grid>
                </Grid>
                      </Card>
      ))}
      
                  </>
                </Stack>
              
                
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Professional Speciality</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setProfSpeciality(newValue.typeID)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={profSpecialityList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select Professional Speciality"
                    helperText={errorText.profSpeciality.error === true ? errorText.profSpeciality.errorText:""}
                    error={errorText.profSpeciality.error === true ? true : false}
                    autoFocus={errorText.profSpeciality.error === true ? true : false}
                    />}
                  />
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>              
                    
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>State License</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setStateLicence(newValue.typeID)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={statelicenseList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select State License"
                    helperText={errorText.stateLicence.error === true ? errorText.stateLicence.errorText:""}
                    error={errorText.stateLicence.error === true ? true : false}
                    autoFocus={errorText.stateLicence.error === true ? true : false}
                    />}
                  />
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Other License </Typography>
                <TextField
                  required
                  fullWidth
                  value={otherLicence}
                  onChange={(e) => setOtherLicence(e.target.value)}
                  id="email"
                  label="Other License"
                  name="email"
                  autoComplete="family-name"
                  size='small'
                  helperText={errorText.otherLicence.error === true ? errorText.otherLicence.errorText:""}
                  error={errorText.otherLicence.error === true ? true : false}
                  autoFocus={errorText.otherLicence.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Experience in Years</Typography>
                <TextField
                  required
                  fullWidth
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  id="email"
                  label="Experience in Years"
                  name="email"
                  type="number"
                      onInput = {(e) =>{
                        e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                      }}
                  autoComplete="family-name"
                  size='small'
                  helperText={errorText.experience.error === true ? errorText.experience.errorText:""}
                  error={errorText.experience.error === true ? true : false}
                  autoFocus={errorText.experience.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Registration Number</Typography>
                <TextField
                  required
                  fullWidth
                  value={regNumber}
                  onChange={(e) => setRegNumber(e.target.value)}
                  id="email"
                  label="Registration Number"
                  name="email"
                  autoComplete="family-name" 
                  size='small'
                  helperText={errorText.regNumber.error === true ? errorText.regNumber.errorText:""}
                  error={errorText.regNumber.error === true ? true : false}
                  autoFocus={errorText.regNumber.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Category</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                        setCategory(newValue.typeID)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={docCategoryList}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select Category"
                    helperText={errorText.category.error === true ? errorText.category.errorText:""}
                    error={errorText.category.error === true ? true : false}
                    autoFocus={errorText.category.error === true ? true : false}
                    />}
                  />
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Description</Typography>
                <TextField
                  required
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  id="email"
                  label="Enter Description"
                  name="email"
                  autoComplete="family-name" 
                  multiline
                  rows={4}
                  helperText={errorText.description.error === true ? errorText.description.errorText:""}
                  error={errorText.description.error === true ? true : false}
                  autoFocus={errorText.description.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Emergency</Typography>
              <TextField
                  required
                  fullWidth
                  value={emergency}
                  onChange={(e) => setEmergency(e.target.value)}
                  id="email"
                  label="Enter Emergency"
                  name="email"
                  autoComplete="family-name" 
                  multiline
                  rows={4}
                  helperText={errorText.emergency.error === true ? errorText.emergency.errorText:""}
                  error={errorText.emergency.error === true ? true : false}
                  autoFocus={errorText.emergency.error === true ? true : false}
                />
              </Grid>
        </Grid>
                  </Paper>     
                  <Paper sx={{marginTop:1}}>
                  <Typography padding={2} color={colors.grey[400]}>ACHIEVEMENTS</Typography>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Researches</Typography>
              <TextField
                  fullWidth
                  value={researches}
                  onChange={(e) => setResearches(e.target.value)}
                  id="email"
                  label="Enter Researches"
                  name="email"
                  autoComplete="family-name" 
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Awards</Typography>
                <TextField
                  autoComplete="given-name"
                  name="mobilenum"
                  fullWidth
                  value={awards}
                  onChange={(e) => setAwards(e.target.value)}
                  id="mobilenum"
                  label="Enter Awards"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Accreditations</Typography>
                <TextField
                  fullWidth
                  value={accreditations}
                  onChange={(e) => setAccreditations(e.target.value)}
                  id="email"
                  label="Enter Accreditations"
                  name="email"
                  autoComplete="family-name"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Specializations</Typography>
                <TextField
                  fullWidth
                  value={specializationDescription}
                  onChange={(e) => setSpecialization(e.target.value)}
                  id="email"
                  label="Enter Specializations"
                  name="email"
                  autoComplete="family-name"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Qualifications</Typography>
              <TextField
                  fullWidth
                  value={qualificationDescription}
                  onChange={(e) => setQualifications(e.target.value)}
                  id="email"
                  label="Enter Qulaifications"
                  name="email"
                  autoComplete="family-name"
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Publications</Typography>
                <TextField
                  fullWidth
                  value={publications}
                  onChange={(e) => setPublications(e.target.value)}
                  id="email"
                  label="Enter Publications"
                  name="email"
                  autoComplete="family-name" 
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Work Experience</Typography>
                <TextField
                  fullWidth
                  value={workExp}
                  onChange={(e) => setWorkExp(e.target.value)}
                  id="email"
                  label="Enter Work Experience"
                  name="email"
                  autoComplete="family-name" 
                  multiline
                  rows={4}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              
              </Grid>
              
              <Grid item xs={12} md={12} margin={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                onClick = {() => {
                  var res = handleValidation()
                  if(res === false){
                    addProfessionalDetails(doctorUUID,qualificationList,profSpeciality,stateLicence,otherLicence,experience,
                      regNumber,category,description,emergency,researches,awards,accreditations,specializationDescription,
                      qualificationDescription,publications,workExp).then(res =>{
                        console.log(res)
                        {res.status === 200 ? confirmAlert({
                          title: res.message,
                            message: 'Click Proceed to fill Consultation Details.',
                            buttons: [
                              {
                                label: 'Proceed',
                                onClick: () => setDComponent('Consultation Details')
                              },
                              {
                                label: 'Fill Later',
                                onClick: () => setComponent('Doctor Management')
                              }
                            ],
                          closeOnEscape:false,
                          closeOnClickOutside:false,
                        })
                      :toast("Error Occured")}
                    })
                  }else{
                    toast("Form not Saved, Please fill the details")
                  }
                }}
              >
                Save
              </Button>
              </Grid>
        </Grid>
                  </Paper> 
                </Grid>
      );
    }
    
    function ConsultationDetails(){
      const [doWList,setDoWList] = React.useState([]);
      const [consultType, setConsultType] = React.useState('');
      const [organization, setOrganization] = React.useState('');
      const [weekDay,setWeekDay] = React.useState('');
      const [weekDayID,setWeekDayID] = React.useState('');
      const [duration,setDuration] = React.useState('');
      const [coolingHours,setCoolingHours] = React.useState('');
      const [coolingMinutes,setCoolingMinutes] = React.useState('');
      const [startTime, setStartTime] = React.useState(dayjs());
      const [endTime, setEndTime] = React.useState(dayjs());
      const [startDate, setStartDate] = React.useState(dayjs());
      const [endDate, setEndDate] = React.useState(dayjs());
      const [consultationList,setConsultationList]= React.useState([])
      const [consultationTypeList,setConsultationTypeList]= React.useState([]);
      const [organizationTypeList,setOrganizationTypeList]= React.useState([]);
      const [cList,setCList]= React.useState([]);
      const [durationData,setDurationData]= React.useState([
        {
          "label":"15 mins",
          "value":15
        },
        {
          "label":"30 mins",
          "value":30
        },
        {
          "label":"45 mins",
          "value":45
        },
        {
          "label":"1 hr",
          "value":60
        },
        {
          "label":"1 hr 15 mins",
          "value":75
        },
        {
          "label":"1 hr 30 mins",
          "value":90
        },
        {
          "label":"1 hr 45 mins",
          "value":105
        },
        {
          "label":"2 hr",
          "value":120
        },
        {
          "label":"2 hr 15 mins",
          "value":135
        },
        {
          "label":"2 hr 30 mins",
          "value":150
        },
        {
          "label":"2 hr 45 mins",
          "value":165
        },
        {
          "label":"3 hr",
          "value":180
        },
        {
          "label":"3 hr 15 mins",
          "value":195
        },
        {
          "label":"3 hr 30 mins",
          "value":210
        },
        {
          "label":"3 hr 45 mins",
          "value":225
        },
        {
          "label":"4 hrs",
          "value":240
        },
        {
          "label":"4hrs 15 mins",
          "value":255
        },
        {
          "label":"4hrs 30 mins",
          "value":270
        },
        {
          "label":"4 hrs 45 mins",
          "value":285
        },
        {
          "label":"5 hrs",
          "value":300
        },
      ]);
      const [coolingHoursData,setCoolingHourData] = React.useState([
        {
          "label":"00",
          "value":"00"
        },
        {
          "label":"01",
          "value":"01"
        },
        {
          "label":"02",
          "value":"02"
        },
        {
          "label":"03",
          "value":"03"
        },
        {
          "label":"04",
          "value":"04"
        },
        {
          "label":"05",
          "value":"05"
        },
        {
          "label":"06",
          "value":"06"
        },
        {
          "label":"07",
          "value":"07"
        },
        {
          "label":"08",
          "value":"08"
        },
        {
          "label":"09",
          "value":"09"
        },
        {
          "label":"10",
          "value":"10"
        },
        {
          "label":"11",
          "value":"11"
        },
        {
          "label":"12",
          "value":"12"
        },
        {
          "label":"13",
          "value":"13"
        },
        {
          "label":"14",
          "value":"14"
        },
        {
          "label":"15",
          "value":"15"
        },
        {
          "label":"16",
          "value":"16"
        },
        {
          "label":"17",
          "value":"17"
        },
        {
          "label":"18",
          "value":"18"
        },
        {
          "label":"19",
          "value":"19"
        },
        {
          "label":"20",
          "value":"20"
        },
        {
          "label":"21",
          "value":"21"
        },
        {
          "label":"22",
          "value":"22"
        },
        {
          "label":"23",
          "value":"23"
        },
        {
          "label":"24",
          "value":"24"
        },
      ])
      const [coolingMinuteData,setCoolingMinuteData] = React.useState([
        {
          "label":"00",
          "value":"00"
        },
        {
          "label":"05",
          "value":"05"
        },
        {
          "label":"10",
          "value":"10"
        },
        {
          "label":"15",
          "value":"15"
        },
        {
          "label":"20",
          "value":"20"
        },
        {
          "label":"25",
          "value":"25"
        },
        {
          "label":"30",
          "value":"30"
        },
        {
          "label":"35",
          "value":"35"
        },
        {
          "label":"40",
          "value":"40"
        },
        {
          "label":"45",
          "value":"45"
        },
        {
          "label":"50",
          "value":"50"
        },
        {
          "label":"55",
          "value":"55"
        },
      ])

      console.log("start time is",startTime)
      console.log("start date is",startDate)
      console.log("end time is",endTime)
      console.log("end Date is",endDate)
      const handleDelete = (deleteIndex) => {
        setConsultationList((prevData) =>
          prevData.filter((_, index) => index !== deleteIndex)
        );
      };
      const [errorText,setErrorText] = React.useState({
        consultType:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        organization:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        weekDay:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        duration:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        coolingPeriod:{
          error:false,
          errorText:"Please Input in HH:MM format",
          verified:true
        },
      })

      function handleValidation(){
        if(consultType === '' || consultType !==''){
            if(consultType === ''){
              setErrorText(previous => ({...previous,consultType:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(consultType !== ''){
              setErrorText(previous => ({...previous,consultType:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(organization === '' || organization !==''){
            if(organization === ''){
              setErrorText(previous => ({...previous,organization:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(organization !== ''){
              setErrorText(previous => ({...previous,organization:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(weekDayID === '' || weekDayID !==''){
            if(weekDayID === ''){
              setErrorText(previous => ({...previous,weekDay:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(weekDayID !== ''){
              setErrorText(previous => ({...previous,weekDay:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(duration === '' || duration !==''){
            if(duration === ''){
              setErrorText(previous => ({...previous,duration:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(duration !== ''){
              setErrorText(previous => ({...previous,duration:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(coolingHours === '' || coolingHours !==''){
            if(coolingHours === ''){
              setErrorText(previous => ({...previous,coolingPeriod:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(coolingHours !== ''){
              setErrorText(previous => ({...previous,coolingPeriod:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
            }
          }
        if(coolingMinutes === '' || coolingMinutes !==''){
            if(coolingMinutes === ''){
              setErrorText(previous => ({...previous,coolingPeriod:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(coolingMinutes !== ''){
              setErrorText(previous => ({...previous,coolingPeriod:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
                return false;
            }
          }
        
        console.log("RESULTS............");
            
        }
      React.useEffect(() => {
        GetMasterSearchData('WEEK').then(res => setDoWList(res));
        GetMasterSearchData('CONS_TYPE').then(res => setConsultationTypeList(res));
        GetMasterSearchData('ORG_CATEGORY').then(res => setOrganizationTypeList(res));
        
        },[])
      return(
        <Grid item xs={10} md={10} lg={10}>
                  <Typography padding={0.5} fontWeight={'bold'}>Consultation Details</Typography>
                  <Paper sx={{marginTop:1}}>
                  <Typography padding={2} color={colors.grey[400]}>Details</Typography>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Consultation Type</Typography>
              <FormControl fullWidth>
              <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setConsultType(newValue.label);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={consultationTypeList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Select Consultation Type" 
                      helperText={errorText.consultType.error === true ? errorText.consultType.errorText:""}
                      error={errorText.consultType.error === true ? true : false}
                      autoFocus={errorText.consultType.error === true ? true : false}
                      />}
  
                    />
                        </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Organization</Typography>
              <FormControl fullWidth>
              <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setOrganization(newValue.label);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={organizationTypeList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Select Organization Type" 
                      helperText={errorText.organization.error === true ? errorText.organization.errorText:""}
                      error={errorText.organization.error === true ? true : false}
                      autoFocus={errorText.organization.error === true ? true : false}
                      />}
  
                    />
                        </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Week Day</Typography>
              <FormControl fullWidth>
              <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setWeekDay(newValue.label);
                          setWeekDayID(newValue.typeID)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={doWList}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Select Day of Week"
                      helperText={errorText.weekDay.error === true ? errorText.weekDay.errorText:""}
                      error={errorText.weekDay.error === true ? true : false}
                      autoFocus={errorText.weekDay.error === true ? true : false}
                      />}
  
                    />
                        </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Duration</Typography>
              <FormControl fullWidth>
              <Autocomplete
                    onChange = {(event, newValue) => {if(newValue && newValue.value){
                        setDuration(newValue.value)}}}
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    options={durationData}
                    getOptionLabel={(option) => option.label}
                    renderInput={(params) => <TextField {...params} label="Select Duration"
                    helperText={errorText.duration.error === true ? errorText.duration.errorText:""}
                    error={errorText.duration.error === true ? true : false}
                    autoFocus={errorText.duration.error === true ? true : false}
                    />}
                  />
                </FormControl>
              
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Start Time</Typography>
              <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopTimePicker
                          label="Select Start Time"
                          value={startTime}
                          onChange={(newValue) => {
                            setStartTime(dayjs(newValue));
                          }}
                          renderInput={(params) => <TextField {...params} size='small' />}
                        />
                  </LocalizationProvider>
              </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Select End Time</Typography>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopTimePicker
                          label="Select End Time"
                          value={endTime}
                          onChange={(newValue) => {
                            setEndTime(dayjs(newValue));
                          }}
                          renderInput={(params) => <TextField {...params} size='small' />}
                        />
                  </LocalizationProvider>
                        </FormControl>
              </Grid> 
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Start Date</Typography>
              <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    label="Select Start Date"
                    inputFormat="MM/dd/yyyy"
                    value={startDate}
                    onChange={(newDate) => setStartDate(dayjs(newDate))}
                    renderInput={(params) => <TextField {...params} size = 'small'/>}
                  />
              </LocalizationProvider> 
                        </FormControl>
              </Grid>  
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Select End Date</Typography>
              <FormControl fullWidth>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    label="Select End Date"
                    inputFormat="MM/dd/yyyy"
                    value={endDate}
                    onChange={(newDate) => setEndDate(dayjs(newDate))}
                    renderInput={(params) => <TextField {...params} size = 'small'/>}
                  />
              </LocalizationProvider> 
                        </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Consultation Cooling Period</Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={5}>
                  <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setCoolingHours(newValue.label);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={coolingHoursData}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Hour HH" 
                      helperText={errorText.coolingPeriod.error === true ? errorText.coolingPeriod.errorText:""}
                      error={errorText.coolingPeriod.error === true ? true : false}
                      autoFocus={errorText.coolingPeriod.error === true ? true : false}
                      />}
                  />
                  </Grid>
                  <Grid item xs={12} md={0.5}>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item xs={12} md={5}>
                  <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setCoolingMinutes(newValue.label);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={coolingMinuteData}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => <TextField {...params} label="Minutes MM" 
                      helperText={errorText.coolingPeriod.error === true ? errorText.coolingPeriod.errorText:""}
                      error={errorText.coolingPeriod.error === true ? true : false}
                      autoFocus={errorText.coolingPeriod.error === true ? true : false}
                      />}
                  />
                  </Grid>
                </Grid>
              </Grid>            
        </Grid>
              </Paper>
              <Paper sx={{marginTop:1}}>
                <Grid item xs={12} md={12} margin={2}>
                <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff',width:'25%'}}
                      onClick={() =>{
                        var res = handleValidation()
                        if(res === false){
                          setConsultationList(consultationList.concat({
                            "doctorUuId": doctorUUID ,
                            "startTime": startTime.format('hh:mm A'),
                            "endTime": endTime.format('hh:mm A'),
                            "startDate": startDate.format('YYYY/MM/DD'),
                            "endDate": endDate.format('YYYY/MM/DD'),
                            "weekDay": weekDay,
                            "slotType": consultType,
                            "duration":duration,
                            "organization":organization
                          }))
                          let startDateandTime = new Date(startDate.format('YYYY/MM/DD')+" " + startTime.format('hh:mm A'))
                          console.log("startDateandTime is",startDateandTime);
                          let endDateandTime = new Date(endDate.format('YYYY/MM/DD')+" " + endTime.format('hh:mm A'))
                          console.log("endDateandTime is",endDateandTime);
                          var startEpochTime = startDateandTime.getTime();
                          console.log("epoch start time",startEpochTime);
                          var endEpochTime = endDateandTime.getTime();
                          console.log("epoch start time",endEpochTime);
                          setCList(cList.concat({
                            "doctorUuId":doctorUUID,
              
                            "startTime":startEpochTime,
                         
                            "endTime":endEpochTime,
                         
                            "startDate":startEpochTime,
                         
                            "endDate":endEpochTime,
              
                            "coolingperiod": `${coolingHours}:${coolingMinutes}`,
                         
                            "slotDuration":duration,
                         
                             "weekId":{
                                 "typeID":weekDayID
                             },
                         
                             "slotType":consultType
                          }))
                        }else{
                          toast("Form not saved, Please fill the details")
                        }
                      }}
                    >
                      Add
                    </Button>
                  </Box>
              </Grid>
              {consultationList.length != 0 ? 
            <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Consultation Type</StyledTableCell>
            <StyledTableCell align="center">Organization</StyledTableCell>
            <StyledTableCell align="center">Week Day</StyledTableCell>
            <StyledTableCell align="center">Start Date</StyledTableCell>
            <StyledTableCell align="center">End Date</StyledTableCell>
            <StyledTableCell align="center">Start Time</StyledTableCell>
            <StyledTableCell align="center">End Time</StyledTableCell>
            <StyledTableCell align="center">Duration</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {consultationList.map((details,index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {details.slotType}
              </StyledTableCell>
              <StyledTableCell align="center">{details.organization}</StyledTableCell>
              <StyledTableCell align="center">{details.weekDay}</StyledTableCell>
              <StyledTableCell align="center">{details.startDate}</StyledTableCell>
              <StyledTableCell align="center">{details.endDate}</StyledTableCell>
              <StyledTableCell align="center">{details.startTime}</StyledTableCell>
              <StyledTableCell align="center">{details.endTime}</StyledTableCell>
              <StyledTableCell align="center">{details.duration}</StyledTableCell>
              <StyledTableCell align="center">
                <IconButton onClick={() =>{handleDelete(index)}}>
                    <DeleteIcon></DeleteIcon>
                </IconButton>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
       : <div></div>} 
                </Paper>
                <Paper sx={{marginTop:1}}>
                <Grid item xs={12} md={12} margin={2} justify='center'>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                      onClick={()=>{
                        console.log("CLIST DATA....",cList)
                      if(cList.length !== 0){
                        addConsultationDetails(cList).then(res =>{
                          console.log(res)
                          {res.status === 200 ? confirmAlert({
                            title: res.message,
                            message: 'Click Finish to complete.',
                            buttons: [
                              {
                                label: 'Finish',
                                onClick: () => setComponent('Doctor Management')
                              },
                            ],
                            closeOnEscape:false,
                            closeOnClickOutside:false,
                          })
                        :toast("Error Occured")}
                      })
                      }else{
                        toast("Consultation List cannot be empty")
                      }
                        
                    }}
                    >
                      Save
                    </Button>
              </Grid>
                </Paper>  
                  
                </Grid>
      );
    }
    console.log("INSIDE MENU",menuComponent)
    React.useEffect(()=>{
      if(menuComponent[0].checked === true){
        setDComponent(menuComponent[0].label)
        setSelectedIndex(0)
      }else if(menuComponent[1].checked === true){
        setDComponent(menuComponent[1].label)
        setSelectedIndex(1)
      }else if(menuComponent[2].checked === true){
        setDComponent(menuComponent[2].label)
        setSelectedIndex(2)
      }
    },[])
    return(
      <Grid container spacing={2}>
        
                <Grid item xs={2} md={2} lg={2}>
                <Typography padding={0.5} fontWeight={'bold'}>Doctor Registration</Typography>
                    {/* <Paper>
                    
                    <Box display='center' justifyContent="center" padding={2}>
                      <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <Box sx={{width:30,height:30,bgcolor:'#651fff',borderRadius:16,paddingLeft:0.5}}>
                          <EditIcon sx={{color:'#ffff'}}/>
                        </Box>
                        
                      }
                    >
                      <Avatar alt="Remy Sharp" src={person_img} sx={{width:100,height:100}} style={{alignSelf:'center'}}/>
                    </StyledBadge>  
                    </Box>
                    <Typography fontWeight={'bold'} align={'center'}>Dr. Abhijit Sharma</Typography>
                    <Typography align={'center'} color={colors.grey[500]} fontSize={14}>MBBS.MD.DND</Typography>
                    <Typography align={'center'} color={colors.grey[500]} fontSize={14} paddingBottom={1}>Cardiologist</Typography>
                       </Paper> */}
                       <Paper sx={{marginTop:1,borderRadius:3}}>
                        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <List component="nav" aria-label="main mailbox folders"
                        sx={{
                            // selected and (selected + hover) states
                            '&& .Mui-selected, && .Mui-selected:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                            // hover states
                            '& .MuiListItemButton-root:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                          }}
                        >
                            {menuComponent[0].checked === true ? <ListItemButton
                            selected={selectedIndex === 0}
                            onClick={(event) => {
                              // handleListItemClick(event, 0)
                              // setDComponent('personal')
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary={menuComponent[0].label} 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> : null }

                            {menuComponent[1].checked === true ?<ListItemButton
                            selected={selectedIndex === 1}
                            onClick={(event) => {
                              // handleListItemClick(event, 1)
                              // setDComponent('professional')
                              toast("Please fill this form to proceed")
                            }}
                            >
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary={menuComponent[1].label}  
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton> :null}

                            {menuComponent[2].checked === true ?<ListItemButton
                            selected={selectedIndex === 2}
                            onClick={(event) => {
                              // handleListItemClick(event, 2)
                              // setDComponent('consultation');          
                              toast("Please fill this form to proceed")

                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary={menuComponent[2].label}  
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton>:null}

                            
                        </List>
                        </Box>
                    </Paper>
                </Grid>
                {dcomponent === 'Personal Details' ? <PersonalDetails/> : <div></div>}
                {dcomponent === 'Professional Details' ? <ProfessionalDetails/> : <div></div>}
                {dcomponent === 'Consultation Details' ? <ConsultationDetails/> : <div></div>}
            </Grid>
    );
  }
export default DoctorNewRegistration;