import store from '../../../context/StatePoolService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { Await } from 'react-router-dom';

const SearchService = () => {
    
    const [user, setUser] = store.useState("user");
    const [patient, setPatient] = store.useState("patient");
    const [personID,setPersonID] = store.useState('personID');
    const [doctorUUID,setDoctorUUID] = store.useState('doctorUUID');
    const [orgID,setOrgID] = store.useState('orgID');

    async function getPatientPersoanlDetails(patient_uuid){
      
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getPatientDetails/${patient_uuid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
      }

    async function createNewAllergy(org_id,patient_uuid,allergyCode,allergyInfo,
        allergyStatus,reaction_id,reaction_info,start_date,end_date,treatedBy,
        severity,file,note){
            var myHeaders = new Headers();
            myHeaders.append("Authorization", user.token);
            myHeaders.append("Content-Type", "application/json");
            
            var raw = JSON.stringify({
              "orgid": org_id,
              "uuid": patient_uuid,
              "allergy": {
                "typeID": allergyCode
              },
              "alleryinfo": allergyInfo,
              "allergystatus": allergyStatus,
              "reaction": {
                "typeID": reaction_id
              },
              "reactioninfo": reaction_info,
              "startdate": start_date,
              "enddate": end_date,
              "treatedby": treatedBy,
              "severty": severity,
              "file": file,
              "remark": "",
              "appointmentid": "",
              "note": note,
              "source": "Hospital"
            });
            
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };
            console.log(raw)
            return fetch(`${user.baseUrl}public/addAllergy`, requestOptions)
              .then(response => response.json())
              .then(result => {
                console.log(result)
                return result;
            })
              .catch(error => console.log('error', error));
    }

    async function getAllergyList(patient_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "uuid": patient_uuid
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}public/allergyList`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getAllergyDetails(id){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}public/allergyDetail/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function createNewMedicalCondition(org_id,doctor_uuid,doctor_name,patient_uuid,medical_condition,
        start_date,end_date,file,note){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "orgid": org_id,
        "source": "Self",
        "doctoruuid": doctor_uuid,
        "doctorname": doctor_name,
        "uuid": patient_uuid,
        "medicalcondition": medical_condition,
        "startdate": start_date,
        "enddate": end_date,
        "uploadfilename": file,
        "uploadreportname": file,
        "note": note
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        console.log(raw)
        return fetch(`${user.baseUrl}addMedicalCondition`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getMedicalConditionList(patient_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "uuid": patient_uuid
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}listMedicalCondition`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }
    
    async function getMedicialConditionDetails(id){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getMedicalCondition/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }
    
    async function createLabReport(org_id,patient_uuid,patient_name,doctor_name,doctor_uuid,
        labtest_name,file,note,test_date,test_code,test_type){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "orgid": org_id,
        "uuid": patient_uuid,
        "patientname": patient_name,
        "doctorname": doctor_name,
        "doctoruuid": doctor_uuid,
        "appointmentid": "",
        "name": labtest_name,
        "file": file,
        "note": note,
        "testdate": test_date,
        "packagename": labtest_name,
        "source": "",
        "code": test_code,
        "form": "",
        "Instruction": "",
        "test_type": test_type,
        "remark": ""
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        console.log(raw)
        return fetch(`${user.baseUrl}addLabReport`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getLabReportList(patient_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "uuid": patient_uuid
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}listLabReport`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getLabReportDetails(id){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getLabReport/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function createNewPrescription(org_id,patient_uuid,doctor_uuid,doctor_name,source,note,pharmacy_name,
        file,medical_condition,medicineList){
        var myHeaders = new Headers();
            myHeaders.append("Authorization", user.token);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
            "orgid": org_id,
            "patientuuid": patient_uuid,
            "doctoruuid": doctor_uuid,
            "doctorname": doctor_name,
            "source": source,
            "note": note,
            "farmacyname": pharmacy_name,
            "filename": file,
            "medicalconditionid": {
                "typeID": medical_condition
            },
            "medicineList": medicineList
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };
            console.log(raw)
            return fetch(`${user.baseUrl}addPrescription`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                return result;
            })
            .catch(error => console.log('error', error));
    }

    async function getPrescriptionList(patient_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "patientuuid": patient_uuid
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}listPrescription`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getPrescriptionDetails(id){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getPrescription/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function createHealthIndicator(org_id,patient_uuid,pain,tiredness,drowsiness,
        nausea,appetite,breathing,anxiety,generalwellness,depress){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "orgid": org_id,
        "uuid": patient_uuid,
        "pain": pain,
        "tiredness": tiredness,
        "drowsiness": drowsiness,
        "nausea": nausea,
        "appetite": appetite,
        "breathing": breathing,
        "anxiety": anxiety,
        "generalwellness": generalwellness,
        "depress": depress
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        console.log(raw)
        return fetch(`${user.baseUrl}addHealthIndicator`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getHealthIndicatorList(org_id,patient_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "orgid": org_id,
        "uuid": patient_uuid
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}listHealthIndicator`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getHealthIndicatorDetail(id){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getHealthIndicator/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function updateHealthIndicator(id,org_id,patient_uuid,pain,tiredness,drowsiness,
        nausea,appetite,breathing,anxiety,generalwellness,depress){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "orgid": org_id,
        "uuid": patient_uuid,
        "pain": pain,
        "tiredness": tiredness,
        "drowsiness": drowsiness,
        "nausea": nausea,
        "appetite": appetite,
        "breathing": breathing,
        "anxiety": anxiety,
        "generalwellness": generalwellness,
        "depress": depress
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        console.log(raw)
        return fetch(`${user.baseUrl}updateHealthIndicator/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function deleteHealthIndicator(id){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}deleteHealthIndicator/${id}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }
    async function getPastAppointmentList(patient_uuid,startDate,endDate){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}pastAppointment/${patient_uuid}/${startDate}/${endDate}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getUpcomingAppointmentList(patient_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}upComingAppointment/${patient_uuid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getPendingAppointmentList(patient_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}pendingAppointment/${patient_uuid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }


    async function addFileCabinet(orgid,doctor_uuid,patient_uuid,filePath,fileName,doc_type,description,source){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "orgid": orgid,
        "doctoruuid": doctor_uuid,
        "patientuuid": patient_uuid,
        "filepath": filePath,
        "filename": fileName,
        "documenttype": {
            "typeID": doc_type,
        },
        "description": description,
        "source": source
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        console.log(raw)
        return fetch(`${user.baseUrl}addFileCabinet`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getFileCabinetList(patient_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}listFileCabinet/${patient_uuid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function editFileCabinet(fileCabinetId,orgid,doctor_uuid,patient_uuid,filePath,fileName,doc_type,description,source){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "fileCabinetId": fileCabinetId,
        "orgid": orgid,
        "doctoruuid": doctor_uuid,
        "patientuuid": patient_uuid,
        "filepath": filePath,
        "filename": fileName,
        "documenttype": {
            "typeID": doc_type,
        },
        "description": description,
        "source": source
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        console.log(raw)
        return fetch(`${user.baseUrl}updateFileCabinet`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getFileCabinetDetails(filecabinetId){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getFileCabinet/${filecabinetId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function deleteFileCabinet(fileCabinetId){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}deleteFileCabinet/${fileCabinetId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getShareFileDetail(fileCabinetId){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getFileCabinetAssignedDoctorList/${fileCabinetId}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function addAccesstoFile(patient_uuid,orgid,permission,expiry_time,fileCabinetId,doctor_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "type": "filecabinet",
        "uuid": patient_uuid,
        "orgid": orgid,
        "permission": permission,
        "expiry_time": expiry_time,
        "fileCabinetInfo": {
            "fileCabinetId": fileCabinetId
        },
        "doctorInfo": {
            "uuId": doctor_uuid
        }
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };
        console.log(raw)
        return fetch(`${user.baseUrl}assignDoctorToFilecabinet`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function removeAccesstoFile(fileCabinetId,doctor_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}removeDoctorFromFileCabinet/${fileCabinetId}/${doctor_uuid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    async function getSharedFileListForDoctor(doctor_uuid){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", user.token);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        return fetch(`${user.baseUrl}getAssignedFileCabinetList/${doctor_uuid}`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    return {getPatientPersoanlDetails,createNewAllergy,getAllergyDetails,getAllergyList,
    createNewMedicalCondition,getMedicalConditionList,getMedicialConditionDetails,createLabReport,
    getLabReportDetails,getLabReportList,createNewPrescription,getPrescriptionList,getPrescriptionDetails,getSharedFileListForDoctor,
    createHealthIndicator,getHealthIndicatorList,getHealthIndicatorDetail,updateHealthIndicator,deleteHealthIndicator,getPastAppointmentList,getUpcomingAppointmentList,
    getPendingAppointmentList,addFileCabinet,deleteFileCabinet,editFileCabinet,getFileCabinetDetails,getFileCabinetList,getShareFileDetail,addAccesstoFile,removeAccesstoFile}

}

export default SearchService;