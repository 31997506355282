
const PreRegService = () => {
    async function GetGenderDetails(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        return fetch(`${process.env.REACT_APP_BASE_URL}/public/masterDataSearch/GENDER`, requestOptions)
          .then(response => response.json())
          .then(data => {
              console.log('Success:', data.content);
              return data.content;
          })
          .catch(error => console.log('error', error));
      
    }

    async function GetMasterData(masterDataType){
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      return fetch(`${process.env.REACT_APP_BASE_URL}/public/masterDataSearch/${masterDataType}`, requestOptions)
        .then(response => response.json())
        .then(data => {
          console.log('MasterData:', data.content);
          return data.content;
      })
      .catch(error => console.log('error', error));
    }

    async function getAddressList(){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        return fetch(`${process.env.REACT_APP_BASE_URL}public/addressSearch`, requestOptions)
          .then(response => response.json())
          .then(result => {
            console.log("genderdata",result.content);
            return result;
          })
          .catch(error => console.log('error', error));
      }

    async function generateOTPForReg(mobileno){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "action":"PATIENT_REGISTRATION",
            "mobileNumber":mobileno
            });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        return fetch(`${process.env.REACT_APP_BASE_URL}public/generateOtp`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }
    async function validateOTPForReg(otp){
        var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
            "otp": otp,
            "action":"PATIENT_REGISTRATION"
            });

            var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };

            return fetch(`${process.env.REACT_APP_BASE_URL}public/validateOtp`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                return result;
            })
            .catch(error => console.log('error', error));
    }

    async function patientPreReg(firstName,lastName,prefix,genderCode,email,mobileno,dateOfBirth,bloodgroup,
        hospitalNumber,address,city,state,country,pincode,clinicId,idType,idNumber,idFatherName,
        profileImage,emergencyName,emergencyContact,emergencyRelation,doctorName,doctorNumber){
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
        "userType": "PATIENT",
        "prefix": prefix,
        "givenName": firstName,
        "middleName": lastName,
        "gender": {
            "genderCode": genderCode
        },
        "email": email,
        "dateOfBirth": dateOfBirth,
        "dobStatus": "age",
        "bloodgroup": {
            "typeID": bloodgroup
        },
        "patientId": hospitalNumber,
        "address": {
            "type": "permanent",
            "address1": address,
            "address2": "",
            "taluk": city,
            "district": city,
            "city": city,
            "state": state,
            "country": country,
            "landMark": "",
            "postalCode": pincode,
            "postOffice": ""
        },
        "partnerId": clinicId,
        "idproof": {
            "type": idType,
            "idNumber": idNumber,
            "father_name": idFatherName
        },
        "profileImage": profileImage,
        "phoneNumber": mobileno,
        "emergencyName": emergencyName,
        "emergencyNumber": emergencyContact,
        "emergencyRelation": emergencyRelation,
        "doctorName": doctorName,
        "doctorNumber": doctorNumber
        });

        var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
        };

        console.log(raw)

        return fetch(`${process.env.REACT_APP_BASE_URL}person/public/PreRegisterPatient`, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)
            return result;
        })
        .catch(error => console.log('error', error));
    }

    
return {GetGenderDetails,GetMasterData,getAddressList,generateOTPForReg,validateOTPForReg,patientPreReg}
}

export default PreRegService;