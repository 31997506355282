import * as React from 'react';
import { styled, useTheme, alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { colors,IconButton,Paper } from '@mui/material';
import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminService from '../AdminService';
import { useNavigate} from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import store from '../../../context/StatePoolService';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { FileUploader } from "react-drag-drop-files";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Avatar from '@mui/material/Avatar';
import Helper from '../../../Helper/Helper';
import { DeleteOutlineRounded, DeleteRounded, DeleteSharp } from '@mui/icons-material';

const {toBase64,generateString,containsOnlyNumbers,containsSpecialChars,
  validateEmail,checkPassword} = Helper();

function PartnerDetails(){

    const {getAddressList,GetMasterSearchData,fileUpload,
      updateTemplateDetails,getOrgDetails,getConfigurationDetails,
    updateConfigurationDetails,updateOrg,getTemplateDetails} = AdminService();
    const [dcomponent, setDComponent] = React.useState('organization');
    const [component, setComponent] = store.useState('componentState');
    const [orgCreatedID, setOrgCreatedID] = React.useState('');
    const [orgID,setOrgID] = store.useState('orgID');
    console.log("org inside",orgID)
    

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const handleListItemClick = (event,index) => {
      setSelectedIndex(index);
  };
  
    const StyledBadge = styled(Badge)(({ theme }) => ({
      '& .MuiBadge-badge': {
        right: -3,
        top: 55,
        border: `2px solid white`,
        padding: '0 4px',
      },
    }));
    function OrganizationDetails(){
      const [orgName, setOrgName] = React.useState('');
      const [contactEmail, setContactEmail] = React.useState('');
      const [contactPhone, setContactPhone] = React.useState('');
      const [contactPerson, setContactPerson] = React.useState('');
      const [contactUsEmail, setContactUsEmail] = React.useState('');
      const [contactUsPhone, setContactUsPhone] = React.useState('');
      const [orgCategory, setOrgCategory] = React.useState('');
      const [display_name, setDisplayName] = React.useState('');
      const [addressList, setAddressList] = React.useState([]);
      const [address1, setAddress1] = React.useState('');
      const [pincode, setPincode] = React.useState('');
      const [state, setState] = React.useState('Select State');
      const [city, setCity] = React.useState('Select City');
      const [country, setCountry] = React.useState('Select Country');
      const [organizationTypeList,setOrganizationTypeList]= React.useState([]);
      const [orgCategoryID, setOrgCategoryID] = React.useState('');
      const [logoPreview, setLogoPreview] = React.useState(false);
      const [logoURL, setLogoURL] = React.useState(null);
      const [logoPath, setLogoPath] = React.useState(null);
      const [otherImagePath, setOtherImagePath] = React.useState(null);
      const [otherImagePreview, setOtherImagePreview] = React.useState(false);
      const [otherImageURL, setOtherImageURL] = React.useState(null);
      const fileTypes = ["JPG", "PNG", "GIF","JPEG"];
      const [errorText,setErrorText] = React.useState({
        orgName:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        orgCategoryID:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        display_name:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        address1:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        pincode:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        contactPerson:{
          error:false,
          errorText:"Select Date of Birth",
          verified:true
        },
        contactPhone:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        contactEmail:{
          error:false,
          errorText:"Select an option from DropDown",
          verified:true
        },
        contactUsEmail:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        contactUsPhone:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        logoPath:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        }
      })
  
      function handleValidation(){

      if(orgName === "" || orgName !== ""){
          if(orgName === ""){
            setErrorText(previous => ({...previous,orgName:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(containsSpecialChars(orgName) === true){
            setErrorText(previous => ({...previous,orgName:{error:true,
              errorText:"Field cannot contain Special Characters",
              verified:false}}))
              return true;
          }else if(containsOnlyNumbers(orgName) === true){
            setErrorText(previous => ({...previous,orgName:{error:true,
              errorText:"Field cannot contain Numbers",
              verified:false}}))
              return true;
          }else if(orgName !== ""){
            setErrorText(previous => ({...previous,orgName:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))

          }       
        }

      if(orgCategoryID === '' || orgCategoryID !==''){
          if(orgCategoryID === ''){
            setErrorText(previous => ({...previous,orgCategoryID:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(orgCategoryID !== ''){
            setErrorText(previous => ({...previous,orgCategoryID:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }
      
      if(display_name === "" || display_name !== ""){
          if(display_name === ""){
            setErrorText(previous => ({...previous,display_name:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
          }else if(containsSpecialChars(display_name) === true){
            setErrorText(previous => ({...previous,display_name:{error:true,
              errorText:"Field cannot contain Special Characters",
              verified:false}}))
              return true;
          }else if(containsOnlyNumbers(display_name) === true){
            setErrorText(previous => ({...previous,display_name:{error:true,
              errorText:"Field cannot contain Numbers",
              verified:false}}))
              return true;
          }else if(display_name !== ""){
            setErrorText(previous => ({...previous,display_name:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
            
          }         
        }

      if(address1 === '' || address1 !==''){
          if(address1 === ''){
            setErrorText(previous => ({...previous,address1:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
          }else if(address1 !== ''){
            setErrorText(previous => ({...previous,address1:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
          }
        }

      if(pincode === '' || pincode !==''){
          if(pincode === ''){
            setErrorText(previous => ({...previous,pincode:{error:true,
              errorText:"Select an option from DropDown",
              verified:false}}))
              return true;
          }else if(pincode !== ''){
            setErrorText(previous => ({...previous,pincode:{error:false,
              errorText:"Select an option from DropDown",
              verified:true}}))
          }
        }

      if(contactPerson === "" || contactPerson !== ""){
          if(contactPerson === ""){
            setErrorText(previous => ({...previous,contactPerson:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
              
          }else if(containsSpecialChars(contactPerson) === true){
            setErrorText(previous => ({...previous,contactPerson:{error:true,
              errorText:"Field cannot contain Special Characters",
              verified:false}}))
              return true;
          }else if(containsOnlyNumbers(contactPerson) === true){
            setErrorText(previous => ({...previous,contactPerson:{error:true,
              errorText:"Field cannot contain Numbers",
              verified:false}}))
              return true;
          }else if(contactPerson !== ""){
            setErrorText(previous => ({...previous,contactPerson:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))

          }       
        }
      
      if(contactPhone === "" || contactPhone !== ""){
          if(contactPhone === ""){
            setErrorText(previous => ({...previous,contactPhone:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
          }else if(contactPhone.length < 10){
            setErrorText(previous => ({...previous,contactPhone:{error:true,
              errorText:"Minimum length should be 10 digits",
              verified:false}}))
              return true;
          }else if(containsSpecialChars(contactPhone) === true){
            setErrorText(previous => ({...previous,contactPhone:{error:true,
              errorText:"Field cannot contain Special Characters",
              verified:false}}))
              return true;
          }else if(containsOnlyNumbers(contactPhone) === true && contactPhone !== ""){
            setErrorText(previous => ({...previous,contactPhone:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              
          }       
        }

      if(contactEmail === "" || contactEmail !== ""){
          if(contactEmail === ""){
            setErrorText(previous => ({...previous,contactEmail:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
          }else if(containsOnlyNumbers(contactEmail) === true){
            setErrorText(previous => ({...previous,contactEmail:{error:true,
              errorText:"Field cannot contain only Numbers",
              verified:false}}))
              return true;
          }else if(validateEmail(contactEmail) === false){
            setErrorText(previous => ({...previous,contactEmail:{error:true,
              errorText:"Email is not valid should contain '@' and '.'",
              verified:false}}))
              return true;
          }else if(contactEmail !== ""){
            setErrorText(previous => ({...previous,contactEmail:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              
          }        
        }

      if(contactUsEmail === "" || contactUsEmail !== ""){
          if(contactUsEmail === ""){
            setErrorText(previous => ({...previous,contactUsEmail:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
          }else if(containsOnlyNumbers(contactUsEmail) === true){
            setErrorText(previous => ({...previous,contactUsEmail:{error:true,
              errorText:"Field cannot contain only Numbers",
              verified:false}}))
              return true;
          }else if(validateEmail(contactUsEmail) === false){
            setErrorText(previous => ({...previous,contactUsEmail:{error:true,
              errorText:"Email is not valid should contain '@' and '.'",
              verified:false}}))
              return true;
          }else if(contactUsEmail !== ""){
            setErrorText(previous => ({...previous,contactUsEmail:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              
          }        
        }

      if(contactUsPhone === "" || contactUsPhone !== ""){
          if(contactUsPhone === ""){
            setErrorText(previous => ({...previous,contactUsPhone:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
          }else if(contactUsPhone.length < 10){
            setErrorText(previous => ({...previous,contactUsPhone:{error:true,
              errorText:"Minimum length should be 10 digits",
              verified:false}}))
              return true;
          }else if(containsSpecialChars(contactUsPhone) === true){
            setErrorText(previous => ({...previous,contactUsPhone:{error:true,
              errorText:"Field cannot contain Special Characters",
              verified:false}}))
              return true;
          }else if(containsOnlyNumbers(contactUsPhone) === true && contactUsPhone !== ""){
            setErrorText(previous => ({...previous,contactUsPhone:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
              
          }       
        }
      
      if(logoPath === null || logoPath !== null){
        if(logoPath === null){
          setErrorText(previous => ({...previous,logoPath:{error:true,
            errorText:"Please Upload Logo",
            verified:false}}))
            return true;
        }else if(logoPath !== null){
          setErrorText(previous => ({...previous,logoPath:{error:false,
            errorText:"Select an option from DropDown",
            verified:true}}))
            return false;
          }
        }
      
      console.log("RESULTS............");
      
    }
      
      const handleLogoChange = async (newFile) => {
        
        fileUpload(newFile,'PARTNER_REG_COMPANY_LOGOS').then(res =>{
          console.log("File",res)
          setLogoPath(res.path)
        })
        setLogoURL(await toBase64(newFile));
        setLogoPreview(true);
      };
     const handleOtherImageChange = async (newFile) => {
  
      fileUpload(newFile,'PARTNER_REG_OTHER_IMAGES').then(res =>{
        console.log("File",res)
        setOtherImagePath(res.path)
      })
        setOtherImageURL(await toBase64(newFile));
        setOtherImagePreview(true);
       };

      React.useEffect(() => {
        getAddressList().then(res => setAddressList(res.content));
        GetMasterSearchData('ORG_CATEGORY').then(res => setOrganizationTypeList(res));
        getOrgDetails(orgID).then(res =>{
          console.log("org details",res)
          if(res.status === 200){
            setOrgName(res.data.orgname)
            setOrgCreatedID(res.data.orgId)
            setOrgCategory(res.data.organizationCategory.label)
            setOrgCategoryID(res.data.organizationCategory.typeID)
            setDisplayName(res.data.displayname)
            setAddress1(res.data.addressDto.address1)
            setPincode(res.data.addressDto.postalCode)
            setCity(res.data.addressDto.city)
            setState(res.data.addressDto.state)
            setCountry(res.data.addressDto.country)
            setContactPerson(res.data.contactperson)
            setContactPhone(res.data.phone)
            setContactEmail(res.data.email)
            setContactUsEmail(res.data.contactUsEmail)
            setContactUsPhone(res.data.contactUsContactNumber)
            setLogoURL(res.data.orglogo)
            setOtherImageURL(res.data.otherimage)
            setLogoPath(res.data.orglogo)
            setOtherImagePath(res.data.otherimage)
            setLogoPreview(true)
            setOtherImagePreview(true)
          }else{
            toast("Organization Details is Empty")
          }
        })
      },[])

      return(
      <Grid item xs={10} md={10} lg={10}>
       
                  <Paper sx={{marginTop:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>ORGANIZATION DETAILS</Typography>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Organization Name</Typography>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Enter First Name"
                  size='small'
                  value={orgName}
                  onChange={(e)=>setOrgName(e.target.value)}
                  helperText={errorText.orgName.error === true ? errorText.orgName.errorText:""}
                  error={errorText.orgName.error === true ? true : false}
                  autoFocus={errorText.orgName.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Category</Typography>
                  <FormControl fullWidth>
              <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setOrgCategory(newValue.label);
                          setOrgCategoryID(newValue.typeID)
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={organizationTypeList}
                      value={orgCategory}
                      getOptionLabel={(option) => option.label || orgCategory}
                      renderInput={(params) => <TextField {...params} label="Select Organization Type" 
                      helperText={errorText.orgCategoryID.error === true ? errorText.orgCategoryID.errorText:""}
                      error={errorText.orgCategoryID.error === true ? true : false}
                      autoFocus={errorText.orgCategoryID.error === true ? true : false}
                      />}
  
                    />
                        </FormControl>
              </Grid>
        </Grid>
        <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Short Name</Typography>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Enter Short Name"
                  size='small'
                  value={display_name}
                  onChange={(e)=>setDisplayName(e.target.value)}
                  helperText={errorText.display_name.error === true ? errorText.display_name.errorText:""}
                  error={errorText.display_name.error === true ? true : false}
                  autoFocus={errorText.display_name.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              
              </Grid>
        </Grid>
                  </Paper> 
                  <Paper sx={{marginTop:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>ADDRESS</Typography>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={10.35} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Full Address</Typography>
                <TextField
                  value={address1}
                  onChange={(e) => setAddress1(e.target.value)}
                  autoComplete="given-name"
                  name="addr1"
                  required
                  fullWidth
                  id="addr1"
                  label="Enter Address"
                  size='small'
                  multiline
                  rows={3}
                  helperText={errorText.address1.error === true ? errorText.address1.errorText:""}
                  error={errorText.address1.error === true ? true : false}
                  autoFocus={errorText.address1.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12} md={5} margin={2}>
        <Typography color={colors.grey[900]} fontWeight={'bold'}>Pincode</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.postalCode){
                        setPincode(newValue.postalCode)
                        setCity(newValue.district)
                        setState(newValue.state);
                        setCountry(newValue.country);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={addressList}
                      value={pincode}
                      getOptionLabel={(option) => option.postalCode || pincode}
                      renderInput={(params) => <TextField {...params} label="Enter PinCode" 
                      helperText={errorText.pincode.error === true ? errorText.pincode.errorText:""}
                      error={errorText.pincode.error === true ? true : false}
                      autoFocus={errorText.pincode.error === true ? true : false}
                      />}
                    />
                  </FormControl>
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>City</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.district){
                          setCity(newValue.district);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={addressList}
                      getOptionLabel={(option) => option.district}
                      renderInput={(params) => <TextField {...params} label={city} />}
  
                    />
                  </FormControl>           
              </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12} md={5} margin={2}>
        <Typography color={colors.grey[900]} fontWeight={'bold'}>State</Typography>
              <FormControl fullWidth>
                    <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.state){
                          setState(newValue.state);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={addressList}
                      getOptionLabel={(option) => option.state}
                      renderInput={(params) => <TextField {...params} label={state} />}
  
                    />
                  </FormControl>
              </Grid>
  
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Country</Typography>
              <FormControl fullWidth>
                  <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.country){
                          setCountry(newValue.country);
                      }}}
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={addressList}
                      getOptionLabel={(option) => option.country}
                      renderInput={(params) => <TextField {...params} label={country} />}
  
                    />
                  </FormControl>
              </Grid>
        </Grid>
                  </Paper>
                  <Paper sx={{marginTop:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>CONTACT PERSON</Typography>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Contact Person Name</Typography>
                <TextField
                  autoComplete="given-name"
                  name="mobilenum"
                  required
                  fullWidth
                  id="mobilenum"
                  label="Enter Contact Person Name"
                  size='small'
                  value={contactPerson}
                  onChange={(e)=>setContactPerson(e.target.value)}
                  helperText={errorText.contactPerson.error === true ? errorText.contactPerson.errorText:""}
                  error={errorText.contactPerson.error === true ? true : false}
                  autoFocus={errorText.contactPerson.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Contact Person Mobile Number</Typography>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Enter Contact Person Mobile Number"
                  name="email"
                  autoComplete="family-name"
                  size='small'
                  value={contactPhone}
                  onChange={(e)=>setContactPhone(e.target.value)}
                  type="number"
                  onInput = {(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                  }}
                  helperText={errorText.contactPhone.error === true ? errorText.contactPhone.errorText:""}
                  error={errorText.contactPhone.error === true ? true : false}
                  autoFocus={errorText.contactPhone.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Contact Person Email</Typography>
                <TextField
                  autoComplete="given-name"
                  name="mobilenum"
                  required
                  fullWidth
                  id="mobilenum"
                  label="Enter Contact Person Email"
                  size='small'
                  value={contactEmail}
                  onChange={(e)=>setContactEmail(e.target.value)}
                  helperText={errorText.contactEmail.error === true ? errorText.contactEmail.errorText:""}
                  error={errorText.contactEmail.error === true ? true : false}
                  autoFocus={errorText.contactEmail.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              
              </Grid>
        </Grid>
        
                  </Paper>
                  <Paper sx={{marginTop:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>CONTACT US</Typography>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Contact Us Email</Typography>
                <TextField
                  autoComplete="given-name"
                  name="mobilenum"
                  required
                  fullWidth
                  id="mobilenum"
                  label="Enter Contact Person Name"
                  size='small'
                  value={contactUsEmail}
                  onChange={(e)=>setContactUsEmail(e.target.value)}
                  helperText={errorText.contactUsEmail.error === true ? errorText.contactUsEmail.errorText:""}
                  error={errorText.contactUsEmail.error === true ? true : false}
                  autoFocus={errorText.contactUsEmail.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Contact Us Mobile No.</Typography>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Enter Contact Person Mobile Number"
                  name="email"
                  autoComplete="family-name"
                  size='small'
                  value={contactUsPhone}
                  onChange={(e)=>setContactUsPhone(e.target.value)}
                  type="number"
                  onInput = {(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                  }}
                  helperText={errorText.contactUsPhone.error === true ? errorText.contactUsPhone.errorText:""}
                  error={errorText.contactUsPhone.error === true ? true : false}
                  autoFocus={errorText.contactUsPhone.error === true ? true : false}
                />
              </Grid>
        </Grid>
        
                  </Paper>
                  <Grid container spacing={2}>
                  <Grid item xs={5} md={6} marginTop={1}>
                  <Paper sx={{height:'40vh'}}>
                    <Typography padding={1} fontWeight={'bold'} fontSize={16}>Company Logo</Typography>
                    <Grid container spacing={2}>
                    <Grid item xs={5} md={5} padding={1}>
                    {logoPreview === true ? <Avatar alt="Remy Sharp"
                              src={logoURL} 
                              sx={{width:150,height:150, margin:'auto'}}/>: <div></div>}
                    </Grid>
                    <Grid item xs={7} md={7} marginBottom={2} paddingRight={1}>
                      <FileUploader 
                            handleChange={handleLogoChange} 
                            name="file" 
                            types={fileTypes} 
                            multiple={false}
                            children={
                              <Box sx={{width:'100%',height:'auto',border:3,borderColor:'#651fff',borderStyle:'dashed',padding:3}}>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2}
                              >drag and drop to upload</Typography>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2} marginBottom={2}
                              >or</Typography>
                              <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF'}} onClick={handleLogoChange} size={'small'} fullWidth>
                                  Upload
                              </Button>
                            </Box>
                            }
                            />
                      {errorText.logoPath.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.logoPath.errorText}</Typography> : <div></div>}
                      </Grid>
                    </Grid>                   
                    </Paper>
                  </Grid>
                  <Grid item xs={5} md={6} marginTop={1}>
                  <Paper sx={{height:'40vh'}}>
                    <Typography padding={1} fontWeight={'bold'} fontSize={16}>Other Image</Typography>
                    <Grid container spacing={2}>
                    <Grid item xs={5} md={5} padding={1}>
                    {otherImagePreview === true ? <Avatar alt="Remy Sharp"
                              src={otherImageURL} 
                              sx={{width:150,height:150, margin:'auto'}}/>: <div></div>}
                    </Grid>
                    <Grid item xs={7} md={7} marginBottom={2} paddingRight={1}>
                      <FileUploader 
                            handleChange={handleOtherImageChange} 
                            name="file" 
                            types={fileTypes} 
                            multiple={false}
                            children={
                              <Box sx={{width:'100%',height:'auto',border:3,borderColor:'#651fff',borderStyle:'dashed',padding:3}}>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2}
                              >drag and drop to upload</Typography>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2} marginBottom={2}
                              >or</Typography>
                              <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF'}} onClick={handleOtherImageChange} size={'small'} fullWidth>
                                  Upload
                              </Button>
                            </Box>
                            }
                            />
                      {/* {errorText.otherImagePath.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.otherImagePath.errorText}</Typography> : <div></div>} */}
                      </Grid>
                    </Grid>                   
                    </Paper>
                  </Grid>
                </Grid>

                <Paper sx={{marginTop:1}}>
                <Grid item xs={12} md={12} margin={2}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                      onClick={(e)=>{
                        let res = handleValidation();
                        if(res === false){
                          updateOrg(orgID,orgName,contactEmail,contactPerson,contactPhone,orgCategoryID,display_name,address1,city,pincode,state,country,contactUsEmail,contactUsPhone,logoPath,otherImagePath).then(res =>{
                              console.log(res)
                              // setOrgCreatedID(res.id)
                             {res.status === 200 ? confirmAlert({
                              title: res.message,
                              buttons: [
                                {
                                  label: 'Okay',
                                  onClick: () => {
                                    getAddressList().then(res => setAddressList(res.content));
                                    GetMasterSearchData('ORG_CATEGORY').then(res => setOrganizationTypeList(res));
                                    getOrgDetails(orgID).then(res =>{
                                      console.log("org details",res)
                                      if(res.status === 200){
                                        setOrgName(res.data.orgname)
                                        setOrgCreatedID(res.data.orgId)
                                        setOrgCreatedID(res.data.orgId)
                                        setOrgCategory(res.data.organizationCategory.label)
                                        setDisplayName(res.data.displayname)
                                        setAddress1(res.data.addressDto.address1)
                                        setPincode(res.data.addressDto.postalCode)
                                        setCity(res.data.addressDto.postalCode)
                                        setState(res.data.addressDto.state)
                                        setCountry(res.data.addressDto.country)
                                        setContactPerson(res.data.contactperson)
                                        setContactPhone(res.data.phone)
                                        setContactEmail(res.data.email)
                                        setContactUsEmail(res.data.contactUsEmail)
                                        setContactUsPhone(res.data.contactUsContactNumber)
                                        setLogoURL(res.data.orglogo)
                                        setOtherImageURL(res.data.otherimage)
                                        setLogoPreview(true)
                                        setOtherImagePreview(true)
                                      }else{
                                        toast("Organization Details is Empty")
                                      }
                                   })
                                  }
                                },
                              ],
                              closeOnEscape:false,
                              closeOnClickOutside:false,
                            })
                          :toast("Error Occured")}
                              
                            }) 
                        }else{
                          toast("Please Fill all the Details")
                        }
                        
                        }}
                    >
                      Save
                    </Button>
              </Grid>
                </Paper>
          </Grid>
      );
    }
    
    function ConfigurationDetails(){
      const [entityID,setEntityID] = React.useState('');
      const [url,setUrl] = React.useState('');
      const [fromName,setFromName] = React.useState('');
      const [fromEmail,setFromEmail] = React.useState('');
      const [username,setUsername] = React.useState('');
      const [password,setPassword] = React.useState('');
      const [host,setHost] = React.useState('');
      const [tls,setTLS] = React.useState('');
      const [port,setPort] = React.useState('');
      const [smtpAuth,setSmtpAuth] = React.useState('');
      const [id,setID] = React.useState('');

      const [errorText,setErrorText] = React.useState({
        entityID:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        url:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        fromName:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        fromEmail:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
        username:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        password:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        host:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        tls:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        port:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
        smtpAuth:{
          error:false,
          errorText:"Field can't be empty",
          verified:true
        },
      })
  
      function handleValidation(){
        if(entityID === '' || entityID !==''){
            if(entityID === ''){
              setErrorText(previous => ({...previous,entityID:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(entityID !== ''){
              setErrorText(previous => ({...previous,entityID:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
            }
          }

        if(url === '' || url !==''){
            if(url === ''){
              setErrorText(previous => ({...previous,url:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(url !== ''){
              setErrorText(previous => ({...previous,url:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
            }
          }
        
        if(fromName === "" || fromName !== ""){
            if(fromName === ""){
              setErrorText(previous => ({...previous,fromName:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
                
            }else if(containsSpecialChars(fromName) === true){
              setErrorText(previous => ({...previous,fromName:{error:true,
                errorText:"Field cannot contain Special Characters",
                verified:false}}))
                return true;
            }else if(containsOnlyNumbers(fromName) === true){
              setErrorText(previous => ({...previous,fromName:{error:true,
                errorText:"Field cannot contain Numbers",
                verified:false}}))
                return true;
            }else if(fromName !== ""){
              setErrorText(previous => ({...previous,fromName:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
  
            }       
          }

        if(fromEmail === "" || fromEmail !== ""){
            if(fromEmail === ""){
              setErrorText(previous => ({...previous,fromEmail:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(containsOnlyNumbers(fromEmail) === true){
              setErrorText(previous => ({...previous,fromEmail:{error:true,
                errorText:"Field cannot contain only Numbers",
                verified:false}}))
                return true;
            }else if(validateEmail(fromEmail) === false){
              setErrorText(previous => ({...previous,fromEmail:{error:true,
                errorText:"Email is not valid should contain '@' and '.'",
                verified:false}}))
                return true;
            }else if(fromEmail !== ""){
              setErrorText(previous => ({...previous,fromEmail:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
                
            }        
          }

        if(username === "" || username !== ""){
            if(username === ""){
              setErrorText(previous => ({...previous,username:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
                
            }else if(containsSpecialChars(username) === true){
              setErrorText(previous => ({...previous,username:{error:true,
                errorText:"Field cannot contain Special Characters",
                verified:false}}))
                return true;
            }else if(containsOnlyNumbers(username) === true){
              setErrorText(previous => ({...previous,username:{error:true,
                errorText:"Field cannot contain Numbers",
                verified:false}}))
                return true;
            }else if(username !== ""){
              setErrorText(previous => ({...previous,username:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
  
            }       
          }
       
        if( password === "" || password !== ""){
          if(password === ""){
            setErrorText(previous => ({...previous,password:{error:true,
              errorText:"Field can't be empty",
              verified:false}}))
              return true;
          }else if(containsOnlyNumbers(password) === true){
            setErrorText(previous => ({...previous,password:{error:true,
              errorText:"Field cannot contain Numbers",
              verified:false}}))
              return true;
          }else if(password.length < 8){
            setErrorText(previous => ({...previous,password:{error:true,
              errorText:"Minimum Length of Password must be 8 characters",
              verified:false}}))
              return true;
          }else if(checkPassword(password) === false){
            setErrorText(previous => ({...previous,password:{error:true,
              errorText:"Password does not match Criteria: One UpperCase,lowercase,number and special character",
              verified:false}}))
              return true;
          }else if(password !== ""){
            setErrorText(previous => ({...previous,password:{error:false,
              errorText:"Field can't be empty",
              verified:true}}))
            }       
          }

        if(host === '' || host !==''){
            if(host === ''){
              setErrorText(previous => ({...previous,host:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(host !== ''){
              setErrorText(previous => ({...previous,host:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
            }
          }

        if(smtpAuth === '' || smtpAuth !==''){
            if(smtpAuth === ''){
              setErrorText(previous => ({...previous,smtpAuth:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(smtpAuth !== ''){
              setErrorText(previous => ({...previous,smtpAuth:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
            }
          }

        if(tls === '' || tls !==''){
            if(tls === ''){
              setErrorText(previous => ({...previous,tls:{error:true,
                errorText:"Field can't be empty",
                verified:false}}))
                return true;
            }else if(tls !== ''){
              setErrorText(previous => ({...previous,tls:{error:false,
                errorText:"Field can't be empty",
                verified:true}}))
            }
          }

        if(port === '' || port !==''){
            if(port === ''){
              setErrorText(previous => ({...previous,port:{error:true,
                errorText:"Select an option from DropDown",
                verified:false}}))
                return true;
            }else if(port !== ''){
              setErrorText(previous => ({...previous,port:{error:false,
                errorText:"Select an option from DropDown",
                verified:true}}))
                return false;
            }
          }
        
        console.log("RESULTS............");
        
      }
      React.useEffect(() => {
        getConfigurationDetails(orgID).then(res =>{
          console.log("org configuration details",res)
          if(res.status === 200){
            setID(res.data.id)
            setEntityID(res.data.entityId)
            setUrl(res.data.url)
            setFromName(res.data.fromName)
            setFromEmail(res.data.fromEmail)
            setUsername(res.data.userId)
            setPassword(res.data.password)
            setHost(res.data.smtpServer)
            setSmtpAuth(res.data.smtpAuth)
            setTLS(res.data.tls)
            setPort(res.data.port)
          }else{
            toast("Organization Configuration Details is Empty")
          }
        })
      },[])
      return(
      <Grid item xs={10} md={10} lg={10}>
                  <Typography padding={0.5} fontWeight={'bold'}>Configuration</Typography>
                  <Paper sx={{marginTop:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>DETAILS</Typography>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Entity ID</Typography>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="Enter Entity ID"
                  size='small'
                  value={entityID}
                  onChange={(e)=>setEntityID(e.target.value)}
                  helperText={errorText.entityID.error === true ? errorText.entityID.errorText:""}
                  error={errorText.entityID.error === true ? true : false}
                  autoFocus={errorText.entityID.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>URL</Typography>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Enter URL"
                  name="lastName"
                  autoComplete="family-name"
                  size='small'
                  value={url}
                  onChange={(e)=>setUrl(e.target.value)}
                  helperText={errorText.url.error === true ? errorText.url.errorText:""}
                  error={errorText.url.error === true ? true : false}
                  autoFocus={errorText.url.error === true ? true : false}
                />
              </Grid>
        </Grid>
                  </Paper>
                  <Paper sx={{marginTop:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>DETAILS</Typography>
                  <Grid container spacing={2}>
                  <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>From Name</Typography>
                <TextField
                  autoComplete="given-name"
                  name="mobilenum"
                  required
                  fullWidth
                  id="mobilenum"
                  label="Enter Name"
                  size='small'
                  value={fromName}
                  onChange={(e)=>setFromName(e.target.value)}
                  helperText={errorText.fromName.error === true ? errorText.fromName.errorText:""}
                  error={errorText.fromName.error === true ? true : false}
                  autoFocus={errorText.fromName.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>From Email</Typography>
                <TextField
                  autoComplete="given-name"
                  name="mobilenum"
                  required
                  fullWidth
                  id="mobilenum"
                  label="Enter Email"
                  size='small'
                  value={fromEmail}
                  onChange={(e)=>setFromEmail(e.target.value)}
                  helperText={errorText.fromEmail.error === true ? errorText.fromEmail.errorText:""}
                  error={errorText.fromEmail.error === true ? true : false}
                  autoFocus={errorText.fromEmail.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Username</Typography>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Enter Username"
                  name="email"
                  autoComplete="family-name"
                  size='small'
                  value={username}
                  onChange={(e)=>setUsername(e.target.value)}
                  helperText={errorText.username.error === true ? errorText.username.errorText:""}
                  error={errorText.username.error === true ? true : false}
                  autoFocus={errorText.username.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Password</Typography>
                <TextField
                  autoComplete="given-name"
                  name="mobilenum"
                  required
                  fullWidth
                  value={password}
                  id="mobilenum"
                  label="Enter Password"
                  size='small'
                  onChange={(e)=>setPassword(e.target.value)}
                  helperText={errorText.password.error === true ? errorText.password.errorText:""}
                  error={errorText.password.error === true ? true : false}
                  autoFocus={errorText.password.error === true ? true : false}
                />
              </Grid>
        </Grid>
                  </Paper>
                  <Paper sx={{marginTop:1}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>MAIL SETTING</Typography>
                  <Grid container spacing={2}>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Host</Typography>
                <TextField
                  autoComplete="given-name"
                  name="addr1"
                  required
                  fullWidth
                  id="addr1"
                  label="Enter Address"
                  size='small'
                  value={host}
                  onChange={(e)=>setHost(e.target.value)}
                  helperText={errorText.host.error === true ? errorText.host.errorText:""}
                  error={errorText.host.error === true ? true : false}
                  autoFocus={errorText.host.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>SMTP AUTH</Typography>
                <TextField
                  required
                  fullWidth
                  id="addr2"
                  label="Enter Details"
                  name="addr2"
                  value={smtpAuth}
                  autoComplete="family-name"
                  size='small'
                  onChange={(e)=>setSmtpAuth(e.target.value)}
                  helperText={errorText.smtpAuth.error === true ? errorText.smtpAuth.errorText:""}
                  error={errorText.smtpAuth.error === true ? true : false}
                  autoFocus={errorText.smtpAuth.error === true ? true : false}
                />
              </Grid>
        </Grid>
        <Grid container spacing={2}>
        <Grid item xs={12} md={5} margin={2}>
        <Typography color={colors.grey[900]} fontWeight={'bold'}>TLS Enable</Typography>
                <TextField
                  required
                  fullWidth
                  id="addr2"
                  label="Enter Details"
                  name="addr2"
                  value={tls}
                  autoComplete="family-name"
                  size='small'
                  onChange={(e)=>setTLS(e.target.value)}
                  helperText={errorText.tls.error === true ? errorText.tls.errorText:""}
                  error={errorText.tls.error === true ? true : false}
                  autoFocus={errorText.tls.error === true ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={5} margin={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>SMTP Port</Typography>
                <TextField
                  required
                  fullWidth
                  id="addr2"
                  label="Enter Details"
                  name="addr2"
                  value={port}
                  autoComplete="family-name"
                  size='small'
                  onChange={(e)=>setPort(e.target.value)}
                  helperText={errorText.port.error === true ? errorText.port.errorText:""}
                  error={errorText.port.error === true ? true : false}
                  autoFocus={errorText.port.error === true ? true : false}
                />
              </Grid>
        </Grid>
                  </Paper>

                <Paper sx={{marginTop:1}}>
                <Grid item xs={12} md={12} margin={2}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                      onClick={(e)=>{
                        let res = handleValidation()
                        if(res === false){
                          updateConfigurationDetails(id,orgCreatedID,entityID,host,username,password,
                            fromName,fromEmail,port,url,tls,smtpAuth).then(res =>{
                              console.log(res)
                              // setOrgCreatedID(res.id)
                             {res.status === 200 ? confirmAlert({
                              title: res.message,
                              buttons: [
                                {
                                  label: 'Okay',
                                  onClick: () => {
                                    getConfigurationDetails(orgID).then(res =>{
                                      console.log("org configuration details",res)
                                      if(res.status === 200){
                                        setID(res.data.id)
                                        setEntityID(res.data.entityId)
                                        setUrl(res.data.url)
                                        setFromName(res.data.fromName)
                                        setFromEmail(res.data.fromEmail)
                                        setUsername(res.data.userId)
                                        setPassword(res.data.password)
                                        setHost(res.data.smtpServer)
                                        setSmtpAuth(res.data.smtpAuth)
                                        setTLS(res.data.tls)
                                        setPort(res.data.port)
                                      }else{
                                        toast("Organization Configuration Details is Empty")
                                      }
                                    })
                                  }
                                },
                              ],
                              closeOnEscape:false,
                              closeOnClickOutside:false,
                            })
                          :toast("Error Occured")}
                              
                            }) 
                        }else{
                          toast("Please fill the details")
                        }
                        
                      }}
                    >
                      Save
                    </Button>
              </Grid>
                </Paper>
          </Grid>
      );
    }

    function Templates(){
      const [templateType, setTemplateType] = React.useState('');
      const [templateID, setTemplateID] = React.useState('');
      const [smsTemplate, setSMSTemplate] = React.useState('');
      const [emailtemplate, setEmailTemplate] = React.useState('');
      const [pushNotificationtemplate, setPushNotificationTemplate] = React.useState('');
      const [templateTypeID, setTemplateTypeID] = React.useState('');
      const [templateImagePreview, setTemplateImagePreview] = React.useState(false);
      const [templateImageURL, setTemplateImageURL] = React.useState(null);
      const [templateImagePath, setTemplateImagePath] = React.useState(null);
      const [templateTypeList,setTemplateTypeList]= React.useState([]);
      const [templateDataList,setTemplateDataList]= React.useState([]);
      const [templateDataPacket,setTemplateDataPacket]= React.useState([]);
      const fileTypes = ["JPG", "PNG", "GIF","JPEG"];
      const [errorText,setErrorText] = React.useState({
        templateType:{
          error:false,
          errorText:"Field cant be empty",
          verified:true
        },
      })
  
      function handleValidation(){
        if(templateType === '' || templateType !==''){
            if(templateType === ''){
              setErrorText(previous => ({...previous,templateType:{error:true,
                errorText:"Select an option from the Dropdown",
                verified:false}}))
                return true;
            }else if(templateType !== '' && templateTypeID !== ''){
              setErrorText(previous => ({...previous,templateType:{error:false,
                errorText:"Select an option from the Dropdown",
                verified:true}}))
                return false;
            }
          }

        console.log("RESULTS............");
        
      }
      
      const handleTemplateImageChange = async (newFile) => {
        
        fileUpload(newFile,'PARTNER_REG_EMAIL_DESIGNS').then(res =>{
          console.log("File",res)
          setTemplateImagePath(res.path)
        })
        setTemplateImageURL(await toBase64(newFile));
        setTemplateImagePreview(true);
        
      };
      
      const handleDelete = (deleteIndex) => {
        setTemplateDataList((prevData) =>
          prevData.filter((_, index) => index !== deleteIndex)
        );
        setTemplateDataPacket((prevData) =>
          prevData.filter((_, index) => index !== deleteIndex)
        );
      };
      console.log("sssssssss",templateDataList)
      console.log("dddddddddd",templateDataPacket)
      React.useEffect(() => {

        GetMasterSearchData('NOTIFICATION_TEMPLATE').then(res => setTemplateTypeList(res));
        getTemplateDetails(orgID).then(res =>{
          console.log(res)
          if(res.status === 200){
            setTemplateDataList(res.data)
            setTemplateDataPacket(res.data)
            
          }else{
            toast("Organization Template Details is Empty")
          }
        })
      },[])
      return(
        <Grid item xs={10} md={10} lg={10}>
                  <Typography padding={0.5} fontWeight={'bold'}>Template</Typography>
                  <Paper sx={{marginTop:2}}>
                  <Typography paddingLeft={2} paddingTop={2} color={colors.grey[800]}>TEMPLATE</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>DLT Template ID</Typography>
                      <TextField
                        required
                        fullWidth
                        id="addr2"
                        label="Enter Template ID"
                        name="addr2"
                        autoComplete="family-name"
                        size='small'
                        onChange={(e) => setTemplateID(e.target.value)}
                      />
                      </Grid>
                      <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>Template Name</Typography>
                      <FormControl fullWidth>
              <Autocomplete
                      onChange = {(event, newValue) => {if(newValue && newValue.label){
                          setTemplateType(newValue.label);
                          setTemplateTypeID(newValue.typeID)
                      }}}
                      
                      size="small"
                      disablePortal
                      id="combo-box-demo"
                      options={templateTypeList}
                      getOptionLabel={(option) => option.label}
                      onCut={()=>{
                          setTemplateType('');
                          setTemplateTypeID('')
                      }}
                      renderInput={(params) => <TextField {...params} label="Select Template" 
                      helperText={errorText.templateType.error === true ? errorText.templateType.errorText:""}
                      error={errorText.templateType.error === true ? true : false}
                      autoFocus={errorText.templateType.error === true ? true : false}
                      />}
  
                    />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>SMS Template</Typography>
                      <TextField
                        required
                        fullWidth
                        id="addr2"
                        label="Enter SMS Template"
                        name="addr2"
                        autoComplete="family-name"
                        size='small'
                        onChange={(e) => setSMSTemplate(e.target.value)}
                      />
                      </Grid>
                      <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>Email Template</Typography>
                      <TextField
                        required
                        fullWidth
                        id="addr2"
                        label="Enter Email Template"
                        name="addr2"
                        autoComplete="family-name"
                        size='small'
                        onChange={(e) => setEmailTemplate(e.target.value)}
                      />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={5} margin={2}>
                      <Typography color={colors.grey[900]} fontWeight={'bold'}>Push Notification Template</Typography>
                      <TextField
                        required
                        fullWidth
                        id="addr2"
                        label="Enter Push Notification Template"
                        name="addr2"
                        autoComplete="family-name"
                        size='small'
                        onChange={(e) => setPushNotificationTemplate(e.target.value)}
                      />
                      </Grid>
                      <Grid item xs={12} md={5} margin={2}>
                      <Paper sx={{height:'30vh'}}>
                    <Typography padding={1} fontWeight={'bold'} fontSize={16}>Upload Image For Email Design</Typography>
                    <Grid container spacing={2}>
                    <Grid item xs={5} md={5} padding={1}>
                    {templateImagePreview === true ? <Avatar alt="Remy Sharp"
                              src={templateImageURL} 
                              sx={{width:150,height:150, margin:'auto'}}
                              variant='square'
                              />: <div></div>}
                    </Grid>
                    <Grid item xs={7} md={7} marginBottom={2} paddingRight={1}>
                      <FileUploader 
                            handleChange={handleTemplateImageChange} 
                            name="file" 
                            types={fileTypes} 
                            multiple={false}
                            children={
                              <Box sx={{width:'100%',height:'auto',border:3,borderColor:'#651fff',borderStyle:'dashed',padding:3}}>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2}
                              >drag and drop to upload</Typography>
                              <Typography align='center'fontWeight={'bold'} color={'#9e9e9e'} 
                                fontSize={12} fontStyle={'italic'} marginTop={2} marginBottom={2}
                              >or</Typography>
                              <Button variant="contained" endIcon={<KeyboardArrowDownIcon/>} sx={{bgcolor:'#696CFF'}} onClick={handleTemplateImageChange} size={'small'} fullWidth>
                                  Upload
                              </Button>
                            </Box>
                            }
                            />
                      {/* {errorText.profileImage.error === true ? <Typography fontWeight={'bold'} fontSize={12} sx={{color:'red'}}>{errorText.profileImage.errorText}</Typography> : <div></div>} */}
                      </Grid>
                    </Grid>                   
                    </Paper>
                      </Grid>
                    </Grid>
                  </Paper>
                  <Paper sx={{marginTop:1}}>
                <Grid item xs={12} md={12} margin={2}>
                <Box
                    m={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff',width:'25%'}}
                      onClick={()=>{
                        let res = handleValidation();
                        if(res === false){
                          setTemplateDataList(templateDataList.concat({
                            notificationTemplate: templateType,
                            emailNotificationTemplate: emailtemplate,
                            smsNotificationTemplate: smsTemplate,
                            pushNotificationTemplate: pushNotificationtemplate,
                            active: true,
                            orgId: orgCreatedID,
                            templateId: templateID,
                            uploadFile: templateImageURL,
                            template: {
                              typeID: templateTypeID
                            } 
                          }))
                          setTemplateDataPacket(templateDataPacket.concat({
                            notificationTemplate: templateType,
                            emailNotificationTemplate: emailtemplate,
                            smsNotificationTemplate: smsTemplate,
                            pushNotificationTemplate: pushNotificationtemplate,
                            active: true,
                            orgId: orgCreatedID,
                            templateId: templateID,
                            uploadFile: templateImagePath,
                            template: {
                              typeID: templateTypeID
                            } 
                          }))
                        }else{
                          toast("Please Select the Template")
                        }
                        
                      }}
                    >
                      Add
                    </Button>
                  </Box>
              </Grid>
                </Paper>
                  {templateDataList.length !== 0 ?templateDataList.map((list,i) => (
        <Paper sx={{marginTop:2,padding:2,border:2}} key={generateString(i,16)}>
          <Typography color={colors.grey[900]} fontWeight={'bold'} marginBottom={3} fontSize={20}>Template {i + 1}</Typography>
                  <Grid container spacing={3}>
                  <Grid item xs={12} md={11}>
                  <Grid container spacing={2}>
                      {list.templateId !== '' ?<Grid item xs={12} md={5.5}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>Template ID</Typography>
                        <Typography color={colors.grey[700]} fontSize={14}>{list.templateId}</Typography>
                      </Grid> : null}
                      {list.notificationTemplate !== '' ? <Grid item xs={12} md={5.5}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>Template Name</Typography>
                        <Typography color={colors.grey[700]} fontSize={14}>{list.notificationTemplate}</Typography>
                      </Grid> : null}
                      {list.smsNotificationTemplate !== '' ? <Grid item xs={12} md={5.5}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>SMS Template</Typography>
                        <Typography color={colors.grey[700]} fontSize={14}>{list.smsNotificationTemplate}</Typography>
                      </Grid>: null}
                      {list.emailNotificationTemplate !== '' ? <Grid item xs={12} md={5.5}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>Email Template</Typography>
                        <Typography color={colors.grey[700]} fontSize={14}>{list.emailNotificationTemplate}</Typography>
                      </Grid>: null}
                      {list.pushNotificationTemplate !== '' ? <Grid item xs={12} md={5.5}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>Push Notification Template</Typography>
                        <Typography color={colors.grey[700]} fontSize={14}>{list.pushNotificationTemplate}</Typography>
                      </Grid> : null}
                      {list.uploadFile !== null ? <Grid item xs={12} md={5.5}>
                        <Typography color={colors.grey[900]} fontWeight={'bold'}>Email Design Image</Typography>
                        <Avatar alt="Remy Sharp"
                              src={list.uploadFile}
                              sx={{width:150,height:150, margin:'auto'}}
                              variant='square'
                              />
                      </Grid>: null}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={1}>
                    <IconButton onClick={() => handleDelete(i)}>
                      <DeleteSharp/>
                    </IconButton>
                  </Grid>
                </Grid>
                      </Paper>)) : null }
              
                <Paper sx={{marginTop:1}}>
                <Grid item xs={12} md={12} margin={2} justify='center'>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 ,borderRadius: 2,bgcolor:'#651fff'}}
                      onClick={(e)=>{
                        updateTemplateDetails(templateDataPacket,orgID).then(res =>{
                            console.log(res)
                            // setOrgCreatedID(res.id)
                           {res.status === 200 ? confirmAlert({
                            title: res.message,
                            buttons: [
                              {
                                label: 'okay',
                                onClick: () => {
                                  getTemplateDetails(orgID).then(res =>{
                                    console.log(res)
                                    if(res.status === 200){
                                      setTemplateDataList(res.data)
                                      setTemplateDataPacket(res.data)
                                    }else{
                                      toast("Organization Template Details is Empty")
                                    }
                                  })
                                }
                              },
                            ],
                            closeOnEscape:false,
                            closeOnClickOutside:false,
                          })
                        :toast("Error Occured")}
                            
                          }) 
                        }}
                    >
                      Save
                    </Button>
              </Grid>
                </Paper>  
                  
                </Grid>
      );
    }
    return(
      <Grid container spacing={2}>
                <Grid item xs={2} md={2} lg={2}>
                <Typography padding={0.5} fontWeight={'bold'}>Partner Registration</Typography>
                <Paper sx={{marginTop:1,borderRadius:3}}>
                        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <List component="nav" aria-label="main mailbox folders"
                        sx={{
                            // selected and (selected + hover) states
                            '&& .Mui-selected, && .Mui-selected:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                            // hover states
                            '& .MuiListItemButton-root:hover': {
                              bgcolor: '#651fff',
                              '&, & .MuiListItemIcon-root': {
                                color: 'white',
                              },
                            },
                          }}
                        >
                            <ListItemButton
                            selected={selectedIndex === 0}
                            onClick={(event) => {
                              handleListItemClick(event, 0)
                              setDComponent('organization')
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Organization Details" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton>

                            <ListItemButton
                            selected={selectedIndex === 1}
                            onClick={(event) => {
                              handleListItemClick(event, 1)
                              setDComponent('configuration')
                              
                            }}
                            >
                            <ListItemIcon>
                                <DraftsIcon />
                            </ListItemIcon>
                            <ListItemText primary="Configuration" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton>

                            <ListItemButton
                            selected={selectedIndex === 2}
                            onClick={(event) =>{ 
                              handleListItemClick(event, 2)
                              setDComponent('templates')
                              
                            }}
                            >
                            <ListItemIcon>
                                <InboxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Templates" 
                            primaryTypographyProps={{fontSize: '1.5vh'}} 
                            />
                            </ListItemButton>

                        </List>
                        </Box>
                    </Paper>
                </Grid>
                {dcomponent === 'organization' ? <OrganizationDetails/> : <div></div>}
                {dcomponent === 'configuration' ? <ConfigurationDetails/> : <div></div>}
                {dcomponent === 'templates' ? <Templates/> : <div></div>}
        </Grid>
    );
  }

  export default PartnerDetails;