import * as React from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import { colors,Paper} from '@mui/material';
import EncounterService from '../AppointmentsComponent/EncounterService';



function FaceSheet(props){
  console.log(props.open)
  const {getFaceSheetData} = EncounterService();
  const [faceSheetData, setFaceSheetData] = React.useState();
  const [medicalConditionList, setMedicalConditionList] = React.useState([]);
  const [allergyList, setAllergyList] = React.useState([]);
  const [prescriptionList, setPrescriptionList] = React.useState([]);
  const [medicalReportList, setMedicalReportList] = React.useState([]);
  const [vitalList, setVitalList] = React.useState([]);
  const [consultationList, setConsultationList] = React.useState([]);
  const [showFaceSheet,setShowFaceSheet] = React.useState(false);
  console.log("FACESHEET DATA",faceSheetData)
  React.useEffect(()=>{
    getFaceSheetData(props.patientUUID).then(res =>{
      console.log(res)
      if(res.status === 200){
        setFaceSheetData(res.data)
        setShowFaceSheet(true)
      }else{
        console.log(res)
      }
    })
  },[])
  function PersonalDetails(){
   
    return(
    <Grid item xs={12} md={12} lg={12}>
        <Paper sx={{marginTop:2,padding:1}}>
                <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>PERSONAL DETAILS</Typography>
            
            <Paper sx={{marginTop:2}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Name</Typography>
                    <Typography paddingLeft={2} color={colors.grey[900]}>{faceSheetData.personInfo.givenName} {faceSheetData.personInfo.lastName} </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Email</Typography>
                            <Typography paddingLeft={2} color={colors.grey[900]}>{faceSheetData.personInfo.email}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Phone</Typography>
                            <Typography paddingLeft={2} color={colors.grey[900]}>{faceSheetData.personInfo.contactNumber}</Typography>
                        </Grid>
      </Grid>
                </Paper>
                
                <Paper sx={{marginTop:4}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Gender</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{faceSheetData.personInfo.gendername}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Age</Typography>
                            <Typography paddingLeft={2} color={colors.grey[900]}>{faceSheetData.personInfo.age} years</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Patient ID</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>123456</Typography>
                        </Grid>
      </Grid>
                </Paper>
                <Paper sx={{marginTop:4}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Blood Group</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{faceSheetData.personInfo.bloodGroup}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>DOB</Typography>
                            <Typography paddingLeft={2} color={colors.grey[900]}>{faceSheetData.personInfo.dob}</Typography>
                        </Grid>
      </Grid>
                </Paper>
                
                <Paper sx={{marginTop:4}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Address</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{faceSheetData.personInfo.address.address1}, {faceSheetData.personInfo.address.address2}, {faceSheetData.personInfo.address.city},
                    {faceSheetData.personInfo.address.state}, {faceSheetData.personInfo.address.postalCode}</Typography>
                        </Grid>                         
      </Grid>
                </Paper>
          </Paper>

        <Paper sx={{marginTop:2,padding:1}}>
                <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>EMERGENCY CONTACT</Typography>
                  
                  <Paper sx={{marginTop:2}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Name</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{faceSheetData.personInfo.emergencyContactDetailDto.familyContactName}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Relationship</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{faceSheetData.personInfo.emergencyContactDetailDto.familyRelation}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Mobile Number</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{faceSheetData.personInfo.emergencyContactDetailDto.familyContactNumber}</Typography>
                        </Grid>
      </Grid>
                    </Paper>
                    <Paper sx={{marginTop:4}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Doctor Name</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{faceSheetData.personInfo.emergencyContactDetailDto.doctorName}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Doctor Phone</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{faceSheetData.personInfo.emergencyContactDetailDto.doctorContactNumber}</Typography>
                        </Grid>
      </Grid>
                    </Paper>
          </Paper>

        <Paper sx={{marginTop:2,padding:1}}>
                      <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>ID</Typography>
                  
        <Paper sx={{marginTop:2}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>ID Type</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{faceSheetData.personInfo.idproofDto.type}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>ID Number</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{faceSheetData.personInfo.idproofDto.idNumber}</Typography>
                        </Grid>
                  </Grid>
                    </Paper>
          </Paper>

        <Paper sx={{marginTop:2,padding:1}}>
              <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>MEDICAL CONDITION</Typography>
                
              {faceSheetData.medicalConditionList !== [] ? faceSheetData.medicalConditionList.map((medData,index)=>(
              <Paper sx={{marginTop:2}}>
              <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                  <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>MEDICAL CONDITION</Typography>
                  <Typography paddingLeft={4} color={colors.grey[900]}>{medData.medicalcondition}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                          <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>DOCTOR NAME</Typography>
                          <Typography paddingLeft={4} color={colors.grey[900]}>{medData.doctorname}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                          <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Diagonsed Date</Typography>
                          <Typography paddingLeft={4} color={colors.grey[900]}>{medData.startdate}</Typography>
                      </Grid>
              </Grid>
                  </Paper> )):<div></div>}
          </Paper>
       
        <Paper sx={{marginTop:2,padding:1}}>
                      <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>ALLERGIES</Typography>
                  
          {faceSheetData.allergyList !== [] ? faceSheetData.allergyList.map((allergyData,index)=>(
                      <Paper sx={{marginTop:2}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>NAME</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{allergyData.alleryinfo}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>REACTION INFO</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{allergyData.reactioninfo}</Typography>
                        </Grid>
                  </Grid>
                    </Paper>)):<div></div>}
          </Paper>
        
        <Paper sx={{marginTop:2,padding:1}}>
                      <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>PRESCRIPTIONS</Typography>
                  
              {faceSheetData.prescriptionList !== [] ? faceSheetData.prescriptionList.map((prescriptionData,index)=>(
              <Paper sx={{marginTop:2}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>NAME</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{prescriptionData.prescriptionid}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>DATE</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{prescriptionData.createdOn.slice(0,10)}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>DOCTOR NAME</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{prescriptionData.doctorname}</Typography>
                        </Grid>
                  </Grid>
                    </Paper>)):<div></div>}
          </Paper>
        
        <Paper sx={{marginTop:2,padding:1}}>
                      <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>DIAGNOSTIC REPORTS</Typography>
                  
          {faceSheetData.medicalReportList !== [] ? faceSheetData.medicalReportList.map((reportData,index)=>(
                      <Paper sx={{marginTop:2}}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>NAME</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{reportData.name}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>DATE</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{reportData.testdate}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Source</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{reportData.source}</Typography>
                        </Grid>
                  </Grid>
                    </Paper> )):<div></div>}
          </Paper>
       
        <Paper sx={{marginTop:2,padding:1}}>
                      <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>Vitals</Typography>
                  
          {faceSheetData.vitalList !== [] ? faceSheetData.vitalList.map((vitalsData,index)=>(
              <Paper sx={{margin:5}} elevation={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Height</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{vitalsData.height}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Weight</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{vitalsData.weight}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Pulse</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{vitalsData.pulse}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>BP</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{vitalsData.systolic} / {vitalsData.diastolic}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Temp.</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{vitalsData.temperature}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Oxygen Saturation</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{vitalsData.oxygensaturation}</Typography>
                        </Grid>
                  </Grid>
                    </Paper>)):<div></div>}
          </Paper>
        
        <Paper sx={{marginTop:2,padding:1}}>
                      <Typography padding={2} color={colors.grey[900]} fontWeight={'bold'}>VISITS</Typography>
                  
        {faceSheetData.consultationList !== [] ? faceSheetData.consultationList.map((visitData,index)=>(
                      <Paper sx={{margin:5}} elevation={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                    <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Reason for Visit</Typography>
                    <Typography paddingLeft={4} color={colors.grey[900]}>{visitData.reasonForConsultation}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Date</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{visitData.consultationDate.slice(0,10)}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography paddingLeft={4} color={colors.grey[900]} fontWeight={'bold'}>Doctor Name</Typography>
                            <Typography paddingLeft={4} color={colors.grey[900]}>{visitData.doctorName}</Typography>
                        </Grid>
                        
                  </Grid>
                    </Paper> )):<div></div>}
          </Paper>
       
        
        </Grid>
    );
  }

    return (
    <div>
      <Dialog open={props.open} onClose={props.handleClose} fullScreen>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} padding={2}>
            <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>{props.patientName}</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={props.handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
        >
          <CloseIcon />
        </IconButton>
            </Grid>
          </Grid>
          <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
        <Divider sx={{bgcolor:'#651fff',height:5}}/>
        <DialogContent sx={{bgcolor:colors.grey[200]}}>
            {showFaceSheet === true ? <PersonalDetails /> : <div>No Data Available</div>}
        </DialogContent>
      </Dialog>
          </div>
    );
  }

  export default FaceSheet;