import './App.css';
import './index.css';
import Login from '../src/Auth/Login/login';

function App() {
  return (
    <>
    <Login/>
    </> 
    );
}

export default App;
