import * as React from 'react';
import { styled, useTheme, alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { colors,Paper } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import person_img from '../../assets/images/person.jpg';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Helper from '../../Helper/Helper';
import store from '../../context/StatePoolService';
import AdminService from '../AdminDashboard/AdminService';
import EditIcon from '@mui/icons-material/Edit';
import DraftsIcon from '@mui/icons-material/Drafts';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import dayjs from 'dayjs';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';
import { FileUploader } from "react-drag-drop-files";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SignatureCanvas from 'react-signature-canvas';
import { useNavigate } from 'react-router-dom';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';

const {drawerWidth,Main,AppBar,DrawerHeader,Search,SearchIconWrapper,StyledInputBase,patientList,
  generateString,toBase64,containsOnlyNumbers,containsSpecialChars,
  validateEmail,checkPassword} = Helper();

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function DoctorSettingsPage(){

    const {approvalList,getPreRegDetailById,GetMasterData,preRegUpdateAprrove,mapUsertoOrg} = AdminService();
    const [user, setUser] = store.useState("user");
    const [preRegListData,setPreRegListData] = React.useState([])
    const [fcPopupDetails,setFCDetails] = React.useState([]);
    const [showFCDetail, setShowFCDetail] = React.useState(false);
    const [listText,setListText] = React.useState('Pending')
    const [listType, setListType] = React.useState('');
    const [listTypeList, setListTypeList] = React.useState([{label:'Pending'},{label:'Approved'}]);
    const handleCloseFCDetailPopup = () => {
        setShowFCDetail(false);
      };

    
    function ApprovalListComponent(){
        
        return(
          <Grid>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 1000,marginTop:2}} aria-label="customized table">
                <TableHead>
                  <TableRow>
                <StyledTableCell align="center">Patient name</StyledTableCell>
                <StyledTableCell align="center">DOB</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Phone Number</StyledTableCell>
                <StyledTableCell align="center">Created Date</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>  
              </TableRow>
            </TableHead>
            <TableBody>
              {preRegListData.length !== 0 ? preRegListData.map((details,index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row" align="center">
                  {`${details.prefix} ${details.givenName} ${details.middleName}`} 
                  </StyledTableCell>
                  <StyledTableCell align="center">{details.dateOfBirth !== 'None' || details.dateOfBirth !== '' || details.dateOfBirth !== undefined || details.dateOfBirth !== null ? details.dateOfBirth: "NA"}</StyledTableCell>
                  <StyledTableCell align="center">{details.email !== 'None' || details.email !== '' || details.email !== undefined || details.email !== null ? details.email: "NA"}</StyledTableCell>
                  <StyledTableCell align="center">{details.phoneNumber !== 'None' || details.phoneNumber !== '' || details.phoneNumber !== undefined || details.phoneNumber !== null ? details.phoneNumber : "NA"}</StyledTableCell>
                  <StyledTableCell align="center">{details.createdOn !== 'None' || details.createdOn !== '' || details.createdOn !== undefined || details.createdOn !== null ? details.createdOn.slice(0,10) : details.createdOn}</StyledTableCell>
                  
                  <StyledTableCell align="center">
                  <Button variant='contained' sx={{bgcolor:'green'}} onClick={()=>{
                    getPreRegDetailById(details.id).then(res =>{
                      console.log(res)
                      if(res.status === 200){
                        setFCDetails(res.data)
                        setShowFCDetail(true)
                      } 
                    })
                    
                    
                    }}>{details.status === 'Pending' ? 'Approve' : 'View'}</Button>
                </StyledTableCell>
                </StyledTableRow>
              )):<Paper sx={{margin:5,padding:5}}>
              <Typography align='center'>No Data Available</Typography>
                </Paper>}
            </TableBody>
          </Table>
        </TableContainer>
          </Grid>
        )
      }
      function ShowRegistrationDetails(){

        console.log(fcPopupDetails)
        const [partnerList,setPartnerList] = React.useState([]);
        const [partnerName,setPartnerName] = React.useState(null);
        const [partnerID,setPartnerID] = React.useState(null);
    
        React.useEffect(() =>{
          GetMasterData('PARTNER').then(res => setPartnerList(res))
        },[])
        return (
        <div>
          <Dialog open={showFCDetail} onClose={handleCloseFCDetailPopup} fullWidth maxWidth={'md'}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} padding={2}>
              <DialogTitle sx={{fontWeight:'bold',display:'inline',position:'absolute'}}>Patient Details</DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleCloseFCDetailPopup}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
          >
            <CloseIcon />
          </IconButton>
              </Grid>
            </Grid>
            <Divider sx={{bgcolor:'#651fff',height:5,marginTop:5}}/>
            <DialogContent>    
            <Grid container spacing={3} marginTop={1}>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>First Name</Typography>
                <Typography fontSize={16}>{fcPopupDetails.givenName !== 'None' || fcPopupDetails.givenName !== '' || fcPopupDetails.givenName !== undefined || fcPopupDetails.givenName !== null ? fcPopupDetails.givenName : 'NA'}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Last Name</Typography>
                <Typography fontSize={16}>{fcPopupDetails.middleName !== 'None' || fcPopupDetails.middleName !== '' || fcPopupDetails.middleName !== undefined || fcPopupDetails.middleName !== null ? fcPopupDetails.middleName : 'NA'}</Typography>
              </Grid> 

              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Date of Birth</Typography>
                <Typography fontSize={16}>{fcPopupDetails.dateOfBirth !== 'None' || fcPopupDetails.dateOfBirth !== '' || fcPopupDetails.dateOfBirth !== undefined || fcPopupDetails.dateOfBirth !== null ? fcPopupDetails.dateOfBirth : 'NA'}</Typography>
              </Grid> 
                      
            </Grid> 
            <Grid container spacing={3} marginTop={1}>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Gender</Typography>
                <Typography fontSize={16}>{fcPopupDetails.gender.genderName !== 'None' || fcPopupDetails.gender.genderName !== '' || fcPopupDetails.gender.genderName !== undefined || fcPopupDetails.gender.genderName !== null ? fcPopupDetails.gender.genderName : 'NA'}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Blood Group</Typography>
                <Typography fontSize={16}>{fcPopupDetails.bloodgroup.label !== 'None' || fcPopupDetails.bloodgroup.label !== '' || fcPopupDetails.bloodgroup.label !== undefined || fcPopupDetails.bloodgroup.label !== null ? fcPopupDetails.bloodgroup.label : 'NA'}</Typography>
              </Grid> 

              <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Created Date</Typography>
                <Typography fontSize={16}>{fcPopupDetails.createdOn !== 'None' || fcPopupDetails.createdOn !== '' || fcPopupDetails.createdOn !== undefined || fcPopupDetails.createdOn !== null ? fcPopupDetails.createdOn.slice(0,10) : fcPopupDetails.createdOn}</Typography>
              </Grid> 
                      
            </Grid> 
            <Grid container spacing={3} marginTop={1}>
              {fcPopupDetails.status === 'Pending' ? <Grid item xs={12} sm={4} padding={2}>
              <Typography color={colors.grey[900]} fontWeight={'bold'}>Select Hospital / Clinic</Typography>
            <FormControl fullWidth>
                  <Autocomplete
                  onChange = {(event, newValue) => {if(newValue && newValue.typeID){
                      setPartnerID(newValue.uuid)
                      setPartnerName(newValue.label)
                    }}}
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={partnerList}
                  value={partnerName}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.typeID}>
                        {`${option.label} [${option.uuid}]`}
                      </li>
                    );
                  }}
                  getOptionLabel={(option) => option.label || partnerName}
                  renderInput={(params) => <TextField {...params} label={fcPopupDetails.partnerId}
                  

                  />}
                />
                </FormControl>
              </Grid> : <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Partner ID</Typography>
                <Typography fontSize={16}>{fcPopupDetails.partnerId !== 'None' || fcPopupDetails.partnerId !== '' || fcPopupDetails.partnerId !== undefined || fcPopupDetails.partnerId !== null ? fcPopupDetails.partnerId : 'NA'}</Typography>
              </Grid> }
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Phone Number</Typography>
                <Typography fontSize={16}>{fcPopupDetails.phoneNumber !== 'None' || fcPopupDetails.phoneNumber !== '' || fcPopupDetails.phoneNumber !== undefined || fcPopupDetails.phoneNumber !== null ? fcPopupDetails.phoneNumber : 'NA'}</Typography>
              </Grid> 

              <Grid item xs={12} sm={4} padding={2}>
              <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Email ID</Typography>
                <Typography fontSize={16}>{fcPopupDetails.email !== 'None' || fcPopupDetails.email !== '' || fcPopupDetails.email !== undefined || fcPopupDetails.email !== null ? fcPopupDetails.email : 'NA'}</Typography>
              </Grid> 
                      
            </Grid> 
            <Grid container spacing={2} marginTop={1}>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Hospital Number</Typography>
                <Typography fontSize={16}>{fcPopupDetails.patientId !== 'None' || fcPopupDetails.patientId !== '' || fcPopupDetails.patientId !== undefined || fcPopupDetails.patientId !== null ? fcPopupDetails.patientId : 'NA'}</Typography>
              </Grid>
              <Grid item xs={12} sm={8} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Address</Typography>
                <Typography fontSize={16}>
                  {fcPopupDetails.address.address1 !== 'None' || fcPopupDetails.address.address1 !== '' || fcPopupDetails.address.address1 !== undefined || fcPopupDetails.address.address1 !== null ? fcPopupDetails.address.address1 : 'NA'},
                  {fcPopupDetails.address.taluk !== 'None' || fcPopupDetails.address.taluk !== '' || fcPopupDetails.address.taluk !== undefined || fcPopupDetails.address.taluk !== null ? fcPopupDetails.address.taluk : 'NA'},
                  {fcPopupDetails.address.state !== 'None' || fcPopupDetails.address.state !== '' || fcPopupDetails.address.state !== undefined || fcPopupDetails.address.state !== null ? fcPopupDetails.address.state : 'NA'},
                  {fcPopupDetails.address.country !== 'None' || fcPopupDetails.address.country !== '' || fcPopupDetails.address.country !== undefined || fcPopupDetails.address.country !== null ? fcPopupDetails.address.country : 'NA'},
                  {fcPopupDetails.address.postalCode !== 'None' || fcPopupDetails.address.postalCode !== '' || fcPopupDetails.address.postalCode !== undefined || fcPopupDetails.address.postalCode !== null ? fcPopupDetails.address.postalCode : 'NA'}
                  </Typography>
              </Grid> 

            </Grid> 
            <Grid container spacing={3} marginTop={1}>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>ID Type</Typography>
                <Typography fontSize={16}>{fcPopupDetails.idproof.type !== 'None' || fcPopupDetails.idproof.type !== '' || fcPopupDetails.idproof.type !== undefined || fcPopupDetails.idproof.type !== null ? fcPopupDetails.idproof.type : 'NA'}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>ID Number</Typography>
                <Typography fontSize={16}>{fcPopupDetails.idproof.idNumber !== 'None' || fcPopupDetails.idproof.idNumber !== '' || fcPopupDetails.idproof.idNumber !== undefined || fcPopupDetails.idproof.idNumber !== null ? fcPopupDetails.idproof.idNumber : 'NA'}</Typography>
              </Grid> 
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>ID Father / Spouse / Guardian Name</Typography>
                <Typography fontSize={16}>{fcPopupDetails.idproof.idNumber !== 'None' || fcPopupDetails.idproof.father_name !== '' || fcPopupDetails.idproof.father_name !== undefined || fcPopupDetails.idproof.father_name !== null ? fcPopupDetails.idproof.father_name : 'NA'}</Typography>
              </Grid>
                      
            </Grid> 
            <Grid container spacing={3} marginTop={1}>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Emergency Contact Name</Typography>
                <Typography fontSize={16}>{fcPopupDetails.emergencyName !== 'None' || fcPopupDetails.emergencyName !== '' || fcPopupDetails.emergencyName !== undefined || fcPopupDetails.emergencyName !== null ? fcPopupDetails.emergencyName : 'NA'}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Emergency Contact Number</Typography>
                <Typography fontSize={16}>{fcPopupDetails.emergencyNumber !== 'None' || fcPopupDetails.emergencyNumber !== '' || fcPopupDetails.emergencyNumber !== undefined || fcPopupDetails.emergencyNumber !== null ? fcPopupDetails.emergencyNumber : 'NA'}</Typography>
              </Grid> 

              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Emergency Contact Relation</Typography>
                <Typography fontSize={16}>{fcPopupDetails.emergencyRelation !== 'None' || fcPopupDetails.emergencyRelation !== '' || fcPopupDetails.emergencyRelation !== undefined || fcPopupDetails.emergencyRelation !== null ? fcPopupDetails.emergencyRelation : 'NA'}</Typography>
              </Grid> 
                      
            </Grid> 
            <Grid container spacing={3} marginTop={1}>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Emergency Doctor Name</Typography>
                <Typography fontSize={16}>{fcPopupDetails.doctorName !== 'None' || fcPopupDetails.doctorName !== '' || fcPopupDetails.doctorName !== undefined || fcPopupDetails.doctorName !== null ? fcPopupDetails.doctorName : 'NA'}</Typography>
              </Grid>
              <Grid item xs={12} sm={4} padding={2}>
                <Typography fontSize={16} fontWeight={'bold'} sx={{color:'grey'}}>Emergency Doctor Number</Typography>
                <Typography fontSize={16}>{fcPopupDetails.doctorNumber !== 'None' || fcPopupDetails.doctorNumber !== '' || fcPopupDetails.doctorNumber !== undefined || fcPopupDetails.doctorNumber !== null ? fcPopupDetails.doctorNumber : 'NA'}</Typography>
              </Grid> 
                      
            </Grid> 
            </DialogContent>
            {fcPopupDetails.status === 'Pending' ? <DialogActions>
            <Button variant="contained" sx={{width:'20%',bgcolor:'green'}}
            onClick={() =>{
              preRegUpdateAprrove(fcPopupDetails.id,fcPopupDetails.prefix,fcPopupDetails.givenName,fcPopupDetails.middleName,fcPopupDetails.gender.genderCode,
                fcPopupDetails.email,fcPopupDetails.phoneNumber,fcPopupDetails.dateOfBirth,fcPopupDetails.bloodgroup.typeID,
                fcPopupDetails.patientId,fcPopupDetails.address.address1,fcPopupDetails.address.taluk,fcPopupDetails.address.state,fcPopupDetails.address.country,
                fcPopupDetails.address.postalCode,partnerID === null ? fcPopupDetails.partnerId : partnerID,fcPopupDetails.idproof.type,
                fcPopupDetails.idproof.idNumber,fcPopupDetails.father_name,fcPopupDetails.emergencyName,fcPopupDetails.emergencyNumber,fcPopupDetails.emergencyRelation,
                fcPopupDetails.doctorName,fcPopupDetails.doctorNumber,fcPopupDetails.profileImage).then(res =>{
                  console.log(res)
                  if(res.status === 200){
                    toast.success(res.message)
                    handleCloseFCDetailPopup()
                    approvalList(user.orgID,"Pending").then(res => {
                      console.log(res)
                      if(res.status === 200){
                        setPreRegListData(res.data)
                      }
                    })
                    mapUsertoOrg(res.data.orgId,res.data.personId).then(res =>{
                      console.log(res)
                    })
                  }
                })
            }}
            >
                      Approve
                    </Button>
    
                    
          </DialogActions> : null}
          </Dialog>
              </div>
        );
      }
      React.useEffect(()=>{
        setListType(listTypeList[0].label)
        approvalList(user.orgID,listTypeList[0].label).then(res => {
          console.log(res)
          if(res.status === 200){
            setPreRegListData(res.data)
          }
        })
      },[])
        return(
          <Grid item xs={10} md={10} lg={10}>
              <Paper sx={{padding:2}}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={10} >
                  <Typography color={colors.grey[500]} marginTop={1}>{listText} List</Typography>
                  </Grid>
                  <Grid item xs={12} md={2} >
                  <FormControl fullWidth>
                  <Autocomplete
                        onChange = {(event, newValue) => {if(newValue){
                            setListType(newValue.label)
                            approvalList(user.orgID,newValue.label).then(res => {
                              console.log(res)
                              if(res.status === 200){
                                setPreRegListData(res.data)
                              }
                            })
                            setListText(newValue.label)
                        }}}
                        size="small"
                        disablePortal
                        value={listType}
                        id="combo-box-demo"
                        options={listTypeList}
                        getOptionLabel={(option) => option.label || listType}
                        renderInput={(params) => <TextField {...params} label="Select List Category"
                        
                        />}
                      />
                  </FormControl>
                  </Grid>
                  
                </Grid>
                      
                  </Paper>
                  <Paper sx={{padding:2,marginTop:2}}>
                  <ApprovalListComponent/>
                  </Paper>
                   {showFCDetail === true ? <ShowRegistrationDetails/> : <div></div>}
          </Grid>
        );
      
      
}