import * as React from 'react';
import { styled, useTheme, alpha} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { colors, Icon, Paper, useRadioGroup } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import logoUB from '../../assets/images/logo_ub.png'
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import person_img from '../../assets/images/person.jpg';
import Grid from '@mui/material/Grid';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import SearchResults from './SearchComponents/SearchResults';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import GroupIcon from '@mui/icons-material/Group';
import DoctorSettingsPage from './DoctorSettingsPage';
import Helper from '../../Helper/Helper';
import AnalysisBar from './AnalysisComponent/AnalysisBarUI';
import AppointmentsComponent from './AppointmentsComponent/AppointmentsUI';
import Calendar from './DoctorScheduling/DoctorSchedule';
import TeleCallComponent from './AppointmentsComponent/TeleCallComponent';
import store from '../../context/StatePoolService';
import NewAppointment from './DoctorScheduling/NewAppointment';
import RescheduleAppointment from './DoctorScheduling/RescheduleAppointment';
import Menu from '@mui/material/Menu';
import { useNavigate} from 'react-router-dom';
import AdminService from '../AdminDashboard/AdminService';
import PatientRegistration from '../PatientComponents/PatientRegistration';
import { toast } from 'react-toastify';
import DoctorDetailsBar from './DoctorHandlerComponent/DoctorDetailsBar';
import ScheduleLeave from './DoctorScheduling/ScheduleLeave';
import SearchService from './SearchComponents/SearchService';
import LoginLogic from '../../Auth/Login/LoginLogic';
import dayjs from 'dayjs';
import DoctorDashboard from './DoctorDashboard';
import AddIcon from '@mui/icons-material/Add';
import AssignDoctor from 'C:/Users/shara/ReactApp/Git_repo/webdevelopment/src/routes/AdminDashboard/Assign Doctor/AssignDoctor.js';
import DoctorNewRegistration from 'C:/Users/shara/ReactApp/Git_repo/webdevelopment/src/routes/AdminDashboard/DoctorManagement/DoctorNewRegistartion';
import DoctorDetails from 'C:/Users/shara/ReactApp/Git_repo/webdevelopment/src/routes/AdminDashboard/DoctorManagement/DoctorGetUpdateDetails';
import DoctorPanelList from 'C:/Users/shara/ReactApp/Git_repo/webdevelopment/src/routes/DoctorDashBoard/DoctorPanel/PanelListComponent';
import AddNewPanel from 'C:/Users/shara/ReactApp/Git_repo/webdevelopment/src/routes/DoctorDashBoard/DoctorPanel/CreateNewPanel';
import CampForm from './AppointmentsComponent/CampForm';
import ScheduleService from './DoctorScheduling/ScheduleService';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';



const {drawerWidth,Main,AppBar,DrawerHeader,Search,SearchIconWrapper,StyledInputBase,generateString} = Helper();


export default function Doctor() {

  let navigate = useNavigate();
  const {getPatientPersoanlDetails} = SearchService();
  const {userLogout,getPatientList,getDoctorsList,campPatientList} = AdminService();
  const {getDoctorDashboardDetail} = LoginLogic();
  const {createNewAppointment} = ScheduleService();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [user, setUser] = store.useState("user");
  const [component, setComponent] = store.useState('componentState');
  const [openPopup, setOpenPopup] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [patientList, setPatientList] = React.useState([]);
  const [dashboardDetails, setDashboardDetails] = React.useState([]);
  const [dataForScheduler,setDataForScheduler] = store.useState('schedulerData');
  const [dataForAppointment,setDataForAppointment] = store.useState('appointmentData');
  const [patientID, setPatientID] = React.useState('')
  const [patientData, setPatientData] = React.useState();
  const [personID,setPersonID] = store.useState('personID');
  const [doctorUUID,setDoctorUUID] = store.useState('doctorUUID');
  const [showPatientSearch,setShowPatientSearch] = React.useState(true)
  const [consultationID, setConsultationID] = React.useState('');
  const [isLoading,setIsLoading] = React.useState(false);
  const [patientSearchTerm, setPatientSearchTerm] = React.useState('');
  
  console.log('data of appointment',dataForAppointment)
  console.log('data of scheduler',dataForScheduler)
  console.log('compo',component)
  const openNow = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleClosePopup = async () => {
    setOpenPopup(false);
    await getPatientList(user.orgID,'a').then(res => setPatientList(res.data.content));
  };
  
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleSearchResults =(uuid,orgID,name,personID,profileImage) =>{
    
    store.setState("patient", {
      uuid: uuid,
      orgID: orgID,
      name: name,
      personID: personID,
      profileImage: profileImage                
    });
    getPatientPersoanlDetails(uuid).then(res =>{
      console.log("PDATA",res)
      if(res.status === 200){
        setPatientData(res.data)
        setComponent('SearchResults')
      }else{
        setComponent('SearchResults')
      }
    })
    
  }

  const handleLogout = () =>{
    userLogout().then( res => {
      if(res.status === 200){
        navigate("/login")
      }
    })
  }

  function drawerNavigation(text){
    if(text.label === 'Patient Registration'){
      setOpenPopup(true);
    }else{
      console.log("INSIDE NAV",text)
      store.setState("componentDetails",text)
      setComponent(text.checked === true ? text.label : "")
    }
    
  }


  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  function ManageDoctor(){
    const [doctorList,setDoctorList] = React.useState([]);
    const[showList,setShowList] = React.useState(false);

    React.useEffect(() => {
      const loadData = async () => {
        await getDoctorsList(user.orgID,'a').then(res =>{
          console.log("DOCTOR LIST",res)
          if(res.status === 200){
            setDoctorList(res.data.content);
            setShowList(true);
          }else{
            setDoctorList([]);
            setShowList(false);
          }
          
        });
      }
      loadData();
    },[])
    return(
      <Grid item xs={10} md={10} lg={10}>
        <Grid container spacing={3}>
          <Grid item xs={5} md={4}>
          <Typography fontSize={20} fontWeight={'bold'} >Manage Doctor</Typography>
          </Grid>
          <Grid item xs={2} md={5.5}></Grid>
          <Grid item xs={5} md={2.5}>
            <Button variant="contained" sx={{width:'100%',bgcolor:'#651fff'}} startIcon={<AddIcon/>} onClick={()=>{setComponent('Camp')}}>
                    Register Doctor
                  </Button>
          </Grid>
        </Grid>
        {doctorList.length !== 0 ?doctorList.map((list,i) => (
        <Paper sx={{marginTop:2,padding:2}} key={generateString(i,16)}>
                  <Grid container spacing={3} >
                  <Grid item xs={12} md={0.5}>
                    <Box width={30} height={30} sx={{bgcolor:'#651fff'}}>
                      <img src={list.profileImage}></img>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={9.5}>
                  <Typography color={colors.grey[900]}>{list.prefix} {list.name}</Typography>
                  <Typography color={colors.grey[700]} fontSize={12}>{list.orgName} . {list.contactNumber} . {list.emailId}</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                  <Button variant="outlined" sx={{width:'100%'}} onClick={()=>{
                    setPersonID(list.personId)
                    setDoctorUUID(list.uuId)
                    setComponent('doctorDetails')
                  }}>
                    Edit
                  </Button>
                  </Grid>
                </Grid>
                      </Paper>)) : <Paper sx={{margin:5,padding:5}}>
                      <Typography align='center'>No Data Available</Typography>
                        </Paper> }
                      
                      
      </Grid>
    );
  }
  const renderSearchState = () =>{
    if(user.userType === 'OrgSuperAdmin'){
      for(var i = 0; i < user.priviliges.length;i++){
        if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === false){
          setShowPatientSearch(false)
        }
      }
      
    }else if(user.userType === 'ADMIN'){
      for(var i = 0; i < user.priviliges.length;i++){
        if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === false){
          setShowPatientSearch(false)
        }
      }
      
    }else if(user.userType === 'DOCTOR'){
      for(var i = 0; i < user.priviliges.length;i++){
        if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === false){
          setShowPatientSearch(false)
        }
      }
      
    }
  }

  

  function CampDashboard(){
    const [campPatientListData,setCampPatientListData] = React.useState([]);
    const[showList,setShowList] = React.useState(true);
    const [componentDetails, setComponentDetails] = store.useState('componentDetails');
    const [pageTotalCount, setPageTotalCount] = React.useState();
    const [mcSearchTerm, setMCSearchTerm] = React.useState('');

    const handleInputChange = async (event) => {
      var value = event.target.value;
      setMCSearchTerm(event.target.value);
      console.log("Search Val",value)
      if (value !== '') {
        // Call the search API when there is input
        setCampPatientListData([]);
        await campPatientList(value,0).then(res =>{
          console.log("DOCTOR ELSE LIST",res)
            if(res.status === 200){
              setCampPatientListData(res.data);
              // setPageTotalCount(res.data.totalPages)
              setShowList(true);
            }else{
              setCampPatientListData([]);
              setShowList(false);
            }
                      
          });
      } else {
        // Call the default API when input is cleared
        setCampPatientListData([]);
        await campPatientList('a',0).then(res =>{
          console.log("DOCTOR LIST",res)
            if(res.status === 200){
              setCampPatientListData(res.data);
                setPageTotalCount(res.data.totalPages)
                setShowList(true);
              }else{
                setCampPatientListData([]);
                setShowList(false);
              }
                      
          });
      }
    };

    React.useEffect(() => {
      const loadData = async () => {
        await campPatientList('a',0).then(res =>{
          console.log("Camp Patient LIST",res)
          if(res.status === 200){
            setCampPatientListData(res.data);
            setShowList(true);
          }else{
            setCampPatientListData([]);
            setShowList(false);
          }
          
        });
      }
      loadData();
    },[])
    return(
      <Grid item xs={10} md={10} lg={10}>
        <Grid container spacing={3}>
          <Grid item xs={5} md={4}>
          <Typography fontSize={20} fontWeight={'bold'} >Registered Patients</Typography>
          </Grid>
          <Grid item xs={2} md={5.5}>
              <TextField label="Search Patients" variant="outlined" size='small' fullWidth
                value={mcSearchTerm}
                onChange={handleInputChange}
                
              />
          </Grid>
        </Grid>
        {campPatientListData.length !== 0 ?campPatientListData.map((list,i) => (
        <Paper sx={{marginTop:2,padding:2,marginBottom:3}} key={generateString(i,16)}>
                  <Grid container spacing={3} >
                  <Grid item xs={12} md={0.5}>
                  <Box width={30} height={30} sx={{bgcolor:'#651fff'}}>
                    <Avatar src={""} sizes='small' variant='square'></Avatar>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={9.5}>
                  <Typography color={colors.grey[900]}>{list.prefix} {list.name}</Typography>
                  <Typography color={colors.grey[700]} fontSize={12}>{list.orgName} . {list.contactNumber} . {list.emailId}</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                  {list.isConsultationGenerated === 'No'? 
                  <Button variant="contained" sx={{width:'100%',fontSize:12}} onClick={()=>{
                    setIsLoading(true)
                    var startEpochTime =new Date().getTime();
                    console.log("epoch start time",startEpochTime);
                    var endEpochTime = new Date().getTime();
                    console.log("epoch end time",endEpochTime);
                    createNewAppointment(user.uuid,116,list.uuId,user.uuid,startEpochTime,endEpochTime,15,'Medical Examination','NOT PAID',0,'NA','NA').then(res =>
                      {
                        console.log(res)
                        if(res.status === 200){
                          toast.success(res.message)
                          setConsultationID(res.id)
                          setPatientID(list.uuId)
                          setIsLoading(false)
                          setComponent('Camp Form')
                        }else{
                          toast.error("Error Occurred")
                        }
                      }
                    )
                  }}
                  >
                    Book Consultation
                  </Button>
                  : <Button variant="outlined" sx={{width:'100%'}} onClick={()=>{
                    setIsLoading(true)
                    setPatientID(list.uuId)
                    setConsultationID(list.consultationId)
                    setComponent('Camp Form')
                    setIsLoading(false)
                  }}
                  disabled={list.consultationStatus === 'completed' ? true : false}
                  >
                    Details
                  </Button> }
                  
                  </Grid>
                </Grid>
                      </Paper>)) : <Paper sx={{margin:5,padding:5}}>
                      <Typography align='center'>No Data Available</Typography>
                        </Paper> }
                      
    {/* <Stack spacing={2} marginTop={3} marginBottom={5}>
      
      <Pagination count={pageTotalCount} color="primary" onChange={async(event,page) =>{
        setCampPatientListData([]);
        await campPatientList(' ',page - 1).then(res =>{
          console.log("DOCTOR LIST",res)
          if(res.status === 200){
            setCampPatientListData(res.data.content);
            setPageTotalCount(res.data.totalPages)
            setShowList(true);
          }else{
            setCampPatientListData([]);
            setShowList(false);
          }
          
        });
      
      }}/>
    
    </Stack>          */}
      </Grid>
    );
  }
  console.log("Profile Image",user.profileImage)
  console.log("Doctor name",user.name)
  React.useEffect(() => {
    const loadData = async () => {
      renderSearchState()
      await getPatientList(user.orgID,'a').then(res =>{
        console.log("Patient LIST",res)
        if(res.status === 200){
          setPatientList(res.data.content);
        }else{
          setPatientList([]);
          console.log(res.message)
        }
        
      });
    }
    loadData();
  },[])

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{bgcolor:colors.grey[200]}}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon  sx={{color:'#000000'}}/>
          </IconButton>
          <Paper className='.MuiPaper-elevation12' sx={{width:'98%'}}>
           
          {showPatientSearch === true ?
          <Search>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
               <Autocomplete
                sx={{
                  width:'50%',marginLeft:4,
                }}
                size="small"
                freeSolo
                id="free-solo-2-demo"
                disableClearable
                options={patientList}
                getOptionLabel={(option) => option.name || ""}
                renderInput={(params) => (
                  <StyledInputBase
                  hiddenLabel
                  variant='standard'
                    {...params}
                    label="Search for Patients...."
                    InputProps={{
                      ...params.InputProps,
                      type: 'search',
                      disableUnderline:true
                    }}
                  />
                )}
                renderOption={(props,option) =>(
                  <li{...props} key={option.uuId}>
                  <List sx={{ width: '100%',bgcolor: '#fff' }}>
                    <ListItem alignItems="flex-start"  onClick={() => {
                      setComponent('Patient Dashboard')
                      setPatientID(option.uuId)
                      handleSearchResults(option.uuId,option.orgName,option.name,option.personId,option.profileImage)
                      if(user.userType === 'OrgSuperAdmin'){
                        for(var i = 0; i < user.priviliges.length;i++){
                          if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === true){
                            store.setState("componentDetails",user.priviliges[i])
                          }
                        }
                        
                      }else if(user.userType === 'ADMIN'){
                        for(var i = 0; i < user.priviliges.length;i++){
                          if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === true){
                            store.setState("componentDetails",user.priviliges[i])
                          }
                        }
                        
                      }else if(user.userType === 'DOCTOR'){
                        for(var i = 0; i < user.priviliges.length;i++){
                          if(user.priviliges[i].label === 'Patient Search' && user.priviliges[i].checked === true){
                            store.setState("componentDetails",user.priviliges[i])
                          }
                        }
                        
                      }
                      }}>
                      <ListItemButton sx={{height:'auto'}}>
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={''} />
                      </ListItemAvatar>
                      <ListItemText
                     
                        primary={option.name}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: 'inline' }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                              align='left'
                              fontStyle={'italic'}
                              fontSize={12}
                            >
                              {option.emailId} . {option.orgName} . {option.contactNumber}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                      </ListItemButton>
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </List>
                  </li>
                )} 
              /> 
            
            </Search>: <div></div> }
            

          </Paper>
          <div>
            <IconButton color="inherit" onClick={handleClick}>
              <StyledBadge
                color="secondary"
                variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                classes={{
                  badge: 'green-ripple',
                }}
              >
              <Avatar alt="Remy Sharp" src={user.profileImage} sx={{width:30, height:30}}/>
              
              </StyledBadge>
            </IconButton>
            <Menu
               anchorEl={anchorEl}
               open={Boolean(anchorEl)}
               onClose={handleClose}
               anchorOrigin={{
                 vertical: 'top',
                 horizontal: 'right',
               }}
               transformOrigin={{
                 vertical: 'top',
                 horizontal: 'right',
               }}
              >
                <MenuItem onClick={() => {setComponent('Doctor Settings')
                  handleClose()
              }}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>

            </div>
                          
            <Badge color="secondary" badgeContent={4} sx={{position: 'absolute',
                  top: 20,right:80,color:'black'}}>
              <NotificationsIcon />
            </Badge>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          '& .MuiButtonBase-root': {
            '&:hover': {
              backgroundColor: '#651fff',
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
        <Avatar
                alt="logo"
                src={logoUB}
                sx={{ width: 50, height: 50, position: 'absolute',
                  top: 0,
                  left: 5}}
                variant = 'square'
              />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Typography component="h1" variant="subtitle2" sx={{padding:1,color:colors.grey[500]}}>
              MENU
        </Typography>
        <List>
        {user.priviliges.map((text, index) => (
            (text.label !== 'Patient Search' && text.checked === true ?<ListItem key={text.id} disablePadding>
            <ListItemButton onClick={() => drawerNavigation(text)}>
              <ListItemIcon>
              {text.checked === true ? index % 2 === 0 ? <InboxIcon /> : <MailIcon /> : null}
              </ListItemIcon>
              <ListItemText primary={text.checked === true ? text.label : ""} />
            </ListItemButton>
          </ListItem> : <div></div>)
            
          ))}
        </List>
      </Drawer>
      <Main open={open} sx={{bgcolor:colors.grey[200]}}>
        <DrawerHeader />
        {component === 'SearchResults' ?<SearchResults patientData = {patientData} patientUUID = {patientID}/>:<div></div>}
        {component === 'Doctor Dashboard' ? <DoctorDashboard token = {user.token} uuid = {user.uuid} baseUrl = {user.baseUrl} profileImage = {user.profileImage} doctorName = {user.name}/>:<div></div>}
        {component === 'Doctor Settings' ?<DoctorSettingsPage/>:<div></div>}
        {component === 'Doctor Scheduling' ? <Calendar/>:<div></div>}
        {component === 'ScheduleLeave' ? <ScheduleLeave doctorName = {dataForScheduler.doctorName} doctorUUID = {dataForScheduler.doctorUUID} profileImage={dataForScheduler.profileImage} speciality={dataForScheduler.speciality}/>:<div></div>}
        {component === 'Reschedule' ? <RescheduleAppointment consultationId = {dataForAppointment.aptId} doctorUUID = {dataForAppointment.doctorUUID}/>:<div></div>}
        {component === 'NewAppointment' ? <NewAppointment orgID = {user.orgID} doctorName = {dataForScheduler.doctorName} doctorUUID = {dataForScheduler.doctorUUID}/>:<div></div>}
        {component === 'TeleCall' ?<TeleCallComponent consultationId = {dataForAppointment.aptId} doctorUUID = {dataForAppointment.doctorUUID} patientID = {dataForAppointment.patientID} doctorName={user.name} doctorSignature={user.signature}
          videoCallURL={dataForAppointment.videoCallURL} doctorProfileImage={user.profileImage} doctorQualification={user.qualification} doctorSpecialization={user.specialization} doctorProfSpeciality={user.profSpeciality}/>:<div></div>}
        {openPopup === true ?<PatientRegistration openPopup = {openPopup} handleClosePopup = {handleClosePopup}/>:<div></div>}
        {component === 'Assign Doctor' ? <AssignDoctor/>:<div></div>}
        {component === 'Doctor Management' ? <ManageDoctor/>:<div></div>}
        {component === 'doctorDetails' ? <DoctorDetails/>:<div></div>}
        {component === 'DoctorRegistration' ? <DoctorNewRegistration/>:<div></div>}
        {component === 'Doctor Panel' ?<DoctorPanelList/>:<div></div>}
        {component === 'AddNewPanel' ?<AddNewPanel/>:<div></div>}
        {component === 'Medical Camp' ?<CampDashboard/>:<div></div>}
        {isLoading === true ? 
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          : <div></div>} 
        {component === 'Camp Form' ?<CampForm consultationId = {consultationID} doctorUUID = {user.uuid} patientID = {patientID} doctorName={user.name} doctorSignature={user.signature}
           doctorProfileImage={user.profileImage} doctorQualification={user.qualification} doctorSpecialization={user.specialization} doctorProfSpeciality={user.profSpeciality}/>:<div></div>}
      </Main>
    </Box>
  );
}